/*
Documentation

this component shows only texts inside the case feed
it also allows emails to be sent

*/

import SendText from 'components/system/SendText';
import SendTextModalWrapper from 'components/system/SendText/ModalWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardHeader } from "reactstrap";
import RenderText from './Render/Text';



class Feed extends Component {

    state = {
        feedTextEntry:      { to: this.props.case.contact.phone },
        canSave:            true,
        showModal: false
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onTextSendError = () => {}

    //this function takes the state feed array and filters it by type equal to the filter value
    filterFeed = (feed) => {

        if(feed && feed.length ) { feed = feed.filter(f => f.type === 'contact_text') }
        return feed

    }

    render() {

        if(this.props.view !== 'contact_texts') return null

        const { showModal } = this.state
        let { feed, showFeedEntries, isFullScreen, toggleFullScreen, renderNameAndInitial } = this.props
        const _case = this.props.case

        if(!feed) feed = []
        feed = this.filterFeed(feed)
        feed = feed.slice(0, showFeedEntries)

        return (

            <>

            <CardHeader>

                <p className="font-weight-bold mb-0 text-muted">
                    <i className="fas fa-phone" />
                    <span className="float-right">
                        <i
                            style={{cursor: 'pointer'}}
                            className={!isFullScreen ? "fas fa-plus text-success pl-4" : "fas fa-minus text-warning ml-3 pl-4"}
                            onClick={toggleFullScreen}
                        />
                    </span>
                </p>

                <button onClick={this.toggleModal} className="btn btn-block btn-outline-success mt-2">Send Text</button>

            </CardHeader>

            <div id="feed-wrapper" className="feed-wrapper" style={{overflowY: 'auto'}}>

                <table className="table" >
                    <tbody>

                        { feed && feed.length ? (
                            feed.map((entry, i) => (
                                <RenderText
                                    key={i}
                                    entry={entry} 
                                    case={_case} 
                                    renderNameAndInitial={renderNameAndInitial} 
                                />
                            ) )
                        ) : feed ? (

                            <tr>
                                <td style={{whiteSpace: 'normal'}}>
                                    <div className="item-wrapper">
                                        <h6 className="text-sm mb-0 font-weight-bold">
                                            SYSTEM
                                        </h6>
                                        There are no texts associated with this contact
                                    </div>
                                </td>
                            </tr>

                        ) : null}

                    </tbody>
                </table>

            </div>

            <SendTextModalWrapper
                toggleModal={this.toggleModal}
                showModal={showModal}
            >
                <SendText
                    onSendingStart= {this.toggleModal}
                    onError=        {this.onTextSendError}
                    contact=        {_case && _case.contact ? _case.contact._id : undefined}
                />
            </SendTextModalWrapper>

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

Feed.propTypes = {
    feed                : PropTypes.array.isRequired,
    case                : PropTypes.object.isRequired,
    toggleFullScreen    : PropTypes.func.isRequired,
    isFullScreen        : PropTypes.bool.isRequired,
    showFeedEntries     : PropTypes.number.isRequired,
    view                : PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(Feed);
