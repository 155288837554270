import React, { Component } from 'react';
import renderName from 'utils/renderName';
import PersonSearchInput from 'views/dashboard/pages/_components/PersonSearchInput';
import PropTypes from 'prop-types'

class SendEmailTo extends Component {

    state = {
        isEditing: false,
    }

    focusEditingInput = () => {
        const input = document.getElementById('send-email-input')
        if(input) input.focus()
    };

    onQuillFocus = () => this.setEditing(false)

    setEditing = (isEditing) => {

        this.setState({isEditing}, () => {

            const editor = document.querySelector('.ql-editor')

            if(isEditing) {

                this.focusEditingInput()

                if(editor) editor.addEventListener('focus', this.onQuillFocus)

            } else {

                if(editor) editor.removeEventListener('focus', this.onQuillFocus)

            }

        })

    }

    onRemoveTo = (contact_id) => {

        let to = this.props.to ? [...this.props.to] : [];
        to = to.filter(contact => contact._id !== contact_id)

        this.props.onToChange(to)
        this.focusEditingInput()

    }

    onAddTo = (contact) => {

        if(!contact.email) return alert('The contact you have selected does not have an email address.')

        let to = this.props.to ? [...this.props.to] : [];

        if(!to.find(to_contact => to_contact._id === contact._id)) {

            to.push(contact)

            this.props.onToChange(to)
            this.focusEditingInput()

        }

    }

    componentWillUnmount = () => {

        const editor = document.querySelector('.ql-editor')
        if(editor) editor.removeEventListener('focus', this.onQuillFocus)

    }

    render() {

        const { to } = this.props
        const { isEditing } = this.state

        if(isEditing) {

            return (

                <div className="to-container editing">
                    To: {' '}
                    {to && to.length ? to.map(contact => (
                        <span key={contact._id}>{contact.email}
                            <b><i onClick={() => this.onRemoveTo(contact._id)} className="fas fa-times" /></b>
                        </span>
                    )): null}

                     <PersonSearchInput
                        inputID="send-email-input"
                        value={{}}
                        onSelect={this.onAddTo}
                        leaveEmpty={true}
                    />
                </div>
            )

        }

        return (

            <div className="to-container" onClick={() => this.setEditing(true)}>
                To: {' '}

                {to && to.length ? to.map(contact => {
                    const name = renderName(contact)
                    return <span key={contact._id}>{name === 'Not Found' ? contact.email : name}</span>
                }): null}
            </div>

        )

    }

}

SendEmailTo.propTypes = {
    onToChange   : PropTypes.func.isRequired,
    to           : PropTypes.array,
}

export default SendEmailTo
