/*
Documentation

checks for required fields on and object, returns success true / false if passes, sets statename to {state}Name = 'invalid if failed on property
stateName       = the name of the object to check for
required_fields = array of state properties to check for
_this           = the this property on the object
dontPassEmpty   = normally if field is not undefined we pass, if this is true if the field is null, 0 or false we return false

*/

export default (stateName, required_fields, _this, dontPassEmpty) => {

    let obj = Object.assign({}, _this.state[stateName])
    //assume we have no errors
    let errors = 0;

    // make sure all fields that are required are set and not undefined
    required_fields.forEach((field) => {
        //check each required field
        if(obj[field] !== undefined) {

            if(dontPassEmpty && !obj[field]) {
                obj[field + "State"] = 'invalid';
                errors++;
            } else {
                obj[field + "State"] = 'valid';
            }

        } else {
            obj[field + "State"] = 'invalid';
            errors++
        }

    })

    _this.setState({[stateName] : obj})

    return { success: errors ? false : true, state: obj }


}
