import * as actionTypes from '../../actions';
import store from '../../index';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import _auth from '_functions/auth';

export const setViewingUser = async() => {
    return new Promise( async resolve => {

        const viewing_user = await _auth.status.isLoggedIn()

        if(viewing_user.success) {

            store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: viewing_user.user } });
            setListener(viewing_user.user)
            resolve({success: true, data: viewing_user.user});

        } else {

            resolve({success: false});

        }

    })

}

const setListener = (viewing_user) => {

    io.on(socketEvents.viewing_user_updated, async (data) => {

        if(viewing_user && data && data.data && viewing_user._id === data.data._id) {
            store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: data.data } });
        }

    })

}
