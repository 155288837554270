import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'

const ModalComplete = ({payment, showModal, toggleModal}) => {

    const onCapture = async () => {

        toggleStandardLoader(true)
        let action = await _payments.transactions.complete(payment._id);
        toggleStandardLoader(false)

        toggleModal()

        if(action.success) {
            toggleAlertBS(false, `The Payment Was Captured Successfully And Will Show Up Shortly.`)
        } else {
            toggleAlertBS(true, `Something went wrong, payment was not captured.`)
        }

    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Capture Payment
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {payment && payment._id ? (
                <div className="modal-body">
                    <p className="mb-0">Capture Payment For <span className="text-success text-underline font-weight-bold">{formatMoney(payment.transaction_amount)}</span> </p>
                    <p className="text-sm text-muted mb-0">This must be done within 7 days of the authorized date.</p>
                </div>
            ) : null}

            <div className="modal-footer">
                <button onClick={onCapture} className="btn btn-success">
                    <i className="fas fa-headphone" /> Capture
                </button>
            </div>

        </Modal>

    )

}

ModalComplete.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    payments    : PropTypes.object,
}

export default ModalComplete;
