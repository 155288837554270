/*
Documentation

this is the standard error page for the application that should pop up
if any component catches

*/

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getUrlParameter } from 'utils/urls'
import { Container } from 'reactstrap';
import { log } from 'database';
import moment from 'moment'
import { toggleDevTickets } from 'store/functions/system/system';

class Standard extends Component {

    componentDidMount = () => {
        toggleDevTickets(true)
    }

    render() {

        const error = getUrlParameter('error')
        const referrerurl = getUrlParameter('referrerurl')
        const time = moment().valueOf()

        log({
            url: referrerurl,
            file: '/src/views/errors/pages/standard.js',
            code: 'Unexpected App Error',
            text: error
        });

        if(error) {
            return (
                <Container>
                    <div className="text-center py-6">
                        <h2 className="text-uppercase mb-4">
                            An unexpected error occurred. <br />
                            <span className="text-underline">Send this information to the developers monitoring your system immediately.</span>
                        </h2>

                        <div className="text-sm bg-white p-4 rounded">
                            <h3 className="text-danger">{error}</h3>
                            <br />

                            <span className="text-underline">{referrerurl}</span>

                            <br />

                            <b>Time: {' '}
                                { moment.utc(time, 'x').format('MMMM Do, YYYY')  }
                                {' at '}
                                { moment.utc(time, 'x').format('hh:mm A')  } (GMT)
                            </b>
                        </div>

                    </div>
                </Container>
            )
        }

        return (
            <Redirect to="/" />
        );

    }

}

export default Standard;
