import cases from './cases';
import courts from './courts';
import contacts from './contacts';
import workflows from './workflows';
import employees from './employees';
import template_docs from './template_docs';
import case_templates from './case_templates';
import calendar from './calendar';
import template_emails from './template_emails';

export default {

    cases,
    courts,
    contacts,
    workflows,
    employees,
    template_docs,
    case_templates,
    calendar,
    template_emails

}
