/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    transfer_listener: {
        status        : undefined,
        contact_id    : undefined,
        user_id       : undefined,
    },
    call_status: {
        onCall        : false,
        finishCall    : undefined,
        contact_name  : undefined,
        contact_phone : undefined,
        contact       : undefined,
        contact_email : undefined,
        ConferenceSid : undefined,
        questionnaire : undefined,
        division      : undefined,
        callType      : undefined,
    },
    end_call_modal: {
        show: false
    },
    start_call_modal: {
        show: false,
        contact: null
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_TRANSFER_LISTENER:

            return {
                ...state,
                transfer_listener: action.payload
            }

        case actionTypes.SET_CALL_STATUS:

            return {
                ...state,
                call_status: action.payload
            }

        case actionTypes.SET_END_CALL_MODAL:

            return {
                ...state,
                end_call_modal: action.payload
            }

        case actionTypes.SET_START_CALL_MODAL:

            return {
                ...state,
                start_call_modal: action.payload
            }

        default: return state;

    }

}

export default reducer;
