/*
Documentation

renders the left hand sidebar of the call centers when a user
is actively on a call with a contact

*/

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import _questionnaire_answers from '_functions/questionnaire_answers';
import Circle from 'components/markup/loading/Circle';
import A from 'components/markup/links/A'

import ContactQuestionnaires from '../../../contacts/components/ContactQuestionnaires'

class SidebarOnCall extends Component {

    state = {
        questionnaire: null,
        defaultAnswers: {},
        loaded: false,
    }

    componentDidMount = async () => {

        if(this.props.appointment && this.props.appointment.questionnaire) {

            const questionnaire = this.props.questionnaires.find(q => q._id === this.props.appointment.questionnaire);
            
            if(questionnaire) {

                const foundAnswers = await _questionnaire_answers.findByContact(questionnaire._id, this.props.contact)
                const defaultAnswers = foundAnswers.data && foundAnswers.data.answers ? foundAnswers.data.answers : {};
    
                this.setState({questionnaire: questionnaire._id, defaultAnswers, loaded: true})
            } else {
                this.setState({loaded: true})
            }

        } else if(this.props.questionnaire) {
    
            const questionnaire = this.props.questionnaires.find(q => q._id === this.props.questionnaire);
            
            if(questionnaire) {

                const foundAnswers = await _questionnaire_answers.findByContact(questionnaire._id, this.props.contact)
                const defaultAnswers = foundAnswers.data && foundAnswers.data.answers ? foundAnswers.data.answers : {};
    
                this.setState({questionnaire: questionnaire._id, defaultAnswers, loaded: true})
            } else {
                this.setState({loaded: true})
            }
            
        } else {

            this.setState({loaded: true})

        }

    }

    render() {

        const { loaded } = this.state

        if(!loaded) return  <Circle />

        return (

            <div className="p-3">

                {this.state.questionnaire ? (
                    <ContactQuestionnaires 
                        contact_id={this.props.contact}
                        questionnaire={this.state.questionnaire}
                        defaultAnswers={this.state.defaultAnswers}
                        autoSave={true}
                    />
                ): (
                    <p className="text-sm">
                        No forms were found for this call.{' '}
                        <span className="text-underline">
                            <A href={`/dashboard/contacts/view/${this.props.contact}?nav=7`}>
                                Click there to view all onboarding information.
                            </A>
                        </span>
                    </p>
                )}

            </div>

        )

    }

}


const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires.questionnaires,
    };
};

SidebarOnCall.propTypes = {
    appointment: PropTypes.object,
    contact: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(SidebarOnCall);
