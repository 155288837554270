import CustomDataFormGroup from 'components/functional/inputs/CustomDataFormGroup';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';
import _cases from '_functions/cases';
import PropTypes from 'prop-types'
import * as privileges from '_functions/users/privileges'

class CaseDetails extends Component {

    state = {
        showModal: false,
        newCase: {}
    };

    onSave = async () => {

        const data = {}

        // only send object keys that include "__cd__"
        Object.keys(this.state.newCase).forEach(key => {
            if(key.includes('__cd__')) data[key] = this.state.newCase[key]
        })

        const updated = await _cases.update(this.props.case._id, data)

        if(updated.success) {
            toggleAlertBS(false, 'Custom data updated successfully')
        } else {
            toggleAlertBS(true, 'An error occurred updating the custom data for this case')
        }

    }

    getCustomDataTypes = () => {

        let analytics_custom_data = this.props.analytics_data_types;
        analytics_custom_data = analytics_custom_data.filter(a => a.collection_name === 'cases');

        this.setState({analytics_custom_data})

    }

    componentWillReceiveProps = (nextProps) => {

        this.setState({newCase: Object.assign({}, nextProps.case)})

    }

    componentDidMount = () => {

        this.setState({newCase: Object.assign({}, this.props.case)})
        this.getCustomDataTypes()

    }

    render() {

        const { newCase, analytics_custom_data } = this.state
        const { navPills } = this.props

        const canUpdate = privileges.canUpdateCases();

        if(navPills !== 9) return null

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Case Details</CardTitle>
                </CardHeader>

                <CardBody>

                {analytics_custom_data && analytics_custom_data.length ? analytics_custom_data.map(data => (
                    newCase.division === data.division ? (
                        <CustomDataFormGroup
                            key={data._id}
                            disabled={!canUpdate}
                            obj={newCase}
                            objName="newCase"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v}})}
                            // formGroupClasses="w-50"
                            values={data.values}
                            type={data.type}
                            field={data.key}
                            title={data.key}
                        />
                    ) : null
                )) : (
                    <p className="text-sm mb-0">There is no custom data added for this case.</p>
                )}

                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={canUpdate ? this.onSave : null} disabled={!canUpdate} className="btn btn-success">Save Custom Data</button>

                </CardFooter>

            </Card>

        )

    }

}



const mapStateToProps = state => {

    return {
        analytics_data_types: state.analytics_data_types.analytics_data_types
    };
};


CaseDetails.propTypes = {
    case: PropTypes.object.isRequired,
    navPills: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, '')(CaseDetails);
