import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import { secondsToTime } from 'utils/time';

class CallLogs extends Component {

    getTime = (log) => {

        const  m = moment.unix(log.created_at)

        return (
            <span>
                <div>{m.format('MMM Do, YYYY')}</div>
                <div>{m.format('h:mm') + ' - ' + m.add('seconds', log.duration ? log.duration : log.CallDuration ).format('h:mm A')}</div>
            </span>
        )
    }

    render() {

        const { call_logs, navPills, voicemail, onMarkVoicemailListened } = this.props

        return (

            navPills === 8 ? (

                <>

                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">Call Logs</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Called By</th>
                                    <th>Date</th>
                                    <th>Duration</th>
                                    <th className="text-right">Actions</th>
                                    <th className="text-right" style={{width: 300}}>Audio</th>
                                </tr>
                            </thead>

                            <tbody>
                            {call_logs && call_logs.length ? call_logs.map(log => (
                                <tr key={log._id}>
                                    <td className="text-capitalize">
                                        <div>{renderName(log.user)}</div>
                                        <div>{log.user ? formatPhone(log.user.phone) : '-'}</div>
                                    </td>
                                    <td>{this.getTime(log)}</td>
                                    <td>{secondsToTime(log.duration ? log.duration : log.CallDuration)}</td>
                                    <td className="text-right">
                                        {log.deleted || log.RecordingUrl === 'disabled'? (
                                            '-'
                                        ) : (
                                            <a target="_blank" rel="noopener noreferrer" download className="btn btn-success" href={log.recording_url ? log.recording_url : log.RecordingUrl+ '.mp3?Download=false'}>Download</a>
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {log.deleted ? (
                                            'Deleted'
                                        ) : log.RecordingUrl === 'disabled' ? (
                                            'N/A'
                                        ) : (
                                            <audio style={{width: '100%'}} controls controlsList="nodownload" src={log.recording_url ? log.recording_url : log.RecordingUrl+ '.mp3?Download=false'}>
                                            Your browser does not support the
                                            <code>audio</code> element.
                                        </audio>
                                        )}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No call logs have been found for this contact.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                            </tbody>

                        </table>
                    </div>

                </Card>

                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">Voicemail</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>Date</th>
                                    <th style={{width: 300}}>Audio</th>
                                    <th className="text-right" >Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {voicemail && voicemail.length ? voicemail.map(voicemail => (
                                    <tr key={voicemail._id}>
                                        <td className="text-capitalize">
                                            <div>{renderName(voicemail.contact)}</div>
                                            <div>{voicemail.contact ? formatPhone(voicemail.contact.phone) : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{moment.unix(voicemail.created_at).format('MMM Do, YYYY - h:mm A')}</div>
                                            <div>{secondsToTime(voicemail.recording_duration)}</div>
                                        </td>
                                        <td className="text-right">
                                            <audio style={{width: '100%'}} controls controlsList="nodownload" src={voicemail.recording_url+ '.mp3?Download=false'}>
                                                Your browser does not support the
                                                <code>audio</code> element.
                                            </audio>
                                        </td>
                                        <td className="text-right">
                                            {voicemail.listened ? (
                                                <button 
                                                    style={{width: 140}}
                                                    onClick={() => onMarkVoicemailListened(voicemail._id, false)} 
                                                    className="btn btn-outline-success "
                                                >
                                                    Mark Un-Listened
                                                </button>
                                            ) : (
                                                <button 
                                                    style={{width: 140}}
                                                    onClick={() => onMarkVoicemailListened(voicemail._id, true)} 
                                                    className="btn btn-outline-warning "
                                                >
                                                    Mark Listened
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>There are no un-listened voicemails</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>

                </Card>

                </>

            ) : null

        )

    }

}

CallLogs.propTypes = {
    navPills    : PropTypes.number.isRequired,
    call_logs   : PropTypes.array,
}

export default CallLogs;
