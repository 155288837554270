import React from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import Feed from '../../../views/dashboard/pages/cases/View/components/Feed';

import Bankruptcy from './Bankruptcy'
import Roadside from './Roadside'

import moment from 'moment';

const StepsToPrep = ({divisions, _case, contact, workflows}) => {

    const workflow = _case && workflows ? workflows.find(w => w._id === _case.original_workflow) : null;
    
    return (
        <>
     
            <div className="archk-steps-to-prep-header-sm border-bottom">
                <p className="text-sm mb-0"><small>
                    {_case.division ? <span><b className="text-uppercase text-dark">DIVISION:</b> {divisions.find(d => d._id === _case.division).name}</span>: null}
                    <b className="text-uppercase text-dark pl-3">Step:</b> {_case.current_step ? _case.current_step.name : <span>None</span>}{' '}
                    {_case.current_step_start ? <span> <b className="text-uppercase text-dark pl-3">Step Start:</b> {moment.unix(_case.current_step_start).fromNow()}</span> : ''}
                </small></p>
            </div>

            <div className="archk-steps-to-prep-main">
                <Row style={{height: 'calc(100%)'}}> 
                    <div className="col-auto col-left" >
                        <Feed
                            renderNameAndInitial={true}
                            ignoreHeight={true}
                            case={{
                                _id: _case._id,
                                contact: {
                                    _id: contact._id
                                }
                            }}
                        />
                    </div>
                
                    <Col className="col-right" >
                        {workflow.type === 'Bankruptcy' || workflow.type === 'Bankruptcy - V1' ? (
                            <Bankruptcy 
                                _case={_case}
                                contact={contact}
                                workflow={workflow}
                            />
                        ) : workflow.type === 'Roadside' ? (
                            <Roadside 
                                _case={_case}
                                contact={contact}
                                workflow={workflow}
                            />
                        ) : null}
                        
                    </Col>

                </Row>
            </div>

        </>
    )
}


const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires.questionnaires,
        divisions: state.divisions.divisions,
        workflows: state.workflows.workflows,
        missing_docs: state.missing_docs.missing_docs,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(StepsToPrep);