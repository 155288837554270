/*
Documentation

This file handles a click and drag on the main canvas element showing
a page of the updated PDF. used to show the user a select and then
shoots back the start and end of this to whatever function is calling this on.

@param mainCanvas is node representing the page we want to watch for dragging
@param cb is the callback that gets on object of start and end coordinates

*/

module.exports = (mainCanvas, cb) => {

    let overlayCanvas;
    let ctx;

    // offset of canvas
    let offsetX;
    let offsetY;

    // width and height of page
    let width;
    let height

    let startX;
    let startY;
    let isDrawing;

    mainCanvas.addEventListener('mousedown', (e) => handleMouseDown(e, mainCanvas))

    // create a canvas on top of the page we are viewing and grab the 
    // start position. then listen for movement and the release of the 
    // mouse so we know where we are drawing
    const handleMouseDown = (e, mainCanvas) => {

        overlayCanvas = createAndReturnOverlayCanvas(mainCanvas)
        ctx = overlayCanvas.getContext("2d");

        const offset = mainCanvas.getBoundingClientRect()

        offsetX   = offset.x
        offsetY   = offset.y
        width     = offset.width
        height    = offset.height

        startX = parseInt(e.clientX - offsetX);
        startY = parseInt(e.clientY - offsetY);
            
        overlayCanvas.style.cursor = "crosshair";		
        isDrawing = true

        overlayCanvas.addEventListener('mousemove', (e) => handleMouseMove(e))
        overlayCanvas.addEventListener('mouseup', (e) => handleMouseUp(e))

    }

    // draw a rectangle on the canvas so a user can 
    // see what they are selecting
    const handleMouseMove = (e) => {
        if (isDrawing) {

            var mouseX = parseInt(e.clientX - offsetX);
            var mouseY = parseInt(e.clientY - offsetY);
            
            ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
            ctx.beginPath();
            ctx.strokeStyle = "#94b218";
            ctx.fillStyle = "rgba(148, 178, 24, .5)";
            ctx.fillRect(startX, startY, mouseX - startX, mouseY - startY);

            ctx.rect(startX, startY, mouseX - startX, mouseY - startY);
            ctx.stroke();
            
        }
    }

    // when mouse is released grab the start and end position of
    // the box drawn and sent it back to the callback
    // destroy the canvas we are drawing on
    const handleMouseUp = (e) => {

        var endX = parseInt(e.clientX - offsetX);
        var endY = parseInt(e.clientY - offsetY);

        isDrawing = false;
	    mainCanvas.style.cursor = "default";	

        overlayCanvas.parentNode.removeChild(overlayCanvas)

        const left = startX < endX ? startX : endX;
        const right = startX >= endX ? startX : endX;

        const top = startY < endY ? startY : endY;
        const bottom = startY >= endY ? startY : endY;

        // prevent accidental clicks
        if((right - left) < 3) return
        if((bottom - top) < 3) return

        cb({ left, right, top, bottom, pageWidth: width, pageHeight: height, pageOffsetY: offsetY})

    }

}

// create a canvas that we can draw on to show the users
// selection as they move their mouse
const createAndReturnOverlayCanvas = (mainCanvas) => {

    const parent = mainCanvas.parentNode;

    const newCanvas = document.createElement('canvas');

    newCanvas.id = mainCanvas.getAttribute('id') + '-cover';
    
    newCanvas.style.position    = 'absolute';
    newCanvas.style.left        = 0;
    newCanvas.style.right       = 0;
    newCanvas.style.top         = 0;
    newCanvas.style.bottom      = 0;
    newCanvas.style.zIndex      = 100;

    newCanvas.setAttribute('height', mainCanvas.getAttribute('height'))
    newCanvas.setAttribute('width', mainCanvas.getAttribute('width'))

    parent.appendChild(newCanvas)

    return document.getElementById(mainCanvas.getAttribute('id') + '-cover')

}