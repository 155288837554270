/*
Documentation

renders a card within the document merge body that can be dragged around

*/

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { UncontrolledTooltip } from 'reactstrap';

import PdfThumbnail from './PdfThumbnail'

import onDownloadDocument, { getImageAsBase64  } from '_functions/documents/download'


export default  (params) => {


    const { doc, index, onMoveDoc, onDeletePage, onLoadedDocument, loaded, onCardClick, togglePreview } = params
    const { selectedDocuments,  runMoveChange, onDragstart, onDragAccept, onDragReject, onRotateImage, rotatedDocuments } = params

    const isSelected = selectedDocuments[doc._id + doc.page] !== undefined
    const isPDF = doc.url.includes('.pdf')

    const [src, setSrc] = useState(doc.src ? doc.src : '');


    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'card',
        hover(item, monitor) {
            if (!ref.current) return 

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            // Time to actually perform the action
            onMoveDoc(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;

            runMoveChange(item)

        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id: doc._id + doc.page, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            onDragstart()
        },
        end: (item, monitor) => {
            const result = monitor.getDropResult()

            if(result && result.dropEffect === 'move') {
                onDragAccept(item);
            } else {
                onDragReject()
            }
        }
    });

    const loadDocument = useCallback(() => {
        if(doc.src) return;
        onDownloadDocument(doc._id, (err, data) => {
            if(isPDF) {
                doc.src = data;
                setSrc(data)
            } else {
                doc.src = data;
                setSrc(getImageAsBase64(data))
            }
        })
    }, [doc._id])

    useEffect(() => {
        loadDocument()
    }, [loadDocument])

    drag(drop(ref));

    let rotation = rotatedDocuments[doc._id + doc.page]
    rotation = rotation ? rotation : 0;

    if(!src) return <></>;

    return (

        <div 
            className={isSelected ? "merge-card merge-card-selected" : "z-depth- merge-card"} 
            ref={ref} 
            style={{ borderColor: doc.color, display: loaded ? 'inline-block' : 'none'}}
        >
            <div style={{display: loaded ? 'block' : 'none'}}>
                
                {doc.condensedDocuments && isPDF ? (
                    <div className="merge-header merge-header-condensed" onMouseUp={(e) => onCardClick(e, index, doc)}  >
                        <p className="text-sm mb-0 text-white">CONDENSED</p>
                    </div>
                ) : (
                    <div className="merge-header">
                        <p className="text-sm mb-0" onMouseUp={(e) => onCardClick(e, index, doc)}>Page: {index + 1}</p>
                    </div>
                )}

                <div className="merge-preview" onMouseUp={(e) => onCardClick(e, index, doc)} >

                    <div className="merge-tint" style={{background: doc.color}} />
                    {isPDF ? (
                        <PdfThumbnail rotation={rotation} doc={doc} docID={`pdf_thumbnail_${doc._id + doc.page}`} onLoadedDocument={onLoadedDocument} />
                    ) : (
                        <img style={{maxWidth: '100%', transform: `rotate(${rotation}deg)`, borderRadius: 6, border: 'solid 1px #ddd'}} src={src} alt="..."  />
                    )}
                </div>

                <div className="merge-footer z-depth-2" onMouseUp={(e) => onCardClick(e, index, doc)} >
                    <p id={`tooltip-${doc._id + doc.page}`} className="text-sm mb-0">{doc.friendly_name.length > 20 ? doc.friendly_name.slice(0,20) + '...' : doc.friendly_name}</p>
                    <UncontrolledTooltip delay={0} placement="top" target={`tooltip-${doc._id + doc.page}`}>
                        {doc.friendly_name}
                    </UncontrolledTooltip>
                </div>

                <i className="fas fa-trash text-danger merge-delete-icon " onClick={() => onDeletePage(index)} />
                <i className="fas fa-plus text-success merge-expand-icon " onClick={() => togglePreview(index)} />
                
                <i className="fas fa-chevron-circle-left merge-rotate-left-icon " onClick={() => onRotateImage(doc, -90, index)} />
                <i className="fas fa-chevron-circle-right merge-rotate-right-icon " onClick={() => onRotateImage(doc, 90, index)} />

            </div>
        </div>
        
    )
};
 