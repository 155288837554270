/*
Documentation

renders the header of the merge component showing first page
of each document trying to be merged

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'reactstrap';

import PdfThumbnail from './PdfThumbnail'

class MergeDocumentsSelector extends React.Component {

    render() {

        const { originalDocuments, condensedDocuments, toggleMergedDoc } = this.props;

        return (

            <Container className="pt-3 merge-selector bg-white border-right" fluid>

                <div className="merge-wrapper" >

                    <h3 className="" style={{ borderBottom: '1px solid rgb(206, 212, 218)', paddingLeft: 15, marginBottom: 15, marginLeft: -15, marginRight: -15, paddingBottom: 9,}} >Documents To Merge</h3>

                    {originalDocuments.map(doc => {

                        doc = Object.assign({page: 1}, doc)
                        
                        const isPDF = doc.url.includes('.pdf')
                        const isPacked = isPDF && condensedDocuments.some(d => d._id === doc._id)

                        return (
                            <div key={doc._id} className="z-depth-1 merge-card">

                                {isPDF ? (
                                    isPacked ? (
                                        <div onClick={() => toggleMergedDoc(doc)} className="merge-pack-status merge-is-packed">Unpack</div>
                                    ) : (
                                        <div onClick={() => toggleMergedDoc(doc)} className="merge-pack-status merge-is-unpacked">Condense</div>
                                    )
                                ) : null}
                                    
                                <div className="merge-preview">

                                    <div className="merge-tint" style={{background: doc.color}} />
                                    {isPDF ? (
                                        <PdfThumbnail doc={doc} docID={`pdf_selector_${doc._id + doc.page}`} />
                                    ) : (
                                        <img style={{maxWidth: '100%', borderRadius: 6, border: 'solid 1px #ddd'}} src={doc.url} alt="..."  />
                                    )}
                                </div>

                                <div className="merge-footer z-depth-2">
                                    <p className="text-sm mb-0">{doc.friendly_name.length > 20 ? doc.friendly_name.slice(0,20) + '...' : doc.friendly_name}</p>
                                </div>

                            </div>

                        )
                    })}

                </div>

            </Container>

        );
    }
}

MergeDocumentsSelector.propTypes = {
    originalDocuments   : PropTypes.array.isRequired,
    condensedDocuments  : PropTypes.array.isRequired,
    toggleMergedDoc     : PropTypes.func.isRequired,
}

export default MergeDocumentsSelector
