import PropTypes from 'prop-types';
import React from "react";
import { UncontrolledTooltip } from 'reactstrap';

import ModalUpdateName from '../Modals/UpdateName'

import { getMarkerPositionOnPage, getMarkerIcon, getMarkerName } from '../_functions/utils'

class CanvasMarkers extends React.Component {

    state = {
        loaded: false,
        showModal: false,
        marker: {},
        i: 0
    }

    getToolbarClass = (marker, type) => {
        return marker.type === type ? "list-style-none d-inline-block active bg-dark" : "list-style-none d-inline-block bg-dark"
    }

    toggleModal = (marker, index) => this.setState({marker, index, showModal: !this.state.showModal})

    onSetTextField = (marker, i) => {

        this.props.setMarkerType(i, 'text')
        this.toggleModal(marker, i)

    }

    render() {

        const { setMarkerType, removeMarker, updateMarkerName } = this.props

        const markers = [...this.props.markers];
        const container = document.getElementById('pdf-canvas')

        const { index, marker, showModal } = this.state

        if(!container || !markers.length) return <div />

        return (
            <div>

                {markers.map((marker, i) => {


                    const style = {
                        position    : 'absolute',
                        ...getMarkerPositionOnPage (marker)
                    }

                    return  (
                        <span key={marker.top.toString() + marker.left.toString()} data-marker-index={i} className="pdf-marker z-depth-2" style={style} >

                            <div style={{width: marker.right - marker.left, overflow: 'hidden'}} className="marker-name">{getMarkerName(marker)}</div>

                            <div className="position-relative">
                                <span className="toolbar" style={{ minWidth: '100%', left: marker > 500 ? 'auto' : 0, right: marker > 500 ? 0 : 'auto', }}>
                                    <ul className="pl-0 mb-0">
                                        <li
                                            id="archk-canvas-toolbar-text"
                                            onClick={() => this.onSetTextField(marker, i)}
                                            className={this.getToolbarClass(marker, 'text')}
                                        >
                                            <i className="fas fa-text-width" />
                                            <UncontrolledTooltip delay={0} placement="top" target="archk-canvas-toolbar-text" >
                                                Text Field
                                            </UncontrolledTooltip>
                                        </li>
                                        <li
                                            id={`archk-canvas-toolbar-date-${marker.right}-${marker.left}`}
                                            onClick={() => setMarkerType(i, 'date')}
                                            className={this.getToolbarClass(marker, 'date')}
                                        >
                                            <i className="fas fa-calendar" />
                                            <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-date-${marker.right}-${marker.left}`} >
                                                Date Field
                                            </UncontrolledTooltip>
                                        </li>
                                        <li
                                            id={`archk-canvas-toolbar-initial-${marker.right}-${marker.left}`}
                                            onClick={() => setMarkerType(i, 'initial')}
                                            className={this.getToolbarClass(marker, 'initial')}
                                        >
                                            <i className="fas fa-signature" />
                                            <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-initial-${marker.right}-${marker.left}`} >
                                                Initial Field
                                            </UncontrolledTooltip>
                                        </li>
                                        <li
                                            id={`archk-canvas-toolbar-signature-${marker.right}-${marker.left}`}
                                            onClick={() => setMarkerType(i, 'signature')}
                                            className={this.getToolbarClass(marker, 'signature')}
                                        >
                                            <i className="fas fa-file-signature" />
                                            <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-signature-${marker.right}-${marker.left}`} >
                                                Signature Field
                                            </UncontrolledTooltip>
                                        </li>
                                        <li
                                            id={`archk-canvas-toolbar-remove-${marker.right}-${marker.left}`}
                                            onClick={() => removeMarker(i)}
                                            className={this.getToolbarClass(marker, 'delete')}
                                        >
                                            <i className="fas fa-trash text-danger" />
                                            <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-remove-${marker.right}-${marker.left}`} >
                                                Remove Field
                                            </UncontrolledTooltip>
                                        </li>
                                    </ul>
                                </span>
                            </div>

                            <div className="position-relative h-100">
                                {marker.type ? (
                                    <span className="identifier z-depth-3 text">
                                        {getMarkerIcon(marker)}
                                    </span>
                                ) : null}
                            </div>

                        </span>
                    )

                })}

                <ModalUpdateName 
                    toggleModal={() => this.toggleModal({}, 0)}
                    showModal={showModal}
                    updateMarkerName={updateMarkerName}
                    name={marker.name}
                    index={index}
                />

            </div>
        )
        
    }
}

CanvasMarkers.propTypes = {
    markers             : PropTypes.array.isRequired,
    setMarkerType       : PropTypes.func.isRequired,
    removeMarker        : PropTypes.func.isRequired,
    updateMarkerName    : PropTypes.func.isRequired,
}

export default CanvasMarkers
