import React, { Component } from 'react';
import AllInvoices from './AllInvoices';
import ViewInvoice from './ViewInvoice';
import * as privileges from '_functions/users/privileges';

class Invoices extends Component {


    state = {
        showModal: false,
        invoiceToShow: null
    };

    //this is fired on creating new invoice, set invoice obj to empty
    toggleModal = () => this.setState({showModal: !this.state.showModal, invoiceToShow: null})

    //fired when we are viewing an invoice
    setInvoice = (invoiceToShow) => {
        this.setState({invoiceToShow, showModal: true})
    }

    render() {

        const _case = this.props.case
        const {  showModal, invoiceToShow } = this.state

        const canUpdateFinances = privileges.canUpdateFinances()

        return (

            <>

                <ViewInvoice
                    showModal={showModal}
                    toggle={this.toggleModal}
                    invoiceToShow={invoiceToShow}
                    case={_case}
                    setCreatedInvoice={(invoice) => this.setState({invoiceToShow: invoice})}
                    onInvoiceSent={(sent_invoice) => this.props.onInvoiceSent(sent_invoice)}

                />

                <AllInvoices
                    case={_case}
                    setInvoice={(invoiceToShow) => this.setInvoice(invoiceToShow)}
                />

                {canUpdateFinances ? (
                    <div className="text-right">
                        <button className="btn btn-success" onClick={this.toggleModal}>Create Invoice</button>
                    </div>
                ) : null }


            </>

        )

    }

}

export default Invoices;
