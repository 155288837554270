/*
Documentation

this file renders the card to assign, add, and revoke users on the case

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Badge, Card, CardHeader, CardTitle, Col, Row } from "reactstrap";
import renderName from 'utils/renderName';
import AvatarImage from 'components/functional/images/AvatarImage';
import { connect } from 'react-redux';
import { canUpdateCases } from '_functions/users/privileges';

import ModalAddRole from './ModalAddRole'
import ModalAssignUser from './ModalAssignUser'
import ModalChangeLead from './ModalChangeLead'
import ModalRemoveUser from './ModalRemoveUser'

class Roles extends Component {

    state = {
        role: null,
        showModalAddRole: false,
        showModalChangeLead: false,
        showModalRemoveUser: false,
        showModalAssignUser: false,
    };

    toggleModalAddRole    = () => this.setState({showModalAddRole: !this.state.showModalAddRole})
    toggleModalChangeLead = () => this.setState({showModalChangeLead: !this.state.showModalChangeLead})
    toggleModalRemoveUser = (role) => this.setState({showModalRemoveUser: !this.state.showModalRemoveUser, role})
    toggleModalAssignUser = (role) => this.setState({showModalAssignUser: !this.state.showModalAssignUser, role})

    createUserRow = (roles) => {

        const markup = []

        const canUpdate = canUpdateCases();

        roles.forEach((role, i) => {

            let content;

            // a small bug was found where backend would not pass back in the 
            // populated users object to case on certain updates. If that is the 
            // case we can match up the users here to avoid an problems
            if(role.user && !role.user._id) {
                role.user = this.props.users.find(u => u._id === role.user);
            }

            if(role.user) {

                content = (
                    <tr key={role.user._id + role.role}>
                        <td className="text-capitalize">
                            {canUpdate ? (
                                <i
                                    onClick={() => this.toggleModalRemoveUser(role)}
                                    className="fas fa-trash text-danger"
                                    style={{cursor: 'pointer', display: 'inline-block', width: 25,}}
                                />
                            ) : null}
                            <AvatarImage
                                src={role.user ? role.user.avatar : ''}
                                style={{position: 'relative', top: -2}}
                                className="mr-2"
                            />
                            {renderName(role.user)}
                        </td>
                        <td className="text-right text-capitalize">
                            {role.role}
                        </td>
                    </tr>
               )

               markup.push(content)

            } else {

                markup.push(
                    <tr key={i}>

                        <td>
                            {canUpdate ? (
                            <Badge className="cursor-pointer" onClick={() => this.toggleModalAssignUser(role)}  color="warning" pill>
                                <i className="fas fa-exclamation-triangle mr-2" />
                                Assign User
                            </Badge>
                            ) : null}
                        </td>

                        <td className="text-right">{role.role}</td>

                    </tr>
                )

            }

        })

        return markup

    }

    render() {

        const { showModalAddRole, showModalAssignUser, showModalChangeLead, showModalRemoveUser, role } = this.state
        const { navPills } = this.props;

        if(navPills !== 1 ) return null

        const _case = this.props.case;
        const canUpdate = canUpdateCases();

        return (

            <>

            <Card className="card-color card-yellow">

                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <CardTitle className="mb-0">Users On Case</CardTitle>
                        </Col>
                        <Col md={6} className="text-right">
                            {canUpdate ? (
                                <button onClick={this.toggleModalAddRole} className="btn btn-success btn-s text-center"> Add Role </button>
                            ) : null}

                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive employees-table">
                    <table className="table">

                        <thead>
                            <tr>
                                <td className="font-weight-bold">Name</td>
                                <td className="text-right font-weight-bold">Case Roles</td>
                            </tr>
                        </thead>

                        <tbody>
                            {/* <tr key="lead">
                                <td className="text-capitalize">

                                    {canUpdate ? (
                                        <i
                                            onClick={this.toggleModalChangeLead}
                                            className="fas fa-edit text-success"
                                            style={{cursor: 'pointer', display: 'inline-block', width: 25,}}
                                        />
                                    ) : null}

                                    {_case.lead_attorney ? (
                                        <AvatarImage
                                            src={_case.lead_attorney.avatar}
                                            style={{position: 'relative', top: -2}}
                                            className="mr-2"
                                        />
                                    ) : null}

                                    {_case.lead_attorney ? renderName(_case.lead_attorney) : "Not Set"}
                                </td>
                                <td className="text-right text-capitalize">
                                    Lead Attorney
                                </td>
                            </tr> */}

                            {_case.roles && _case.roles.length ? (
                                 this.createUserRow(_case.roles)
                            ) : (
                                <tr>
                                    <td>There are no roles for this case.</td>
                                    <td></td>
                                </tr>
                            )}

                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>
                </div>

            </Card>

            <ModalAddRole
                case={_case}
                showModal={showModalAddRole}
                toggleModal={this.toggleModalAddRole}
            />
            <ModalAssignUser
                case={_case}
                showModal={showModalAssignUser}
                toggleModal={this.toggleModalAssignUser}
                role={role}
            />
            <ModalChangeLead
                case={_case}
                showModal={showModalChangeLead}
                toggleModal={this.toggleModalChangeLead}
            />
            <ModalRemoveUser
                case={_case}
                showModal={showModalRemoveUser}
                toggleModal={this.toggleModalRemoveUser}
                role={role}
            />

            </>

        )

    }

}

Roles.propTypes = {
    case        : PropTypes.object.isRequired,
    navPills    : PropTypes.number.isRequired,
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(Roles);

