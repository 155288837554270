/*
Documentation

this is the main file for our pdf merging functionality

*/

import React from 'react';
import { Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { setDocumentsForMerge } from 'store/functions/system/system'

import _documents from '_functions/documents';
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import update from 'immutability-helper';

import Circle from 'components/markup/loading/Circle'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


import Card from './Card'
import Preview from './Preview'
import Sidebar from './Sidebar'
import Selector from './Selector'

import scrolling from './_functions/scrolling'
import loadDocument from './_functions/loadDocument'
import getColor from './_functions/getColor'

import * as footer from 'utils/footer';
import { resetMemory } from 'utils/pdf/controller'

import onDownloadDocument, { getImageAsBase64  } from '_functions/documents/download'


import './_merge.scss'

class MergeDocuments extends React.Component {

    state = {
        loadingTasks        : [],
        documents           : [],
        fallbackDocuments   : [],
        deletedDocuments    : [],
        originalDocuments   : [],
        condensedDocuments  : [],
        selectedDocuments   : {},
        rotatedDocuments    : {},
        loaded: false,
        documentsReady: 0,
        showPreview: false,
        previewIndex: 0,
        hideSelected: undefined
    }

    onDragstart = () => {
        this.setState({fallbackDocuments: [...this.state.documents] })
    }

    onDragAccept = (item) => {
        this.runMoveChange(item, true)
        this.setState({fallbackDocuments: [] })
    }

    onDragReject = () => {
        this.setState({fallbackDocuments: [], selectedDocuments: {}, documents: [...this.state.fallbackDocuments]})
    }

    runMoveChange = (item, removeSelected) => {
        const indexEnded = item.index

        let documents = [...this.state.documents]
        let selectedDocuments = this.state.selectedDocuments

        const endedDoc = documents[indexEnded]
        const keys = Object.keys(selectedDocuments);

        let newDocuments    = []
        let movedDocuments  = []
        let movedIndex      = 0;
        let finalIndex      = 0;

        documents.forEach((d, index) => {

            // document is ready to move
            if(keys.includes(d._id + d.page)) {
                movedDocuments.push(d)
            } else {
                newDocuments.push(d)
                if(!finalIndex) movedIndex++
            }

            if(d._id + d.page === endedDoc._id + endedDoc.page) finalIndex = movedIndex

        })

        movedDocuments.sort((a, b) => selectedDocuments[a._id + a.page] < selectedDocuments[b._id + b.page] ? -1 : 1)

        if(finalIndex < 0) {
            newDocuments.splice(0,0, ...movedDocuments)
        } else {
            newDocuments.splice(finalIndex, 0, ...movedDocuments)
        }

        this.setState({documents: newDocuments, selectedDocuments: removeSelected ? {} : this.state.selectedDocuments})

    }

    onCardClick = (e, index, doc) => {

        const docID = doc._id + doc.page
        const shift = e.metaKey || e.shiftKey
        const documents = [...this.state.documents];

        let selectedDocuments = Object.assign({}, this.state.selectedDocuments)

        // if shift keys is not pressed either set the document clicked
        // to be the only only once selected or if its selected remove it
        if(!shift) {
            if(selectedDocuments[docID] !== undefined) {
                selectedDocuments = {}
            } else {
                selectedDocuments = {[docID]: index}
            }

            return this.setState({selectedDocuments})
        }

        // if shift is clicked and we have clicked a doc that is
        // already selected remove it from selection
        const keys = Object.keys(selectedDocuments)

        if(keys.includes(docID)) {

            delete selectedDocuments[docID];
            return this.setState({selectedDocuments})


        }

        // if we have clicked shift and nothing is selected set this to be
        // the only document selected
        if(!keys.length) {
            selectedDocuments = {[docID]: index}
            return this.setState({selectedDocuments})
        }

        const startIndex = selectedDocuments[keys[0]]
        let indexCounter = startIndex

        selectedDocuments = {}

        // if clicking to the left select all documents left of the
        // start index going backward
        if(startIndex < index) {

            while(indexCounter <= index) {

                const document = documents[indexCounter]
                selectedDocuments[document._id + document.page] = indexCounter
                indexCounter++

            }

        // if clicking to the right select all documents from the 
        // start index going to the right
        } else {

            while(indexCounter >= index) {
                const document = documents[indexCounter]
                selectedDocuments[document._id + document.page] = indexCounter
                indexCounter--
            }

        }

        return this.setState({selectedDocuments})

    }

    // set the index of the document to show the updated order
    onMoveDoc = (dragIndex, hoverIndex) => {
        
        let documents = [...this.state.documents]
        const dragCard = documents[dragIndex];

        documents = update(documents, {
            $splice: [ [dragIndex, 1], [hoverIndex, 0, dragCard], ],
        })

        this.setState({documents})

    }

    onMerge = async (friendly_name) => {
        
        const case_id = this.props.match.params.case_id
        const documents = [...this.state.documents]
        const { rotatedDocuments } = this.state

        // delete the pdfDoc of of each document before sending this causes
        // a circular loop in json and the request fails if not done
        documents.forEach(d => { 
            delete d.pdfDoc 
            if(d.condensedDocuments) d.condensedDocuments.forEach(dd => { delete dd.pdfDoc })
        })

        // add a period, server handles appending the file extensions
        friendly_name = friendly_name + '.'

        toggleStandardLoader(true)

        const created = await _documents.merge({ documents, case_id, friendly_name, rotatedDocuments })

        toggleStandardLoader(false)

        // if this succeeds direct the user back to the case page and 
        // let them know the name of the final document to check for
        if(created.success) {

            toggleAlertBS(false, `Your document is being merged as ${friendly_name}pdf. It will appear on this page after it has been processed.`)
            this.setState({shouldRedirect: `/dashboard/cases/view/${case_id}?nav=3`})

        }

    }


    // when a page is deleted take it out of the main documents
    // array and store it in deletedDocument in case it needs
    // to be restored in the future
    onDeletePage = (index) => {

        let documents = [...this.state.documents]
        let deletedDocuments = [...this.state.deletedDocuments]
        
        deletedDocuments.push(Object.assign({}, documents[index]))

        documents.splice(index, 1)

        this.setState({documents, deletedDocuments})

    }

    // bring the document back from deleted storage
    onRestorePage = (doc) => {

        let documents = [...this.state.documents]
        let deletedDocuments = [...this.state.deletedDocuments]
        
        documents.unshift(Object.assign({}, doc))

        deletedDocuments = deletedDocuments.filter(d => d._id + d.page !== doc._id + doc.page)

        this.setState({documents, deletedDocuments})

    }

    // set pdf pages from a pdf and create a new document for each one
    getPdfPages = (documents) => {
        
        documents = [...documents];

        let finalDocs = []

        documents.sort((a,b) => a.order < b.order ? -1 : 1)
        let order = 0

        documents.forEach(doc => {


            if(doc.url.includes('.pdf')) {

                let pageInDocument = 0

                while(pageInDocument < doc.pdfDoc.numPages) {

                    finalDocs.push({
                        ...Object.assign({}, doc),
                        pageOrder: order, // page order in editor
                        page: pageInDocument + 1
                    })

                    pageInDocument++; order++;

                }


            } else {
                doc.pageOrder = order

                finalDocs.push(doc)
                order++

            }


        })

        const originalDocuments = [...this.state.originalDocuments];
        const finishedDocuments = []

        // for each document push an array of condensedDocuments to it
        // this starts out the editor with everything in a small size
        originalDocuments.forEach(doc => {
            finishedDocuments.push(Object.assign({
                page: 1,
                condensedDocuments: [...finalDocs.filter(d => d._id === doc._id)],
                ...doc
            }))
        })

        this.setState({documents: finishedDocuments, condensedDocuments: finishedDocuments}, this.checkLoadedDocuments)

    }


    // opens up our "lighthouse" preview
    togglePreview = (index) => {

        const showPreview = !this.state.showPreview

        // stop page from scrolling or allow it to scroll again
        if(showPreview) {
            document.body.classList.add('noScroll')
        } else {
            document.body.classList.remove('noScroll')
        }

        this.setState({showPreview, previewIndex: index})

    }

    // runs whenever a pdf loads on initial rendering
    onLoadedDocument = () => {
        this.setState({ documentsReady: this.state.documentsReady + 1 }, this.checkLoadedDocuments)
    }

    // check to see if we are done loading documents
    checkLoadedDocuments = () => {
        if(this.state.documentsReady >= this.state.documents.length) {
            this.setState({loaded: true})
        }
    }

    toggleMergedDoc = (doc) => {

        let documents           = [...this.state.documents]
        let condensedDocuments  = [...this.state.condensedDocuments]

        const docIndex               = documents.findIndex(d => d._id === doc._id)
        const foundCondensedDocument = condensedDocuments.find(d => d._id === doc._id)

        // pack the document to condense it
        if(!foundCondensedDocument) {

            let packedDocuments = [...documents.filter(d => d._id === doc._id)]
            let filteredDocuments = [...documents.filter(d => d._id !== doc._id)]

            condensedDocuments.push({_id: doc._id, condensedDocuments: packedDocuments })
            filteredDocuments.splice(docIndex, 0, {...doc, condensedDocuments: packedDocuments})

            this.setState({documents: filteredDocuments, condensedDocuments})

        // unpack document
        } else {

            documents.splice(docIndex, 1, ...foundCondensedDocument.condensedDocuments)
            condensedDocuments = [...condensedDocuments.filter(d => d._id !== doc._id)]

            this.setState({documents, condensedDocuments})

        }

    }

    onRotateImage = (doc, rotation, index) => {

        const rotatedDocuments = Object.assign({}, this.state.rotatedDocuments)
        const r = rotatedDocuments[doc._id + doc.page]

        let rotate = r ? r + rotation : rotation
        if(rotate === -90) rotate = 270;
        if(rotate === 360) rotate = 0;

        rotatedDocuments[doc._id + doc.page] = rotate

        this.setState({rotatedDocuments})
        
    }

    getImage = (doc) => new Promise (async resolve => {
    
        onDownloadDocument(doc, (err, data) => {
            if(data) return resolve(getImageAsBase64(data));
            return resolve(null)
        })
    
    })

    componentWillUnmount = () => {

        document.body.classList.remove('noScroll')
        footer.show();        

        this.state.loadingTasks.forEach(task => {
            try {
                task.destroy();
            } catch(e) {}
        })

        resetMemory();

        document.body.style.height = 'auto'
        document.body.style.overflow = 'auto'

    }

    componentDidMount = () => {

        document.body.style.height = 'calc(100vh - 88px)'
        document.body.style.overflow = 'hidden'

        scrolling.addEventListenerForSidebar();

        footer.hide();        

        const documents = [...this.props.documents_for_merge]

        setDocumentsForMerge([])

        let imageCounter  = 0
        let pdfCounter    = 0

        const loadingTasks = []

        // if no documents sent for merge redirect back to the case page
        if(!documents.length) return this.setState({shouldRedirect: `/dashboard/cases/view/${this.props.match.params.case_id}?nav=3`})

        documents.forEach(async (doc, i) => {

            delete doc.bucket;
            delete doc.case;
            delete doc.color;
            delete doc.contact;
            delete doc.bucket;
            delete doc.color;
            delete doc.created_at;
            delete doc.thumbnail_url;
            delete doc.thumbnail_object_key;
            delete doc.updated_at;
            delete doc.user;

            if(doc.url.includes('.pdf')) {

                const pdfDoc = await loadDocument(doc);
                doc.src = pdfDoc.src;
                doc.order  = i;
                doc.pdfDoc = pdfDoc.pdfDoc;
                doc.color  = getColor(pdfCounter);

                loadingTasks.push(pdfDoc.loadingTask)
                pdfCounter++

            } else {

                const url = await this.getImage(doc);
                doc.url = url;

                imageCounter++

            }

            if(documents.length === (imageCounter + pdfCounter)) {
                this.setState({originalDocuments: [...documents], documentsReady: imageCounter, loadingTasks }, () => {
                    this.getPdfPages(documents)
                })
            }

        })

    }

    render() {

        const { documents, deletedDocuments, originalDocuments, condensedDocuments, shouldRedirect } = this.state
        const { loaded, documentsReady, showPreview, previewIndex, selectedDocuments, rotatedDocuments } = this.state

        if(shouldRedirect) return <Redirect to={shouldRedirect} />

        return (

            <div className="merge">

               <Sidebar 
                    deletedDocuments={deletedDocuments}
                    onRestorePage={this.onRestorePage}
                    onMerge={this.onMerge}
               />

                <Selector 
                    originalDocuments={originalDocuments}
                    condensedDocuments={condensedDocuments}
                    toggleMergedDoc={this.toggleMergedDoc}
                />

               <Preview 
                    showPreview={showPreview}
                    previewIndex={previewIndex}
                    documents={documents}
                    rotatedDocuments={rotatedDocuments}
                    togglePreview={() => this.togglePreview()}
                    onRotateImage={this.onRotateImage}

               />

                <div className="merge-main">

                    <Container className="py-3 border-bottom bg-white" fluid>

                        <Row className="">

                            <Col xs={6} className="align-self-center">
                            </Col>

                            <Col xs={6} className="align-self-center text-right">
                                <Link 
                                    className="btn btn-outline-success" 
                                    style={{height: 26}}
                                    to={`/dashboard/cases/view/${this.props.match.params.case_id}?nav=3`}
                                >
                                    <i className="fas fa-arrow-left mr-2" /> Back To Case
                                </Link>
                            </Col>

                        </Row>
                    </Container>

                        <Container id="merge-container-master"  className="py-4 text-center merge-dnd-container" fluid>

                            <DndProvider backend={HTML5Backend}>
                                    {documents.map((doc, i) => (
                                        <Card 
                                            key={doc._id + doc.page}  
                                            doc={doc} index={i}  
                                            onMoveDoc={this.onMoveDoc} 
                                            onDeletePage={this.onDeletePage} 
                                            onLoadedDocument={this.onLoadedDocument} 
                                            selectedDocuments={selectedDocuments} 
                                            loaded={loaded} 
                                            onCardClick={this.onCardClick}
                                            togglePreview={this.togglePreview}
                                            runMoveChange={this.runMoveChange}
                                            onDragAccept={this.onDragAccept}
                                            onDragReject={this.onDragReject}
                                            onDragstart={this.onDragstart}
                                            onRotateImage={this.onRotateImage}
                                            rotatedDocuments={rotatedDocuments}
                                        />
                                    ))}
                            </DndProvider>

                            {!loaded ? (
                                <>
                                    <Circle />
                                    <p className="text-sm text-center">Loading pages {documentsReady}/{documents.length} </p>
                                </>
                            ) : null }
                        </Container>
                       </div>


            </div>
                  
        );
    }
}

const mapStateToProps = state => {
    return {
    	documents_for_merge: state.system.documents_for_merge,
    };
};

export default connect(mapStateToProps, '')(MergeDocuments);
