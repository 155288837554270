/*
Documentation

This file renders a modal to either create or update a garnishment
Simply it takes 1 field: name

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, FormGroup } from 'reactstrap';
import _filing_fees from '_functions/filing_fees';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const required_form_fields = [
    'amount',
    'installment',
    'show_cause',
]

class ModalFilingFeesCrud extends React.Component {

    state = {
        filing_fee: {},
        can_save: true,
        has_made: false
    }

    onSave = async () => {

        this.setState({can_save: false, error_message: null})
        //copy state
        let newState = Object.assign({}, this.state.filing_fee);
        //assume we have no errors
        let errors = 0;

        if(!newState.installment) newState.installment = 1;
        if(!newState.show_cause) newState.show_cause = 'no';

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ filing_fee: newState });

        if(!errors) {


            let action

            if(newState._id) {

                action = await _filing_fees.update(newState._id, newState)

            } else {

                action = await _filing_fees.create({
                    ...newState,
                    case              : this.props.case._id,
                    contact           : this.props.case.contact._id,
                })

            }

            if(action.success) { this.props.onSuccess(action.data) }

        }

        this.setState({can_save: true})

    }

    componentDidMount = () => {

        if(this.props.filing_fee && this.props.filing_fee._id) {
            this.setState({filing_fee: this.props.filing_fee})
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.filing_fee && nextProps.filing_fee._id) {

            const filing_fee = Object.assign({}, nextProps.filing_fee)
            this.setState({filing_fee})

        } else {

            this.setState({filing_fee: {}})

        }

        // if modal is toggled off clear the filing_fee
        if(this.props.showModal && !nextProps.showModal) this.setState({ filing_fee: {} })

    }

    render() {

        const { filing_fee, error_message, can_save } = this.state;
        const { showModal, toggleModal } = this.props;

        return (
        <>

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {filing_fee._id ? 'Update Filing Fee'  : 'Create A New Filing Fee'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={filing_fee}
                        objName='filing_fee'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="installment"
                        title="Installment Number"
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={filing_fee}
                        objName='filing_fee'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="number"
                        field="amount"
                        title="Amount"
                    />

                    <StandardFormGroup
                        obj={filing_fee}
                        objName='filing_fee'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="show_cause"
                        title="Show Cause"
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </StandardFormGroup>

                    {filing_fee.show_cause === 'yes' ? (
                        <FormGroup>
                            <label className="form-control-label">Show Cause Date</label>
                            <DatePicker
                                selected={filing_fee.show_cause_date ? moment(filing_fee.show_cause_date).toDate() : undefined}
                                onChange={date => this.setState({filing_fee: {...Object.assign(filing_fee), show_cause_date: date}})}
                            />
                        </FormGroup>
                    ) : null}

                    <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <DatePicker
                            selected={filing_fee.due_date ? moment(filing_fee.due_date).toDate() : undefined}
                            onChange={date => this.setState({filing_fee: {...Object.assign(filing_fee), due_date: date}})}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Paid Date</label>
                        <DatePicker
                            selected={filing_fee.paid_date ? moment(filing_fee.paid_date).toDate() : undefined}
                            onChange={date => this.setState({filing_fee: {...Object.assign(filing_fee), paid_date: date}})}
                        />
                    </FormGroup>
                   
                    <StandardFormGroup
                        obj={filing_fee}
                        objName='filing_fee'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="textarea"
                        field="notes"
                        title="Notes"
                    />

                    {error_message ? (
                        <p className="mb-0 text-sm font-weight-bold text-danger">{error_message}</p>
                    ) : null}

                </div>

                <div className="modal-footer">
                    <button  disabled={!can_save}  onClick={this.onSave}  className="btn btn-success">
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                </div>

            </Modal>

        </>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ModalFilingFeesCrud.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    onSuccess     : PropTypes.func.isRequired,
    case          : PropTypes.object.isRequired,
    filing_fee    : PropTypes.object,
}

export default connect(mapStateToProps, '')(ModalFilingFeesCrud);
