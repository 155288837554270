/*
Documentation

This is basically a popup, on hover we show a larger
area of content, if not hovered we truncate the content

*/

import React, { Component } from 'react';

class ArchkTableExpansion extends Component {

    componentDidMount = () => {

        const content = this.refs.content
        const wrapper = this.refs.wrapper
        const teaser = this.refs.teaser

        // when we enter open up the content
        wrapper.onmouseenter = () => {
            content.style.display = 'inline'
            teaser.style.display = 'none'
        }

        // when we leave close the content
        wrapper.onmouseleave = () => {
            content.style.display = 'none'
            teaser.style.display = 'inline'
        }

        // when content is entered set the width
        content.onmouseenter = () => {

            // set this to get the full width of the block without text wrapper
            content.style.whiteSpace = 'pre'

            // account for css with + 16px
            const width = content.offsetWidth < 300 ? content.offsetWidth + 16 : 300

            // return to original whiteSpace and set the width
            content.style.width = width + 'px'
            content.style.whiteSpace = 'normal'

        }

    }

    render() {

        const { teaser, content } = this.props

        return (

            <div className="archk-table-expansion" ref="wrapper"> 

                <span className="archk-table-expansion-teaser"  ref="teaser">{teaser}</span>
                <span className="archk-table-expansion-content" ref="content">{content}</span>

            </div>

        )

    }

}

export default ArchkTableExpansion;
