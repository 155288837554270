/*
Documentation

this file renders a modal allowing users to set their status
this status can then be viewed by admin in the call_center

*/

import React from "react";
import { Modal, FormGroup, Input } from "reactstrap";

import { connect } from 'react-redux';
import _user from '_functions/users';
import moment from 'moment';

class SetStatus extends React.Component {

    state = {
        user_set_location: this.props.viewing_user.user_set_location || '',
        showModal: false,
    }

    onSetLocation = () => {

        let user_set_location = this.state.user_set_location
        
        if(!user_set_location) return;
        if(user_set_location === 'null') return;
        if(user_set_location === 'home') user_set_location = null;

        if(user_set_location !== 'not working') {
            _user.update(this.props.viewing_user._id, { user_set_location })
        }
        
        this.setState({showModal: false})
        if(this.props.onClose) this.props.onClose();

    }

    componentWillReceiveProps = (nextProps) => {
        if(!this.props.showModal && nextProps.showModal) {
            this.setState({showModal: true})
        }
    }

    componentDidMount = () => {

        // if this is false it means the modal can be called outside of the main 
        // component rendering in the dashboard layouts. This only matters if a status has 
        // never been set however this will prevent the modal from firing multiple times
        if(this.props.showModal === false) return;

        const { user_set_location_time } = this.props.viewing_user
        const startOfDay = parseInt(moment().startOf('day').format('X'))

        if(user_set_location_time < startOfDay) {
            this.setState({showModal: true});
        }

    }

    render() {

        const { offices }  = this.props
        const { showModal } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => {}}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Set Location</h5>
                </div>

                <div className="modal-body">
                    <FormGroup>
                        <label className="form-control-label">Where are you working from today?</label>
                        <Input 
                            value={this.state.user_set_location}
                            onChange={(e) => this.setState({user_set_location: e.target.value})}
                            type="select"
                        >
                            <option value="null"></option>
                            <option value="home">Home</option>
                            <option value="not working">Not Working</option>

                            {offices.map(office => (
                                <option key={office._id} value={office._id} >{office.name} Office</option>
                            ))}

                            <option value="clock out">***Set My Status As Not Active</option>

                        </Input>
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    <button onClick={this.onSetLocation} className="btn btn-success">Set My Location</button>
                </div>

            </Modal>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user    : state.auth.viewing_user,
        offices   : state.offices.offices,
    };
};

export default connect(mapStateToProps, '')(SetStatus);

