
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardHeader, CardTitle } from "reactstrap";
import { formatMoney } from 'utils/currency';
import compileTimeLog from '../functions/compileTimeLog';

class TimeLog extends React.Component {

    state = {}

    columns = [
        {
            dataField: "name",
            text: "Task Name",
        },
        {
            dataField: "labor_billable",
            text: "Amount To Bill",
            formatter: (cell, row) => (
                <span>
                    {formatMoney(row.labor_billable / 100)}
                    {row.finished_by ?  (
                        <>
                            <br />
                            <span className='text-muted'>{formatMoney(row.finished_by.billable_rate / 100)} / hour</span>
                        </>
                    ) : null}
                </span>
            )
        },
        {
            dataField: "labor_cost",
            text: "Internal Cost",
            formatter: (cell, row) => (
                <span>
                    {formatMoney(row.labor_cost / 100)}
                    {row.finished_by ?  (
                        <>
                        <br />
                        <span className='text-muted'>{formatMoney(row.finished_by.hourly_rate / 100)} / hour</span>
                        </>
                    ) : null}
                </span>
            )
        },
        {
            dataField: "task_profit",
            text: "Task Profit",
            formatter: (cell, row) => (
                <b className={row.task_profit > 0 ? 'text-success' : row.task_profit < 0 ? 'text-danger' : 'text-primary'}>
                    {formatMoney(row.task_profit / 100)}
                </b>
            )
        },
        {
            dataField: "minutes",
            text: "Time",
            formatter: (cell, row) => (
                <span>
                    {row.minutes} Minutes <br />
                    <span className={
                        row.minutes > row.default_billable_minutes ?
                            'text-danger font-weight-bold' :
                            row.minutes < row.default_billable_minutes ?
                            'text-success font-weight-bold' : 'text-muted'
                        }>
                       Expected: {row.default_billable_minutes}
                    </span>
                </span>
            )
        },
        {
            dataField: "finished_by",
            text: "Finished By",
            formatter: (cell, row) => {

                if(row.finished_by) {
                    return (
                        <span>
                            {row.finished_by.given_name + ' ' + row.finished_by.family_name} <br />
                        </span>
                    )
                }

                return 'User Not Found'
            }

        },

        {
            dataField: "finished",
            text: "Date",

            formatter: (cell, row) => (
                <span>
                    {moment.unix(row.finished).format("M/D/YYYY")} <br />
                    at {moment.unix(row.finished).format("h:mm A")}
                </span>
            )
        },

    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const timeLogs = compileTimeLog(this.props.case_workflow)

        return (
            <>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Case Time Log</CardTitle>
                    </CardHeader>

                    <ToolkitProvider
                        data={timeLogs}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <div className=" table-responsive table-vertical-align">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>

                </Card>
            </>
        );
    }
}

export default TimeLog

