/*
Documentation

this file renders the Overview row of a case.

This is the content that appears on the home page right about parties,
case_alerts, and feed

*/

import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import TagsUpdater from 'views/dashboard/pages/_components/TagsUpdater';
import Dots from 'components/markup/loading/Dots'
import renderName from 'utils/renderName';
import moment from 'moment'
import * as privileges from '_functions/users/privileges'

import _garnishments from '_functions/garnishments';
import _objections from '_functions/objections';

import _contacts from '_functions/contacts';

import ModalUpdate from '../ModalUpdate'

class CaseOverview extends Component {

    state = {
        newCase: {},
        last_payment: null,
    };

    isLoading = false;

    // splits objections into two categories, open and finished.
    // this is a count to be rendered as a number
    splitObjections = (objections) => {

        let open = 0
        let finished = 0

        if(objections) {

            objections.forEach(objection => {

                if(objection.resolved_at) finished++
                open++

            })

        }

        return { open, finished }

    }

    getOpenTasks = (case_workflow) => {

        return <span className={case_workflow.tasks_unfinished.length ? "text-warning" : "text-success"}>{case_workflow.tasks_unfinished.length}</span>

    }

    onSaveTags = async (tags) => {
        await _contacts.update(this.props.case.contact._id, { tags })
        this.props.getCase({hideRecentActivity: true})
    }

    fetchDocuments = async (props) => {

        if(props.navPills === 1 && !this.isLoading) {

            // set loading so we dont make a db call more than we have to if new props are passed in while we are fetching
            this.isLoading = true

            if(!this.state.garnishments) {
                const garnishments = await _garnishments.find(`?filter=case__${this.props.case._id}`)

                //if success and we already have state data due a socket request combine the data here, if not just add the data we got
                if(garnishments.success) {

                    let data = garnishments.data

                    if(this.state.garnishments) { data = this.state.garnishments.concat(data) }

                    this.setState({garnishments: data})

                }
            }

            if(!this.state.objections) {
                const objections = await _objections.find(`?filter=case__${this.props.case._id}`)

                //if success and we already have state data due a socket request combine the data here, if not just add the data we got
                if(objections.success) {

                    let data = objections.data

                    if(this.state.objections) { data = this.state.objections.concat(data) }

                    this.setState({objections: data})

                }
            }

            

            this.isLoading = false

        }

    }

    componentWillReceiveProps = (nextProps) => this.fetchDocuments(nextProps)
    componentDidMount = () => this.fetchDocuments(this.props)

    render() {

        const _case = this.props.case;
        const { case_workflow, navPills, toggleModal} = this.props
        let { garnishments, objections, last_payment, showModal } = this.state;

        const { open, finished } = this.splitObjections(objections)

        if(!last_payment) last_payment = {}

        if(navPills !== 1 ) return null

        return (

            <>

            <Card>

                <CardHeader className="d-none d-lg-block">
                    <Row>
                        <Col lg={4}>
                            <CardTitle className="mb-0">Contact Tags</CardTitle>
                        </Col>
                        <Col lg={4}>
                            <CardTitle className="mb-0">Contact Details</CardTitle>
                        </Col>
                        <Col lg={4}>
                            <CardTitle className="mb-0">Case Details</CardTitle>
                        </Col>
                    </Row>

                </CardHeader>

                <CardBody>

                    <Row>

                        <Col lg={4}>
                            <h4 className="d-block d-lg-none mb-0">Case Tags</h4>
                            <TagsUpdater
                                keyValue={_case._id}
                                current_tags={_case.contact.tags}
                                onSave={this.onSaveTags}
                                disabled={!privileges.canUpdateCases()}
                            />
                        </Col>

                        <Col lg={4}>

                            <h4 className="d-block d-lg-none mb-0 mt-4">Contact Details</h4>

                            <p className="text-sm mb-0">
                                <b style={styles.descriptor}>Times Contacted: </b> {_case.contact.times_contacted}
                            </p>

                            <p className="text-sm mb-0 text-capitalize">
                                <b style={styles.descriptor}> Last Contact By:</b>
                                {_case.contact.last_contact_by ? renderName(_case.contact.last_contact_by) : '-'}
                            </p>

                            <p className="text-sm mb-0">
                                <b style={styles.descriptor}> Last Contact:</b>
                                {_case.contact.last_contact ? moment.unix(_case.contact.last_contact).format('MM/DD/YYYY - h:mm A') : '-'}

                            </p>

                            <p className="text-sm mb-0">

                                <b style={styles.descriptor}> Payment Status:</b>

                                {last_payment.status === 'failed' ?     <b className="text-danger mt-3" ><i className="fas fa-exclamation-triangle mr-1" /> Failed</b> : null}
                                {last_payment.status === 'refunded' ?   <b className="text-warning mt-3" ><i className="fas fa-exclamation mr-1" /> Refunded</b> : null}
                                {last_payment.status === 'succeeded' ?  <b className="text-success mt-3" ><i className="fas fa-check mr-1" /> Succeeded</b> : null}

                            </p>

                        </Col>

                        <Col lg={4}>

                            <h4 className="d-block d-lg-none mb-0 mt-4">Case Details</h4>

                            <p className="text-sm mb-0">
                                <b style={styles.descriptor}>Objections: </b>
                                <span className={finished === open ? "text-success font-weight-bold" : "text-warning font-weight-bold"}>
                                    {objections ? (
                                        objections.length ? ( `${finished}/${open}` ) : ( '0/0' )
                                    ): (
                                        <Dots />
                                    )}
                                </span>
                            </p>

                            <p className="text-sm mb-0">
                                <b style={styles.descriptor}> Garnishments:</b>
                                <span className="text-warning font-weight-bold">
                                    {garnishments ? ( garnishments.length ): <Dots />}
                                </span>
                            </p>

                            <p className="text-sm mb-0">
                                <b style={styles.descriptor}> Open Step Tasks:</b>
                                <span className="font-weight-bold">
                                    {case_workflow ? this.getOpenTasks(case_workflow) : <Dots /> }
                                </span>
                            </p>

                            <button
                                onClick={toggleModal}
                                className="btn btn-success mt-3"
                                disabled={!privileges.canUpdateCases()}
                            >
                                <i className="fas fa-edit mr-2 " />
                                Edit Case Details
                            </button>

                        </Col>

                    </Row>

                </CardBody>

                <ModalUpdate
                    case={_case}
                    showModal={showModal}
                    toggleModal={toggleModal}
                />

            </Card>

            </>

        )

    }

}

const styles = {

    descriptor: {

        display: 'inline-block',
        width: 150,
        fontWeight: 'bold'
    }

}

CaseOverview.propTypes = {
    case            : PropTypes.object.isRequired,
    navPills        : PropTypes.number.isRequired,
    garnishments    : PropTypes.array,
    objections      : PropTypes.array,
    case_workflow   : PropTypes.object,
    toggleModal   : PropTypes.func.isRequired,
}

export default CaseOverview;
