import React, { Component } from 'react';
import { Input } from 'reactstrap'

import PropTypes from 'prop-types'

class SendEmailSubject extends Component {

    render() {

        const { subject, onSubjectChange } = this.props

        return (

            <div className="subject-controller">

                <span className="subject-title">Subject: </span>

                <Input
                    type="text"
                    value={subject || ''}
                    onChange={(e) => onSubjectChange(e.target.value)}
                />

           </div>

        )

    }

}

SendEmailSubject.propTypes = {
    onSubjectChange   : PropTypes.func.isRequired,
    subject           : PropTypes.string,
}

export default SendEmailSubject
