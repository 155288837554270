import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { connect } from 'react-redux'
import _cases from '_functions/cases'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'

import { formatMoney, passesDecimalCheck } from 'utils/currency';

class TrustMakePayment extends React.Component {

    state = {
        trustChange: {
            value: 0,
            type: 'add'
        },
        error: false
    }

    onSubmit = async () => {

        this.setState({error: false})

        let { value, type } = this.state.trustChange;
        // reverse value if it is supposed to be negative
        if(type === 'subtract') value = value * -1
        // set the value to a true number
        value = parseFloat(value);
        // if the value is not a true number or has more than 2 decimal points return
        if(!value || Number.isNaN(value) || !passesDecimalCheck(value)) {
            return this.setState({error: true})
        }

        toggleStandardLoader(true)

        const updated = await _cases.updateTrust(this.props.case._id, { billing_trust: value })

        toggleStandardLoader(false)

        if(updated.success) {
            toggleAlertBS(false, `Trust amount adjusted successfully.`)
        } else {
            toggleAlertBS(true, updated.user_message ? updated.user_message : `An error occurred logging this payment.`)
        }

        this.setState({trustChange: {type: 'add', value: 0} })

    }


    render() {

        const { trustChange, error } = this.state;

        const { billing_in_trust, billing_paid, billing_total, billing_trust } = this.props.case

        return (
            <>

                <h2 className="display-5"><i className="fas fa-hand-holding-usd mr-2 text-danger" />Adjust Future Trust Payment</h2>

                <div className="table-responsive">
                    <table className="table bg-secondary">

                        <tbody>
                            <tr>
                                <td>Amount Paid On Case: </td>
                                <td className="text-right">{formatMoney(billing_paid)}</td>
                            </tr>
                            <tr>
                                <td>Total For Case:</td>
                                <td className="text-right"> {formatMoney(billing_total)}</td>
                            </tr>
                            <tr>
                                <td>Amount In Trust: </td>
                                <td className="text-right">{formatMoney(billing_in_trust)}</td>
                            </tr>
                            <tr>
                                <td>Future Amount Going To Trust: </td>
                                <td className="text-right">{formatMoney(billing_trust)}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>

                <div className="mt-3">

                    <StandardFormGroup
                        obj={trustChange}
                        objName="trustChange"
                        onChange={(o, f, v) => this.setState({trustChange: {value: trustChange.value, type: v}})}
                        type="select"
                        field={'type'}
                        title={'Add or subtract future trust amount *'}
                    >
                        <option value="add">Add</option>
                        <option value="subtract">Subtract</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={trustChange}
                        objName="trustChange"
                        onChange={(o, f, v) => this.setState({trustChange: {value: v, type: trustChange.type}})}
                        type="number"
                        field={'value'}
                        title={(
                            <span>
                                The amount to{' '}
                                {trustChange.type === 'subtract' ? (
                                    <span className="text-danger">SUBTRACT</span>
                                ): (
                                    <span className="text-success">ADD</span>
                                )}
                                {' '} to future payments going into trust.
                            </span>
                        )}
                    />

                    <hr />

                    {error ? (
                        <div className="alert alert-danger">The below amount must be a number with no more than 2 decimal places.</div>
                    ) : null}

                    <div className="text-right">
                        <button onClick={this.onSubmit} disabled={!trustChange.value} className="btn btn-success">Adjust Trust</button>
                    </div>

                </div>


            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(TrustMakePayment);

