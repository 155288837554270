/*
Documentation

Renders the contact search on the billing center sidebar

*/

import PropTypes from 'prop-types';
import React from "react";
import { Card, CardHeader } from "reactstrap";
import ContactSearch from 'views/dashboard/pages/_components/ContactSearch';

class SearchContacts extends React.Component {

    render() {

        return (
            <>

                <Card>

                    <CardHeader>
                        <h3 className="mb-0">Select A Contact</h3>
                        <p className="mb-0 text-sm">To view billing information for a specific contact find them in the list below.</p>
                    </CardHeader>

                    <ContactSearch
                        sizePerPage={10}
                        onSelect={(contact) => this.props.onSelectContact(contact)}
                    />

                </Card>

            </>
        );
    }
}

SearchContacts.propTypes = {
    onSelectContact: PropTypes.func.isRequired,
}

export default SearchContacts
