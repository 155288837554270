/*

this file renders the header that stays static across the top of the case view page
If we are on the home page it also renders the blue banner at the top of the page.
Note that the banner should be moved to the home page when time allows

*/

import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Col, Container, Row } from "reactstrap";
import { formatMoney } from 'utils/currency';
import Navigation from './Navigation';
import renderName from 'utils/renderName';
import { connect } from 'react-redux';
import AvatarImage from 'components/functional/images/AvatarImage';
import { formatPhone } from 'utils/text'
import { toggleBillingCenter } from 'store/functions/system/system'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import ModalUpdate from '../HomePage/ModalUpdate'

const actionStepLink = 'https://us-east-1.actionstep.com/mym/asfw/workflow/action/overview/action_id/'

class Header extends Component {

    state = {
        showModal: false,
    }

    widthToScrollTo = 0;

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    renderSteps = () => {

        const case_workflow = this.props.case_workflow
        const step_to_show = this.props.step_to_show
        const recommended_steps_tree = case_workflow.recommended_steps_tree

        if(!case_workflow) { return <div></div> }

        let markup = [];
        let trackerWidth = 0

        // push history to tracker however don't add in the current step if it is in the history
        let trackerArray = [...case_workflow.history.filter(a => case_workflow.current_step && a.step_id !== case_workflow.current_step._id)]

        if(case_workflow.current_step) {
            trackerArray.push(Object.assign({}, case_workflow.current_step))
        }

        // if we have recommended steps use them, else use possible next step
        if(recommended_steps_tree && recommended_steps_tree.length) {
            trackerArray = trackerArray.concat([...recommended_steps_tree])
        } else {
            if(case_workflow.current_step_status) {
                trackerArray = trackerArray.concat([...case_workflow.current_step_status.possible_move_to_steps])
            }
        }

        trackerArray.forEach((s, i) => {

            let name = s.name ? s.name : s.step_name

            if(name) {

                 // set the width for the step
                const width = (name.length * 9) + 55
                const is_current_step = case_workflow.current_step && s._id === case_workflow.current_step._id ? true : false
                const is_main_step = s._id === step_to_show ? true : false

                // globally add the width so we have it to set on the tracker
                trackerWidth += width
                // set the scroll width so we know where to scroll to
                if(is_current_step) this.widthToScrollTo = trackerWidth

                markup.push((
                    <li
                        style={{width}}
                        key={i}
                        onClick={async () => {
                            await this.props.setClickedStep(s)
                            this.props.setNav(2)
                        }}
                        className={is_current_step ? "active step cursor-pointer" : is_main_step ? "main-step step cursor-pointer" : "step cursor-pointer" }
                    >
                        {/* <div className="prompt" onClick={() => this.props.setStepToShow(s._id)}>{name }</div> */}
                        <div className="prompt">{name }</div>
                    </li>
                ))

            }

        })

        return (
            <ul style={{width: !markup.length ? trackerWidth + 220 : trackerWidth }}>
                {markup}
                {!markup.length ? (
                    <li
                        style={{width: 220}}
                        className={this.props.case.finished_on ? "step bg-success cursor-pointer" : "step bg-primary cursor-pointer"}
                        onClick={() => this.props.setNav(2)}
                    >
                        <div className="prompt text-white">
                            {this.props.case.finished_on ? (
                                <i className="fas fa-check mr-2" />
                            ) : null}

                            Case Finished
                        </div>
                    </li>
                ) : null}
            </ul>
        )

    }

    getLeadAttorney = () => {
        const lead_attorney = this.props.case.roles.find(role => role.role === 'Attorney') || {};
        return lead_attorney && lead_attorney.user ? lead_attorney.user : {};
    }

    componentWillReceiveProps = (nextProps) => {

        // only do this on the first time we get the case_workflow props
        if(nextProps.case_workflow && nextProps.case_workflow.steps && !this.props.case_workflow) {

            // set timeout to allow markup to render then scroll the tracker
            setTimeout(() => {
                const el = document.getElementById('case-step-tracker')
                if(el) el.scrollTo({top: 0, left: this.widthToScrollTo - 550, behavior: 'smooth'})
            }, 1000)
        }

    }

    render() {

        const _case = this.props.case
        const { navPills, setNav, device, duplicateCases } = this.props
        const contact = _case.contact ? _case.contact : {}
        const lead_attorney = this.getLeadAttorney();

        const screenUnder1000 = device.info.currentScreenWidth < 1000

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <>

            <div className="bg-white pt-2 mb-2" style={styles.caseHeader}>

                <Container className="pt-3 case-header" fluid>

                    <Row>

                        <Col lg={9} className="align-self-center tag-wrapper">
                            <div className="mb-3 tag-inner">

                                {duplicateCases > 1 ? (
                                    <a href={`/dashboard/contacts/view/${_case.contact._id}?nav=1`} target="_blank" rel="noopener noreferrer">
                                        <Badge color="danger" className="text-sm mb-2 mr-2">
                                            <i className="fas fa-exclamation mr-2" />POSSIBLE DUPLICATE
                                        </Badge>
                                    </a>
                                ) : null}

                                {contact.action_step_case_id ? ( 
                                    <a
                                        href={`${actionStepLink}${contact.action_step_case_id}`} 
                                        target="_blank" 
                                        rel="noreferrer noopener" 
                                    >
                                        <Badge color="success" className="text-sm mb-2">
                                            <i className="fas fa-door-open mr-2" />From Action Step
                                        </Badge>
                                    </a> ) : null}

                                {_case.chapter ? ( 
                                    <Badge color="danger" className="text-sm mb-2">
                                        <i className="fas fa-gavel mr-2" />
                                        {_case.chapter}
                                    </Badge> 
                                ) : null}

                                {_case.contact.tags && _case.contact.tags.length ? _case.contact.tags.map(badge => (
                                    <Badge key={badge._id} color="warning" className="mb-2 text-sm">
                                        <i className="fas fa-user mr-2" />{badge.name}
                                    </Badge>
                                )) : null}
                                {_case.tags && _case.tags.length ? _case.tags.map(badge => (
                                    <Badge key={badge._id} color="warning" className="mb-2 text-sm">
                                        {badge.name}
                                    </Badge>
                                )) : null}

                             </div>
                        </Col>

                        <Col lg={3} className="text-right align-self-center">
                            <Navigation
                                case={_case}
                                navPills={navPills}
                                setNav={setNav}
                            />
                        </Col>

                    </Row>


                </Container>

                {/* {navPills === 1 ? ( */}
                <div className="bg-primary z-depth-2">
                    <Container className="pb-3" fluid>

                        <Row>
                            <Col xs={12}>
                                <h2 className="display-5 pt-3 text-white mb-0 font-weight-bold text-capitalize">
                                    <span onClick={() => setNav(1)} className="cursor-pointer">
                                        {_case.name} {device.info.isMobile ? <br /> : '|'}  <span className="text-warning">ID:</span> <span className="text-success"> {_case._id}</span>
                                    </span>
                                </h2>
                                <hr className="my-2" style={{borderTop: 'solid 1px white' }}/>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg={3} sm={6}>
                                <div style={{...styles.cards, height: screenUnder1000 ? 'auto' : 100, marginBottom: screenUnder1000 ? 16 : 0, marginTop: screenUnder1000 ? 16 : 0 }}>

                                    <h2 className="text-uppercase text-yellow display-5 mb-0">
                                        <AvatarImage
                                            style={{width: 30, height: 30}}
                                            className="mr-3 z-depth-4"
                                            src={lead_attorney && lead_attorney.avatar ? lead_attorney.avatar: null}
                                        />
                                        LEAD Attorney
                                    </h2>

                                    {lead_attorney && lead_attorney._id ? (
                                        <div>
                                            <p className="mb--2 text-sm font-weight-bold text-capitalize">
                                                <Link className="text-white" to={`/dashboard/users/view/${lead_attorney._id}`}>
                                                    {renderName(lead_attorney)}
                                                </Link>
                                            </p>
                                            <p className="text-muted mb--2 text-sm">{lead_attorney.email}</p>
                                            <p className="text-muted mb-0 text-sm">{formatPhone(lead_attorney.phone)}</p>
                                        </div>
                                    ) : (
                                        <p className="mb--2 text-sm font-weight-bold text-capitalize text-white">None Assigned</p>
                                    )}

                                </div>
                            </Col>

                            <Col lg={3} sm={6}>

                                <div style={{...styles.cards, height: screenUnder1000 ? 'auto' : 100, marginBottom: screenUnder1000 ? 16 : 0 }}>

                                    <h2 className="text-uppercase text-warning display-5 mb-0">
                                        <i className="fas fa-user text-warning mr-2" />
                                        contact
                                    </h2>

                                    <p className="mb--2 text-sm font-weight-bold">
                                        <Link className="text-white text-capitalize" to={`/dashboard/contacts/view/${contact._id}`}>
                                            {renderName(contact)}
                                            {contact.nickname ? <span> - {contact.nickname}</span> : null}
                                        </Link>
                                    </p>

                                    {contact.email ? (
                                        <p className="text-muted mb--2 text-sm"><i className="text-white fas fa-envelope mr-3" />{contact.email}</p>
                                    ) : (
                                        <p className="text-muted mb--2 text-sm">Email Not Found</p>

                                    )}

                                    {contact.phone ? (
                                        <p className="text-muted mb-0 text-sm ">
                                            <span className="cursor-pointer  font-weight-bold" onClick={() => toggleStartCallModal(true, contact)} >
                                                <i className=" fas fa-headphones mr-2 text-white" />
                                                <span className="text-success">{formatPhone(contact.phone)}</span>
                                            </span>
                                        </p>
                                    ) : <p className="text-muted mb-0 text-sm">Phone Not Found</p>}

                                </div>
                            </Col>

                            <Col lg={3} sm={6}>
                                <div style={{...styles.cards, height: screenUnder1000 ? 'auto' : 100, marginBottom: screenUnder1000 ? 16 : 0 }}>

                                    <h2 className="text-uppercase text-success display-5 mb-0">
                                        <span onClick={() => toggleBillingCenter(true)} className="cursor-pointer">
                                            <i className="text-success fas fa-dollar-sign" /> Billing
                                        </span>
                                    </h2>

                                    <p className="text-white mb--2 text-sm font-weight-bold">
                                        Billing Type: {_case.billing_type ? _case.billing_type : 'not set'}
                                    </p>
                                    <p className="text-muted mb--2 text-sm text-capitalize">Paid On Case: {formatMoney(_case.billing_paid)}</p>
                                    <p className="text-muted mb-0 text-sm text-capitalize">Case Total {formatMoney(_case.billing_total)}</p>

                                </div>
                            </Col>

                            <Col lg={3} sm={6}>
                                <div style={{...styles.cards, height: screenUnder1000 ? 'auto' : 100, marginBottom: screenUnder1000 ? 16 : 0 }}>

                                    <h2 className="text-uppercase text-danger display-5 mb-0">
                                        <i className="text-danger fas fa-gavel" /> Case
                                    </h2>
                                    <p className="text-white mb--2 text-sm font-weight-bold text-capitalize">Opened: {moment.unix(_case.created_at).format('MM/DD/YYYY - h:mm A')}</p>
                                    {/* <p className="text-muted mb--2 text-sm"> Case ID: {' '}</p> */}
                                    <p className="text-muted mb--2 text-sm">Case Number: {' '} {_case.case_number ? _case.case_number : '-'}</p>
                                    <p className="text-muted mb-0 text-sm">Office: {' '} {_case.office ? _case.office.name : '-'}</p>

                                </div>
                            </Col>

                        </Row>

                        <hr className="my-2" style={{borderTop: 'solid 1px white' }}/>

                        <h2 className='mb-0 text-white text-capitalize'>Case Manager: {_case.case_manager ? renderName(this.props.users.find(u => u._id === _case.case_manager)) : <span className="text-danger">No Case Manager</span>} <i className="fas fa-edit ml-2 cursor-pointer " onClick={() => this.setState({showModal: !this.state.showModal})} /></h2>

                        

                    </Container>
                      {_case.finished_on && _case.finished_by ? (
                            <div className="alert alert-success mt-3 font-weight-bold mb-0">
                                <i className="fas fa-check mr-2" />
                                This case was finished by <span className="text-capitalize">{renderName(_case.finished_by)}</span> on {moment.unix(_case.finished_on).format('MMMM Do, YYYY - h:mm A')}
                            </div>
                        ) : null}



                </div>
                {/* ) : null} */}

                {this.props.postDocuments.length ? this.props.postDocuments.map(d => (
                      <div className="alert alert-warning mt-2">
                        {d.name}
                  </div>
                ))  : ''}

                <hr className="my-0"/>

                {!_case.finished_by ? (
                    <div>
                        {this.props.case_workflow ? (

                            <Container className="case-steps-container px-4" fluid>
                                <div className="case-steps"  id="case-step-tracker">
                                    {this.renderSteps()}
                                </div>
                            </Container>

                            ) : (
                            <div className="py-4">
                                <Circle />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="py-3"></div>
                )}

                <div className=" mb-4  case-navigation"></div>

            </div>

            <ModalUpdate
                case={_case}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
            />

            </>

        )

    }

}

const styles = {

    cards: {
        background: 'none',
        border: 'none',
        height: 100,
        boxShadow: 'none'
    },

    caseHeader: {
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2) '
    }

}

Header.propTypes = {
    setClickedStep  : PropTypes.func.isRequired,
    case            : PropTypes.object.isRequired,
    step_to_show    : PropTypes.string,
    case_workflow   : PropTypes.object,
}

const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	viewing_user: state.auth.viewing_user,
    	device: state.device,
    };
};

export default connect(mapStateToProps, '')(Header);
