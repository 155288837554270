const colors = [
    '#A7C81B', // green 
    '#1A426D', // teal  
    '#fb6340', // orange

    '#8965e0', // purple
    '#f5365c', // red   

    '#053A53', // blue  
    '#5603ad', // indigo
    '#f3a4b5', // pink  
    '#ffd600', // yellow
]

const getColor = (i) => {

    if(colors[i]) return colors[i]

    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#" + randomColor
}

export default getColor