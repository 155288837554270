import apiWorker from '../apiWorker'

export default {

    update: (data) =>  apiWorker('post', `/api/v1/core/activity/update/`, data, true),

    screen_watch: {
        getWatching:    ()     =>  apiWorker('get',  `/api/v1/core/activity/screen_watch/get_watching`, null, true),
        setWatching:    (data) =>  apiWorker('post', `/api/v1/core/activity/screen_watch/set_watching`, data, true),
        removeWatching: (data) =>  apiWorker('post', `/api/v1/core/activity/screen_watch/remove_watching`, data, true),
        sendScreen:     (data) =>  apiWorker('post', `/api/v1/core/activity/screen_watch/send_screen`, data, true),
        cancelEvents:   (data) =>  apiWorker('post', `/api/v1/core/activity/screen_watch/cancel_user_events`, data, true),
    }

}
