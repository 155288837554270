/*
Documentation

This component handles the finalization of data within the case creation process.
Upon finish is should validate the case creation and send of a post request to create it within the server

*/

import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row, Badge } from "reactstrap";
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import _cases from '_functions/cases';
import ModalCreateTemplate from './ModalCreateTemplate'
import { getUrlParameter } from 'utils/urls'
import { toggleStandardLoader } from 'store/functions/system/system'
import * as privileges from '_functions/users/privileges';
import { formatPhone } from 'utils/text';
import OfficeSearchInput from 'views/dashboard/pages/_components/OfficeSearchInput';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const required_form_fields = [
    'name',
]
class FinalizeDetails extends React.Component {

    state = {
        lead_attorney: {},
        case: {},
        showModal: false,
        office: null
    }

    renderBadge = (badge) => {

        const found_badge = this.props.tags.find(b => b._id === badge)

        if(found_badge) return (

            <Badge
                key={badge}
                onClick={() => this.onRemoveBadge(badge)}
                className="text-sm cursor-pointer"
                color="danger"
            >
                {found_badge.name}
                <i className="fas fa-times ml-2" />
            </Badge>

        )

        return null


    }

    onRemoveBadge = (tag_id) => {

        let tags = this.state.case.tags ? [...this.state.case.tags] : [];

        tags = tags.filter(badge => badge !== tag_id)

        this.setState({
            case: {
                ...this.state.case,
                tags
            }
        })

    }

    onAddTag = (tag_id) => {

        let tags = this.state.case.tags ? [...this.state.case.tags] : [];

        // dont push duplicates
        if(!tags.find( badge => badge === tag_id)) {

            tags.push(tag_id)
            this.setState({ case: { ...this.state.case, tags } })

        }

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.case);
        const value = e.target.value
        newState[stateName] = value;

        if (stateName === "checkbox") {
            if (value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState({ case: newState });


        const name              = stateName === 'name' ? value : getUrlParameter('name');
        const selected_workflow = getUrlParameter('selected_workflow');
        const selected_contact  = getUrlParameter('selected_contact');
        const selected_roles    = getUrlParameter('selected_roles');

        const url =
        window.location.pathname +
        '?selected_workflow=' + selected_workflow +
        '&selected_contact=' + selected_contact +
        '&selected_roles=' + selected_roles +
        '&name=' + name;

        window.history.pushState(null, null, url);

    };

    onShowModal = () => this.setState({showModal: true});
    onHideModal = () => this.setState({showModal: false});

    onSave = async () => {

        //copy state
        let newState = Object.assign({}, this.state.case);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        //remove new_password fields

        this.setState({ case: newState });

        if(!errors) {

            toggleStandardLoader(true)

            const { selected_workflow,  selected_contact,  selected_roles } = this.props
            const { office } = this.state;

            const created = await _cases.create({
                user: this.props.viewing_user._id,

                name: this.state.case.name,
                contact: selected_contact._id,
                workflow: selected_workflow._id,
                roles: selected_roles,
                office: office ? office._id : undefined,
            })

            toggleStandardLoader(false)

            if(created.success) {

                //redirect to the case that was just created
                this.setState({shouldRedirect: '/dashboard/cases/view/' + created.data.case._id});
                if(this.props.onCaseCreated) this.props.onCaseCreated(created.data.case)

                _analytics.events.create({event: ANALYTIC_EVENTS.CASES_CREATED});
         
            }

        }

    }

    componentDidMount = () => {

        const contact = this.props.selected_contact;
        const workflow = this.props.selected_workflow;

        if(contact && workflow) {

            // const defaultCaseName = contact.given_name + ' ' + contact.family_name + ' - ' + workflow.type
            let defaultCaseName;

            if(contact.given_name && contact.family_name) {
                defaultCaseName = contact.given_name + ' ' + contact.family_name;
            } else if(contact.given_name) {
                defaultCaseName = contact.given_name;
            } else if(contact.family_name) {
                defaultCaseName = contact.family_name;
            } else if(contact.email) {
                defaultCaseName = contact.email;
            } else if(contact.phone) {
                defaultCaseName = formatPhone(contact.phone)
            } else {
                defaultCaseName = 'Unknown';
            }

            defaultCaseName += ' - ' + workflow.type

            const name = getUrlParameter('name');
            const user = getUrlParameter('user');
            const court = getUrlParameter('court');
            const billing_type = getUrlParameter('billing_type');

            this.setState({
                lead_attorney: user ? this.props.users.find(u => u._id === user) : null,
                court: court ? this.props.location_courts.find(u => u._id === court) : null,
                case: {
                    ...this.state.case,
                    name: name ? name : defaultCaseName,
                    user: user ? user : null,
                    location_court: court ? court : null,
                    billing_type: billing_type ? billing_type : null,

                }
            })

        }

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const { selected_workflow,  selected_contact,  selected_roles, hideNavigation } = this.props
        const { showModal, office  } = this.state;

        const _case = this.state.case;

        const canModerateCaseTemplates = privileges.canModerateCaseTemplates()

        return (

            <div>

            <Card>

                <CardHeader className="bg-secondary mb-3">
                    <CardTitle className="mb-0">Contact Information</CardTitle>
                </CardHeader>



                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Name</h5>
                    <h5 className="font-weight-normal d-inline-block text-capitalize">
                        {selected_contact.given_name + ' ' + selected_contact.family_name}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Email</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {selected_contact.email ? selected_contact.email : '-'}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Phone</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {selected_contact.phone ? formatPhone(selected_contact.phone) : '-'}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                <CardHeader className="bg-secondary mb-3">
                    <CardTitle className="mb-0">Workflow Info</CardTitle>
                </CardHeader>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Name</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {selected_workflow.type ? selected_workflow.type : '-'}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Steps</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {selected_workflow.steps && selected_workflow.steps.length ? selected_workflow.steps.length: '-'}
                    </h5>
                </CardBody>

                <CardHeader className="bg-secondary mb-3">
                    <CardTitle className="mb-0">Case Info</CardTitle>
                </CardHeader>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Name</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {_case.name ? _case.name : '-'}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                <CardBody className="py-0">
                    <h5 style={{display: 'inline-block', width: 100}}>Office</h5>
                    <h5 className="font-weight-normal d-inline-block">
                        {office ? (
                            <div>
                                {office.name}
                                <i className="fas fa-trash ml-2 text-danger" onClick={() => this.setState({office: null})} />
                            </div>
                        ) : '-'}
                    </h5>
                </CardBody>

                <hr className="my-2"/>

                {selected_roles && selected_roles.length ? selected_roles.map((r, i) => (

                    <div key={i}>

                        <CardBody className="py-0">
                            <h5 style={{display: 'inline-block', width: 100}}>{r.role}</h5>
                            <h5 className="font-weight-normal d-inline-block">
                                {r.user ? r.user.given_name : null}
                                {r.user ? <span> {r.user.family_name}</span> : null}
                            </h5>
                        </CardBody>

                        <hr className="my-2"/>

                    </div>
                )) : (
                    <>

                        <CardBody className="py-0">
                            <h5 style={{display: 'inline-block', width: 100}}>Roles</h5>
                            <h5 className="font-weight-normal text-danger d-inline-block">
                                <b>No Roles Assigned</b>
                            </h5>
                        </CardBody>
                        <hr className="my-2"/>

                    </>
                )}

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Finalize Details</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup >
                        <label className="form-control-label" htmlFor="name" >
                            Case Name *
                        </label>
                        <Input
                            id="name"
                            value={_case.name || ''}
                            placeholder="Case Name"
                            type="text"
                            valid={ _case.nameState === "valid" }
                            invalid={ _case.nameState === "invalid" }
                            onChange={e => this.onInputChange(e, "name") }
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>

                    <OfficeSearchInput 
                        value={office}
                        onSelect={(office) => this.setState({office})}
                    />

                </CardBody>

                <CardFooter>
                    <Row>

                        <Col xs={6}>

                            {canModerateCaseTemplates && !hideNavigation ? (
                                <button className="btn btn-outline-success" onClick={this.onShowModal}>
                                    <i className="fas fa-check mr-2" />
                                    Create Template
                                </button>
                            ) : null}

                        </Col>

                        <Col xs={6} className="text-right">
                            <button className="btn btn-success" onClick={this.onSave}>Create Case</button>
                        </Col>

                    </Row>
                </CardFooter>
            </Card>

            <ModalCreateTemplate
                showModal={showModal}
                onHideModal={this.onHideModal}
            />

            </div>

        );
    }
}

FinalizeDetails.propTypes = {
    selected_contact: PropTypes.object.isRequired,
    selected_workflow: PropTypes.object.isRequired,
    selected_roles: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        location_courts: state.location_courts.location_courts,
        tags: state.tags.tags,
    };
};

export default connect(mapStateToProps, '')(FinalizeDetails);
