import AvatarImage from 'components/functional/images/AvatarImage';
import PropTypes from 'prop-types';
import React from 'react';
import renderName from 'utils/renderName';
import renderNameInitial from 'utils/renderNameAndInitial';
import FeedWrapper from './Wrapper';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import ReactHTMLParser from 'react-html-parser';

import { getEntryDate } from './_utils';


const RenderNote = (props) => {

    const { entry, renderNameAndInitial } = props;

    const now = Math.floor(new Date() / 1000);
    const isWithin24Hours = entry.created_at >= (now - 86400);
    const isFromUser = entry.user && entry.user._id === props.viewing_user._id;

    let value = entry.value;

    value = value.replace(/<p>/g, '<span class="d-block">')
    value = value.replace(/<\/p>/g, '</span>')

    return (

        <FeedWrapper>
            <h6 className="text-sm mb-0 font-weight-bold text-capitalize">
                {entry.user ? (
                    <span>
                        <AvatarImage 
                            style={{position: 'relative', top: -2, width: 30, height: 30}} 
                            src={entry.user.avatar} 
                            className="mr-2" 
                        />
                        {renderNameAndInitial ? renderNameInitial(entry.user) : renderName(entry.user)}
                    </span>
                ) : 'SYSTEM'}

                {isWithin24Hours && isFromUser ? (
                    <i 
                        onClick={() =>  props.toggleModal(true, entry)}  
                        className="fas ml-2 fa-edit text-warning cursor-pointer" 
                    />
                ) : (
                    <i className="fas ml-2 fa-user text-success" />
                )}

                

                <span className="float-right">
                    {entry.updated_at && entry.updated_at !== entry.created_at ? (
                        <Badge color="danger" className="mr-2">Edited</Badge>
                    ) : null}
                    <span style={{position: 'relative', top: 3}}>
                    {getEntryDate(entry)}
                    </span>
                </span>
            </h6>

            <hr className="my-2" />

            <span style={{wordBreak: 'break-word'}}  className="text-sm mb-0">{ReactHTMLParser(value)}</span>
        </FeedWrapper>

    )

}


RenderNote.propTypes = {
    entry   : PropTypes.object.isRequired,
}



const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};


export default connect(mapStateToProps, '')(RenderNote);
