/*
Documentation

this file changes the emails that get shown in the left hand sidebar
possible values are all, send, unread

*/

import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types'

class ChangeEmailReadFilter extends Component {

    render() {

        const {  showing, onChangeShowing } = this.props

        return (

            <Row >

                <Col md={3} className="align-self-center">
                    <h3 className="title">Emails</h3>
                </Col>

                <Col md={9} className="align-self-center text-right showing-container">
                    <p className="text-sm mb-0">
                        <span className={showing === 'all' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('all')}>Inbox</span>
                        {' / '}
                        <span className={showing === 'sent' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('sent')}>Sent</span>
                        {' / '}
                        <span className={showing === 'unread' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('unread')}>Unread</span>
                    </p>
                </Col>

            </Row>

        )

    }

}

ChangeEmailReadFilter.propTypes = {
    showing           : PropTypes.string.isRequired,
    onChangeShowing   : PropTypes.func.isRequired,
}

export default ChangeEmailReadFilter
