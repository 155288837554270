import apiWorker from '../apiWorker'

export default {

    create            : (data)          => apiWorker('post',  `/api/v1/core/contact_texts/create`, data),
    find              : (query)         => apiWorker('get',   `/api/v1/core/contact_texts/find${query}`),
    searchMessenger   : (query)         => apiWorker('get',   `/api/v1/core/contact_texts/searchMessenger${query}`),
    update            : (_id, data)     => apiWorker('patch',   `/api/v1/core/contact_texts/update/${_id}`, data),

}
