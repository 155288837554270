import apiWorker from '../apiWorker'

import cardsGetName from './cards/getName'

import contactsFindOrCreate from './contacts/findOrCreate'

import validateDecimals from './validate/decimals'

import formatIntervalType from './format/intervalType'
import formatIntervalRuns from './format/intervalRuns'
import formatPaymentMethod from './format/paymentMethod'

export default {

    contacts: {
        findOrCreate: contactsFindOrCreate
    },

    account_vaults: {
        delete: (account_vault_id, data) => apiWorker('delete', `/api/v1/billing/account_vaults/delete/${account_vault_id}`, data),
        updateDefaultVaults: (data) => apiWorker('patch', `/api/v1/billing/recurrings/updateDefaultVaults`, data),

    },

    // these will charge a card or fake it as a cash payment
    transactions: {
        cash:       (data)                    => apiWorker('post', `/api/v1/billing/transactions/cash/`, data, true),
        cashRefund: (payment_id, data)        => apiWorker('patch', `/api/v1/billing/transactions/cash/refund/${payment_id}`, data),
        card:       (account_vault_id, data)  => apiWorker('post', `/api/v1/billing/transactions/card/${account_vault_id}`, data, true),
        cardRefund: (payment_id, data)        => apiWorker('patch', `/api/v1/billing/transactions/card/refund/${payment_id}`, data),
        complete: (payment_id)                => apiWorker('post', `/api/v1/billing/transactions/complete/${payment_id}`),
    },

    findByCase:    (case_id)     => apiWorker('get', `/api/v1/billing/payments/case/${case_id}`),
    findByContact: (contact_id)  => apiWorker('get', `/api/v1/billing/payments/contact/${contact_id}`),
    findByInvoice: (invoice_id)  => apiWorker('get', `/api/v1/billing/payments/invoice/${invoice_id}`),

    createPayform: (contact_id)  => apiWorker('get', `/api/v1/billing/payform/create_url/${contact_id}`),

    recurrings: {
        create: (data)               => apiWorker('post', `/api/v1/billing/recurrings/create/`, data),
        delete: (recurring_id)       => apiWorker('delete', `/api/v1/billing/recurrings/delete/${recurring_id}`),
        drop: (case_id)              => apiWorker('delete', `/api/v1/billing/recurrings/drop/${case_id}`),
        update: (recurring_id, data) => apiWorker('patch', `/api/v1/billing/recurrings/update/${recurring_id}`, data),
    },

    cards: {
        getName: cardsGetName,
    },

    validate: {
        decimals: validateDecimals
    },

    format: {

        intervalType: formatIntervalType,
        intervalRuns: formatIntervalRuns,
        paymentMethod: formatPaymentMethod,

    },

    event_logs: {
        findByContact: (contact_id) => apiWorker('get', `/api/v1/billing/payment_event_logs/contact/${contact_id}`),
        create: (data) => apiWorker('post', `/api/v1/billing/payment_event_logs/create`, data),

    },

    projections: {
        schedule: (case_id) => apiWorker('get', `/api/v1/billing/projections/schedule/case/${case_id}`),
    }

}
