import ConfirmationModal from 'components/functional/modals/Confirmation.js';
import React from "react";
import * as privileges from '_functions/users/privileges'

class ContactBlacklisted extends React.Component {

    state = {
        showModal: false
    }

    onConfirmation = () => {
        this.toggleModal();
        this.props.updateBlacklist();
    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    render() {

        const { showModal } = this.state;
        const { contact } = this.props;

        if(!contact.blacklisted) return <></>;

        const canUpdate = privileges.canViewAdminCallCenter();


        return (
            <div>

                <div className="alert alert-danger">
                    <i className="fas fa-exclamation-triangle mr-2 " /> 
                    This contact has been identified as spam and is no longer able to call in.
                    {canUpdate ? <i className="fas fa-edit ml-3 cursor-pointer" onClick={this.toggleModal} /> : null }
                </div>

                <ConfirmationModal
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    title="Remove From Blacklist"
                    body={<span>Confirm un-blacklisting this contact. They will be allowed to call in and leave voicemails in the future.</span>}
                    onConfirmation={this.onConfirmation}
                />

            </div>
        );
    }
}

export default ContactBlacklisted
