import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { formatMoney } from 'utils/currency';
import { calculateTotalBillable } from '../functions/calculations';

class Calculations extends Component {

    state = {};

    render() {

        if(!this.props.case_workflow) { return <div></div> }

        const case_workflow    = Object.assign({}, this.props.case_workflow)
        const calculations = calculateTotalBillable(case_workflow)

        return (

            <>

                <Row>

                    <Col md="6" lg="4">
                        <Card className="bg-gradient-success border-0">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                            Billable (Not Adjusted)
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0 text-white">{formatMoney(calculations.billable / 100)}</span>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" lg="4">
                        <Card className="bg-gradient-danger border-0">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                            Internal Cost
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0 text-white">{formatMoney(calculations.cost / 100)}</span>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" lg="4">
                        <Card className="bg-gradient-warning border-0">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                            Total Expected Time
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0 text-white">{(calculations.expected_minutes / 60).toFixed(2)} Hours</span>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

            </>

        )

    }

}

Calculations.propTypes = {
    case_workflow: PropTypes.object.isRequired,
    case: PropTypes.object.isRequired,
}


export default Calculations
