import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux'
import { Container, Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

import calendly from '_functions/calendly'

import Circle from 'components/markup/loading/Circle'

import moment from 'moment'

import hired_logs from '_functions/hired_logs'

import renderName from 'utils/renderName'

const Sales = ({users}) => {

    const [calendlyData, setCalendlyData] = useState()
    const [hires, setHires] = useState()

    const getUserName = useCallback((id) => {
        const user = users.find(u => u._id === id);
        return user ? renderName(user) : ''
    }, [users])

    const fetchHired = useCallback(async () => {
        const start = parseInt(moment().startOf('day').format('X'))
        const data = await hired_logs.find(start, start + 86400);
        setHires(data.data ? data.data : 'err')
    }, [])
    const fetchCalendly = useCallback(async () => {
        const data = await calendly.get();
        setCalendlyData(data.data ? data.data : 'err')
    }, [])

    useEffect(() => {
        fetchCalendly()
        fetchHired()
    }, [])

    return (
        <Container fluid>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Calendly Leads</CardTitle>
                </CardHeader>
                {!calendlyData ? <Circle /> : calendlyData === 'err' ? 'Error' : (
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>User</th>
                                    <th>Phone</th>
                                    <th className='text-right'>Booked Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {calendlyData.map((d, i) => (
                                    <tr key={i}>
                                        <td>{d.name}</td>
                                        <td>{d.email}</td>
                                        <td>{d.user}</td>
                                        <td>{d.phone}</td>
                                        <td className='text-right'>{moment.unix(d.unix).format('MM/DD/YYYY h:mm A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Today's Sales</CardTitle>
                </CardHeader>
                {!calendlyData ? <Circle /> : calendlyData === 'err' ? 'Error' : (
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>contact</th>
                                    <th>Roles</th>
                                    <th className='text-right'>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hires.map((d, i) => (
                                    <tr key={i}>
                                        <td>{renderName(d.contact)}</td>
                                        <td className='text-capitalize'>{getUserName(d.step_moved_by)}</td>
                                        <td className='text-right'>{moment.unix(d.date).format('MM/DD/YYYY h:mm A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Card>
        </Container>
    )

}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(Sales);