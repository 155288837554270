import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, Row, Col} from 'reactstrap';
import { formatMoney } from 'utils/currency';

import EditSubscriptionModal from './EditSubscriptionModal'

import _payments from '_functions/payments'

class HasRecurring extends Component {


    state = {
        showEditSubscriptionModal: false,
        recurring: null
    };

    getRecurringTotal = () => {

        let total = 0

        if(this.props.case.recurrings && this.props.case.recurrings.length) {
            this.props.case.recurrings.forEach(r => total += r.transaction_amount)
        }

        return total

    }

    showEditSubscriptionModal = (recurring) => this.setState({showEditSubscriptionModal: true, recurring})

    closeEditSubscriptionModal = () => this.setState({showEditSubscriptionModal: false})

    render() {

        const _case = this.props.case
        const recurring = _case.recurrings[0];
        const { contact, account_vaults, invoices } = this.props

        const total = this.getRecurringTotal()

        return (

            <div>


                <Card key={recurring._id}>

                    <CardHeader>
                        <CardTitle className="mb-0">
                            <Row>
                                <Col lg={10}>Current Subscription</Col>
                                <Col lg={2} className="text-right">
                                    <i onClick={() => this.showEditSubscriptionModal(recurring)} className="fas fa-edit text-success" />
                                </Col>
                            </Row>
                        </CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <tbody>

                                <tr>
                                    <td>Amount</td>
                                    <td className="text-right">{formatMoney(total)}</td>
                                </tr>

                                <tr>
                                    <td>Runs</td>
                                    <td  className="text-right">
                                        {_payments.format.intervalRuns(recurring.interval)}
                                        {_payments.format.intervalType(recurring.interval_type)}
                                        {parseInt(recurring.interval) === 3 ? 's' : null}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Next Payment</td>
                                    <td  className="text-right">
                                        {moment(recurring.next_run_date_formatted).format('MMMM Do, YYYY')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Payment Method</td>
                                    <td  className="text-right">
                                    {_payments.format.paymentMethod(recurring.payment_method)}

                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </Card>

                <EditSubscriptionModal
                    total={total}
                    recurring={{ ...recurring, transaction_amount: total }}
                    account_vaults={account_vaults}
                    showEditSubscriptionModal={this.state.showEditSubscriptionModal}
                    toggle={this.closeEditSubscriptionModal}
                    case={_case}
                    contact={contact}
                    invoices={invoices}
                    onRecurringDeleted={() => this.props.onRecurringDeleted()}
                />

            </div>

        )

    }

}

HasRecurring.propTypes = {
    case                  : PropTypes.object.isRequired,
    contact               : PropTypes.object.isRequired,
    onRecurringDeleted    : PropTypes.func.isRequired,
    account_vaults        : PropTypes.array,
    invoices              : PropTypes.array,
}

export default HasRecurring;
