/*
Documentation

renders an alert that drops down on the top of the screen.

Called by the following

import { toggleAlert } from 'store/functions/system/system';

toggleAlert(true, 'danger', 'Something went wrong uploading this document.', 4000)

*/

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { UncontrolledAlert } from 'reactstrap';

class Alert extends Component {

    state = {
        should_show: this.props.alerts.show ? true : false
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({should_show: nextProps.alerts.show})
    }

   render() {

        const text = this.props.alerts.text
        const type = this.props.alerts.type

        let icon

        if(type === 'info') {
            icon = <i className="mr-2 fas fa-info-circle" />
        } else if(type === 'danger') {
            icon = <i className="mr-2 fas fa-exclamation-triangle" />
        } else if(type === 'warning') {
            icon = <i className="mr-2 fas fa-eye" />
        } else if(type === 'success') {
            icon = <i className="mr-2 fas fa-thumbs-up" />
        }

        return (

            <UncontrolledAlert
                visible={this.state.should_show.toString()}
                className="w-100 py-4 shadow-lg"
                toggle={() => this.setState({should_show: !this.state.should_show})}
                style={this.state.should_show ? styles.fixed_show : styles.fixed_hide}
                color={type}
                fade={false}
            >
                <span className="alert-inner--icon"> {icon}</span>{" "}
                <span className="alert-inner--text">
                    {type === 'danger' ? <><strong>Error!</strong>{' '}</> : null}
                    {type === 'warning' ? <><strong>Warning!</strong>{' '}</> : null}
                    {type === 'success' ? <><strong>Success!</strong>{' '}</> : null}
                    {type === 'info' ? <><strong>Info:</strong>{' '}</> : null}
                    {text}
                </span>
            </UncontrolledAlert>

        )

    }

}

const styles = {

    fixed_show: {
        transition: '.5s all',
        zIndex: 999999999,
        borderRadius: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%'
    },

    fixed_hide: {
        transition: '.5s all',
        zIndex: 999999999,
        borderRadius: 0,
        position: 'fixed',
        top: -100,
        left: 0,
        right: 0,
        width: '100%'
    }

}

const mapStateToProps = state => {
    return {
        alerts: state.system.alerts,
    };
};

export default connect(mapStateToProps, '')(Alert);
