import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Input, Modal } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'

class ModalRefund extends Component {


    state = {
        refund: {}
    };

    onRefund = async () => {

        const { payment } = this.props
        const { refund } = this.state

        if(!refund.description) {
            return this.setState({paymentError: true})
        } else {
            this.setState({paymentError: false})
        }

        toggleStandardLoader(true)
        let action

        if(payment.type === 'cash') {
            action = await _payments.transactions.cashRefund(payment._id, refund);
        } else {
            action = await _payments.transactions.cardRefund(payment._id, refund);
        }

        toggleStandardLoader(false)

        this.props.toggleModal()

        if(action.success) {
            toggleAlertBS(false, `Payment Refunded Successfully.`)
        } else {
            toggleAlertBS(true, `Something went wrong, payment was not refunded.`)
        }

    }

    componentWillReceiveProps = (nextProps) => {

        const showModal = this.props.showModal;
        const nextShowModal = nextProps.showModal

        // wait half a second and then try to focus the input for better ux
        if(!showModal && nextShowModal) {
            setTimeout(() => {

                const input = document.getElementById('refund-input')
                if(input) input.focus()

            }, 500)
        } else {
            this.setState({refund: {}})
        }

    }

    render() {

        const { refund, paymentError } = this.state
        const { payment, showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Refund Payment
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {payment && payment._id ? (
                    <div className="modal-body">
                        <p className="mb-0">Refund Payment For <span className="text-danger text-underline">{formatMoney(payment.transaction_amount)}</span> </p>
                        <p className="text-sm text-muted">Note that refunds can take up to <b className="text-dark">7 days</b> to appear on a customers statement.</p>
                        <FormGroup>
                            <Input
                                id="refund-input"
                                type="textarea"
                                placeholder="Refund Description *"
                                value={refund.description}
                                onChange={(e) => this.setState({refund: {description: e.target.value}})}
                            />
                        </FormGroup>
                        {paymentError ? <p className="text-sm mb-0 text-danger">Description Is Required</p> : null}
                    </div>
                ) : null}

                <div className="modal-footer">
                    <button onClick={this.onRefund} className="btn btn-success">
                        <i className="fas fa-headphone" /> Create Refund
                    </button>
                </div>

            </Modal>

        )

    }

}

ModalRefund.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    payments    : PropTypes.object,
}

export default ModalRefund;
