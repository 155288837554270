import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Col, Row, Modal } from "reactstrap";

class ModalSelectTemplateText extends Component {

    state = {
        values: {}
    }

    onChange = (values) => {

        this.setState({values})

    }

    onSelect = () => {

        if(this.state.values) {
            this.props.onSelect(this.state.values)
        }

        this.props.toggleModal();

    }


    getOptions = () => {

        const { template_texts } = this.props;
        let options = []

        if(template_texts && template_texts.length) {

            template_texts.forEach(text => {
                options.push({ value: text, label: text.name })
            })
        }

        return options

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.showModal && !nextProps.showModal) {
            this.setState({values: {}})
        }

    }


    render() {

        const { toggleModal, showModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
            >

                <div className="modal-header d-block">
                    <Row>
                        <Col lg={6}>
                            <h5 className="modal-title" id="exampleModalLabel">Select Template Text</h5>
                        </Col>
                        <Col lg={6} className="text-right">
                            <button onClick={toggleModal} className="btn btn-outline-warning">
                                <i className="fas fa-times" /> Close
                            </button>
                        </Col>
                    </Row>
                </div>

                <div className="modal-body" style={{minHeight: 300}}>

                    <h4 className="text-capitalize">Select Template</h4>

                    <div className="pt-3" />
                        <ReactSelect
                            options={this.getOptions()}
                            onChange={this.onChange}
                        />
                    </div>

                <div className="modal-footer">
                    <button onClick={this.onSelect}  className="btn btn-success">Select Template</button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
    	template_texts: state.template_texts.template_texts,
    };
};

ModalSelectTemplateText.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    onSelect    : PropTypes.func.isRequired,
}

export default connect(mapStateToProps, '')(ModalSelectTemplateText);
