import React, {useCallback} from 'react';
import { Input, FormGroup } from 'reactstrap';

const ArchkNull = ({label, onChange}) => {

    const onInputChange = useCallback((e) => {
        let value = e.target.value

        if(value === 'true') {
            return onChange({$ne: null})
        } else if(value === 'false') {
            return onChange({$eq: null})
        }

        return onChange(undefined)
    }, [onChange])

    return (
        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <div className="archk-select">
                <Input
                    onChange={onInputChange}
                    type="select"
                >
                    <option></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </Input>
            </div>
        </FormGroup>
    )

}

export default ArchkNull;
