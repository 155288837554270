/*
Documentation

this modal creates a case_template for use later
It works by saving the url in the database as the url
contains all information needed to come back to

*/

import PropTypes from 'prop-types'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';
import _case_templates from '_functions/case_templates';

class ModalCreateTemplate extends Component {

    state = {
        template: {}
    }

    onCreateTemplate = async () => {

        const name = this.state.template.name
        if(!name) return this.setState({template: {...this.state.template, nameState: 'invalid' }})

        const href = window.location.href
        const path = window.location.pathname

        // get the relative path of the url and add the template=true to it
        const url = href.split(path)[1] + `&is_template=true`

        const created = await _case_templates.create({name, url})

        if(created.success) {

            this.setState({template: {}})
            this.props.onHideModal()
            toggleAlertBS(false, 'Case template created successfully.')

        }

    }

    render() {

        const { showModal, onHideModal }= this.props
        const template = this.state.template

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={onHideModal}
                size="lg"
                fade={false}
                id="modal-invoice"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create Case Template</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHideModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body bg-secondary">
                    <StandardFormGroup
                        obj={template}
                        objName="template"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        field="name"
                        description="Creating a case template will save all values associated with the case. You may then reference the template in the future for a quicker case creation process."
                        title="Template Name *"
                    />
                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={this.onCreateTemplate}>Create Template</button>
                </div>

            </Modal>

        )

    }

}

ModalCreateTemplate.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    onHideModal : PropTypes.func.isRequired,
}

export default ModalCreateTemplate;
