import React from "react";
import { Redirect } from 'react-router-dom';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls'

import _contacts from '_functions/contacts';
import _template_docs from '_functions/template_docs'
import _template_docs_sent from '_functions/template_docs_sent'
import _documents from '_functions/documents'

import Main from './Main'
import Sidebar from './Sidebar'
import ModalSend from './Modals/Send/index'
import ModalParties from './Modals/Parties'
import ModalCreateTemplate from './Modals/CreateTemplate'

import * as footer from 'utils/footer'
import { resetMemory } from 'utils/pdf/controller'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const associationWarning = 'While you may send a document from this screen it is recommended to do so by going to the documents tab within a case so the case will be associated to the sent PDF'

class SignatureMaster extends React.Component {

    state = {
        markers: [],
        initial_pages: false,
        pdfData: null,
        showModal: false,
        shouldFireUploads: false,
        parties: 1,
        showingParty: 1,
        showModalParties: false,
        docName: '',
        isSending: false,
        isTemplate: false,
        showTemplateModal: false,
        originalDoc: null,
        contacts: [],
        associatedCase: null,
        associatedContact: null
    }

    toggleInitialPages = () => this.setState({initial_pages: !this.state.initial_pages});

    // if we don't have an associated case show a warning
    toggleModal = () => {
        if(!getUrlParameter('case') && this.state.showModal === false) toggleAlertBS('info', associationWarning)
        this.setState({showModal: !this.state.showModal})
    };

    toggleTemplateModal = () => this.setState({showTemplateModal: !this.state.showTemplateModal});
    togglePartyModal = () => this.setState({showModalParties: !this.state.showModalParties});

    onNameChange = (docName) => this.setState({docName});
    onPartyChange = (parties) => this.setState({parties});
    onShowingPartyChange = (showingParty) => this.setState({showingParty});

    clearPDF = () => this.setState({markers: []})
    wipePDF = () => this.setState({pdfData: null})

    onFileAdded = async (files) => {


        // if the file is a pdf continue as normal
        const file = files[0]
        if(file.type === 'application/pdf') {
            this.setState({pdfData: file})
        // if the file is a word document convert it to a pdf and then fake the document
        } 
        // this crashes the server due to how word documents are process
        // DO NOT USE WORD DOCUMENTS HERE UNTIL FIXED
        // else if(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        //     toggleStandardLoader(true);

        //     var bodyFormData = new FormData();
        //     bodyFormData.append('file', file);
    
        //     const sent = await _template_docs.convertWordToPDF(bodyFormData)
        //     if(sent.success) this.setState({pdfData: { isBuffer: true, buffer: sent.data.data }})

        //     toggleStandardLoader(false)
        // } 
        else {
            toggleAlertBS('info', `This page only accepts standard PDF documents.`)
        }

    }

    addMarker = (coords, pageNumber) => {
        const markers = [...this.state.markers];
        markers.push({...coords, pageNumber, party: this.state.showingParty})
        this.setState({markers})
    }

    removeMarker = (i) => {
        const markers = [...this.state.markers]
        markers.splice(i, 1)
        this.setState({markers})
    }

    setMarkerType = (i, type) => {
        const markers = [...this.state.markers]
        markers[i].type = type
        this.setState({markers})
    }

    updateMarkerName = (text, i) => {
        const markers = [...this.state.markers]
        markers[i].name = text
        this.setState({markers})
    }

    setMarkerAnswer = (i, answer) => {
        const markers = [...this.state.markers]
        markers[i].answer = answer
        this.setState({markers})
    }

    compileParties = () => {
     
        const compiledParties = [];

        this.state.contacts.forEach(contact => {
            compiledParties.push({ contact : contact.fullContact._id, party_index : contact.party_index, })
        })

        return compiledParties;

    }

    // add in a party to the document, don't allow duplicate contacts
    onAddParty = (contact, party_index) => {
        let contacts = [...this.state.contacts];
        contacts = contacts.filter(contact => contact.party_index !== party_index)
        contacts.push({ contact: contact._id, party_index, fullContact: contact });

        this.setState({contacts});
    }

    // type is 'isSending or 'isTemplate
    createDocumentFromWordBuffer = async (type) => {

        // upload the buffer by creating a new document
        const name = `Contract_${Math.floor(new Date() / 1)}.pdf`;

        const file = {
            fieldname: 'file',
            originalname: name,
            encoding: '7bit',
            mimetype: 'application/pdf',
            buffer: this.state.pdfData.buffer
        }

        toggleStandardLoader(true);

        this.setState({[type]: true})
        const sent = await _documents.create({ file, 'bucket': '/template docs' })

        if(sent.success) {
            this.onUpload(null, sent)
        } else {
            toggleStandardLoader(false)
        }

    }

    // type is 'isSending or 'isTemplate
    onSendOrTemplate = (type) => {

        const { originalDoc, pdfData } = this.state;

        // update an existing document or upload a new document for creation
        if(originalDoc && originalDoc._id) {
            if(type === 'isSending') {
                this.onSendTemplate();
            } else {
                this.onUpdateTemplate();
            }
        // we are sending a word document that was converted to a pdf document
        } else if(pdfData.isBuffer) {
            this.createDocumentFromWordBuffer(type)
        // fire uploads and set state so we know what to do with the file after
        } else {
            toggleStandardLoader(true)
            this.setState({shouldFireUploads: true, [type]: true}, () => {
                this.setState({shouldFireUploads: false})
            })
        }

    }

    onUpdateTemplate = async () => {

        toggleStandardLoader(true)

        const { initial_pages, markers, docName, parties, originalDoc } = this.state

        const updated = await _template_docs.update(originalDoc._id, {
            initial_pages   : initial_pages ? 'yes' : 'no',
            markers         : markers,
            name            : docName,
            parties         : parties,
        });

        if(updated.success) {
            toggleAlertBS(false, `Template Updated Successfully.`)
        } else {
            toggleAlertBS(true, `An error occurred updating this template.`)
        }

        toggleStandardLoader(false)

    }

    onSendTemplate = async () => {

        toggleStandardLoader(true)

        const { initial_pages, markers, docName, originalDoc } = this.state
        const case_id = getUrlParameter('case') ? getUrlParameter('case') : undefined;

        const created = await _template_docs_sent.create({
            template_doc    : originalDoc._id,
            initial_pages   : initial_pages ? 'yes' : 'no',
            markers         : markers,
            name            : docName,
            parties         : this.compileParties(),
            case            : case_id,
        });

        if(created.success) {
            toggleAlertBS(false, `PDF successfully sent for signing.`);
            if(case_id) {
                this.setState({shouldRedirect: `/dashboard/cases/view/${case_id}?nav=3`})
            } else {
                this.toggleModal();
            }

            _analytics.events.create({event: ANALYTIC_EVENTS.DOCUMENTS_SENT});

        } else {
            toggleAlertBS(true, `An error occurred sending this document.`)
        }

        toggleStandardLoader(false)

    }

    onUpload = async (file, request) => {

        if(request.data) {

            const { initial_pages, markers, isSending, docName, parties } = this.state
            const case_id = getUrlParameter('case') ? getUrlParameter('case') : undefined;

            if(isSending) {

                const data = {
                    document        : request.data._id,
                    initial_pages   : initial_pages ? 'yes' : 'no',
                    markers         : markers,
                    name            : docName,
                    parties         : this.compileParties(),
                    case            : case_id
                }
    
                const created = await _template_docs_sent.create(data);
    
                if(created.success) {
                    toggleAlertBS(false, `PDF successfully sent for signing.`)
                    if(case_id) this.setState({shouldRedirect: `/dashboard/cases/view/${case_id}?nav=3`})
                } else {
                    toggleAlertBS(true, `An error occurred sending this PDF.`)
                }

            } else {

                const data = {
                    document        : request.data._id,
                    initial_pages   : initial_pages ? 'yes' : 'no',
                    markers         : markers,
                    name            : docName,
                    parties         : parties,
                }
    
                const created = await _template_docs.create(data);
    
                if(created.success) {
                    toggleAlertBS(false, `Template Created Successfully.`)
                } else {
                    toggleAlertBS(true, `An error occurred creating the template.`)
                }


            }

        }

        toggleStandardLoader(false)

    }

    fetchContact = async () => {

        const contact_id = getUrlParameter('contact')

        if(contact_id) {
            const contact = await _contacts.findById(contact_id, true)
            if(contact.data) {
                this.setState({contacts: [{ contact: contact.data._id, party_index: 1, fullContact: contact.data }]});
            }
        }

    }

    componentWillUnmount = () => {
        footer.show()
        resetMemory()
    }

    componentDidMount = async () => {

        footer.hide()
        this.fetchContact();

        const template_doc_id = this.props.match.params.template_doc_id;
        if(template_doc_id !== 'new') {

            const doc = await _template_docs.findById(template_doc_id)
            if(doc.data) {
                this.setState({
                    docName         : doc.data.name,
                    initial_pages   : doc.data.initial_pages === 'yes' ? true : false,
                    markers         : doc.data.markers,
                    parties         : doc.data.parties,
                    originalDoc     : doc.data,
                    pdfData         : doc.data.document
                })
            }

        }

    }

    render() {

        const { shouldRedirect, markers, initial_pages, pdfData, showModal, shouldFireUploads, contacts } = this.state
        const { parties, showModalParties, showingParty, docName, showTemplateModal, originalDoc, associatedCase, associatedContact } = this.state

        if(shouldRedirect) return <Redirect to={shouldRedirect} />

        return (

            <div className="py-5 archk-template-docs" style={{minWidth: 900}} >

                <Main 
                    markers={markers}
                    addMarker={this.addMarker}
                    removeMarker={this.removeMarker}
                    setMarkerType={this.setMarkerType}
                    updateMarkerName={this.updateMarkerName}
                    onFileAdded={this.onFileAdded}
                    onUpload={this.onUpload}
                    pdfData={pdfData}
                    shouldFireUploads={shouldFireUploads}
                />
            
                <Sidebar 
                    markers={markers}
                    initial_pages={initial_pages}
                    toggleInitialPages={this.toggleInitialPages}
                    clearPDF={this.clearPDF}
                    wipePDF={this.wipePDF}
                    confirmSend={this.toggleModal}
                    pdfData={pdfData}
                    updateMarkerName={this.updateMarkerName}
                    togglePartyModal={this.togglePartyModal}
                    parties={parties}
                    showingParty={showingParty}
                    onShowingPartyChange={this.onShowingPartyChange}
                    onNameChange={this.onNameChange}
                    docName={docName}
                    confirmTemplate={this.toggleTemplateModal}
                    originalDoc={originalDoc}
                />

                <ModalParties 
                    showModalParties={showModalParties}
                    parties={parties}
                    togglePartyModal={this.togglePartyModal}
                    onPartyChange={this.onPartyChange}
                />

                <ModalSend 
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    onSend={() => this.onSendOrTemplate('isSending')}
                    markers={markers}
                    initial_pages={initial_pages}
                    parties={parties}
                    docName={docName}
                    setMarkerAnswer={this.setMarkerAnswer}
                    contacts={contacts}
                    onAddParty={this.onAddParty}
                    associatedCase={associatedCase}
                    associatedContact={associatedContact}
                />

                <ModalCreateTemplate 
                    showTemplateModal={showTemplateModal}
                    toggleTemplateModal={this.toggleTemplateModal}
                    markers={markers}
                    initial_pages={initial_pages}
                    parties={parties}
                    onTemplate={() => this.onSendOrTemplate('isTemplate')}
                    docName={docName}
                    originalDoc={originalDoc}
                />

            </div>

        );
    }
}

export default SignatureMaster
