import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import { formatMoney } from 'utils/currency';

class Refunds extends Component {

    // scroll to top of open modal
    scrollModalToTop = () => {

        const modal = document.getElementById('modal-invoice')

        //if modal is open and active
        if(modal) {

            let modal_content = null;

            // find the .modal-content within the modal
            for (let i = 0; i < modal.childNodes.length; i++) {

                if (modal.childNodes[i].className === "modal-content") {
                    modal_content = modal.childNodes[i];
                    break;
                }

            }

            //if we have the modal content scroll to top
            if(modal_content) modal_content.scrollTop = 0

        }

    }

    render() {

        const { invoice } = this.props

        const data = invoice.refunds

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Current Refund Items</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table  className="table">

                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                                <th className="text-right">Date</th>
                            </tr>
                        </thead>

                        <tbody>

                            {data && data.length ? data.map(f => (
                                <tr key={f._id ? f._id : f.id}>
                                    <td style={{wordBreak: 'break-word'}}>{f.description}</td>
                                    <td>{formatMoney(f.amount)}</td>
                                    <td className="text-right text-capitalize">{moment.unix(f.date).format('MMM Do, YYYY - h:mm A')}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td style={{wordBreak: 'break-word'}}>There are no refunds on this invoice.</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>

                    </table>
                </div>

            </Card>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

Refunds.propTypes = {
    invoice: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(Refunds);
