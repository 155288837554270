/*
Documentation

this file renders all location_courts within the system

*/

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";
import _documents from '_functions/documents'
import renderName from 'utils/renderName'

const { SearchBar } = Search;

class DocumentsUser extends React.Component {

    state = {
        sizePerPage: 25
    }

    columns = [
        {
            dataField: "friendly_name",
            text: "Document",
            formatter: (cell, row) => (
                row.case ? (
                    <Link to={`/dashboard/cases/view/${row.case._id}?nav=3&doc=${row._id}`}>{row.friendly_name}</Link>
                ) : (
                    row.friendly_name
                )
            )
        },
        {
            dataField: "contact",
            text: "Contact",
            filterValue: (cell,row) => renderName(row.contact),

            formatter: (cell, row) => (
                row.contact ? (
                    <Link className="text-capitalize" to={`/dashboard/contacts/view/${row.contact._id}`}>{renderName(row.contact)}</Link>
                ) : (
                    <div>{renderName(row.contact)}</div>
                )
            )
        },
        {
            dataField: "case",
            text: "Case",
            filterValue: (cell,row) => row.case ? row.case.name : 'Case Not Found',
            formatter: (cell, row) => (
                row.case ? (
                    <Link className="text-capitalize" to={`/dashboard/cases/view/${row.case._id}?nav=3`}>{row.case.name}</Link>
                ) : (
                    'Case Not Found'
                )
            )
        },
        {
            dataField: "created_at",
            text: "Date Uploaded",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">{moment.unix(row.created_at).format("M/D/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")}</div>
            )
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    row.case ? (
                        <div className="text-right">
                            <Link to={`/dashboard/cases/view/${row.case._id}?nav=3`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        </div>
                    ) : null
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPage: this.state.sizePerPage,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const documents = await _documents.findAdmin()
        if(documents.success) this.setState({documents: documents.data})

    }

    render() {

        const { documents } = this.state
        if(!documents) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <Helmet>
                    <title>{`Uploaded Documents (${documents.length})`}</title>
                    <meta name="description" content="Documents" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Admin Documents</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link to="/dashboard/documents/user" className="btn btn-success">My Documents</Link>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Unassigned Documents</CardTitle>
                    </CardHeader>

                    {documents ? (

                        <ToolkitProvider
                            data={documents}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            <p className="text-sm mb-0">There are no unassigned documents to view</p>
                        </CardBody>

                    )}
                </Card>

            </Container>

        );
    }
}

export default DocumentsUser

