import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import * as _ from 'underscore';
import _contacts from '_functions/contacts';
import Content from './Content';
import Navs from './Navs';

const limit = 25

const baseState = {
    contacts: null,
    selectedContact: null,
    show: 'all',
    skip: 0,
}

let lastScrollPosition = 0;

const MessengerSidebarLeft = ({viewing_user, onSelectContact, getContactIdentifier, selectedContact}) => {

    const [mounted, setMounted] = useState(false)
    const [show, setShow] = useState('all')
    const [skip, setSkip] = useState(0)
    const [state, setState] = useState(baseState)


    const safeSetState = (newState) => {
        const oldState = Object.assign({}, state)
        setState({...oldState, ...newState})
    }
   
    const fetchContacts = (forceShow) =>  new Promise (async resolve => {

        setSkip(skip + limit)

        let query = `?limit=${limit}&skip=${skip}`

        const view = forceShow ? forceShow : show;

        if(view === 'unread') {
            query += `&read=false` ;
        } else if(view === 'mine') {
            query += `&assigned_user=${viewing_user._id}` ;

        }

        const contactData = await _contacts.searchMessenger(query)
        const contacts = contactData.data ? contactData.data : [];
        
        return resolve(contacts);
        
    })

      
    const fetchMoreContacts = _.throttle( async () => {

        console.log('fetch')

        const currentContacts = [...state.contacts];

        const contacts = await fetchContacts(false, skip);
        if(!contacts) return;

        const allContacts = currentContacts.concat(contacts);

        safeSetState({contacts: allContacts})

    }, 500)

     const onShowChange = useCallback(async (show) => {

        setShow(show);
        safeSetState({contacts: null,})
        const contacts = await fetchContacts(show, 0);


        safeSetState({contacts})
    }, [])

    const onContactUpdated = (data) => {

        const contacts = state.contacts ? [...state.contacts] : [];
        const contactIndex = contacts.findIndex(el => el._id === data.data._id);
        
        const isInList = contactIndex === -1 ? false : true;
        const isNewText = data.data.unread_texts > 0;

        const updateOrPush = (onlyPushIfNew) => {
           
            if(isInList) {

                // new text ink list then push them to the top of the list
                if(isNewText) {
                    contacts.splice(contactIndex, 1);
                    contacts.unshift(data.data);
                // is in list but text is not new, keep them where they are just mark as read
                } else {
                    contacts[contactIndex] = data.data
                }

            } else {

                // if(onlyPushIfNew) {
                //     // if not in list and this is a new texts add them to the top of the list
                //     if(isNewText) {
                //         contacts.unshift(data.data);
                //     }
                // } else {
                //     contacts.unshift(data.data);
                // }

                if(isNewText) {
                    contacts.unshift(data.data);
                }
                
            }

            safeSetState({contacts})

        }

        if(show === 'unread') {
            updateOrPush(true)
        } else if(show === 'mine') {
            // if the contact is assigned to the user viewing
            if(data.data.assigned_user === viewing_user._id) {
                updateOrPush(false)
            }
        } else {
            updateOrPush(false)
        }
        
    }

    const onSelect = async (contact) => {

        if(selectedContact && selectedContact._id === contact._id) return;
        
        onSelectContact(contact)
        _contacts.update(contact._id, { unread_texts: 0 })

    }

    const onSidebarScroll = (e) => {

        const scrollTop = e.target.scrollTop
        const shouldScroll = e.target.scrollHeight - e.target.offsetHeight - 10;

        if( (scrollTop >= shouldScroll) && (scrollTop > lastScrollPosition) ) {
            fetchMoreContacts()
        }

        lastScrollPosition = scrollTop
    }

    useEffect(() => {

        io.on(socketEvents.contacts_updated, onContactUpdated)

        const sidebar = document.getElementById('archk-messenger-sidebar')
        sidebar.addEventListener('scroll', onSidebarScroll)

        if(!mounted) {

            setMounted(true) 

            const fetchDate = async () => {
                const contacts = await fetchContacts();
                safeSetState({contacts})
            }

            fetchDate();
        }
       

        return () => {
            io.off(socketEvents.contacts_updated, onContactUpdated)

            const sidebar = document.getElementById('archk-messenger-sidebar')
            sidebar.removeEventListener('scroll', onSidebarScroll)
        }

    }, [state, mounted])

    const { contacts } = state;

    return (

        <div id="archk-messenger-sidebar" className="col-auto archk-messenger-sidebar" >

            <ul>
                
                <Navs 
                    show={show}
                    onShowChange={onShowChange}
                />

                <Content 
                    contacts={contacts}
                    onSelect={onSelect}
                    selectedContact={selectedContact}
                    getContactIdentifier={getContactIdentifier}
                />
               
            </ul>
        </div>

    );

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(MessengerSidebarLeft);