/*
Documentation

this file renders a modal allowing users to set their status
this status can then be viewed by admin in the call_center

*/

import React from "react";
import { Modal } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { connect } from 'react-redux';
import _user from '_functions/users';

class SetStatus extends React.Component {

    state = {
        user: null
    }

    onSetStatus = () => {

        let user_set_status = this.state.user.user_set_status
        if(!user_set_status) user_set_status = 'default'

        _user.update(this.props.viewing_user._id, { user_set_status })
        this.props.toggle()

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModalSetStatus && nextProps.showModalSetStatus) {

            this.setState({user: Object.assign({}, this.props.viewing_user)})

        }

    }

    render() {

        const { showModalSetStatus, toggle, user_statuses }  = this.props
        const { user } = this.state

        if(!showModalSetStatus || !user) return null

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalSetStatus}
                toggle={() => toggle()}
                size="md"
            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">
                        Set Status
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={user}
                        objName="user"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        field="user_set_status"
                        type="select"
                    >
                        <option value="default">No Status</option>
                        {user_statuses.map(status => (
                            <option key={status._id} value={status.name} >{status.name}</option>
                        ))}
                    </StandardFormGroup>

                </div>

                <div className="modal-footer">
                    <button onClick={this.onSetStatus} className="btn btn-success">Set My Status</button>
                </div>


            </Modal>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user    : state.auth.viewing_user,
        user_statuses   : state.user_statuses.user_statuses,
    };
};

export default connect(mapStateToProps, '')(SetStatus);

