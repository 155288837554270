import ReactSelect from 'components/functional/inputs/ReactSelect';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { connect } from 'react-redux'
import _trust_logs from '_functions/trust_logs';

import validator from 'utils/validator';
import DatePicker from 'react-datepicker';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'
import * as privileges from '_functions/users/privileges';

import moment from 'moment';

let required_fields = [
    'amount',
    'date',
    'reason',
    'payment_method',
    'party',
]

const paymentOptions = [
    {'label': 'ECH/EFT/Wire Transfer', 'value': 'ECH/EFT/Wire Transfer'},
    {'label': 'Printed Check', 'value': 'Printed Check'},
    {'label': 'Written Check', 'value': 'Written Check'},
    {'label': 'Bank Check/Money Order', 'value': 'Bank Check/Money Order'},
]
const reasonOptions = [
    {'label': 'Client\'s Portion Of Garnishment Recovery', 'value': 'Client\'s Portion Of Garnishment Recovery'},
    {'label': 'Earned Fees', 'value': 'Earned Fees'},
    {'label': 'Post-Filing Garnishment', 'value': 'Post-Filing Garnishment'},
    {'label': 'Refund', 'value': 'Refund'},
    {'label': 'Reimbursement For Filing Fees', 'value': 'Reimbursement For Filing Fees'},
    {'label': 'Settlement', 'value': 'Settlement'},
    {'label': 'Other', 'value': 'Other'},
]

class TrustMakePayment extends React.Component {

    state = {
        invoices_options: [],
        trust_log: {
            date: new Date(new Date().setDate(new Date().getDate()-1)),
            amount: this.props.case.billing_in_trust ? this.props.case.billing_in_trust.toFixed(2) : '',
            'party': 'FM',
            'payment_method': 'ECH/EFT/Wire Transfer',
            'transaction_reference': 'Batch',
            'to_account_number': 'General',
            'reason': 'Reimbursement For Filing Fees',
        },
        showSelects: true
    }

    onInputChange = (stateField, value) => {
        validator.onInputChange(value, 'trust_log', stateField, this)
    }

    onSave = async () => {

        const payment_method = this.state.trust_log.payment_method
        let fieldToValidate = [...required_fields]

        if(this.state.trust_log.going_toward_invoice) {
            fieldToValidate.push('invoice')
        }

        if(payment_method === `ECH/EFT/Wire Transfer`) {
            fieldToValidate.push('transaction_reference')
            fieldToValidate.push('to_account_number')
        }

        if(payment_method === `Printed Check`) {
            fieldToValidate.push('check_series')
            fieldToValidate.push('check_number')
        }

        if(payment_method === `Written Check` || payment_method === `Bank Check/Money Order`) {
            fieldToValidate.push('check_number')
        }

        const validateFields = validator.requiredFields('trust_log', fieldToValidate, this, true)
        const newState = validateFields.state

        this.setState({trust_log: {...newState} })

        if(validateFields.success) {

            if(newState.check_date) {
                newState.check_date = parseInt(moment.unix(newState.check_date).format('X'))
            }

            newState.date = parseInt(moment(newState.date).format('X'))
            newState.amount     = parseFloat(newState.amount) * -1
            newState.type       = 'out';
            newState.user       = this.props.viewing_user._id
            newState.contact    = this.props.case.contact._id
            newState.case       = this.props.case._id

            if(!this.state.trust_log.going_toward_invoice) delete newState.invoice

            toggleStandardLoader(true)

            const created = await _trust_logs.create(newState, true)

            toggleStandardLoader(false)

            if(created.success) {

                toggleAlertBS(false, `Payment logged successfully.`)
                this.setState({trust_log: {}, showSelects: false}, () => {
                    this.setState({showSelects: true})
                })

            } else {

                toggleAlertBS(true, created.user_message ? created.user_message : `An error occurred logging this payment.`)

            }

        }

    }

    componentDidMount = () => {

        const { invoices } = this.props
        let invoices_options = []

        if(invoices) {
            invoices.forEach(invoice => invoices_options.push({'label': invoice.name, 'value': invoice._id}))
        }

        this.setState({invoices_options})

        var copyText = document.getElementById("archk-billing-case-id");
        if(copyText) navigator.clipboard.writeText(copyText.innerText);

    }

    render() {

        const { trust_log, invoices_options, showSelects } = this.state;
        const canUpdate = privileges.canUpdateFinances()

        return (
            <>

                <h2 className="display-5"><i className="fas fa-minus-square mr-2 text-warning" /> Make A Payment</h2>

                <Row>

                    <Col lg={6}>
                        <StandardFormGroup
                            obj={trust_log}
                            objName="trust_log"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="number"
                            field={'amount'}
                            title={'Payment Amount'}
                        />
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Payment Date</label>
                            <div
                                className={
                                    trust_log.dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    trust_log.dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={trust_log.date}
                                    onChange={date => this.onInputChange('date', date)}
                                />
                            </div>
                        </FormGroup>
                    </Col>

                </Row>

                <StandardFormGroup
                    obj={trust_log}
                    objName="trust_log"
                    onChange={(o, f, v) => {
                        console.log(v)
                        if(v === 'FM' || v === 'fm') {
                            this.setState({
                                trust_log: {
                                    ...this.state.trust_log,
                                    date: new Date(new Date().setDate(new Date().getDate()-1)),
                                    'party': 'FM',
                                    'payment_method': 'ECH/EFT/Wire Transfer',
                                    'transaction_reference': 'Batch',
                                    'to_account_number': 'General',
                                    'reason': 'Reimbursement For Filing Fees',
                                }
                            })
                            var copyText = document.getElementById("archk-billing-case-id");
                            if(copyText) navigator.clipboard.writeText(copyText.innerText);
                        } else {
                            this.onInputChange(f, v)

                        }
                    }}
                    type="text"
                    field={'party'}
                    title={'Pay To *'}
                />

                <div className="custom-control custom-checkbox">
                    <input
                        value={trust_log.going_toward_invoice || ''}
                        onChange={(e) => this.setState({
                            trust_log: {
                                ...Object.assign({}, trust_log),
                                going_toward_invoice: !trust_log.going_toward_invoice
                            }
                        })}
                        className="custom-control-input"
                        id="billing-center-trust-current-invoice"
                        type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="billing-center-trust-current-invoice">This payment is going toward an current invoice </label>
                </div>

                {trust_log.going_toward_invoice ? (
                    <FormGroup>
                        <label className="form-control-label">Select Invoice</label>
                        <ReactSelect
                            invalid={trust_log.invoiceState === 'invalid'}
                            placeholder=""
                            onChange={values => this.onInputChange('invoice', values.value)}
                            options={invoices_options}
                        />
                    </FormGroup>
                ) : null}

                <hr />

                <Row>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Reason For Payment</label>
                            {console.log(this.state.trust_log.reason)}
                            {showSelects ? (
                                <ReactSelect
                                    placeholder=""
                                    invalid={trust_log.reasonState === 'invalid'}
                                    onChange={values => this.onInputChange('reason', values.value)}
                                    value={reasonOptions.find(o => o.value === this.state.trust_log.reason)}
                                    options={reasonOptions}
                                />
                            ): null}
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Payment Method</label>
                            {showSelects ? (
                                <ReactSelect
                                    placeholder=""
                                    invalid={trust_log.payment_methodState === 'invalid'}
                                    onChange={values => this.onInputChange('payment_method', values.value)}
                                    value={paymentOptions.find(o => o.value === this.state.trust_log.payment_method)}
                                    options={paymentOptions}
                                />
                            ) : null}
                        </FormGroup>
                    </Col>

                </Row>

                {trust_log.payment_method === `ECH/EFT/Wire Transfer` ? (
                    <>
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'transaction_reference'}
                        title={'Transaction Reference *'}
                    />
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'to_account_number'}
                        title={'To Account Number *'}
                    />

                    </>
                ) : null}

                {trust_log.payment_method === `Printed Check` ? (
                    <>
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'check_series'}
                        title={'Check Series *'}
                    />
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'check_number'}
                        title={'Check Number *'}
                    />
                    </>
                ) : null}

                {trust_log.payment_method === `Written Check` || trust_log.payment_method === `Bank Check/Money Order` ? (
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'check_number'}
                        title={'Check Number *'}
                    />
                ) : null}

                <StandardFormGroup
                    obj={trust_log}
                    objName="trust_log"
                    onChange={(o, f, v) => this.onInputChange(f, v)}
                    type="textarea"
                    field={'description'}
                    title={'Memo'}
                />

                <hr />

                <div className="text-right">
                    <button
                        disabled={!canUpdate}
                        onClick={canUpdate ? this.onSave : null}
                        className="btn btn-success"
                    >
                        Pay From Trust
                    </button>
                </div>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(TrustMakePayment);

