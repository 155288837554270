import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { formatMoney } from 'utils/currency';
import NotificationDelete from 'components/functional/notifications/Delete';

import ModalCrud from './ModalCrud';

import _filing_fees from '_functions/filing_fees';



class Garnishments extends React.Component {

    state = {
        filing_fees: null,
        showModal: false,
        filing_fee: {},
        filingFeeToDelete: null,
        null: {},
    }

    columns = [
        {
            dataField: "installment",
            text: "Installment",
        },

        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => formatMoney(row.amount)
        },
        {
            dataField: "show_cause",
            text: "Show Cause",
        },
        {
            dataField: "show_cause_date",
            text: "Show Cause Date",
            formatter: (cell, row) => row.show_cause_date ? moment(row.show_cause_date).format('MM/DD/YYYY') : '-'
        },
        {
            dataField: "due_date",
            text: "Due Date",
            formatter: (cell, row) => row.due_date ? moment(row.due_date).format('MM/DD/YYYY') : '-'
        },
        {
            dataField: "paid_date",
            text: "Paid Date",
            formatter: (cell, row) => row.paid_date ? moment(row.paid_date).format('MM/DD/YYYY') : '-'
        },
        {
            dataField: "notes",
            text: "Notes",
            formatter: (cell, row) => (
                <span style={{width: 300, maxWidth: 300, whiteSpace: 'pre-line'}}>{row.notes}</span>
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => {
                return (
                    <div className="text-right">

                        <i onClick={() => this.setState({filingFeeToDelete: row})} className="cursor-pointer fas fa-trash text-danger mr-2"></i>
                        <i onClick={() => this.toggleModal(row)} className="cursor-pointer fas fa-edit text-success"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onDelete = (call) => {
        let filing_fees = [...this.state.filing_fees];
        filing_fees = call.data ? filing_fees.filter(f => f._id !== call.data._id) : filing_fees

        this.setState({filing_fees, showModal: false});
    }

    onSuccess = (filing_fee) => {

        let filing_fees = [...this.state.filing_fees];
        filing_fees = filing_fees.filter(f => f._id !== filing_fee._id);

        filing_fees.push(filing_fee)

        this.setState({filing_fees, showModal: false});

    }

    toggleModal = (filing_fee) => this.setState({showModal: !this.state.showModal, filing_fee});

    componentDidMount = async () => {

        const data = await _filing_fees.findByCase(this.props.case._id);
        if(data.data) this.setState({filing_fees: data.data})

    }

    render() {

        const { filing_fees, showModal, filing_fee, filingFeeToDelete } = this.state;
        const _case = this.props.case;

        if(!filing_fees) return <Circle />

        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <Row> 
                        <Col xs={6} className="align-self-center">
                            <CardTitle className="mb-0 float-left">All Case Filing Fees</CardTitle>
                        </Col>
                        <Col xs={6} className="align-self-center text-right">
                            <button onClick={() => this.toggleModal({})} className="btn btn-success">Add Fee</button>
                        </Col>
                    </Row>
                </CardHeader>

                {filing_fees.length ? (
                    <ToolkitProvider
                        data={filing_fees}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                            </>
                        )}
                    </ToolkitProvider>
                ) : (
                    <CardBody>
                        No filing fees have been created for this case yet.
                    </CardBody>
                )}

                <ModalCrud 
                    onSuccess={this.onSuccess}
                    filing_fee={filing_fee}
                    showModal={showModal}
                    toggleModal={() => this.toggleModal(filing_fee)}
                    case={_case}
                />

                {filingFeeToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/core/filing_fees/delete/${filingFeeToDelete._id}`}
                        confirmBtnText="Delete Contact"
                        title={<span className="text-capitalize">{`Delete Installment #${filingFeeToDelete.installment}`}</span>}
                        text="Are you sure you wish to delete this entry?"
                        onClose={() => { this.setState({filingFeeToDelete: null}) }}
                        onSuccess={this.onDelete}
                    />
                )}

            </Card>

        );
    }
}

export default Garnishments
