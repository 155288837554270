import { helpCenter } from 'store/functions/system/system';
import { Link } from 'react-router-dom'
import React from 'react';
import * as privileges from '_functions/users/privileges'
// import store from 'store';

const backButton = (
    <Link
        to="/dashboard/cases/all"
        className="btn btn-warning"
        onClick={() => helpCenter.toggle(
            helpCenter.content.cases.all.body,
            helpCenter.content.cases.all.footer,
        )}
    >
        <i className="fas fa-arrow-left mr-2" />Back
    </Link>
)

export default {

    all: {
        body: () =>  (
            <div>
                <h3>All Cases</h3>
                <p>This page allows you to view all created cases that have not been deleted. On the top right of the screen there are two buttons allowing you to create a case from scratch or using a pre existing case template.</p>
                <p>In order to create a case 3 actions must be taken first:</p>
                <ol>
                    <li>
                        {/* {<i className={store.getState().contacts.contacts.length ? "fas fa-check text-success mr-2" : "fas fa-times text-danger mr-2"} />} */}
                        There must be an existing contact within the system to assign to the case.
                    </li>
                    <li>
                        {/* {<i className={store.getState().location_courts.location_courts.length ? "fas fa-check text-success mr-2" : "fas fa-times text-danger mr-2"} />} */}
                        At least 1 court must be in existing.
                    </li>
                    <li>
                    {/* {<i className={store.getState().workflows.workflows.find(w => w.active)? "fas fa-check text-success mr-2" : "fas fa-times text-danger mr-2"} />} */}
                        There must be a valid and <b className="text-warning">ACTIVE</b> workflow within the system to associate the case to.

                    </li>
                </ol>
            </div>
        ),
        footer: () => (
            <div>

                {privileges.canUpdateContacts() ? (
                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.contacts.create.body,
                        helpCenter.content.contacts.create.footer,
                        backButton
                    )}
                    to="/dashboard/contacts/create"
                    className="btn btn-success"
                >
                    Create Contact
                </Link>
                ) : null}

                {privileges.canModerateLocations() ? (
                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.courts.create.body,
                        helpCenter.content.courts.create.footer,
                        backButton
                    )}
                    to="/dashboard/courts/create"
                    className="btn btn-success"
                >
                    Create Court
                </Link>
                ) : null}

                {privileges.canModerateWorkflows() ? (
                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.workflows.create.body,
                        helpCenter.content.workflows.create.footer,
                        backButton
                    )}
                    to="/dashboard/workflows/all"
                    className="btn btn-success"
                >
                    Create Workflow
                </Link>
                ) : null}


            </div>
        )
    },

    create: {
        body: (
            <div>
                <h3>Case Creation</h3>
                <p>Creating a case has 4 stages and finishes with the ability to save your created case as a template for future reference.</p>

                <ol>
                    <li><b>Add A Workflow - </b> In stage 1 you will select the workflow that you would like to use for this case. This <b>CANNOT</b> be changed after the case has been created. Also note that you may delete or change the source workflow after a case has been created and it will <b>NOT</b> affect the case. Cases are locked into the worklow and its settings upon creation.</li>
                    <li><b>Associate Contact - </b> On stage 2 you will be asked to either associate this case to a contact within the system or create a new contact on the spot.</li>
                    <li><b>Assign Users - </b> Stage 3 is where you assign users to all roles that are designated for the associated workflow</li>
                    <li><b>Finalize Details - </b>The last stage is where you will associate a court and lead attorney to the case and set the billing type of the case. You will have the option to see all prior cases settings as you finish creating the case.</li>
                </ol>

            </div>
        ),

    }

}
