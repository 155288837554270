/*
Documentation

Creates an alert via ReactBSAlert.
It can be called anywhere inside the system by doing the following

import { toggleAlertBS } from 'store/functions/system/system'
toggleAlertBS(false (success) / true (error), 'Insert Message Here')

*/

import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { toggleAlertBS } from 'store/functions/system/system'

class AlertBS extends React.Component {

    state = {
        alert: null,
    }

    fireAlert = (error, text) => {

        const title = !error ? 'Success' : error === true ? 'Error' : error

        this.setState({
            alert: (
                <ReactBSAlert
                    danger={!error ? false : true}
                    success={error && error === true ? false : true}
                    info={error && error !== true ? true : false}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {text}
                </ReactBSAlert>
            )
        });
    };

    componentWillReceiveProps = (nextProps) => {

        const alertBS = nextProps.alertBS

        if(alertBS) {
            this.fireAlert(alertBS.error, alertBS.text)
        }

    }

    hideAlert = () => {
        this.setState({ alert: null });
        toggleAlertBS()
    }

    render() {

        return this.state.alert
    }


}
const mapStateToProps = state => {
    return {
        alertBS: state.system.alertBS,
    };
};

export default connect(mapStateToProps, '')(AlertBS);
