import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'
import { Container, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';

import OffCallNavigation from './OffCallNavigation'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';


class ContactSearch extends Component {

    state = {
        contact: {},
        canCall: true
    };

    createCall = async () => {

        this.props.setOnCall({ 
            user: this.props.viewing_user._id, 
            contact: this.state.contact._id,
            isMobile: true
        }, ANALYTIC_EVENTS.DIAL_MANUAL);

        this.setState({contact: {}, canCall: false}, () => {
            setTimeout(() => {
                this.setState({canCall: true})
            }, 5000)
        })

    }

    render() {

        const { contact, canCall } = this.state;
        const { view, setView } = this.props

        return (

            <Container className="search py-4 text-center">

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Call Center</CardTitle>
                    </CardHeader>
                    
                    <CardBody>
                        <p className="text-sm mb--5 pb-2 mt-0">Search For A Contact To Call:</p>

                        <ContactSearchInput
                            value={contact}
                            hideTitle={true}
                            titleFormatter={() => ' '}
                            onSelect={(contact) => this.setState({contact})}
                        />
                    </CardBody>
                </Card>

                {contact._id ? (
                    <Card className="text-center mt-9 dial-contact-from-search">

                        <CardHeader>
                            <CardTitle className="mb-0 text-capitalize">{renderName(contact)}</CardTitle>
                        </CardHeader>
                        
                        <CardBody>

                            <div>
                                {contact.phone ? (
                                    <div>
                                        <p className="text-sm mb-0">{formatPhone(contact.phone)}</p>
                                        {contact.email ? (
                                            <p className="text-sm mb-0">{contact.email}</p>
                                        ) : null}
                                    </div>
                                ) : (
                                    <p className="text-sm mb-0"><i className="fas fa-mobile text-danger font-weight-bold mr-2" />This contact does not have a valid phone number.</p>
                                )}

                                
                            </div>
                        </CardBody>
                        
                        {contact.phone ? (
                            <CardFooter className="text-center">
                                <button disabled={!canCall} onClick={this.createCall} className="btn btn-success btn-block">Call Contact</button>
                            </CardFooter>
                        ) : null}

                    </Card>
                ) : null}

                <OffCallNavigation 
                    view={view}
                    setView={setView}
                />

            </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ContactSearch.propTypes = {
    setOnCall: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, '')(ContactSearch);
