/*
Documentation

This renders form inputs for a task with the type "set chapter"

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _case_workflow_tasks from '_functions/case_workflow_tasks'

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const required_form_fields = [
    'value',
]

class FormTypesSetCaseNumber extends Component {

    state = {
        editing_task: {},
    };

    onFinishTask = async () => {


        let editing_task = Object.assign({}, this.state.editing_task);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(editing_task[field]) {
                editing_task[field + "State"] = 'valid';
            } else {
                editing_task[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ editing_task });

        if(!errors) {

            toggleStandardLoader(true)

            await _case_workflow_tasks.finish(this.state.editing_task._id,  {
                value: editing_task.value,
                minutes: 0,
                user: this.props.viewing_user._id,
                case_workflow: this.props.case_workflow_id,
            })

            toggleStandardLoader(false)

            this.props.toggleModal();
            this.props.forceRefresh();
            _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

        }

    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes
            })

            this.setState({editing_task: task})

        }

    }

    render() {

        const editing_task = this.state.editing_task || {};

        if(editing_task.type !== 'set case number') { return <></> }

        return (

            <>

                <StandardFormGroup
                    obj={editing_task}
                    objName='editing_task'
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                    type="text"
                    field="value"
                    title={<span className="text-capitalize">{editing_task.name}</span>}
                />

                <hr className="my-4" />

                <div className="text-right">

                    <button
                        onClick={() => this.onFinishTask()}
                        className="btn btn-success"
                    >
                        Finish Task
                    </button>
                </div>

            </>

        )

    }

}

FormTypesSetCaseNumber.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesSetCaseNumber);
