import { Link } from 'react-router-dom'
import React from 'react';
import { simulateClickOfId } from 'utils/events';
import { helpCenter } from 'store/functions/system/system';
import * as privileges from '_functions/users/privileges'

// simulates click of back button sending the user to the workflow router
const backButton = (id) => (
    <Link
        to="/"
        className="btn btn-warning"
        onClick={(e) => {
            e.preventDefault();
            simulateClickOfId(id);
            helpCenter.toggle(
                helpCenter.content.workflows.view_router.body,
                helpCenter.content.workflows.view_router.footer,
            )
        }}
    >
        <i className="fas fa-arrow-left mr-2" />Back
    </Link>
)

const hcButton = (params) => (
    <Link
        onClick={(e) => {
            e.preventDefault();
            window.history.pushState(null, null, `${window.location.pathname}?view=${params.viewTo}`);
            window.dispatchEvent(new Event('popstate'));
            helpCenter.toggle(
                helpCenter.content.workflows[params.hcTo].body,
                helpCenter.content.workflows[params.hcTo].footer,
                (
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            window.history.pushState(null, null, `${window.location.pathname}?view=${params.viewFrom}`);
                            window.dispatchEvent(new Event('popstate'));
                            helpCenter.toggle(
                                helpCenter.content.workflows[params.hcFrom].body,
                                helpCenter.content.workflows[params.hcFrom].footer,
                            )}
                        }
                        to="/"
                        className="btn btn-warning"
                    >
                        <i className="fas fa-arrow-left mr-2" />Back
                    </Link>
                )
            )}
        }
        to="/"
        className="btn btn-success"
    >
        {params.name}
    </Link>
)

export default {

    create: {
        body: (
            <div>
                <h3>Workflow Creation</h3>
                <p>Workflows create a structured way to move cases a long and allow a limitless amount of automations to be created inside them.</p>
                <p>Note that you may play around with creating workflows as they will not become available to be used in case creation until you set their status to active. </p>
                <p>On this screen you may:</p>
                <ol>
                    <li>Create new workflows by clicking on the "Create Workflow" button.</li>
                    <li>View / update workflows by clicking on an individuals workflows "View Workflow" button.</li>
                    <li>Delete a workflow by clicking on the red trash can icon.</li>
                </ol>

            </div>
        ),
    },

    view_router: {
        body: (
            <div>
                <h3>Navigating A Workflow</h3>
                <p>On this tab you may select an action to perform on the selected worfklow.</p>

                <ol>

                    <li>
                        <b>Roles - </b>
                        The Roles tab allow you to add the desired user roles to this workflow. You must create at least one (1) role before a workflow can be set to active.
                        <br/>
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                simulateClickOfId('worfklow-router-roles')
                                helpCenter.toggle(
                                    helpCenter.content.workflows.view_roles.body,
                                    helpCenter.content.workflows.view_roles.footer,
                                    backButton('workflow-view-back')
                                )
                            }}
                            className="text-underline text-success text-capitalize font-weight-bold"
                        >
                            Navigate To Roles
                        </a>
                    </li>

                    <li>
                        <b>Automations Flow - </b>The Automations Flow tab allows you to create the specific steps and tasks for this workflow. It is the heart of any workflow.
                        <br/>
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                simulateClickOfId('worfklow-router-automations')
                                helpCenter.toggle(
                                    helpCenter.content.workflows.view_flow.body,
                                    helpCenter.content.workflows.view_flow.footer,
                                )
                            }}
                            className="text-underline text-success text-capitalize font-weight-bold"
                        >
                            Navigate To Flow
                        </a>
                    </li>

                    <li>
                        <b>Documents - </b>The Documents tab allows you to associate template documents to this workflow for easy access and automatic population of fields.
                        <br/>
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                simulateClickOfId('worfklow-router-documents')
                                helpCenter.toggle(
                                    helpCenter.content.workflows.view_documents.body,
                                    helpCenter.content.workflows.view_documents.footer,
                                    backButton('workflow-view-back')
                                )
                            }}
                            className="text-underline text-success text-capitalize font-weight-bold"
                        >
                            Navigate To Documents
                        </a>
                    </li>

                    <li>
                        <b>Settings - </b>The Settings tab allows you to control the active status of the workflow as well as it's name.
                        <br/>
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                simulateClickOfId('worfklow-router-settings')
                                helpCenter.toggle(
                                    helpCenter.content.workflows.view_settings.body,
                                    helpCenter.content.workflows.view_settings.footer,
                                    backButton('workflow-view-back')
                                )
                            }}
                            className="text-underline text-success text-capitalize font-weight-bold"
                        >
                            Navigate To Settings
                        </a>
                    </li>

                </ol>
            </div>
        ),
    },

    view_roles: {
        body: (
            <div>
                <h3>Workflow Roles</h3>
                <p>Workflow roles are how a case knows how to assign users to a task. You may add any number of roles to a workflow and when creating a case with this workflow you will assign a user to each role specified. This will track which tasks are assigned to associated user and alert them of due dates and unfinished tasks as the case progresses.</p>
            </div>
        ),
    },

    view_settings: {
        body: (
            <div>
                <h3>Workflow Settings</h3>
                <p>Workflow settings are simple. One controls the name of the workflow and the other controls whether or not the workflow is active. If a workflow is not active it will not show up under the available list of workflows when creating a case allowing you to play around with it before it can be used by any employee.</p>
                <p>To set a workflow to active the workflow must have:</p>
                <ol>
                    <li>At least 1 role associated to the workflow under the "Roles" tab on the main workflow screen.</li>
                    <li>At least one flow step associated to the workflow under the "Automation Flow" tab on the main workflow screen.</li>
                </ol>
            </div>
        ),
        footer: (
            <>
                {hcButton({
                    viewTo: 'roles',
                    hcTo: 'view_roles',
                    hcFrom: 'view_settings',
                    viewFrom: 'settings',
                    name: 'View Roles'
                })}

                {hcButton({
                    viewTo: 'automations',
                    hcTo: 'view_flow',
                    viewFrom: 'settings',
                    hcFrom: 'view_settings',
                    name: 'View Flow'
                })}

            </>
        )
    },

    view_documents: {
        body: (
            <div>
                <h3>Workflow Documents</h3>
                <p>Workflow docs work through adding already uploaded template docs to create a list of documents that can be generated for a case and in some cases sent to a client to sign. Fields in a template doc will be pre-filled with the contact and case information associated. Before adding a document to this workflow you must create and upload one via the template doc uploader.</p>
            </div>
        ),
        footer: (

            privileges.canModerateTemplateDocs() ? (
                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.template_docs.create.body,
                        helpCenter.content.template_docs.create.footer,
                    )}
                    to="/dashboard/template_docs/all"
                    className="btn btn-success"
                >
                    View Template Docs
                </Link>
            ) : null
        )
    },


    view_flow: {
        body: (
            <div>

                <h3>Workflow Automations Flow</h3>
                <p>This page allows you to associate steps and tasks to a workflow. You must create at least one (1) step before a case can be created</p>

                <h3>Steps</h3>
                <p>Steps follow a logical order inside a case. In theory step 1 moves to step 2, step 2 moves to step 3, etc. By clicking on the edit icon of the step name <b className="text-info-original">(Highlighted in blue)</b> you are able to assign a special order to the steps in this workflow. </p>
                <p>For instance you can make step one be able to move to step 3 <b>OR</b> step 4 and skip step 2 completely. This allows for forked workflows and the ability to create special automations based on what happens during a case. If you do not assign a special movement order to step it will automatically move to the next step in the workflow order.</p>
                <p>You may also update the step order in the dropdown created by clicking on this same icon.</p>

                <h3>Tasks</h3>
                <p>Tasks are how a step is fulfilled and you may create an unlimited number of tasks for a given step. There are three types of tasks that can be created:</p>
                <ol>
                    <li><b>Text - </b> The task may be completed by typing something such as "called client", "Contacted Opposing Attorney", etc.</li>
                    <li><b>Upload - </b>The task may be completed by uploading 1 or more documents.</li>
                    <li><b>Appointment - </b>The task may be completed by creating an appointment related to the case.</li>
                </ol>
                <p>When creating a task you may also assign a role (created in the roles tab of a workflow) and the time it is due after assignment.</p>
                <p>Whenever a case moves steps all tasks are assigned to the corresponding user and the due date is created for the each task to be finished.</p>

                <h3>Automation View</h3>
                <p>You may create automations for any given task by clicking on the <b className="text-warning">ORANGE</b> button called "Automation View" on the right side of the screen across from every step. Automations are fired <b>whenever a task is finished.</b></p>
                <p>Once this button is clicked you will see a green button with the text "Add a new automation" under every task. When you click this button you will be able to fill out an automation for the associated task.</p>
                <p>Automations include the following:</p>
                <ol>
                    <li><b>Email - </b> Lets you send an email to either: 1) the client of the case 2) the lead attorney on the case 3) a preset email address.</li>
                    <li><b>Text - </b> Lets you send an text to either: 1) the client of the case 2) the lead attorney on the case 3) a preset phone number.</li>
                </ol>
                <p>The last field of any automation is called body. This is the content of the email or text to be sent.</p>
                <p>As an example you could create the following automation: Email -> Client -> Body "Your case has been updated". This would send an email to the client of the related case saying "Your case has been updated." whenever that task is finished. </p>
                <p>You could also create an automation with the following: Text -> Phone Number "555 555 5555" -> Body "Your court case was updated". This will send a text to the phone number "555 555 5555" saying "Your court case was updated." whenever this task is finished for a given case. </p>

                <h3>Workflow Notes</h3>
                <p>Note that once a step is created you must always have one (1) step associated to the workflow.</p>

            </div>
        ),
    }

}
