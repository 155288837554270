/*
Documentation

this file will create a fortispay subscription or delete and old one and create a new one if needed
this file renders the step tracker to create a subscription

if a valid recurring object is passed in when it will populate all fields with the recurring data

on submit it will erase the old recurring allowing for us effectively change a subscription without
validation on fields from fortispay

*/

import StepTracker from 'components/functional/progress/StepTracker';
import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup, Input, Row, Card, CardBody, CardHeader, CardTitle, CardFooter } from "reactstrap";
import DatePicker from 'react-datepicker'
import * as privileges from '_functions/users/privileges';

import { formatMoney } from 'utils/currency';
import moment from 'moment';
import _payments from '_functions/payments';
import _invoices from '_functions/invoices';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'

class CreatePaymentPlan extends React.Component {

    state = {
        step                        : 1,
        canSave                     : true,
        _id                         : null,
        transaction_amount          : null,
        interval                    : null,
        interval_type               : null,
        start_date                  : null,
        account_vault_api_id        : null,
        delete_recurring            : null,
        delete_recurring_internal   : null,
        date_error                  : null,
        amount_error                : null,
    }

    componentDidMount = () => {

        const recurring = this.props.recurring

        if(recurring && recurring._id) {
            this.setState({
                //fields to populate
                transaction_amount    : recurring.transaction_amount,
                start_date            : recurring.start_date,
                interval              : recurring.interval,
                interval_type         : recurring.interval_type,
                account_vault_api_id  : recurring.fortispay_account_vault_api_id,
                _id                   : recurring._id
            })
        }
    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        //set state and clear recurring interval if the type of payment plan changes
        this.setState({ ...newState, interval: stateName === 'interval_type' ? null : newState.interval, });

    };

    confirmTotal = () => this.setState({step: 2})

    onSetPaymentPlan = () => {

        const { interval, interval_type, start_date, account_vault_api_id } = this.state;

        const transaction_amount = parseFloat(this.state.transaction_amount)

        //set state bases on if we have a valid amount
        if(!transaction_amount || !_payments.validate.decimals(transaction_amount)) {
            return this.setState({transaction_amountState: 'invalid'})
        }

        if(transaction_amount <= 0) {

            return this.setState({
                amount_error: 'Amount must be greater than 0.',
                transaction_amountState: 'invalid'
            })

        }  else {

            this.setState({amount_error: null})

        }

        if(!interval)               return this.setState({intervalState:      'invalid'});
        if(!interval_type)          return this.setState({interval_typeState: 'invalid'});
        if(!start_date)             return this.setState({start_dateState:    'invalid'});
        if(!account_vault_api_id)   return this.setState({account_vault_api_idState: 'invalid'});

        const today = moment.unix(moment.now() / 1000).format('YYYY-MM-DD')
        const dateIsFuture = moment(start_date).isAfter(today)

        if(!dateIsFuture) {
            return this.setState({start_dateState: 'invalid', date_error: true})
        } else {
            this.setState({date_error: null})
        }

        this.setState({step: 3})

    }


    onFinish = async () => {

        toggleStandardLoader(true);

        const state = this.state;

        const data = {
            case: this.props.case._id,
            transaction_amount: state.transaction_amount,
            interval: state.interval,
            interval_type: state.interval_type,
            start_date: moment(state.start_date).format('YYYY-MM-DD'),
            account_vault_api_id: state.account_vault_api_id,
        }

        let action;

        //if this is successful the component will un-mount via parent component logic
        if(this.state._id) {
            action = await _payments.recurrings.update(this.state._id, data)
        } else {
            action = await _payments.recurrings.create(data)
        }

        //incase this fails create an alert
        if(!action.success) {

            toggleAlertBS(true, 'An error occurred creating this plan.')

        } else {

            if(this.props.onSuccess) { this.props.onSuccess(action) }

        }

        toggleStandardLoader(false);

    }

    render() {

        const { step, transaction_amount, interval, interval_type, start_date, amount_error, } = this.state;

        const { invoices, account_vaults } = this.props

        const total = _invoices.calculate.totalRemainingByCase(invoices)
        const canUpdateFinances = privileges.canUpdateFinances();

        return (

            canUpdateFinances ? (

                <Card>

                    {this.state.alert}

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">Setup Payment Plan</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StepTracker
                            current={step}
                            // onChangeStep={(step) => {}}
                            steps={[
                                {name: 'Case Total'},
                                {name: 'Payment Plan'},
                                {name: 'Finalize'},
                            ]}
                        />

                        <hr className="my-3" />

                        {step === 1 ? (
                            <>

                            <div>

                                <div className="my-3">
                                    <p className="text-sm mb-1 font-weight-bold">Current Case Total Via <b className="text-underline">SENT</b> Invoices:</p>

                                    <div className="table-responsive mx--3">
                                        <table className="table">

                                            <thead>
                                                <tr>
                                                    <th>Invoice</th>
                                                    <th className="text-right">Remaining Balance</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {invoices && invoices.length ? invoices.map(i => (
                                                i.status === 'sent' ? (
                                                    <tr key={i._id}>
                                                        <td>{i.name}</td>
                                                        <td
                                                            className={
                                                                i.total === i.total_paid ?
                                                                'text-right text-success' :
                                                                'text-right'
                                                            }
                                                        >
                                                            {formatMoney(i.total - i.total_paid)}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            )) : null}

                                            <tr>
                                                <td className="font-weight-bold">Total:</td>
                                                <td className="text-right font-weight-bold">
                                                {formatMoney(total)}
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>


                                </div>

                            </div>

                            </>
                        ) : null}

                        {step === 2 ? (
                            <>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="transaction_amount" >What is the recurring amount of the payment plan?</label>
                                    {amount_error ? ( <span className="text-danger"><br />{amount_error}</span>) : null}
                                    <Input
                                        id="transaction_amount"
                                        value={transaction_amount || ''}
                                        type="number"
                                        valid={ this.state.transaction_amountState === "valid" }
                                        invalid={ this.state.transaction_amountState === "invalid" }
                                        onChange={e => this.onInputChange(e, "transaction_amount") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="interval_type" >Will this plan charge the client daily, weekly, or monthly?</label>
                                    <Input
                                        id="interval_type"
                                        value={interval_type || ''}
                                        type="select"
                                        valid={ this.state.interval_typeState === "valid" }
                                        invalid={ this.state.interval_typeState === "invalid" }
                                        onChange={e => this.onInputChange(e, "interval_type") }
                                    >
                                        <option value={null}></option>
                                        <option value="d">Daily</option>
                                        <option value="w">Weekly</option>
                                        <option value="m">Monthly</option>
                                    </Input>
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="interval" >How often should this plan run?</label>
                                    <Input
                                        id="interval"
                                        disabled={interval_type ? false : true}
                                        value={this.state.interval || ''}
                                        type="select"
                                        valid={ this.state.intervalState === "valid" }
                                        invalid={ this.state.intervalState === "invalid" }
                                        onChange={e => this.onInputChange(e, "interval") }
                                    >
                                        <option value={null}></option>
                                        <option value={1}>Every {_payments.format.intervalType(interval_type)}</option>
                                        <option value={2}>Every 2  {_payments.format.intervalType(interval_type)}s</option>
                                        <option value={3}>Every 3  {_payments.format.intervalType(interval_type)}s</option>
                                    </Input>
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="start_date" >
                                        When will this plan start?
                                        {this.state.date_error ? (
                                            <span className="text-danger"><br />Date must be in the future.</span>
                                        ) : null}
                                    </label>
                                    <div
                                        className={
                                            this.state.start_dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                            this.state.start_dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                        }
                                    >
                                        <DatePicker
                                            selected={start_date ? moment(start_date).toDate() : null}
                                            onChange={date => this.setState({
                                                start_date: date,
                                                start_dateState: 'valid'
                                            })}
                                        />
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="account_vault_api_id" >What Contact Payment Method Should Be Charged?</label>
                                    <Input
                                        id="account_vault_api_id"
                                        value={this.state.account_vault_api_id || ''}
                                        type="select"
                                        valid={ this.state.account_vault_api_idState === "valid" }
                                        invalid={ this.state.account_vault_api_idState === "invalid" }
                                        onChange={e => this.onInputChange(e, "account_vault_api_id") }
                                    >
                                        <option value={null}></option>
                                        {account_vaults && account_vaults.length ? account_vaults.map(a => (
                                            <option key={a.id} value={a.account_vault_api_id}>
                                                {_payments.cards.getName(a.account_type)}: {a.first_six.slice(0,1)}......{a.last_four} {' '}
                                                Exp: {a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}
                                                {a.id === this.props.case.contact.default_account_vault_id ? ' (DEFAULT)' : null}
                                            </option>
                                        )) : (
                                            <option value={null}>This contact does not have a payment method on file.</option>
                                        )}
                                    </Input>
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>

                            </>
                        ) : null}

                        {step === 3 ? (

                            <div className="bg-secondary p-4 my-4">

                                <p className="text-sm">
                                    You are about to finalize the recurring payment plan for this case with a total of: <br />
                                    <b>{formatMoney(total)}</b>
                                </p>

                                <p className="text-sm mb-0">
                                    <span style={styles.spanWidth}>Payment Amount: </span>
                                    <b>{formatMoney(transaction_amount)}</b>
                                </p>

                                <p className="text-sm mb-0">
                                    <span style={styles.spanWidth}>Payment Type: </span>
                                    <b>{_payments.format.intervalType(interval_type).replace('Day', 'Dai')}ly</b>
                                </p>

                                <p className="text-sm">
                                    <span style={styles.spanWidth}>Payment Interval: </span>
                                    <b>
                                        {interval === 1 ?
                                            <span>Every 1 {interval_type}</span> :
                                            <span>Every {interval} {_payments.format.intervalType(interval_type)}(s)</span>
                                        }
                                    </b>
                                </p>

                                <p className="text-sm">
                                    <span style={styles.spanWidth}>Start Date: </span>
                                    <b>{moment(start_date).format('MMMM Do, YYYY')}</b>
                                </p>

                            </div>

                        ) : null}

                    </CardBody>

                    <CardFooter className="text-right">

                        {step === 1 ? (
                            <button onClick={this.confirmTotal} className="btn btn-success">Confirm Case Total</button>
                        ) : null}

                        {step === 2 ? (
                            <Row>
                                <Col xs={6} className="text-left">
                                    <button onClick={() => this.setState({step: 1})} className="btn btn-outline-success" > <i className="fas fa-arrow-left mr-2" /> Back </button>
                                </Col>

                                <Col xs={6}>
                                    <button onClick={this.onSetPaymentPlan} className="btn btn-success" > Set Payment Plan </button>
                                </Col>
                            </Row>
                        ) : null}

                        {step === 3 ? (
                             <Row>

                                <Col xs={6} className="text-left">
                                <button onClick={() => this.setState({step: 2})} className="btn btn-outline-success" > <i className="fas fa-arrow-left mr-2" /> Back </button>
                                </Col>

                                <Col xs={6}>
                                    <button onClick={this.onFinish} className="btn btn-success" > Finalize Payment Plan </button>
                                </Col>

                            </Row>
                        ) : null}

                    </CardFooter>

                </Card>

             ) : (
                <div className="alert alert-danger">You account does not have access to update payment records.</div>
            )


        );
    }
}

const styles = {

    spanWidth: {
        display: 'inline-block',
        width: 140
    }

}

CreatePaymentPlan.propTypes = {
    case: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    recurring: PropTypes.object,
    account_vaults: PropTypes.array,
    invoices: PropTypes.array,
}

export default CreatePaymentPlan

