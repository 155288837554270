import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { formatMoney } from 'utils/currency';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import renderName from 'utils/renderName';

import _trust_logs from '_functions/trust_logs';
import Circle from 'components/markup/loading/Circle';

import ConfirmationModal from 'components/functional/modals/Confirmation.js'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';


class TrustHistory extends React.Component {

    state = {
        contactToDelete: null,
        showModal: false,
        payment: null,
        trust_logs: null,
        logToReverse: null,
    }

    columns = [
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => (
                <div>
                    <div><span style={{display: 'inline-block', width: 65}}>Created:</span> {moment.unix(row.created_at).format('MM/DD/YYYY')}</div>
                    <div className="text-capitalize">
                    By: {row.user ? renderName(row.user) : 'System Generated'}
                    </div>

                </div>
            ),
        },
        {
            dataField: "date",
            text: "Transaction Date",
            formatter: (cell, row) => (
                <div>{moment.unix(row.date).format('MM/DD/YYYY')}</div>
                )
        },
        {
            dataField: "payment_method",
            text: "Payment Method",

        },
        {
            dataField: "reason",
            text: "Reason",
            formatter: (cell, row) => (
                <div className="text-capitalize" style={{maxWidth: 180, whiteSpace: 'pre-line'}}>{row.reason}</div>
            )
        },
        {
            dataField: "description",
            text: "Memo",
            formatter: (cell, row) => (
                <div style={{maxWidth: 180, whiteSpace: 'pre-line'}}>{row.description ? row.description : '-'}</div>
            )
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => (
                <b><span className={row.amount < 0 ? 'text-danger' : 'text-success'}>
                    {row.amount > 0 ? '+' : null}
                    {formatMoney(row.amount)}
                </span></b>
            )
        },
        {
            dataField: "balance",
            text: "Balance",
            formatter: (cell, row) => {
                return formatMoney(row.balance)
            }
        },
        {
            dataField: "_id",
            text: "Reverse",
            formatter: (cell, row) => {
                if(row.reason && row.reason !== '-' && row.reason !== 'Trust Log Reversal' && !row.reversed_on) {
                    return (
                        <button className="btn btn-outline-warning" onClick={() => this.setState({logToReverse: row})}>
                            Reverse
                        </button>
                    )
                } else {
                    return ''
                }
            }
        },

    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    onReverse = async (_logToReverse) => {

        toggleStandardLoader(true);
        const reversed = await _trust_logs.reverse(_logToReverse._id);
        toggleStandardLoader(false);

        if(reversed.success) {
            this.fetchLogs();
            toggleAlertBS(false, 'Trust log reversed');
        } else {
            toggleAlertBS(false, 'Something went wrong, please try again');

        }
    }

    fetchLogs = async  () => {

        const trust_logs = await _trust_logs.findByCase(this.props.case._id)
        if(trust_logs.success) this.setState({trust_logs: trust_logs.data})

    }

    listenForTrustLogsAdded = (data) => {

        if(data.data.case === this.props.case._id) {
            this.fetchLogs()
        }

    }

    componentWillUnmount = () => {

        io.off(socketEvents.trust_logs_added, this.listenForTrustLogsAdded)

    }

    componentDidMount = async () => {

        this.fetchLogs()
        io.on(socketEvents.trust_logs_added, this.listenForTrustLogsAdded)

    }

    render() {

        const { trust_logs, logToReverse } = this.state;
        const _case = this.props.case;

        return (
            <>

                <Card >

                    <CardHeader>
                        <Row>

                            <Col lg={6}>
                                <CardTitle className="mb-0 text-capitalize">
                                    Case Trust History {' '}
                                    <a target="_blank" rel="noopener noreferrer" href={`/exports/trust/history/${_case._id}`} className="ml-5 text-sm cursor-pointer">Print</a>
                                    {/* <span className="ml-4 text-sm cursor-pointer">Export CSV</span> */}
                                </CardTitle>
                            </Col>

                            <Col lg={6} className="text-right">
                                <CardTitle className="mb-0 text-capitalize">Total In Trust: {formatMoney(_case.billing_in_trust)}</CardTitle>
                            </Col>

                        </Row>
                    </CardHeader>

                    {trust_logs && trust_logs.length ? (
                        <ToolkitProvider
                            data={trust_logs}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div id="trust-history-table" className="table-not-fixed table-responsive table-vertical-align table-no-margin">
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <CardBody>
                            {trust_logs ? (
                                'No trust logs have been created for this case yet.'
                            ) : (
                                <Circle />
                            )}
                        </CardBody>
                    )}

                </Card>

                <ConfirmationModal
                    showModal={logToReverse ? true : false}
                    toggleModal={() => this.setState({logToReverse: false})}
                    title="Reverse Trust Payment"
                    body={(
                        <span>This cannot be undone and will reverse the trust log for {logToReverse ? logToReverse.amount > 0 ? '+' : '' : ''}{formatMoney(logToReverse ? logToReverse.amount : '')}.</span>
                    )}
                    onConfirmation={() => this.onReverse(logToReverse)}
                />


            </>
        );
    }
}

export default TrustHistory
