import React from 'react';
import Question from './Question';

const Questions = ({questions, answers, onSetAnswers, nestedLevel, questionnaireId, contact, onMarkDocNaOnNo}) => (
    <div className="branch">
        {questions.map(question => (
            <Question 
                key={question._id}
                onSetAnswers={onSetAnswers}
                question={question}
                answers={answers}
                nestedLevel={nestedLevel}
                questionnaireId={questionnaireId}
                contact={contact}
                onMarkDocNaOnNo={onMarkDocNaOnNo}
            />
        ))}
    </div>
)

export default Questions
