import _activity from '_functions/activity'

const sendPing = (user_id, minutes) => _activity.update({ user_id, minutes})

const monitorActivity = (user_id) => {

    // on load set activity immediately
    sendPing(user_id, 1)

    var minutesSinceLastActivity = 0;

    setInterval(function(){

        minutesSinceLastActivity++;

        // update the server with last activity time
        sendPing(user_id, minutesSinceLastActivity)

    }, 60000);

    //The function that will be called whenever a user is active
    function activity(){
        //reset the minutesSinceLastActivity variable back to 0
        minutesSinceLastActivity = 0;
    }

    // whenever these events happen set last activity to 0
    var activityEvents = [ 'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart' ];

    activityEvents.forEach(function(eventName) {
        document.addEventListener(eventName, activity, true);
    });

    // if tab is changed to visible we have activity
    document.addEventListener("visibilitychange", async () => {
        if (document.visibilityState === "visible") {
            activity();
        }
    })

    // if window is focused we have activity
    window.addEventListener("focus", async function(event) {
        activity();
    });
}

export default monitorActivity
