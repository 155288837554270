

import * as actionTypes from '../../actions';
import store from 'store';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { add, remove, update } from 'store/functions/_crud';
import _users from '_functions/users';

import onDownloadDocument, { getImageAsBase64 } from '_functions/documents/download';


export const setUsers = async() => {
    return new Promise( async (resolve) => {

        setListener()

        const users = await _users.find(`?filter=deleted__false{bool}&limit=20000&sort=given_name__asc|family_name__asc`)

        if(users.success) {

            store.dispatch({
                type: actionTypes.SET_USERS,
                payload: users
            });

            resolve({success: true});

        } else {

            resolve({success: false, message: users.message});

        }

    })

}

export const setUserImage = (document_id, cb) => {

    store.dispatch({
        type: actionTypes.SET_USER_IMAGE,
        payload: {
            [document_id]: 'loading'
        }
    });

    onDownloadDocument(document_id, (err, doc) => {
        if(err) {
            return cb ? cb(err) : null;
        }

        if(cb) cb(null, doc);
        store.dispatch({
            type: actionTypes.SET_USER_IMAGE,
            payload: {
                [document_id]: getImageAsBase64(doc)
            }
        });
    })

}

const setListener = () => {

    io.on(socketEvents.users_added, async (data) => {
        add(data.data, 'SET_USERS', ['users', 'users']);
    })

    io.on(socketEvents.users_deleted, async (data) => {
        remove(data.data._id, 'SET_USERS', ['users', 'users']);
    })

    io.on(socketEvents.users_updated, async (data) => {
        update(data.data, 'SET_USERS', ['users', 'users']);
    })

}
