/*
Documentation

This renders form inputs for a task with the type "set chapter"

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _case_workflow_tasks from '_functions/case_workflow_tasks'

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class FormTypesSendTemplateDoc extends Component {

    state = {
        editing_task: {},
        values: {},
    };

    onFinishTask = async () => {

        let custom_fields = []
        const editing_task = this.state.editing_task

        if(!editing_task.template_doc) return

        Object.keys(this.state.values).forEach(key => {
            custom_fields.push({name: key, value: this.state.values[key]})
        })

        let errors = false

        editing_task.template_doc.custom_fields.forEach(f => {
            if(f.required === 'yes') {

                const foundValue = custom_fields.find(cf => cf.name === f.name);

                if(!foundValue) return errors++
                if(foundValue && !foundValue.value) errors ++

            }
        })

        if(errors) return this.setState({errors: true})

        toggleStandardLoader(true)

        await _case_workflow_tasks.finish(this.state.editing_task._id,  {
            value: `Sent the template document ${editing_task.template_doc.name} for signing.`,
            minutes: 0,
            user: this.props.viewing_user._id,
            case_workflow: this.props.case_workflow_id,
            custom_fields,
        })

        toggleStandardLoader(false)

        this.props.toggleModal();
        this.props.forceRefresh();
        _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes
            })

            this.setState({editing_task: task});

        }

    }

    render() {

        const { values, errors } = this.state
        const editing_task = this.state.editing_task || {};

        if(editing_task.type !== 'send template doc') { return <></> }

        return (

            <>

                {editing_task.template_doc && editing_task.template_doc.custom_fields && editing_task.template_doc.custom_fields.length ? editing_task.template_doc.custom_fields.map(field => (
                    <StandardFormGroup
                        key={field._id}
                        obj={values}
                        objName="values"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid', generate_all: 'false'} })}
                        field={field.name}
                        title={<span className="text-capitalize">{field.name} {field.required === 'yes' ? '*' : null}</span>}
                        type="textarea"
                    />
                )) : (
                    <div></div>
                )}

                {errors ? <div className="alert alert-danger">You must fill our all required fields before generating this document</div> : null}

                <hr className="my-4" />

                <div className="text-right">

                    <button
                        onClick={() => this.onFinishTask()}
                        className="btn btn-success"
                    >
                        Finish Task
                    </button>
                </div>

            </>

        )

    }

}

FormTypesSendTemplateDoc.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesSendTemplateDoc);
