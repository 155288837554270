/*
Documentation

this page holds all the routes for the dashboard layout

*/

import Account                  from '../pages/account';

import AppointmentsView         from '../pages/appointments/AppointmentsView'
import AppointmentsAll          from '../pages/appointments/AppointmentsAll'
import AppointmentsSearch       from '../pages/appointments/Search'

import Calendar                 from '../pages/calendar/Calendar';
import Lookup                 from '../pages/lookup';

import CallCenterUsers          from '../pages/call_center'
import CallCenterRedirect       from '../pages/call_center/StartCallRedirect'
import CallCenterAdmin          from '../pages/call_center_admin'
import CallCenterVoicemail      from '../pages/call_center/Voicemail'
import CallCenterTest           from '../pages/call_center_test'

import CasesAll                 from '../pages/cases/All';
import CasesByStep                 from '../pages/cases/ByStep';
import CasesByManager                 from '../pages/cases/CaseManager';
import CasesCreate              from '../pages/cases/Create/index';
import CasesView                from '../pages/cases/View/index';
import CasesCategory                from '../pages/cases/Category';

import Directory                from '../pages/directory';


import Sales         from '../pages/sales';

import CaseTemplatesAll         from '../pages/case_templates/All';

import ContactsCreate           from '../pages/contacts/Create';
import ContactsAll              from '../pages/contacts/All';
import ContactsView             from '../pages/contacts/View/index';

import Collections             from '../pages/collections';

import DocumentsUsers           from '../pages/documents/User';
import DocumentsAdmin           from '../pages/documents/Admin';
import DocumentsMerge           from '../pages/documents/Merge';

import Help                     from '../pages/help';

import GarnishmentsAll          from '../pages/garnishments/All'
import GarnishmentsView         from '../pages/garnishments/View'

import Links                    from '../pages/links'

import Messenger        from '../pages/messenger/View'

import MessagingOverview        from '../pages/messaging/Overview'
import MessagingEmailView       from '../pages/messaging/EmailsView'

import MicrosoftIframeOffice    from '../pages/microsoft/IframeOffice'

import NotificationsView        from '../pages/notifications/View'
import NotificationsSettings    from '../pages/notifications/Settings'

import ObjectionsAll            from '../pages/objections/All'
import ObjectionsView           from '../pages/objections/View'

import TasksUser                from '../pages/tasks/User';
import TasksAdmin               from '../pages/tasks/Admin';

import TemplateDocs             from '../pages/template_docs/index'
import TemplateDocsPDF          from '../pages/template_docs/PDF'

import Stats          from '../pages/stats'

import Unauthorized             from '../pages/unauthorized'
import UtilityFullRedirect      from '../pages/utility/FullRedirect'

import UserTypes                from '../pages/user_types/All'

import WikisAll                from '../pages/wikis/All'
import WikisView                from '../pages/wikis/View'

import * as privileges          from '_functions/users/privileges'


export default () => {

    return[

        /***********************
        ACCOUNT
        ************************/
        {
            path: "/lookup",
            component: Lookup,
        },
        /***********************
        ACCOUNT
        ************************/
        {
            path: "/settings",
            component: Account,
        },

        /***********************
        Appointments
        ************************/
        {
            path: "/appointments/view/:_id",
            component: privileges.canModerateAppointments() ?  AppointmentsView : Unauthorized,
        },
        {
            path: "/appointments/all/v1",
            component: AppointmentsAll,
        },
        {
            path: "/appointments/all",
            component: AppointmentsSearch,
        },

        /***********************
        Call Center
        ************************/
        {
            path: "/call_center",
            component: CallCenterUsers,
        },
        {
            path: "/call_center_redirect",
            component: CallCenterRedirect,
        },
        {
            path: "/call_center_admin",
            component: privileges.canViewAdminCallCenter() ? CallCenterAdmin : Unauthorized,
        },
        {
            path: "/call_center/voicemail",
            component: CallCenterVoicemail,
        },
        {
            path: "/call_center_test",
            component: CallCenterTest,
        },

        /***********************
        CASE TEMPLATES
        ************************/
        {
            path: "/case_templates/all",
            component: CaseTemplatesAll
        },


        /***********************
        CALENDAR
        ************************/
        {
            path: "/calendar/me",
            component: Calendar,
        },

        /***********************
        CONTACTS
        ************************/
        {
            path: "/contacts/all",
            component: ContactsAll,
        },
        {
            path: "/contacts/create",
            component: privileges.canUpdateContacts() ? ContactsCreate : Unauthorized,

        },
        {
            path: "/contacts/view/:_id",
            component:ContactsView,
        },

        /***********************
        COLLECTIONS
        ************************/
        {
            path: "/collections",
            component: Collections,
        },
        /***********************
        Help
        ************************/
        {
            path: "/help",
            component: Help,
        },

        /***********************
        DocumentFolders
        ************************/
        {
            path: "/documents/user",
            // component: privileges.canModerateTags() ?  DocumentFoldersAll : Unauthorized,
            component: DocumentsUsers
        },
        {
            path: "/documents/admin",
            // component: privileges.canModerateTags() ?  DocumentFoldersAll : Unauthorized,
            component: privileges.canModerateMissingDocs() ?  DocumentsAdmin : Unauthorized
        },
        {
            path: "/documents/merge/:case_id",
            component: DocumentsMerge
        },

        /***********************
        Garnishments
        ************************/
        {
            path: "/garnishments/:case",
            component: GarnishmentsAll,
        },
        {
            path: "/garnishments/:case/:garnishment",
            component: GarnishmentsView,
        },

        /***********************
        Microsoft
        ************************/
        {
            path: "/microsoft/365/emails",
            component: MicrosoftIframeOffice,
        },

        /***********************
        Links
        ************************/
        {
            path: "/links",
            component: Links,
        },

        /***********************
        Messanger
        ************************/
        {
            path: "/messenger",
            component: Messenger,
        },

        /***********************
        Messaging
        ************************/
        {
            path: "/messaging/emails",
            component: MessagingOverview,
        },
        {
            path: "/messaging/emails/view/:email/:message_id",
            component: MessagingEmailView,
        },

        /***********************
        Notifications
        ************************/
        {
            path: "/notifications",
            component: NotificationsView,
        },
        {
            path: "/notifications/settings",
            component: NotificationsSettings,
        },

        /***********************
        Objections
        ************************/
       {
            path: "/objections/:case",
            component: ObjectionsAll,
        },
        {
            path: "/objections/:case/:objection",
            component: ObjectionsView,
        },


        /***********************
        OVERVIEWS
        ************************/
        {
            path: "/cases/all",
            component: privileges.canViewCases() ? CasesAll : Unauthorized,
        },
        {
            path: "/cases/step",
            component: privileges.canViewCases() ? CasesByStep : Unauthorized,
        },
        {
            path: "/cases/case_manager",
            component: privileges.canViewCases() ? CasesByManager : Unauthorized,
        },
        {
            path: "/cases/category",
            component: privileges.canViewCases() ? CasesCategory : Unauthorized,
        },

        {
            path: "/cases/create",
            component: privileges.canUpdateCases() ? CasesCreate : Unauthorized,

        },
        {
            path: "/cases/view/:_id",
            component: privileges.canViewCases() ? CasesView : Unauthorized,
        },
      
        {
            path: "/directory",
            component: Directory,
        },

        
        /***********************
        Stats
        ************************/
        {
            path: "/team/stats",
            component: Stats,
        },

        /***********************
        Tasks
        ************************/
        {
            path: "/tasks/user",
            component: TasksUser,
        },

        {
            path: "/tasks/admin",
            component: privileges.canModerateUnfinishedTasks() ? TasksAdmin : Unauthorized,
        },


        /***********************
        TEMPLATE DOCS
        ************************/
        {
            path: "/template_docs/all",
            component: privileges.canModerateTemplateDocs() ? TemplateDocs : Unauthorized,
        },
        {
            path: "/template_docs/:template_doc_id",
            component: TemplateDocsPDF
        },

        /***********************
        User Types
        ************************/
        {
            path: "/user_types/all",
            component: UserTypes,
        },

        /***********************
        UTILITY
        ************************/
        {
            path: "/full_redirect",
            component: UtilityFullRedirect,
        },

        /***********************
        Sales
        ************************/
        {
            path: "/sales",
            component: Sales,
        },

        /***********************
        Wikis
        ************************/
        {
            path: "/help/wikis",
            component: WikisAll,
        },
        {
            path: "/help/wikis/:_id",
            component: WikisView,
        },


    ]

}
