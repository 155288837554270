import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';

class Schedule extends Component {


    state = {
        schedule: null
    };

    componentDidMount = async () => {

        const schedule = await _payments.projections.schedule(this.props.case._id)
        if(schedule.success) this.setState({schedule: schedule.data})

    }

    render() {

        const { contact } = this.props
        const { schedule } = this.state

        return (

            <Card>

                <CardHeader>
                    <h3 className="mb-0 text-capitalize">{contact.given_name}'s Payment Schedule</h3>
                </CardHeader>

                {schedule ? (

                    schedule.length ? (

                        <div className="table-responsive">
                            <table className="table" style={{tableLayout: 'fixed'}}>

                                <thead>
                                    <tr>
                                        <th style={{width: 140, maxWidth: 140}}>Date</th>
                                        <th className="text-right">Remaining Balance</th>
                                        <th className="text-right">Payment</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {schedule.map((payment, i) => (
                                        <tr key={i}>
                                            <td>{moment(payment.next_run_date_formatted).format('MMM Do, YYYY')}</td>
                                            <td className="text-right" > {formatMoney(payment.amountLeft)} </td>
                                            <td className="text-right" > {formatMoney(payment.payment)} </td>

                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0">There is no payment schedule setup for <span className="text-capitalize">{contact.given_name}</span></p>
                        </CardBody>
                    )

                ) : <Circle />}

            </Card>


        )

    }

}

export default Schedule;
