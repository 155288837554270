/*
Documentation

strips html from a string

*/

export const stripHTML = (html)  => {

    if(!html) return undefined

    var tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || "";

 }
