import Circle from 'components/markup/loading/Circle';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import _cases from '_functions/cases';

const SidebarRightDetails = ({contact, getContactIdentifier, users}) => {

    const [cases, setCases] = useState(null);

   useEffect(() => {
        
        const fetchCases = async () => {
            const cases = await _cases.find(`?filter=contact__${contact._id}|deleted__false{bool}`, true)
            setCases(cases.data);
        }

        fetchCases()

   }, [contact._id])

   if(!cases) return <Circle />

    return (

        <div>

            <div className="archk-messenger-sidebar-section">
                <h3 className="mb-0"><i className="fas fa-gavel mr-2 text-success " />  Cases</h3>
            </div>

            <ul>
                {cases.length ? cases.map(_case => (
                    <a href={`/dashboard/cases/view/${_case._id}`} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-uppercase archk-messenger-sidebar-hover-bg">
                        <div className="archk-messenger-sidebar-section ">
                            <p className="text-sm mb-0">{_case.name}</p>
                        </div>
                    </a>
                )) : (
                    <div className="archk-messenger-sidebar-section ">
                        There are no cases for this contact.
                    </div>
                )}
            </ul>

        </div>

    );

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
    };
};


export default connect(mapStateToProps, '')(SidebarRightDetails);
