/*
Documentation

Renders the contact search on the billing center sidebar

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ReceiveFunds from './ReceiveFunds';
import MakePayment from './MakePayment';
import AdjustTrust from './AdjustTrust';

import * as privileges from '_functions/users/privileges';

class TrustActions extends React.Component {

    state = {
        navigation: 2,
    }

    onChangeNavigation = (navigation) => this.setState({navigation})

    render() {

        const { invoices } = this.props
        const { navigation } = this.state;

        const _case = this.props.case

        const canAdjustTrust = privileges.canAdjustTrust()


        return (
            <>

                <Card>

                    <CardHeader>
                        <CardTitle>Trust Actions</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Row>

                            <Col lg={3}>

                                <ul className="pl-0" style={{listStyle: 'none'}}>
                                    
                                    <li className={navigation === 1 ? 'cursor-pointer bg-primary text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(1)}>
                                        Receive Funds
                                    </li>

                                    <li className={navigation === 2 ? 'cursor-pointer bg-primary text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(2)}>
                                        Make Payment
                                    </li>

                                    {canAdjustTrust ? (
                                        <li className={navigation === 3 ? 'cursor-pointer bg-primary text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(3)}>
                                            Adjust Trust
                                        </li>
                                    ) : null}

                                </ul>

                            </Col>

                            <Col lg={9}>

                                {navigation === 1 ? (
                                    <ReceiveFunds
                                        case={_case}
                                    />
                                ) : null}

                                {navigation === 2 ? (
                                    <MakePayment
                                        case={_case}
                                        invoices={invoices}
                                    />
                                ) : null}
                                {navigation === 3 ? (
                                    <AdjustTrust
                                        case={_case}
                                        invoices={invoices}
                                    />
                                ) : null}

                            </Col>

                        </Row>
                    </CardBody>

                </Card>


            </>
        );
    }
}


export default TrustActions
