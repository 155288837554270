/*
Documentation

This page shows all contacts on hold and lets an admin either drop
the call on hold or connect directly to it

*/

import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider  from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import _contacts from '_functions/contacts';

import renderName from 'utils/renderName'
import { formatPhone } from 'utils/text'

import { architeckCall } from 'database';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import Circle from 'components/markup/loading/Circle'

class CallCenterAdminOnHold extends React.Component {

    state = {
        startOfToday: moment.unix(Math.floor(new Date() / 1000)).startOf('day').valueOf() / 1000,
        onHold: null
    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            filterValue: (cell, row) => row.given_name + ' ' + row.family_name,
            formatter: (cell, row) => (
                <Link  to={`/dashboard/contacts/view/${row._id}`} className="text-capitalize font-weight-bold">
                    {renderName(row)}
                </Link>
            )
        },
        {
            dataField: "phone",
            text: "Phone",
            formatter: (cell, row) => formatPhone(row.phone)
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">

                        <i
                            onClick={() => this.dropOnHold(row)}
                            className="cursor-pointer fas fa-times text-warning ml-3"
                        />
                        <i
                            onClick={() => this.connectToCall(row)}
                            className="cursor-pointer fas fa-mobile text-success ml-3"
                        />

                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    dropOnHold = async (contact) => {

        architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: `/api/v1/call_center/actions/calls/drop_on_hold/${contact._id}`,
        })

    }

    connectToCall = async (contact) => {
        this.setState({shouldRedirect: `/dashboard/call_center?startCall=true&contact=${contact._id}`})
    }

    renderDate = (date) => {

        if(!date) return '-'

        if(date > this.state.startOfToday) {
            return moment.unix(date).format('h:mm A')
        } else {
            return moment.unix(date).format('MM/DD/YYYY - h:mm A')
        }

    }

    listenForContactPutOnHold = (data) => {

        const onHold = this.state.onHold ? [...this.state.onHold] : []
        onHold.push(data.data)

        this.setState({onHold})

    }

    listenForContactRemovedFromHold = (data) => {

        let onHold = this.state.onHold ? [...this.state.onHold] : []
        onHold = onHold.filter(contact => contact._id !== data.data._id)

        this.setState({onHold})

    }

    componentDidMount = async () => {

        const onHold = await _contacts.onHold()
        if(onHold.data) this.setState({onHold: onHold.data})

        io.on(socketEvents.contact_put_on_hold, this.listenForContactPutOnHold)
        io.on(socketEvents.contact_removed_from_hold, this.listenForContactRemovedFromHold)

    }

    render() {

        const { onHold } = this.state

        if(!onHold) return <div className="py-6"><Circle /></div>
        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Contacts On Hold</CardTitle>
                </CardHeader>

                {onHold && onHold.length ? (

                    <ToolkitProvider
                        data={onHold}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <div className=" table-responsive table-vertical-align">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>

                ) : (

                    <CardBody>
                        <p className="text-sm mb-0">
                            <i className="fas fa-check mr-2 text-success" /> No contacts are currently on Hold
                        </p>
                    </CardBody>

                )}
            </Card>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(CallCenterAdminOnHold);
