/*
Documentation

this page holds all of our layouts and application functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import Dashboard from 'views/dashboard/layout';
import Blank from 'views/blank/layout';
import Errors from 'views/errors/layout';
import Exports from 'views/exports/layout';
import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import SystemOnlineStatus   from 'components/markup/system/OnlineStatus'
import SystemUploads        from 'components/markup/system/Uploads'
import SystemAlertBS        from 'components/markup/system/AlertBS'
import SystemAlert          from 'components/markup/system/Alert'
import SystemLoader         from 'components/markup/system/Loader'
import StandardLoader       from 'components/markup/system/StandardLoader'
import SystemDevTickets     from 'components/system/DevTickets'

import StepsToPrep     from 'components/system/StepsToPrep'

import TransferNotifications from 'views/dashboard/pages/call_center/exports/TransferNotifications'
import OnCallBanner          from 'views/dashboard/pages/call_center/exports/OnCallBanner'
import EndCallModal          from 'views/dashboard/pages/call_center/exports/EndCallModal'
import StartCallModal        from 'views/dashboard/pages/call_center/exports/StartCallModal'

// import 'quill-paste-smart';

import init from './init'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

       const loadedSuccessfully = await init();
       this.setState({loaded: true, error: !loadedSuccessfully});

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        const { online } = this.props;

        if(this.state.shouldRedirect) return window.location.href = this.state.shouldRedirect;
        if(!this.state.loaded)        return <div></div>;
        if(this.state.error)          return <FailedConnection />;

        return (

            <div className={online ? 'online' : 'offline'}>

                <SystemOnlineStatus />
                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />
                <SystemDevTickets />
                
                <BrowserRouter>
                    <Switch>

                        <Route path="/blank"   render={props => <Blank   {...props} /> } />
                        <Route path="/exports" render={props => <Exports {...props} /> } />
                        <Route path="/errors"  render={props => <Errors  {...props} /> } />

                        <Route path="/dashboard"    render={props => (
                            <>
                                <StartCallModal />
                                <TransferNotifications />
                                <OnCallBanner />
                                <EndCallModal />
                                <SystemUploads />
                                <StepsToPrep />

                                <Dashboard {...props} />
                            </>
                        )}/>


                        <Route path="/" render={props => <Redirect to="/dashboard" /> } />

                    </Switch>
                </BrowserRouter>

            </div>

        )

    }

}

const mapStateToProps = state => {
	return {
	    online: state.system.online,
	};
};

export default connect(mapStateToProps, '')(App);