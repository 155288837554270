/*
Documentation

this page displays a single email  pulled directly from
microsoft by its id

*/

import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Badge, CardTitle,  Container } from 'reactstrap';
import { architeckCall } from 'database';
import moment from 'moment';
import Circle from 'components/markup/loading/Circle'
import reactHTMLParser from 'react-html-parser';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { getUrlParameter } from 'utils/urls'

class MicrosoftEmails extends Component {

    state = {
        email: null
    };

    // this function tries to fetch the email in query inside of microsoft
    // this will return an error if trying to view and email that is not to
    // the logged in user the email was for
    // example: if email is to x@y.com a user logged in as y@y.com
    // will see and error that they can't view the email
    componentDidMount = async  () => {

        const { email, message_id } = this.props.match.params

        const foundEmail = await architeckCall({
            shouldNotShowAndLogError: true,
            method: 'get',
            url: `/api/v1/core/microsoft/emails/find/${email}/${message_id}`
        })

        if(foundEmail.success) {

            this.setState({email: foundEmail.data})

        } else {

            this.setState({error: true})

        }

    }

    renderDate = (date) => {

        const { startOfDay } = this.state

        if(date >= startOfDay ) {
            return moment(date).format('h:mm A')
        } else {
            return moment(date).format('MM/DD/YYYY')
        }

    }

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { email, error } = this.state
        const back = getUrlParameter('back')

        if(!email && !error) return <div className="py-6"><Circle /></div>

        if(error) return <Card><CardBody><h2 className="text-center mb-0 my-4">This email cannot be viewed by the current email address</h2></CardBody></Card>

        return (

           <Container fluid className="pt-5">

                {back ? (
                    <div className="text-right mb-3">
                        <Link className="btn btn-success" to={back}>
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    </div>
                ) : null}

                <Card className="z-depth-3">

                    <CardHeader className="bg-secondary z-depth-1 px-0">
                        <CardTitle className="px-3" style={{borderBottom: 'solid 1px #ddd'}}>{email.subject}</CardTitle>

                        <h4 className="px-3">
                            <span style={{display: 'inline-block', width: 75}}>Date:</span>
                            {moment(email.createdDateTime).format('MMMM Do, YYYY - h:mm A')}
                        </h4>
                        <h4 className="px-3">
                            <span style={{display: 'inline-block', width: 75}}>From:</span>
                            {email.sender.emailAddress.address}
                        </h4>
                        <h4 className="px-3">
                            <span style={{display: 'inline-block', width: 75}}>To:</span>
                            {email.toRecipients.map(to => (
                                <Badge key={to.emailAddress.address} color="success" className="text-sm text-lowercase">
                                    {to.emailAddress.address}
                                </Badge>
                            ))}
                        </h4>

                    </CardHeader>

                   <CardBody className="email-content">
                        {reactHTMLParser(
                            email.body.content
                                .replace(/<html>/, '<div>').replace(/<\/html>/, '</div>')
                                .replace(/<head>/, '<div>').replace(/<\/head>/, '</div>')
                                .replace(/<body>/, '<div>').replace(/<\/body>/, '</div>')
                        )}
                   </CardBody>

                </Card>

           </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(MicrosoftEmails);
