/*
Documentation

this file renders the knowledge base sidebar that shows up on every page's navbar inside the dashboard

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { Container, Modal } from "reactstrap";
import Article from './Article';
import information from './information';

class KnowledgeBase extends Component {

    state = {
        query: {},
    }

    componentWillMount = () => {
        this.setState({information})
    }


    render() {

        const filter = this.state.query.filter
        let information = this.state.information;
        let results = ''

        if(filter) {

            if(filter.toLowerCase() !== 'all') {
                information = information.filter(i => i.title.toLowerCase().includes(filter.toLowerCase()) ? true : false)
            } 

            results = `${information.length} Search Results`

        }

        return (

            <Modal
                className="modal-dialog-centered modal-full knowledge-base"
                isOpen={this.props.showKnowledgeBase}
                toggle={() => this.props.toggle()}
                size="lg"
                fade={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Knowledge Base</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

               <Container>
               <div className="modal-body">
                    
                

                    <StandardFormGroup
                        obj={this.state.query}
                        objName='query'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})} 
                        type="text"
                        field="filter"
                        hideErr={true}
                        title={(
                            <span>
                                Search Knowledge Base: {' '}
                                <span className="font-weight-bold text-warning">{results}</span>
                            </span>
                        )}
                    />

                    <p className="text-sm mb-0">Frequently Searched: <b>Case, Workflow, Tasks, Roles (Search <span className="text-warning">"all"</span> to show all articles)</b></p>

                    <hr />

                    {filter ? 
                        information && information.length ? information.map(i => (
                            <Article key={i.title} i={i} />
                        )) : <p className="text-sm text-warning">No articles were found for your search: "{filter}"</p> 
                        : (
                            <>
                            <h3 className="text-info-original">Welcome to the knowledge base</h3>
                            <p className="text-sm">Here you can search for any questions you may have about this system. Just type in a keyword above and you see the list of results right here. </p>
                            </>
                        )
                    }


                        

                </div>
               
               </Container>
            </Modal>

        )

    }

}


export default KnowledgeBase

