import PropTypes from 'prop-types';
import React from 'react';

class ModalSendReviewInitials extends React.Component {

    state = {
        isValidated: true,
        contacts: []
    }

    render() {

        const { initial_pages, contacts } = this.props
        const willInitial = initial_pages && contacts.length === 1;

        return (
            <div className={willInitial ? 'bg-success px-4' : 'bg-warning text-white px-4'}>
                <p className="text-sm mb-0">
                    This document{' '}
                    {willInitial ? (
                        <span className="font-weight-bold">WILL</span>
                    ) : (
                        <span className="font-weight-bold">WILL NOT</span>
                    )}
                    {' '}have the clients initials on each page based on the PDF's settings.
                </p>
            </div>
           
        );
    }
}

ModalSendReviewInitials.propTypes = {
    initial_pages : PropTypes.bool.isRequired,
}

export default ModalSendReviewInitials
