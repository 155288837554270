/*
Documentation

Here we show a count of all overdue tasks that will refresh once every 60 seconds

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle, UncontrolledTooltip } from "reactstrap";
import { Link } from 'react-router-dom';
import _case_workflow_tasks from '_functions/case_workflow_tasks';
import Circle from 'components/markup/loading/Circle'

class CallCenterAdminOverdueTasks extends React.Component {

    state = {
        overdueTasksCount: null
    }

    refreshInterval = null

    queryOverdueCount = async () => {

        const tasks = await _case_workflow_tasks.queryOverdueToday({limit: 1 })
        if(tasks.success) this.setState({overdueTasksCount: tasks.total_documents})

    }

    componentDidMount = async () => {

        this.queryOverdueCount()

        // run refresh interval on overdue tasks every minute
        this.refreshInterval = setInterval(this.queryOverdueCount, 1000 * 60)

    }

    componentWillUnmount = () => clearInterval(this.refreshInterval)



    render() {

        let { overdueTasksCount } = this.state;

        if(overdueTasksCount === null) return <Circle />

        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 ">
                        Overdue Today

                        <i id="tooltip-refresh-interval-today" className="fas fa-info-circle text-info-original ml-2" />
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="tooltip-refresh-interval-today"
                        >
                            Unfinished tasks refresh every 60 seconds
                        </UncontrolledTooltip>

                        <span className="float-right">
                            <Link to="/dashboard/tasks/admin">

                                <i className="fas fa-door-open text-success" />
                            </Link>
                        </span>
                    </CardTitle>
                </CardHeader>

                <CardBody className="text-center font-weight-bold">
                    <span
                        style={{fontSize: '2rem'}}
                        className={!overdueTasksCount ? 'text-success' : overdueTasksCount < 10 ? 'text-warning' : 'text-danger'}
                    >
                        <i
                            className={
                                !overdueTasksCount ? 'fas fa-check mr-2' :
                                overdueTasksCount < 10 ? 'fas fa-exclamation mr-2' :
                                'fas fa-exclamation-triangle mr-2'
                            }
                        />
                        {overdueTasksCount}
                    </span>
                </CardBody>

            </Card>

        );
    }
}

export default CallCenterAdminOverdueTasks
