import PropTypes from 'prop-types';
import React from 'react';

class ModalSendFooter extends React.Component {

    render() {

        const { toggleModal, onSend, isValidated, step, setStep, contacts, errors } = this.props

        return (

            <div className="modal-footer">
                {isValidated ? (
                    step === 1 ? (
                        <>
                            <button onClick={toggleModal} className="btn btn-outline-danger">Cancel</button>
                            <button onClick={() => setStep(2)} className="btn btn-success">
                                Next <i className="fas fa-arrow-right ml-2" />
                            </button>
                        </>
                    ) : step === 2 ? (
                        <>
                            <button onClick={() => setStep(1)} className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </button>
                            {contacts.length ? (
                                <button onClick={() => setStep(3)} className="btn btn-success">
                                    Next <i className="fas fa-arrow-right ml-2" />
                                </button>
                            ) : null }

                        </>
                    ) : step === 3 ? (
                        <>
                            <button onClick={() => setStep(2)} className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </button>
                            {contacts.length ? (
                                <button onClick={() => setStep(4)} className="btn btn-success">
                                    Next <i className="fas fa-arrow-right ml-2" />
                                </button>
                            ) : null }

                        </>
                    ): (
                        <>
                            <button onClick={() => setStep(3)} className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </button>
                            {!errors.length ? (
                                <button onClick={() => onSend(contacts)} className="btn btn-success">
                                    <i className="fas fa-paper-plane mr-2 " /> Send Document
                                </button>
                            ) : null}

                        </>
                    )
                ) : null}
            </div>

        );
    }
}

ModalSendFooter.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    onSend        : PropTypes.func.isRequired,
    setStep       : PropTypes.func.isRequired,
    isValidated   : PropTypes.bool.isRequired,
    step          : PropTypes.number.isRequired,
}

export default ModalSendFooter
