import React from 'react';
import renderName from 'utils/renderName';

class ModalSendIsInvalid extends React.Component {

    state = {
        errors: [],
        warnings: [],
    }

    componentDidMount = () => {

        const { contacts, markers, setWarnings, setErrors } = this.props;

        let errors = [];
        let warnings = [];
        let contactIds = []

        // validate each contact has an associated marker
        contacts.forEach(contact => {

            if(contact.party_index === 0) return;

            const { party_index } = contact

            const hasMarker = markers.some(m => m.party === party_index)
            const hasMarkerSignature = markers.some(m => m.party === party_index && m.type === 'signature')

            if(!hasMarker) {
                const error = `Party ${contact.party_index} - ${renderName(contact.fullContact)} does not have any actions to take.`
                if(!errors.includes(error)) errors.push(error)
            }

            if(!hasMarkerSignature) {
                const error = `Party ${contact.party_index} - ${renderName(contact.fullContact)} does not have a signature field.`;
                if(!errors.includes(error)) errors.push(error)
            }

            if(contactIds.includes(contact.fullContact._id)) {
                const error = `${renderName(contact.fullContact)} is used as multiple parties in the document.`;
                if(!errors.includes(error)) errors.push(error)
            }

            if(!contact.fullContact.email && !contact.fullContact.phone) {
                const error = `${renderName(contact.fullContact)} does not have a valid email address or phone number to send this document to.`;
                if(!errors.includes(error)) errors.push(error)
            }

            contactIds.push(contact.fullContact._id)

        })

        // validate each marker has an associated contact
        markers.forEach(marker => {

            if(!marker.type) {
                const errText = `All PDF markers must have a set type of either text, date, initial, or signature`;
                if(!errors.includes(errText))errors.push(errText);
            }

            if(marker.party === 0) return;

            const hasContact = contacts.some(contact => contact.party_index === marker.party)
            const warning = `Party ${marker.party} has been left blank and will not sign this document`
            if(!hasContact && !warnings.includes(warning)) warnings.push(warning)

        })

        setWarnings(warnings)
        setErrors(errors)

    }

    render() {

        const { errors, warnings } = this.props;

        return (

            <div className="modal-body">
               
                <h3 className="mb-0">Warnings</h3>
                {warnings.length ? warnings.map((warnings, i) => (
                    <p key={i} className="text-sm text-danger font-weight-bold mb-0">{warnings}</p>
                )) : (
                    <p className="text-sm mb-0">None</p>
                )}

                <h3 className="mb-0">Errors</h3>
                {errors.length ? errors.map((error, i) => (
                    <p key={i} className="text-sm text-danger font-weight-bold mb-0">{error}</p>
                )) : (
                    <p className="text-sm mb-0">This documents checks out and is ready to send.</p>
                )}

            </div>

        );
    }
}

export default ModalSendIsInvalid
