/*
Documentation

documents how user_types work

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";

class UserTypes extends React.Component {

    render() {

        return (
            <>

            <Helmet>
                <title>{`User Types`}</title>
                <meta name="description" content="User Types" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>User Types</span>} breadcrumb_1="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Types</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">User Types allow certain actions and notifications to be made available for a user. You may assign as many user types as needed to a users and each associated action/notification will present itself to the user whenever they are fired. You may add and remove user types on the <Link to="/dashboard/users/all" className="text-warning font-weight-bold text-underline">staff screen</Link> when viewing a profile if you have the required privileges.</p>
                    </CardBody>

                </Card>

                <Card>
                    <CardBody>
                        <ul className="mb-0">
                            <li>
                                <b>Call Center Operator - </b>
                                <span className="text-sm mb-0">This will trigger notifications for the user if they have been off of a call for more than 10 minutes.</span>
                            </li>
                            <li>
                                <b>Status Independant - </b>
                                <span className="text-sm mb-0">This will allow a user to change their online status at will and select the AFK option to tell users they are available to take calls even while offline.</span>
                            </li>
                        </ul>
                    </CardBody>
                </Card>

            </Container>

            </>
        );
    }
}

export default UserTypes
