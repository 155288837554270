/*
Documentation

this is the first file in the system to use code splitting via React.lazy
Documents will most likely be a very heavy load time due to the amount of them

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import LoadingCard from 'components/markup/loading/LoadingCard'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import _documents from '_functions/documents';

import All from './All';
import Missing from './Missing';
import Upload from './Upload';
import Signing from './Signing';
import Generate from './Generate';
import CSV from './modals/csv';

class Documents extends Component {

    state = {
        documents: null,
        lastDocsAssigned: {},
        showParseCSV: false
    }

    isLoading = false;

    listenForDocumentsDeleted = (data) => {

        if(data.data.case === this.props.case._id) {

            let documents = this.state.documents ? [...this.state.documents] : []
            documents = documents.filter(d => d._id !== data.data._id)

            this.setState({documents})

        }

    }

    listenForDocumentsAdded = (data) => {

        if(data.data.case === this.props.case._id) {

            let documents = this.state.documents ? [...this.state.documents] : []

            documents.unshift({...data.data, isNewUpload: true})
            this.setState({documents})

        }

    }

    fetchDocuments = async (props) => {

        if(!this.state.documents && props.navPills === 3 && !this.isLoading) {

            // set loading so we dont make a db call more than we have to if new props are passed in while we are fetching
            this.isLoading = true

            const data = await _documents.findByCase(this.props.case._id)
            if(data.success) this.setState({documents: data.data})

            this.isLoading = false

        }

    }

    listenForDocumentsUpdated = (data) => {

        let documents = this.state.documents ? [...this.state.documents] : []
        documents[documents.findIndex(d => d._id === data.data._id)] = data.data

        this.setState({documents})

    }

    componentWillReceiveProps = (nextProps) => {
        this.fetchDocuments(nextProps)
    }

    componentWillUnmount = () => {
        io.off(socketEvents.documents_added,    this.listenForDocumentsAdded)
        io.off(socketEvents.documents_updated,  this.listenForDocumentsUpdated)
        io.off(socketEvents.documents_deleted,  this.listenForDocumentsDeleted)
    }

    componentDidMount = async  () => {
        io.on(socketEvents.documents_added,     this.listenForDocumentsAdded)
        io.on(socketEvents.documents_updated,   this.listenForDocumentsUpdated)
        io.on(socketEvents.documents_deleted,  this.listenForDocumentsDeleted)

        this.fetchDocuments(this.props)
    }

    render() {

        const {  document_folders, navPills } = this.props;
        const { documents, lastDocsAssigned  } = this.state
        const _case = this.props.case;

        if(navPills !== 3) return null

        return (

            <>

            <Missing case={_case} documents={documents} lastDocsAssigned={lastDocsAssigned} />

            <Row>

                <Col lg={8}>
                    {!documents ? <LoadingCard /> : (
                        <All
                            documentFolders={document_folders}
                            case={_case}
                            documents={documents}
                            onDocsAssigned={(lastDocsAssigned) => this.setState({lastDocsAssigned})}
                        />
                    )}
                </Col>

                <Col lg={4}>
                    <Upload case={_case} />
                    <Generate case={_case} />
                    <Signing case={_case} />

                    <button onClick={() => this.setState({showParseCSV: !this.state.showParseCSV})} className='btn btn-info btn-block'>Parse CSV</button>
                </Col>

            </Row>

            <CSV 
                showModal={this.state.showParseCSV}
                toggleModal={() => this.setState({showParseCSV: !this.state.showParseCSV})}
            />

            </>

        )

    }

}

Documents.propTypes = {
    case                : PropTypes.object.isRequired,
    navPills            : PropTypes.number.isRequired,
    document_folders    : PropTypes.array
}

export default Documents;
