import Circle from 'components/markup/loading/Circle';
import NameCircle from 'components/markup/misc/NameCircle';
import moment from 'moment';
import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, Col, Input, Row } from "reactstrap";
import _analytics from '_functions/analytics';
import _contact_texts from '_functions/contact_texts';
import _recent_activity from '_functions/recent_activity';
import * as ANALYTIC_EVENTS from '_settings/analytic_events';

import ModalSendPicture from 'components/system/SendText/ModalSendPicture'

const MAX_TEXT_LENGTH = 750;

class Feed extends React.Component {

    state = {
        appointments: null,
        texts: null,
        emails: null,
        feed: null,
        contact: null,
        height: 0,
        textBody: '',
        hasScrolled: false,
        showModalSendPicture: false,
    }

    toggleModalSendPicture = () => this.setState({showModalSendPicture: !this.state.showModalSendPicture})

    setHeight = () => {

        const offset = this.props.offset ? this.props.offset : 203;
        this.setState({height: window.innerHeight -  offset})
    }

    scrollToBottom = () => {

        const textFeedBody = document.getElementById("archk-text-feed-body");
        const hasScrolled = this.state.hasScrolled;

        textFeedBody.scrollTo({
            top: textFeedBody.scrollHeight,
            // behavior: hasScrolled ? 'smooth' : undefined
        })

        if(!hasScrolled) this.setState({hasScrolled: true})

    }

    scrollWhenLoaded = () => {

        const interval = setInterval(() => {

            const textFeedBody = document.getElementById("archk-text-feed-body");
            if(textFeedBody) {
                this.scrollToBottom();
                clearInterval(interval);
            }

        }, 300)

    }

    //on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

            e.preventDefault();
			document.getElementById("archk-text-feed-send-button").click();

		}
	}

    onTextBodyChange = (e) => {
        this.setState({textBody: e.target.value})
    }

    onSend = async () => {

        this.setState({error: null});

        const { contact } = this.props;
        let { textBody } = this.state;

        textBody = textBody.trim();

        if(!contact.phone) return this.setState({error: 'The contact you have selected does not have a valid phone number.'})
        if(!textBody) return
        if(textBody.length > MAX_TEXT_LENGTH) return this.setState({error: 'The text body may not be bigger than 160 characters.'})

        this.scrollToBottom();

        const sent = await _contact_texts.create({ 
            user     : this.props.viewing_user._id,
            contact  : contact._id,
            to       : contact.phone,
            value    : textBody,
            isManual : true
        })

        _recent_activity.update({title: 'Sent Text', value: textBody})

        if(sent.success) {

            _analytics.events.create({event: ANALYTIC_EVENTS.TEXTS_SENT});
            this.setState({textBody: ''})

        } else {

            console.log('err')

        }
      
    }

    renderMessages = (texts, contact) => {

        let lastTime;
        let lastDay;

        if(texts.length) {

            return texts.map(text => {

                const timestamp = moment.unix(text.created_at)

                const day = timestamp.format('MMM Do, YYYY')
                const time = timestamp.format('h:mm A')
                let daySeparator = <></>;
                let timeSeparator = <></>;

                if(day !== lastDay) {
                    daySeparator = <p className="-top -bottom bg-secondary text-sm text-center text-muted font-weight-bold mb-2 mt-3 mx--4">{day}</p>
                }
                if(time !== lastTime) {
                    timeSeparator = <p className="text-sm text-muted mb-0">{time}</p>
                }

                lastDay = day;
                lastTime = time;

                const outbound = text.to === contact.phone || !text.inbound;

                let markup = [];

                const images = text.media_urls ? text.media_urls.map((url, i) => (
                    <a key={i} href={`${url}?download=true`} download target="blank" rel="noopener noreferrer" className={i === 0 ? 'mx-3 d-inline-block' : 'mx-3 mt-3 display d-inline-block'}>
                        <img  src={url} alt="sent media" className="w-100 rounded z-depth-2 cursor-pointer" style={{maxWidth: 300}} />
                    </a>
                )) : [];


                if(outbound) {
                    markup.push (
                        <div key={text._id} className=" text-right mb-2">
                            {daySeparator}
                            {timeSeparator}
                            <div className="archk-text-feed-message bg-gradient-info text-white d-inline-block rounded z-depth-1" style={{maxWidth: 400}}>
                                <p className="text-sm mb-0">
                                    {text.value}
                                </p>
                            </div>
                        </div>
                    )
                } else {
                    markup.push (
                        <div key={text._id} className="mb-2">
                            {daySeparator}
                            {timeSeparator}
                            <span>
                                <NameCircle width={30} contact={contact} />
                            </span>
                            <div className="archk-text-feed-message bg-secondary d-inline-block rounded ml-3 z-depth-1" style={{maxWidth: 400}}>
                                <p className="text-sm mb-0">
                                    {text.value}
                                </p>
                            </div>
                        </div>
                    )
                }

                return markup.concat(images);

            })
        }

    }

    componentDidUpdate = (prevProps) => {

        const textFeedBody = document.getElementById("archk-text-feed-body");

        const textNumberChanged = prevProps.texts && this.props.texts && prevProps.texts.length !== this.props.texts.length;
        const isDifferentContact = prevProps.contact && this.props.contact && prevProps.contact._id !== this.props.contact._id;

        // when a text has been added scroll to bottom of feed
        if(textNumberChanged) {

            if(!this.state.hasScrolled) return this.scrollToBottom();

            // new text came in
            if(prevProps.texts.length + 1 === this.props.texts.length ) {
                if( textFeedBody.scrollTop >= (textFeedBody.scrollHeight - textFeedBody.offsetHeight) - 300) {
                    this.scrollToBottom();
                }
            }
           
            if(!prevProps.texts.length && this.props.texts.length) {

                this.scrollToBottom();
            }


        } else if(!this.state.hasScrolled) {

            this.scrollToBottom();

        } else if(isDifferentContact) {

            this.setState({hasScrolled: false})

        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setHeight)
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.setHeight)
        this.setHeight()
        this.scrollWhenLoaded()
    }

    render() {

        var { texts, contact, showLoader, getContactIdentifier, isReady } = this.props;
        const { height, textBody, error, hasScrolled, showModalSendPicture } = this.state;

        if(!contact) contact = {};

        return (

            <Col id="archk-messenger-feed" className="archk-messenger-main">

                {(
                    <Card>

                    <div className="position-relative bg-secondary" style={{height: height}}>

                        {(!hasScrolled || showLoader) && (
                            <div className="archk-text-feed-loader bg-secondary">
                                <div className="archk-text-feed-loader-circle">
                                    {isReady ? <Circle /> : <span className="ml--7"><i className="fas fa-user mr-2 text-success " /> Select a contact from the left.</span> }
                                </div>
                            </div>
                        )}
                        <CardBody id="archk-text-feed-body" className="bg-secondary position-absolute" style={{left: 0, right: 0, bottom: 0, width: '100%', maxHeight: height, overflow: 'auto'}}>      
                        {contact._id ? (
                            <div className="text-center mb-2">
                                 <div className="bg-gradient-warning text-white d-inline-block p-3 rounded z-depth-1" style={{maxWidth: 500}}>
                                     <p className="text-sm mb-0 font-weight-bold text-uppercase">
                                         This is the start of text messages with {getContactIdentifier(contact)}
                                     </p>
                                 </div>
                             </div>
                            ) : null}                  
                            {this.renderMessages(texts, contact)}
                           
                        </CardBody>

                    </div>

                    <CardFooter className="position-relative">
                        {error && <div className="alert alert-danger">{error}</div>}
                        {textBody && textBody.length > MAX_TEXT_LENGTH && (
                            <p className="text-sm text-danger font-weight-bold mb-0 text-center" style={{position: 'absolute', left: 0, right: 0, width: '100%', top: -25}}>
                                This message cannot exceed {MAX_TEXT_LENGTH} characters. {textBody.length} / MAX_TEXT_LENGTH
                            </p>
                        )}
                        <Row>
                            <Col className="pr-0">
                                <Input 
                                    onKeyDown={this._handleKeyDown}
                                    value={textBody}
                                    onChange={this.onTextBodyChange}
                                    type="textarea"
                                    style={{height: 30}}
                                />
                            </Col>
                            <div className="col-auto pr-1" style={{width: 60}}>
                                <button 
                                    disabled={!contact._id ? true : false}
                                    onClick={contact ? this.toggleModalSendPicture : null} 
                                    className="btn bg-gradient-info btn-block text-white" 
                                    style={{minWidth: 0, height: 38, position: 'relative', top: 1}}
                                >
                                    <i className="fas fa-camera " />
                                </button>
                            </div>
                            <div className="col-auto pl-1" style={{width: 60}}>
                                <button 
                                    disabled={!contact._id ? true : textBody.length ? false : true} 
                                    onClick={this.onSend} 
                                    className="btn btn-success bg-gradient-success btn-block" 
                                    style={{minWidth: 0, height: 38, position: 'relative', top: 1}}
                                    id="archk-text-feed-send-button"
                                >
                                    <i className="fas fa-paper-plane " />
                                </button>
                            </div>
                        </Row>
                    </CardFooter>
                </Card>
                )}

                <ModalSendPicture 
                    showModal={showModalSendPicture}
                    toggleModal={this.toggleModalSendPicture}
                    contact_id={contact && contact._id ? contact._id : contact}
                />

                
            </Col>
        );

    }

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(Feed);