/*
Documentation

toggleAlert params

1 - boolean
this is either true or false. True will show the alert false will hide it

2 - type
one of: success, danger, warning, info

3 - text
this is either a react component or a string of text to show in the alert

4 - time
the time in miliseconds to keep the alert up
default is 5000
if is string "infinite" the alert will never go away

*/

import HCContent from 'components/system/HelpCenter/content/_compiled'
import { architeckCall } from 'database';
import * as actionTypes from '../../actions';
import store from 'store';

import { Device } from 'twilio-client';

let timeout;

export const showStepsToPetitionPrep = (payload) => {
    store.dispatch({ type: actionTypes.SET_STEPS_TO_PETITION_PREP, payload, });
}


export const setDocumentLoading = (payload) => {
    store.dispatch({ type: actionTypes.SET_DOCUMENT_LOADING, payload, });
}


export const setOnlineStatus = (bool) => {
    store.dispatch({ type: actionTypes.SET_ONLINE_STATUS, payload: bool, });
}


// @param - array of document objects
export const setDocumentsForMerge = (payload) => {
    store.dispatch({ type: actionTypes.SET_DOCUMENTS_FOR_MERGE, payload, });
}

export const toggleStandardLoader = (boolean) => {

    if(boolean === true) {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        window.onpopstate = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        // window.addEventListener('popstate', unsavedProgress);
    } else {
        window.onbeforeunload = null;
        window.onpopstate = null;

    }

    store.dispatch({ type: actionTypes.TOGGLE_STANDARD_LOADER, payload: boolean, });
}

export const toggleAlertBS = (error, text) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT_BS, payload: { error, text } });
}

export const toggleHelpCenter = (body, footer) => {
    store.dispatch({ type: actionTypes.TOGGLE_HELP_CENTER, payload: { body, footer } });
}

export const toggleLoader = (boolean) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_LOADER, payload: boolean });
}

export const toggleDevTickets = (boolean) => {
    store.dispatch({ type: actionTypes.TOGGLE_DEV_TICKETS, payload: boolean }); 
}

export const toggleBillingCenter = (show, contact) => {

    if(show) {
        document.body.classList.add('noScroll')

        setTimeout(() => {
            const input = document.querySelector('.contact-search-master-input')
            if(input) input.focus()
        }, 300)

    } else {
        document.body.classList.remove('noScroll')
    }

    store.dispatch({ type: actionTypes.TOGGLE_BILLING_CENTER, payload: { show, contact } });

}

export const toggleRecentActivity = (show) => {

    if(show) {
        document.body.classList.add('noScroll')
    } else {
        document.body.classList.remove('noScroll')
    }

    store.dispatch({ type: actionTypes.TOGGLE_RECENT_ACTIVITY, payload: { show } });

}

// body and footer are respectively the content for the help center
// back is option and if given will create a react Link going to that page
export const helpCenter = {
    content: HCContent,
    toggle: (body, footer, back) => {
        store.dispatch({ type: actionTypes.TOGGLE_HELP_CENTER, payload: { body, footer, back } });
    }
}

export const toggleAlert = (boolean, type, text, time) => {

    clearTimeout(timeout)

    //set the payload to dispatch the notification
    const payload =  { boolean, text, type }

    //dispatch the store
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT, payload });

    //if we are showing and not hiding the alert
    if(boolean === true) {

        //if second parameter is not true lets set a timeout to hide this alert
        //default is 5 seconds to hide
        if(time !== 'infinite') {

            //set timeout so it can be cleared later
            //useful if another toggleAlert is called while this is still up.
            timeout = setTimeout(() => {
                store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT, payload: { ...payload, boolean: false, } });
            }, time ? time : 5000);

        }

    }

}

// this function will create a device with twilio allowing the user to dial via browser if they have that as their call setting
export const setBrowserDialing = async (user_id) => {
    return new Promise (async resolve => {

        const generateToken = await architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/tokens/generate',
            data: {
                user_id,
            }
        })

        try {

            console.log('connect')
            Device.setup(generateToken.data, {
                "iceServers": [ { "url": "turn:global.turn.twilio.com:443?transport=tcp" } ] 
            });

        } catch(e) {

            console.log(e)
            store.dispatch({type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'error'}, });
            return resolve(false)

        }

        // wont fire on login but fires every other time a user refreshes the page
        Device.on('ready', () => {

            store.dispatch({ type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'ready'}, });
            return resolve(true)

        })

        // onReady does not fire on login, believed to be a twilio error as the device is ready after 2 seconds here, needs some testing on other devices
        // #MonkeyPatch
        setTimeout(() => {

            if(Device.status() === 'ready');

            store.dispatch({ type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'ready'}, });

            return resolve(true)

        }, 2000)

        Device.on('connect', (conn) => {
            // console.log(conn)
        })

        // log any device errors
        Device.on('error', async (err) => {

            // if there is an error try to refresh the token
            const refreshedToken = await architeckCall({
                shouldNotLogAndShowError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/tokens/generate',
                data: {
                    user_id,
                }
            })

            try {

                Device.setup(refreshedToken.data);
                console.log('successfully refreshed browser dialing token')

            } catch(e) {

                console.log(err)
                store.dispatch({type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'error'}, });
                console.log(e)


            }

        })

    })
}
