/*
Documentation

the functions are used to convert unix time and standard time between appointments
and their values in inputs

*/

import moment from 'moment'

export const compileDateInputs = (obj) => {

    if(!obj) return { success: false, message: 'object must contain time_start, time_end, and date properties' }

    if(!obj.time_start)                 return { success: false, message: 'Appointment start time must be filled out'};
    if(!obj.time_end)                   return { success: false, message: 'Appointment end time must be filled out'};
    if(!obj.date)                       return { success: false, message: 'Appointment date must be filled out'};

    if(obj.time_start >= obj.time_end)   return { success: false, message: 'Appointment start time cannot be greater or the same as the appointment end time.'};

    const day                 = parseInt(moment(obj.date).startOf('day').format('X'))
    const time_start          = parseInt(obj.time_start)
    const time_end            = parseInt(obj.time_end)

    const date                = day + time_start;
    const date_end            = day + time_end;

    return { success : true, data: { date, date_end } }

}

export const decodeDateInputs = (obj) => {

    let { date, date_end } = obj;

    const startOfDate = parseInt(moment.unix(date).startOf('day').format('X'))

    return {
        date        : moment.unix(startOfDate).toDate(),   // the start of the day the appointments on
        time_start  : date - startOfDate,           // the seconds from the start of the day the appoint starts on
        time_end    : date_end - startOfDate,       // the seconds from the start of the day the appoint ends on
    }

}

export const decodeUnixDate = (date) => {

    const startOfDate = parseInt(moment.unix(date).startOf('day').format('X'))

    return {
        date: moment.unix(startOfDate).toDate(),
        time_start: date - startOfDate,
        time_end: date - startOfDate + 1800
    }

}

export const convertDateToUnix = (date) => {

    if(!date) return undefined
    date = parseInt(moment(date).format('X'))

    return date !== 'Invalid Date' ? date : null

}

export const convertDateToObject = (date) => {

    if(!date) return undefined
    if(!Number.isInteger(date)) return date

    date = moment.unix(date).toDate()

    return date !== 'Invalid Date' ? date : null

}

export const compileAppointmentDateInputs = (obj) => {

    if(!obj) return { success: false, message: 'object must contain time_start, time_end, and date properties' }

    if(!obj.time_start)                 return { success: false, message: 'Appointment start time must be filled out'};
    if(!obj.time_end)                   return { success: false, message: 'Appointment end time must be filled out'};
    if(!obj.date)                       return { success: false, message: 'Appointment date must be filled out'};

    if(obj.time_start >= obj.time_end)   return { success: false, message: 'Appointment start time cannot be greater or the same as the appointment end time.'};

    const day                 = parseInt(moment(obj.date).startOf('day').format('X'))
    const time_start          = parseInt(obj.time_start)
    const time_end            = parseInt(obj.time_end)

    const appointments_start                = day + time_start;
    const appointments_end            = day + time_end;

    return { success : true, data: { appointments_start, appointments_end } }

}

export const decodeAppointmentDateInputs = (obj) => {

    let { appointments_start, appointments_end } = obj;

    const startOfDate = parseInt(moment.unix(appointments_start).startOf('day').format('X'))

    return {
        date        : moment.unix(startOfDate).toDate(),   // the start of the day the appointments on
        time_start  : appointments_start - startOfDate,           // the seconds from the start of the day the appoint starts on
        time_end    : appointments_end - startOfDate,       // the seconds from the start of the day the appoint ends on
    }

}


export function secondsToTime(e){

    const day       = 86400;
    const hour      = 3600;
    const minute    = 60;
    const second    = 1;

    if(!e || e === 'N/A') return 'N/A'

    const days = Math.floor(e / day);
    const hours = Math.floor((e - (days * day)) / hour);
    const minutes = Math.floor((e - (days * day) - (hours * hour)) / minute);
    const seconds = Math.floor((e - (days * day) - (hours * hour)  - (minutes * minute)) / second);

    if(days !== 0) return days + ' Days ' + hours + ' Hrs ' + minutes + ' Mins ';
    if(hours !== 0) return hours + ' Hrs ' + minutes + ' Mins ';
    if(minutes !== 0) return minutes + ' Mins ';
    if(seconds !== 0) return seconds + ' Seconds ';
    
    return 'N/A';
}