import React from "react";
import PropTypes from 'prop-types';

import { FormGroup, Input } from "reactstrap";

import PortalStandard from 'components/functional/portals/Standard'

class ObjectSearchInput extends React.Component {

    state = {
        filter: null,
        showResults: false,
        selected: {}
    }

    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    onChange = (value) => {

        if(value) {
            this.setState({filter: value, showResults: true})
        } else {
            this.setState({filter: null, showResults: false})
        }

        this.props.onChange(value)

    }

    onBlur = () => {
        setTimeout(() => { this.setState({filter: null, showResults: false}) }, 200)
    }

    onClick = () => {
        setTimeout(() => { this.setState({filter: null, showResults: true}) }, 50);
        this.setPosition();
    }

    setPosition = () => {
        const pos = this.refs.objectSearchRef.getBoundingClientRect()

        this.setState({ 
            position: { 
                left: pos.x + window.scrollX, 
                top: pos.bottom + window.scrollY, 
                width: pos.width 
            } 
        })
    }

    // store position for future use in rendering the results
    // set timeout to let resize finish
    setPositionDelay = () => setTimeout(this.setPosition, 1)

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setPositionDelay)
        window.removeEventListener('scroll', this.setPosition)
    }

    componentDidMount = () => {
        
        this.setPosition()
        window.addEventListener('resize', this.setPositionDelay)
        window.addEventListener('scroll', this.setPosition)
    


    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.value && nextProps.value._id) {

            if(nextProps.value._id !== this.state.selected._id) {
                this.setState({selected: nextProps.value})
            }

            return;

        }

        this.setState({selected: {}})

    }

    render() {

        const { position,  selected, showResults } = this.state

        const { data, titleFormatter, hideTitle, placeholderFormatter, inputStyles, wrapperStyles } = this.props
        const { formGroupStyles, resultsFormatter, noResultsFormatter, hideResultsOnFocus } = this.props

        return (
            <div ref="objectSearchRef" className="object-search-input" style={wrapperStyles ? wrapperStyles : {}}>

                <FormGroup style={formGroupStyles ? formGroupStyles : {}}>
                    {!hideTitle ? (
                        <label className="form-control-label mb-0">
                            {titleFormatter ? titleFormatter :
                                selected ? <span>Selected: <b className="text-success">{selected.name}</b></span> : 
                                'No Field Selected, Type To Search'
                            }
                        </label>
                    ) : null}
                    <Input
                        id={this.props.inputID ? this.props.inputID : undefined}
                        onBlur={() => this.onBlur()}
                        onClick={() => this.onClick()}
                        onChange={(e) => this.onChange(e.target.value)}
                        type="text"
                        value={this.state.filter || ''}
                        style={inputStyles ? inputStyles : {}}
                        placeholder={
                            placeholderFormatter ? placeholderFormatter :  
                                selected.name ? selected.name : 
                                'Type To Search'
                        }
                    />
                </FormGroup>

                {hideResultsOnFocus && !this.state.filter ? null : showResults ? (
                    <PortalStandard>
                        <ul className="object-search-input-portal results" style={{...position}}>

                            <li className="title">Search Results For: {this.state.filter}</li>

                            {data && data.length ? data.map(d => (
                                resultsFormatter ? (
                                    resultsFormatter(d)
                                ) : (
                                    <li key={d._id} onClick={() => this.onSelect(d)}>{d.name}</li>
                                )
                            )) : (
                                <li>{noResultsFormatter ? noResultsFormatter : 'No Search Results To Show'}</li>
                            )}

                        </ul>
                    </PortalStandard>
                ) : null}

            </div>
        );
    }
}

ObjectSearchInput.propTypes = {
    data                    : PropTypes.array.isRequired,
    onSelect                : PropTypes.func.isRequired,
    onChange                : PropTypes.func.isRequired,

    noResultsFormatter      : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleFormatter          : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholderFormatter    : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    resultsFormatter        : PropTypes.func,
    
    wrapperStyles           : PropTypes.object,
    formGroupStyles         : PropTypes.object,
    inputStyles             : PropTypes.object,
    hideTitle               : PropTypes.bool,
    hideResultsOnFocus      : PropTypes.bool,
    inputID                 : PropTypes.string,
}

export default ObjectSearchInput
