/*

when opened we drop down a modal to try and call a user.

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Modal } from "reactstrap";
import { toggleStartCallModal } from 'store/functions/call_center/call_center';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import { toggleAlertBS } from 'store/functions/system/system';
import keys from 'keys'
class StartCallModal extends Component {

    state = {}

    // redirect to call center with query params, that will handle the rest
    // set the modal to not show after redirect
    onCallClient = (phone) => {

        this.setState({shouldRedirect: `/dashboard/call_center?startCall=true&phone=${phone}`}, () => {
            this.setState({shouldRedirect: false})
            toggleStartCallModal(false, null)
        })

    }

     // redirect to call center with query params, that will handle the rest
    // set the modal to not show after redirect
    onCallClientRedirect = (phone) => {

        window.open(`/dashboard/call_center?startCall=true&phone=${phone}`, '_blank')
        toggleStartCallModal(false, null)

    }

    // if trying to call a client while on call stop and show the error message
    // then toggle showing this alert back to false
    componentWillReceiveProps = (nextProps) => {

        // const { viewing_user } = this.props
        // let currentShow = this.props.start_call_modal.show
        // let nextShow = nextProps.start_call_modal.show

        // if(viewing_user.call_status === "on call" && !currentShow && nextShow ) {
        //     // toggleAlertBS('Warning', "You may not start another call until you finish the current one you are on.")
        //     toggleStartCallModal(false, null)
        // }
    }

    render() {

        const { viewing_user } = this.props
        let { show, contact } = this.props.start_call_modal
        if(!contact) contact = {}

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const hasName = contact.given_name || contact.family_name;

        return (
            
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={() => toggleStartCallModal(false, null)}
                size="md"
            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">
                        Call {' '}
                        {hasName ? (
                            <span className="text-capitalize">{renderName(contact)} </span>
                        ) : 'Contact'}
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleStartCallModal(false, null)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <p className="text-sm mb-0 font-weight-bold">
                        {hasName ? (
                            <span>
                                Click below to dial{' '} 
                                <span className="text-capitalize text-dark font-weight-bold">{renderName(contact)}</span> 
                                {' at '}
                                <span className="text-underline">{formatPhone(contact.phone)}</span>
                            </span>
                        ) : (
                            <span>Click below to dial <span className="text-capitalize text-underline">{formatPhone(contact.phone)} </span></span>
                        )}
                    </p>
                </div>

                <div className="modal-footer">
                    <button onClick={() => toggleStartCallModal(false, null)} className="btn btn-outline-warning float-left mr-auto">
                        Cancel
                    </button>
                 
                    <a 
                        href={`zoomphonecall://${contact.phone}?callerid=${
                            contact.phone && contact.phone.includes("+1973")  ? "+12162304412" : 
                            contact.phone && contact.phone.includes("+1216")  ? "+12162304412" : 
                            contact.phone && contact.phone.includes("+1419")  ? "+14197254001" : 
                            // contact.phone && contact.phone.includes("+1216")  ? "3002" : 
                            contact.phone && contact.phone.includes("+1220")  ? "+12202140155" : 
                            contact.phone && contact.phone.includes("+1231")  ? "+12312022705" : 
                            contact.phone && contact.phone.includes("+1234")  ? "+12342678428" : 
                            contact.phone && contact.phone.includes("+1269")  ? "+12692306655" : 
                            contact.phone && contact.phone.includes("+1330")  ? "+13309775575" : 
                            contact.phone && contact.phone.includes("+1380")  ? "+13808887080" : 
                            contact.phone && contact.phone.includes("+1440")  ? "+14403732909" : 
                            contact.phone && contact.phone.includes("+1513")  ? "+15133409889" : 
                            contact.phone && contact.phone.includes("+1517")  ? "+15172581770" : 
                            contact.phone && contact.phone.includes("+1567")  ? "+15672231175" : 
                            contact.phone && contact.phone.includes("+1586")  ? "+15863260415" : 
                            contact.phone && contact.phone.includes("+1616")  ? "+16163480599" : 
                            contact.phone && contact.phone.includes("+1734")  ? "+17349299599" : 
                            contact.phone && contact.phone.includes("+1740")  ? "+17403518383" : 
                            contact.phone && contact.phone.includes("+1810")  ? "+18108869976" : 
                            contact.phone && contact.phone.includes("+1906")  ? "+19063718271" : 
                            contact.phone && contact.phone.includes("+1937")  ? "+19373518959" : 
                            contact.phone && contact.phone.includes("+1947")  ? "+19472142814" : 
                            contact.phone && contact.phone.includes("+1989")  ? "+19894591215" : 
                            contact.phone && contact.phone.includes("+1231")  ? "+12314130858" : 
                            "+13138168809"
                        }`} 
                        className="btn btn-success"
                    >
                        <i className="fas fa-headphone" /> Call Contact
                    </a>

                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        start_call_modal: state.call_center.start_call_modal,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(StartCallModal);


