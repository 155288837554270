export default [

    {
        title: 'What is a workflow?',
        html: `
        <p>A workflow is a dedicated list of instructions that you may create to sync up your company and streamline how a case is processed. Workflows are comprised of three main elements:</p>
        <ol>
            <li>Steps</li>
            <li>Tasks</li>
            <li>Task Automation</li>
        </ol>
    
        <h4>Steps</h4>
        <p>Steps are a logical order in which this workflow takes place. You may name them anything you want and then order them accordingly. For instance you may name them "step 1", "step 2", etc or you may be more specific such as "client onboarding", "state filing" and so on.</p>
        <p>As with tasks, a workflow may have an unlimited amount of steps. It is up to you to decide how your workflows will live and breathe.</p>
    
        <h4>Tasks</h4>
        <p>Tasks are not ordered (if you wish to order them split them into different steps) and represent something that needs to be done within a step. You may create any name you want for a given step, set the default number of billable minutes for it, whether it's required, the user role that needs to finish it, and more. All required tasks within a step need to be finished before someone can move that case to the next step. </p>
        <p>Task types include: adding text, uploading documents, and setting appointments for that case.</p>
    
        <h4>Task Automation</h4>
        <p>Hand crafted to save you from monotonous jobs. Task automations can be assigned to different tasks so that when that task is finished these automations fire. For instance, you can assign a task an automation to send your client a text that says "Your bill has been updated" and email the lead attorney on the case they they now have a job to perform as the documents for that task have now been uploaded and are available for them to view.</p>
        <p>There is no limit to how many automations can be created for a task. Email and text away.</p>        

        `
    },
    {
        title: 'How workflows link their steps and tasks and automations',
        html: `
        <p>Step creation is simple because their purpose is to simply be a container that holds a group of tasks. While tasks cannot be ordered steps can. If you have a task that needs to be done before another one simply assign it to a step that comes first in the workflow order</p>
        <p>Automations then come after you create a task. They fire whenever this task is finished.<p/>
        <p>The hierarchy is Workflow -> Step -> Task -> Task Automation</p>
        `
    },
    {
        title: 'Automating your workflows',
        html: `
            <p>To automate your tasks click on the sidebar tab called "Workflows" and navigate to the workflow you wish to begin automating.</p>
            <p> Click on the "automation view" located accross from the step name this task is associated to</p>
            <p>This view allows you to assign text and email to be sent after each task is finished. Follow the workflow and click "save this automation" once you are finished adding new automations</p>
            
        `
    },



    {
        title: 'What is a user role and why is it important?',
        html: `
            <p>User roles play an incredibly vital part within this system. They allow you to give a designation to each person using this software internally. </p>
            <p>You can create an unlimited amount of user roles and name them in a fashion that allows you to quickly identify what their purpose is.</p>
            <p>Once you assign a role to a user you are able to automatically assign tasks to them in the workflow creation pages.</p>
        `
    },

    {
        title: 'How is case billing calculated?',
        html: `
            <p>Billing is calculated through 3 metrics:</p>
            <ol>
                <li>The case base rate</li>
                <li>The hourly rate of a user who finished the task</li>
                <li>How many minutes the task took to complete</li>
            </ol>

            <p>While not required, make sure you assign and hourly rate to each user within this system. </p>
            <p>When a user completes a task the system automatically multiplies the number of minutes the task took to complete by the users hourly rate to calculate how much is paid out to this user on payrole</
            p>
            <p>The system them calculates the case base rate and multiplies it by the minutes the task took to arrive at the amount the client should be billed for this task</p>
            <p>With that you can see how much revenue is brought into the company from each case, how much you paid for each employee on the case, and the amount of excess the company brings in as profit based on the tasks completed.</p>

        `
    },

    {
        title: 'Creating cases',
        html: `
            <p>When creating a new case there are three main courses of action:</p>
            <ol>
                <li>Create a new client to assign to the case</li>
                <li>Assign this case to an existing client giving them multiple cases</li>
                <li>Assign a lead to this case and in the process move them from a lead to a client</li>
            </ol>

            <p>Note that you must also assign a lead attorney, court, hourly rate, and workflow to this case.</p>
            <p>These fields determine how the case will progress and who is responsible for the case being finished on time.</p>


        `
    },

    {
        title: 'What is the case feed?',
        html: `
        <p>The case feed is home base. It logs every action taken by users on this case.</p>
        <p>In the case feed you can see all case notes, when tasks where finished, steps were moved, emails / texts sent to the client and so on.</p>
        <p>This feed appears in real time so you never have to worry about refreshing your page to see the latest updates</p>
        `
    },

    {
        title: 'How case appointments work',
        html: `
        <p>Case appointments are simple, a user may create a date, time and location for an event. </p>
        <p>All users associated with the case that the appointment was created for can now see this event within their personal calendar</p>
        `
    },

   

]