import React, { Component } from 'react';
import { Card, Row, Col } from "reactstrap";
import Address from './Address';
import BillingInformation from './BillingInformation';
import Information from './Information';
import Profile from './Profile';
import PropTypes from 'prop-types'

class OverviewContent extends Component {

    state = {};

    render() {

        const { contact } = this.props

        return (

            <Card className="card-color card-primary">

              <Row>
              <Col lg={6} className="pr-3 pr-lg-0">
                    <Profile contact={contact} />
                    <Information contact={contact} />
                </Col>
                <Col lg={6} className="pl-3 pl-lg-0">
                    <Address contact={contact} />
                    <BillingInformation contact={contact} />
                </Col>
              </Row>




            </Card>

        )

    }

}

OverviewContent.propTypes = {
    contact: PropTypes.object.isRequired
}

export default OverviewContent;
