/*
Documentation

This file renders the view garnishments page

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import filter from 'utils/filter';
import { convertDateToObject } from 'utils/time';
import validator from 'utils/validator';
import _garnishments from '_functions/garnishments';

import ModalGarnishmentsCrud from './components/ModalGarnishmentsCrud';
import ModalGarnishmentsStatus from './components/ModalGarnishmentsStatus';
import GarnishmentDocuments from './components/Documents';
import GarnishmentFields from './components/Fields';
import GarnishmentNotes from './components/Notes';
import GarnishmentAppointments from './components/Appointments';
import GarnishmentLeftColumn from './components/LeftColumn';

class GarnishmentView extends React.Component {

    state = {
        garnishment: null,
        garnishments: null,
        garnishmentNotFound: false,

        showModalGarnishmentsStatus: false,
        showModalGarnishmentsCrud: false,
    }

    renderGarnishmentNotFound = () => (
        <Container className="pt-6">
            <div className=" alert alert-danger">
                <i className="fas fa-exclamation-triangle mr-3" />
                The garnishment you are trying to find does not exist.
            </div>
        </Container>
    )

    onInputChange = (field, value) =>  validator.onInputChange(value, 'garnishment', field, this)

    toggleModalGarnishmentsCrud = (action) => this.setState({ showModalGarnishmentsCrud: action})
    toggleModalGarnishmentsStatus = () => this.setState({ showModalGarnishmentsStatus: !this.state.showModalGarnishmentsStatus})

    listenForGarnishmentsAdded = (data) => {

        const garnishments = this.state.garnishments ? [...this.state.garnishments] : [];

        if(data.data.case._id === this.props.match.params.case) {

            garnishments.push(data.data)
            this.setState({garnishments});

        }
    }

    listenForGarnishmentsUpdated = (data) => {

        const garnishments = this.state.garnishments ? [...this.state.garnishments] : [];

        // if the garnishment was for this case
        if(data.data.case._id === this.props.match.params.case) {

            garnishments[garnishments.findIndex(e => e._id === data.data._id)] = data.data;

            this.setState({garnishments}, () => {
                if(data.data._id === this.props.match.params.garnishment) {
                    this.setGarnishmentById(data.data._id)
                }
            });

        }

    }

    listenForGarnishmentsDeleted = (data) => {

        const garnishments = this.state.garnishments ? [...this.state.garnishments] : [];

        const newGarnishments = filter.byId(garnishments, data.data._id)
        this.setState({garnishments: newGarnishments});

    }

    initListeners = () => {
        io.on(socketEvents.garnishments_added,    this.listenForGarnishmentsAdded)
        io.on(socketEvents.garnishments_updated,  this.listenForGarnishmentsUpdated)
        io.on(socketEvents.garnishments_deleted,  this.listenForGarnishmentsDeleted)
    }

    componentWillUnmount = () => {
        io.off(socketEvents.garnishments_added,    this.listenForGarnishmentsAdded)
        io.off(socketEvents.garnishments_updated,  this.listenForGarnishmentsUpdated)
        io.off(socketEvents.garnishments_deleted,  this.listenForGarnishmentsDeleted)
    }

    setGarnishmentById = (_id) => {

        const garnishments = this.state.garnishments

        if(garnishments) {

            let garnishment = garnishments.find(o => o._id ===_id)
            if(garnishment) {

                garnishment.letter_date = convertDateToObject(garnishment.letter_date)
                garnishment.letter_follow_up_date = convertDateToObject(garnishment.letter_follow_up_date)
                garnishment.check_date_1 = convertDateToObject(garnishment.check_date_1)
                garnishment.check_date_2 = convertDateToObject(garnishment.check_date_2)
                garnishment.check_date_3 = convertDateToObject(garnishment.check_date_3)
                garnishment.check_date_4 = convertDateToObject(garnishment.check_date_4)

                this.setState({garnishment})
                return garnishment

            } else {

                this.setState({garnishmentNotFound: true})

            }

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.match.params.garnishment !== nextProps.match.params.garnishment) {
            this.setGarnishmentById(nextProps.match.params.garnishment)
        }

    }

    componentDidMount = async () => {

        this.initListeners()

        const garnishments = await _garnishments.find(`?filter=case__${this.props.match.params.case}`)

        if(garnishments.success) {

            this.setState({garnishments: garnishments.data}, () => {
                this.setGarnishmentById(this.props.match.params.garnishment)
            })

        }

    }

    render() {

        const { garnishment, garnishmentNotFound } = this.state

        if(garnishmentNotFound) return this.renderGarnishmentNotFound()
        if(!garnishment) return <div className="py-6"><Circle /></div>

        const case_id = garnishment.case._id
        const contact_id = garnishment.case.contact

        return (
        <>

            <Helmet>
                <title>{`Garnishments`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Garnishments</span>} breadcrumb_1={<span className="text-capitalize">{garnishment.case.name}</span>}
                    actionComponent={(
                        <Link to={`/dashboard/garnishments/${case_id}`} className="btn btn-success">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                <div className="alert alert-warning text-capitalize">
                    <Row>
                        <Col xs={10}>
                            <b><i className="fas fa-shield-alt" /> STATUS:</b> {garnishment.status}
                        </Col>
                        <Col xs={2} className="text-right">
                            <i className="fas fa-edit mr-2 cursor-pointer" onClick={this.toggleModalGarnishmentsStatus} />
                        </Col>
                    </Row>
                </div>

                <Row>

                    <Col lg={4}>
                        <GarnishmentLeftColumn
                            garnishment={garnishment}
                            onInputChange={this.onInputChange}
                        />
                    </Col>

                    <Col lg={8}>

                        <GarnishmentDocuments
                            garnishment={garnishment}
                            case_id={case_id}
                            contact_id={contact_id}
                        />

                        <Row>

                            <Col lg={6}>
                                <GarnishmentFields
                                    garnishment={garnishment}
                                    onInputChange={this.onInputChange}
                                />
                                <GarnishmentAppointments
                                    garnishment={garnishment}
                                />
                            </Col>

                            <Col lg={6}>
                                <GarnishmentNotes
                                    garnishment={garnishment}
                                    toggleModalGarnishmentsCrud={this.toggleModalGarnishmentsCrud}
                                />
                            </Col>

                        </Row>

                    </Col>

                   

                </Row>

            </Container>

            <ModalGarnishmentsCrud
                case={case_id}
                showModal={this.state.showModalGarnishmentsCrud ? true : false}
                toggleModal={() => this.toggleModalGarnishmentsCrud(false)}
                onSuccess={() => this.toggleModalGarnishmentsCrud(false)}
                garnishment={this.state.showModalGarnishmentsCrud === 'create' ? null :  garnishment}
            />

            <ModalGarnishmentsStatus
                showModal={this.state.showModalGarnishmentsStatus ? true : false}
                toggleModal={() => this.toggleModalGarnishmentsStatus(false)}
                onSuccess={() => this.toggleModalGarnishmentsStatus(false)}
                garnishment={garnishment}
            />

            </>
        );
    }
}

export default GarnishmentView
