/*

Usage

onChange is meant to set the parent components state with the objName.field = value
example: if objName = 'appointment' and field = 'start_time
this function effectively writes: this.setState({...this.state.appointment, start_time: 'the on change value' })

<StandardFormGroup
    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
    obj={this.state.appointment}
    objName="appointment"
    field="time_start"
    type="select"
    title="Start Date"
    placeholder=""
/>

*/

import React, { Component } from 'react';
import {FormGroup, Input, } from 'reactstrap'

// const allowPeriod = (value) => {

//     if(!value) return false;

//     if(value.slice(value.length - 1, value.length) === '.' && !value.slice(0, value.length - 1).includes('.')) {
//         return true
//     }

//     return false
// }

class StandardFormGroup extends Component {

    state = {

    };

    handleNumberChange = (objName, field,  e) => {

        const value = e.target.value;
        // if the value added has a length and is not a number do nothing
        if(value.length && value !== '0' && !Number(value)) {

            // let a leading '.' pass the check
            if(value.slice(value.length - 1, value.length) === '.' && !value.slice(0, value.length - 1).includes('.') ) {

            } else {
                return
            }
        }

        this.props.onChange(objName, field, value, e)

    }

    render() {

        const field = this.props.field
        const obj = this.props.obj
        const description = this.props.description

        let value = obj[field]

        // *Footer Note 1
        if(this.props.populate) {

            if(value) {
                value = value[this.props.populate]
            }

        }

        // *Footer Note 2
        if(this.props.formatter) {
            value = this.props.formatter(value)
        }

        const err = this.props.hideErr ? null : obj[`${field}State`]

        const title = this.props.title
        const type = this.props.type ? this.props.type : 'text'
        const placeholder = this.props.placeholder
        const objName = this.props.objName

        return (

            <FormGroup className={this.props.formGroupClasses ? this.props.formGroupClasses : "mb-3 "}>
                {title ? <label className="form-control-label mb-0">{title}</label> : null}
                {description ? <p className="text-sm mb-2">{description}</p> : null}
                <Input
                    id={this.props.inputId ? this.props.inputId : null}
                    onKeyDown={this.props.onKeyDown ? (e) =>  this.props.onKeyDown(e) : null}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    onChange={e => {

                        if(type === 'number') {
                            this.handleNumberChange(objName, field, e)
                        } else {
                            this.props.onChange(objName, field, e.target.value, e)
                        }

                    }}
                    value={value || value === 0 ? value : ''}
                    placeholder={placeholder}

                    // use text as input field however ron change we will validate the change is a number before doing anything
                    type={type === 'number' ? 'text' : type}
                    valid={ err === "valid" }
                    invalid={ err === "invalid" }
                    className={this.props.inputGroupClasses}
                    onBlur={(e) => this.props.onBlur ? this.props.onBlur(e) : () => {}}
                    onPaste={(e) => this.props.onPaste ? this.props.onPaste(e) : () => {}}
                    style={this.props.style ? this.props.style : {}}
                >
                    {this.props.children}
                </Input>
            </FormGroup>

        )

    }

}

export default StandardFormGroup;


/*
Documentation

Note 1

if populate is not null we will try to get that value on the base object
ex:

field = 'assigned_to'
populate = '_id'

we try to get assigned_to._id


Note 2
if formatter is added it will run the value of the field through that function

*/
