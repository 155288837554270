import * as actionTypes from '../../actions';

const initialState = {
    phone_numbers: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_PHONE_NUMBERS:

            return {
                ...state,
                phone_numbers: action.payload.data,
            }

        default: return state;

    }

}

export default reducer;
