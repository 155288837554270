import React from 'react';

import { Nav, NavLink, NavItem, Row, Col } from 'reactstrap';

const StepsToPrepNavigation = ({nav, setNav, missingDocs, completedDocs, questionnaireDoc, creditCounselingDoc, questionnaire_id, infoProgress}) => {

    const hasInfo = questionnaire_id ? infoProgress === 100 : true;
    const hasDocs = missingDocs.length === 0;
    const hasQuestionnaire = !questionnaireDoc ? true : questionnaireDoc.status === 'n/a' || questionnaireDoc.status === 'completed';
    const hasCCC = !creditCounselingDoc ? true : creditCounselingDoc.status === 'n/a' || creditCounselingDoc.status === 'completed';
   
    return (
        <div className="archk-steps-to-prep-navigation border-bottom">
            <div className="archk-steps-to-prep-spacer">
                <Row>
                    <Col lg={12}>

                        <Nav
                            className="nav-fill my-0 py-3 flex-column flex-sm-row "
                            id="tabs-text"
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    aria-selected={nav === 'info'}
                                    className={`mb-sm-3 mb-md-0 ${nav === 'info' ? 'active' : ''} ${hasInfo ? 'finished' : ''}`}
                                    onClick={() => setNav('info')}
                                    href="#pablo"
                                    role="tab"
                                >
                                    {hasInfo ? <i className="fas fa-check mr-2 " /> : null}
                                    Info
                                    {!hasInfo ? <span> ({questionnaire_id ? infoProgress : 100})%</span>: null}
            
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    aria-selected={nav === 'docs'}
                                    className={`mb-sm-3 mb-md-0 ${nav === 'docs' ? 'active' : ''} ${hasDocs ? 'finished' : ''}`}
                                    onClick={() => setNav('docs')}
                                    href="#pablo"
                                    role="tab"
                                >
                                    {hasDocs ? <i className="fas fa-check mr-2 " /> : ''}
                                    Docs {hasDocs ? '' : <span> {missingDocs.length ? Math.round((completedDocs.length / (missingDocs.length + completedDocs.length)) * 100 ) : 100}%</span>}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-selected={nav === 'questionnaire'}
                                    className={`mb-sm-3 mb-md-0 ${nav === 'questionnaire' ? 'active' : ''} ${hasQuestionnaire ? 'finished' : ''}`}
                                    onClick={() => setNav('questionnaire')}
                                    href="#pablo"
                                    role="tab"
                                >
                                    {hasQuestionnaire ? <i className="fas fa-check mr-2 " /> : ''}
                                    Q & A
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-selected={nav === 'ccc'}
                                    className={`mb-sm-3 mb-md-0 ${nav === 'ccc' ? 'active' : ''} ${hasCCC ? 'finished' : ''}`}
                                    onClick={() => setNav('ccc')}
                                    href="#pablo"
                                    role="tab"
                                >
                                        {hasCCC ? <i className="fas fa-check mr-2 " /> : ''}
                                    CCC
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>    
                </Row>  
            </div>
        </div>
    )

}

export default StepsToPrepNavigation;