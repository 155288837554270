import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { architeckCall } from 'database';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { toggleAlertBS, toggleDevTickets } from 'store/functions/system/system';
import validator from 'utils/validator';


class SystemDevTickets extends React.Component {

    state = {
        dev_ticket: {},
        showDevTickets: false
    }

    onInputChange = (field, value) =>  validator.onInputChange(value, 'dev_ticket', field, this)

    onSave = async () => {

        architeckCall({
            shouldNotShowAndLogError: true,
            method: 'post',
            url: '/api/v1/dev_tickets/create',
            data: {
                ...this.state.dev_ticket,
                user: this.props.viewing_user ? this.props.viewing_user._id : undefined
            }
        })

        toggleAlertBS(false, 'Thank you, your error report has been received.')
        toggleDevTickets(false)

     }

    componentWillReceiveProps = (nextProps) => this.setState({showDevTickets: nextProps.devTickets, dev_ticket: {}})

    componentDidMount = () => this.setState({showDevTickets: this.props.devTickets})


    render() {

        const { dev_ticket, showDevTickets } = this.state

        if(!showDevTickets) return null

        return (
            <div className="archk-system-dev-tickets z-depth-5">
                <Card>

                    <CardHeader className="bg-transparent">
                        <CardTitle className="mb-0"><i className="fas fa-exclamation-triangle text-danger mr-2" /> Error Report</CardTitle>
                        <p className="text-sm mb-0">A system error occurred, please take a minute to fill out the below error report.</p>
                        <button onClick={() => toggleDevTickets(false)} className="btn btn-warning btn-block mt-2">I Did Not Notice An Error</button>
                        <span onClick={() => toggleDevTickets(false)} className="close cursor-pointer"><i className="fas fa-times" /></span>
                    </CardHeader>

                    <CardBody className="bg-transparent">
                        <StandardFormGroup
                            obj={dev_ticket}
                            objName="dev_ticket"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="text"
                            field="page"
                            title={'What page were you on when the error occurred? You can paste the url if applicable.'}
                        />

                        <StandardFormGroup
                            obj={dev_ticket}
                            objName="dev_ticket"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="text"
                            field="error_message"
                            title={'Was there an error message given to you? If so add it below.'}
                        />

                        <StandardFormGroup
                            obj={dev_ticket}
                            objName="dev_ticket"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="text"
                            field="action"
                            title={'*IMPORTANT: What were you doing when the error occurred? (Ex: I clicked the update contact button.)'}
                        />

                        <StandardFormGroup
                            obj={dev_ticket}
                            objName="dev_ticket"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="text"
                            field="action_previous"
                            title={'What were you doing right before the error occurred? (Ex: I was viewing a contact and then went to update their information.)'}
                        />

                        <StandardFormGroup
                            obj={dev_ticket}
                            objName="dev_ticket"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="text"
                            field="notes"
                            title={'Is there anything else you can think of that might help the system developers solve this error?'}
                        />
                    </CardBody>

                    <CardFooter className="text-right bg-transparent">
                        <button onClick={this.onSave} className="btn btn-success">Send Error Report</button>
                    </CardFooter>

                </Card>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        devTickets: state.system.devTickets,
    };
};

export default connect(mapStateToProps, '')(SystemDevTickets);
