/*
Documentation

this modal can add a role to the case, note that this
does not mean assign a user to role, only add a new role

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";
import { toggleAlert } from 'store/functions/system/system';
import _cases from '_functions/cases';
import role_types from '_settings/role_types';

class RolesAddRole extends Component {

    state = {
        role: null,
        showModal: false,
    };

    onAddRole = () => {

        const role = this.state.role;

        if(role && role !== 'false') {

            _cases.updateRoles({ case: this.props.case._id, user: this.props.viewing_user._id, role })
            this.props.toggleModal()

        } else {

            // should not fire as button is disabled, works as double validation
            toggleAlert(true, 'warning', 'Before adding a role you must give it a name.', '3000')

        }

    }

    componentWillReceiveProps = (nextProps) => {

        // when modal closes remove the user from state
        if(this.props.showModal && !nextProps.showModal) this.setState({role: null})

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { roles } = this.props.case
        const { role } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Add A Role</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">
                        This action will add a new role to the case. Note that since this role is new all task assignments to this role must be done individually.
                        <b>Roles cannot be deleted once they are added to a case!</b>
                    </p>

                    <FormGroup>
                        <label className="form-control-label"> Role Name </label>
                        <Input
                            value={this.state.role || ''}
                            type="select"
                            onChange={e => this.setState({role: e.target.value})}
                        >
                            <option value="false"></option>
                            {role_types.map(type => (
                                !roles.some(r => r.role === type) ? (
                                    <option key={type} value={type}>{type}</option>
                                ) : null
                            ))}
                        </Input>
                    </FormGroup>

                </div>

                <div className="modal-footer">
                    <button disabled={!role} onClick={this.onAddRole} className="btn text-center btn-success" >
                        Add Role
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

RolesAddRole.propTypes = {
    case        : PropTypes.object.isRequired,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired
}

export default connect(mapStateToProps, '')(RolesAddRole);
