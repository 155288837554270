/*
Notes on the Calendar



*/

import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component } from 'react';
import { Container } from 'reactstrap'

import Header from './components/Header'
import Sidebar from './components/Sidebar'
import ViewDay from './components/ViewDay';
import ViewMonth from './components/ViewMonth';
import ViewWeek from './components/ViewWeek';

import { getUrlParameter } from 'utils/urls'
import { getEventsBetweenInterval } from './functions/utils'

class Calendar extends Component {

    state = {
        day_to_show: parseInt(moment().format('X')),
        sidebar_day_to_show: parseInt(moment().format('X')),
        showView: 'month',
        startOnSunday: true
    };


    setURL = (settings) => {

        let view = settings.view ? settings.view : getUrlParameter('view')
        let time = settings.time ? settings.time : getUrlParameter('time')
        let users = settings.users ? settings.users : getUrlParameter('users')

        window.history.pushState(null, null, `${window.location.pathname}?view=${view}&time=${time}&users=${users}`);

    }

    goToToday = () => {

        const now = parseInt(moment.unix(Math.floor(new Date() / 1000)).format('X'))

        this.setState({
            day_to_show: now,
            sidebar_day_to_show: now,
        })

        this.setTimeframe(now)

    }

    scrollToTop = () => window.scroll({ top: 0, left: 0, behavior: 'smooth' });


    setView = (view) => {

        this.setURL({view})
        this.setState({showView: view})

    }

    setTimeframe = (time) => {

        const view = getUrlParameter('view');

        this.setState({day_to_show: time})
        this.onDateChange(time, view)
        this.setURL({view, time})

    }

    setSidebarTimeframe = (time) => this.setState({sidebar_day_to_show: time})


    //this fires on component mount and when someone uses browser fron and back buttons
    setDataByUrl = () => {

        const view  = getUrlParameter('view')
        let time    = getUrlParameter('time')

        if(view && time) {

            time = parseInt(time)

            this.setState({ showView: view, day_to_show: time })
            this.onDateChange(time, view)


        } else {

            //set start time to the start of today's date
            let time_start = parseInt(moment().startOf('month').format('X'));
            this.onDateChange(time_start, 'month')


        }

    }

    //this function should fire anytime the date changes (including on mount) and pass the start time and end time of current day plus events
    onDateChange = (time_start, view) => {

        if(this.props.onDateChange) {
            if(typeof this.props.onDateChange === 'function') {

                const time_now = Math.floor(new Date() / 1000);

                let time_end;
                let events_in_view_today    = []
                let start_of_today          =  parseInt(moment.unix(time_now).startOf('day').format('X'))
                let end_of_today            =  parseInt(moment.unix(time_now).endOf('day').format('X'))

                let events_in_view = this.props.data

                //based on the start time passed in get validate the start date based on the view and set time end to the end of the interval based on the view
                if(view === 'day') {
                    time_end = parseInt(moment.unix(time_start).endOf('day').format('X'))
                    time_start = parseInt(moment.unix(time_start).startOf('day').format('X'))
                }

                if(view === 'week') {
                    time_end = parseInt(moment.unix(time_start).endOf('week').format('X'))
                    time_start = parseInt(moment.unix(time_start).startOf('week').format('X'))
                }

                if(view === 'month') {
                    time_end = parseInt(moment.unix(time_start).endOf('month').format('X'))
                    time_start = parseInt(moment.unix(time_start).startOf('month').format('X'))
                }

                events_in_view = getEventsBetweenInterval(events_in_view, time_start, time_end)

                //if the interval we are looking for has the current time in it return another array of events in view today;
                if(time_now < time_end && time_now >= time_start) {
                    events_in_view_today = getEventsBetweenInterval(events_in_view, start_of_today, end_of_today)
                }

                this.props.onDateChange({
                    view,

                    start_of_today,
                    start_of_today_string:  moment.unix(start_of_today).format('MMM Do, YYYY hh:mm:ss A'),

                    end_of_today,
                    end_of_today_string:    moment.unix(end_of_today).format('MMM Do, YYYY hh:mm:ss A'),

                    start_of_view:          time_start,
                    start_of_view_string:   moment.unix(time_start).format('MMM Do, YYYY hh:mm:ss A'),

                    end_of_view: time_end,
                    end_of_view_string:     moment.unix(time_end).format('MMM Do, YYYY hh:mm:ss A'),

                    time_now,
                    time_now_string:        moment.unix(time_now).format('MMM Do, YYYY hh:mm:ss A'),

                    events_in_view,
                    events_in_view_today
                })

            } else {

                console.warn('onDateChange is not a function. Component: ArchkCalendar')

            }
        }

    }

    componentWillMount = () => {

        const time = parseInt(getUrlParameter('time'))

        if(!Number.isNaN(time)) {
            this.setState({sidebar_day_to_show: time})
        } else {
            this.setState({sidebar_day_to_show: Math.floor(new Date() / 1000)})
        }

        //this listen for clicks on the back button of the browser
        window.addEventListener('popstate', this.setDataByUrl);
        this.setDataByUrl()

    }

    componentWillUnmount = () => {
        //remove window location listener
        window.removeEventListener('popstate', this.setDataByUrl);
    }

    //this function resets the calendar on any error
    onError = (e) => {

        this.setState({
            day_to_show: parseInt(moment().startOf('month').format('X')),
            showView: 'month',
        })

    }



    render() {

        // const events = this.runFilter(this.props.data)
        const events = this.props.data

        const { renderSidebarCalendars, renderSidebarSearch, renderSidebarAdditions,  onRefresh, renderViewMonth } = this.props

        return (

            <Container fluid className="bg-white">
                <div className="archk-calendar">

                    <Sidebar
                        sidebar_day_to_show={this.state.sidebar_day_to_show}
                        day_to_show={this.state.day_to_show}
                        setTimeframe={(val) => this.setTimeframe(val)}
                        setSidebarTimeframe={(val) => this.setSidebarTimeframe(val)}
                        renderSidebarCalendars={renderSidebarCalendars}
                        renderSidebarSearch={renderSidebarSearch}
                        renderSidebarAdditions={renderSidebarAdditions}
                    />

                    <div className="calendar">

                        <Header
                            day_to_show={parseInt(this.state.day_to_show)}
                            showView={this.state.showView}
                            setView={this.setView}
                            goToToday={this.goToToday}
                            onRefresh={onRefresh}
                        />


                        <ViewDay
                            day_to_show={parseInt(this.state.day_to_show)}
                            showView={this.state.showView}
                            events={events}
                            onError={(e) => this.onError(e)}
                            startOnSunday={this.state.startOnSunday}

                            onDayEventClick={this.props.onDayEventClick ? this.props.onDayEventClick : null}
                            onDayCellClick={this.props.onDayCellClick ? this.props.onDayCellClick : null}
                            renderDayEvent={this.props.renderDayEvent ? this.props.renderDayEvent : null}
                        />

                        <ViewWeek
                            day_to_show={parseInt(this.state.day_to_show)}
                            showView={this.state.showView}
                            events={events}
                            startOnSunday={this.state.startOnSunday}

                            onViewWeekCellClick={(values) => {
                                if(typeof this.props.onViewWeekCellClick === 'function') {
                                    this.props.onViewWeekCellClick(values)
                                } else {
                                    if(this.props.onViewWeekCellClick === 'show_day') {
                                        this.setView('day')
                                        this.setTimeframe(parseInt(moment.unix(values.start_date).startOf('day').format('X')))
                                    }
                                }
                            }}

                        />
                        <ViewMonth
                            day_to_show={parseInt(this.state.day_to_show)}
                            showView={this.state.showView}
                            events={events}
                            renderViewMonth={renderViewMonth}
                            startOnSunday={this.state.startOnSunday}


                            onViewMonthCellClick={(values) => {
                                if(this.props.onViewMonthCellClick) {
                                    if(typeof this.props.onViewMonthCellClick === 'function') {
                                        this.props.onViewMonthCellClick(values)
                                    } else {
                                        if(this.props.onViewMonthCellClick === 'show_day') {
                                            this.setView('day')
                                            this.setTimeframe(values.start_date)
                                        }
                                    }
                                }
                            }}

                        />

                    </div>

                </div>

            </Container>

        )

    }

}

Calendar.propTypes = {
    data: PropTypes.array.isRequired,

    onDateChange: PropTypes.func, // a function to fire when the date the calendar is showing changes

    onDayEventClick   : PropTypes.func, // a function to fire when an empty cell is clicked on the month view
    onDayCellClick    : PropTypes.func, // a function to fire when a cell with an event is clicked on the month view

    renderSidebarCalendars    : PropTypes.func,
    renderSidebarSearch       : PropTypes.func,
    onRefresh                 : PropTypes.func,

    // a function to fire when a cell with an event is clicked on the month view
    // can also be "show_day" in which case when the cell is clicked we open up the day view
    onViewMonthCellClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),

    // a function to fire when a cell with an event is clicked on the month view
    // can also be "show_day" in which case when the cell is clicked we open up the day view
    onViewWeekCellClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),

}

export default Calendar;

