import React from 'react';

export default {

    master: {
        body: (
            <div>
                <h3>Master Calendar</h3>
                <p>The master calendar give you the ability to join up to three (3) employees schedules onto one color coded calendar.</p>
                <p>To add a user to the calendar for viewing search their name in the box on the top of the screen and click on the user you wish to view.</p>

            </div>
        ),

    },

}
