// updateMissingDoc

/*
Documentation

this file creates a modal to upload missing docs on a case

*/

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, FormGroup, Input } from 'reactstrap';
import _cases from '_functions/cases'
import { toggleStandardLoader } from 'store/functions/system/system';

class ModalMissingDocs extends React.Component {

    state = {
        name: '',
        description: null,
    }

    onAddMissingDoc = async () => {

        const { name, description } = this.state

        if(!name) return

        toggleStandardLoader(true)

        const created = await _cases.addMissingDoc(this.props.case._id, { 
            name: this.props.isPost ? 'POST DOCUMENT - ' + name : this.props.isRequest ? 'POST INFO REQUEST - ' + name :  name, 
            description
         })
        if(created.success) this.props.onSuccess(created.data)

        this.props.toggleModal()

        toggleStandardLoader(false)

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { name, description } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Missing Doc</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <FormGroup>
                        <label className="form-control-label">Missing Doc Name</label>
                        <Input
                            value={name || ''}
                            type="text"
                            onChange={(e) => this.setState({name: e.target.value})}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Description To Client</label>
                        <Input
                            type="text"
                            value={description || ''}
                            onChange={(e) => this.setState({description: e.target.value})}
                        />
                    </FormGroup>

                </div>

                <div className="modal-footer">
                    <button onClick={toggleModal} className="btn btn-outline-warning">Cancel</button>
                    <button onClick={this.onAddMissingDoc} className="btn btn-success">Add Missing Doc</button>
                </div>


            </Modal>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

ModalMissingDocs.propTypes = {
    missingDoc: PropTypes.object,


    case: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default connect(mapStateToProps, '')(ModalMissingDocs);
