/*
Documentation

this file renders a modal in which a contact can be added and the email
being viewed can be associated to the contact by creating a contact_text document

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {  Modal } from "reactstrap";
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput'
import _contact_emails from '_functions/contact_emails'

import { connect } from 'react-redux';

class ModalAssociateToContact extends Component {

    state = {
        task: {},
        selectedContact: null
    }

    onCreate = async () => {

        this.setState({error: null})

        const { selectedContact } = this.state

        if(!selectedContact._id) return this.setState({error: 'You must specify a contact to associate this email to.'})

        const { viewing_user, email } = this.props


        var bodyFormData = new FormData();

        bodyFormData.append('user',         viewing_user._id);
        bodyFormData.append('contact',      selectedContact._id);
        bodyFormData.append('value',        email.value);
        bodyFormData.append('from',         email.from);
        bodyFormData.append('subject',      email.subject);
        bodyFormData.append('created_at',   email.created_at);
        bodyFormData.append('outgoing',     email.outgoing);
        bodyFormData.append('read',         email.read);
        bodyFormData.append('to_email',     email.to_email);
        bodyFormData.append('to_user',      email.to_user);
        bodyFormData.append('web_link',     email.web_link);
        bodyFormData.append('send',         false);

        if(email.to)                    bodyFormData.append('to', JSON.stringify(email.to)  );
        if(email.attachments_link)      bodyFormData.append('attachments_link', email.attachments_link);
        if(email.microsoft_message_id)  bodyFormData.append('microsoft_message_id', email.microsoft_message_id);

        _contact_emails.create(bodyFormData)

        this.props.toggleModal()

    }

    onSelectContact = (selectedContact) => this.setState({selectedContact})

    componentWillReceiveProps = (nextProps) => {

        // reset selectedContact whenever this modal opens
        if(!this.props.showModal && nextProps.showModal) {
            this.setState({selectedContact: {}})
        }

    }

    render() {

        const { email, showModal, toggleModal } = this.props;
        const { selectedContact, error } = this.state;

        if(!email) return null

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleModal()}
                size="lg"
                fade={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">Associate To Contact</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm  mb-0">
                        <span className="font-weight-bold" style={{display: 'inline-block', width: 55}}>FROM: </span>
                        {email.from}
                    </p>
                    <p className="text-sm mb-0">
                        <span className="font-weight-bold" style={{display: 'inline-block', width: 55}}>TO:</span>
                        {email.to && Array.isArray(email.to.length) && email.to.length ? email.to.map((email, i) => i=== 0 ? email : ', ' + email) : email.to}
                    </p>

                    <p className="text-sm mb-0">{email.subject}</p>

                    <hr className="my-2" />

                    <ContactSearchInput
                        title="Associate Email To Contact:"
                        onSelect={this.onSelectContact}
                        value={selectedContact || {}}
                    />

                    {error ? <div className="alert alert-danger mb-0">{error}</div> : null}

                </div>

                <div className="modal-footer text-right">
                    <button onClick={this.onCreate} className="btn btn-success"><i className="fas fa-check mr-2" /> Associate To Contact</button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ModalAssociateToContact.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    email         : PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(ModalAssociateToContact);

