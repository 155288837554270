/*
Documentation

this file renders the responses to a case_alert

*/

import PropTypes from 'prop-types'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button } from "reactstrap";
import _case_alerts from '_functions/case_alerts';

import { canUpdateCases } from '_functions/users/privileges'

const required_form_fields = [
    'value',
]

class Response extends Component {


    state = {
        alertResponse: {}
    };

     onCreateAlertResponse = async () => {

        let newState = Object.assign({}, this.state.alertResponse);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ alertResponse: newState });

        if(!errors) {

            _case_alerts.createResponse(this.props.case_alert, {
                value: newState.value,
                user: this.props.viewing_user._id,
            })
            this.setState({alertResponse: {}})

        }

    }

    onResolveAlert = async () => {

        _case_alerts.resolve(this.props.case_alert, {
            user: this.props.viewing_user._id,
        })

    }

    render() {

        const alertResponse = this.state.alertResponse
        const canUpdate = canUpdateCases();

        return (

            <div className="mt-4">
                <div className="bg-secondary">
                    <StandardFormGroup
                        disabled={!canUpdate}
                        obj={alertResponse}
                        objName='alertResponse'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        placeholder="Add Reply"
                        type="textarea"
                        field="value"
                    />
                </div>

                <Row>

                    <Col xs={4}>
                        <Button
                            disabled={!canUpdate}
                            className="btn-block"
                            onClick={canUpdate ? this.props.onShowLess : null}
                            size="sm"
                            color="primary"
                            outline
                            type="button"
                        >
                            Show Less
                        </Button>
                    </Col>

                    <Col xs={4} className="text-center">
                        <Button
                            disabled={!canUpdate}
                            className="btn-block"
                            onClick={canUpdate ? this.onResolveAlert : null}
                            size="sm"
                            color="warning"
                            outline
                            type="button"
                        >
                            <i className="fas fa-check mr-2" /> Resolve
                        </Button>
                    </Col>

                    <Col xs={4} className='text-right'>
                        <button
                            disabled={!canUpdate}
                            className="btn btn-success btn-sm btn-block"
                            onClick={canUpdate ? this.onCreateAlertResponse : null }
                        >
                            Reply
                        </button>
                    </Col>

                </Row>

            </div>

        )

    }

}
const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

Response.propTypes = {
    onShowLess: PropTypes.func.isRequired,
    case_alert: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(Response);
