/*
Documentation

this file allows a user to update missing document

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup, Input } from 'reactstrap';

import renderName from 'utils/renderName';
import moment from 'moment';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import _documents from '_functions/documents';

import onDownloadDocument, { getImageAsBase64  } from '_functions/documents/download'

const imageTypes = ['jpeg', 'jpg', 'png', 'gif', 'tiff']

class ModalUpdateDocument extends React.Component {

    state = {
        documentToUpdate    : null,
        documentFolder      : null,
        documentName        : null,
        error               : null,
        folderSelectOptions : [],
        imageSrc: '',
    }

    onUpdate = async () => {

        this.setState({error: null})
        const { documentToUpdate, documentName, documentFolder } = this.state;

        if(!documentName) return this.setState({error: `A document name must be specified before updating a document`})
        if(documentName.includes('.')) return this.setState({error: `File name cannot include a period`})

        _documents.update(documentToUpdate._id, {friendly_name: documentName, document_folder: documentFolder ? documentFolder : undefined})
        this.props.toggleModal()

    }

    onDelete = () => {

        _documents.delete(this.state.documentToUpdate._id)
        this.props.toggleModal()

    }

    // when react select is click, this wont scroll to bottom leaving some white space that looks cut of
    // when we click the select try to scroll the modal to the bottom to fix this
    scrollToBottom = () => {

        try {
            var modalContent = document.querySelector('#modal-update-document .modal-content');
            modalContent.scrollTop = modalContent.scrollHeight;
        } catch(e) { console.log(e) }

    }

    // returns the file type in lower case or null if failed
    getFileEnding = (document) => {

        try {
            let split = document.url.split('.')
            let ending = split[split.length -1]

            ending = ending.toLowerCase();

            return ending

        } catch(e) {
            return null
        }

    }

    renderPreview = (document) => {

        const ending = this.getFileEnding(document)
        if(!ending) return ''

        if(imageTypes.includes(ending)) {
            onDownloadDocument(document._id, (err, data) => {
                if(data) {
                    this.setState({imageSrc: getImageAsBase64(data)})
                }
            })
        }

    }

    componentWillReceiveProps = (nextProps) => {

        let currentId = this.props.document ? this.props.document._id : null
        let nextId = nextProps.document ? nextProps.document._id : null

        if(nextId && currentId !== nextId) {

            let nameWithoutFileType
            const split = nextProps.document.friendly_name ? nextProps.document.friendly_name.split('.') : []
            const documentFolder = nextProps.document.document_folder ? nextProps.document.document_folder : null

            split.forEach((segment, i) => {

                // if its the first segment show the name, if not try to remove the . prefix to get the name without a file type attached
                if((i === (split.length - 1)) && i !== 0) return
                nameWithoutFileType = nameWithoutFileType ? nameWithoutFileType + segment : segment

            })

            // try to focus the name input
            setTimeout(() => {
                const input = document.getElementById('modal-update-name-input')
                if(input) input.focus()
            }, 300)

            this.renderPreview(nextProps.document)

            this.setState({
                documentToUpdate: nextProps.document,
                documentName: nameWithoutFileType,
                documentFolder
            })
        }

    }

    componentDidMount = () => {

        let folderSelectOptions = []

        this.props.documentFolders.forEach(folder => {
            folderSelectOptions.push({value: folder._id, label: folder.name})
        })

        this.setState({folderSelectOptions})

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { documentToUpdate, folderSelectOptions, documentName, error, imageSrc } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
                id="modal-update-document"
            >
                {documentToUpdate && documentToUpdate._id ? (
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title" >{documentToUpdate ? documentToUpdate.friendly_name : ''}</h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={toggleModal}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <div className="modal-body" >

                            <p className="mb-2 text-sm text-capitalize mb-0"><b>Uploaded By: </b>
                            {documentToUpdate.user ? renderName(documentToUpdate.user): 'System'}
                            {' on '}
                            {moment.unix(documentToUpdate.created_at).format('MMM Do YYYY - h:mm A')}
                            </p>

                            {imageSrc ? (
                                <img className="w-100 rounded z-depth-2" alt="document preview" src={imageSrc} />
                            ) : 'No Document Preview Available'}

                            <hr />

                            <FormGroup  onClick={this.scrollToBottom}>
                                <ReactSelect
                                    placeholder="Add To Folder"
                                    onChange={(values) => this.setState({documentFolder: values.value}) }
                                    options={folderSelectOptions}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    id="modal-update-name-input"
                                    placeholder="Document Name"
                                    value={documentName}
                                    onChange={(e) => this.setState({documentName: e.target.value})}
                                />
                            </FormGroup>

                        </div>

                        {error ? <div className="alert alert-danger" >{error}</div> : null }

                        <div className="modal-footer">
                            {!documentToUpdate.friendly_name.includes('In Progress Contract') ? (
                                <button onClick={this.onDelete} className="btn btn-danger"><i className="fas fa-trash mr-2" /> Delete Document</button>
                            ) : null}
                            <button onClick={this.onUpdate} className="btn btn-success"><i className="fas fa-save mr-2" /> Update Document</button>
                        </div>
                    </>
                ) : null}
            </Modal>

        );
    }
}

ModalUpdateDocument.propTypes = {
    showModal         : PropTypes.bool.isRequired,
    toggleModal       : PropTypes.func.isRequired,
    onSuccess         : PropTypes.func.isRequired,
    documentFolders   : PropTypes.array.isRequired,
    document          : PropTypes.object,
}

export default ModalUpdateDocument
