import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom'

import _call_queue from '_functions/call_queue';
import _calls_under_90 from '_functions/calls_under_90';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import { Container, Card, CardBody, Modal } from 'reactstrap';

import { formatPhone } from 'utils/text'
import { toggleAlertBS } from 'store/functions/system/system';


const CardItem = ({queue, onStartCall, getName}) => {

    const [now, setNow] = useState(Math.floor(new Date() / 1000))

    const  secondsToTime = (e) => {
        var h = Math.floor(e / 3600).toString().padStart(2,'0'),
            m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
            s = Math.floor(e % 60).toString().padStart(2,'0');
        return `${h}:${m}:${s}`;
    }
    const time = now - queue.date;
    const isQueue = queue.priority === 40 || queue.priority === 50;

    let name = getName(queue)

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Math.floor(new Date() / 1000))
        }, [1000])

        return () => {
            clearInterval(interval);
        }
    }, [queue.date])

    return (
        <Card className={`${isQueue ? 'bg-purple' : 'bg-warning'} mb-0 border-right cursor-pointer`} style={{maxWidth: 200}} onClick={onStartCall}>
            <CardBody className={`text-center rounded ${isQueue ? 'bg-purple' : time >= 90 ? 'bg-danger' : 'bg-warning'}`}>
                <p className="text-sub text-secondary mb--3 text-italic" style={{fontSize: 12}}>{isQueue ? 'Incoming Call' : 'NEW LEAD'}</p>
                <p className="text-sm text-white font-weight-bold mb--3 text-capitalize">{isQueue ? queue.type : name}</p>
                <p className="text-sm text-white  mb-0">{secondsToTime(time)}</p>
            </CardBody>
        </Card>
    )
}

const CallAlerts = ({viewing_user, hasSidebar}) => {

    const [state, setState] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [newQueue, setNewQueue] = useState({})

    const isQueue = newQueue && (newQueue.priority === 40 || newQueue.priority === 50);


    const onStartCall = useCallback(async () => {

        const data = await _call_queue.searchNew();
        const queues = data.data;

        setShowModal(false)
        if(!queues.length) return toggleAlertBS('info', 'This call has been taken')
        const queue = queues.find(q => q.priority === newQueue.priority);
        if(!queue) return toggleAlertBS('info', 'This call has been taken')

        const phone = queue.contact && queue.contact.phone ? queue.contact.phone : null
        if(!phone) return toggleAlertBS('info', 'This call has been taken')

        const now = Math.floor(new Date() / 1000)

        const time = now - queue.date;

        if(time <= 90) {
            _calls_under_90.create({
                user: viewing_user._id,
                contact: queue && queue.contact ? queue && queue.contact._id : undefined,
                time
            })
        }

        setRedirect(`/dashboard/call_center_redirect?startCall=true&phone=${phone}`)
        setTimeout(() => {
            setRedirect(false)
        }, 1000);

    }, [state.queue, newQueue.priority])

    const getName = useCallback((queue) => {
        let name = 'Unknown';

        if(queue.contact) {
            const contact = queue.contact;
    
            if(contact.given_name && contact.family_name) {
                const string = contact.given_name + ' ' + contact.family_name
                if(string && string.length > 10) {
                    name = contact.given_name ? contact.given_name : contact.family_name
                } else {
                    name = string
                }
            } else if(contact.given_name) {
                name = contact.given_name;
            } else if(contact.family_name) {
                name = contact.family_name
            } else {
                name = formatPhone(queue.contact.phone)
            }
        }
        return name;
    }, [])

    const fetchQueue = useCallback(async () => {
        const data = await _call_queue.searchNew();
        if(data.data) {
            setState({
                queue: data.data,
                allQueues: data.queue,
                usersOnCall: data.usersOnCall
            })
        }
    }, [])

    const listenForQueueAdded = useCallback((data) => {
        if(data.data && (data.data.priority <= 11 || data.data.priority >= 40)) {

            const show = () => {
                fetchQueue();
                setNewQueue(data.data)
                if(viewing_user.call_status === 'open') {
                    setShowModal(true);
                }
            }

            if(data.data.priority === 50 && viewing_user.privileges && viewing_user.privileges.includes('calls_after_sent_for_prep')) {
                show()
            } else if(data.data.priority === 40 && viewing_user.privileges && viewing_user.privileges.includes('calls_contacts')) {
                show()
            } else if(data.data.priority <= 11 && viewing_user.privileges && viewing_user.privileges.includes('calls_new_contacts')) {
                show()
            }
            
        }
    }, [fetchQueue, viewing_user.call_conference])
    
    const listenForQueueDeleted = useCallback((data) => {
        fetchQueue();
    }, [fetchQueue])

    useEffect(() => {
        if(showModal && viewing_user.call_status !== 'open') {
            setTimeout(() => {
                setShowModal(false)
            }, 1000)
        }
    }, [viewing_user.call_conference, showModal])

    useEffect(() => {
        fetchQueue()
        const interval = setInterval(() => {
            fetchQueue()
        }, [30 * 1000])

        io.on(socketEvents.call_queue_deleted, listenForQueueDeleted);
        io.on(socketEvents.call_queue_added,   listenForQueueAdded);

        return () => {
            io.off(socketEvents.call_queue_deleted, listenForQueueDeleted);
            io.off(socketEvents.call_queue_added,   listenForQueueAdded);
            clearInterval(interval);
        }
    }, [fetchQueue, listenForQueueDeleted, listenForQueueAdded])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div id="archk-call-alerts" className="border-top py-3 border-bottom bg-white">

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                size="sm"
            >
                <div className="modal-header">
                    <h5 className="modal-title">{isQueue ? 'Incoming Call' : 'New Lead!'}</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {isQueue ? (
                    <div className="modal-body">
                        <p className="text-sm">A call is currently on the line, press the call button below to accept.</p>
                        <p className="text-sm mb-0 text-capitalize">Type: {newQueue.type}</p>
                    </div>
                ) : (
                    <div className="modal-body">
                        <p className="text-sm">A new lead came in, press the call button below to call them.</p>
                        <p className="text-sm mb-0">Lead: {getName(newQueue)}</p>
                    </div>
                )}

                <div className="modal-footer">
                    <button className="btn btn-warning" onClick={() => setShowModal(false)}>Close</button>
                    <button className="btn btn-success" onClick={onStartCall}>Call</button>
                </div>
            </Modal>

            <Container fluid>
                <div className="card-group" style={{marginLeft: hasSidebar ? 200 : 0}}>


                    <Card className={`mb-0 border-right ml-0 mr-0 ${state.allQueues >= 10 ? 'bg-warning' : 'bg-info'}`} style={{maxWidth: 200}}>
                        <CardBody className={`text-center rounded ${state.allQueues >= 10 ? 'bg-warning' : 'bg-info'}`}>
                            <p className="text-sm mb-0 text-secondary">Status <br /> <i className="mr-1 fas fa-phone-square " />  {state.allQueues} <i className="mr-1 fas fa-headset ml-4" /> {state.usersOnCall}</p>
                        </CardBody>
                    </Card>

                    {state.queue && state.queue.length ? state.queue.map(queue => (
                        <CardItem key={queue._id} queue={queue}  onStartCall={() => {
                            if(viewing_user.call_status !== 'open') return;
                            setNewQueue(queue)
                            setShowModal(true);
                        }} getName={getName} />
                    )) : (
                        <Card className="bg-success mb-0 border-right ml-0 mr-0" style={{maxWidth: 200}}>
                            <CardBody className={`text-center rounded bg-success`}>
                                <p className="text-sm mb-0 text-secondary">There are no new contacts to call</p>
                            </CardBody>
                        </Card>
                    )}

                </div>

            </Container>
        </div>
    )
}

const Wrapper = ({viewing_user}) => {
    const location = useLocation();

    const [show, setShow] = useState(true);
    const [hasSidebar, setHasSidebar] = useState(false);

    useEffect(() => {
        if(
            location.pathname.includes('/dashboard/template_docs') ||
            location.pathname.includes('/dashboard/documents/merge') ||
            location.pathname === '/dashboard/messaging/emails' || 
            location.pathname === '/dashboard/calendar/me'
        ) {
            setShow(false);
        } else if(!show) {
            setShow(true);
        }
        if(location.pathname.includes('/dashboard/call_center')) {
            if(location.pathname === '/dashboard/call_center_admin') {
                setHasSidebar(false)
            } else {
                setHasSidebar(true)
            }
        } else {
            setHasSidebar(false)
        }
    }, [location, show])

    if(!show) return <div />

    return <CallAlerts viewing_user={viewing_user} hasSidebar={hasSidebar} />
  
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Wrapper);