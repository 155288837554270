/*
Documentation

this components shows all documents related to this case

*/

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom'
import React, { Component } from 'react';
import { Card, Row, Col, CardTitle, Badge} from "reactstrap";
import NotificationDelete from 'components/functional/notifications/Delete';
import CardHeader from 'reactstrap/lib/CardHeader';
import { setDocumentsForMerge } from 'store/functions/system/system'
import { toggleAlertBS } from 'store/functions/system/system';
import ImageRender from 'components/functional/images/ImageRenderer'

import { getUrlParameter } from 'utils/urls'
import moment from 'moment';

import ModalUpdateDocument from './modals/ModalUpdateDocument';
import ModalAssignMissing from './modals/ModalAssignMissing';
import ModalBulkUpdate from './modals/ModalBulkUpdate';

import renderName from 'utils/renderName'

class Documents extends Component {

    state = {
        document_id         : getUrlParameter('doc'),
        documentToUpdate    : null,
        showModal           : false,
        showModalBulkUpdate : false,
        showModalAssignMissing : false,

        foldersToShow       : [],
        documentsToShow       : [],
        tree                : [{name: 'Uncategorized'}],
        allChecks           : {},
        documentsForAction  : [],
        bulkType            : 'move', // move | delete
        checkAll            : false
    };

    scrolled = false;

    toggleModal              = (documentToUpdate) => this.setState({showModal: !this.state.showModal, documentToUpdate})
    toggleModalAssignMissing = () => this.setState({showModalAssignMissing: !this.state.showModalAssignMissing})
    toggleModalBulkUpdate    = (bulkType) => this.setState({showModalBulkUpdate: !this.state.showModalBulkUpdate, bulkType})

    onBulkActionCompleted = () => this.setState({documentsForAction: [], allChecks: {}})

    scrollTo = () => {
        if(!this.scrolled) {

            this.scrolled = true;

            const selectedDocument = document.getElementById('selected_document')        
            if(selectedDocument) selectedDocument.scrollIntoView({ behavior: 'auto', block: 'center' });

        }
       
    }

    onCheckAll = () => {

        const { checkAll } = this.state;

        const documentsToShow = [...this.state.documentsToShow];
        const allChecks = Object.assign({}, this.state.allChecks)
        let documentsForAction = [...this.state.documentsForAction];

        if(!checkAll) {

            documentsToShow.forEach(d => {
                if(!allChecks[d._id]) {
                    allChecks[d._id] = true;
                    documentsForAction.push(d)
                }
            })
         
            this.setState({checkAll: true, documentsForAction, allChecks})

        } else {

            documentsToShow.forEach(doc => {
                delete allChecks[doc._id]
            })

            documentsForAction  = documentsForAction.filter(d => allChecks[d._id] ? true : false)

            this.setState({checkAll: false, documentsForAction, allChecks})


        }

    }

    onCheckboxChange = (e, doc) => {

        const isChecked = this.state.allChecks[doc._id]
        let documentsForAction = [...this.state.documentsForAction]

        if(!isChecked) {
            documentsForAction.push(doc)
        } else {
            documentsForAction = documentsForAction.filter(d => d._id !== doc._id)
        }

        this.setState({
            documentsForAction,
            allChecks: {
                ...Object.assign({}, this.state.allChecks),
                [doc._id]: !isChecked
            }
        })

    }

    onStartMerge = () => {

        let errorDocs = []

        const docs = this.state.documentsForAction

        docs.forEach(doc => {

            const splitKeys = doc.object_key.split('.')
            const ending = splitKeys[splitKeys.length - 1]

            try {
                if(!ending.includes('jpg') && !ending.includes('jpeg') && !ending.includes('png') && !ending.includes('pdf')) {
                    errorDocs.push(doc.friendly_name)
                }
            } catch(e) {
                errorDocs.push(doc.friendly_name)
            }
         

        })

        if(errorDocs.length) {

            let message = [<div>The following files cannot be merged into a PDF:</div>]

            errorDocs.forEach((doc, i) => {
                if(i === 0) message.push(<div style={{height: 10}}></div>)
                message.push(<div className="font-weight-bold">{doc}</div>)
            })


            return toggleAlertBS(true, message)
        }

        // for each document if ending is not jpeg or pdf return an error here

        // validate types are only of image or pdf before moving on
        setDocumentsForMerge(docs)
        this.setState({shouldRedirect: `/dashboard/documents/merge/${this.props.case._id}`})

    }

    shouldStartChecked = (currentDocs) => {
        const { allChecks } = this.state
        return currentDocs[0] && allChecks[currentDocs[0]._id] ? true : false
    }

    setFoldersToShow = (parent_folder_id, props) => {

        const documentFolders = props.documentFolders ? [...props.documentFolders] : []
        const documents = props.documents ? [...props.documents] : []

        let foldersToShow = []

        // if not folder id set the state to show the base folder, aka no folder
        if(!parent_folder_id) {

            foldersToShow = documentFolders.filter(f => !f.parent)
            foldersToShow.forEach(f => {
                f.hasSubFolders = documentFolders.find(otherFolders => otherFolders.parent === f._id) ? true : false
            })

            const documentsToShow = documents ? documents.filter(d => !d.document_folder) : []

            return this.setState({
                parent_folder_id,
                foldersToShow,
                tree: [{name: 'Uncategorized'}],
                documentsToShow,
                checkAll: this.shouldStartChecked(documentsToShow)
            }, this.scrollTo)

        }

        let tree = [...this.state.tree]

        // see if the branch passed in is found, if not return as this is an error
        let foundBranch = documentFolders.find(f => f._id === parent_folder_id);
        if(!foundBranch) return

        const isLastFolder = tree[tree.length -1]._id === parent_folder_id
        const folderIsInTree = tree.find(t => t._id === parent_folder_id)

        foldersToShow = documentFolders.filter(f => f.parent === parent_folder_id)

        foldersToShow.forEach(f => {
            f.hasSubFolders = documentFolders.find(otherFolders => otherFolders.parent === f._id) ? true : false
        })

        const documentsToShow = documents ? documents.filter(d => d.document_folder === foundBranch._id) : []

        if(!isLastFolder) {

             // if folder is in the tree, remove everything after the tree, else add it to the folder tree
            if(folderIsInTree) {
                tree = tree.slice(0, tree.findIndex(t => t => t._id === parent_folder_id) - 1)
            } else {
                tree.push(foundBranch)
            }

        }

        // set the folder true to show the folder of the parent folder passed in
        return this.setState({
            parent_folder_id, 
            foldersToShow, 
            tree, 
            documentsToShow, 
            checkAll: this.shouldStartChecked(documentsToShow) 
        }, this.scrollTo)

    }

    componentWillReceiveProps = (nextProps) => {
        this.setFoldersToShow(this.state.parent_folder_id, nextProps)
    }

    componentDidMount = () => {
        this.setFoldersToShow(this.state.parent_folder_id, this.props)



    }

    render() {

        const { foldersToShow, documentsToShow, tree, documentToUpdate, showModal, documentToDelete, document_id } = this.state
        const { allChecks, documentsForAction, showModalBulkUpdate, bulkType, shouldRedirect, checkAll, showModalAssignMissing } = this.state
        const { documentFolders, onDocsAssigned } = this.props
        const _case = this.props.case;

        const now = Math.floor(new Date() / 1000)

        if(shouldRedirect) return <Redirect to={shouldRedirect} />

        return (

            <>

            {documentToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/documents/delete/${documentToDelete._id}`}
                    confirmBtnText="Delete Document"
                    title={`Delete ${documentToDelete.friendly_name}`}
                    text="Deleting this document will permanently remove it from the system, it can never be accessed again."
                    onClose={() => this.setState({documentToDelete: null})}
                    onSuccess={(result) => {
                        if(this.state.allChecks[result.data._id]) {
                            this.onCheckboxChange(null, result.data)
                        }
                    }}
                />
            )}

            <Card className="card-color card-warning">

                <CardHeader>
                    <Row>
                        <Col md={3}>
                            <CardTitle className="mb-0">Case Documents</CardTitle>
                        </Col>
                        <Col md={9} className="text-right">
                            <button 
                                disabled={!documentsForAction.length} 
                                className="btn btn-outline-warning"
                                onClick={() => this.toggleModalAssignMissing()}
                            >
                                Missing
                            </button>
                            <button 
                                disabled={!documentsForAction.length} 
                                className="btn btn-outline-primary"
                                onClick={this.onStartMerge}
                            >
                                Merge PDF
                            </button>
                            <button 
                                disabled={!documentsForAction.length} 
                                className="btn btn-outline-danger"
                                onClick={() => this.toggleModalBulkUpdate('delete')}
                            >
                                Delete
                            </button>
                            <button 
                                disabled={!documentsForAction.length} 
                                className="btn btn-success"
                                onClick={() => this.toggleModalBulkUpdate('move')}
                            >
                                Bulk Move
                            </button>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive" style={{overflow: 'visible'}}>
                    <table className="table" style={{tableLayout: 'fixed'}}>

                        <thead>
                            <tr>
                                <th className="bg-secondary font-weight-bold" style={{width: '20px'}}>
                                    {/* <i 
                                        className="fas fa-home text-success cursor-pointer" 
                                        onClick={() => this.setFoldersToShow(undefined, this.props)}
                                    /> */}
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id={`document-id-check-all`} 
                                            type="checkbox" 
                                            value={checkAll || ''}
                                            checked={checkAll || ''}
                                            onChange={(e) => this.onCheckAll()} 
                                            className="custom-control-input" 
                                        />
                                        <label className="custom-control-label" htmlFor={`document-id-check-all`}></label>
                                    </div>
                                </th>
                                <th className="bg-secondary" >
                                    {tree.map((t, i) => (
                                        <span key={i} className="mr-3" style={{position: 'relative', top: -4.5}}>
                                            {i === 0 ? null : <i className='text-success fas fa-arrow-right mr-3' /> }
                                            <span className="cursor-pointer" onClick={() => this.setFoldersToShow(t._id, this.props)}>{t.name}</span>

                                        </span>
                                    ))}
                                </th>
                                <th className="bg-secondary" ></th>
                                <th className="bg-secondary" ></th>
                                <th className="bg-secondary" style={{width: '63px'}}></th>
                            </tr>
                        </thead>

                        <tbody>

                            {foldersToShow && foldersToShow.length ? foldersToShow.map((folder, i) => (
                                    <tr key={i} >
                                         <td style={{width: '20px', maxWidth: '20px'}}></td>
                                        <td>
                                            <span
                                                className="cursor-pointer" 
                                                onClick={() => this.setFoldersToShow(folder._id, this.props)}
                                            >
                                                <i 
                                                    className={
                                                        folder.hasSubFolders ? "fas fa-folder-open mr-2 text-warning"  : 
                                                        "far fa-folder mr-2" 
                                                    } 
                                                />
                                                <span className="text-muted">{folder.name}</span>
                                            </span>
                                           
                                        </td>
                                        <td className="text-right"></td>
                                        <td></td>
                                        <td></td>
                                </tr>
                            )) : null }

                            {documentsToShow && documentsToShow.length ? documentsToShow.map((doc, i) => {

                                const isSelected = doc._id === document_id;

                                return (
                                    <tr key={doc._id} id={isSelected ? "selected_document" : `document_id_${doc._id}`} className={isSelected ? 'bg-dark text-white' : ''}>
                                        <td style={{width: '20px', maxWidth: '20px'}}>
                                            <div className="custom-control custom-checkbox">
                                                <input 
                                                    id={`document-id-${doc._id}`} 
                                                    type="checkbox" 
                                                    value={allChecks[doc._id] || ''}
                                                    checked={allChecks[doc._id] || ''}
                                                    onChange={(e) => this.onCheckboxChange(e, doc)} 
                                                    className="custom-control-input" 
                                                />
                                                <label className="custom-control-label" htmlFor={`document-id-${doc._id}`}></label>
                                            </div>
                                        </td>
                                        <td style={{width: '50%'}}>
                                            {doc.isNewUpload ? <Badge style={{position: 'relative', top: -1}} className="mr-3" color="success">New Upload</Badge> :
                                            doc.created_at >= now - 3600 ? <Badge style={{position: 'relative', top: -1}} className="mr-3" color="warning">Recent</Badge> :
                                            null}
                                            <ImageRender 
                                                doc={doc}
                                            />
                                        </td>
                                        <td className="text-right text-capitalize">{doc.user && doc.user.given_name ? renderName(doc.user) : '-'}</td>
                                        <td className="text-right">{moment.unix(doc.created_at).format('MM/DD/YYYY - h:mm A ')}</td>
                                        <td className="text-right">
                                            {doc.friendly_name && !doc.friendly_name.includes('In Progress Contract') ? (
                                                <>
                                                <i onClick={() => this.toggleModal(doc)} className="fas fa-edit text-success cursor-pointer mr-2" />
                                                <i onClick={() => this.setState({documentToDelete: doc})} className="fas fa-trash text-danger cursor-pointer" />
                                                </>
                                            ) : null}
                                        </td>
                                    </tr>
                                )

                            }) : (
                                <tr>
                                    <td className="text-warning font-weight-gold">There are no documents in this folder</td>
                                    <td></td>
                                </tr>
                            ) }

                        </tbody>

                    </table>
                </div>

                {documentFolders ? (
                    <ModalUpdateDocument
                        showModal={showModal}
                        toggleModal={() => this.toggleModal()}
                        document={documentToUpdate}
                        onSuccess={(values) => {}}
                        documentFolders={documentFolders}
                    />
                ) : null}

                {documentFolders ? (
                    <ModalBulkUpdate
                        showModal={showModalBulkUpdate}
                        bulkType={bulkType}
                        toggleModal={() => this.toggleModalBulkUpdate()}
                        onBulkActionCompleted={this.onBulkActionCompleted}
                        documentsForAction={documentsForAction}
                        onSuccess={(values) => {}}
                        documentFolders={documentFolders}
                    />
                ) : null}

                {showModalAssignMissing ? (
                    <ModalAssignMissing
                        showModal={showModalAssignMissing}
                        toggleModal={this.toggleModalAssignMissing}
                        onAssignMissing={this.onBulkActionCompleted}
                        documentsForAction={documentsForAction}
                        onSuccess={(values) => {}}
                        documentFolders={documentFolders}
                        case={_case}
                        onDocsAssigned={onDocsAssigned}
                    />
                ) : null}

            </Card>

            </>

        )

    }

}

Documents.propTypes = {
    case: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    documentFolders: PropTypes.array.isRequired,
}

export default Documents
