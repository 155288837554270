/*
Documentation

validate a phone number or email is valid based on the function

*/

import phone from 'phone'
import email from 'email-validator'

//doc this function not only validates if a phone number is accurate it also formats it into a universal format if it is
export const validatePhoneNumber = (number) => {

    //if no number is passed in return false
    if(!number) { return false }

    //try to convert the number to a string
    try {
        number = number.toString()
    } catch(e) {
        //if passed in value cant be converted to a string return false
        return false
    }

    const formatted = phone(number);

    //if formatting succeeded return the formatted phone number
    if(formatted[0]) return formatted[0]

    return false

}

//this function return true if an email is valid or false if it is not
export const validateEmail = (string) => {
    return email.validate(string)
}
