import AvatarImage from 'components/functional/images/AvatarImage';
import React from "react";
import { connect } from 'react-redux';
import { toggleStartCallModal } from 'store/functions/call_center/call_center';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

const SidebarRightDetails = ({contact, getContactIdentifier, users}) => {

    const getAssignedUser = () => {
        if(!contact.assigned_user) return <p className="text-sm mb-0 text-warning font-weight-bold">No Assigned User</p>;
        const foundUser = users.find(u => u._id === contact.assigned_user);
        if(foundUser) return (
            <div>
                <AvatarImage src={foundUser.avatar} />
                <p className="text-sm mb-0 d-inline-block text-capitalize ml-3  ">{renderName(foundUser)}</p>
            </div>
            
        )
        return <p className="text-sm mb-0 d-inline-block">No Assigned User Found</p>
    }

    return (

        <div>

            <div className="archk-messenger-sidebar-section">

                <h3 className="text-capitalize">
                    <span style={styles.icon}>
                        <i className="fas fa-user text-success mr-2 " /> 
                    </span>
                    <a href={`/dashboard/contacts/view/${contact._id}`} target="_blank" rel="noopener noreferrer">
                        {getContactIdentifier(contact)}
                    </a>
                </h3>

                <p className="text-sm mb-0">
                    <span style={styles.icon}>
                        <i className="fas fa-envelope mr-2 text-muted" /> 
                    </span>
                    {contact.email ? contact.email : '-'}
                </p>
                <p className="text-sm mb-0">
                    <span style={styles.icon}>
                        <i className="fas fa-mobile mr-2 text-muted" /> 
                    </span>
                    {contact.phone ? formatPhone(contact.phone) : '-'}
                </p>

            </div>

            <div className="archk-messenger-sidebar-section">

                <p className="text-sm mb-0">
                    <span style={styles.identifier}>Times Contacted:  </span>
                    {contact.times_contacted.toString()}
                </p>
                <p className="text-sm mb-0">
                    <span style={styles.identifier}>Text Opt Out:  </span>
                    {contact.opt_out_text ? <i className="fas fa-times text-danger font-weight-bold " /> : '-'}
                </p>
                <p className="text-sm mb-0">
                    <span style={styles.identifier}>Email Opt Out:  </span>
                    {contact.opt_out_email ? <i className="fas fa-times text-danger font-weight-bold " /> : '-'}
                </p>

            </div>
            
            <div className="archk-messenger-sidebar-section">
                <p className="text-sm mb-0 font-weight-bold text-dark">
                    <b><i className="fas fa-comments mr-2 " /></b> 
                    {contact.last_text ? contact.last_text :  <b className="text-warning font-weight-bold">No Previous Texts</b>}
                </p>
            </div>

            <div className="archk-messenger-sidebar-section">
                {getAssignedUser()}
            </div>

            <div className="archk-messenger-sidebar-section">
                <button onClick={() => toggleStartCallModal(true, contact)} className="btn btn-success btn-block"><i className="fas fa-headset mr-2 " />Call Contact</button>
            </div>

        </div>

    );

}

const styles = {
    icon: {
        display: 'inline-block',
        width: 30,
    },
    identifier: {
        display: 'inline-block',
        width: 170,
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};


export default connect(mapStateToProps, '')(SidebarRightDetails);
