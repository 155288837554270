import Circle from 'components/markup/loading/Circle';
import NameCircle from 'components/markup/misc/NameCircle';
import moment from 'moment';
import React from "react";
import { connect } from 'react-redux';
import { Badge, Col, Row } from 'reactstrap';

const MessengerSidebarLeft = ({contacts, onSelect, selectedContact, getContactIdentifier}) => {

    const getName = (contact) => {

        const identifier = getContactIdentifier(contact)
        return identifier && identifier.length > 18 ? identifier.slice(0,18) + '...' : identifier
        
    }

    if(!contacts) return <Circle />
    if(!contacts.length) return  <li><p className="text-sm mb-0">There are no contacts in this list to show.</p></li>
    
    return contacts.map((contact, i) => {

        const hasUnread = contact.unread_texts > 0;
        const neverContacted = !contact.times_contacted;
        const isSelected = selectedContact && selectedContact._id === contact._id;

        const today = moment().format('MM/DD/YYYY')
        const dateDay = moment.unix(contact.last_text_date).format('MM/DD/YYYY')
        const dateTime = moment.unix(contact.last_text_date).format('h:mm A')

        let wrapperClass = 'position-relative cursor-pointer'
        if(isSelected) wrapperClass += ' bg-gradient-success text-white z-depth-1'

        return (
            <li key={i} className={wrapperClass} onClick={() => onSelect(contact)}>
                {neverContacted ? (
                    hasUnread ? (
                        <Badge color="success">NEW!</Badge>
                    ) : null
                ) : hasUnread && (
                    <Badge color="success">{contact.unread_texts}  <i className="fas fa-comments " /></Badge>
                )}
                <Row>
                    <div className="col-auto align-self-center" style={{width: 35}}>
                        <NameCircle 
                            contact={contact} 
                            bg={hasUnread && isSelected ? 'bg-secondary' : hasUnread ? '' :  'bg-gradient-success'} 
                            color={neverContacted  ? 'text-white' : hasUnread && isSelected ? 'text-dark' : 'text-white'} 
                            width={35} 
                        />
                    </div>
                    <Col className="align-self-center">
                        <div className="pl-3">
                            <div className="text-capitalize archk-messenger-sidebar-name"> {getName(contact)}</div>
                            <div className="archk-messenger-sidebar-preview text-muted">
                                <span className={isSelected ? 'text-white' : ''}>
                                    <span className="archk-messenger-sidebar-preview-date text-lowerase mr-2">
                                        <b>{contact.last_text_date ? dateDay === today ? dateTime : dateDay : '-'}</b>
                                    </span>
                                    {contact.last_text}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </li>
        )
    });

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(MessengerSidebarLeft);