/*
Documentation

allows a user to subscribe to contacts and get a notification anytime communication comes in
from the contact such as a text or email

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import Circle from 'components/markup/loading/Circle';

import _user_notification_settings from '_functions/user_notification_settings'
import { toggleStandardLoader } from 'store/functions/system/system';
import { toggleAlertBS } from 'store/functions/system/system'
import renderName from 'utils/renderName';

import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';

import validator from 'utils/validator';

const required_fields = [ ]

class NotificationsSettings extends React.Component {

    state = {
        loaded: false,
        notification_settings: {
            subscribed_contacts: []
        },
    }

    onSelect = (contact) => {

        const notification_settings = Object.assign({}, this.state.notification_settings)

        let subscribed_contacts = notification_settings.subscribed_contacts || [];

        if(!subscribed_contacts.find(c => c._id === contact._id)) {

            subscribed_contacts.push(contact)
            this.setState({notification_settings : {...notification_settings, subscribed_contacts}})

        }

    }

    onRemove = (contact) => {

        const notification_settings = Object.assign({}, this.state.notification_settings)

        let subscribed_contacts = notification_settings.subscribed_contacts || [];

        subscribed_contacts = subscribed_contacts.filter(c => c._id !== contact._id)

        this.setState({notification_settings : {...notification_settings, subscribed_contacts}})


    }

    onInputChange = (field, value) =>  validator.onInputChange(value, 'notification_settings', field, this)

    onSave = async () => {

        const validateFields = validator.requiredFields('notification_settings', required_fields, this)
        const notification_settings = validateFields.state

        if(validateFields.success) {

            toggleStandardLoader(true)

            let subscribed_contacts = []
            const contacts = notification_settings.subscribed_contacts

            if(contacts && contacts.length) contacts.forEach(c => subscribed_contacts.push(c._id))

            const data = { ...notification_settings, subscribed_contacts }

            const saved = await _user_notification_settings.update(this.props.viewing_user._id, data)
            if(saved.success) toggleAlertBS(false, 'Saved Successfully')

            toggleStandardLoader(false)

        }

     }

     componentDidMount = async () => {

        const notification_settings = await _user_notification_settings.find(`?filter=user__${this.props.viewing_user._id}`)

        if(notification_settings.success && notification_settings.data[0]) {
            this.setState({notification_settings: notification_settings.data[0]})
        }

        this.setState({loaded: true})

     }

    render() {

        const { notification_settings, loaded } = this.state
        let { subscribed_contacts } = notification_settings

        if(!subscribed_contacts) subscribed_contacts = []

        if(!loaded) return <div className="py-6"><Circle /></div>

        return (
        <>

            <Helmet>
                <title>{`Notification Settings`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Notification Settings</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link to="/dashboard/notifications" className="btn btn-success">
                        <i className="fas fa-arrow-left mr-2" /> All Notifications
                        </Link>
                    )}
                />

                <Row>

                    <Col lg={6}>

                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Contact Communications</CardTitle>
                                <p className="text-sm mb-0">By adding a contact below you will receive alerts for all emails and texts sent to and from them.</p>
                            </CardHeader>

                            <CardBody>
                                <ContactSearchInput
                                    title="Add Contact"
                                    value={{}}
                                    onSelect={this.onSelect}
                                    leaveEmpty={true}
                                />
                            </CardBody>

                        </Card>

                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Subscribed Contacts</CardTitle>
                            </CardHeader>

                            <table className="table" >

                                <tbody>
                                    {subscribed_contacts && subscribed_contacts.length ? subscribed_contacts.map(contact => (
                                        <tr key={contact._id}>
                                            <td style={{whiteSpace: 'pre-line'}} className="text-capitalize">
                                                <i className="fas fa-trash text-danger mr-3 cursor-pointer" onClick={() => this.onRemove(contact)} />
                                                {renderName(contact)}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td className="text-warning font-weight-bold" style={{whiteSpace: 'pre-line'}}>
                                                You are currently not subscribed to any contact notifications.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>

                            <CardFooter className="text-right">
                                <p className="text-sm text-left">Saving the above contacts will enable communication alerts with everyone in the list.</p>
                                <button onClick={this.onSave} className="btn btn-success">
                                    <i className="fas fa-save mr-2" />
                                    Save Contacts
                                </button>
                            </CardFooter>

                        </Card>

                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(NotificationsSettings);
