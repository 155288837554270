/*
Documentation

This page shows all of the systems contacts

*/

import React from "react";
import { connect } from 'react-redux';
import { Modal, Row, Col } from "reactstrap";
import moment from 'moment';
import Circle from 'components/markup/loading/Circle';
import _appointments from '_functions/appointments';
import renderName from 'utils/renderName';
import AvatarImage from 'components/functional/images/AvatarImage';

const TextRow = (props) => (
    <Row>
        <Col md={4}>
            <p className="mb-0 text-sm font-weight-bold">{props.title}</p>
        </Col>
        <Col md={8}>
            <p className="mb-0 text-sm">{props.value}</p>
        </Col>
    </Row>
);


class CalendarEventModal extends React.Component {

    state = {
        appointment: null,
    }

    fetchAppointment = async (props) => {

        if(props.appointment_id) {

            const foundAppointment = await _appointments.findFinishedById(this.props.appointment_id)

            if(foundAppointment.success) {
                this.setState({finished_appointment: foundAppointment.data})
            }

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            this.fetchAppointment(nextProps)
        }

    }

    componentDidMount = async () => {

        this.fetchAppointment(this.props)

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { finished_appointment } = this.state

        return (

            <>

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleModal()}
                size="md"
            >

                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">Finished Appointment </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body">

                   {finished_appointment ? (
                       <div>
                            {finished_appointment.finished_by ? (
                                <TextRow
                                    title={<span style={{position: 'relative', top: 3}}>Finished By</span>}
                                    value={<span className="text-capitalize"><AvatarImage className="z-depth-2" style={{width: 30, height: 30}} src={finished_appointment.finished_by.avatar}/> {renderName(finished_appointment.finished_by)}</span>}
                                />
                            ) : null}

                            <hr className="my-2" />

                            <TextRow
                                title="Name"
                                value={<span className="text-capitalize">{finished_appointment.name}</span>}
                            />

                            <TextRow
                                title="Location"
                                value={finished_appointment.location}
                            />

                            <hr className="my-2" />

                            <TextRow
                                title="Finished On"
                                value={moment.unix(finished_appointment.finished_on).format('MMMM Do, YYYY - h:mm A')}
                            />
                            <TextRow
                                title="Date"
                                value={moment.unix(finished_appointment.date).format('MMMM Do, YYYY - h:mm A')}
                            />

                       </div>
                   ) : <Circle />}

                </div>



            </Modal>

            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(CalendarEventModal);
