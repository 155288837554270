import moment from 'moment';
import React, { Component } from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import PropTypes from 'prop-types';

class CalendarHeader extends Component {


    state = {
        showLoading: false
    };

    // show loading and run props to refresh data
    onRefresh = () => {

        // fake a loading of 2 seconds
        this.setState({showLoading: true}, () => {
            setTimeout(() => { this.setState({showLoading: false}) }, 2000)
        })

        // send to props to refresh the data on the calendar
        this.props.onRefresh()

    }

    // basically just capitalize
    getViewFriendly = () => {

        const { showView } = this.props

        if(showView === 'day') {
            return 'Day'
        } else if(showView === 'week') {
            return 'Week'
        }  else if(showView === 'month') {
            return 'Month'
        }

    }

    // get the format to show in the header of the calendar
    getHeaderDate = () => {

        const { day_to_show, showView } = this.props
        const date = moment.unix(day_to_show);

        if(showView === 'day') {
            return date.format('MMMM Do YYYY')
        } else if(showView === 'week') {
            return date.format('MMMM YYYY')
        }  else if(showView === 'month') {
            return date.format('MMMM YYYY')
        }


    }

    render() {

        const { setView, goToToday } = this.props
        const { showLoading } = this.state

        return (

            <div className="archk-calendar-header">

                <Row className="ml-0 mr-0">

                    <Col md={6} className="align-self-center pl-0">

                        <button onClick={() => goToToday()} className="btn btn-neutral timeframe-picker">Go To Today</button>

                        <span className="month-year">
                            {this.getHeaderDate()}
                        </span>

                    </Col>

                    <Col md={6} className="text-right align-self-center pr-0">

                        <i
                            onClick={!showLoading ? this.onRefresh : null}
                            className={showLoading ? "archk-spin fas fa-sync-alt mr-3 cursor-pointer"  : "fas fa-sync-alt mr-3 cursor-pointer"  }
                        />

                        <UncontrolledDropdown nav>

                            <DropdownToggle className="nav-link pl-0 pr-0 timeframe" color="" tag="a">
                                <button style={{width: 85}} className="btn btn-neutral timeframe-picker">{this.getViewFriendly()} <i className="fas fa-caret-down" /></button>
                            </DropdownToggle>

                            <DropdownMenu className="bg-primary" right>

                                <DropdownItem onClick={() => setView('day')}>
                                    <span className="float-left">Day</span>
                                    <span className="float-right">D</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => setView('week')}>
                                    <span className="float-left">Week</span>
                                    <span className="float-right">W</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => setView('month')}>
                                    <span className="float-left">Month</span>
                                    <span className="float-right">M</span>
                                </DropdownItem>

                            </DropdownMenu>

                        </UncontrolledDropdown>

                    </Col>

                </Row>

            </div >

        )

    }

}

CalendarHeader.propTypes = {

    // required
    showView    : PropTypes.string.isRequired,
    setView     : PropTypes.func.isRequired,
    goToToday   : PropTypes.func.isRequired,
    onRefresh   : PropTypes.func.isRequired,

}

export default CalendarHeader
