
/*
Documentation

This card listens for un listened voicemails within the system
and displays them on the admin call center

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import _voicemail from '_functions/voicemail';

import Circle from 'components/markup/loading/Circle'

class CallCenterVoicemail extends React.Component {

    state = {
        voicemailCount: null
    }

    listenForVoicemailUpdated = (data) => {

        let { voicemailCount } = this.state

        if(data && data.data && data.data.listened !== undefined) {

            if(data.data.listened === false) {
                voicemailCount++
            } else if(data.data.listened === true) {
                voicemailCount--
            }
            
            // don't let count go below zero, just in case
            if(voicemailCount >= 0) this.setState({voicemailCount})

        }

    }

    listenForVoicemailAdded   = () => {
        let { voicemailCount } = this.state
        voicemailCount++

        this.setState({voicemailCount})
    }

    // here we make a call to find unlistend voicemail but limit
    // the results to 1 document. this will return the total_documents
    // property which is all we need
    queryVoicemail = async () => {
    
        const voicemail = await _voicemail.find(`?filter=listened__false{bool}&limit=1`);
        if(voicemail.success) this.setState({voicemailCount: voicemail.total_documents})
    
    }

    componentDidMount = async () => {

        this.queryVoicemail()

        io.on(socketEvents.voicemail_updated,    this.listenForVoicemailUpdated)
        io.on(socketEvents.voicemail_added,      this.listenForVoicemailAdded)

    }

    componentWillUnmount = () => {

        io.off(socketEvents.voicemail_updated,    this.listenForVoicemailUpdated)
        io.off(socketEvents.voicemail_added,      this.listenForVoicemailAdded)

    }

    render() {

        let { voicemailCount } = this.state;

        if(voicemailCount === null) return <Circle />

        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Voicemail</CardTitle>
                </CardHeader>

                <CardBody className="text-center font-weight-bold">
                    <span
                        className={!voicemailCount ? 'text-success' : voicemailCount < 30 ? 'text-warning' : 'text-danger'}
                        style={{fontSize: '2rem'}}
                    >
                        <i className="fas fa-mail-bulk mr-2" />
                        <span> {voicemailCount}</span>
                    </span>
                </CardBody>

            </Card>

        );
    }
}

export default CallCenterVoicemail
