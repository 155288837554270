/*
Documentation

this component renders the card showing all missing documents on the case

*/

import ReactSelect from 'components/functional/inputs/ReactSelect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from "react";
import { Card, CardFooter, CardHeader, CardTitle, UncontrolledTooltip, Row, Col } from "reactstrap";

import ModalMissingDocs from './modals/ModalMissingDocs'
import ModalAddMissingDocs from './modals/ModalAddMissingDocs'
import _cases from '_functions/cases';
import * as privileges from '_functions/users/privileges'
import ImageRender from 'components/functional/images/ImageRenderer'
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

class DocumentsMissing extends React.Component {

    state = {
        missing_docs: null,
        missing_doc: null,
        showModal: false,
        showModalAddMissingDoc: false,
    }

    onSuccessAddMissingDoc = (doc) => {

        const missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []
        missing_docs.push(doc)

        this.setState({missing_docs})

    }


    // get everything after . or return "Media Object"
    getDocumentName = (link) => {

        if(!link) return 'Misc Document'

        const arr = link.split('/')

        return arr ? arr[arr.length -1] : 'Misc Document'

    }

    toggleModal = (missing_doc, bool) => this.setState({showModal: bool ? bool : !this.state.showModal, missing_doc})
    toggleModalAddMissingDoc = (e) => this.setState({
        showModalAddMissingDoc: !this.state.showModalAddMissingDoc, 
        isPost: e === 'post' ? true : false,
        isRequest: e === 'request' ? true : false,
    })


    onRemoveMissingDoc = (missing_doc, document) => {

        let missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []

        const found_doc = missing_docs.find(doc => doc._id === missing_doc._id)

        if(found_doc) {

            let documents = found_doc.documents ? found_doc.documents : []
            documents = documents.filter(d => {

                const doc_id = d._id ? d._id : d;
                return doc_id !== document._id
            })

            found_doc.documents = documents

            this.setState({missing_docs})
            this.onSaveMissingDocs(true)

        }
        
    }

    onSaveMissingDocs = (hideAlert) => {

        _cases.update(this.props.case._id, { missing_docs: this.state.missing_docs })

    }

    onMissingDocChange = (missing_doc_id, values) => {

        let missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []
        const doc = missing_docs[missing_docs.findIndex(doc => doc._id === missing_doc_id)]

        doc.status_marked_at = Math.floor(new Date() / 1000);
        doc.status_marked_by = this.props.viewing_user._id;
        doc.status = values.value

        this.setState({missing_docs})

    }

    onDocsAssigned = (missing_docs) => {
        this.setState({missing_docs})

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.lastDocsAssigned && this.props.lastDocsAssigned.timestamp !== nextProps.lastDocsAssigned.timestamp) {
            this.setState({missing_docs: nextProps.lastDocsAssigned.missing_docs})
        }

    }

    onTemplateDocAdded = (data) => {

        if(data.data.document.case === this.props.case._id) {

            const missing_docs = [...this.props.case.missing_docs];
            const foundDoc = missing_docs.find(d => d.name === data.data.templateDocName);

            if(foundDoc) {
                foundDoc.documents.push(data.data.document);
                this.setState({missing_docs})
            }

        }

    }

    componentWillUnmount = () => {
        
        io.off(socketEvents.case_template_doc_added, this.onTemplateDocAdded)

    }

    componentDidMount = () => {

        this.setState({missing_docs: this.props.case.missing_docs});

        io.on(socketEvents.case_template_doc_added, this.onTemplateDocAdded)

    }


    render() {

        const canUpdate = privileges.canUpdateCases()

        const { missing_doc, missing_docs, showModal, showModalAddMissingDoc } = this.state
        const { documents } = this.props;
        const _case = this.props.case

        const isMobile = this.props.device.info.isMobile;

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">
                        <Row>

                            <Col xs={6}>
                                Missing Docs
                            </Col>

                            <Col xs={6} className="text-right">
                                <button
                                    style={{width: 160}}
                                    onClick={canUpdate ? () => this.toggleModalAddMissingDoc('request') : null }
                                    disabled={!canUpdate}
                                    className="btn btn-outline-info"
                                >
                                    Add POST Request
                                </button>

                                <button
                                    style={{width: 160}}
                                    onClick={canUpdate ? () => this.toggleModalAddMissingDoc('post') : null }
                                    disabled={!canUpdate}
                                    className="btn btn-outline-warning"
                                >
                                    Add POST Doc
                                </button>

                                <button
                                    style={{width: 160}}
                                    onClick={canUpdate ? this.toggleModalAddMissingDoc : null }
                                    disabled={!canUpdate}
                                    className="btn btn-outline-success"
                                >
                                    Add Missing Doc
                                </button>
                            </Col>

                        </Row>
                    </CardTitle>
                </CardHeader>

                <div className="table-responsive" style={{overflow: isMobile ? 'auto': 'visible'}}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Doc Name</th>
                                <th>Uploaded</th>
                                <th className="text-right" style={{width: 150}}>Add Document</th>
                                <th className="text-right" style={{width: 250, minWidth: 250}}>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {missing_docs && missing_docs.length ? missing_docs.map((doc, i) => (
                                <tr key={doc._id ? doc._id : i}>
                                    <td style={{verticalAlign: 'top'}}>
                                        <span id={'missing-doc-description-' + doc._id } className={doc.description ? 'cursor-pointer' : ''}>
                                            {doc.description && (
                                                <i className="fas fa-info-circle text-info-original mr-2" />
                                            )}
                                            {doc.name}
                                        </span>
                                        {doc.description && (
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={'missing-doc-description-' + doc._id}
                                            >
                                            {doc.description}
                                            </UncontrolledTooltip>
                                        )}
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        {doc.documents && doc.documents.length ? doc.documents.map((document, i) => {

                                            if(!document._id) {

                                                // somehow document.find can give a not found error, check that documents are an array here before
                                                // running the find function documents
                                                const foundDoc = documents && documents.length && documents.find(d => d._id === document);
                                                if(foundDoc) {
                                                    document = foundDoc;
                                                } else {
                                                    // don't show any documents that cannot be found
                                                    return '';
                                                }

                                            }

                                            return (
                                                <div key={document._id + i}>
                                                    <span className="border-bottom">
                                                        <span style={{display: 'inline-block', width: 230}}>
                                                            <ImageRender 
                                                                maxNameLength={20}
                                                                doc={document}
                                                            />
                                                        </span>
                                                        <i 
                                                            onClick={() => this.onRemoveMissingDoc(doc, document)} 
                                                            className="fas fa-trash ml-3 text-danger cursor-pointer" 
                                                        />
                                                    </span>  
                                                </div>
                                            )
                                        }) : '-'}
                                    </td>
                                    <td style={{verticalAlign: 'top'}}  className="text-right">
                                        <button 
                                            style={{height: 38}} 
                                            onClick={() => this.toggleModal(doc)} 
                                            className="btn btn-outline-success ml-4"
                                        >
                                            <i className="fas fa-plus" /> Upload
                                        </button>
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <ReactSelect
                                            defaultValue={{
                                                label: doc.status === 'n/a' ? 'N/A' :
                                                    doc.status === 'completed' ? <span><i className="fas fa-check mr-3 text-success" />Completed </span> :
                                                    <span><i className="fas fa-times mr-3 text-danger" />Missing</span>,
                                                value: doc.status
                                            }}

                                            onChange={(values) => this.onMissingDocChange(doc._id, values)}
                                            options={[
                                                {value: 'n/a', label: 'N/A' },
                                                {value: 'missing', label: <span><i className="fas fa-times mr-3 text-danger" />Missing</span> },
                                                {value: 'completed', label: <span><i className="fas fa-check mr-3 text-success" />Completed </span> },
                                            ]}
                                        />
                                    </td>
                                </tr>
                                )) : (
                                <tr>
                                    <td>This case has no missing documents.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                {missing_docs && missing_docs.length ? (
                    <CardFooter className="text-right">
                        <button
                            onClick={canUpdate ? this.onSaveMissingDocs : null}
                            className="btn btn-success"
                            disabled={!canUpdate}
                        >
                            Save Missing
                        </button>
                    </CardFooter>
                ) : null}

            </Card>

            <ModalMissingDocs
                case={_case}
                missing_doc={missing_doc}
                showModal={showModal}
                toggleModal={() => this.toggleModal(undefined, false)}
                isPost={this.state.isPost}
            />

            <ModalAddMissingDocs
                case={_case}
                showModal={showModalAddMissingDoc}
                toggleModal={this.toggleModalAddMissingDoc}
                isPost={this.state.isPost}
                isRequest={this.state.isRequest}
                onSuccess={this.onSuccessAddMissingDoc}
            />

            </>

        );
    }
}

DocumentsMissing.propTypes = {
    case: PropTypes.object.isRequired
}


const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    	device: state.device,
    };
};

export default connect(mapStateToProps, '')(DocumentsMissing);
