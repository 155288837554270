

/*
Documentation

This file shows all case_alerts in the system. Has simple functionality to create, and delete them

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Badge, Card, CardHeader, CardTitle, Container, CardFooter, Row, Col, CardBody } from "reactstrap";

import { architeckCall } from 'database';

import { Device } from 'twilio-client';
import ReactJson from 'react-json-view';

class CallCenterTest extends React.Component {

    state = {
        browserDevices: null,
        browser: null,
    }

    container = () => document.getElementById('archk-debug-container');

    addMessage = (message) => {
        this.container().innerHTML += (`<p class="text-sm mb-0">${message}</p>`)
    }
    addError = (message) => {
        this.container().innerHTML += (`<p class="text-sm mb-0"> <i class="fas fa-times text-danger mr-2"></i>${message}</p>`)
    }
    addSuccess = (message) => {
        this.container().innerHTML += (`<p class="text-sm mb-0"> <i class="fas fa-check text-success mr-2"></i>${message}</p>`)
    }

    setConnection = async () => {

        this.addMessage('Fetching Connection Token')

        let token;

        try {

            const generateToken = await architeckCall({
                shouldNotLogAndShowError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/tokens/generate',
                data: {
                    user_id: this.props.viewing_user._id,
                }
            })

            token = generateToken.data;

            this.addSuccess('Token Successfully Fetched');


        } catch(e) {

            this.addError('Token did not return successfully');
            return this.addMessage(JSON.stringify(e))

        }


      
        try {

            this.addMessage('Setting Up Device')

            this.addMessage(`Browser Can Support Device: ${Device.isSupported}`);

            Device.setup(token, {
                debug: true,
                enableRingingState: true,
                warnings: true
            });

            this.addSuccess('Device Successfully Set Up');

        } catch(e) {

            this.addError('Device did not set up correctly');
            return this.addMessage(JSON.stringify(e))

        }

        this.addMessage('Device Loading')

        Device.on('ready', () => {

            this.addMessage('Device Ready');
            this.addMessage('Testing Speakers');

            Device.audio.speakerDevices.test();
            
        })

        Device.on('cancel', (a) => {

            this.addMessage('Event: Cancelled')
            this.addMessage(JSON.stringify(a))

        })

        Device.on('error', (a) => {

            this.addMessage('Event: Error')
            this.addMessage(JSON.stringify(a))

        })

        Device.on('disconnect', (a) => {

            this.addMessage('Event: Disconnect')
            this.addMessage(JSON.stringify(a))

        })
        Device.on('incoming', (a) => {

            this.addMessage('Event: Incoming')
            this.addMessage(JSON.stringify(a))

        })
        Device.on('offline', (a) => {

            this.addMessage('Event: Offline')
            this.addMessage(JSON.stringify(a))

        })
       
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) =>  {
            this.addSuccess('Stream Is Successfully Allowed');
        })
        .catch((e) => {
            this.addError('Stream is not allowed by browser');
            return this.addMessage(JSON.stringify(e))
        });

        setTimeout(() => {
            this.addMessage('Finished Main Events. Done')
        }, 2000)

    }

    getBrowser = () => {

        var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
        if(/trident/i.test(M[1])){
            tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
            return {name:'IE',version:(tem[1]||'')};
            }   
        if(M[1]==='Chrome'){
            tem=ua.match(/\bOPR|Edge\/(\d+)/)
            if(tem!=null)   {return {name:'Opera', version:tem[1]};}
            }   
        M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
        
        this.setState({browser: M[0] + M[1] })
    }

    getBrowserDevices = async () => {
        const browserDevices = await navigator.mediaDevices.enumerateDevices()
        this.setState({browserDevices})
    }

    componentDidCatch = (err) => {
        console.log(err)
    }

    componentDidMount = async () => {
       

        
        this.getBrowserDevices();
        this.getBrowser();

    }
  

    render() {

        const { browserDevices, browser } = this.state;

        return (
            <>

            <Helmet>
                <title>{`Call Center Test`}</title>
                <meta name="description" content="Call Center Test" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Call Center Test</span>} breadcrumb_1="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <Row>
                            <Col lg={6}>
                                <CardTitle className="mb-0 float-left">Call Center Test</CardTitle>
                            </Col>
                            <Col lg={6} className="text-right">
                                <button onClick={this.setConnection} className="btn btn-success">TEST CONNECTION</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardFooter>
                        <p className="text-sm mb-0">Browser: {browser}</p>
                    </CardFooter>

                    <CardFooter id="archk-debug-container" className="bg-secondary">
                        <p className="text-sm mb-0">Connection Log</p>
                    </CardFooter>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Kind</th>
                                    <th>IDs</th>
                                    <th className="text-right">Label</th>
                                </tr>
                            </thead>

                            <tbody>
                                {browserDevices && browserDevices.length ? browserDevices.map((device, i) => (
                                    <tr key={i}>
                                        <td>
                                        {device.kind === 'audioinput' ? (
                                            <Badge style={{width: 150, display: 'inline-block'}} className="text-sm" color="success">INPUT</Badge>
                                        ) : device.kind === 'audiooutput' ? (
                                            <Badge style={{width: 150, display: 'inline-block'}} className="text-sm" color="warning">OUTPUT</Badge>
                                        ): (
                                            <Badge style={{width: 150, display: 'inline-block'}} className="text-sm" color="info">{device.kind}</Badge>
                                        )}
                                        </td>
                                        <td>
                                            <div>Device ID: {device.deviceId === 'default' ? <Badge className="text-sm" color="success">{device.deviceId}</Badge> : device.deviceId}</div>
                                            <div>Group ID: {device.groupId}</div>
                                        </td>
                                        <td className="text-right">{device.label}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Devices Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </Card>
                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">My Device Info</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <ReactJson 
                            src={this.props.device}
                        />
                    </CardBody>

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        device: state.device.info,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CallCenterTest);
