import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

const StartCallRedirect = () => {

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setRedirect(`/dashboard/call_center/${window.location.search}`)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return <div></div>
}

export default StartCallRedirect