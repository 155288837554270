/*
Documentation

this shows a table of assigned appointments unfinished before the end of the day
whether or not they are overdue. This may go back a few days if appointments are missed

*/

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import * as _ from 'underscore';
import renderName from 'utils/renderName';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import _appointments from '_functions/appointments';

class CallCenterAdminAssignedAppointments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            endOfDay: moment().endOf('day').format('X'),
            startOfDay: moment().startOf('day').format('X'),
            now: Math.floor(new Date() / 1000),

            page: 1,
            sizePerPage: 4,
            total_documents: 0,
            data: null,


            showAppointmentsCrud: false,

            call_queue: null,
            appointment: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 1500)
    }

    toggleAppointmentsCrud = (appointment) => this.setState({showAppointmentsCrud: !this.state.showAppointmentsCrud, appointment})

    listenForAppointmentUpdated = (data) => {

        if(data.data.show === 'user') this.queryTableData()

    }

    listenForAppointmentDeleted = (data) => {

        let newState = this.state.data ? [...this.state.data] : [];
        newState = newState.filter(a => a._id !== data.data._id)

        this.setState({data: newState})

    }

    listenForAppointmentAdded = (data) => {

        if(data.data.show === 'user' && data.data.date <= this.state.endOfDay) {
            this.queryTableData()
        }

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const { endOfDay, page, sizePerPage, startOfDay } = this.state
        const skip = (page - 1) * sizePerPage

        const appointments = await  _appointments.find(`?filter=show__user|date__${startOfDay}{gt}|date_end__${endOfDay}{lt}&populate=assigned_to contact&sort=date__asc&limit=${sizePerPage}&skip=${skip}`)

        this.setState({
            data: appointments.data,
            total_documents: appointments.total_documents,
            canRun: false,
            loading: false,

        })

    }

    componentWillUnmount = () => {

        io.off(socketEvents.appointments_updated,    this.listenForAppointmentUpdated)
        io.off(socketEvents.appointments_deleted,    this.listenForAppointmentDeleted)
        io.off(socketEvents.appointments_added,      this.listenForAppointmentAdded)

    }

    componentDidMount = async () => {

        this.queryTableData()

        io.on(socketEvents.appointments_updated,    this.listenForAppointmentUpdated)
        io.on(socketEvents.appointments_deleted,    this.listenForAppointmentDeleted)
        io.on(socketEvents.appointments_added,      this.listenForAppointmentAdded)

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    columns = [
        {
            dataField: "assigned_to",
            text: "Assigned To",
            formatter: (cell, row) => (
                <div className="text-capitalize">
                    {row.assigned_to && row.assigned_to.length ? row.assigned_to.map((user, i) => (
                        i === 0 ? renderName(user) : ', ' + renderName(user)
                    )) : 'Not Assigned'}
                </div>
            ),
        },
        {
            dataField: "contact",
            text: "Contact",

            formatter: (cell, row) =>  <span className="text-capitalize">{renderName(row.contact)}</span>

        },
        {
            dataField: "appointment",
            text: "Appointment",

            formatter: (cell, row) =>  (
                <div style={{width: 180, whiteSpace: 'pre-line'}}>
                    <div className="text-capitalize">{row.name}</div>
                </div>
            )

        },
        {
            dataField: "time",
            text: "Time",
            // anything that is dated before 30 minutes in the future show as read
            formatter: (cell, row) =>  (
                <div className={row.date + 1800 < this.state.now ? 'text-danger font-weight-bold' : null}>
                    {moment.unix(row.date).format('M/DD - h:mm A')}
                </div>
            )
        },
        {
            dataField: "created_at",
            text: "Update",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i onClick={() => this.toggleAppointmentsCrud(row)} className="fas fa-edit text-success" />
                    </div>
                )
            }
        },
    ]

    render() {

        let { data, searchText,  appointment, showAppointmentsCrud, total_documents } = this.state;
        const { currentScreenWidth } = this.props.device.info


        return (
            <>

                <Card className="card-color card-primary" style={{minHeight: currentScreenWidth < 1000 ? 'auto' : 400}}>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Assigned Appointments ({total_documents}) </CardTitle>
                    </CardHeader>

                    {data ? (
                        data.length || searchText ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <div className="table-not-fixed table-responsive table-vertical-align" style={{minHeight: 262}}>
                                        <BootstrapTable

                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: false,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => false

                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}

                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0"> There are no assigned appointments today. </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

                {showAppointmentsCrud ? (
                    <AppointmentsCrud
                        toggleAppointmentsCrud={this.toggleAppointmentsCrud}
                        showAppointmentsCrud={showAppointmentsCrud}
                        appointment_id={appointment ? appointment._id : null}
                        title={"Update Appointment"}
                        onSuccess={() => this.toggleAppointmentsCrud()}
                        isModal={true}
                    />
                ) : null}
               

            </>
        );
    }
}

CallCenterAdminAssignedAppointments.propTypes = {
    company: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        user_statuses: state.user_statuses.user_statuses,
        device: state.device,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdminAssignedAppointments);
