/*
Documentation

This file renders and input that auto suggests tags from our database
these tags work of of a redux store and will only allow a tag to be added that is in this store
the save animation is purely aesthetic as saving is handled by the parent component


Change Log
6/18/2020 - John Maher
Initial Creation Of File
Tested And Working

6/25/2020 - John Maher
added in value to will mount to reset tags when probs change by key

*/

import React from 'react'

import TagsInput from "react-tagsinput";
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';

class TagsUpdater extends React.Component {

    state = {
        tags: [],
        suggestions: this.props.tags
    }

    setTagsValue = (props) => {

        let tagArray = []

        let current_tags = props.current_tags;

        if(current_tags && current_tags.length ) {
            current_tags.forEach(t => tagArray.push(t.name) )
        }

        this.setState({tags: tagArray})

    }

    handleChange = (tags) =>  {

        const oldTags = [...this.state.tags]

        // only run this if adding a tag to the array
        if(tags.length && oldTags.length < tags.length) {

            // only accept tag if it is not a duplicate
            if(oldTags && oldTags.includes(tags[tags.length -1])) return;

            // only accept the tag if it matches one of our tag names inside db collection
            if(!this.state.suggestions.find(s => s.name === tags[tags.length -1])) return;

        }

        this.setState({tags}, () => {

            if(this.props.onChange) {

                let compiledArray = [];

                tags.forEach(tag => {

                    const found_tag = this.props.tags.find(t => t.name === tag);
                    if(found_tag) compiledArray.push(found_tag)

                })

                this.props.onChange(compiledArray)
            }

        })
    }

    autocompleteRenderInput = ({addTag, ...props}) => {

        const handleOnChange = (e, {newValue, method}) => {
            if (method === 'enter') {
                e.preventDefault()
            } else {
                props.onChange(e)
            }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || ''

        let suggestions = this.state.suggestions.filter((state) => {
            return state.name.toLowerCase().includes(inputValue)
        })

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={(value) => true}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                inputProps={{...props, onChange: handleOnChange}}
                onSuggestionSelected={(e, {suggestion}) => { addTag(suggestion.name) }}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={() => {}}
            />
        )
    }

    onSave = () => {

        let compiledArray = [];

        this.state.tags.forEach(tag => {

            const found_tag = this.props.tags.find(t => t.name === tag);
            if(found_tag) compiledArray.push(found_tag)

        })

        this.props.onSave(compiledArray)

        this.setState({saved: true}, () => {
            setTimeout(() => { this.setState({saved: false}) }, 2000)
        })

    }

    componentDidMount = () => {

        this.setTagsValue(this.props)

    }

    componentWillReceiveProps = (nextProps) =>  {

        if(this.props.keyValue !== nextProps.keyValue) {
            this.setTagsValue(nextProps)
        }

    }

    render () {

        const { hideSaveButton, disabled } = this.props

        return  (

            <div>

                <TagsInput
                    renderInput={this.autocompleteRenderInput}
                    value={this.state.tags}
                    onChange={this.handleChange}
                    className="bootstrap-tagsinput"
                    tagProps={{ className: "tag badge badge-warning mr-1" }}
                />

               {!hideSaveButton ?  <hr className="my-3" /> : null}

                {!hideSaveButton ? this.state.saved ? (
                    <button disabled={true} className="btn btn-success"><i className="fas fa-check mr-2" /> Saved!</button>
                ) : (
                    <button disabled={disabled} onClick={this.onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save Tags</button>
                ) : null }

            </div>

        )

    }
}


const mapStateToProps = state => {

    return {
    	tags: state.tags.tags,
    };
};

TagsUpdater.propTypes = {

    current_tags: PropsTypes.array.isRequired,
    onSave: PropsTypes.func,
    onChange: PropsTypes.func,
    keyValue: PropsTypes.string,
    hideSaveButton: PropsTypes.bool,
    disabled: PropsTypes.bool,

}

export default connect(mapStateToProps, '')(TagsUpdater);
