/*
Documentation

This renders form inputs for a task with the type "appointment"

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import _case_workflow_tasks from '_functions/case_workflow_tasks';
import { toggleStandardLoader } from 'store/functions/system/system';
import * as privileges from '_functions/users/privileges'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class FormTypesAppointment extends Component {

    state = {
        editing_task: {
            minutes: this.props.editing_task.default_billable_minutes
        },
        appointment: {}
    };

    onFinishTask = async (appointment) => {

        const editing_task = this.state.editing_task;

        toggleStandardLoader(true)

        await _case_workflow_tasks.finish(this.state.editing_task._id,  {
            value: appointment,
            minutes: editing_task.minutes ? parseInt(editing_task.minutes) : editing_task.minutes,
            finished_by: this.props.viewing_user._id,
            user: this.props.viewing_user._id,
            case_workflow: this.props.case_workflow_id,
        })

        toggleStandardLoader(false)

        this.props.toggleModal();
        this.props.forceRefresh();
        _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes
            })

            this.setState({editing_task: task});

        }

    }

    render() {

        const editing_task = this.state.editing_task || {};
        const { contact } = this.props.case
        const _case = this.props.case

        if(editing_task.type !== 'appointment') { return <></> }

        if(!privileges.canModerateAppointments()) return (
            <div className="alert alert-danger">Your account does not give you access to create or update appointments</div>
        )

        return (

            <AppointmentsCrud
                contact_id={contact && contact._id ? contact._id : undefined}
                appointment_id="new"
                template_appointment={editing_task.template_appointment ? editing_task.template_appointment._id : null}
                title="Create Appointment"
                onSuccess={(appointment) => this.onFinishTask(appointment)}
                case_id={_case._id}
                isStepChange={true}
            />

        )

    }

}

FormTypesAppointment.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesAppointment);
