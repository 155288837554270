/*
Documentation

This is the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink, Badge } from "reactstrap";
import keys from 'keys'
import * as privileges from '_functions/users/privileges';


class NavbarBottom extends React.Component {

	state = {
        showMobileNav: false
    }

    toggleMobileNav = () => {

        const showMobileNav = this.state.showMobileNav

        if(showMobileNav) {
            document.body.classList.remove('noScroll')
        } else {
            document.body.classList.add('noScroll')
        }

        this.setState({showMobileNav: !showMobileNav})
    }

    closeMobileNav = () => {
        document.body.classList.remove('noScroll');
        this.setState({showMobileNav: false});
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.closeMobileNav)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.closeMobileNav)
    }

	render() {

        const { showMobileNav } = this.state

        const {  users, location } = this.props

    	return (

			<>

				<Navbar className="navbar-expand border-bottom navbar navbar-mobile d-block d-md-none "  >
					<Container>

						<Nav className="align-items-center mx-auto" navbar>
                            <NavLink
                                tag={Link}
                                to="/dashboard/home"
                                className="pl-0 "
                            >
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer', paddingLeft: 0}}>
                                    <img src={keys.LOGO} className="w-50 " alt={keys.COMPANY_NAME} />
								</NavItem>
							</NavLink>
						</Nav>

						<Nav className="align-items-center ml-auto ml-md-0" navbar>
                            <NavLink
                                tag={Link}
                                to="/defaultevent"
                                className="pr-0 "
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleMobileNav()
                                }}
                            >
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer', paddingLeft: 0, fontSize: '1.2rem'}}>
                                    <i className="fas fa-bars" />
								</NavItem>
							</NavLink>
						</Nav>

					</Container>
				</Navbar>

                {showMobileNav ? (
                    <div className="navbar-mobile-content d-block d-md-none ">

                        <ul>

                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    My Fairmax Law
                                </a>
                            </li>

                            {privileges.canViewAdminDashboard() ? (
                            <li className={location.includes('/admin') ? 'active' : ''} >
                                <a href={keys.ADMIN_URL} onClick={() => this.closeMobileNav()} >
                                    <i className="fas fa-user-secret" /> Admin Dashboard
                                </a>
                            </li>
                            ) : null }

                            <li className={location.includes('/dashboard/call_center') ? 'active' : ''} >
                                <Link to="/dashboard/call_center" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-headset" /> Call Center
                                </Link>
                            </li>

                           
                            {privileges.canViewCases() ? (
                            <li className={location.includes('/dashboard/cases/') ? 'active' : ''} >
                                <Link to="/dashboard/cases/all" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-gavel" /> Cases
                                </Link>
                            </li>
                            ) : null }

                            <li className={location.includes('/dashboard/contacts') ? 'active' : ''} >
                                <Link to="/dashboard/contacts/all" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-users" /> Contacts
                                </Link>
                            </li>

                            <li className={location.includes('/dashboard/calendar/') ? 'active' : ''} >
                                <Link to="/dashboard/calendar/me" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-calendar" /> Calendar
                                </Link>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.toggleBillingCenter();
                                    this.closeMobileNav();
                                }}>
                                    <i className="fas fa-dollar-sign" /> Billing
                                </a>
                            </li>

                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    Admin Settings
                                </a>
                            </li>

                            {privileges.canModerateStaff() ? (
                            <li className={location.includes('/dashboard/users/') ? 'active' : ''} >
                                <Link to="/dashboard/users/all" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-user" /> Staff
                                    <Badge color="success">{users.length}</Badge>

                                </Link>
                            </li>
                            ) : null}

                            {privileges.canModerateWorkflows() ? (
                            <li className={location.includes('/dashboard/workflows/') ? 'active' : ''} >
                                <Link to="/dashboard/workflows/all" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-route" /> Workflows
                                </Link>
                            </li>
                            ) : null}

                            {privileges.canModerateTemplateDocs() ? (
                            <li className={location.includes('/dashboard/template_docs/') ? 'active' : ''} >
                                <Link to="/dashboard/template_docs/all" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-file" /> Template Docs
                                </Link>
                            </li>
                            ) : null}


                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    My Account
                                </a>
                            </li>

                            <li className={location.includes('/dashboard/settings') ? 'active' : ''} >
                                <Link to="/dashboard/settings" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-cogs" /> Settings
                                </Link>
                            </li>
                            <li>
                                <a href={keys.AUTH_URL + '/logout'} >
                                <i className="ni ni-user-run" /> Logout
                                </a>
                            </li>

                        </ul>

                    </div>
                ) : null}

			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
  	};
};

export default connect(mapStateToProps, '')(NavbarBottom);
