import React, { useState } from 'react';
import { Card, CardHeader, CardFooter, CardTitle, FormGroup, Input, Row, Col } from 'reactstrap';
import States from 'components/markup/inputs/States';

import _array from '_functions/array';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';


const Integrations = ({contact, foundCase}) => {

    const [firstName, setFirstName] = useState(contact.given_name);
    const [lastName, setLastName] = useState(contact.family_name);
    const [dobDay, setDobDay] = useState('');
    const [dobMonth, setDobMonth] = useState('');
    const [dobYear, setDobYear] = useState('');
    const [ssn, setSsn] = useState('');
    
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const [errs, setErrs] = useState([]);

    const onOrder = async () => {

        const _errs = [];

        if(!ssn) _errs.push('ssn');
        if(!firstName) _errs.push('firstName');
        if(!lastName) _errs.push('lastName');
        if(!dobDay) _errs.push('dobDay');
        if(!dobMonth) _errs.push('dobMonth');
        if(!dobYear) _errs.push('dobYear');
        if(!street) _errs.push('street');
        if(!city) _errs.push('city');
        if(!state) _errs.push('state');
        if(!zip) _errs.push('zip');

        setErrs(_errs);

        if(_errs.length) return;

        const data = {
            case_id: foundCase._id,
            contact_id: contact._id,
            report: {
                ssn,
                firstName,
                lastName,
                dob: `${dobYear}-${dobMonth}-${dobDay}`,
                address: {
                    street,
                    city,
                    state,
                    zip
                }
            }
        }

        toggleStandardLoader(true);
        const order = await _array.download(data)
        toggleStandardLoader(false);
        return  console.log(order)
        if(!order.success) return  toggleAlertBS(true, order.message)

        if(order.success) {
            toggleAlertBS(false, `Credit report generated successfully, check case documents. If document does not appear refresh page.`)

        } else {
            toggleAlertBS(true, `Credit report could not be generated, please double check fields and try again.`)
        }


    }

    return (

        <Card>
            
            <CardHeader>
                <CardTitle className="mb-0">Credit Reports</CardTitle>
            </CardHeader>

            <CardHeader>
                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">First Name</label>
                            <Input 
                                type="text"
                                value={firstName}
                                invalid={errs.includes('firstName')}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Last Name</label>
                            <Input 
                                type="text"
                                value={lastName}
                                invalid={errs.includes('lastName')}

                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <label className="form-control-label">Social Security - 9 Digits</label>
                    <p className="text-sm mb-0"><i className="fas fa-exclamation-triangle text-warning font-weight-bold " /> Do Not Store This Within Intuidesk</p>
                    <Input 
                        maxLength="9"
                        type="text"
                        value={ssn}
                        invalid={errs.includes('ssn')}
                        onChange={(e) => setSsn(e.target.value)}
                        placeholder="555667777"
                    />
                </FormGroup>

                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">Dob - Month</label>
                            <Input 
                                maxLength="2"
                                type="text"
                                value={dobMonth}
                                invalid={errs.includes('dobMonth')}
                                onChange={(e) => setDobMonth(e.target.value)}
                                placeholder="MM"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">Dob - Day</label>
                            <Input 
                                maxLength="2"
                                type="text"
                                value={dobDay}
                                invalid={errs.includes('dobDay')}
                                onChange={(e) => setDobDay(e.target.value)}
                                placeholder="DD"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">Dob - Day</label>
                            <Input 
                                maxLength="4"
                                type="text"
                                value={dobYear}
                                invalid={errs.includes('dobYear')}
                                onChange={(e) => setDobYear(e.target.value)}
                                placeholder="YYYY"
                            />
                        </FormGroup>
                    </Col>


                </Row>

            </CardHeader>

            <CardHeader>

                <FormGroup>
                    <label className="form-control-label">Street</label>
                    <Input 
                        type="text"
                        value={street}
                        invalid={errs.includes('street')}
                        onChange={(e) => setStreet(e.target.value)}
                    />
                </FormGroup>

                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">City</label>
                            <Input 
                                type="text"
                                value={city}
                                invalid={errs.includes('city')}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">State</label>
                            <Input 
                                type="select"
                                value={state}
                                invalid={errs.includes('state')}
                                onChange={(e) => setState(e.target.value)}
                            >
                                <option value="false">Select State</option>
                                <States />
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <label className="form-control-label">Zip</label>
                            <Input 
                                maxLength="5"
                                type="text"
                                value={zip}
                                invalid={errs.includes('zip')}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>

            <CardFooter className="text-right">
                <button onClick={onOrder} className="btn btn-success">Order Credit Report</button>
            </CardFooter>

        </Card>

    )

}

export default Integrations;