/*
Documentation

This file renders a modal to update case details, name, chapter, etc

*/

import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { connect } from 'react-redux'
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import OfficeSearchInput from 'views/dashboard/pages/_components/OfficeSearchInput';
import LocationHearingSearchInput from 'views/dashboard/pages/_components/LocationHearingSearchInput';
import LocationCourtSearchInput from 'views/dashboard/pages/_components/LocationCourtSearchInput';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';

import _cases from '_functions/cases';
import renderName from 'utils/renderName'

class CaseOverviewModalUpdate extends Component {

    state = {
        newCase: {},
    };

    toggleModal = () => {
        this.setState({newCase: Object.assign({}, this.props.case)});
        this.props.toggleModal()
    }

    onAssignOffice = (office) => {

        const newCase = Object.assign({}, this.state.newCase)
        newCase.office = office;

        this.setState({newCase})

    }

    onAssignLocationHearing = (location_hearing) => {

        const newCase = Object.assign({}, this.state.newCase)
        newCase.location_hearing = location_hearing;

        this.setState({newCase})

    }

    onAssignLocationHearingTrustee = (location_hearing_trustee) => {

        const newCase = Object.assign({}, this.state.newCase)
        newCase.location_hearing_trustee = location_hearing_trustee;

        this.setState({newCase})

    }
    
    onAssignCaseManager = (case_manager) => {

        const newCase = Object.assign({}, this.state.newCase)
        newCase.case_manager = case_manager;

        this.setState({newCase})

    }

    onAssignLocationCourt = (location_court) => {

        const newCase = Object.assign({}, this.state.newCase)
        newCase.location_court = location_court;

        this.setState({newCase})

    }

    onSaveCase = () => {

        const { newCase } = this.state

        if(!newCase.name) return

        _cases.update(this.props.case._id, {
            name                        : newCase.name,
            case_number                 : newCase.case_number,
            office                      : newCase.office ? newCase.office._id : undefined,
            case_manager                : newCase.case_manager ? newCase.case_manager._id : null,
            location_court              : newCase.location_court ? newCase.location_court._id : undefined,
            location_hearing            : newCase.location_hearing ? newCase.location_hearing._id : undefined,
            location_hearing_trustee    : newCase.location_hearing_trustee ? newCase.location_hearing_trustee._id : undefined,
            chapter                     : newCase.chapter ? newCase.chapter : undefined,
        })

        this.toggleModal()

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            this.setState({newCase: Object.assign({}, nextProps.case)})
        }

    }

    render() {


        let { newCase } = this.state;
        const { showModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={this.toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Case Details</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">



                    <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Case Name"
                    />

                    <UserSearchInput
                        title="Case Manager"
                        value={newCase.case_manager && newCase.case_manager._id ? newCase.case_manager : newCase.case_manager ? this.props.users.find(u => u._id === newCase.case_manager) : {}}
                        titleFormatter={(selected) =>  selected ? 
                            <span>Selected Case Manager: <b className="text-success">{renderName(selected)}</b></span> 
                            : 'Select Case Manager'
                        }
                        onSelect={(case_manager) => this.onAssignCaseManager(case_manager)}
                    />

                    {newCase.case_manager ? (
                        <p onClick={() => {
                            this.setState({
                                newCase: {
                                    ...newCase,
                                    case_manager: null
                                }
                            })
                        }} className="text-sm mb-0 text-warning">Remove Case Manager</p>
                    ) : null}

                    <OfficeSearchInput
                        title="Assigned Office"
                        value={newCase.office ? newCase.office : {}}
                        onSelect={(office) => this.onAssignOffice(office)}
                    />

                    <LocationCourtSearchInput
                        title="Assigned Court"
                        value={newCase.location_court ? newCase.location_court : {}}
                        onSelect={(location_court) => this.onAssignLocationCourt(location_court)}
                    />

                    <LocationHearingSearchInput
                        title="Assigned Hearing Location"
                        value={newCase.location_hearing ? newCase.location_hearing : {}}
                        onSelect={(location_hearing) => this.onAssignLocationHearing(location_hearing)}
                    />

                    <ContactSearchInput
                        title="Assigned Trustee"
                        value={newCase.location_hearing_trustee ? newCase.location_hearing_trustee : {}}
                        titleFormatter={(selected) =>  selected ? 
                            <span>Selected Trustee: <b className="text-success">{renderName(selected)}</b></span> 
                            : 'Select Trustee'
                        }
                        onSelect={(location_hearing_trustee) => this.onAssignLocationHearingTrustee(location_hearing_trustee)}
                    />

                    <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="case_number"
                        title="Case Number"
                    />

                    {/* add in chapter here */}
                    <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="chapter"
                        title="Case Chapter"
                    >
                        <option value={null}></option>
                        <option value="Chapter 7 Full">Chapter 7 Upfront</option>
                        <option value="Chapter 7 Emergency">Chapter 7 Zero Down</option>
                        <option value="Chapter 13">Chapter 13</option>
                    </StandardFormGroup>



                </div>

                <div className="modal-footer">
                    <button onClick={this.onSaveCase}  className="btn btn-success">Save Case</button>
                </div>

            </Modal>

        )

    }

}

CaseOverviewModalUpdate.proptTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    case        : PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	viewing_user: state.auth.viewing_user,
    	device: state.device,
    };
};

export default connect(mapStateToProps, '')(CaseOverviewModalUpdate);
