import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TimeLog from './components/TimeLog';
import Calculations from './components/Calculations';

class Payments extends Component {

    state = {};

    render() {

        if(!this.props.case_workflow) { return <div></div> }

        const case_workflow  = Object.assign({}, this.props.case_workflow)

        return (

            <>

                <Calculations
                    case_workflow={case_workflow}
                    case={this.props.case}
                />

                <TimeLog
                    case_workflow={case_workflow}
                />

            </>

        )

    }

}

Payments.propTypes = {
    case_workflow: PropTypes.object.isRequired,
}

export default Payments
