import React from 'react';
import { connect } from 'react-redux';
import Dots from 'components/markup/loading/Dots'


const OnlineStatus = ({online}) => {

    if(online) return <></>;

    return (
        <div className="text-white bg-danger archk-online-status z-depth-3">
            <div className="archk-online-status-bg"></div>
            <span>
                <i className="fas fa-exclamation-triangle mr-2" /> 
                You are not connected to the internet, please check your internet connection or try to refresh your page.{' '}
                <b className="d-block font-weight-bold">Trying Connection<Dots /></b>
            </span>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        online: state.system.online,
    };
};

export default connect(mapStateToProps, '')(OnlineStatus);
