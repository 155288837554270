/*
Documentation

This page shows a list of all contacts
can search by name, email, phone, or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider  from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card,  CardHeader, Container, CardTitle, Input } from "reactstrap";
import _wikis from '_functions/wikis';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';


class ContactsAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "subject",
            text: "Subject",
            formatter: (cell, row) => (
                <div>
                    <Link className="text-capitalize" to={`/dashboard/help/wikis/${row._id}`}>
                        {row.subject}
                    </Link>

                </div>
            ),
        },
        {
            dataField: "x",
            text: "View",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/help/wikis/${row._id}`}>
                            <i className="fas fa-edit text-success mr-2" />
                        </Link>
                    </div>
                )
            }
        },
    ]

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {
            this.queryTableDataThrottled(searchText, true)
        } else {
            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })
        }

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const skip = (page - 1) * this.state.sizePerPage;

        const wikis = await _wikis.search({
            searchString          : searchText,
            skip            : skip,
            limit           : this.state.sizePerPage,
        })
        this.setState({ data: wikis.data.documents, total_documents: wikis.total_documents, loading: false })

    }

    componentDidMount = async () => {

        const wikis = await _wikis.search({
            searchString          : '',
            skip            : 0,
            limit           : this.state.sizePerPage,
        })
        this.setState({data: wikis.data.documents, total_documents: wikis.total_documents})

    }

    render() {

        const { data, loading } = this.state;
        const { cardTitle } = this.props;
        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Wikis (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Wikis" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> Help</span>}
                    breadcrumb_1="Wikis"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">
                            {cardTitle ? cardTitle : 'All Wikis'}
                        </CardTitle>
                    </CardHeader>

                    {data ? (
                        <ToolkitProvider
                            data={data.length ? data : []}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                        <label>
                                            Search:
                                            <Input
                                                className="form-control-sm"
                                                value={this.state.searchText}
                                                placeholder="Subject"
                                                style={{width: 190}}
                                                onChange={(e) => {
                                                    this.setState({searchText: e.target.value})
                                                    this.queryTableDataThrottled(e.target.value, true)
                                                }}
                                            />
                                                {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                        </label>
                                    </div>

                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: true,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label>
                                                            Show{" "}
                                                            {
                                                            <select
                                                                value={currSizePerPage}
                                                                name="datatable-basic_length"
                                                                aria-controls="datatable-basic"
                                                                className="form-control form-control-sm"
                                                                onChange={e => onSizePerPageChange(e.target.value)}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            }{" "}
                                                            entries.
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}
                                            rowClasses={(row, rowIndex) => {
                                                if(row.blacklisted) {
                                                    return 'bg-secondary' 
                                                }
                                                return '';
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default ContactsAll
