/*
Documentation

this file renders a text in the CenterContent component

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import reactHTMLParser from 'react-html-parser';

import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import renderName from 'utils/renderName'
import SendText from 'components/system/SendText';

import { Link } from 'react-router-dom';

class MessagingCenterHandleText extends Component {

    setEmailHTML = (html) => {

        return html.replace(/<html>/, '<div>').replace(/<\/html>/, '</div>')
            .replace(/<head>/, '<div>').replace(/<\/head>/, '</div>')
            .replace(/<body>/, '<div>').replace(/<\/body>/, '</div>')
            .replace(/<body dir="ltr">/, '<div>').replace(/<\/body>/, '</div>')
            .replace(/<#>/, '') // not sure why this fucks things up but it can
    }

    render() {

        const { content, show, toggleRead, onChangeShow, onSuccess } = this.props;

        if(!content) return (
            <div className="center-content text-center d-flex">
                <div className="align-self-center w-100">
                <div className="gradient-box " style={{width: 350, margin: 'auto'}}>
                    <h2>Select An Email Or Text To View</h2>
                </div>
                </div>
            </div>
        )

        return (

            <Card className="card-no-show">

                <CardHeader>
                    <h3 className="mb-0">
                        <Link to={`/dashboard/contacts/view/${content.contact && content.contact._id ? content.contact._id : content.contact}`}>
                            <i className="fas fa-user mr-2" />
                            From: {' '}
                            {content.contact && content.contact._id ? <span className="text-capitalize">{renderName(content.contact)} - </span> : null}
                            {content.from}
                        </Link>
                    </h3>
                    <h3 className="mb-0 font-weight-normal">{content.subject}</h3>
                </CardHeader>

                <CardBody>{reactHTMLParser(this.setEmailHTML(content.value))}</CardBody>

                {!show ? (
                    <CardFooter className="text-right">
                        <button onClick={() => toggleRead('text')} className="btn btn-neutral">
                            {content.read ? (
                                <><i className="fas fa-undo-alt mr-2" />Mark Unread</>
                            ) : (
                                <><i className="fas fa-check mr-2" />Mark Read</>
                            )}
                        </button>
                        <button onClick={() => onChangeShow('reply')} className="btn btn-success"><i className="fas fa-reply-all mr-2" /> Reply</button>
                    </CardFooter>
                ) : show === 'reply' ? (

                <>

                    <div className="text-right mb-3">
                        <button onClick={() => onChangeShow(null)} className="btn btn-warning"><i className="fas fa-arrow-left" /> All Options</button>
                    </div>

                    <SendText
                        onSuccess={onSuccess}
                        contact={content.contact ? content.contact._id ? content.contact._id : content.contact : undefined}
                        isReply={true}
                    />

                </>

            ) : null}

            </Card>

        )

    }

}

MessagingCenterHandleText.propTypes = {
    onSuccess       : PropTypes.func.isRequired,
    toggleRead      : PropTypes.func.isRequired,
    onChangeShow    : PropTypes.func.isRequired,
    content         : PropTypes.object,
    show            : PropTypes.string
}

export default MessagingCenterHandleText
