import React from 'react';

class ModalSendIsInvalid extends React.Component {

    render() {

        return (

            <div className="modal-body">
                <p className="text-sm mb-0">
                    <i className="fas fa-exclamation-triangle text-danger mr-2 " />
                    Before sending this document you must have at least 1 signature field on the PDF and all text fields must have a question associated to them.
                    You must also name the document to be sent. 
                </p>

            </div>

        );
    }
}

export default ModalSendIsInvalid
