import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'reactstrap'

class SendTextBody extends Component {

    render() {

        const { body, onBodyChange } = this.props

        return (

            <Input
                id="archk-send-text-body-input"
                type="textarea"
                value={body || ''}
                style={{resize: 'none'}}
                placeholder="Text body..."
                onChange={(e) => onBodyChange(e.target.value)}
            />

        )

    }

}

SendTextBody.propTypes = {
    body            : PropTypes.string,
    onBodyChange    : PropTypes.func.isRequired,
}

export default SendTextBody
