/*
Documentation

Renders the preview of all texts in the right hand sidebar

*/

import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import AvatarImage from 'components/functional/images/AvatarImage';

class RenderText extends Component {

    state = {
        startOfDay: moment.unix(Math.floor(new Date() / 1000)).startOf('day').valueOf() / 1000,
    }

    renderDate = (date) =>  moment.unix(date).format(date >= this.state.startOfDay ? 'h:mm A' : 'MM/DD/YYYY')

    render() {

        const { texts, setContent, loading, activeText } = this.props

        return (

            <ul className="pl-0 mb-0">
                {texts && texts.length ? texts.map(text => (
                    <li
                        onClick={() => setContent(text, 'text')}
                        key={text._id}
                        className={
                            text._id === activeText ? 'cursor-pointer list-style-none bg-warning text-white' :
                            text.read ? 'cursor-pointer list-style-none bg-secondary text-muted' :
                            'cursor-pointer list-style-none'
                        }
                    >

                        <div className="from">
                            <Row>
                                <Col lg={7}>
                                {text.contact && (text.contact.given_name || text.contact.family_name) ?
                                    <span className="text-capitalize">
                                        {renderName(text.contact).length > 18 ? renderName(text.contact).slice(0, 18) + '...' : renderName(text.contact)}

                                    </span> :
                                    text.from && text.from.length > 18 ? text.from.slice(0, 18) + '...' : text.from
                                }
                                </Col>
                                <Col lg={5} className="text-right date">
                                    {this.renderDate(text.created_at)}
                                </Col>
                            </Row>
                        </div>

                        <div className={ text._id === activeText ? 'body text-white' : "body text-muted"}>
                            {(text.value && text.value.length > 50 ? text.value.slice(0, 50) + '...' : text.value)}
                        </div>

                        {!text.assigned_to ? (
                            <>
                                <i className={text._id === activeText ? "fas fa-exclamation-triangle r-2 text-white" : "fas fa-exclamation-triangle r-2 text-warning"} /> No Assigned User
                            </>
                        ) : (
                            <div className="text-capitalize">
                                <AvatarImage style={{width: 25, height: 25}} src={text.assigned_to.avatar} />
                                {' '}
                                <span style={{position: 'relative', top: 2}}> {renderName(text.assigned_to)}</span>
                            </div>
                        )}

                    </li>
                )) : (
                    !loading ? <li><i className="fas fa-check mr-2 text-success" />There are not texts to view with the current filters.</li> : null
                )}

                {loading ? <Circle />  : null}
            </ul>

        )

    }

}

RenderText.propTypes = {
    setContent    : PropTypes.func.isRequired,
    texts         : PropTypes.array,
    loading       : PropTypes.bool,
    activeText    : PropTypes.string,
}

export default RenderText
