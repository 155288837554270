
import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import moment from 'moment';

import _collections from '_functions/collections'

const ModalUpdateCollections = ({showModal, toggleModal, collection}) => {

    const [date, setDate] = useState();
    const [err, setErr] = useState(false);

    const onSave = useCallback(async () => {

        if(!date) return setErr(true)

        setErr(false);


        // 12pm on date selected
        const next_call = parseInt(moment(date).format('X')) + 43200;

        toggleStandardLoader(true);
        const updated = await _collections.setNextCall(collection._id, {next_call});
        toggleStandardLoader(false);

        if(updated.data) {
            toggleAlertBS(false, `Next Call Date Updated Successfully.`)
            toggleModal();
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)

        }

    }, [date, collection._id])

    useEffect(() => {
        if(!showModal) setDate(null)
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Collections Call</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className="form-control-label">Next Call Date*</label>
                    <Input 
                        type="date"
                        invalid={err ? true : false}
                        onChange={e => setDate(e.target.value)}
                    />
                </FormGroup>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => onSave()}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ModalUpdateCollections.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalUpdateCollections
