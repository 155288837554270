/*
Documentation

this page holds all the routes for the dashboard layout

*/

import TrustHistory from '../pages/TrustHistory';
import Payments from '../pages/Payments';

export default () => {

    return[

        {
            path: "/trust/history/:case",
            component: TrustHistory
        },
        {
            path: "/payments/history/:case",
            component: Payments
        },

    ]

}
