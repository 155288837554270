/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system                   from './reducers/system/system';
import auth                     from './reducers/auth/auth';
import users                    from './reducers/users/users';
import tags                     from './reducers/tags/tags';
import case_templates           from './reducers/cases/case_templates';
import case_alert_templates     from './reducers/cases/case_alert_templates';
import device                   from './reducers/device/device';
import divisions                from './reducers/divisions/divisions';
import email_parsers            from './reducers/email_parsers/email_parsers';
import groupings                from './reducers/groupings/groupings';
import workflows                from './reducers/workflows/workflows';
import forms                    from './reducers/forms/forms';
import location_hearings        from './reducers/locations/location_hearings';
import location_courts          from './reducers/locations/location_courts';
import lead_sources             from './reducers/lead_sources/lead_sources';

import analytics_data_types     from './reducers/analytics_data_types/analytics_data_types';
import call_center              from './reducers/call_center/call_center';
import objection_numbers        from './reducers/objection_numbers/objection_numbers';
import missing_docs             from './reducers/missing_docs/missing_docs';

import automations              from './reducers/automations/automations';
import template_emails          from './reducers/templates/emails';
import template_appointments    from './reducers/templates/appointments';
import template_texts           from './reducers/templates/texts';
import template_docs            from './reducers/templates/docs';
import template_word_docs       from './reducers/templates/word_docs';
import companies                from './reducers/companies/companies';
import offices                  from './reducers/offices/offices';
import user_statuses            from './reducers/user_statuses/user_statuses';
import phone_numbers            from './reducers/phone_numbers/phone_numbers';
import questionnaires           from './reducers/questionnaires/questionnaires';
import email_senders            from './reducers/email_senders/email_senders';

//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    auth,
    users,
    system,
    tags,
    location_courts,
    lead_sources,
    case_templates,
    case_alert_templates,
    device,
    divisions,
    email_parsers,
    groupings,
    workflows,
    forms,
    location_hearings,
    template_appointments,
    analytics_data_types,
    call_center,
    objection_numbers,
    template_emails,
    template_texts,
    template_docs,
    template_word_docs,
    automations,
    missing_docs,
    companies,
    offices,
    user_statuses,
    phone_numbers,
    questionnaires,
    email_senders
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
