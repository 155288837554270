import PropTypes from 'prop-types';
import React from "react";

import renderUpload from '../_functions/renderUpload';
import onCanvasDrag from '../_functions/onCanvasDrag';

import Circle from 'components/markup/loading/Circle';

import Markers from './Markers'

class Canvas extends React.Component {

    state = {
        loaded: false,
        pdfDoc: null,
    }

    initListeners = (attempts = 0) => {

        const pages = document.querySelectorAll('[data-pdf-page="true"]')
    
        // once we load, try to set listeners for 10 seconds to click on the pdf, if this fails we 
        // alert the user to reload their page and try again. this can be made to look better later
        if(pages.length === 0 && attempts < 100) {
            return setTimeout(() => this.initListeners(attempts + 1), 100)
        } else if (!pages) {
            alert('Something went wrong loading your pdf, please refresh your page and try again.')
        }

        pages.forEach((page, i) => {

            const pageNumber = i + 1;
            onCanvasDrag(page, (coords) => this.props.addMarker(coords, pageNumber))

        })

    }

    componentWillUnmount = () => {
        try { this.state.pdfDoc.destroy() } catch(e) { };
    }

    componentDidMount = () => {

        renderUpload(this.props.pdfData, (pdfDoc) => this.setState({loaded: true, pdfDoc}, this.initListeners))

    }

    render() {

        const { loaded } = this.state;
        const { markers, setMarkerType, removeMarker, updateMarkerName } = this.props

        return (

            <div>

                {!loaded && <div className="text-center"><p className="text-sm">Loading document.</p><Circle /></div>}

                <div id="canvas-wrapper" className="position-relative" style={{opacity: loaded ? 1 : 0}}>
                    <div id="pdf-canvas" className="position-relative">

                        <Markers 
                            markers={markers}
                            setMarkerType={setMarkerType}
                            removeMarker={removeMarker}
                            updateMarkerName={updateMarkerName}
                        />
                 
                    </div>
                </div>

            </div>

        );
    }
}

Canvas.propTypes = {
    pdfData             : PropTypes.object,
    markers             : PropTypes.array.isRequired,
    setMarkerType       : PropTypes.func.isRequired,
    removeMarker        : PropTypes.func.isRequired,
    updateMarkerName    : PropTypes.func.isRequired,
    addMarker           : PropTypes.func.isRequired,
}

export default Canvas
