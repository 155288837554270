/*
Documentation

this file renders the card that lets a user actually move a step

*/

import { connect } from 'react-redux'
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Progress, CardTitle, FormGroup, Modal, Input} from "reactstrap";
import PropTypes from 'prop-types';
import { canUpdateCases } from '_functions/users/privileges'
import ReactSelect from 'components/functional/inputs/ReactSelect';
import { Redirect } from 'react-router-dom'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import _case_workflows from '_functions/case_workflows';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class StepChange extends Component {

    state = {
        movingToStep: {},
        showModal: false,
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal, hideMissingDocsMessage: 'no'})

    onStepChange = async () => {

        const { movingToStep } = this.state

        toggleStandardLoader(true)

        const moved = await _case_workflows.moveStep({
            case: this.props.case._id,
            user: this.props.viewing_user._id,
            next_step: movingToStep._id === 'finish' ? undefined : movingToStep._id,
            finished: movingToStep._id === 'finish' ? true : false,
            hideMissingDocsMessage: this.state.hideMissingDocsMessage === 'yes' ? true : false
        }, true)


        toggleStandardLoader(false)

        if(!moved.success) {
            return toggleAlertBS('info', 'Please Refresh Your Page To Move This Step.')
        }


        if(movingToStep && movingToStep._id === 'finish') {

            this.setState({shouldRedirect: '/dashboard/cases/all'})
            toggleAlertBS(false, 'This case has been successfully marked as finished.')

        } else {

            this.props.toggleIsMoving()
            this.props.forceRefresh()
            this.setState({movingToStep: {}})

        }

        _analytics.events.create({event: ANALYTIC_EVENTS.STEPS_MOVED});

    }

    onMoveStep = () => {

        if(this.state.movingToStep && this.state.movingToStep._id) {
            this.setState({movingToStep: this.state.movingToStep})
            this.props.toggleIsMoving(this.state.movingToStep)
        }

    }

    componentDidMount = () => {
        const { clickedStep } = this.props;

        if(clickedStep) {

            if(this.props.case_workflow.current_step_status && this.props.case_workflow.current_step_status.can_move_to_steps.find(s => s._id === clickedStep._id)) {
                this.setState({movingToStep: clickedStep, send_missing_docs_message: clickedStep.send_missing_docs_message}, () => {

                    this.onMoveStep()
    
                })
            }

        }
    }

    render() {

        if(!this.props.case_workflow.current_step_status) return <div />
        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { step_to_show, isMoving, hasMovingTasksOpen, toggleIsMoving, case_workflow } = this.props
        const { can_move_to_steps, totalSteps, totalStepsPercent }   = case_workflow.current_step_status
        const { movingToStep, showModal, send_missing_docs_message, hideMissingDocsMessage} = this.state

        const not_current_step =  step_to_show !== this.props.case.current_step

        const canUpdate = canUpdateCases()

        const options = can_move_to_steps.map(step => {
            return {value: step, label: step.name, send_missing_docs_message: step.send_missing_docs_message}
        })

        return (

            <>

            {isMoving && movingToStep && movingToStep.name ? <div className="alert alert-success text-secondary">Moving To Step: <b className="text-white">{movingToStep.name}</b></div> : null}

            {not_current_step ? (
                <div className="alert alert-warning">While you may view a step that this case is not currently on you may not update any tasks of that step.</div>
            ) : (
                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">Step Progress</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <div className="progress-wrapper pt-0">

                            <div className="progress-info">
                                <div className="progress-label">
                                    <span>Required Tasks ({totalSteps})</span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{totalStepsPercent}%</span>
                                </div>
                            </div>

                            <Progress max="100" value={totalStepsPercent} className={totalStepsPercent === 100 ? 'progress-gradient finished' : 'progress-gradient'} />

                        </div>

                        {can_move_to_steps && can_move_to_steps.length && !isMoving ? (

                        <FormGroup>
                            <label className="form-control-label">
                                <i className="fas fa-check text-success mr-2" />
                                This case is ready to move steps, select the next step below:
                            </label>
                            <ReactSelect
                                placeholder=""
                                onChange={values => this.setState({movingToStep: values.value, send_missing_docs_message: values.send_missing_docs_message})}
                                options={options}
                            />
                        </FormGroup>

                        ) : null}



                    </CardBody>

                    {can_move_to_steps && can_move_to_steps.length ? (
                        <CardFooter className="text-right" >
                            {!isMoving ? (
                                    <button
                                        disabled={!canUpdate}
                                        onClick={canUpdate ? this.onMoveStep : null}
                                        className="btn btn-success btn-s"
                                    >
                                        Move To New Step
                                    </button>
                            ) : !hasMovingTasksOpen ? (

                                <>
                                    <p className="font-weight-bold">
                                        <i className="fas fa-check-double mr-2 text-success font-weight-bold mb-0" />
                                        This step is ready to be moved. Confirm the step change below.
                                    </p>

                                    <button onClick={toggleIsMoving} className="btn btn-outline-danger btn-s">
                                        <i className="fas fa-arrow-left mr-2" /> Cancel Move
                                    </button>

                                    <button
                                        onClick={send_missing_docs_message === 'yes' ? this.toggleModal : this.onStepChange}
                                        className="btn btn-success"
                                    >
                                        <i className="fas fa-save mr-2" /> Confirm Move
                                    </button>
                                </>

                            ) : (

                                <button onClick={toggleIsMoving} className="btn btn-outline-danger btn-s">
                                    <i className="fas fa-arrow-left mr-2" /> Cancel Move
                                </button>
                            )}
                        </CardFooter>
                    ) : null}

                    <Modal
                        className="modal-dialog-centered"
                        isOpen={showModal}
                        toggle={this.toggleModal}
                        size="md"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reminder Email</h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleModal}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <FormGroup>
                                <label className="form-control-label">
                                    Moving to the next step will send a reminder email about missing documents to the client. If this should not happen specify so below
                                </label>
                                <Input
                                    type="select"
                                    value={hideMissingDocsMessage}
                                    onChange={(e) => this.setState({hideMissingDocsMessage: e.target.value})}
                                >
                                    <option value="no">Send Reminder Email</option>
                                    <option value="yes">Do Not Send Reminder Email</option>
                                </Input>

                            </FormGroup>
                        </div>

                        <div className="modal-footer">
                            <button onClick={this.toggleModal} className="btn btn-outline-warning">Cancel</button>
                            <button
                                onClick={() => {
                                    this.toggleModal()
                                    this.onStepChange()
                                }}
                                className="btn btn-success"
                            >
                                Confirm Move
                            </button>
                        </div>

                    </Modal>

                </Card>
            )}

            </>

        )

    }

}

StepChange.propTypes = {
    case                  : PropTypes.object.isRequired,
    case_workflow         : PropTypes.object.isRequired,
    toggleIsMoving        : PropTypes.func.isRequired,
    hasMovingTasksOpen    : PropTypes.number.isRequired,
    isMoving              : PropTypes.bool.isRequired,

    // this may be empty on initial case creation where we are not on a step
    step_to_show          : PropTypes.string,

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(StepChange);
