/*
Documentation

Note that daystartfriendly and daystart are used for easy viewing in the console
no js event or css classes are attached and are therefore only used for testing

*/

import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CalendarSidebar extends Component {

    // this function gets the start of the previous and next months based on the passed in base date
    getSisterMonthDates = () => {

        const { sidebar_day_to_show } = this.props

        const startOfMonth = parseInt( moment.unix(sidebar_day_to_show).startOf('month').format('X'))
        const endOfMonth = parseInt( moment.unix(sidebar_day_to_show).endOf('month').format('X'))
        const startOfPrevMonth = moment.unix(startOfMonth - 1).startOf('month').format('X')
        const startOfNextMonth = moment.unix(endOfMonth + 1).startOf('month').format('X')

        return { startOfPrevMonth, startOfNextMonth, }

    }

    // this function renders the correct days in the top left UI, allows to click them and set the calendar date
    renderDates = () => {

        const { sidebar_day_to_show, setTimeframe, day_to_show } = this.props;

        const startOfMonth  = moment.unix(sidebar_day_to_show).startOf('month')
        const endOfMonth    = moment.unix(sidebar_day_to_show).endOf('month')
        const daysInMonth   = startOfMonth.daysInMonth()

        // 0 is sunday, 6 is saturday
        const startDayOfMonth   = startOfMonth.day()
        const endDayOfMonth     = endOfMonth.day()

        let markup = []

        let currentMonthsDaysRendered   = 0
        let prevMonthsDaysRendered      = 0
        let nextMonthsDaysRendered      = 0

        // get previous month days to show in gray
        while(prevMonthsDaysRendered < startDayOfMonth) {

            const m = moment.unix(parseInt(startOfMonth.format('X')) - ((startDayOfMonth - prevMonthsDaysRendered) ) * 86400 )
            const time_unix = m.format('X')

            markup.push((
                <span
                    onClick={() => setTimeframe(time_unix)}
                    daystartfriendly={m.format('MMM, Do YYYY h:mm A')}
                    daystart={time_unix}
                    key={time_unix}
                    className="cell other-month"
                >
                    {m.format('D')}
                </span>
            ))

            prevMonthsDaysRendered++

        }

        // get current month days to show as clickable
        while(currentMonthsDaysRendered < daysInMonth) {

            const m = moment.unix(parseInt(startOfMonth.format('X'))).add(currentMonthsDaysRendered, 'days')
            const time_unix = parseInt(m.format('X'))

            const startOfToday = parseInt(moment.unix(Math.floor(new Date() / 1000)).startOf('day').format('X'))

            markup.push((
                <span
                    onClick={() => setTimeframe(time_unix)}
                    daystartfriendly={m.format('MMM, Do YYYY h:mm A')}
                    daystart={time_unix}
                    key={time_unix}
                    className={
                        time_unix === startOfToday ? 'cell today' :
                        time_unix === startOfToday - 3600 ? 'cell today' : // fix for daylight savings time
                        day_to_show === time_unix ? 'cell selected' :
                        'cell'
                    }
                >
                    {currentMonthsDaysRendered + 1}
                </span>
            ))

            currentMonthsDaysRendered++

        }

        // get next month days to show in gray
        if(endDayOfMonth !== 6) {

            while(nextMonthsDaysRendered < (6 - endDayOfMonth)) {

                const m = moment.unix(parseInt(endOfMonth.unix('X')) + (nextMonthsDaysRendered * 86400 ) + 1)
                const time_unix = m.format('X')

                markup.push(
                    <span
                        onClick={() => setTimeframe(time_unix)}
                        daystartfriendly={m.format('MMM, Do YYYY h:mm A')}
                        daystart={time_unix}
                        key={time_unix}
                        className="cell other-month"
                    >
                        {m.format('D')    }
                    </span>
                )

                nextMonthsDaysRendered++

            }

        }

        return markup

    }

    render() {

        const { sidebar_day_to_show, setSidebarTimeframe, renderSidebarCalendars, renderSidebarSearch, renderSidebarAdditions } = this.props
        const markup = this.renderDates()

        const sisterDates = this.getSisterMonthDates()

        return (

            <div className="sidebar">

                <div className="month-year">
                    <span className="title">{moment.unix(sidebar_day_to_show).format('MMMM YYYY')} </span>
                    <span className="navigation">
                        <span onClick={() => setSidebarTimeframe(sisterDates.startOfPrevMonth)} className="back"><i className="fas fa-angle-left" /></span>
                        <span onClick={() => setSidebarTimeframe(sisterDates.startOfNextMonth)} className="next"><i className="fas fa-angle-right" /></span>
                    </span>
                </div>

                <div className="dates">

                    <span className="header cell">S</span>
                    <span className="header cell">M</span>
                    <span className="header cell">T</span>
                    <span className="header cell">W</span>
                    <span className="header cell">T</span>
                    <span className="header cell">F</span>
                    <span className="header cell">S</span>

                    {markup}

                </div>

                {renderSidebarAdditions ? (
                    <> <hr className="my-3" /> {renderSidebarAdditions()} </>
                ) : null}

                {renderSidebarSearch ? (
                    <> <hr className="my-3" /> {renderSidebarSearch()} </>
                ) : null}

                {renderSidebarCalendars ? (
                    <> <hr className="my-3" /> {renderSidebarCalendars()} </>
                ) : null}

            </div>

        )

    }

}

CalendarSidebar.propTypes = {

    // required
    sidebar_day_to_show       : PropTypes.number.isRequired,
    day_to_show               : PropTypes.number.isRequired,
    setTimeframe              : PropTypes.func.isRequired,
    setSidebarTimeframe       : PropTypes.func.isRequired,

    // optional
    renderSidebarCalendars    : PropTypes.func,
    renderSidebarSearch       : PropTypes.func,

}

export default CalendarSidebar
