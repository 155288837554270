import apiWorker from '../apiWorker'

export default {

        // when manually updating a contact do not send any requests to update the call center or send to alert communication
        update:            (contact_id, data)      =>  apiWorker('patch',  `/api/v1/core/contacts/update/${contact_id}`, {...data, HIDE_SHOW_NOTIFICATION: true}),
        create:            (data)                  =>  apiWorker('post',   `/api/v1/core/contacts/create`, data),
        onHold:            ()                      =>  apiWorker('get', `/api/v1/core/contacts/onHold`),
        findById:          (contact_id, hideError) =>  apiWorker('get', `/api/v1/core/contacts/find/${contact_id}`, null, hideError),
        find:              (query)                 =>  apiWorker('get', `/api/v1/core/contacts/find${query}`),
        findByEmail:       (email)                 =>  apiWorker('get', `/api/v1/core/contacts/findByEmail/${email}`),
        findByPhone:       (phone)                 =>  apiWorker('get', `/api/v1/core/contacts/findByPhone/${phone}`),
        search:            (data)                  =>  apiWorker('post', `/api/v1/core/contacts/search`, data),
        searchMessenger:   (query)                 =>  apiWorker('get', `/api/v1/core/contacts/searchMessenger${query}`),
        duplicate:         (data)                  =>  apiWorker('post', `/api/v1/core/contacts/duplicate`, data),
        feed:              (query)                 =>  apiWorker('get', `/api/v1/core/contacts/feed${query}`),
        mergeFile:         (data)                  =>  apiWorker('post', `/api/v1/core/contacts/mergeFile`, data),
        
        century:         (_id)                  =>  apiWorker('post', `/api/v1/integrations/century/${_id}`),

}
