/*
Documentation

this component renders the markup needed to associate a case to a client
It either allows a user to search existing contacts or create a new one

creating a new one uses the same component as in Contacts/Create.js


*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ContactsCrud from '../../../contacts/components/ContactsCrud';
import ContactSearch from '../../../_components/ContactSearch';
import PropTypes from 'prop-types'

class CasesCreate extends React.Component {

    state = {
        association: null
    }

    render() {

        const { association } = this.state;
        const { selected_contact } = this.props;

        if(!association) {

            return (

                <div>

                    <h2>I want to:</h2>

                    <Row>

                        {selected_contact && selected_contact._id ? (
                            <Col md={4}>
                                <Card
                                    onClick={() => this.props.onChangeStep(3)}
                                    className="bg-gradient-primary cursor-pointer py-1"
                                >
                                    <CardBody className="text-center">
                                        <i className=" text-white fas fa-check display-1" />
                                        <h4 className="text-white mb-0">Use Selected Contact</h4>
                                        <h4 className="text-white font-weight-normal text-capitalize">
                                            {selected_contact.given_name} {selected_contact.family_name}
                                        </h4>
                                    </CardBody>
                                </Card>
                            </Col>
                        ) : null}

                        <Col md={4}>
                            <Card
                                onClick={() => this.setState({association: 'search'})}
                                className="bg-gradient-success cursor-pointer py-3"
                            >
                                <CardBody className="text-center">
                                    <i className="text-white fas fa-users display-1" />
                                    <h4 className="text-white">Search For A Contact</h4>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card
                                className="bg-gradient-warning cursor-pointer py-3"
                                onClick={() => this.setState({association: 'new'})}
                            >
                                <CardBody className="text-center">
                                    <i className="text-white fas fa-plus display-1" />
                                    <h4 className="text-white">Add A New Contact</h4>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                </div>

            )

        }

        if(association === 'new') {

            return (

                <Card>

                    <CardHeader>
                        <Row>

                            <Col xs={6}>
                                <h3>Add A New Contact</h3>
                            </Col>

                            <Col xs={6} className="text-right">
                                <button
                                    onClick={() => this.setState({association: null})}
                                    className="btn btn-warning"
                                >
                                    <i className="fas fa-arrow-left mr-2" />
                                    Back
                                </button>
                            </Col>

                        </Row>
                    </CardHeader>

                    <CardBody>
                        <ContactsCrud onCreate={(values) => this.props.onSelectContact(values) } />
                    </CardBody>

                </Card>

            )

        } else {

            return (

                <Card>

                    <CardHeader>
                        <Row>

                            <Col xs={6}>
                                <CardTitle>Find A Contact</CardTitle>
                            </Col>

                            <Col xs={6} className="text-right">
                                <button
                                    onClick={() => this.setState({association: null})}
                                    className="btn btn-warning"
                                >
                                    <i className="fas fa-arrow-left mr-2" />
                                    Back
                                </button>
                            </Col>

                        </Row>
                    </CardHeader>

                    <ContactSearch onSelect={(contact) => this.props.onSelectContact(contact)} />

                </Card>

            )

        }

    }
}

CasesCreate.propTypes = {
    selected_contact    : PropTypes.object.isRequired,
    onSelectContact     : PropTypes.func.isRequired,
    onChangeStep        : PropTypes.func.isRequired,
}

export default CasesCreate
