/*
Documentation

renders the left hand sidebar when a user is not on a call

*/

import PropTypes from 'prop-types';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { Component } from 'react';
import _contact_notes from '_functions/contact_notes';
import ModalStartAppointment from '../modals/StartAppointment';
import { connect } from 'react-redux'
import AvatarImage from 'components/functional/images/AvatarImage';

class SidebarNotOnCall extends Component {


    state = {
        contact_notes: null,
        selected_appointment: {},
        refreshKey: Math.floor(new Date() / 1000),
        showModalStart: false
    };

    refreshKeyInterval;

    setRefreshKeyInterval = () => {
        // every 5  minutes refresh the keys to rerender new timestamps
        this.refreshKeyInterval = setInterval(() => {
            this.setState({refreshKey: Math.floor(new Date() / 1000)})
        }, 300000)
    }

    stopRefreshInterval = () => clearInterval(this.refreshKeyInterval)

    toggleModalStart = () => {
        this.setState({showModalStart: !this.state.showModalStart})

        if(this.state.showModalStart) {
            this.setState({contact_notes: null})
        }

    }

    // a is an appointment object
    setAppointment = (a) => {

        this.fetchContactNotes(a.contact._id)

        this.setState({
            showModalStart: true,
            selected_appointment: a
        })

    }

    // query db to grab contacts notes
    fetchContactNotes = async (contact_id) => {

        const notes = await _contact_notes.findByContact(contact_id)

        if(notes.success) {
            this.setState({contact_notes: notes.data ? notes.data : []})
        }

    }

    splitAppointments = () => {

        let overdue = [];
        let upcoming = [];

        const now = Math.floor(new Date() / 1000)

        this.props.appointments.forEach(a => {

            if(a.date < (now - 1800)) {
                overdue.push(a)
            } else {
                upcoming.push(a)
            }

        })

        return { upcoming, overdue }

    }

    componentDidMount = () => {

        this.setRefreshKeyInterval()

    }

    componentWillUnmount = () => {

        this.stopRefreshInterval()

    }

    render() {

        const { appointments, viewing_user, canTakeCalls, setOnCall } = this.props;
        const { contact_notes, selected_appointment, showModalStart} = this.state;

        if(!appointments) return <div className="py-6"><Circle/></div>

        const { upcoming, overdue } = this.splitAppointments()

        return (

            <div>
                {overdue && overdue.length ? (
                <ul className="pl-0 list-style-none">

                    <li className="title" ><i className="fas fa-exclamation-triangle text-danger mr-2"  />My Overdue</li>

                    {overdue.map(a => (
                        <li key={a._id + this.state.refreshKey} onClick={() => this.setAppointment(a)} className="hover-select">

                            {a.in_person ? <p className="text-sm mb-0 text-info-original font-weight-bold">IN OFFICE APPOINTMENT</p> : null}

                            <p className="text-sm mb-0 font-weight-bold name text-capitalize">{a.name}</p>

                            <p className="text-sm mb-0 ">
                                <i className="fas fa-times text-danger font-weight-bold mr-2" style={{position: 'relative', top: 1}} />
                                {moment.unix(a.date).format('MMM Do h:mm')} - {moment.unix(a.date_end).format('h:mm A')}
                            </p>

                        </li>
                    ))}

                </ul>
                ) : null }

                <ul className="pl-0 list-style-none">
                    <li className="title text-capitalize">

                        <AvatarImage  src={viewing_user.avatar} className="mr-2 z-depth-2" />
                        My Upcoming
                        {/* {viewing_user.given_name}'s Upcoming */}
                    </li>
                    {upcoming && upcoming.length ? upcoming.map(a => (
                        <li key={a._id + this.state.refreshKey}  onClick={() => this.setAppointment(a)} className="hover-select">
                            <p className="text-sm mb-0 font-weight-bold name text-capitalize">{a.name}</p>
                            <p className="text-sm mb-0">{moment.unix(a.date).format('MMM Do h:mm')} - {moment.unix(a.date_end).format('h:mm A')}</p>
                        </li>
                    )) : (
                        <li><p className="text-sm mb-0"><i className="fas fa-check text-success mr-2" /> You have no upcoming appointments today</p> </li>
                    )}

                </ul>

                <ModalStartAppointment
                    canTakeCalls={canTakeCalls}
                    setOnCall={setOnCall}
                    contact_notes={contact_notes}
                    selected_appointment={selected_appointment}
                    showModalStart={showModalStart}
                    toggleModalStart={() => this.toggleModalStart()}
                />

            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

SidebarNotOnCall.propTypes = {
    setOnCall       : PropTypes.func.isRequired,
    appointments    : PropTypes.array,
    canTakeCalls    : PropTypes.bool,
}

export default connect(mapStateToProps, '')(SidebarNotOnCall);
