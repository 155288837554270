/*
Documentation

renders each page of an uploaded pdf as its own canvas wrapped in a 
surrounding div.

@param pdfFile is a file object, probably uploaded by the system's drag and drop
@cb is a callback function when we are done. no params are passed in

*/

import { pdfjs } from 'react-pdf';

import onDownloadDocument from '_functions/documents/download'

export default async (pdfFile, cb) => {

    let pdfData = pdfFile;

    if(pdfFile.url) {
        onDownloadDocument(pdfFile, async (err, data) => {
            // set reader to turn the response blob into base64
    
            const loadingTask = pdfjs.getDocument({data: data})
            loadingTask.promise.then(function(pdfDoc) {
                return renderAllPages(pdfDoc, cb)
            });
          
       })
       return;
    }


    // set reader to turn the response blob into base64
    var reader = new FileReader();
    reader.readAsDataURL(pdfData);

    reader.onloadend = async function() {

        let pdfDoc;

        if(pdfFile && pdfFile.url) {

            var base64data = reader.result;       
            base64data = base64data.substr(37, base64data.length - 1)       
    
            pdfDoc = await  pdfjs.getDocument({data: atob(base64data)})

        } else {

            pdfDoc = await pdfjs.getDocument({data: atob(reader.result.substr(28, reader.result.length - 1))} )

        }

        return renderAllPages(pdfDoc, cb)
       
    }

}

const renderAllPages = async (pdfDoc, cb) => {

    const totalPages = pdfDoc.numPages
    
    let pageNumber = 0
    let awaitCounter = 0

    // for each page from the pdf render it as a canvas
    while(pageNumber < parseInt(totalPages)) {
        pageNumber++
        await renderPage(pageNumber, totalPages, pdfDoc);
        awaitCounter++;
        if(awaitCounter === totalPages) cb(pdfDoc)

    }
    
}

const renderPage = (pageNumber, totalPages, pdfDoc) => new Promise (async resolve => {
    pdfDoc.getPage(pageNumber).then(async function(page) {

        const canvasID = `pdf-canvas-page-${pageNumber}`
        const container = document.getElementById('pdf-canvas')

        const {  multiplier, finalWidth, finalHeight } = getDimensions(page)

        createPageNumber({container, pageNumber, totalPages })
        createPageCanvas({container, pageNumber, canvasID, finalWidth, finalHeight })

        var canvas = document.getElementById(canvasID);
        if(!canvas) return;

        var ctx = canvas.getContext('2d');
        
        var viewport = page.getViewport({scale: multiplier});

        canvas.height = finalHeight;
        canvas.width = finalWidth;

        // Render PDF page into canvas context
        page.render({ canvasContext: ctx, viewport: viewport }).promise.then(() => {
            resolve()
        });


    });

})


const getDimensions = (page) => {

    const checkViewport = page.getViewport({scale: 1});

    // pages must be a standard 8.5 / 11 or else we convert to this
    // 816 x 1056 would be for us standard page

    const currentWidth = checkViewport.width
    const currentHeight = checkViewport.height

    const desiredWidth = 720

    const multiplier = desiredWidth / currentWidth
    const finalWidth = currentWidth * multiplier
    const finalHeight = currentHeight * multiplier

    return { multiplier, finalWidth, finalHeight }

}

const createPageNumber = (params) => {

    if(!params.container) return;

    var newDiv = document.createElement('div');
    newDiv.innerHTML = `<p class="text-right font-weight-bold mb-0">Page ${params.pageNumber} / ${params.totalPages}</p>`
    params.container.appendChild(newDiv)

}


const createPageCanvas = (params) => {

    if(!params.container) return;

    // 1 create a wrapper to store the canvas
    const wrapperID = params.canvasID + '-wrapper';

    var newWrapper = document.createElement('div')
    newWrapper.id = wrapperID;
    newWrapper.style.position = 'relative'

    newWrapper.setAttribute('data-pdf-page-wrapper', 'true')

    
    params.container.appendChild(newWrapper)

    const wrapperElement = document.getElementById(wrapperID)

    var newCanvas = document.createElement('canvas');

    newCanvas.id = params.canvasID;
    newCanvas.style.width = 816;
    newCanvas.style.height = 'auto';
    newCanvas.style.outlineWidth = '2px';
    newCanvas.style.outlineColor = 'black';
    newCanvas.style.marginBottom = '20px';
    
    newCanvas.classList.add('z-depth-2')


    newCanvas.setAttribute('data-page', params.pageNumber)
    newCanvas.setAttribute('data-width', params.finalWidth)
    newCanvas.setAttribute('data-height', params.finalHeight)
    newCanvas.setAttribute('data-pdf-page', "true")



    wrapperElement.appendChild(newCanvas)

}