/*
Documentation

renders the one time payment form within case view on the billing center

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Input, Card, CardBody, CardHeader, CardTitle, Row, Col, CardFooter } from 'reactstrap';
import { passesDecimalCheck } from 'utils/currency';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'
import reactHTMLParser from 'react-html-parser';

import _payments from '_functions/payments'
import { formatMoney } from 'utils/currency';

class ChargeOneTimePayment extends Component {

    state = {
        canSave: true,
    };

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState(newState);

    };

    //when a new payment is charged
    onChargePayment = async () => {

        const { one_time_payment, account_vault_api_id } = this.state

        this.setState({
            one_time_paymentState: 'valid',
            account_vault_api_idState: 'valid',
            canSave: false
        })

        //set state bases on if we have a valid amount
        if(!one_time_payment || !passesDecimalCheck(one_time_payment)) {
            return this.setState({one_time_paymentState: 'invalid', canSave: true})
        }

        //set state for valid account api id
        if(!account_vault_api_id) {
            return this.setState({account_vault_api_idState: 'invalid', canSave: true})
        }

        toggleStandardLoader(true)

        const charge = await _payments.transactions.card(account_vault_api_id, {
            transaction_amount: one_time_payment,
            case: this.props.case._id
        })

        toggleStandardLoader(false)
        toggleAlertBS(!charge.success, reactHTMLParser(charge.user_message))

        this.setState({one_time_payment: null, canSave: true, one_time_paymentState: null, account_vault_api_id: null, selected_invoice_balance: null, selected_invoice: null})
        this.props.addPaymentToState(one_time_payment)

    }

    render() {

        const account_vaults = this.props.account_vaults
        const { valueError } = this.state
        const _case = this.props.case

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">Charge A Credit, Debit, Or Bank Payment</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>

                        <label className="form-control-label" htmlFor="account_vault_api_id" >Choose Contact's Payment Method</label>

                        <Input
                            id="account_vault_api_id"
                            value={this.state.account_vault_api_id || ''}
                            type="select"
                            valid={ this.state.account_vault_api_idState === "valid" }
                            invalid={ this.state.account_vault_api_idState === "invalid" }
                            onChange={e => this.onInputChange(e, "account_vault_api_id") }
                        >
                            <option value={null}></option>
                            {account_vaults && account_vaults.length ? account_vaults.map(a => (
                                <option key={a.id} value={a.account_vault_api_id}>
                                    {_payments.cards.getName(a.account_type)}: {a.first_six.slice(0,1)}......{a.last_four} {' '}
                                    Exp: {a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}
                                    {a.id === this.props.case.contact.default_account_vault_id ? ' (DEFAULT)' : null}
                                </option>
                            )) : (
                                <option value={null}>This contact does not have a payment method on file.</option>
                            )}

                        </Input>

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                    <FormGroup>

                        <label className="form-control-label" htmlFor="one_time_payment" > Amount (US Dollars) </label>

                        {valueError ? (
                            <p className="text-sm text-danger font-weight-bold mb-0"> {valueError} </p>
                        ) : null}

                        <Input
                            id="one_time_payment"
                            value={this.state.one_time_payment || ''}
                            type="number"
                            valid={ this.state.one_time_paymentState === "valid" }
                            invalid={ this.state.one_time_paymentState === "invalid" }
                            onChange={e => this.onInputChange(e, "one_time_payment") }
                        />

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="text-muted mb-0 text-sm">Paid: {formatMoney(_case.billing_paid)} of {formatMoney(_case.billing_total)}</p>
                        </Col>

                        <Col lg={6} className="align-self-center text-right">
                            <button
                                disabled={!this.state.canSave}
                                onClick={this.onChargePayment}
                                className="btn btn-success"
                            >
                                Charge Payment
                            </button>
                        </Col>

                    </Row>
                </CardFooter>

            </Card>

        )

    }

}

ChargeOneTimePayment.propTypes = {
    case: PropTypes.object.isRequired,
    account_vaults: PropTypes.array.isRequired,
    addPaymentToState: PropTypes.func.isRequired,
}

export default ChargeOneTimePayment
