


import React from "react";
import { CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'
import { formatPhone } from 'utils/text'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'

class ContactViewInformation extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    render() {

        const { contact } = this.props

        return (
            <>

            <CardHeader className="bg-secondary">
                <CardTitle className="mb-0">Contact Information</CardTitle>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col xs={4}>
                        <h5>Phone</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.phone ? (
                            <span className="cursor-pointer" onClick={() => toggleStartCallModal(true, contact)} >
                                {formatPhone(contact.phone)}
                            </span>
                        ) : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Phone 2</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.phone_2 ? formatPhone(contact.phone_2) : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Phone 3</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                            {contact.phone_3 ? formatPhone(contact.phone_3) : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Email</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">{contact.email}</h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Email 2</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                            {contact.email_2 ? contact.email_2 : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Email 3</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                            {contact.email_3 ? contact.email_3 : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Fax Number</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                            {contact.fax_number ? contact.fax_number : '-'}
                        </h5>
                    </Col>
                </Row>

            </CardBody>

            </>
        );
    }
}

ContactViewInformation.propTypes = {
    contact: PropTypes.object.isRequired
}

export default ContactViewInformation
