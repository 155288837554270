/*
Documentation

this file allows a user to update missing document

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup, Input } from 'reactstrap';

import moment from 'moment';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import _documents from '_functions/documents';
import { toggleStandardLoader } from 'store/functions/system/system';

class ModalBulkUpdate extends React.Component {

    state = {
        documentFolder      : null,
        folderSelectOptions : [],
        confirmDelete       : '',
    }

    onMoveDocuments = async () => {

        const { documentsForAction, onBulkActionCompleted, toggleModal } = this.props
        const { documentFolder } = this.state;

        if(!documentFolder) return

        toggleStandardLoader(true)

        let counter = 0

        documentsForAction.forEach(async doc => {

            await _documents.update(doc._id, {document_folder: documentFolder})

            counter++

            if(counter === documentsForAction.length) {
                toggleStandardLoader(false)
                toggleModal()
                onBulkActionCompleted()
            }

        })

    }

    onDeleteDocuments = async () => {

        const { documentsForAction, onBulkActionCompleted, toggleModal } = this.props

        toggleStandardLoader(true)

        let counter = 0

        documentsForAction.forEach(async doc => {


            if(!doc.friendly_name.includes('In Progress Contract')) {
                await _documents.delete(doc._id)
            }

            counter++

            if(counter === documentsForAction.length) {
                toggleStandardLoader(false)
                toggleModal()
                onBulkActionCompleted()
            }

        })

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.showModal !== this.props.showModal) {
            this.setState({confirmDelete: '', documentFolder: null})

            setTimeout(() => {
                const input = document.getElementById('bulk-doc-delete')
                if(input) input.focus()
            }, 300)
            
        }

    }

    componentDidMount = () => {

        let folderSelectOptions = []

        this.props.documentFolders.forEach(folder => {
            folderSelectOptions.push({value: folder._id, label: folder.name})
        })

        this.setState({folderSelectOptions})

    }

    render() {

        const { showModal, toggleModal, documentsForAction, bulkType, documentFolders } = this.props
        const { folderSelectOptions, confirmDelete } = this.state

        const isDeleting = bulkType === 'delete'

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
                id="modal-update-document"
            >
                <div className="modal-header">
                    <h5 className="modal-title" >
                        {isDeleting ? (
                            <span>
                                <i className="fas fa-exclamation-triangle text-danger mr-2" />Bulk Delete
                            </span>
                        ) : 'Move Documents To Folder'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th style={styles.cellLeft}>File Name</th>
                                <th>Folder</th>
                                <th style={styles.cellRight} className="text-right">Created At</th>
                            </tr>
                        </thead>

                        <tbody>
                            {documentsForAction && documentsForAction.length ? documentsForAction.map(doc => {

                                const folder = documentFolders.find(d => d._id === doc.document_folder )

                                return (
                                    <tr key={doc._id}>
                                        <td style={styles.cellLeft}>
                                            <i className="fas fa-file mr-2 text-warning " />
                                            {doc.friendly_name}
                                        </td>
                                        <td className="text-capitalize">
                                            {folder ? folder.name : <span className="text-warning font-weight-bold">* Root Folder</span>}
                                        </td>
                                        <td style={styles.cellRight} className="text-right">
                                            {moment.unix(doc.created_at).format('M/D/YY - h:mm A')}
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        </tbody>

                    </table>
                </div>
                   
                 <div className="modal-body border-top">
                    {isDeleting ? (
                        <FormGroup>
                            <label className="form-control-label">To confirm type "permanently delete" in the box below.</label>
                            <Input 
                                id="bulk-doc-delete"
                                type="text"
                                value={confirmDelete}
                                onChange={(e) => this.setState({confirmDelete: e.target.value})}
                                placeholder="permanently delete"
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup>
                            <ReactSelect
                                placeholder="Add To Folder"
                                onChange={(values) => this.setState({documentFolder: values.value}) }
                                options={[{label: 'Move To Root Folder', value: 'root'}].concat(folderSelectOptions)}
                            />
                        </FormGroup>
                    )}
                </div>

                <div className="modal-footer">
                    {isDeleting ? (
                        <button onClick={this.onDeleteDocuments} className="btn btn-danger" disabled={confirmDelete !== 'permanently delete'}>
                            <i className="fas fa-trash mr-2" /> Delete Documents
                        </button>
                    ) : (
                        <button onClick={this.onMoveDocuments} className="btn btn-success">
                            <i className="fas fa-save mr-2" /> Update Document
                        </button>
                    )}
                </div>

            </Modal>

        );
    }
}

const styles = {
    cellLeft: {
        paddingLeft: 16
    },
    cellRight: {
        paddingRight: 16
    }
}

ModalBulkUpdate.propTypes = {
    showModal         : PropTypes.bool.isRequired,
    toggleModal       : PropTypes.func.isRequired,
    onSuccess         : PropTypes.func.isRequired,
    documentFolders   : PropTypes.array.isRequired,
    document          : PropTypes.object,
}

export default ModalBulkUpdate
