

import React from 'react';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import { Modal } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const ModalSendPicture = ({showModal, toggleModal, contact_id}) => {
    return  (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header d-block">
                <h5 className="modal-title" id="exampleModalLabel">Convert CSV</h5>
            </div>

            <div className="modal-body" >

                <DragAndDrop
                    zone_id="documents_2"
                    url={`/api/csv_converter`}
                    defaultValue={'Parsed PDF'}
                    uploadOne={true}
                    hideProgress={true}
                    onDocUploaded={(data) => {
                        toggleModal();

                        var a = document.createElement("a"); //Create <a>
                        a.href = "data:application/pdf;base64," + data.data; //Image Base64 Goes here
                        a.download = "ParsedCSV.csv"; //File name Here
                        a.click(); //Downloaded file
                    }}
                    extraData={{

                    }}
                />

            </div>

            <div className="modal-footer">
                <button onClick={toggleModal}  className="btn btn-outline-warning">Close</button>
            </div>

        </Modal>
    )
}

export default ModalSendPicture
