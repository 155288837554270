import React from 'react';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import { Modal } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const ModalSendPicture = ({showModal, toggleModal, contact_id}) => {
    return  (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header d-block">
                <h5 className="modal-title" id="exampleModalLabel">Send Image</h5>
            </div>

            <div className="modal-body" >

                <DragAndDrop
                    zone_id="documents_2"
                    url={`/api/v1/core/contact_texts/sendImage`}
                    onUploadStarted={() => {
                        toggleStandardLoader(true)
                    }}
                    defaultValue={'Image'}
                    uploadOne={true}
                    hideError={true}
                    onFileError={(e) => {
                        try {
                            toggleStandardLoader(false)
                            toggleModal();
                            const err = JSON.parse(e.xhr.response);
                            toggleAlertBS(true, err.message)
                        } catch(e) {
                            toggleAlertBS(true, 'Please Try Again')
                        }
                    }}
                    onDocUploaded={() => {
                        toggleStandardLoader(false);
                        toggleModal();
                        toggleAlertBS(false, 'Image Sent Successfully')
                    }}
                    extraData={{
                        contact: contact_id, 
                        finishType: 'texted_media', 
                    }}
                />

            </div>

            <div className="modal-footer">
                <button onClick={toggleModal}  className="btn btn-outline-warning">Close</button>
            </div>

        </Modal>
    )
}

export default ModalSendPicture
