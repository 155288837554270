/*
Documentation

this file renders the system Call Center

*/

import React from 'react';

const OffCallNavigation = ({view, setView}) => (
    <ul className="off-call-navigation">
        <li onClick={() => setView('search')} className={view === 'search' ? 'active' : ''}><i className="fas fa-users " /> Search</li>
        <li onClick={() => setView('keypad')} className={view !== 'search' ? 'active' : ''}><i className="fas fa-mobile " />Dial</li>
    </ul>
)

export default OffCallNavigation
