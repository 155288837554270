/*
Documentation

renders the dark bar on the bottom of the screen.
When the button is clicked we set up browser dialing

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBrowserDialing } from 'store/functions/system/system';
import { Container, Row, Col, UncontrolledTooltip } from 'reactstrap'

class BrowserDialingConsent extends Component {

    render() {

        return (

            <div className="browser-consent-banner py-4 bg-dark">
                <Container fluid >
                    <Row>
                        <Col xs={4}>
                            <i 
                                id="tooltip-browser-consent" 
                                className="fas fa-exclamation-triangle text-info-original cursor-pointer "  
                                onClick={() => setBrowserDialing(this.props.viewing_user._id)} 
                            />
                            <UncontrolledTooltip delay={0} placement="top" target={`tooltip-browser-consent`}>
                                To call through your browser click the confirmation button to the right.
                            </UncontrolledTooltip>
                        </Col>

                        <Col xs={8} className="text-right">
                            <span className="text-white">
                                <span className="mr-4" style={{position: 'relative', top: 3}}>Set Up Browser Dialing:</span>
                                <button onClick={() => setBrowserDialing(this.props.viewing_user._id)} className="btn btn-success"><i className="fas fa-check mr-2" /> Confirm</button>
                            </span>
                        </Col>

                    </Row>
                </Container>
            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(BrowserDialingConsent);
