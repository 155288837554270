/*
Documentation

this modal allows a user to change the lead attorney on a case

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { toggleAlert } from 'store/functions/system/system';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import _cases from '_functions/cases';

class RolesAssignUser extends Component {

    state = {
        role: null,
        showModal: false,
    };

    onChangeLead = () => {

        const user    = this.state.user

        if(user) {

            _cases.update(this.props.case._id, { lead_attorney: user._id })
            this.props.toggleModal()

        } else {

            toggleAlert(true, 'warning', 'You have not selected a valid user.', '3000')

        }

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { user } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Change Lead Attorney</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">This action will change the lead attorney on the case. </p>

                    <UserSearchInput
                        onSelect={(user) => this.setState({user})}
                        value={this.state.user ? this.state.user : {}}
                    />

                </div>

                <div className="modal-footer">
                    <button disabled={user && user._id ? false : true} onClick={this.onChangeLead} className="btn text-center btn-success" >
                        Change Lead
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

RolesAssignUser.propTypes = {
    case        : PropTypes.object.isRequired,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
}

export default connect(mapStateToProps, '')(RolesAssignUser);
