import moment from 'moment';
import React, { useState, memo } from 'react';
import { connect } from 'react-redux';

import { capitalize } from 'utils/text'
import { Card, CardHeader, CardTitle, Row, Col, FormGroup, Input } from 'reactstrap';

import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

import fileDownloader from 'js-file-download';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import keys from 'keys';
import Axios from 'axios';

import _analytics from '_functions/analytics';
import { useEffect } from 'react';
import { useCallback } from 'react';

import Circle from 'components/markup/loading/Circle'

const OverviewOverview = () => {

    // users = users.filter(u => u.)
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState('totalTimeBetweenCalls');
    const [filterOrder, setFilterOrder] = useState(1);

    const onSetFilter = (_filter) => {
        if(filter === _filter) {
            return setFilterOrder(-1 * filterOrder)
        }
        setFilterOrder(1);
        setFilter(_filter);
    }



    const UserRow = ({user}) => (
        <tr>
             <td className="text-capitalize">
               {console.log(user)}
                <small>
                    {renderName(user.user)}
                </small>
            </td>
             <td><small>{user.callbacks}</small></td>
             <td><small>{user.texts}</small></td>
             <td><small>{user.voicemails}</small></td>
        </tr>
    )

    let sortedMetrics = data ? JSON.parse(JSON.stringify(data)) : []


    // filter object keys to map either all asc or all desc
    if(filter && data) {
        sortedMetrics.sort((a, b) => a[filter] > b[filter] ? -1 : 1)
    }

    const fetchData = useCallback(async () => {

        const data = await _analytics.call_center.specific();
        setData(data.data);
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <Circle />

    return (
        <Card className="card-color card-danger" style={{overflow: 'auto'}}>

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">User Specific Metrics</CardTitle>
                    </Col>

                </Row>
            </CardHeader>
    
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th className={`curor-pointer ${filter === 'callbacks' ? 'text-success' : null}`} onClick={() => onSetFilter('callbacks')}>
                                Call Backs
                            </th>

                            <th className={`curor-pointer ${filter === 'texts' ? 'text-success' : null}`} onClick={() => onSetFilter('texts')}>
                                Texts
                            </th>
                            <th className={`curor-pointer ${filter === 'voicemails' ? 'text-success' : null}`} onClick={() => onSetFilter('voicemails')}>
                                Voicemail
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {sortedMetrics && sortedMetrics.length ? sortedMetrics.map((metric, i) => (
                            <UserRow key={i} user={metric} />                            
                        )) : (
                            <tr>
                                <td><i className="fas fa-exclamation-triangle mr-2 text-warning" /> No Data Available</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
    
        </Card>
    )
}


export default memo(OverviewOverview)