/*
Documentation

This is the master file for creating contact
The functionality for this is held in views/dashboard/pages/contacts/ContactsCrud

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";
import ContactsCrud from './components/ContactsCrud';
import { Link, Redirect } from 'react-router-dom';

class ContactsCreate extends React.Component {

    state = {}

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Contacts Create</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Contacts</span>} breadcrumb_1="Create"
                    actionComponent={(
                        <Link  to="/dashboard/contacts/all" className="btn btn-success"><i className="fas fa-arrow-left" /> All Contacts</Link>
                    )}
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Create A Contact</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <ContactsCrud
                            onCreate={(values) => this.setState({shouldRedirect: '/dashboard/contacts/view/' + values._id})}
                        />
                    </CardBody>
                </Card>

            </Container>
            </>
        );
    }
}

export default ContactsCreate
