/*
Documentation

This file renders a card that allows for updating letter date, letter follow up data and amount of a garnishment

*/

import React from 'react';
import DatePicker from 'react-datepicker';
import { Card, CardBody, CardHeader, CardTitle, FormGroup, CardFooter } from 'reactstrap';
import { convertDateToUnix } from 'utils/time';
import _garnishments from '_functions/garnishments';
import { toggleAlertBS } from 'store/functions/system/system'
import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

class GarnishmentFields extends React.Component {

    onUpdateFields = async () => {

        const { garnishment } = Object.assign({}, this.props)

        const amount = garnishment.amount ? parseFloat(garnishment.amount) : undefined

        const action = await _garnishments.update(garnishment._id, {
            amount,
            letter_follow_up_date: convertDateToUnix(garnishment.letter_follow_up_date),
            letter_date: convertDateToUnix(garnishment.letter_date)
        })

        if(action.success) {
            toggleAlertBS(false, 'Garnishment Fields Updated Successfully.')
        } else {
            toggleAlertBS(true, 'An error occurred updating garnishment fields.')
        }

    }

    render() {

        const { garnishment, onInputChange } = this.props

        return (
            <>
                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">Garnishment Fields</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Garnishment Letter Date</label>
                            <div
                                className={
                                    garnishment.letter_dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    garnishment.letter_dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={garnishment.letter_date}
                                    onChange={date => onInputChange('letter_date', date)}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Garnishment Follow Up Letter Date</label>
                            <div
                                className={
                                    garnishment.letter_follow_up_dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    garnishment.letter_follow_up_dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={garnishment.letter_follow_up_date}
                                    onChange={date => onInputChange('letter_follow_up_date', date)}
                                />
                            </div>
                        </FormGroup>

                        <StandardFormGroup
                            obj={garnishment}
                            objName="garnishment"
                            onChange={(o, f, v) => onInputChange('amount', v)}
                            type="number"
                            field={'amount'}
                            title={'Amount'}
                        />

                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={this.onUpdateFields} className="btn btn-success"><i className="fas fa-save mr-2" /> Update Fields</button>
                    </CardFooter>

                </Card>

            </>
        );
    }
}

GarnishmentFields.propTypes = {
    garnishment: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired
}

export default GarnishmentFields
