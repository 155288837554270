import React from 'react';

import { Nav, NavLink, NavItem } from 'reactstrap';

const StepsToPrepNavigation = ({nav, setNav, missingDocs, completedDocs,  questionnaire_id, infoProgress}) => {

    const finishedIntake = infoProgress === 100;
    const docProgress = missingDocs.length ? Math.round((completedDocs.length / (missingDocs.length + completedDocs.length)) * 100 ) : 100;

    return (
        <div className="archk-steps-to-prep-navigation border-bottom">
            <div className="archk-steps-to-prep-spacer">

                <Nav
                    className="nav-fill my-0 py-3 flex-column flex-sm-row "
                    id="tabs-text"
                    pills
                    role="tablist"
                >
                    <NavItem>
                        <NavLink
                            aria-selected={nav === 'info'}
                            className={`mb-sm-3 mb-md-0 ${nav === 'info' ? 'active' : ''} ${finishedIntake ? 'finished' : ''}`}
                            onClick={() => setNav('info')}
                            href="#pablo"
                            role="tab"
                        >
                            {finishedIntake ? <i className="fas fa-check mr-2 " /> : null}
                            Info
                            {!finishedIntake ? <span> ({questionnaire_id ? infoProgress : 100})%</span>: null}
    
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={nav === 'docs'}
                            className={`mb-sm-3 mb-md-0 ${nav === 'docs' ? 'active' : ''} ${docProgress === 100 ? 'finished' : ''}`}
                            onClick={() => setNav('docs')}
                            href="#pablo"
                            role="tab"
                        >
                            {docProgress === 100 ? <i className="fas fa-check mr-2 " /> : ''}
                            Docs {<span> {missingDocs.length ? Math.round((completedDocs.length / (missingDocs.length + completedDocs.length)) * 100 ) : 100}%</span>}
                        </NavLink>
                    </NavItem>
                </Nav>

            </div>
        </div>
    )

}

export default StepsToPrepNavigation;