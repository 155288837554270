/*
Documentation

this page holds all the routes for the dashboard layout

*/

import ErrorStandard from '../pages/errors/Standard';

export default [

    {
        component: ErrorStandard,
        show_in_sidebar: true,
        path: "/standard",
    },

]