import apiWorker from '../apiWorker'
import download from './download'

export default {
 
    find          : (query)             => apiWorker('get',  `/api/v1/core/documents/find${query}`),
    findByCase    : (case_id)           => apiWorker('get',  `/api/v1/core/documents/findByCase/${case_id}`),
    findUser      : ()                  => apiWorker('get',  `/api/v1/core/documents/find/user`),
    findAdmin     : ()                  => apiWorker('get',  `/api/v1/core/documents/find/admin`),
    create        : (data)              => apiWorker('post', `/api/v1/core/documents/create`, data),
    update        : (document_id, data) => apiWorker('patch', `/api/v1/core/documents/update/${document_id}`, data),
    delete        : (document_id)       => apiWorker('delete', `/api/v1/core/documents/delete/${document_id}`),
    merge         : (data)              => apiWorker('post', `/api/v1/core/documents/merge`, data),

    download,

}
