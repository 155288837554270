import React from 'react';

import ContactQuestionnaires from 'views/dashboard/pages/contacts/components/ContactQuestionnaires';

const StepsToPrepInfo = ({onSetInfoProgress, questionnaire_id, foundCase, onMarkDocNaOnNo}) => (
    <div className="archk-steps-to-prep-info">
        <div className="archk-steps-to-prep-spacer">
            {questionnaire_id ? (
                <ContactQuestionnaires 
                    populatedContact={foundCase.contact}
                    questionnaire={questionnaire_id}
                    autoSave={true}
                    onAnswerChange={(answers) => onSetInfoProgress(answers)}
                    onMarkDocNaOnNo={onMarkDocNaOnNo}
                />
            ): (
                <p className="text-sm">
                    No questionnaire was found for this case.{' '}
                </p>
            )}

        </div>
    </div>
)

export default StepsToPrepInfo