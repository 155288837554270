/*
Documentation

this file renders all answer contact questions from forms and give a user the ability to update their answer.

*/


/*
Documentation

this page shows the inputs and markup to create a client

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import _contact_forms from '_functions/contact_forms';
import Circle from 'components/markup/loading/Circle';
import { toggleAlertBS } from 'store/functions/system/system'
import { Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
class ContactsCrud extends React.Component {

    state = {
        noContent: false,
        contact_form: null,
        loaded: false,
        navPills: 0
    }

    onSaveAnswers = async () => {

        const saved = await _contact_forms.save({
            contact: this.props.contact_id,
            values: this.state.contact_form,
        })

        if(!saved.success) {
            toggleAlertBS(true, 'An unexpected error occurred while saving this form.')
        } else {
            toggleAlertBS(false, 'Answers Saved')
        }

    }

    fetchContactForms = async () => {

        const forms = await _contact_forms.findByContact(this.props.contact_id)

        if(forms.success) {
            this.setState({contact_form: forms.data && forms.data.values ? forms.data.values : {}, loaded: true})
        }

    }

    renderYesNo = (contact_form, key) => (
        <StandardFormGroup
            key={key}
            obj={contact_form}
            objName="contact"
            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v}})}
            type="select"
            field={key}
            title={key}
            formGroupClasses="w-50"
        >
            <option></option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
        </StandardFormGroup>
    )


    // title plus i is used to fix an error when this was created by storing the
    // title as title + i. If one of those fields exists use the old field, if not use 
    // the standard title.
    renderTextArea = (contact_form, title, i) => (
        <StandardFormGroup
            key={title}
            obj={contact_form}
            objName="contact_form"
            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v}})}
            type="textarea"
            field={contact_form[title + i] ? title + i : title}
            title={title}
            formGroupClasses="w-50"
        />
    )

    renderContactForms = () => {

        const { contact_form, navPills } = this.state
        const { forms } = this.props;

        const form = forms[navPills];

        if(contact_form && form) {

            let markup = []


            if(form.questions) {

                markup.push(<h2 key={form.name}>{form.name}</h2>)

                form.questions.forEach((question, i) => {
                    markup.push(this.renderTextArea(contact_form, question.text, i))
                })

                markup.push(<hr key={form.name + form.created_at} />)

            }


            if(!markup.length) {
                // this.setState({noContent: true})
                return <p className="text-sm font-weight-bold mb-0">There are no form fields for this contact.</p>
            } else {
                return markup
            }


        } else {

            return <p className="text-sm font-weight-bold">There are no form fields for this contact.</p>

        }

    }

    //when we mount if a contact is added in props we should update them
    componentDidMount = () => {
        this.fetchContactForms(this.props.contact_id)
    }

    render() {

        const { contact_form, loaded, navPills } = this.state;
        const { forms } = this.props;

        if(!contact_form) return <Circle />

        return (

            <>

            <Nav className="nav-fill flex-column flex-sm-row my-0" id="tabs-text" pills role="tablist" >
                {forms.map((form, i) => (
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === i}
                            className={classnames("mb-sm-3 mb-md-0 cursor-pointer", { active: navPills === i })}
                            onClick={e => this.setState({navPills: i})} 
                            role="tab" 
                        >
                            {form.name}
                        </NavLink>
                    </NavItem>
                    
                ))}
            </Nav>

            <hr className="mb-2 mt-4" />

            {this.renderContactForms()}

            {loaded ? (
                <>
                    <hr />
                    <div className="text-right">
                        <button className="btn btn-success" onClick={this.onSaveAnswers}>Save Answers</button>
                    </div>
                </>
            ) : null }

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        forms: state.forms.forms
    };
};

ContactsCrud.propTypes = {
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onError: PropTypes.func,
}

export default connect(mapStateToProps, '')(ContactsCrud);
