/*
Documentation

This renders the component to view a case and all the options that come with that
it finds the case to view of of the :_id param of the url

*/

import Circle from 'components/markup/loading/Circle';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { getUrlParameter } from 'utils/urls';

import { canUpdateCases } from '_functions/users/privileges';

import _cases from '_functions/cases';
import _case_workflows from '_functions/case_workflows';
import _document_folders from '_functions/document_folders';
import _case_feed from '_functions/case_feed';
import _lead_sources from '_functions/lead_sources';

import { setUrlParameter } from 'utils/urls'
import { capitalize } from 'utils/text'
import renderName from 'utils/renderName'

import Forms       from './components/Forms';
import Documents   from './components/Documents';
import Header      from './components/Header';
import Payments    from './components/Payments';
import Tasks       from './components/Tasks';
import Details     from './components/Details';
import HomePage    from './components/HomePage';
import FilingFees    from './components/FilingFees';
import Integrations    from './components/Integrations';
import ModalUpdateCollections    from './components/ModalUpdateCollections';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';
import _recent_activity from '_functions/recent_activity';
import _collections from '_functions/collections';

import StepsToPrepLaunch from 'components/system/StepsToPrep/Launch'


class CasesView extends React.Component {

    state = {
        loadingNewCase      : false,
        navPills            : 1000, // set this to show nothing, it will be set in component did mount
        documents           : null,
        document_folders    : null,
        case_workflow       : null,
        clickedStep         : null,
        obj_data            : {}, // this holds the contact, user, and case property ids on load
        duplicateCases      : 0,
        inCollections       : false,
        showModalUpdateCollections: false,
    }

    toggleModalUpdateCollections = () => {
        return this.setState({showModalUpdateCollections: !this.state.showModalUpdateCollections})
    }

    // sets the view for the case, basically what page we are on within the case view
    setNav = (navPills) => {

        this.setState({navPills, height: null})
        //set the url so on page refresh we come back to the same nav
        setUrlParameter('nav', navPills)

    }

    setClickedStep = (step) => new Promise (async resolve => {
        this.setState({clickedStep: step}, resolve)    
    })

    // if we start out with any nav parameters use them for initial page selection
    setNavigationByUrl = () => {

        const navPills = parseInt(getUrlParameter('nav'))

        // set nav pills based on url
        if(!isNaN(navPills)) {
            this.setState({navPills })
        } else {
            this.setState({navPills: 1})
        }

    }

    // this function is called when trying to grab a preview of a step we are NOT currently on
    setStepToShow = async (step_to_show) => {

        const previewStep = await _case_workflows.compileByCase(this.state.case._id, `?step_to_query=${step_to_show}`)
        if(previewStep.success) {

            this.setNav(2)
            this.setState({step_to_show: step_to_show !== 'finish' ? step_to_show : this.state.case.current_step, case_workflow: previewStep.data})

        }

    }

    // log that a user has viewed this case
    logView = (_case) => {

        _case_feed.create(_case._id, {
            value: 'Accessed the case: ' + capitalize(_case.name) + '.',
            user: this.props.viewing_user._id,
            type: 'system'
        })

    }

    // This function looks to see if the page we are on can has correct case _id
    // It will set the case to state if found or redirect to all cases i∂f not
    getCase = async ({hideRecentActivity}) => {
        return new Promise(async resolve => {

            const case_id = this.props.match.params._id;
            const { viewing_user } = this.props;

            //this case may have been deleted
            let _case = await _cases.findById(case_id, true)

            if(_case.data && _case.data.contact) {

                _case = _case.data

                this.setState({
                    case: _case,
                    obj_data: {
                        contact: _case.contact ? _case.contact._id : null,
                        user: viewing_user._id,
                        case: _case._id,
                    }
                })

                if(hideRecentActivity !== true) {
                    _recent_activity.update({
                        title: 'Viewed Case', 
                        value: `<a href="/dashboard/contacts/view/${_case._id}" target="_blank" rel="noopener noreferrer">${_case.name}</a>`
                    })
                }
                

                resolve(_case);

                const cases = await _cases.find(`?filter=contact__${_case.contact._id}|deleted__false{bool}|original_workflow__${_case.original_workflow}&limit=1`)
                if(cases.total_documents) this.setState({duplicateCases: cases.total_documents})

            } else {

                //case is not found in redux or through axios call | redirect to all cases
                this.setState({shouldRedirect: '/dashboard/cases/all'})
                resolve(null);

            }

        })

    }

    getDocumentFolders = async (_case) => {

        const document_folders = await _document_folders.find();
        if(document_folders.success) this.setState({document_folders: document_folders.data})

    }

    getCompiledWorkflow = async (_case) => {

        const compiledWorkflow = await _case_workflows.compileByCase(_case._id);
        if(compiledWorkflow.success) this.setState({case_workflow: compiledWorkflow.data})

    }

    // when a socket comes in we look for the properties on
    // the payload and try to match it to this case if possible
    eventIsForThisCase = (case_id, data) => {

        if(data && data.data) {

            data = data.data;

            if(data._id === case_id) { return true; }

            if(data.case) {

                if(data.case._id === case_id) { return true }
                if(data.case === case_id) { return true }

            }
        }

        return false;

     }

    listenForCaseUpdated = (data) =>
        this.eventIsForThisCase(this.props.match.params._id, data) ? this.setState({case: data.data, step_to_show: data.data.current_step}) : null;

    listenForCaseWorkflowUpdated = (data) =>
        this.eventIsForThisCase(this.props.match.params._id, data) ? this.setState({case_workflow: data.data, current_step: data.data.current_step}) : null;

    listenForCaseWorkflowTasksUpdated = (data) => {
        if(this.eventIsForThisCase(this.props.match.params._id, data)) {

           let task = data.data;

           if(task.case === this.props.match.params._id) {

                let case_workflow = Object.assign({}, this.state.case_workflow) || null;
                if(!case_workflow) return

                let stepUpdated = null

                if(!stepUpdated) {
                    case_workflow.tasks_finished.forEach(task => {
                        let foundTask = task._id === data.data._id ? task : null
                        if(foundTask) {
                            foundTask = data.data; stepUpdated = data.data;
                            case_workflow.tasks_finished[case_workflow.tasks_finished.findIndex(t => t._id === data.data._id)] = data.data
                        }
                    })
                }

                if(!stepUpdated) {
                    case_workflow.tasks_persistent.forEach(task => {
                        let foundTask = task._id === data.data._id ? task : null
                        if(foundTask) {
                            foundTask = data.data; stepUpdated = data.data;
                            case_workflow.tasks_persistent[case_workflow.tasks_persistent.findIndex(t => t._id === data.data._id)] = data.data
                        }
                    })
                }

                if(!stepUpdated) {
                    case_workflow.tasks_unfinished.forEach(task => {
                        let foundTask = task._id === data.data._id ? task : null
                        if(foundTask) {
                            foundTask = data.data; stepUpdated = data.data;
                            case_workflow.tasks_unfinished[case_workflow.tasks_unfinished.findIndex(t => t._id === data.data._id)] = data.data
                        }
                    })
                }

                //set new state
                this.setState({case_workflow})

           }

        }
    }

    initListeners = () => {

        io.on(socketEvents.cases_updated,                this.listenForCaseUpdated)
        io.on(socketEvents.case_workflows_updated,       this.listenForCaseWorkflowUpdated)
        io.on(socketEvents.case_workflow_tasks_updated,  this.listenForCaseWorkflowTasksUpdated)

    }

    forceRefresh = () => {
        this.getCompiledWorkflow(this.state.case)
    }

    fetchCollections = async (contact_id) => {
        const data = await _collections.findByContact(contact_id);
        if(data.data && data.data.length) {
            const collection = data.data.find(d => d.case === this.props.match.params._id);
            if(collection) {
                this.setState({inCollections: collection})
            } else {
                this.setState({inCollections: false})
            }
        }
    }

    loadData = async () => new Promise (async resolve => {
    
        const _case = await this.getCase({})

        if(_case) {

            if(_case.contact && _case.contact._id) setUrlParameter('cid', _case.contact._id)

            this.getDocumentFolders(_case);
            this.getCompiledWorkflow(_case)
            this.setState({step_to_show: _case.current_step});
            this.initListeners();
            this.setNavigationByUrl();
            this.fetchCollections(_case.contact._id)
            // log the user view 3 seconds after access, dont log accidental clicks
            this.logUserAccess = setTimeout(() => this.logView(_case), 3000)

            if(_case.custom_data && _case.custom_data['Needs Dan Law List'] === 'yes') {
                this.setState({showLeadSourceInfo: 'LIST DANN LAW CONSUMER CASE IN SCHEDULES'})
            }

        }

        return resolve();
    
    })

   

    componentDidUpdate = async (lastProps) => {

        if(lastProps.match.params._id !== this.props.match.params._id) {
            this.setState({loadingNewCase: true})
            await this.loadData()
            this.setState({loadingNewCase: false})

        }
        
    }

    //this function removes all socket listeners and component loops
    componentWillUnmount = () => {

        clearTimeout(this.logUserAccess)

        io.off(socketEvents.cases_updated,                  this.listenForCaseUpdated)

        io.off(socketEvents.case_workflows_updated,         this.listenForCaseWorkflowUpdated)
        io.off(socketEvents.case_workflow_tasks_updated,    this.listenForCaseWorkflowTasksUpdated)

        window.removeEventListener('popstate', this.setNavigationByUrl)

    }

    componentDidMount = async () => {

        this.loadData();
        window.addEventListener('popstate', this.setNavigationByUrl);

        _analytics.events.create({event: ANALYTIC_EVENTS.CASES_VIEWED_UNIQUE_BY_VALUE, value: this.props.match.params._id});

    }

    render() {

        const _case = this.state.case
        const { navPills, case_workflow, shouldRedirect, document_folders, loadingNewCase, clickedStep, duplicateCases, inCollections, showModalUpdateCollections, showLeadSourceInfo } = this.state
        const { step_to_show } = this.state

        const postDocuments = _case ? _case.missing_docs.filter(d => d.name.includes('POST') && d.status !== 'completed') : ''

        if(shouldRedirect)  return <Redirect to={shouldRedirect} />;
        if(!_case)          return <div className="py-6"><Circle /></div>
        if(loadingNewCase)  return <div className="py-6"><Circle /></div>

        const canUpdate = canUpdateCases()

        return (

            <div className="page_case_view">

                <Helmet>
                    <title>{_case.contact.given_name || _case.contact.family_name ? capitalize(renderName(_case.contact)) : _case.name}</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                {!canUpdate ? (
                    <div className="py-3 bg-white">
                        <Container fluid>
                            <div className="alert alert-danger mb-0"><i className="fas fa-info-circle" /> NOTE: Your account only has access to view cases, not update.</div>
                        </Container>
                    </div>
                ) : null}

                <div className="case-padding"></div>

                {inCollections ? (
                    <div className="alert alert-warning alert-squared mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 " /> 
                        This case is currently delinquent on payment, check billing.
                        <i className="fas fa-edit ml-2 " onClick={this.toggleModalUpdateCollections} />
                    </div>
                ) : null}

                {showLeadSourceInfo ? (
                    <div className="alert alert-warning alert-squared mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 " /> 
                        {showLeadSourceInfo}
                    </div>
                ) : null}

                <ModalUpdateCollections 
                    collection={inCollections}
                    showModal={showModalUpdateCollections}
                    toggleModal={this.toggleModalUpdateCollections}
                />

                <Header
                    step_to_show={step_to_show}
                    case={_case}
                    case_workflow={case_workflow}
                    setStepToShow={(step_to_show) => this.setStepToShow(step_to_show)}
                    navPills={navPills}
                    setNav={this.setNav}
                    setClickedStep={this.setClickedStep}
                    duplicateCases={duplicateCases}
                    postDocuments={postDocuments}
                />


              

                <Container className="main-case-content position-relative" fluid>

                    {_case.deleted ? (
                        <div className="alert alert-danger text-center">
                            <i className="fas fa-exclamation-triangle mr-3" />
                            THIS CASE HAS BEEN DELETED
                        </div>
                    ) : null}

                    <HomePage
                        navPills={navPills}
                        case={_case}
                        case_workflow={case_workflow}
                        getCase={this.getCase}
                    />

                    {navPills === 2 && (
                        case_workflow ? (
                            <Tasks case={_case} case_workflow={case_workflow} step_to_show={step_to_show} clickedStep={clickedStep} forceRefresh={this.forceRefresh} />
                        ) : null
                    )}

                    <Documents
                        navPills={navPills}
                        case={_case}
                        document_folders={document_folders}
                    />

                    {navPills === 16 && (
                        case_workflow ? (
                            <FilingFees case={_case} case_workflow={case_workflow} />
                        ) : null
                    )}
                    {navPills === 6 && (
                        case_workflow ? (
                            <Payments case={_case} case_workflow={case_workflow} />
                        ) : null
                    )}

                    {navPills === 10 && (
                        _case ? (
                            <Forms contact={_case.contact} />
                        ) : null
                    )}
                    {navPills === 17 && (
                        _case ? (
                            <Integrations case={_case} setNav={this.setNav}  />
                        ) : null
                    )}

                    <Details navPills={navPills} case={_case} />

                    <StepsToPrepLaunch contact={_case.contact} case_id={_case._id} />

                </Container>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CasesView);
