import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import React, { useState, useEffect, memo } from "react";
import DefaultAvatar from './DefaultAvatar.jpg'

import { setUserImage } from 'store/functions/users/users'

const AvatarImage = ({alt, src, style, className, images}) => {

    const [err, setErr] = useState(false)
    const [image, setImage] = useState(false)


    useEffect(() => {
        if(images[src]) {
            if(images[src] !== 'loading') {
                setImage(images[src]);
            }
        } else {
            if(src && !src.includes('system-prod.s3.us')) {
                setUserImage(src, (_err) => {
                    if(_err) setErr(true);
                })
            } else {
                setErr(true);
            }
           
        }
    }, [images, src])

    if(!src || err || !image) {
        return <img style={style ? style : {}} className={'avatar-image z-depth-2 ' + className}  src={DefaultAvatar} alt={alt} />
    }

    return (
        <img 
            style={style ? style : {}}
            className={'avatar-image z-depth-2 ' + className}
            src={image} 
            alt={alt} 
        />
    )

}

AvatarImage.propTypes = {
    src         : PropTypes.string,
    alt         : PropTypes.string,
    style       : PropTypes.object,
    className   : PropTypes.string,
}



const mapStateToProps = state => {
    return {
        images: state.users.images
    };
};

export default memo(connect(mapStateToProps, '')(AvatarImage));
