/*
Documentation

this file creates a modal in which a user may be assigned to a queue_call

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import _call_queue from '_functions/call_queue';

class CallCenterAdminModalAssign extends Component {

    state = {
        user: null
    };

    onAssignUser = () => {

        const user    = this.state.user

        if(user) {

            _call_queue.update(this.props.call_queue._id, { assigned_user: user._id })
            this.props.toggleModal()

        }

    }

    onRemoveUser = () => {

        _call_queue.update(this.props.call_queue._id, { remove_user: true })
        this.props.toggleModal()

    }

    componentWillReceiveProps = (nextProps) => {

        // when modal closes remove the user from state
        if(this.props.showModal && !nextProps.showModal) this.setState({user: null})
        if(!this.props.showModal && nextProps.showModal) this.setState({user: nextProps.call_queue.assigned_user})

    }

    render() {

        const { showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Assign User To Call Queue</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm mb-0">By assigning an user to this call queue the next time they try to get a call they will automatically grab this queue entry if it has not been taken yet. </p>

                    <UserSearchInput
                        onSelect={(user) => this.setState({user})}
                        value={this.state.user ? this.state.user : {}}
                    />

                </div>

                <div className="modal-footer">
                    <button onClick={this.onRemoveUser} className="btn text-center btn-warning" >Remove User</button>
                    <button onClick={this.onAssignUser} className="btn text-center btn-success" >Assign User</button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

CallCenterAdminModalAssign.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    call_queue  : PropTypes.object,
}

export default connect(mapStateToProps, '')(CallCenterAdminModalAssign);
