/*
Documentation

early version of a confirmation modal.

when the delete button is pressed we fire of a DELETE request
to the prop url passed in. Note the url must be a relative url
as this component runs through the system architeckCall

*/


import { architeckCall } from 'database';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";

class NotificationDelete extends Component {

    state = { alert: (
        <ReactBSAlert
            danger
            title={this.props.title}
            onConfirm={() => this.confirmedDelete()}
            onCancel={() => this.hideAlert()}
            showCancel
            confirmBtnBsStyle="danger"
            confirmBtnText={this.props.confirmBtnText}
            cancelBtnBsStyle="secondary"
            cancelBtnText="Cancel"
            reverseButtons={true}
            btnSize=""
        >
            {this.props.text}

        </ReactBSAlert>
    )};

    //when delete is confirmed fire of the delete action.
    confirmedDelete = async (_id) => {

        const deleted = await architeckCall({
            method: 'delete',
            url: this.props.deletionURL,
            shouldNotShowAndLogError: this.props.hideErr,
        });

        if(deleted.success) {

            if(this.props.onSuccess) {
                this.props.onSuccess(deleted);
            }

        } else {

            if(this.props.onError) {
                this.props.onError(deleted);
            }

        }

        this.props.onClose()

    };

    hideAlert = () => {
        this.setState({ alert: null })
        this.props.onClose()
    }

    render() {

        return this.state.alert

    }

}

NotificationDelete.propTypes = {
    deletionURL: PropTypes.string.isRequired,
    confirmBtnText: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    hideErr: PropTypes.bool,

};

export default NotificationDelete;




