/*
Documentation

Param 1 is an array of documents that have an _id property
Param 2 is the id to try and filter the array by

*/

const byId = (objects, _id) => {

    objects = objects ? [...objects] : [];
    objects = objects.filter(o => o._id !== _id)

    return objects

}

export default {
    byId
}
