/*
Documentation

this file renders an email in the center of the messaging center

*/

import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import reactHTMLParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import SendEmail from 'components/system/SendEmail';
import EmailAttachments from './EmailAttachments';

class MessagingCenterHandleEmail extends Component {

    state = {
        value         : null,
        isOpen        : false,
        canMarkRead   : true,
        images        : [],
    }


    toggleRead = () => {

        this.props.toggleRead('email')
        this.setState({canMarkRead: false}, () => {
            setTimeout(() =>    this.setState({canMarkRead: true}) , 2000)
        })

    }

    setEmailHTML = (html) => {

        return html.replace(/<html>/, '<div>').replace(/<\/html>/, '</div>')
            .replace(/<head>/, '<div>').replace(/<\/head>/, '</div>')
            .replace(/<body>/, '<div>').replace(/<\/body>/, '</div>')
            .replace(/<body dir="ltr">/, '<div>').replace(/<\/body>/, '</div>')
    }

    componentDidMount = () => {

        this.setState({value: reactHTMLParser(this.setEmailHTML(this.props.content.value))})

    }

    render() {

        const { content, show, onChangeShow, toggleModalCreateReminder, toggleModalAssociateContact, viewingEmailAddress, onSentEmail } = this.props;
        const { value, photoIndex, isOpen, images, imageTitle, canMarkRead } = this.state

        if(!content) return (
            <div className="center-content text-center d-flex">
                <div className="align-self-center w-100">
                <div className="gradient-box " style={{width: 350, margin: 'auto'}}>
                    <h2>Select An Email Or Text To View</h2>
                </div>
                </div>
            </div>
        )

        return (

            <>

            <Card className="card-no-show">

                <CardHeader>
                    <h3 className="mb-0">
                        <Link to={`/dashboard/contacts/view/${content.contact && content.contact._id ? content.contact._id : content.contact}`}>
                            <i className="fas fa-user mr-2" /> From: {content.from}
                        </Link>
                    </h3>
                    <h3 className="mb-0 font-weight-normal">{content.subject}</h3>
                </CardHeader>

                <CardBody>{value}</CardBody>

                <EmailAttachments
                    content={content}
                />

                {!show ? (
                    <CardFooter className="text-right">
                        <button disabled={!canMarkRead} onClick={this.toggleRead} className="btn btn-neutral">
                            {content.read ? (
                                <><i className="fas fa-undo-alt mr-2" />Mark Unread</>
                            ) : (
                                <><i className="fas fa-check mr-2" />Mark Read</>
                            )}
                        </button>
                        <button onClick={toggleModalCreateReminder} className="btn btn-warning"><i className="fas fa-gavel mr-2" /> Reminder</button>
                        <button onClick={toggleModalAssociateContact} className="btn btn-primary"><i className="fas fa-user mr-2" /> Match</button>
                        <button onClick={() => onChangeShow('reply')} className="btn btn-success"><i className="fas fa-reply-all mr-2" /> Reply</button>
                    </CardFooter>
                ) : show === 'reply' ? (

                    <>

                        <div className="text-right mb-3">
                            <button onClick={() => onChangeShow(null)} className="btn btn-warning"><i className="fas fa-arrow-left" /> All Options</button>
                        </div>

                        <SendEmail
                            sendingFrom=            {viewingEmailAddress}
                            onSuccess=              {onSentEmail}
                            subject=                {content.subject                ? content.subject : undefined}
                            to=                     {content.to                     ? content.to : undefined}
                            from=                   {content.from                   ? content.from : undefined}
                            contact=                {content.contact                ? content.contact : undefined}
                            microsoft_message_id=   {content.microsoft_message_id   ? content.microsoft_message_id : undefined}
                            isReply={true}
                        />

                    </>

                ) : null}

            </Card>

            {isOpen && (
                <Lightbox
                    imageTitle={imageTitle}
                    imageLoadErrorMessage="This document cannot be rendered as an image."
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() => this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length, }) }
                    onMoveNextRequest={() => this.setState({ photoIndex: (photoIndex + 1) % images.length, })
                    }
                />
            )}

            </>

        )

    }

}

MessagingCenterHandleEmail.propTypes = {
    viewingEmailAddress         : PropTypes.string.isRequired,
    onSentEmail                 : PropTypes.func.isRequired,
    toggleModalCreateReminder   : PropTypes.func.isRequired,
    toggleModalAssociateContact : PropTypes.func.isRequired,
    onChangeShow                : PropTypes.func.isRequired,
    toggleRead                  : PropTypes.func.isRequired,
    show                        : PropTypes.string,
    content                     : PropTypes.object,
}

export default MessagingCenterHandleEmail
