import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import OfficeSearchInput from 'views/dashboard/pages/_components/OfficeSearchInput';

class AppointmentsCrudOffice extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.office) return true
            if(appointment.office && !appointment.office._id) return true;

        }

        return false

    }

    render() {

        const { appointment, officeRequired, onAssignOffice, onRemoveOffice } = this.props

        if(!officeRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign Office</h4>
                        <OfficeSearchInput
                            hideTitle={true}
                            value={{}}
                            onSelect={(office) => onAssignOffice(office)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select at least 1 office before proceeding.</div>
                    ) : null}
                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Office</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {appointment.office && appointment.office._id ? (
                                    <tr>
                                    <td className="text-capitalize">
                                        {appointment.office ? appointment.office.name : 'OFFICE NOT FOUND'}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveOffice()} />
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Office Assigned</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudOffice.propTypes = {
    officeRequired   : PropTypes.func.isRequired,
    onAssignOffice   : PropTypes.func.isRequired,
    onRemoveOffice   : PropTypes.func.isRequired,
    appointment               : PropTypes.object.isRequired,
}

export default AppointmentsCrudOffice
