import React from 'react';
import { FormGroup, Input } from 'reactstrap';

const ArchkSimpleSelect = ({label, data, onChange}) => (
    <FormGroup>
        <label className="form-control-label mb-0">{label}</label>
        <Input 
            type="select"
            onChange={(e) => onChange(e.target.value !== '__false__' ? e.target.value : undefined)}
        >
            <option value="__false__"></option>
            {data.map((d, i) => <option value={d.value} key={i}>{d.text}</option>)}
        </Input>
    </FormGroup>
)

export default ArchkSimpleSelect;
