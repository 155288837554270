import apiWorker from '../apiWorker'

export default {

    create:         (data)          => apiWorker('post',   `/api/v1/core/workflow_steps/create`, data),
    delete:         (step_id)       => apiWorker('delete', `/api/v1/core/workflow_steps/delete/${step_id}`),
    update:         (step_id, data) => apiWorker('patch',  `/api/v1/core/workflow_steps/update/${step_id}`, data),
    saveTree:       (data)          => apiWorker('patch',  `/api/v1/core/workflow_steps/save_tree`, data),
    findByWorkflow: (workflow_id)   => apiWorker('get',  `/api/v1/core/workflow_steps/findByWorkflow/${workflow_id}` ),
    updateOrder:    (step_id, data) => apiWorker('patch',  `/api/v1/core/workflow_steps/update_order/${step_id}`, data),

}
