/*
Documentation

this page renders the master calendar



*/


import Calendar from 'components/system/Calendar';
import moment from 'moment';
import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormGroup, Input } from "reactstrap";
import renderName from 'utils/renderName';
import { getUrlParameter } from 'utils/urls';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import _calendar from '_functions/calendar';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import EventModal from './/components/EventModal';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import Circle from 'components/markup/loading/Circle';
import { Helmet } from 'react-helmet';
import * as footer from 'utils/footer'
import * as privileges from '_functions/users/privileges'

class CalendarCalendar extends React.Component {

    state = {
        data: null,
        showCalendars: [this.props.viewing_user._id],
        showCalendarsError: false,
        showOffices: [],
        appointment_id: 'new',
        startDate: null,
        queryFinished: false,
        queryCallCenter: true,
        queryCompany: false,
        dateToQuery: getUrlParameter('time') ?  getUrlParameter('time') : Math.floor(new Date() / 1000),
        showFinishedModal: false,
        showAppointmentsCrud: false,
    }

    setURLParams = () => {

        const { showCalendars } = this.state;

        let string = null

        if(showCalendars.length) {

            showCalendars.forEach(user => {
                string = string ? string + ' ' + user : user
            })

        }

        const view = getUrlParameter('view')
        const time = getUrlParameter('time')

        window.history.replaceState(null, null, `${window.location.pathname}?view=${view}&time=${time}&users=${string}`);

    }

    onDateChange = (date) => {
        this.setState({dateToQuery: date.start_of_view, dateToEnd: date.end_of_view}, this.queryCalendarData)
    }

    // this function is a custom renderer for the month cell blcok
    renderViewMonth = (appointments) => {

        if(!appointments && appointments.length) { return null }

        let appObject = {}
        let markup = []

        appointments.forEach(appointment => {

            if(appointment.assigned_to && appointment.assigned_to.length) {

                appointment.assigned_to.forEach(user => {

                    // only show calendar icon if
                    if(!this.state.showCalendars.includes(user._id)) return

                    const color = user.color ? user.color :  'black'

                    if(appObject[user._id]) {
                        appObject[user._id].appointments.push(appointment)
                    } else {
                        appObject[user._id] = {
                            appointments: [appointment],
                            color_index: color
                        }
                    }

                })

            } else if(appointment.show === 'company') {

                if(appObject['company']) {
                    appObject['company'].appointments.push(appointment)
                } else {
                    appObject['company'] = {
                        appointments: [appointment],
                        color_index: 'linear-gradient(to bottom right, #777, #000)'
                    }
                }

            } else if(appointment.show === 'call center') {

                if(appObject['call_center']) {
                    appObject['call_center'].appointments.push(appointment)
                } else {
                    appObject['call_center'] = {
                        appointments: [appointment],
                        color_index: 'linear-gradient(to bottom right, orange, red)'
                    }
                }

            }

        })

        Object.keys(appObject).forEach((key, i) => {

            const appointments = appObject[key].appointments;
            let margin =  i >= 1 ? ' ml-1' : null

            markup.push((
                <React.Fragment key={key}>
                    <span className="full">
                        <span style={{background: appObject[key].color_index }} className={'index index-important' + margin}>
                            <i className={"fas fa-calendar mr-1 "} />
                            {appointments.length}
                        </span>
                    </span>

                    <span className="circle">
                        <i className={"fas fa-calendar mr-2 "} />
                    </span>
                </React.Fragment>
            ))

        })

        return markup

    }

    renderSidebarAdditions = () => {

        return (
            <div className="">
                <FormGroup className="ml-4 pl-3 mt-2 mb-0">
                    <Input
                        type="checkbox"
                        id={`calendar-query-finished`}
                        checked={this.state.queryFinished}
                        onChange={() => this.setState({queryFinished: !this.state.queryFinished}, this.queryCalendarData)}
                    />

                    <label className="d-block form-control-label" htmlFor={`calendar-query-finished`}>
                        <b className="px-2 py-1  d-block text-dark" >Show Finished Appointments</b>
                    </label>
                </FormGroup>
                <FormGroup className="ml-4 pl-3 mt-2 mb-0">
                    <Input
                        type="checkbox"
                        id={`calendar-query-company`}
                        checked={this.state.queryCompany}
                        onChange={() => this.setState({queryCompany: !this.state.queryCompany}, this.queryCalendarData)}
                    />

                    <label className="d-block form-control-label" htmlFor={`calendar-query-company`}>
                        <b className="px-2 py-1  d-block text-dark" >Show Company Appointments</b>
                    </label>
                </FormGroup>
                <FormGroup className="ml-4 pl-3 mt-2 mb-0">
                    <Input
                        type="checkbox"
                        id={`calendar-query-call-center`}
                        checked={this.state.queryCallCenter}
                        onChange={() => this.setState({queryCallCenter: !this.state.queryCallCenter}, this.queryCalendarData)}
                    />

                    <label className="d-block form-control-label" htmlFor={`calendar-query-call-center`}>
                        <b className="px-2 py-1  d-block text-dark" >Show Call Center Appointments</b>
                    </label>
                </FormGroup>
            </div>
        )

    }

    onCalendarChange = async (user_id, office_id) => {

        let showCalendars = [...this.state.showCalendars];
        let showOffices = [...this.state.showOffices]

        if(user_id) {

            if(showCalendars.includes(user_id)) {
                showCalendars = showCalendars.filter(_id => _id !== user_id)
            } else {
                showCalendars.push(user_id);
            }

        } else if(office_id) {

            if(showOffices.includes(office_id)) {
                showOffices = showOffices.filter(_id => _id !== office_id)
            } else {
                showOffices.push(office_id);
            }

        }

        if(showCalendars.length || showOffices.length || this.state.queryCallCenter) {

            await this.queryCalendarData(showCalendars, showOffices)
            this.setState({showCalendars, showOffices}, this.setURLParams)

        } else {

            this.setState({data: [], showCalendars: [], showOffices: []}, this.setURLParams)

        }

    }

    renderDayEvent = (event, height) => {

        return (
            <span className="hover-popup" style={{display: 'inline-block', height: '100%', width: '100%'}}>
                <span style={{height: height - 4, display: 'inline-block', overflow: 'hidden', fontSize: 12}}>{event.name}</span>
                {event.office ? (
                    <i className="fas fa-home z-depth-3 bg-dark" style={{border: 'solid 1px #eee', padding: '5px 4px', borderRadius: '100%', fontSize: 11, position: 'absolute', top: -10, right: -16}}/>
                ) : null}
                <span  className="pop text-dark">
                    <b>{event.name}</b>

                    {event.description ? (
                        <>
                            <hr className="my-2" />
                            {event.description}
                        </>
                    ) : null}

                    {event.office ? (
                        <i className="fas fa-home " style={{fontSize: 12, position: 'absolute', top: 5, right: 5}}/>
                    ) : null}

                    {event.assigned_to && event.assigned_to.length ? (
                        <>
                            <hr className="my-2" />
                            <b>Assigned To:{' '}</b>
                            {event.assigned_to.map((user, i) => i === 0 ? renderName(user) : ', ' + renderName(user))}
                        </>
                    ) : null}
                    <hr className="my-2" />
                    <span style={{display: 'block'}} className="text-">From: {moment.unix(event.date).format('h:mm A')} -  {moment.unix(event.date_end).format('h:mm A')}</span>
                </span>
            </span>
        )

    }

    renderSidebarSearch = () => {

        return (
            <div className="user-filter">
                <div className="title">Find Staff Calendar </div>
                <UserSearchInput
                    value={{}}
                    onSelect={(user) => this.onCalendarChange(user._id)}
                />
            </div>
        )

    }

    // renders the calendar check boxes in the calendar sidebar
    renderSidebarCalendars = () => {

        const { users, offices } = this.props
        const { showCalendars, showOffices } = this.state

        const viewing_user_id = this.props.viewing_user._id

        let markup = [(
            <React.Fragment key="office-start">

                <div className="title">Office Calendars </div>
                <FormGroup className="ml-4 pl-3" />
            </React.Fragment>
        )];

        if(offices && offices.length) {

            offices.forEach(office => {

                markup.push((
                    <FormGroup key={office._id} className="ml-4 pl-3">
                        <Input
                            type="checkbox"
                            id={`calendar-preview-${office._id}`}
                            checked={showOffices.includes(office._id) ? true : false}
                            onChange={() => this.onCalendarChange(null, office._id)}
                        />
                        <label className="d-block form-control-label" htmlFor={`calendar-preview-${office._id}`}>
                        <b className="px-2 py-1 text-white d-block text-capitalize" style={{background: office.color ? office.color : 'black'}}>{office.name}</b>
                        </label>
                    </FormGroup>
                ))

            })

        }

        markup.push(
            <React.Fragment key={viewing_user_id}>

                <div className="title">Staff Calendars </div>

                {this.state.showCalendarsError ? (
                    <p className="text-sm text-danger font-weight-bold">You may not select more than 3 staff members.</p>
                ) : null}

                <FormGroup className="ml-4 pl-3 mt-3">
                    <Input
                        type="checkbox"
                        id={`calendar-preview-${viewing_user_id}`}
                        checked={showCalendars.includes(viewing_user_id) ? true : false}
                        onChange={() => this.onCalendarChange(viewing_user_id)}
                    />
                    <label className="d-block form-control-label" htmlFor={`calendar-preview-${viewing_user_id}`}>
                        <b className="px-2 py-1 text-white d-block" style={{background: this.props.viewing_user.color}}>Me</b>
                    </label>
                </FormGroup>

            </React.Fragment>
        )

        if(users && users.length) {

            users.forEach(user => {

                if(user._id !== this.props.viewing_user._id) {

                    markup.push((
                        <FormGroup key={user._id} className="ml-4 pl-3">
                            <Input
                                type="checkbox"
                                id={`calendar-preview-${user._id}`}
                                checked={showCalendars.includes(user._id) ? true : false}
                                onChange={() => this.onCalendarChange(user._id)}
                            />
                            <label className="d-block form-control-label" htmlFor={`calendar-preview-${user._id}`}>
                            <b className="px-2 py-1 text-white d-block text-capitalize" style={{background: user.color ? user.color : 'black'}}>{renderName(user)}</b>
                            </label>
                        </FormGroup>
                    ))

                }

            })

        }

        return markup

    }

    queryCalendarData = async (user_ids = this.state.showCalendars, office_ids = this.state.showOffices) => {
        return new Promise (async resolve => {

            let string;
            let officeString;

            user_ids.forEach(_id => string = string ? string + ` ${_id}` : _id)
            if(office_ids.length) office_ids.forEach(_id => officeString = officeString ? officeString + ` ${_id}` : _id)

            let  queryString;

            if(this.state.queryFinished)    queryString = `?queryFinished=true`
            if(this.state.queryCompany)     queryString = queryString ? queryString + `&queryCompany=true` : `?queryCompany=true`
            if(this.state.queryCallCenter)  queryString = queryString ? queryString + `&queryCallCenter=true` : `?queryCallCenter=true`
            if(this.state.dateToQuery)      queryString = queryString ? queryString + `&date=${this.state.dateToQuery}` : `?date=${this.state.dateToQuery}`
            if(officeString)                queryString = queryString ? queryString + `&offices=${officeString}` : `?offices=${officeString}`



            toggleStandardLoader(true)

            const request = await _calendar.query.query(string, queryString);

            this.setState({data: request.data ? request.data : []})

            toggleStandardLoader(false)

            resolve()

        })
    }

    setUsersFromParams = () => {

        const users = getUrlParameter('users')

        if(users && users !== 'null') {

            const showCalendars = users.split(' ')
            let errors = 0

            showCalendars.forEach(_id => {

                if (!_id.match(/^[0-9a-fA-F]{24}$/)) {
                    errors++
                }

            })

            if(!errors) {
                this.queryCalendarData(showCalendars)
                this.setState({showCalendars})
            } else {
                this.queryCalendarData([this.props.viewing_user._id])
            }

        } else {

            this.queryCalendarData([this.props.viewing_user._id])

        }

    }

    onSuccess = () => {

        this.queryCalendarData()

        if(this.state.appointment_id) {
            toggleAlertBS(false, 'Appointment Changed')
        } else {
            toggleAlertBS(false, 'Appointment Created Successfully')
        }

        this.toggleModals()

    }

    toggleModals = (values) => {

        const appointment_id          = values && values.event ? values.event._id : 'new';
        const startDate               = values && values.start_time_unix ? parseInt(values.start_time_unix) : null
        const {  showAppointmentsCrud }    = this.state

        if(values && values.event && values.event.finished_by) {

            this.setState({
                showFinishedModal: true,
                appointment_id,
                startDate
            })

        } else {

            this.setState({
                showAppointmentsCrud: this.state.showFinishedModal === true ? false:  !showAppointmentsCrud,
                showFinishedModal: false,
                appointment_id,
                startDate
            })

        }

    }

    componentWillUnmount = () => {

        footer.show()

        document.body.style.minHeight = '100vh'
        document.body.style.background = `${this.state.bodyBackground}`

    }

    componentDidMount = async () => {

        this.setUsersFromParams()

        footer.hide()

        document.body.style.minHeight = 0
        document.body.style.background = 'white'

        // store document body color
        var bodyBackground = window.getComputedStyle ? window.getComputedStyle(document.body, null).getPropertyValue("background-color") : document.body.style.backgroundColor;
        this.setState({bodyBackground})


    }

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { data, showAppointmentsCrud, appointment_id, startDate, showFinishedModal } = this.state
        const viewing_user = this.props.viewing_user

        if(!data) return <div className="py-6"><Circle /></div>

        return (

            <>

                <Helmet>
                    <title>{`Calendar`}</title>
                    <meta name="description" content="Calendar" />
                </Helmet>

                <Calendar
                    data                      = {data}
                    onCalendarChange          = {this.onCalendarChange}
                    calendarName              = {viewing_user.given_name + ' ' + viewing_user.family_name}

                    renderSidebarCalendars    = {this.renderSidebarCalendars}
                    renderSidebarSearch       = {this.renderSidebarSearch}
                    renderSidebarAdditions    = {this.renderSidebarAdditions}

                    renderViewMonth           = {this.renderViewMonth}

                    renderDayEvent            = {(event, height) => this.renderDayEvent(event, height)}

                    onRefresh                 = {() => this.queryCalendarData() }

                    onDateChange               = {(values) => this.onDateChange(values)}

                    onDayEventClick           = {(values) => this.toggleModals(values)}
                    onDayCellClick            = {(values) => this.toggleModals(values)}

                    onViewWeekCellClick       = "show_day"

                    onViewMonthCellClick      = "show_day"
                />

                {/* allow calendar to scroll left and right on call */}
                {this.props.call_status.onCall ? (
                    <div style={{marginTop: 20}} />
                ) : null}

                {showAppointmentsCrud && privileges.canModerateAppointments() ? (
                    <AppointmentsCrud
                        toggleAppointmentsCrud={this.toggleModals}
                        showAppointmentsCrud={showAppointmentsCrud}
                        appointment_id={appointment_id}
                        title={appointment_id !== 'new' ? "Update Appointment" : "Create Appointment"}
                        onSuccess={this.onSuccess}
                        isModal={true}
                        startDate={startDate}
                    />
                ) : null}

               {showFinishedModal ? (
                    <EventModal
                        showModal={showFinishedModal}
                        toggleModal={this.toggleModals}
                        appointment_id={appointment_id}

                    />
               ) : null }
            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
        call_status: state.call_center.call_status,
    };
};

export default connect(mapStateToProps, '')(CalendarCalendar);
