/*
Documentation

renders the navigation between cases and payment methods on the billing center

*/

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

class Navigation extends Component {

    state = {};

    render() {

        const navPills = this.props.navPills

        return (

            <Nav className="nav-fill flex-column flex-sm-row" pills role="tablist" >

                <NavItem className="cursor-pointer">
                    <NavLink
                        aria-selected={navPills === 1}
                        className={classnames("mb-sm-3 mb-md-0", { 'bg-success text-white': navPills === 1 })}
                        onClick={() => this.props.setNavPills(1)}
                        role="tab"
                    >
                        Cases
                    </NavLink>
                </NavItem>

                <NavItem className="cursor-pointer">
                    <NavLink
                        aria-selected={navPills === 2}
                        className={classnames("mb-sm-3 mb-md-0", { 'bg-success text-white': navPills === 2 })}
                        onClick={() => this.props.setNavPills(2)}
                        role="tab"
                    >
                        Cards
                    </NavLink>
                </NavItem>

                <NavItem className="cursor-pointer">
                    <NavLink
                        aria-selected={navPills === 3}
                        className={classnames("mb-sm-3 mb-md-0", { 'bg-success text-white': navPills === 3 })}
                        onClick={() => this.props.setNavPills(3)}
                        role="tab"
                    >
                        Payments
                    </NavLink>
                </NavItem>

                <NavItem className="cursor-pointer">
                    <NavLink
                        aria-selected={navPills === 4}
                        className={classnames("mb-sm-3 mb-md-0", { 'bg-success text-white': navPills === 4 })}
                        onClick={() => this.props.setNavPills(4)}
                        role="tab"
                    >
                        Events
                    </NavLink>
                </NavItem>

            </Nav>

        )

    }

}

Navigation.propTypes = {
    navPills: PropTypes.number.isRequired,
    setNavPills: PropTypes.func.isRequired,
}

export default Navigation


