import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

import ModalMissingDocs from '../../../../../views/dashboard/pages/cases/View/components/Documents/modals/ModalMissingDocs'

import ImageRender from 'components/functional/images/ImageRenderer';

import _zap_reports from '_functions/zap_reports'
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import ContactQuestionnaires from 'views/dashboard/pages/contacts/components/ContactQuestionnaires';

import moment from 'moment'

import renderNameAndInitial from 'utils/renderNameAndInitial';


const StepsToPrepDocs = ({foundCase, missingDocs, completedDocs, case_id, onDocStatusChange, trimDocName, users}) => {

    const [missingDocToUpload, setMissingDocToUpload] = useState(null);
    const [sentZap, setSentZap] = useState(null);

    const getName = useCallback((doc) => {
        const user = users.find(u => u._id === doc.status_marked_by)
        if(user) return renderNameAndInitial(user)
        return "Deleted User"
    }, [])

    const onSendZapLink = useCallback(async() => {

        toggleStandardLoader(true)
        const sent = await _zap_reports.requestDocuments({contact: foundCase.contact._id, case: case_id})
        toggleStandardLoader(false)

        if(sent.success) {
            setSentZap(true)
            return toggleAlertBS(false, 'Zap Document Request Sent')
        }
        return toggleAlertBS(true, 'Something went wrong, please try again or use the Zap Reports Dashboard. This is most likely a conflict with a duplicate email or phone number.')
    }, [])

    return (
        <div className="archk-steps-to-prep-docs">
            <div className="archk-steps-to-prep-spacer mt-3">

                {!foundCase.contact.zap_reports_id && !sentZap ? (
                    <div className="alert alert-danger"><i className="fas fa-exclamation-triangle mr-2 " /> <span className="text-capitalize">{foundCase.contact.given_name}</span> may not have been added to Zap Reports, send them a request for document by <b className="font-weight-bold text-underline cursor-pointer" onClick={onSendZapLink}>CLICKING HERE</b></div>
                ) : null}

                {missingDocs.length ? (
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md={6} className="align-self-center">
                                    <CardTitle className="mb-0">Missing Documents ({missingDocs.length}/{foundCase.missing_docs.length})</CardTitle>
                                </Col>
                                <Col md={6} className="align-self-center text-right">
                                    <button className="btn btn-outline-warning" onClick={onSendZapLink}>
                                        Send Zap Link
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {missingDocs.map(doc => (
                                <div key={doc._id} className="mb-3 border rounded p-3">
                                    <div className="mb-3">
                                        <i className="far fa-folder-open mr-2 " />{' '}
                                        {trimDocName(doc)}{' '}
                                    
                                        <button className="btn btn-success btn-sm ml-2" style={{position: 'relative', top: -1}} onClick={() => setMissingDocToUpload(doc)}>
                                            <i className="fas fa-upload mr-2 " /> Upload
                                        </button>
    
                                        <Input
                                            className="float-right status"
                                            type="select"
                                            style={{maxWidth: 100}}
                                            value={doc.status || ''}
                                            onChange={(e) => onDocStatusChange(doc, e.target.value)}
                                        >
                                            <option value="missing">Missing</option>
                                            <option value="n/a">N/A</option>
                                            <option value="completed">Completed</option>
                                        </Input>
                                    </div>
                                    {doc.status_marked_at ? (
                                        <div className="mt--3 mb-3"><small>
                                            <b className="text-dark">STATUS SET:{' '}</b>
                                            {moment.unix(doc.status_marked_at).format('MMM Do, YYYY h:mm A')}
                                            {' - '}
                                            <span className="text-capitalize text-dark">{getName(doc)}</span>
                                            </small>
                                        </div>
                                    ) : null}
                                    {doc.documents && doc.documents.length ? (
                                        <div className="ml--2 pl-5">
                                            {doc.documents.map(d => (
                                                <div key={d._id}>
                                                    <ImageRender 
                                                        maxNameLength={20}
                                                        doc={d}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="ml--2 pl-5"><small className="text-muted"><i className="fas fa-info-circle text-info-original mr-2 " /> No Documents Uploaded</small></div>
                                    )}

                                    {doc.questionnaire ? (
                                        <div className="p-3 bg-secondary border mt-3">
                                            <ContactQuestionnaires 
                                                populatedContact={foundCase.contact}
                                                questionnaire={doc.questionnaire}
                                                autoSave={true}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                ) : null}
               
            
                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Completed Documents ({completedDocs.length}/{foundCase.missing_docs.length})</CardTitle>
                    </CardHeader>
                    <CardBody>
                        {completedDocs.length ? completedDocs.map(doc => (
                            <div key={doc._id} className="mb-3 border rounded p-3">
                                <div className="mb-3">
                                    <i className="far fa-folder-open mr-2 " />
                                    {doc.status === 'completed' ? <i className="fas fa-check ml-2 text-success " /> : <i className="far fa-times-circle text-info-original " />}{' '}
                                    <span className="text-line-through text-muted">{trimDocName(doc)} </span>
                                    <Input
                                        className="float-right status"
                                        type="select"
                                        style={{maxWidth: 100}}
                                        value={doc.status || ''}
                                        onChange={(e) => onDocStatusChange(doc, e.target.value)}
                                    >
                                        <option value="missing">Missing</option>
                                        <option value="n/a">N/A</option>
                                        <option value="completed">Completed</option>
                                    </Input>
                                </div>

                                {doc.status_marked_at ? (
                                    <div className="mt--3 mb-3"><small>
                                        <b className="text-dark">STATUS SET:{' '}</b>
                                        {moment.unix(doc.status_marked_at).format('MMM Do, YYYY h:mm A')}
                                        {' - '}
                                        <span className="text-capitalize text-dark">{getName(doc)}</span>
                                        </small>
                                    </div>
                                ) : null}

                                {doc.documents && doc.documents.length ? (
                                    <div className="ml--2 pl-5">
                                        {doc.documents.map(d => (
                                            <div key={d._id}>
                                                <ImageRender 
                                                    maxNameLength={20}
                                                    doc={d}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="ml--2 pl-5"><small className="text-muted"><i className="fas fa-info-circle text-info-original mr-2 " /> No Documents Uploaded</small></div>
                                )}

                            </div>
                        )) : (
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original mr-2 " /> There are currently no completed documents for this case.</p>
                        )}
                    </CardBody>
                </Card>

                <ModalMissingDocs
                    case={foundCase}
                    missing_doc={missingDocToUpload}
                    showModal={missingDocToUpload ? true : false}
                    toggleModal={() => setMissingDocToUpload(null)}
                />
                
            </div>
        </div>
    )

}


const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(StepsToPrepDocs);