import StepTracker from 'components/functional/progress/StepTracker';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';
import AssignContacts from './AssignContacts';
import ReviewContacts from './ReviewContacts';
import ReviewInitials from './ReviewInitials';
import IsInvalid from './IsInvalid';
import Footer from './Footer';
import CheckForErrors from './CheckForErrors';
import AssignFields from './AssignFields';

class ModalSend extends React.Component {

    state = {
        isValidated: true,
        contacts: [],
        step: 1,
        errors: [],
        warnings: []
    }

    setStep = (step) => this.setState({step});

    setWarnings = (warnings) => this.setState({warnings})
    setErrors = (errors) => this.setState({errors})

    componentWillReceiveProps = (nextProps) => {
        if(!this.props.showModal && nextProps.showModal) this.setState({step: 1})
        if(this.props.showModal && !nextProps.showModal) this.setState({step: 1})
    }

    render() {

        const { showModal, toggleModal, initial_pages, markers, parties, onSend, setMarkerAnswer, contacts, onAddParty, associatedContact, docName } = this.props
        const { step, errors, warnings } = this.state

        let isValidated = true

        // make sure there is at least one signature field on it before proceeding.
        if(!markers.some(m => m.type === 'signature')) isValidated = false
        // make sure all text fields have a name property
        if(markers.some(m => m.type === 'text' && !m.name)) isValidated = false

        if(!docName) isValidated = false;
        
        return (

            <Modal
                className="modal-dialog-centered archk-template-docs-send-modal"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title text-capitalize">Sending</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {!isValidated ? <IsInvalid /> : (
                    <div>

                        <div className="bg-secondary py-3 border-bottom">
                            <StepTracker
                                current={step}
                                onChangeStep={(step) => {this.setStep(step)}}
                                steps={[
                                    {name: 'Assign contacts'},
                                    {name: 'Review Document'},
                                    {name: 'Update Fields'},
                                    {name: 'Finish'},
                                ]}
                            />
                        </div>

                        {step === 1 && (
                            <div className="px-2">
                                <AssignContacts 
                                    parties={parties}
                                    contacts={contacts}
                                    onAddParty={onAddParty}
                                    associatedContact={associatedContact}
                                />
                            </div>
                        )}

                        {step === 2 && (
                            <>
                                <div className="modal-body">
                                    <ReviewContacts  contacts={contacts} />
                                </div>

                                <ReviewInitials contacts={contacts} initial_pages={initial_pages} />

                            </>
                        )}

                        {step === 3 && (
                            <AssignFields 
                                contacts={contacts}
                                markers={markers}
                                setMarkerAnswer={setMarkerAnswer}
                            />
                        )}

                        {step === 4 && (
                            <CheckForErrors 
                                contacts={contacts}
                                markers={markers}
                                setWarnings={this.setWarnings}
                                setErrors={this.setErrors}
                                errors={errors}
                                warnings={warnings}
                            />
                        )}

                    </div>
                )}

                <Footer 
                    toggleModal={toggleModal}
                    onSend={onSend}
                    setStep={this.setStep}
                    isValidated={isValidated}
                    step={step}
                    contacts={contacts}
                    errors={errors}
                    warnings={warnings}
                />

            </Modal>

        );
    }
}

ModalSend.propTypes = {
    showModal       : PropTypes.bool.isRequired,
    toggleModal     : PropTypes.func.isRequired,
    onSend          : PropTypes.func.isRequired,
    markers         : PropTypes.array.isRequired,
    initial_pages   : PropTypes.bool.isRequired,
}

export default ModalSend
