/*
Documentation

this file shows a message that no account vaults exist for a contact

*/

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

class HasNoVaults extends Component {

    state = {};

    render() {

        return (

            <Card className="bg-danger">

                <CardHeader className="bg-transparent">
                    <CardTitle className="text-white mb-0">Payment Alert</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className="text-sm text-white">The contact has no payment method on file. This case cannot be moved to a recurring subscription or add manual credit card payments until one is added.</p>
                </CardBody>

            </Card>

        )

    }

}

export default HasNoVaults;
