/*
Documentation



@params (all required)

{
    button_next_id:     'id of the next button',
    button_prev_id:     `id of the previous button`,
    canvas_id:          'id of an empty canvas to render the pdf on',
    page_number_id:     'id of a span to render the page number',
    page_count_id:      'id of a span to render the page count number',
    url:                `url to pdf to load`
}

*/

import { pdfjs } from 'react-pdf';

import onDownloadDocument from '_functions/documents/download'


export default async (doc) => new Promise (async resolve => {
    onDownloadDocument(doc, async (err, data) => {
         // set reader to turn the response blob into base64

         const loadingTask = pdfjs.getDocument({data: data})
         loadingTask.promise.then(function(pdfDoc_) {
             return resolve({pdfDoc: pdfDoc_, loadingTask, src: data})
         });
       
    })

})
