/*
Documentation

This is the top half of the navbar on the dashboard layout

*/

import keys from 'keys'
import KnowledgeBase from 'components/system/KnowledgeBase';
import React from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, UncontrolledDropdown } from "reactstrap";
import { toggleRecentActivity } from 'store/functions/system/system'

import _navbar from '_functions/navbar'

import SetLocation from "../modals/SetLocation";

import AvatarImage from 'components/functional/images/AvatarImage';
import ContactAndCaseSearchInput from 'views/dashboard/pages/_components/ContactAndCaseSearchInput';

import FindCase from '../modals/FindCase';
import SetStatus from '../modals/SetStatus';

import UserDropdown from './components/UserDropdown'
import * as privileges from '_functions/users/privileges';

class NavbarTop extends React.Component {

	state = {
		showKnowledgeBase: false,
		showModalFindCase: false,
		showModalFindContact: false,
		showModalSetLocation: false,

        my_tasks: '-',
        my_appointments: '-',
        my_messaging_center: '-',
	}

    interval;

    getOffice = () => {

        const office_id = this.props.viewing_user.user_set_location;
        if(office_id) {
            const foundOffice = this.props.offices.find(o => o._id === office_id);
            return foundOffice ? foundOffice.name : 'Unknown';
        }

        return 'Home'
        
    }

    renderCountNumber = (number) => number > 99 ? '99+' : number;
    renderCountColor = (number) => number === 0 ? 'text-success' : number < 99 ? 'text-info-original' : 'text-danger';

    onQuerySelected = (object, isLink) => {

        if(object && object._id) {

            if(object.workflow) {

                const shouldRedirect = `/dashboard/cases/view/${object._id}`;

                if(isLink) {
                    window.open(shouldRedirect, "_blank");
                } else {
                    this.setState({shouldRedirect}, () => {
                        this.setState({shouldRedirect: false})
                    })
                }

            } else {

                const shouldRedirect = `/dashboard/contacts/view/${object._id}`;

                if(isLink) {
                    window.open(shouldRedirect, "_blank");
                } else {
                    this.setState({shouldRedirect}, () => {
                        this.setState({shouldRedirect: false})
                    })
                }
                
            }

        }


    }

    getCount = async () => {
        const counts = await _navbar.getCounts(this.props.viewing_user._id)
        if(counts.data) this.setState({...counts.data})
    }

    componentWillUnmount = () => {
        clearInterval(this.interval)
    }

    componentDidMount = () => {
        this.interval = setInterval(this.getCount, 30000)
        this.getCount()
    }

	render() {

        const { showModalSetLocation, my_tasks, my_messaging_center, my_appointments, shouldRedirect } = this.state
		const viewing_user = this.props.viewing_user

    	return (

			<>

            {shouldRedirect ? <Redirect to={shouldRedirect} /> : null}

            <SetLocation 
                showModal={showModalSetLocation}
                onClose={() => this.setState({showModalSetLocation: false})}
            />

            <FindCase
				toggle={() => this.setState({showModalFindCase: false})}
				showModalFindCase={this.state.showModalFindCase}
			/>

            <SetStatus
            	toggle={() => this.setState({showModalSetStatus: false})}
				showModalSetStatus={this.state.showModalSetStatus}
            />

			<KnowledgeBase
				showKnowledgeBase={this.state.showKnowledgeBase}
				toggle={() => this.setState({showKnowledgeBase: !this.state.showKnowledgeBase})}
			/>

			<Navbar style={{background: '#053A53'}} className="dashboard-nav navbar-top navbar-expand border-bottom navbar d-none d-md-block"  >
				<Container fluid>

					<Nav className="align-items-center d-none d-md-block" navbar>
						<NavItem className="mr-5">
							<Link to="/dashboard">
								<img style={{position: 'relative', top: -1}} alt="..." className="navbar-brand-img mr-3" src={keys.LOGO} />
							</Link>

                            <span id="nav-contact-search-input">

                              <ContactAndCaseSearchInput
                                    value={{}}
                                    // name="Search for contact..."
                                    onSelect={this.onQuerySelected}
                                    emptyOnSelect={true}
                                    leaveEmpty={true}
                                />
                             </span>

						</NavItem>

					</Nav>

					<Collapse navbar isOpen={true}>

						<Nav className="align-items-center ml-md-auto " navbar>

                             <span className="ml-3 font-weight-bold">
                                <a href="https://zoom.us/j/3368980445?pwd=K2kxZERtbVJsclZZNzd4Y3JzU1hNZz09" target="_blank" rel="noopener noreferrer" className="text-secondary">
                                    <i className="ml-2 fas fa-building text-success" /> 
                                </a>
                            </span>

                             <span className="ml-3 font-weight-bold">
                                <Link to="/dashboard/tasks/user" className="text-secondary">
                                    <span className={this.renderCountColor(my_tasks)}>{this.renderCountNumber(my_tasks)}</span>
                                    <i className="ml-2 fas fa-tasks " /> 
                                </Link>
                            </span>

                             <span className="ml-3 font-weight-bold">
                                <Link to="/dashboard/messenger" className="text-secondary">
                                    <span className={this.renderCountColor(my_messaging_center)}>{this.renderCountNumber(my_messaging_center)}</span>
                                    <i className="ml-2 fas fa-mobile " /> 
                                </Link>
                            </span>

                             <span className="ml-3 mr-5 font-weight-bold">
                                <Link to="/dashboard/call_center" className="text-secondary">
                                    <span className={this.renderCountColor(my_appointments)}>{this.renderCountNumber(my_appointments)}</span>
                                    <i className="ml-2 fas fa-calendar " /> 
                                </Link>
                            </span>

                            {privileges.canViewAdminDashboard() ? (
                                <a href={keys.ADMIN_URL} target="_blank" rel="noopener noreferrer">
                                    <i className="right-icon fas fa-chart-pie text-success mr-4" />
                                </a>
                            ) : null}
							
							<Link to="/dashboard/help/wikis">
								<i className="right-icon fas fa-info-circle text-warning mr-4" />
							</Link>

							<Link  to="/dashboard/calendar/me" id="archk-calendar-toggle" >
                                <i className="right-icon ni ni-calendar-grid-58" />
                            </Link>


                            <UserDropdown 
                                my_appointments={my_appointments}
                                my_tasks={my_tasks}
                                my_messaging_center={my_messaging_center}
                            />

						</Nav>

						<Nav className="align-items-center ml-auto ml-md-0" navbar>

							<UncontrolledDropdown nav style={{marginTop: -6, top: 3, position: 'relative'}}>

							<DropdownToggle className="nav-link pl-0 pr-0" color="" tag="a">
								<Media className="align-items-center">


									<Media className="ml-2 d-none d-lg-block">
										<span className="mb-0 text-sm font-weight-bold text-white">
											<span className="text-capitalize">{viewing_user.given_name} {viewing_user.family_name}</span>

											<i className="ml-1 fas fa-caret-down pr-4 pl-2"></i>
                                            <AvatarImage  src={viewing_user.avatar} className="mr-2 z-depth-2" />

										</span>
									</Media>

								</Media>
							</DropdownToggle>

							<DropdownMenu className="bg-primary" style={{borderTop: 'solid 1px white', right: 0}} right>

								<DropdownItem className="noti-title" header tag="div">
									<img src={keys.LOGO} className="w-75 " alt="Fairmax" />
								</DropdownItem>

								<DropdownItem divider />

								<span onClick={() => this.setState({showModalSetLocation: true})}>
									<DropdownItem	>
									    <i className="fas fa-building" />
                                            <span>
                                                <span className="text-uppercase font-weight-bold"> {this.getOffice()}</span>
                                            </span>
									</DropdownItem>
								</span>
								<span onClick={() => this.setState({showModalSetStatus: true})}>
									<DropdownItem	>
									    <i className="fas fa-info-circle" />
                                            <span>
                                                <span className="text-uppercase font-weight-bold"> 
                                                {viewing_user.user_set_status ? viewing_user.user_set_status : 'Status Not Set'}
                                                </span>
                                            </span>
									</DropdownItem>
								</span>

								<Link to="/dashboard/settings">
									<DropdownItem	>
									<i className="fas fa-cogs" />
										<span>Account Settings</span>
									</DropdownItem>
								</Link>

                                {viewing_user.call_contact_previous ? (
                                    <Link to={`/dashboard/contacts/view/${viewing_user.call_contact_previous}`}>
                                        <DropdownItem >
                                        <i className="fas fa-headset" />
                                            <span >My Last Call</span>
                                        </DropdownItem>
                                    </Link>
                                ) : null}


                                <span onClick={() => toggleRecentActivity(true)}>
                                    <DropdownItem >
                                    <i className="fas fa-star-of-life" />
                                        <span >Recent Activity</span>
                                    </DropdownItem>
                                </span>

								<Link to="/dashboard/links" >
									<DropdownItem>
                                        <i className="fas fa-link " />
										<span>Links</span>
									</DropdownItem>
								</Link>

								<a href={keys.AUTH_URL + '/logout'} >
									<DropdownItem>
										<i className="ni ni-user-run" />
										<span>Logout</span>
									</DropdownItem>
								</a>

							</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>

					</Collapse>
				</Container>
			</Navbar>

			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
    	offices: state.offices.offices,
  	};
};

export default connect(mapStateToProps, '')(NavbarTop);
