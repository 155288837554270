/*
Documentation

this modal can add a role to the case, note that this
does not mean assign a user to role, only add a new role

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import _case_alerts from '_functions/case_alerts';

class RolesAddRole extends Component {

    state = {
        case_alert_template: null,
        showModal: false,
    };

    onCreate = () => {

        const foundTemplate = this.props.case_alert_templates.find(t => t._id === this.state.case_alert_template)

        if(foundTemplate) {

            _case_alerts.create(this.props.case._id, {
                value                 : foundTemplate.value,
                case_alert_template   : this.state.case_alert_template,
                user                  : this.props.viewing_user._id,
            })

            this.props.toggleModal()

        }



    }

    componentWillReceiveProps = (nextProps) => {

        // when modal closes remove the user from state
        if(this.props.showModal && !nextProps.showModal) this.setState({case_alert_template: null})

    }

    render() {

        const { showModal, toggleModal, case_alert_templates } = this.props
        const { case_alert_template } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Case Alert Templates</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">
                        Create a case alert via template.
                    </p>

                    <FormGroup>
                        <label className="form-control-label"> Role Name </label>
                        <Input
                            value={case_alert_template || ''}
                            type="select"
                            onChange={e => this.setState({case_alert_template: e.target.value})}
                        >
                            <option value="Select Template">Select Template</option>
                            {case_alert_templates.map(t => <option key={t._id} value={t._id}>{t.value}</option>)}
                        </Input>
                    </FormGroup>

                </div>

                <div className="modal-footer">
                    <button onClick={this.onCreate} className="btn text-center btn-success" >
                        Create Case Alert
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        case_alert_templates: state.case_alert_templates.case_alert_templates,
        viewing_user: state.auth.viewing_user,
    };
};

RolesAddRole.propTypes = {
    case        : PropTypes.object.isRequired,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired
}

export default connect(mapStateToProps, '')(RolesAddRole);
