import React from 'react';

const Navigation = ({questionnaires, activeQuestionnaire, onSetActiveQuestionnaire}) => {

    return (
        <ul>
            {questionnaires.map((questionnaire, i) => (
                <li 
                    key={i}
                    className={activeQuestionnaire._id === questionnaire._id ? 'active' : ''}
                    onClick={() => onSetActiveQuestionnaire(questionnaire)}
                >
                    {questionnaire.name}
                </li>
            ))}
        </ul>
    )

}

export default Navigation