import React from "react";
import { Card, CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'

class ContactViewProfile extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    render() {

        const { contact, onSelectNewContact, scrollToRelationships, navPills} = this.props

        return (
            navPills === 3 ? (
                <Card className="card-color card-primary">
                    <CardBody>
                        <Row>

                            {contact.relationships && contact.relationships.length ? contact.relationships.map(r => (
                                <Col md={4} key={r._id}>
                                    <Card onClick={() => onSelectNewContact(r._id)} className="cursor-pointer text-center bg-primary mb-0">

                                        <CardHeader className="bg-transparent">
                                            <CardTitle className="mb-0 text-success text-uppercase">{r.value} of:</CardTitle>
                                        </CardHeader>

                                        <CardBody>
                                            <h2 className="my-0 py-0 text-white">{r.name}</h2>
                                            <p  className="text-sm text-white">{r.email}</p>
                                        </CardBody>

                                    </Card>
                                </Col>
                            )) : null }

                            <Col md={4}>
                                <Card onClick={scrollToRelationships} className="cursor-pointer text-center bg-gradient-warning">

                                    <CardHeader className="bg-transparent">
                                        <CardTitle className="mb-0 text-white">Relationship</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <h2 className="my-0 py-0 text-white">New Association</h2>
                                        <p className="text-white text-sm">Add A Relationship</p>
                                    </CardBody>

                                </Card>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            ) : null
        );
    }
}


ContactViewProfile.propTypes = {
    contact                 : PropTypes.object.isRequired,
    scrollToRelationships   : PropTypes.func.isRequired,
    onSelectNewContact      : PropTypes.func.isRequired,
    navPills                : PropTypes.number.isRequired,
}

export default ContactViewProfile
