import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import PropTypes from 'prop-types';

const sidebarWidth = 225;

const Sidebar = ({tabs, header, navTitle}) => {

    const [activeTab, setActiveTab] = useState(0)

    return (

        <Container fluid>
            <Row>

                <div className="col-auto py-4" style={{width: sidebarWidth}}>
                    <h2>{navTitle ? navTitle : 'Navigation'}</h2>
                    <ul className="pl-0" style={{listStyle: 'none'}}>
                                
                    {tabs.map((tab, i) => (
                        <li key={i} className={activeTab === i ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => setActiveTab(i)}>
                        {tab.title}
                        </li>
                    ))}
                    </ul>
                </div>

                <Col style={{width: `calc(100% - ${sidebarWidth}px)`}}>
                    {header ? header : null}
                    {tabs[activeTab].component}
                </Col>
            </Row>

        </Container>

    )

}

Sidebar.propTypes = {
    tabs: PropTypes.array.isRequired,
    header: PropTypes.element,
    navTitle: PropTypes.string,
}

export default Sidebar;