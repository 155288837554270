/*
Documentation

this file returns a card showing all users that are not currently on a call
the content of each row is held in ./OffCallCard

*/

import PropTypes from 'prop-types';
import moment from 'moment';
import React from "react";
import { Card, CardHeader, CardTitle, Row, Col } from "reactstrap";
import OffCallCard from './OffCallCard';
import { architeckCall } from 'database'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
class CallCenterAdminOffCall extends React.Component {

    state = {
        userActivities: {},
        users: null,
        startOfToday: moment.unix(Math.floor(new Date() / 1000)).startOf('day').valueOf() / 1000,
    }

    renderDate = (date) => {

        if(!date) return '-'
        const momentDate = moment.unix(date)

        return <div>{momentDate.format('M/DD')} at {momentDate.format('h:mm A')}</div>

    }

    listenForActivityUpdate = (data) => {

        const userActivities = Object.assign({}, this.state.userActivities)
        userActivities[data.data.user_id] = data.data.minutes

        this.setState({userActivities})

    }

    componentWillUnmount = () => {
        io.off(socketEvents.activity_updated, this.listenForActivityUpdate)
    }

    componentDidMount = async () => {

        const userActivities = await architeckCall({
            method: 'get',
            url: '/api/v1/core/activity/find'
        })

        if(userActivities.data) this.setState({userActivities: userActivities.data})

        io.on(socketEvents.activity_updated, this.listenForActivityUpdate)

    }

    render() {

        let { offCallUsers } = this.props;
        if(!offCallUsers) return <div />

        return (

            <Card className="card-color card-danger">

            <CardHeader>

                <Row>
                    <Col xs={6}>
                        <CardTitle className="mb-0">Staff Members Not On Call <span className="text-danger">({offCallUsers.length})</span></CardTitle>
                    </Col>
                    <Col xs={6} className="text-right">
                        <CardTitle className="mb-0">Inactivity</CardTitle>
                    </Col>
                </Row>
                
            </CardHeader>

            { offCallUsers.map(u => (
                <OffCallCard key={u._id} lastOnline={u.last_online} userActivity={this.state.userActivities[u._id]} row={u} />

            ))}

            </Card>

        );
    }
}

CallCenterAdminOffCall.propTypes = {
    offCallUsers: PropTypes.array,
}

export default CallCenterAdminOffCall

