/*
Documentation

renders the case cards on the billing center

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import moment from 'moment';

class CaseCards extends Component {

    state = {};

    render() {

        const { cases, contact, onSelectCase } = this.props

        if(!cases) return null

        return (

            <>

            <h2 className="text-capitalize">{contact.given_name ? contact.given_name : 'Name Not Found'}'s Cases</h2>

            <Row>

                { cases.length ? cases.map(c => (

                    <Col key={c._id} md={6}>
                        <Card className="text-center cursor-pointer" onClick={() => onSelectCase(c)}>

                            <CardHeader>
                                <CardTitle className="mb-0 text-capitalize">
                                    Billing: {c.billing_type}
                                </CardTitle>
                            </CardHeader>

                            <CardBody>
                                <h2 className="display-4 mb-0 text-capitalize">{c.name}</h2>
                                <h3 className="mb-0 font-weight-normal ">
                                    Case Status:{' '}
                                    <span className="text-success font-weight-bold">
                                        {c.finished_by ? 'Finished' : 'Active'}
                                    </span>
                                </h3>
                                <h3 className="mb-0 font-weight-normal ">
                                    <b className="font-weight-bold">Created: </b>{' '}
                                    {moment.unix(c.created_at).format('MMM Do, YYYY - h:mm A')}
                                </h3>
                            </CardBody>

                            <CardFooter>
                                <CardTitle className="mb-0 text-capitalize">
                                    Lead Attorney: {' '}
                                    <b className="text-capitalize">
                                        {c.lead_attorney ? (
                                            renderName(c.lead_attorney)
                                        ) : 'Not Found'}
                                    </b>
                                </CardTitle>
                            </CardFooter>

                        </Card>
                    </Col>

                )) : (

                    <Col md={12}>
                        <Card className="">
                            <CardBody>
                                <p className="text-sm mb-0">There are no cases associated with this contact.</p>
                            </CardBody>
                        </Card>
                    </Col>

                )}

            </Row>

            </>

        )

    }

}

CaseCards.propTypes = {
    onSelectCase: PropTypes.func.isRequired,
    cases: PropTypes.array.isRequired,
    contact: PropTypes.object.isRequired,
}

export default CaseCards
