import { helpCenter } from 'store/functions/system/system';
import { Link } from 'react-router-dom'
import React from 'react';
import * as privileges from '_functions/users/privileges'


const backButton = (
    <Link
        to="/dashboard/case_templates/all"
        className="btn btn-warning"
        onClick={() => helpCenter.toggle(
            helpCenter.content.case_templates.all.body,
            helpCenter.content.case_templates.all.footer,
        )}
    >
        <i className="fas fa-arrow-left mr-2" />Back
    </Link>
)

export default {


    all: {
        body: (
            <div>
                <h3>Case Templates</h3>
                <p>Case templates are a pre-filled case set up. This allows you to create a case quickly changing only the details you need.</p>
                <p>You may create a case template on the "Finalize Details" page when creating a case.</p>
            </div>
        ),
        footer: () => (
            <>
                {privileges.canUpdateCases() ? (
                    <Link
                        onClick={() => helpCenter.toggle(
                            helpCenter.content.cases.create.body,
                            helpCenter.content.cases.create.footer,
                            backButton
                        )}
                        to="/dashboard/cases/create"
                        className="btn btn-success"
                        >
                        Create Case
                    </Link>
                ) : null}
            </>
        )
    }

}
