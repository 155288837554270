import React, { useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

const ArchkNumber = ({label, onChange, defaultValue}) => {

    const [value, setValue] = useState('')

    const setValueToSend = useCallback((value) => {

        setValue(value);

        if(value === 'yes') {
            onChange({$gt: 0})
        } else if(value === 'no') {
            onChange(0)
        } else if(value === '__false__') {
            return onChange(undefined);
        }

    }, [onChange])

    useEffect(() => {
        if(defaultValue) setValue(defaultValue)
    }, [defaultValue])

    return (

        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input
                className="mb-2"
                type="select"
                value={value}
                onChange={(e) => setValueToSend(e.target.value)}
            >
                <option value="__false__"></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </Input>
            
        </FormGroup>

    )

}

export default ArchkNumber;
