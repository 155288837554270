/*
Documentation

this file renders the center column in the case homepage known as case alerts

*/

import ReactQuill from "react-quill";
import LoadingCircle from 'components/markup/loading/Circle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Col, Row, CardTitle } from "reactstrap";
import AlertWrapper from './components/AlertWrapper';
import CreateFromTemplate from './components/CreateFromTemplate';
import ConfirmationModal from './components/ConfirmationModal';

import _case_alerts from '_functions/case_alerts';

import { canUpdateCases } from '_functions/users/privileges'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const required_form_fields = [
    'value',
]

class Alerts extends Component {

    state = {
        newAlert:       {},
        canSave:        true,
        isFullScreen:   false,
        case_alerts:    null,
        showModal: false,
        showModalConfirmation: false,
        reactQuillText: ''
    };

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            newAlert: { ...this.state.newAlert, value }
        });
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})
    toggleModalConfirmation = () => this.setState({showModalConfirmation: !this.state.showModalConfirmation})

    listenForCaseAlertsAdded = (data) => {
        if(data.data.case === this.props.case._id) {

            const case_alerts = this.state.case_alerts ? [...this.state.case_alerts] : []
            case_alerts.unshift(data.data)

            this.setState({case_alerts})

        }
    }

    listenForCaseAlertsUpdated = (data) => {
        if(data.data.case === this.props.case._id) {

            const case_alerts = this.state.case_alerts ? [...this.state.case_alerts] : []
            case_alerts[case_alerts.findIndex(el =>  el._id === data.data._id )] = data.data

            this.setState({case_alerts})

        }
    }

    listenForCaseAlertsRemoved = (data) => {

        let case_alerts = this.state.case_alerts ? [...this.state.case_alerts] : []
        case_alerts = case_alerts.filter(c => c._id !== data.data._id)

        this.setState({case_alerts})

    }

    toggleFullScreen = () => this.setState({isFullScreen: !this.state.isFullScreen});

    //this function creates an entry into the feed
    //socket functionality should then updated the props showing it
    onCreateAlertEntry = async (send_to_contact) => {

        this.setState({canSave: false})

        let newState = Object.assign({}, this.state.newAlert);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ newAlert: newState });

        if(!errors) {

            _case_alerts.create(this.props.case._id, {
                value: newState.value,
                user: this.props.viewing_user._id,
                send_to_contact
            })

            this.setState({newAlert: {}, reactQuillText: ''})

        }

        //after load make sure button work again
        this.setState({canSave: true})

    }

    componentWillUnmount = () => {

        io.off(socketEvents.case_alerts_added,              this.listenForCaseAlertsAdded)
        io.off(socketEvents.case_alerts_updated,            this.listenForCaseAlertsUpdated)
        io.off(socketEvents.case_alerts_deleted,            this.listenForCaseAlertsRemoved)

    }

    componentDidMount = async  () => {

        io.on(socketEvents.case_alerts_added,            this.listenForCaseAlertsAdded)
        io.on(socketEvents.case_alerts_updated,          this.listenForCaseAlertsUpdated)
        io.on(socketEvents.case_alerts_deleted,          this.listenForCaseAlertsRemoved)


        if(!this.state.case_alerts) {

            const case_alerts = await _case_alerts.findByCase(this.props.case._id)

            if(case_alerts.data) {
                this.setState({case_alerts: case_alerts.data})
            }

        }
    }

    render() {

        const { isFullScreen, showModal, showModalConfirmation, reactQuillText} = this.state
        const { navPills } = this.props
        const _case = this.props.case

        if(navPills !== 1 ) return null

        const case_alerts = this.state.case_alerts
        const canUpdate = canUpdateCases();

        return (

        <div className={isFullScreen ? 'background full-background' : null}>

            {isFullScreen && <h2 className="mb-3">Case: {this.props.case.name}</h2>}

            <Card className="case_feed card-color card-danger">

                <CardHeader>
                    <Row>

                        <Col xs={10} className="align-self-center">
                            <CardTitle className="mb-0"><i className="text-danger fas fa-exclamation-triangle" /> CASE ALERTS</CardTitle>
                        </Col>

                        <Col xs={2} className="text-right align-self-center">
                            <i style={{cursor: 'pointer'}} className={!isFullScreen ? "fas fa-plus text-success pl-2" : "fas fa-minus text-warning ml-3 pl-2"} onClick={this.toggleFullScreen}/>
                        </Col>

                    </Row>
                </CardHeader>

                <CardHeader>

                    <div className="react-quill-toolbar-hide case-feed-styles">
                        <ReactQuill
                            value={reactQuillText}
                            onChange={this.handleReactQuillChange}
                            placeholder="Add Alert"
                            theme="snow"
                            modules={{
                                toolbar: []
                            }}
                        />
                    </div>

                    {/* <StandardFormGroup
                        disabled={!canUpdate}
                        obj={newAlert}
                        objName='newAlert'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        placeholder="Add Alert"
                        type="textarea"
                        field="value"
                    /> */}

                    <div className="text-right">
                        <Row>
                            <Col lg={6} className="text-left">
                                <button
                                    disabled={!canUpdate ? true: !this.state.canSave}
                                    onClick={canUpdate ? this.toggleModal : null}
                                    className="btn btn-s btn-outline-danger btn-block"
                                >
                                    <i className="fas fa-plus" /> Template
                                </button>
                            </Col>

                            <Col lg={6}>
                                <button
                                    disabled={!canUpdate ? true: !this.state.canSave}
                                    onClick={canUpdate ? this.toggleModalConfirmation : null}
                                    className="btn btn-s text-center btn-danger btn-block"
                                >
                                    <i className="fas fa-exclamation" /> Create Alert
                                </button>
                            </Col>

                        </Row>
                    </div>

                </CardHeader>

                <div className="feed-wrapper feed-wrapper-alerts" style={{overflowY: 'auto'}}>

                    <table className="table" >
                        <tbody>
                            {case_alerts && case_alerts.length ? case_alerts.map(entry => (
                                <AlertWrapper key={entry._id} alert={entry} />
                            )) : (
                                case_alerts ? (
                                <tr>
                                    <td>
                                        <div className="item-wrapper">
                                            {'There are no case alerts to show.'}
                                        </div>
                                    </td>
                                </tr>
                                ) : null
                            )}

                        </tbody>
                    </table>

                    {!case_alerts ? (
                        <LoadingCircle width={30}/>
                    ) : null}

                </div>

            </Card>

            <CreateFromTemplate
                case={_case}
                showModal={showModal}
                toggleModal={this.toggleModal}
            />

            <ConfirmationModal
                showModal={showModalConfirmation}
                toggleModal={this.toggleModalConfirmation}
                onCreateAlertEntry={this.onCreateAlertEntry}
            />

        </div>

        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

Alerts.propTypes = {
    case        : PropTypes.object.isRequired,
    navPills    : PropTypes.number.isRequired,
}

export default connect(mapStateToProps, '')(Alerts);
