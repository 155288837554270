/*
Documentation

this file creates the admin call center view

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";


import * as _ from 'underscore';

import AssignedAppointments from './AssignedAppointments';
import OverdueTasks from './OverdueTasks';
import OverdueTasksToday from './OverdueTasksToday';
import StaffOnline from './StaffOnline';
import UnreadTexts from './UnreadTexts';
import Voicemail from './Voicemail';
// import OpenDocuments from './OpenDocuments';
// import UnreadEmails from './UnreadEmails';
import OnHold from './OnHold';
import OnCall from './OnCall';
import OffCall from './OffCall';
import CallQueue from './CallQueue';
import UserCallMetrics from './UserCallMetrics';
import Specific from './Specific';

import { setUsers } from 'store/functions/users/users'

import keys from 'keys';

class CallCenterAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onCallUsers: null,
            offCallUsers: null,
        }

        // not used but may implement soon to prevent re-rendering
       this.sortUsersThrottled = _.throttle(this.sortUsers, 5000)
    }

    willUpdateUsers = false
    interval = null

    sortUsers = (props) => {

        let users = [...props.users]
        let onCallUsers = []
        let offCallUsers = []

        users.forEach(u => {

            if( u.call_status !== 'on call') {
                offCallUsers.push(u)
            } else {
                onCallUsers.push(u)
            }

        })

        offCallUsers.sort((a, b) => a.status === 'online' ? -1 : 1)

        this.setState({onCallUsers, offCallUsers});

    }

    componentWillUnmount = () => {
        if(this.interval) clearInterval(this.interval)
    }


    componentWillReceiveProps = (nextProps) => this.sortUsers(nextProps)
    componentDidMount = () => {
        this.sortUsers(this.props)
        this.interval = setInterval(setUsers, 1000)
    }

    render() {

        const { onCallUsers, offCallUsers } = this.state
        const { company, hideHeader } = this.props

        return (
            <>

            <Helmet>
                <title>{`Call Center Admin`}</title>
                <meta name="description" content="Call Center Admin" />
            </Helmet>

            <Container fluid>

                {hideHeader !== true ? (
                    <DashHeaderOpen
                        icon="fas fa-home"
                        icon_link="/dashboard"
                        title={<span>Call Center</span>}
                        breadcrumb_1="Call Center"
                        breadcrumb_2="Admin"
                        actionComponent={(
                            // <a href={keys.TALK_URL} target="_blank" rel="noopener noreferrer" className="btn btn-success">Call Center</a>
                            <Link  to="/dashboard/call_center" className="btn btn-success">Call Center</Link>
                        )}
                    />
                ) : null}
               

                {company.call_routing === 'queue' ? <div className="alert alert-danger">All calls are currently being routed to on hold calls or call backs that have been requested</div> : null}

                 {/* <Row>

                    <Col lg={5} xl={8}>
                        <AssignedAppointments company={company} />
                        <UserCallMetrics />
                    </Col>

                    <Col lg={5} xl={4}>

                        <StaffOnline />

                        <Row>
                            <Col md={6}><OverdueTasks       /></Col>
                            <Col md={6}><OverdueTasksToday  /></Col>
                       
                            <Col md={6}><UnreadTexts        /></Col>
                            <Col md={6}><Voicemail          /></Col>
                        </Row>

                    </Col>

                </Row> */}


                <Row>

                    <Col lg={5} xl={4}>
                        <UserCallMetrics />
                        <OffCall offCallUsers={offCallUsers}/>
                        <Specific />
                    </Col>

                    <Col lg={7} xl={8}>
                        <StaffOnline />
                        <OnCall onCallUsers={onCallUsers}/>
                        <OnHold />
                        <CallQueue company={company} />
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        company   : state.companies.company,
        users     : state.users.users,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdmin);

