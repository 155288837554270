import PropTypes from 'prop-types'
import React from "react";
import { Row, Badge, Col, Input, FormGroup } from "reactstrap";
import { getMarkerPositionOnPage, getMarkerIcon } from './_functions/utils'
import ConfirmationModal from 'components/functional/modals/Confirmation.js'
import * as privileges          from '_functions/users/privileges'
import { connect } from 'react-redux'

import onDownloadDocument from '_functions/documents/download'


class Sidebar extends React.Component {

    state = {
        showModal: false
    }

    toggleConfirmationModal = (value) => this.setState({showModal: !this.state.showModal, confirmationValue: value})

    scrollToMarker = (marker) => {

        const position = getMarkerPositionOnPage(marker);
        if(position) window.scrollTo({top: position.top, left: 0})

    }

    render() {

        const { toggleInitialPages, initial_pages, clearPDF, wipePDF, pdfData, confirmSend, updateMarkerName, originalDoc} = this.props;
        const { togglePartyModal, parties, onShowingPartyChange, showingParty, docName, onNameChange, confirmTemplate } = this.props;

        const markers = [...this.props.markers].filter(m => m.party === showingParty);
        markers.sort((a,b) => a.pageNumber < b.pageNumber ? -1 : 1)

        const { showModal, confirmationValue } = this.state

        return (

            <div className="archk-template-docs-sidebar z-depth-1">

                <div className="section">
                    <h3>Document Name</h3>
                </div>

                <ul>
                    <li>
                        <FormGroup className="mb-0">
                            <Input 
                                style={{minHeight: 0}}
                                value={docName}
                                type="test"
                                onChange={(e) => onNameChange(e.target.value)}
                            />
                        </FormGroup>
                    </li>
                </ul>


                <div className="section">
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <h3>PDF Markers</h3>
                        </Col>
                        <Col lg={6} className="align-self-center text-right">
                            <FormGroup className="mb-0">
                                <Input 
                                    style={{minHeight: 0}}
                                    value={showingParty}
                                    type="select"
                                    onChange={(e) => onShowingPartyChange(parseInt(e.target.value))}
                                >
                                    <option value="0">Internal</option>
                                    <option value="1">Party 1</option>
                                    {parties > 1 ? <option value="2">Party 2</option> : null}
                                    {parties > 2 ? <option value="3">Party 3</option> : null}
                                    {parties > 3 ? <option value="4">Party 4</option> : null}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <ul className="hover-pop">
                    {markers.length ? markers.map((marker, i) => (
                        <li key={marker.left.toString() + marker.top.toString()} onClick={() => this.scrollToMarker(marker)}>
                            <Row>
                                <Col xs={12}>
                                    <span className="icon-identifier">{getMarkerIcon(marker)}</span>
                                    <span className="text-capitalize">
                                        {marker.type ? marker.type : <span> <i className="fas fa-exclamation-triangle mr-2 text-danger" /> type not set</span>}
                                    </span>
                                    <Badge className={`${marker.type} party-${marker.party} page-number`}>
                                        P {marker.pageNumber}
                                    </Badge>
                                </Col>
                            </Row>
                            {marker.name ? (
                                <div className="mt-3">
                                    <Input 
                                        style={{
                                            border: '1px solid rgb(221, 221, 221)',
                                            background: 'white',
                                            borderRadius: '8px' ,
                                            fontSize: 12
                                        }}
                                        value={marker.name}
                                        type="textarea"
                                        onChange={(e) => updateMarkerName(e.target.value, i)}
                                    />
                                </div>
                             ) : null}
                        </li>
                    )) : (
                        <li>
                            <p className="text-sm mb-0">
                            {showingParty === 0 ? 
                                <span>No Internal Markers Have Been Placed</span> : 
                                <span>No Markers Placed For Party {showingParty}</span>
                            }
                            </p>
                        </li>
                    )}

                </ul>

                <div className="section">
                    <h3>Settings</h3>
                </div>

                <ul>
                    <li>
                        <div className="custom-control custom-checkbox">
                            <input checked={initial_pages} onChange={toggleInitialPages} className="custom-control-input" id="customCheck1" type="checkbox"/>
                            <label className="text-sm custom-control-label" htmlFor="customCheck1">
                                Place Initials On Each Page
                            </label>
                        </div>
                    </li>
                    <li>
                        <p className="text-sm d-inline">
                            <i onClick={togglePartyModal} className="fas fa-edit mr-3 ml-1 text-success cursor-pointer" /> 
                            {parties > 1 ? `Up to ${parties} parties can sign` : 'Only one party can sign'}
                        </p>
                    </li>

                </ul>

               {pdfData ? (
                   <div style={{paddingBottom: this.props.call_status.onCall ? 40 : 0}}>
                        <div className="section">
                            <h3>PDF Actions</h3>
                        </div>

                        <ul>
                            <li className="py-3">
                                <Row>
                                    <Col md={6}>
                                        <button 
                                            onClick={() => this.toggleConfirmationModal('clear')} 
                                            className="btn btn-outline-warning btn-block"
                                        >
                                            Clear
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button 
                                            onClick={() => this.toggleConfirmationModal('wipe')} 
                                            className="btn btn-outline-danger btn-block"
                                        >
                                            Wipe PDF
                                        </button>
                                    </Col>
                                </Row>
                            </li>

                            {privileges.canModerateTemplateDocs() ? (
                                <li className="py-3">
                                    <button onClick={confirmTemplate} className="btn btn-outline-success btn-block">
                                        {originalDoc ? 'Update Template' : 'Save As Template'}
                                    </button>
                                </li>
                            ) : null}

                            {originalDoc ? (
                                <li className="py-3">
                                    <button onClick={() => onDownloadDocument(originalDoc.document)} className="btn btn-outline-info btn-block">
                                        <i className="fas fa-download mr-2 " /> Download Doc
                                    </button>
                                </li>
                            ) : null}
                           
                           
                            <li className="py-3">
                                <button onClick={confirmSend} className="btn btn-success btn-block">Send PDF</button>
                            </li>
                        </ul>
                    </div>
               ) : null}

                <ConfirmationModal
                    showModal={showModal}
                    toggleModal={() => this.toggleConfirmationModal(false)}
                    title={confirmationValue === 'clear' ? "Clear Markers" : "Remove PDF"}
                    body={
                        confirmationValue === 'clear' ? (
                            <span>This action will remove all markers currently on the pdf.</span>
                        ) : (
                            <span>This action will remove the PDF you are working on.</span>
                        )
                    }
                    onConfirmation={confirmationValue === 'clear' ? clearPDF : wipePDF }
                />

            </div>

        );
    }
}

Sidebar.propTypes = {
    markers               : PropTypes.array.isRequired,
    toggleInitialPages    : PropTypes.func.isRequired,
    initial_pages         : PropTypes.bool.isRequired,
    clearPDF              : PropTypes.func.isRequired,
    wipePDF               : PropTypes.func.isRequired,
    updateMarkerName      : PropTypes.func.isRequired,
    pdfData               : PropTypes.object,
}


const mapStateToProps = state => {
    return {
        call_status: state.call_center.call_status,
    };
};

export default connect(mapStateToProps, '')(Sidebar);
