import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';

import _classes from '../_classes'

import Details from './Details';
import Cases from './Cases';
import Calendar from './Calendar';

const MessengerSidebar = ({contact, getContactIdentifier}) => {

    const [show, setShow ] = useState('details');

    useEffect(() => {
        setShow('details');
    }, [ contact ? contact._id : ''])

    if(!contact) return <div />

    return (

        <div id="archk-messenger-sidebar-right" className="col-auto archk-messenger-sidebar archk-messenger-sidebar-right" >

            <ul>
                <li>

                    <h3 className="mb-2 text-uppercase">Contact Info</h3>

                    <Row>
                        <Col xs={4}>
                            <p className="text-sm mb-0 display-inline-block cursor-pointer">
                                <span className={show === 'details' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => setShow('details')}>
                                    <i className="fas fa-info-circle " />
                                </span>
                            </p>
                        </Col>
                        <Col xs={4}>
                            <p className="text-sm mb-0 display-inline-block cursor-pointer text-center">
                                <span className={show === 'cases' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => setShow('cases')}>
                                    <i className="fas fa-gavel " />
                                </span>
                            </p>
                        </Col>
                        <Col xs={4} className="text-right">
                            <p className="text-sm mb-0 display-inline-block cursor-pointer">
                                <span className={show === 'calendar' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => setShow('calendar')}>
                                    <i className="fas fa-calendar " />
                                </span>
                            </p>
                        </Col>
                    </Row>

                </li>

            </ul>

            {show === 'details' ? (
                <Details 
                    contact={contact}
                    getContactIdentifier={getContactIdentifier}
                />
            ) : show === 'cases' ? (
                <Cases 
                    contact={contact}
                />
            ) : show === 'calendar' ? (
                <Calendar 
                    contact={contact}                
                />
            ) : null}


        </div>

    );

}

export default MessengerSidebar
