/*
Documentation

This is the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import NavbarBottom from './NavbarBottom';
import NavbarTop from './NavbarTop';
import NavbarMobile from './NavbarMobile';

import { toggleBillingCenter } from 'store/functions/system/system'

class LayoutNavbar extends React.Component {

	state = {
		showBillingCenter: false,
	}

	onRedirect = () => {

		let redirect = this.state.shouldRedirect

		if(redirect) {
			this.setState({shouldRedirect: null})
			return <Redirect to={redirect} />
		}
	}


	////////////////////////////
	// Hotkeys

	keysPressed = {}

	_handleKeyDown = (e) => {
		switch (e.keyCode) {

			case undefined: return

			// command + b
			// case this.keysPressed['Meta'] && 66:  toggleBillingCenter(!this.props.billingCenter.show); break;

            default: break;

		}
	}

	_handleKeysPressed = (e) => this.keysPressed[e.key] = true;
	_handleKeysUnPressed = (e) => delete this.keysPressed[e.key];

	// end Hotkeys
	////////////////////////////

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeysPressed)
		document.addEventListener('keydown', this._handleKeyDown)
		document.addEventListener('keyup', this._handleKeysUnPressed)
	}

	componentWillUnmount = () => {
		document.removeEventListener('keydown', this._handleKeysPressed)
		document.removeEventListener('keyup', this._handleKeysUnPressed)
	}


	render() {

		if(this.state.shouldRedirect) return this.onRedirect()

        const location = window.location.href;

    	return (

			<>

                <NavbarTop />

                <NavbarBottom
                    toggleBillingCenter={toggleBillingCenter}
                    location={location}
                />

                <NavbarMobile
                    location={location}
                    toggleBillingCenter={toggleBillingCenter}

                />


			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
        viewing_user: state.auth.viewing_user,
        billingCenter: state.system.billingCenter
  	};
};

export default connect(mapStateToProps, '')(LayoutNavbar);
