/*
Documentation

this page should show if we have a problem connecting to the database

*/

import moment from 'moment';
import React, { Component } from 'react';
import { Container } from 'reactstrap';

class FailedConnection extends Component {

    render() {

        const time = moment().valueOf()

        return (
            <Container>
                <div className="text-center py-6">
                    <h2 className="text-uppercase mb-4">
                        An unexpected error occurred. <br />
                        <span className="text-underline">Send this information to the developers monitoring your system immediately.</span>
                    </h2>

                    <div className="text-sm bg-white p-4 rounded">
                        <h3 className="text-danger">Database Failed To Connect.</h3>

                        <br />

                        <b>Time: {' '}
                            { moment.utc(time, 'x').format('MMMM Do, YYYY')  }
                            {' at '}
                            { moment.utc(time, 'x').format('hh:mm A')  } (GMT)
                        </b>
                    </div>

                </div>
            </Container>
        )
    }

}

export default FailedConnection;
