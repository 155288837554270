/*
Documentation

this file renders the full view of a contact
this should be the most detailed view of a contact within the system

*/

import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Container } from "reactstrap";
import keys from 'keys'

import Appointments from './components/Appointments';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import Overview from './components/Overview';
import Relationships from './components/Relationships';
import Update from './components/Update';
import Cases from './components/Cases';
import Forms from './components/Forms';
import Blacklist from './components/Blacklist';
import Century from './components/Century';
import CallLogs from './components/CallLogs';
import Feed from '../../cases/View/components/Feed';

// import Payments from './components/Payments';
import ContactPayments from '../components/ContactPayments';
import Circle from 'components/markup/loading/Circle';

import renderName from 'utils/renderName';

import { setUrlParameter, getUrlParameter } from 'utils/urls'

import _cases from '_functions/cases';
import _contacts from '_functions/contacts';
import _contact_notes from '_functions/contact_notes';
import _lead_sources from '_functions/lead_sources';
import _call_logs from '_functions/call_logs';
import _voicemail from '_functions/voicemail'

import { capitalize }  from 'utils/text';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';

import _analytics from '_functions/analytics';
import _collections from '_functions/collections'
import _recent_activity from '_functions/recent_activity';

import StepsToPrepLaunch from 'components/system/StepsToPrep/Launch'


const navigationOptions = [
    {value: 0, label: 'Overview'},
    {value: 5, label: 'Update Contact'},
    {value: 1, label: 'Cases'},
    {value: 2, label: 'Appointments'},
    {value: 3, label: 'Relationships'},
    {value: 4, label: 'Payments'},
    {value: 6, label: 'Feed'},
    {value: 7, label: 'Forms'},
    {value: 8, label: 'Call Logs'},
    {value: 50, label: 'Century SS'},
]

const createCaseURL = (contact_id) => {
    return `/dashboard/cases/create?selected_workflow=5f732490afcb124a4b76c473&selected_contact=${contact_id}&selected_roles=Attorney__5ea23922c05caf0017ec01c1&name=&user=&court=&billing_type=&is_template=true`
}

class ContactView extends React.Component {

    state = {
        loaded: false,
        contact_id: null,
        contact: {},
        appointments: [],
        contact_notes: null,
        lead_source_entry: null,
        _contact_forms: null,
        canSave: true,
        navPills: 0,
        cases: [],
        shouldScrollToRelationships: false,
        call_logs: null,
        voicemail: null,
        inCollections: false,
    }

    updateBlacklist = () => {


    }

    toggleNavPills = (value) => {
        this.setState({navPills: value})
        setUrlParameter('nav', value)

    }

    scrollToRelationships = () => {
        this.setState({navPills: 5, shouldScrollToRelationships: true}, () => {

            setTimeout(() => {
                this.setState({shouldScrollToRelationships: false})
            }, 500)

        })

    }

    onSelectNewContact = (_id) => {

        this.setState({contact_id: _id, navPills: 1})
        window.history.replaceState(null, null, `/dashboard/contacts/view/${_id}?nav=0`);

    }

    onMarkVoicemailListened = (_id, listened) => {

        _voicemail.update(_id, { listened })

        let voicemail = [...this.state.voicemail]

        const index = voicemail.findIndex(v => v._id === _id)
        voicemail[index].listened = listened

        this.setState({voicemail})


    }

    fetchContactNotes = async () => {

        const notes = await _contact_notes.findByContact(this.state.contact_id)
        if(notes.success) this.setState({contact_notes: notes.data ? notes.data : []})

    }

    fetchContactCallLogs = async () => {

        const logs = await _call_logs.findByContact(this.state.contact_id)
        if(logs.success) this.setState({call_logs: logs.data ? logs.data : []})

    }

    fetchContactVoicemail = async () => {

        const voicemail = await _voicemail.find(`?filter=contact__${this.state.contact_id}`);
        if(voicemail.success) this.setState({voicemail: voicemail.data})

    }

    fetchContact = async () => {
        return new Promise (async resolve => {

            if(!this.state.contact_id) return resolve(false);
            if(this.state.contact_id === 'undefined') return resolve(false);

            const contact = await _contacts.findById(this.state.contact_id, true)
    
            if(contact.success && contact.data) {
                this.setState({contact: contact.data, loaded: true})
                _recent_activity.update({title: 'Viewed Contact', value: `<a href="/dashboard/contacts/view/${contact.data._id}" target="_blank" rel="noopener noreferrer">${renderName(contact.data)}</a>`})
                return resolve(true);
    
            } else {
                return resolve(false);
            }
        
        })
    }

    fetchContactCases = async () => {

        const cases = await _cases.find(`?filter=contact__${this.state.contact_id}|deleted__false{bool}`, true)
        if(cases && cases.data) this.setState({cases: cases.data})

    }

    fetchLeadSource = async () => {

        if(!this.state.contact_id) return;

        const lead_source_entry = await _lead_sources.entry.findByContact(this.state.contact_id, true)
        if(lead_source_entry && lead_source_entry.data) this.setState({lead_source_entry: lead_source_entry.data})

    }

    getContactId = (props) => {
        return props.contact ? props.contact : props.match ? props.match.params._id : null;

    }

    setContactId = (props) => {
        return new Promise (async resolve => {

            const contact_id = this.getContactId(props);

            this.setState({contact_id}, () => {
                resolve(contact_id)
                setUrlParameter('cid', contact_id)
            })


        })
    }

    setNavigationByUrl = () => {

        // get the nav from the url and try to set the page navigation
        let navPills = getUrlParameter('nav') || 0;

        if(navPills || navPills === 0) {

            navPills = parseInt(navPills)
            if(Number.isInteger(navPills) || navPills === 0) { this.setState({navPills }) }

        }

    }

    updateBlacklist = async () => {

        await _contacts.update(this.state.contact._id, { blacklisted: false });
        this.setData()

    }
        
    setData = async () => {

        this.setState({loaded: false});

        const found = await this.fetchContact();
        if(!found) return this.setState({shouldRedirect: '/dashboard/contacts/all'})

        // scroll to top of page
        window.scrollTo(0, 0);
        this.fetchContactNotes();
        this.fetchContactCallLogs();
        this.fetchContactVoicemail();
        ;
        this.fetchContactCases();
        this.fetchLeadSource();
        this.setNavigationByUrl();
       

    }

    fetchCollections = async (contact_id) => {
        const data = await _collections.findByContact(contact_id);
        if(data.data && data.data.length) {
            this.setState({inCollections: true})
        }
    }

    componentWillReceiveProps = async (nextProps) => {

        const contact_id = this.state.contact_id
        const nextContactId = await this.setContactId(nextProps)

        if(contact_id !== nextContactId) {
            this.setData(nextProps)
        }

    }

    componentWillUnmount = () => {

        window.removeEventListener('popstate', this.setNavigationByUrl);

    }

    componentDidMount = async () => {

        await this.setContactId(this.props);
        this.setData(this.props);

        window.addEventListener('popstate', this.setNavigationByUrl);

        const contact_id = this.getContactId(this.props);

        this.fetchCollections(contact_id)
        _analytics.events.create({event: ANALYTIC_EVENTS.CONTACTS_VIEWED_UNIQUE_BY_VALUE, value: contact_id});

    }

    render() {

        const { navPills, shouldScrollToRelationships, loaded, contact, call_logs } = this.state
        const { voicemail, cases, shouldRedirect, lead_source_entry, inCollections } = this.state

        if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div className="py-6"><Circle/></div>

        return (
            <div className="page-contact-view">

                {inCollections ? (
                    <div className="alert alert-danger alert-squared mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 " /> 
                        This contact is currently delinquent on payment.
                    </div>
                ) : null}

                {contact.important ? (
                    <div className="alert alert-success alert-squared mb-0">
                        <i className="fas fa-info-circle mr-2 " /> 
                        This file has been marked as a priority contact.
                    </div>
                ) : null}

                {contact.merged_to ? (
                    <Link to={`/dashboard/contacts/view/${contact.merged_to}`}>
                        <div className="alert alert-danger alert-squared mb-0">
                            <i className="fas fa-info-circle mr-2 " /> 
                            This file has been merged. Click here to view the updated file.
                        </div>
                    </Link>
                ) : null}

                {/* <Container fluid={this.props.fullWidth ? true : false} > */}
                <Container fluid >

                    {/* <iframe src="http://localhost:3006/calls" allow={`microphone ${keys.TALK_URL}`} title="calls" /> */}

                    <Helmet>
                        <title>{capitalize(`Contact: ${renderName(contact)}`)}</title>
                        <meta name="description" content="Cases Update" />
                    </Helmet>

                    <DashHeaderOpen
                        icon="fas fa-home"
                        icon_link="/dashboard"
                        title={<span >Contact</span>}
                        breadcrumb_1={<span className="text-capitalize">{contact._id ? renderName(contact) : 'New'}</span>}
                        breadcrumb_2={<span className="text-capitalize"><b>ID:</b> {contact._id}</span>}
                        actionComponent={(

                           <div style={{width: 250}} className="float-right text-left">
                                <ReactSelect
                                    placeholder="Contact Overview"
                                    onChange={(values) => this.toggleNavPills(values.value)}
                                    options={navigationOptions}
                                />
                           </div>
                        )}
                    />

                    <Blacklist 
                        updateBlacklist={this.updateBlacklist}
                        contact={contact}
                    />

                    <Overview
                        contact={contact}
                        toggleNavPills={(value) => this.toggleNavPills(value)}
                        navPills={navPills}
                        cases={cases}
                        lead_source_entry={lead_source_entry}
                        createCaseURL={createCaseURL}
                        setData={this.setData}
                    />

                    <Cases
                        contact={contact}
                        navPills={navPills}
                        onSelectNewContact={(_id) => this.onSelectNewContact(_id)}
                        scrollToRelationships={() => this.scrollToRelationships()}
                        createCaseURL={createCaseURL}
                    />

                    <Appointments
                        contact={contact}
                        navPills={navPills}
                    />

                    <Relationships
                        contact={contact}
                        navPills={navPills}
                        onSelectNewContact={(_id) => this.onSelectNewContact(_id)}
                        scrollToRelationships={() => this.scrollToRelationships()}
                    />

                    <ContactPayments
                        shouldShow={navPills === 4}
                        contact={contact}
                    />

                    <Update
                        contact={contact}
                        navPills={navPills}
                        shouldScrollToRelationships={shouldScrollToRelationships}
                        fetchContact={this.fetchContact}
                    />

                    <Feed
                        ignoreHeight={true}
                        case={{contact}}
                        dontShow={navPills !== 6}
                    />

                    <Forms
                        contact={contact}
                        navPills={navPills}
                    />

                    <CallLogs
                        contact={contact}
                        call_logs={call_logs}
                        voicemail={voicemail}
                        navPills={navPills}
                        onMarkVoicemailListened={this.onMarkVoicemailListened}
                    />
                    <Century
                        contact={contact}
                        navPills={navPills}
                    />

                    <StepsToPrepLaunch contact={contact} />

                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ContactView);
