import * as actionTypes from 'store/actions';
import store from 'store';

export const update = (objects, action, state) => {

    let objectsToUpdate = objects

    // //if no array is sent create an array out of single _id sent
    if(!(objects instanceof Array)) {
        objectsToUpdate = [objects]
    }

    let newStateToSet = getState(state)

    objectsToUpdate.forEach(u => {

        newStateToSet[newStateToSet.findIndex(el => el._id === u._id)] = u;

    })

    store.dispatch({
        type: actionTypes[action],
        payload: {
            data: newStateToSet,
            total_documents: newStateToSet.length
        }
    });

}

export const remove = (_ids, action, state) => {

    let objectsToRemove = _ids

    //if no array is sent create an array out of single _id sent
    if(!(_ids instanceof Array)) {
        objectsToRemove = [_ids]
    }

    let newStateToSet = getState(state)


    newStateToSet = newStateToSet.filter(u => objectsToRemove.includes(u._id) === true ? false: true )


    store.dispatch({
        type: actionTypes[action],
        payload: {
            data: newStateToSet,
            total_documents: newStateToSet.length
        }
    });

}

export const add = (objects, action, state) => {

    let objectsToUpdate = objects

    // //if no array is sent create an array out of single _id sent
    if(!(objects instanceof Array)) {
        objectsToUpdate = [objects]
    }

    let newStateToSet = getState(state)

    objectsToUpdate.forEach(u => {

        newStateToSet.unshift(u);

    })

    store.dispatch({
        type: actionTypes[action],
        payload: {
            data: newStateToSet,
            total_documents: newStateToSet.length
        }
    });

}

const getState = (state) => {

    let newStateToSet

    if(state.length === 1) {
        newStateToSet = [...store.getState()[state[0]]]
    } else if(state.length === 2) {
        newStateToSet = [...store.getState()[state[0]][state[1]]]
    }  else if(state.length === 3) {
        newStateToSet = [...store.getState()[state[0]][state[1]][state[2]]]
    } else if(state.length === 4) {
        newStateToSet = [...store.getState()[state[0]][state[1]][state[2]][state[3]]]
    }

    return newStateToSet

}
