import keys from 'keys';
import React, { Component } from 'react';
import { Col, Modal, Row, FormGroup, Input } from 'reactstrap';

import { formatMoney } from 'utils/currency';
import { connect } from 'react-redux'

import _invoices from '_functions/invoices';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const Item = (props) => {

    return (
        <Row className={props.gray === true ? 'bg-secondary' : null}>
            <Col xs={6}>
                <p className="text-sm mb-0">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.name}
                    </span>
                </p>
            </Col>
            <Col xs={6}>
                <p className="text-sm mb-0 text-right">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.value}
                    </span>
                </p>
            </Col>
        </Row>
    );

}

const ItemFee = (props) => {

    return (
        <Row className={props.gray === true ? 'bg-secondary' : null}>
            <Col xs={6}>
                <p className="text-sm mb-0 text-capitalize">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.name}
                    </span>
                </p>
            </Col>
            <Col xs={3}>
                <p className="text-sm mb-0 text-right text-capitalize">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.descriptor}
                    </span>
                </p>
            </Col>
            <Col xs={3}>
                <p className="text-sm mb-0 text-right text-capitalize">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.value}
                    </span>
                </p>
            </Col>
        </Row>
    );

}

class Preview extends Component {

    state = {
        confirmed: false
    }

    toggleModal = () => {

        this.setState({confirmed: false})
        this.props.toggle()

    }

    getDiscountText = (invoice) => {

        let d = invoice.discounts

        if(d && d.length) {

            if(d[0].type === 'percent') {
                return '-' + d[0].amount + '%'
            } else {
                return formatMoney(d[0].amount)
            }

        }

        return '-$0.00'

    }

    getInvoiceStatus = () => {

        const invoice = this.props.invoice
        const warnings = [];
        const errors = [];

        if(!invoice.name) {
            errors.push((
                <p className="text-danger text-sm mb-0 font-weight-bold">There is no name for this invoice.</p>
            ))
        }

        if(!invoice.expenses.length) {
            warnings.push((
                <p className="text-warning text-sm mb-0 font-weight-bold">There are no expenses for this invoice.</p>
            ))
        }
        if(!invoice.fees.length) {
            warnings.push((
                <p className="text-warning text-sm mb-0 font-weight-bold">There are no fees for this invoice.</p>
            ))
        }

        return {
            warnings,
            errors
        }

    }

    onSendInvoice = async () => {

        toggleStandardLoader(true);

        const invoice = this.props.invoice
        let sent_invoice;

        //if invoice was previously saved send it
        if(invoice._id) {

            const saved = await _invoices.update(invoice)

            if(saved.success) {

                sent_invoice = await _invoices.send(invoice._id, this.props.viewing_user._id)

            }

        } else {

            //if invoice was not previously saved create and send it
            sent_invoice = await _invoices.create({
                ...invoice,
                case: this.props.case._id,
                user: this.props.viewing_user._id,
                status: 'sent'
            })

        }

        toggleStandardLoader(false);

        if(sent_invoice && sent_invoice.success) {

            this.toggleModal();
            this.props.onInvoiceSent(sent_invoice)

        } else {

            toggleAlertBS(true, 'Something went wrong trying to send this invoice.')

        }

    }

    renderFee = (f, i) => {

        return (
            <ItemFee
                key={i}
                name={
                    f.description && f.description.includes('Uploaded the document') ?
                    'Uploaded document(s)' :
                    f.description.includes('href=') ?
                    'Uploaded document(s)' :
                    f.description

                }
                descriptor={f.hours}
                value={f.status === 'billable' ? formatMoney(f.amount) : '$0.00'}
            />
        )

    }


    render() {

        const { invoice, showModal }= this.props

        const discount_text = this.getDiscountText(invoice)

        const invoiceFees = formatMoney(_invoices.calculate.totalFees(invoice));
        const invoiceExpenses = formatMoney(_invoices.calculate.totalExpenses(invoice))
        const invoiceTotal = formatMoney(_invoices.calculate.total(invoice))
        const creditTotal = formatMoney(_invoices.calculate.totalCredits(invoice))
        const writeOffTotal = formatMoney(_invoices.calculate.totalWriteOffs(invoice))

        const invoiceStatus = this.getInvoiceStatus()

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
                size="lg"
                fade={false}
                id="modal-invoice"
                zIndex={1600}

            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Preview Invoice</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body bg-secondary">

                    <div id="invoice-preview" className="p-4 bg-white" style={styles.previewContainer}>

                        <Row className="mb-4 bg-primary p-4">

                            <Col xs={4}></Col>
                            <Col xs={4} className="align-self-center">
                                <img alt={keys.COMPANY_NAME} className="w-100" src={keys.LOGO} />
                            </Col>

                        </Row>


                        <Row className="mb-4">

                            <Col xs={4} className="align-self-center">
                                <p className="font-weight-bold text-sm mb-0">
                                    23756 Michigan Ave. Suite 309 <br />
                                    Dearborn, MI 48124
                                </p>
                            </Col>
                            <Col xs={4} className="align-self-center text-center">
                                <p className="font-weight-bold text-sm mb-0">
                                    T: 888-FAIRMAX<br />
                                    (888-324-7629)
                                </p>
                            </Col>
                            <Col xs={4} className="align-self-center text-right">
                                <p className="font-weight-bold text-sm mb-0">fairmaxlaw.com</p>
                            </Col>

                        </Row>

                        <Row className="mb-5">

                            <Col xs={6} className="align-self-center">
                                <p className="text-sm mb-0">
                                    Michael Jaafar<br />
                                    mike@fairmaxlaw.com
                                </p>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <p className="text-sm mb-0">
                                    Invoice #EA816Fe816 <br />
                                    Date: 11/12/2020
                                </p>
                            </Col>

                        </Row>

                        <h2>Billing Summary</h2>

                        <Item
                            bold={true}
                            gray={true}
                            name="Details"
                            value=""
                        />

                        <Item
                            bold={true}
                            gray={true}
                            name="Fees"
                            value=""
                        />

                        <Item
                            name="Total For Services Rendered"
                            value={invoiceFees}
                        />

                        <Item
                            name="Total Expense Cost"
                            value={invoiceExpenses}
                        />

                        <Item
                            bold={true}
                            gray={true}
                            name="Credits & Discounts"
                            value=""
                        />

                        <Item
                            name="Total Credits"
                            value={'-' + creditTotal}
                        />

                        <Item
                            name="Total Write Offs"
                            value={'-' + writeOffTotal}
                        />

                        <Item
                            name="Total Discount"
                            value= {discount_text}
                        />

                        <Item
                            bold={true}
                            gray={true}
                            name="Balance Due"
                            value={invoiceTotal}
                        />

                        <div className="my-5">
                            <hr className="mb-3 mt-0" />
                                <p className="text-sm mb-0">
                                    You may view your current balance and pay on line by clicking the link below:
                                </p>
                                <p className="text-sm mb-0 font-weight-bold">
                                    my.fairmaxlaw.com/invoice/#EA816Fe816
                                </p>
                            <hr className="mt-3 mb-0" />
                        </div>

                        {invoice.fees && invoice.fees.length ? (
                            <div className="my-5">

                                <h2>Services Rendered Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    descriptor="Hours"
                                    value="Amount"
                                />

                                {invoice.fees.map((f, i) => (
                                    f.status !== 'hidden' ? this.renderFee(f, i) : null
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    descriptor=""
                                    value={invoiceFees}
                                />

                            </div>
                        ) : null}

                        {invoice.expenses && invoice.expenses.length ? (
                            <div className="my-5">

                                <h2>Expense Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    descriptor="Cost"
                                    value="Total"
                                />

                                {invoice.expenses.map((f, i) => (
                                    <ItemFee
                                        key={i}
                                        name={f.description}
                                        descriptor={
                                            <span>
                                                ({f.quantity}) {formatMoney(f.amount)}
                                            </span>
                                        }
                                        value={formatMoney(f.total)}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    descriptor=""
                                    value={invoiceExpenses}
                                />

                            </div>
                        ) : null }

                        {invoice.credits && invoice.credits.length ? (
                            <div className="my-5">

                                <h2>Credit Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    value="Amount"
                                />

                                {invoice.credits.map((f, i) => (
                                    <ItemFee
                                        key={i}
                                        name={f.description}
                                        value={formatMoney(f.amount)}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    value={'-' + creditTotal}
                                />

                            </div>
                        ) : null }

                        {invoice.discounts && invoice.discounts.length ? (
                            <div className="my-5">

                                <h2>Discounts</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    value="Amount"
                                />

                                {invoice.discounts.map((f, i) => (
                                    <ItemFee
                                        key={i}
                                        name={f.description}
                                        value={discount_text}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    value={discount_text}
                                />

                            </div>
                        ) : null }


                        {invoice.write_offs && invoice.write_offs.length ? (
                            <div className="my-5">

                                <h2>Write Off Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    value="Amount"
                                />

                                {invoice.write_offs.map((f, i) => (
                                    <ItemFee
                                        key={i}
                                        name={f.description}
                                        value={formatMoney(f.amount)}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    value={'-' + writeOffTotal}
                                />

                            </div>
                        ) : null }

                    </div>

                    <div className="mt-5 bg-white p-4 py-6">
                        <div style={styles.previewContainer}>

                            {invoiceStatus.warnings.length ? (
                                <div>
                                    <h2>Double Check</h2>
                                    {invoiceStatus.warnings.map((w, i) => (
                                        <div key={i}>
                                            {w}
                                        </div>
                                    ))}
                                </div>
                            ) : null}

                            {invoiceStatus.errors.length ? (
                                <div>
                                    <h2>Invoice Errors</h2>
                                    {invoiceStatus.errors.map((w, i) => (
                                        <div key={i}>
                                            {w}
                                        </div>
                                    ))}
                                </div>
                            ) : (

                                <div>
                                    <div className="alert alert-success">This Invoice Is Ready To Send</div>

                                    <hr />

                                    <FormGroup className="ml-4 pl-2">

                                        <Input
                                            type="checkbox"
                                            id="invoice-preview-confirm"
                                            value={this.state.confirmed}
                                            onChange={() => this.setState({confirmed: !this.state.confirmed})}
                                        />
                                        <label className="form-control-label" htmlFor="invoice-preview-confirm">
                                            I have confirmed this invoice is correct.
                                        </label>
                                    </FormGroup>

                                    <button
                                        disabled={!this.state.confirmed}
                                        className="btn btn-outline-success"
                                        onClick={!this.state.confirmed ? null : this.onSendInvoice}
                                    >
                                        Send Invoice
                                    </button>

                                </div>

                            )}

                        </div>
                    </div>

                </div>
            </Modal>

        )

    }

}

const styles = {
    previewContainer: {
        maxWidth: 750,
        margin: 'auto',
        overflow: 'auto'
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(Preview);
