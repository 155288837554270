/*
Documentation

this component is a wrapper for the react-select npm package

It injects the main color on to the package as well as on optional ability to wrap it
in a form group with a title and description

*/

import PropTypes from 'prop-types'
import React from "react";
import Select from 'react-select';
import { FormGroup } from 'reactstrap'

class ReactSelect extends React.Component {

    render() {

        const { formGroup, title, description, invalid} = this.props

        const select = (
            <Select
                // menuIsOpen={true} // use for debugging
                menuPortalTarget={document.body} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    
                    colors: {
                        ...theme.colors,
                        primary: '#B6CF4E',
                    },
                })}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        borderBottom: 'solid 1px #ddd',
                        cursor: 'pointer'
                    }),
                    menuPortal: base => ({ ...base, zIndex: 999999 }),
                    menu: (provided, state) => ({
                        ...provided,
                        boxShadow: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
                    })
                }}
                className={invalid ? "invalid react-select cursor-pointer" : "react-select cursor-pointer"}
                {...this.props}
            />
        )

        if(formGroup) return (
            <FormGroup>
                {title ? <label className="form-control-label">{title}</label> : null}
                {description ? <p className="text-sm mb-0">{description}</p> : null}
                {select}
            </FormGroup>
        )

        return select
    }
}

ReactSelect.propTypes = {
    formGroup   : PropTypes.string,
    title       : PropTypes.string,
    description : PropTypes.string,
    invalid     : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default ReactSelect
