import React from 'react';

export default {

    create: {
        body: (
            <div>
                <h3>Court Creation</h3>
                <p>Courts allow you to organize your cases in relation to the court in which they will appear. Note that at least one (1) court must be created before a case can be made.</p>
                <p>The only required fields to create a court are:</p>
                <ol>
                    <li>The Court Name</li>
                    <li>The Court County</li>
                </ol>
            </div>
        ),

    }

}
