import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Input } from 'reactstrap';

import Filter from './Filter'
import Results from './Results'

import downloadCSV from './_utils/downloadCSV';
import * as _ from 'underscore';

const QueryCreator = ({title, inputs, maxDocuments, columns, runQuery, searchPlaceholder, sortOptions, hideSearch, hideCSV, actionComponent}) => {

    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(false)
    const [hasRun, setHasRun] = useState(false);

    const [state, setState] = useState({
        searchString: '',
        limit: maxDocuments || 15,
        documents: null,
        totalDocuments: 0,
        sortValue: '__false__',
        page: 1,
        err: false,
    })

    const onDownloadCSV = async (filter, newState) => {
        setLoading(true)
        const result = await runQuery({
            limit: newState.limit,
            searchString: newState.searchString,
            filter, 
            isCSV: true
        })
        setLoading(false)

        if(result) downloadCSV(result, 'exports');
    }

    const onStateChange = async (values, shouldQuery) => {
        const copy = JSON.parse(JSON.stringify(state));
        const newState = { ...copy, ...values };
        setState(newState);
        if(shouldQuery) onThrottledQuery(filter, newState)
    }


    const onQuery = async (filter, newState) => {
        setLoading(true)
        const skip = newState.limit * (newState.page - 1)
        const result = await runQuery({
            hasRun,
            limit: newState.limit,
            searchString: newState.searchString,
            filter, 
            sortValue: newState.sortValue,
            skip
        })
        setHasRun(true)
        setLoading(false)
        onResult(newState, result);
    }

    const onThrottledQuery = useCallback(_.throttle(onQuery, 750), [hasRun])

    const onResult = (newState, result) => {
        if(result) {
            onStateChange({
                ...newState,
                err: false,
                totalDocuments: result.total_documents,
                documents: result.documents,
                aggregation: result.aggregation,
            })
        } else {
            onStateChange({err: true});
        }
    }

    const onTableChange = async (data, settings) => {
        // we are changing pages
        if(state.limit === settings.sizePerPage) {
            onStateChange({ page: settings.page }, true)
        // we are changing size, set page to 1
        } else {
            onStateChange({ page: 1, limit: settings.sizePerPage }, true)
        }
    }

    const onSearchChange = async (searchString) => {
        onStateChange({ page: 1, searchString }, true)
    }

    const onFilterChange = (obj, value) => {
        const newState = JSON.parse(JSON.stringify(filter));
        newState[obj.field] = value;
        if(newState[obj.field] === '__delete__') delete newState[obj.field]
        setFilter(newState)
    }
    
    useEffect(() => {
        onThrottledQuery(filter, state)
    }, [])

    return (
        <div className="archk-query-creator">

            <div className="archk-query-creator-left bg-white" >

                <div className="archk-query-creator-section">
                    <h4 className="archk-query-creator-title">{title}</h4>
                </div>

                <div className="archk-query-creator-section">
                    <Filter onChange={onFilterChange} inputs={inputs} />
                </div>

                <div className="archk-query-creator-section text-right">
                    <button onClick={() => onThrottledQuery(filter, {...state, page: 1})} className="btn btn-block btn-outline-success">
                        <i className="fas fa-chart-line mr-2 " /> Run Report
                    </button>
                </div>

            </div>

            <div className="archk-query-creator-right">
                <div className="archk-query-creator-section bg-white">
                    <Row>
                        <Col lg={6} className="align-self-center">
                        <h4 className="archk-query-creator-title">Results</h4>
                        </Col>
                        <Col lg={6} className="align-self-center text-right">
                            {sortOptions ? (
                                <Input 
                                    style={{display: 'inline-block', width: 150}}
                                    className="mr-3"
                                    type="select"
                                    onChange={(e) => onQuery(filter, {...state, sortValue: e.target.value})}
                                    value={state.sortValue}
                                >
                                    <option value="__false__">Select Sort</option>
                                    {sortOptions.map((value, i) => <option key={i} value={value.value}>{value.name}</option>)}
                                </Input>
                            ) : null}
                            {actionComponent ? actionComponent : !hideCSV ? (
                                <button disabled={loading} onClick={() => onDownloadCSV(filter, state)} className="btn btn-outline-warning">
                                    <i className="fas fa-download mr-2 " /> CSV
                                </button>
                            ) : null}
                        </Col>
                    </Row>
                </div>

                {state.err ? (
                    <div className="alert alert-danger m-3">
                        <i className="fas fa-exclamation-triangle mr-2 " />
                        Something went wrong, please try again.
                    </div>
                ) : (
                    <Results 
                        hideSearch={hideSearch} 
                        columns={columns} 
                        onStateChange={onStateChange}
                        onTableChange={onTableChange}
                        onSearchChange={onSearchChange}
                        searchPlaceholder={searchPlaceholder}
                        loading={loading}
                        {...state}
                    />
                )}

            </div>

        </div>
    )

}

export default QueryCreator;