import React, { useState, useCallback } from 'react';
import { FormGroup, Input } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const ArchkDatePicker = ({label, onChange}) => {

    const [date, setDate] = useState('');
    const [queryType, setQueryType] = useState('Is Equal To');

    const onInputChange = useCallback((value, type) => {
        const formattedDate = parseInt(moment(value).format('X'));

        if(type === 'Is Equal To') {
            onChange(formattedDate)
        } else if(type === 'Is Greater Than') {
            onChange({$gt: formattedDate})
        } else if (type === 'Is Greater Than Or Equal To') {
            onChange({$gte: formattedDate})
        } else if(type === 'Is Less Than') {
            onChange({$lt: formattedDate})
        } else if (type === 'Is Less Than Or Equal To') {
            onChange({$lte: formattedDate})
        }

        setDate(value);
    }, [onChange])

    const onChangeQueryType = useCallback((type) => {
        setQueryType(type)
        if(date) onInputChange(date, type)
    }, [date, onChange])

    return (
        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input
                className="mb-2"
                type="select"
                value={queryType}
                onChange={(e) => onChangeQueryType(e.target.value)}
            >
                <option value="Is Equal To">Is Equal To</option>
                <option value="Is Greater Than">Is Greater Than</option>
                <option value="Is Greater Than Or Equal To">Is Greater Than Or Equal To</option>
                <option value="Is Less Than">Is Less Than</option>
                <option value="Is Less Than Or Equal To">Is Less Than Or Equal To</option>
            </Input>

            <div className="date-picker-wrapper">
                <DatePicker
                    selected={date}
                    onChange={(date) => onInputChange(date, queryType)}
                />
            </div>
        </FormGroup>
    )

}

export default ArchkDatePicker;
