import keys from 'keys'
import React from "react";
import {  Redirect, Route, Switch, Link } from "react-router-dom";
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import stackLogger from 'database/stackLogger';

import routes from "./routes";

class Exports extends React.Component {

    state = {
        loaded: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/exports${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    componentWillUnmount = () => {
        document.body.classList.remove('bg-white')
    }

    componentDidMount = async () => {
        document.body.classList.add('bg-white')
    }

    componentDidCatch = (e) => {

        console.log(e);
        
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        return (

            <div >

                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/" />
                </Switch>

                <div id="footer" className="footer-wrapper">
                    <Container fluid>
                        <footer className="footer">
                            <Row className="align-items-center justify-content-lg-between">

                                <Col lg="6">
                                    <div className="copyright text-center text-lg-left text-muted">
                                        © {new Date().getFullYear()}{' '}
                                        {keys.COMPANY}
                                        {' '}&mdash;{' '}
                                        <a className="font-weight-bold ml-1" href="https://architeck.io" rel="noopener noreferrer" target="_blank">
                                            Software Built By Architeck
                                        </a>
                                    </div>
                                </Col>

                                <Col lg="6">

                                    <Nav className="nav-footer justify-content-center justify-content-lg-end">

                                        <NavItem>
                                            <Link className="nav-link" to="/dashboard/settings">My Profile</Link>
                                        </NavItem>

                                        <NavItem>
                                            <Link className="nav-link" to="/dashboard/settings">Terms Of Service</Link>
                                        </NavItem>

                                        <NavItem>
                                            <a className="nav-link" href={keys.AUTH_URL + '/logout'}>Logout</a>
                                        </NavItem>

                                    </Nav>

                                </Col>

                            </Row>
                        </footer>

                    </Container>
                </div>

            </div>

        );
    }
}

export default Exports;