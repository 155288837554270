


import React from "react";
import { CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'

class ContactViewAddress extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    render() {

        const { contact } = this.props

        return (
            <>

            <CardHeader className="bg-secondary">
                <CardTitle className="mb-0">Billing Information</CardTitle>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col xs={4}>
                        <h5>Address Line 1</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_address_line_1 ? contact.billing_address_line_1 : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Address Line 2</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_address_line_2 ? contact.billing_address_line_2 : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>City</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_city ? contact.billing_city : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>State</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_state ? contact.billing_state : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Postal Code</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_postal_code ? contact.billing_postal_code : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

                <Row>
                    <Col xs={4}>
                        <h5>Country</h5>
                    </Col>
                    <Col xs={8}>
                        <h5 className="font-weight-normal">
                        {contact.billing_country ? contact.billing_country : '-'}
                        </h5>
                    </Col>
                </Row>

                <hr className="my-2"/>

            </CardBody>

            </>
        );
    }
}

ContactViewAddress.propTypes = {
    contact: PropTypes.object.isRequired
}

export default ContactViewAddress
