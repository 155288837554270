import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import renderName from 'utils/renderName';
import _cases from '_functions/cases';
import Circle from 'components/markup/loading/Circle';

const { SearchBar } = Search;

class ContactSearch extends React.Component {

    state = {
        page: 1,
        sizePerPage: this.props.sizePerPage ? this.props.sizePerPage : 5,
        total_documents: 0,
        data: null,
        caseToDelete: null
    }

    lastSearch = Math.floor(new Date())
    searchInterval;

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const cases = await _cases.search({
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: cases.data,
            total_documents: cases.total_documents,
            canRun: false,
            loading: false
        })


    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.lastSearch = Math.floor(new Date())

            // if we are running an interval to check when to search clear the old one
            if(this.searchInterval) clearInterval(this.searchInterval)

            // set interval to see if we are typing every .5 seconds
            this.searchInterval = setInterval(() => {

                const now =  Math.floor(new Date())

                // if it has been .5 seconds query the data
                if(now >= this.lastSearch + 500) {
                    clearInterval(this.searchInterval)
                    this.queryTableData(searchText, true)
                }

            }, 500)


        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableData(searchText)
            })

        }

    }

    onDeleteContact = () => {

        this.onTableChange(null, this.state)

    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => {

                if(row.searchPrompt) return row.searchPrompt

                return (
                    <span onClick={() => this.props.onSelect(row)} className="text-capitalize">
                        <div className="text-capitalize font-weight-bold"><i className="fas fa-user mr-2" />{row.name}</div>
                        <div className="text-muted"><i className="fas fa-user mr-2" /> {renderName(row.contact)}</div>
                        <div className="text-muted"><i className="fas fa-phone mr-2" /><b>Identifier</b> {row._id}</div>
                        <div className="text-muted"><i className="fas fa-phone mr-2" /><b>Case ID</b> {row.case_id ? row.case_id : '-'}</div>
                    </span>
                )
            },
            filterValue: (cell, row) => row.given_name + ' ' + row.family_name
        },

        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {

                if(row.searchPrompt) return null

                return (
                    <div className="text-right">
                        <button onClick={() => this.props.onSelect(row)} className="btn btn-success">
                            Select
                        </button>
                    </div>
                )
            }
        },
    ]

    render() {

        const { data, loading } = this.state;

        return (

            <ToolkitProvider
                data={data ? data : [{_id: 'test', searchPrompt: 'Type above to search for a case.'}]}
                keyField="_id"
                columns={this.columns}
                search
            >
                {props => (
                    <div className="position-relative">

                        <div className="dataTables_filter pr-4 pb pt-3 pb-2 pl-3" >
                            <label>
                                Search:
                                <SearchBar
                                    className="form-control-sm"
                                    placeholder="Name, Identifier, ID"
                                    {...props.searchProps}

                                />
                                {loading ? ( <Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}
                            </label>
                        </div>

                        <div className="table-not-fixed table-responsive table-vertical-align">
                            <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory({
                                    totalSize: this.state.total_documents,
                                    page: this.state.page,
                                    sizePerPage: this.state.sizePerPage,
                                    alwaysShowAllBtns: true,
                                    showTotal: false,
                                    withFirstAndLast: true,
                                    sizePerPageRenderer: () => ( false )
                                })}
                                bootstrap4={true}
                                bordered={false}
                                remote={{
                                    search: true,
                                    pagination: true,
                                    sort: false,
                                    cellEdit: false
                                }}
                                onTableChange={this.onTableChange}

                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>

        );
    }
}

export default ContactSearch
