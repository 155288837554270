/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle, Input, FormGroup } from "reactstrap";
import renderName from 'utils/renderName';
import _cases from '_functions/cases';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';
import moment from 'moment'

import * as privileges from '_functions/users/privileges'

class CasesAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            caseToDelete: null
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }


    findUser = (user_id) => {
        const foundUser = this.props.users.find(user => user_id === user._id);
        return foundUser ? foundUser : {}
    }

    columns = [
        {
            dataField: "case name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <Link 
                        className="text-capitalize text-primary font-weight-bold" 
                        to={`/dashboard/cases/view/${row._id}`}
                    >
                        {row.name} 
                    </Link>
                </div>
            ),
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                <div>
                    {row.contact ? (
                         <Link 
                            className="text-capitalize text-primary font-weight-bold" 
                            to={`/dashboard/contacts/view/${row.contact._id}`}
                        > 
                            {renderName(row.contact)} 
                        </Link>
                    ) : 'NOT FOUND'}
                </div>
            ),
        },
        {
            dataField: "type",
            text: "Workflow",
            formatter: (cell, row) =>  <span className="text-capitalize">{row.type}</span>

        },
        {
            dataField: "current_step",
            text: "Step",
            formatter: (cell, row) => {

                if(row.finished_by) return <span className="text-success font-weight-bold">FINISHED</span>
                return row.current_step ? row.current_step.name : <span className="text-warning font-weight-bold">No Assigned Step</span>

            }
        },
        {
            dataField: "_id",
            text: "CASE ID",
        },
        {
            dataField: "case_number",
            text: "Case NUMBER",
            formatter: (cell, row) => row.case_number ? row.case_number : '-'
        },
        {
            dataField: "created_at",
            text: "created",
            formatter: (cell, row) => moment.unix(row.created_at).format('MMM Do, YYYY')
        },
        {
            dataField: "updated_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/cases/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        {privileges.canDeleteCases() && (
                            <i onClick={() => this.setState({caseToDelete: row})} className="fas fa-trash text-danger"></i>
                        )}
                    </div>
                )
            }
        },

    ]

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const cases = await _cases.search({
            step_category: this.state.category && this.state.category !== 'false' ? this.state.category : undefined,
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: cases.data,
            total_documents: cases.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteCase = (_case) => this.onTableChange(null, this.state)

 
    render() {

        const caseToDelete = this.state.caseToDelete;
        let { data, loading, searchText} = this.state;

        return (
            <>

            {caseToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/cases/delete/${caseToDelete._id}`}
                    confirmBtnText="Delete Case"
                    title={<span className="text-capitalize">{`Delete ${caseToDelete.name}`}</span>}
                    text="Deleting this case will remove it from being able to be accessed again. Proceed With Caution!"
                    onClose={() => { this.setState({caseToDelete: null}) }}
                    onSuccess={this.onDeleteCase}
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Cases (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Cases" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Cases</span>} breadcrumb_1="All"
                    actionComponent={privileges.canUpdateCases() ? (
                        <>
                            <Link  to="/dashboard/cases/all" className="btn btn-info"><i className="fas fa-arrow-left " /> All</Link>
                            <Link  to="/dashboard/cases/create" className="btn btn-success">Create Case</Link>
                        </>
                    ): null}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Cases</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup className="mb-0">
                            <label className="form-control-label">Select Step Category</label>
                            <Input 
                                type="select"
                                value={this.state.category}
                                onChange={(e) => this.setState({category: e.target.value}, () => this.queryTableData())}
                            >
                                <option value="false"></option>
                                <option value="pre-filing">Not Filed</option>
                                <option value="post-filing">Filed</option>
                            </Input>
                        </FormGroup>
                    </CardBody>

                    {!this.state.category || this.state.category === 'false' ? (
                         <CardBody>
                         Please select a case category above
                     </CardBody>
                    ) : data ? (
                        data.length || searchText !== undefined ? (
                                <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                Search:
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText || ''}
                                                    placeholder="Name, ID, Number"
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value)
                                                    }}
                                                />
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0">
                                    No cases have been created yet.
                                    <Link to="/dashboard/cases/create">
                                        <b className="text-warning"> Click here to create one.</b>
                                    </Link>
                                </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CasesAll);