/*
Documentation

the key field of the table is the recording_sid
this will be unique from twilio, on updating the listened property
we change this to a timestamp to force an update in the table

*/

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { connect } from 'react-redux';
import Dots from 'components/markup/loading/Dots';
import React from "react";
import { Row, Col, CardBody, Card, CardHeader, CardTitle, Container, CardFooter, Badge } from "reactstrap";
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import _voicemail from "_functions/voicemail";
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';

import { formatPhone } from 'utils/text';
import { secondsToTime } from 'utils/time';
import renderName from 'utils/renderName'

import AvatarImage from 'components/functional/images/AvatarImage';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

const { SearchBar } = Search;


class Voicemail extends React.Component {

    state = {
        show: 'unlistened',
        voicemail: null,
        allVoicemail: null,
        contact: {},
        filter: 'all'
    }

    columns = [
        {
            dataField: "contact",
            text: "Contact",
            filterValue: (cell, row) => renderName(row.contact),
            formatter: (cell, row) => (
                row.contact ? (
                    <Link to={`/dashboard/contacts/view/${row.contact._id}`}>
                        <div>{renderName(row.contact)}</div>
                        <div>{formatPhone(row.contact.phone)}</div>
                    </Link>
                ) : '-'
                
            )
        },
        {
            dataField: "contact.assigned_user",
            text: "Assigned User",
            formatter: (cell, row) => (
                row.contact && row.contact.assigned_user ? (
                <div className="text-capitalize">
                    <AvatarImage src={row.contact.assigned_user.avatar} className="mr-2" />
                    {renderName(row.contact.assigned_user)}
                </div>
                ) : (
                    '-'
                )
            )
        },
        
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => (
                <>
                    <div>{moment.unix(row.created_at).format('MMM Do, YYYY - h:mm A')}</div>
                    <div>{secondsToTime(row.recording_duration)}</div>
                </>
            )
        },
        {
            dataField: "recording_url",
            text: "Audio",
            headerStyle: {width: 300},
            formatter: (cell, row) => (
                <audio style={{width: '100%'}} controls controlsList="nodownload" src={row.recording_url+ '.mp3?Download=false'}>
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            )
        },

        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        {row.listened ? (
                            <button 
                                style={{width: 140}}
                                onClick={() => this.onMarkListened(row._id, false)} 
                                className="btn btn-outline-success "
                            >
                                Mark Un-Listened
                            </button>
                        ) : (
                            <button 
                                style={{width: 140}}
                                onClick={() => this.onMarkListened(row._id, true)} 
                                className="btn btn-outline-warning "
                            >
                                Mark Listened
                            </button>
                        )}
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });


    clear = () => {

        // hideContactSearch is used to refresh the search input 
        // and how it shows old searches as the default
        this.setState({contact: {}, hideContactSearch: true}, () => {
            this.onQuery()
            this.setState({hideContactSearch: false})
        })
        
    }

    onQuery = async () => {

        const { contact } = this.state

        const contact_id = contact && contact._id ? contact._id : null;
        const query = contact_id ? `?filter=contact__${contact_id}` : `?filter=listened__false{bool}`

        const voicemail = await _voicemail.find(query);
        if(voicemail.success) this.setState({voicemail: voicemail.data})

    }

    onSelectContact = async (values) => {

        this.setState({contact: values})

    }

    onMarkListened = (_id, listened) => {

        _voicemail.update(_id, { listened })

        let voicemail = [...this.state.voicemail]

        const index = voicemail.findIndex(v => v._id === _id)
        voicemail[index].listened = listened
        voicemail[index].recording_sid = Math.floor(new Date() / 1)

        this.setState({voicemail})


    }

    listenForVoicemailAdded = (data) => {

        if(data && data.data && this.state.voicemail ) {

            let voicemail = [...this.state.voicemail]
            voicemail.push(data.data)
            
            this.setState({voicemail})

        }

    }

    componentWillUnmount = () => {

        io.off(socketEvents.voicemail_added, this.listenForVoicemailAdded)

    }

    componentDidMount = async () => {

        const voicemail = await _voicemail.find(`?filter=listened__false{bool}`);
        if(voicemail.success) this.setState({voicemail: voicemail.data, allVoicemail: voicemail.data})

        io.on(socketEvents.voicemail_added, this.listenForVoicemailAdded)

    }

    setFilter = (filter) => {

        const { allVoicemail } = this.state;

        if(!allVoicemail) return;

        if(filter === 'all') return this.setState({voicemail: [...allVoicemail], filter});

        const voicemail = allVoicemail.filter(v => {
            if(filter === 'open') {
                if(!v.contact) return true;
                if(!v.contact.assigned_user) return true;
            } else if(filter === 'assigned') {
                if(v.contact && v.contact.assigned_user && v.contact.assigned_user._id === this.props.viewing_user._id) {
                    return true;
                }
            }

            return false;
        })

        this.setState({filter, voicemail})
    }

    render() {

        const { voicemail, contact, hideContactSearch, filter } = this.state

        return (

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Voicemail</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link  to="/dashboard/call_center" className="btn btn-success"><i className="fas fa-arrow-left " /> Call Center</Link>
                    )}
                />


                <Row>

                    <Col lg={9}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">New Voicemail</CardTitle>
                            </CardHeader>

                            {voicemail ? (

                                <ToolkitProvider
                                    data={voicemail}
                                    keyField="recording_sid"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                            <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                                <Row>
                                                    <Col lg={6} className="align-self-center">
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder="Contact Name"
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </Col>
                                                    <Col lg={6} className="align-self-center text-right">
                                                        <p className="text-sm">
                                                            <Badge 
                                                                onClick={() => this.setFilter('all')} 
                                                                style={{minWidth: 80}} 
                                                                color={filter === 'all' ? 'success' : `info`} 
                                                                className="cursor-pointer text-sm ml-3"
                                                            >
                                                                All
                                                            </Badge>
                                                            
                                                            <Badge 
                                                                onClick={() => this.setFilter('assigned')} 
                                                                style={{minWidth: 80}} 
                                                                color={filter === 'assigned' ? 'success' : `info`} 
                                                                className="cursor-pointer text-sm ml-3"
                                                            >
                                                                Assigned
                                                            </Badge>

                                                            <Badge 
                                                                onClick={() => this.setFilter('open')} 
                                                                style={{minWidth: 80}} 
                                                                color={filter === 'open' ? 'success' : `info`} 
                                                                className="cursor-pointer text-sm ml-3"
                                                            >
                                                                Open
                                                            </Badge>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className=" table-responsive table-vertical-align">
                                                <BootstrapTable
                                                    pagination={this.pagination}
                                                    {...props.baseProps}
                                                    bootstrap4={true}
                                                    bordered={false}
                                                />
                                            </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                                ) : (

                                <CardBody>
                                    <p className="text-sm">Loading Voicemail <Dots /></p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                    <Col lg={3}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Search Voicemail</CardTitle>
                            </CardHeader>

                            <CardBody>
                                {hideContactSearch ? null : (
                                    <ContactSearchInput
                                        inputID="associate-contact-input"
                                        placeholder=" "
                                        value={contact._id ? contact : {}}
                                        onSelect={this.onSelectContact}
                                    />
                                )}
                            </CardBody>

                            <CardFooter className="text-right">
                                {contact && contact._id ? 
                                    <button onClick={this.clear} className="btn btn-warning">Clear</button> 
                                : null}
                                <button onClick={this.onQuery} className="btn btn-success">Search</button>
                            </CardFooter>

                        </Card>
                    </Col>


                </Row>
              
            </Container>

        );
    }
}

Voicemail.propTypes = {
    offCallUsers: PropTypes.array,
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        device: state.device,
        browser_dialing_status: state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(Voicemail);

