/*
Documentation

Shows all notifications to a user

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import reactHTMLParser from 'react-html-parser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import renderName from 'utils/renderName';
import _user_notifications from '_functions/user_notifications';
import Circle from 'components/markup/loading/Circle'

const { SearchBar } = Search;
const limit = 500;

class NotificationsView extends React.Component {

    state = {
        user_notifications: null,
        returned_documents: 0
    }

    renderMessage = (message) => {

        if(!message) return ''

        message = message.replace(/<br \/>/g, ' ')

        return reactHTMLParser(message)

    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            filterValue: (cell, row) => renderName({given_name: row.contact_name}).toLowerCase(),
            formatter: (cell, row) => (
                row.contact ? (
                    <Link  to={`/dashboard/contacts/view/${row.contact._id}`} className="text-capitalize font-weight-bold no-highlight">
                        {renderName(row.contact)}
                    </Link>
                ) : 'SYSTEM'
            )
        },
        {
            dataField: "title",
            text: "Title",
        },
        {
            dataField: "message",
            text: "Message",
            formatter: (cell, row) => {

                const content = <div style={{whiteSpace: 'pre-line'}}>{this.renderMessage(row.message)}</div>

                return row.link ? (
                    <Link className="d-block no-highlight" to={row.link}>{content}</Link>
                ) : (
                    content
                )
            }
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i onClick={() => this.onDelete(row)} className="fas fa-trash text-danger cursor-pointer" />
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onDelete = (notification) => {

        _user_notifications.delete(notification._id)

        let user_notifications = [...this.state.user_notifications]
        user_notifications = user_notifications.filter(n => n._id !== notification._id);

        this.setState({user_notifications})

    }

    listenForContactNotificationsAdded = (data) => {

        let notification = data.data

        if(notification.user === this.props.viewing_user._id) {

            let user_notifications = this.state.user_notifications ? [...this.state.user_notifications] : []
            user_notifications.unshift(notification)

            this.setState({user_notifications})

        }

    }

    componentWillUnmount = () => {
        io.off(socketEvents.user_notifications_added, this.listenForContactNotificationsAdded)
    }

    componentDidMount = async () => {

        const user_notifications = await _user_notifications.find(`?filter=user__${this.props.viewing_user._id}&sort=created_at__desc&populate=contact&limit=${limit}`);

        if(user_notifications.data) this.setState({user_notifications: user_notifications.data, returned_documents: user_notifications.returned_documents})

        io.on(socketEvents.user_notifications_added, this.listenForContactNotificationsAdded)

    }

    render() {

        const { user_notifications, returned_documents } = this.state;

        if(!user_notifications) return <div className="py-6"><Circle /></div>


        return (
            <>

            <Container fluid>

                <Helmet>

                    <title>{`Alerts: (${user_notifications.length})`}</title>
                    <meta name="description" content="Notifications" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Notifications</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link  to="/dashboard/notifications/settings" className="btn btn-success">
                            <i className="fas fa-cogs mr-2" /> Settings
                        </Link>
                    )}
                />

                {returned_documents >= limit ? <div className="alert alert-warning">You have more than {limit} notifications, remove unwanted notification to view older ones.</div> : null}

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Notifications</CardTitle>
                        <i
                            className="fas fa-question-circle float-right hc-launch"
                        />
                    </CardHeader>

                    {user_notifications && user_notifications.length ? (

                        <ToolkitProvider
                            data={user_notifications}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                 <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Name, Title, Message"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align table-notifications">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            <p className="text-sm mb-0">You do not have any current notifications.</p>
                        </CardBody>

                    )}
                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(NotificationsView);

