import PropTypes from 'prop-types'
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Badge, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import Circle from 'components/markup/loading/Circle';

class ContactPayments extends React.Component {

    state = {
        payments: null
    }

    columns = [
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => (
                <span>
                    {moment.unix(row.created_at).format('MMMM Do, YYYY')} <br />
                    {moment.unix(row.created_at).format('h:mm A')}

                </span>
            ),
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return formatMoney(row.transaction_amount)
            }
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.type}</span>
            )
        },
        {
            dataField: "account_type",
            text: "Method",
            formatter: (cell, row) => (
               row.account_type ? (
                <span>
                    <b>{_payments.cards.getName(row.account_type)}{' '}</b> <br />
                    {row.first_six.slice(0,1)}...{row.last_four}
                </span>
               ) : 'Cash'
            )
        },
        {
            dataField: "invoice",
            text: "Invoice ID",

        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {

                let markup;

                if(row.status === 'succeeded') {
                    markup = <Badge className="text-sm" color="success">Succeeded</Badge>
                } else if (row.status === 'failed') {
                    markup = <Badge className="text-sm" color="danger">Failed</Badge>
                } else if (row.status === 'voided') {
                    markup = <Badge className="text-sm" color="danger">Voided</Badge>
                } else if (row.status === 'authorized') {
                    markup = <Badge className="text-sm" color="warning">Authorized</Badge>
                } else {
                    markup = <Badge className="text-sm" color="warning">Refunded</Badge>
                }

                return (
                    <div className="text-right"> {markup} </div>
                )

            }
        },

    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    componentDidMount = async () => {

        const payments = await _payments.findByContact(this.props.contact._id)

        if(payments.success) { this.setState({payments: payments.data.payments}) }

    }

    render() {

        const { shouldShow } = this.props
        const { payments } = this.state

        if(!shouldShow) return null
        if(!payments) return <div className="py-6"><Circle /></div>


        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Contact Payments</CardTitle>
                </CardHeader>

                    { payments.length ? (
                        <ToolkitProvider
                            data={payments}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <div className="table-not-fixed table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <CardBody>
                            <p className="text-sm text-warning font-weight-bold mb-0">
                                There are no found payments for this contact.
                            </p>
                        </CardBody>
                    )}

            </Card>

        );
    }
}

ContactPayments.propTypes = {
    contact: PropTypes.object.isRequired
}

export default ContactPayments
