/*
Documentation

@param events
an array of both case_workflow_tasks and appointments

@param startOfDay
a number that represents the start of the day we are looking to filter events for

*/
export const returnEventsForADay = (events, startOfDay) => {

    const endOfDay = startOfDay + 86399;

    if(!events) {
        return []
    }
    const toReturn = events.filter(e => {

        return  e.date >= startOfDay && e.date < endOfDay ? true : false


    })

    return toReturn

}

export const getEventsBetweenInterval = (events, startTime,  endTime) => {

    if(!events) {
        return []
    }

    events = [...events]

    const toReturn = events.filter(e => {


        if(e.date  < endTime && e.date >= startTime) {

            return true

        }

        else if(e.date_end >= startTime && e.date < (startTime )) {

            return true

        }

        return   false

    })

    return toReturn

}


//this needs a test
export const getAppAndEventsForADay = (week_events_array, startTime, i) => {

    const day_start = startTime + (86400 * (i -1))

    const appointments = week_events_array.filter(e => {

        return e.date >= day_start && e.date < day_start + 86399

    })

    return {appointments}

}
