import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup, Input } from 'reactstrap';

class CanvasModalName extends React.Component {

    state = {
        parties: this.props.parties
    }

    onSave = () => {
        this.props.togglePartyModal();
        this.props.onPartyChange(this.state.parties)
    }

    componentWillReceiveProps = (nextProps) => {
        if(!this.props.showModalParties && nextProps.showModalParties) {
            this.setState({parties: this.props.parties})
        }
    }


    render() {

        const { showModalParties, togglePartyModal } = this.props
        const { parties } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalParties}
                toggle={togglePartyModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Document Parties</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={togglePartyModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <FormGroup>
                        <label className="form-control-label">How many parties can sign this document</label>
                      
                        <Input 
                            type="select"
                            value={parseInt(parties)}
                            onChange={(e) => this.setState({parties: parseInt(e.target.value)})}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </Input>
                        <p className="text-sm mt-3 mb-0"><i className="fas fa-info-circle text-info-original mr-2" />Note that if more than 1 party is selected to sign this document initials will not be placed on each page even if set to true.</p>
                    </FormGroup>

                </div>

                <div className="modal-footer">
                    <button onClick={this.onSave} className="btn btn-outline-success">Save</button>
                </div>

            </Modal>

        );
    }
}

CanvasModalName.propTypes = {
    showModalParties    : PropTypes.bool.isRequired,
    togglePartyModal    : PropTypes.func.isRequired,
    onPartyChange       : PropTypes.func.isRequired,
    parties             : PropTypes.number,
}

export default CanvasModalName
