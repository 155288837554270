/*
Documentation

this component shows only notes inside the case feed
it also allows emails to be sent

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactQuill from "react-quill";
import { connect } from 'react-redux';
import { CardHeader } from "reactstrap";
import _contact_notes from '_functions/contact_notes';
import { canUpdateCases } from '_functions/users/privileges';
import NoteEdit from './NoteEdit';
import RenderNote from './Render/Note';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

import _recent_activity from '_functions/recent_activity';

const required_form_fields = [
    'value',
]

class Feed extends Component {

    state = {
        contactNoteEntry    : {},
        canSave             : true,
        reactQuillText      : "",
        noteToEdit          : {},
        showModal           : false

    };

    toggleModal = (bool, note) => this.setState({showModal: bool, noteToEdit: note})

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            contactNoteEntry: { ...this.state.contactNoteEntry, value }
        });
    };

    onCreateNote = async () => {

        this.setState({canSave: false, phoneError: false, emailError: false, emailErrorBody: false})

        let newState = Object.assign({}, this.state.contactNoteEntry);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ contactNoteEntry: newState });

        if(!errors) {

            const note_to_push = {
                value: newState.value,
                contact: this.props.case.contact._id,
                user: this.props.viewing_user._id,
            }

            await _contact_notes.create(note_to_push);

            _analytics.events.create({event: ANALYTIC_EVENTS.NOTES_CREATED});

        }

        this.setState({
            canSave: true,
            reactQuillText: '',
            contactNoteEntry:   {},
        })

    }

    //this function takes the state feed array and filters it by type equal to the filter value
    filterFeed = (feed ) => {

        // get only contact notes but do not get any ones that are just logging if someone had a call with a client
        if(feed && feed.length ) { feed = feed.filter(f => f.type === 'contact_note' && f.value && !f.value.includes('Talked with contact via phone at: ')) }
        return feed

    }

    componentWillUnmount = () => {

        const { reactQuillText } = this.state
        
        if(reactQuillText && reactQuillText !== '<p><br></p>') {
            _recent_activity.update({
                title: 'Left Note As Draft', 
                value: reactQuillText
            })
        }
    }

    render() {

        if(this.props.view !== 'contact_notes') return null

        let { feed, showFeedEntries, renderNameAndInitial } = this.props
        const { noteToEdit, showModal, reactQuillText } = this.state

        const canUpdate = canUpdateCases();

        if(!feed) feed = []
        feed = this.filterFeed(feed)
        feed = feed.slice(0, showFeedEntries)

        return (

        <>

            <CardHeader>

                <div className="react-quill-toolbar-hide">
                    <ReactQuill
                        value={reactQuillText}
                        onChange={this.handleReactQuillChange}
                        placeholder="My Note..."
                        theme="snow"
                        onPaste={() => console.log('you pasted something')}
                        modules={{
                            toolbar: []
                        }}
                    />
                </div>

                <div className="text-right">
                    <button
                        disabled={!canUpdate ? true: this.state.canSave ? false : true}
                        onClick={ canUpdate ? () => this.onCreateNote() : null}
                        className="btn btn-s btn-success"
                    >
                        Create Note
                    </button>
                </div>

            </CardHeader>

            <div id="feed-wrapper" className="feed-wrapper table-responsive" style={{overflowY: 'auto'}}>

                <table className="table" >
                    <tbody>

                        { feed && feed.length ? (
                            feed.map((entry, i) => <RenderNote key={i} renderNameAndInitial={renderNameAndInitial} toggleModal={this.toggleModal} entry={entry} /> )
                        ) : feed ? (

                            <tr>
                                <td style={{whiteSpace: 'normal'}}>
                                    <div className="item-wrapper">
                                        <h6 className="text-sm mb-0 font-weight-bold">
                                            SYSTEM
                                        </h6>
                                        There are no notes associated with this contact
                                    </div>
                                </td>
                            </tr>

                        ) : null}

                    </tbody>
                </table>

            </div>

            <NoteEdit 
                showModal={showModal}
                toggleModal={() => this.toggleModal(false, {})}
                note={noteToEdit}
            />

        </>
        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

Feed.propTypes = {
    feed                : PropTypes.array.isRequired,
    case                : PropTypes.object.isRequired,
    toggleFullScreen    : PropTypes.func.isRequired,
    isFullScreen        : PropTypes.bool.isRequired,
    showFeedEntries     : PropTypes.number.isRequired,
    view                : PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(Feed);
