/*
Documentation

this file renders the modal to edit a subscription

*/

import React, { Component } from 'react';
import { Modal , Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import moment from 'moment';
import { formatMoney } from 'utils/currency';
import CreatePaymentPlan from './CreatePaymentPlan'
import _payments from '_functions/payments'
import PropsTypes from 'prop-types';
import { toggleStandardLoader } from 'store/functions/system/system'

class EditSubscriptionModal extends Component {

    state = {
        showNewPaymentPlan: false
    }

    toggleShowNewPaymentPlan = () => this.setState({showNewPaymentPlan : !this.state.showNewPaymentPlan})

    onDropRecurrings = async () => {

        toggleStandardLoader(true);

        await _payments.recurrings.drop(this.props.recurring.case)

        this.props.onRecurringDeleted()
        this.props.toggle()
        toggleStandardLoader(false);

    }

    componentWillReceiveProps = (nextProps) => {

        //when opening the modal always show the default state
        if(nextProps.showEditSubscriptionModal === true && !this.props.showEditSubscriptionModal) {
            this.setState({showNewPaymentPlan: false})
        }

    }


    render() {

        const { contact, showEditSubscriptionModal, toggle, account_vaults, invoices } = this.props
        const _case = this.props.case;
        const recurring = this.props.recurring;

        const showNewPaymentPlan = this.state.showNewPaymentPlan

        return (

            <Modal
                className="modal-dialog-centered edit-subscription-modal"
                isOpen={showEditSubscriptionModal}
                toggle={() => toggle()}
                size="lg"
                fade={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title text-capitalize">Edit {contact.given_name}'s Payment Plan</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <Card>

                        <CardHeader>
                            <CardTitle className="mb-0 text-capitalize">Case: {_case.name}</CardTitle>
                        </CardHeader>

                        <div className="table-responsive">
                            <table className="table">

                                <tbody>

                                    <tr>
                                        <td>Amount</td>
                                        <td className="text-right">
                                        {formatMoney(recurring.transaction_amount)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Runs</td>
                                        <td  className="text-right">
                                            {_payments.format.intervalRuns(recurring.interval)}
                                            {_payments.format.intervalType(recurring.interval_type)}
                                            {parseInt(recurring.interval) === 3 ? 's' : null}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Next Payment</td>
                                        <td  className="text-right">
                                            {moment.unix(recurring.next_run_date).format('MMMM Do, YYYY')}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Payment Method</td>
                                        <td  className="text-right">
                                        {_payments.format.paymentMethod(recurring.payment_method)}

                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </Card>

                    {showNewPaymentPlan ? (

                        <CreatePaymentPlan
                            invoices={invoices}
                            recurring={recurring}
                            case={_case}
                            account_vaults={account_vaults}
                            onSuccess={this.props.toggle}
                        />

                    ) : (

                        <>

                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Change Payment Plan</CardTitle>
                            </CardHeader>

                            <CardBody>

                                <p className="text-sm">
                                    This action will cancel the current recurring payment plan and create a new one. This is required when changing an active subscription.
                                </p>

                                <div className="text-rigt">
                                    <button
                                        onClick={this.toggleShowNewPaymentPlan}
                                        className="btn btn-danger"
                                    >
                                        <i className="fas fa-exclamation-triangle mr-2" />
                                        Change Payment Plan
                                    </button>
                                </div>

                            </CardBody>

                        </Card>

                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">
                                    <b className="text-danger">DANGER ZONE! </b>
                                    Delete Plan
                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <p className="text-sm">
                                    This action will cancel the current recurring payment plan and stop all future payment from being taken out. You may create a new one at any time after cancelling the current plan.
                                </p>

                                <div className="text-rigt">
                                    <button
                                        style={{width: 170}}
                                        onClick={this.onDropRecurrings}
                                        className="btn btn-outline-danger"
                                    >
                                        <i className="fas fa-exclamation-triangle mr-2" />
                                        Delete Plan
                                    </button>
                                </div>

                            </CardBody>

                        </Card>

                        </>
                    )}

                </div>

            </Modal>

        )

    }

}

// case, contact, showEditSubscriptionModal, toggle, account_vaults

EditSubscriptionModal.propTypes = {
    recurring                   : PropsTypes.object.isRequired,
    case                        : PropsTypes.object.isRequired,
    contact                     : PropsTypes.object.isRequired,
    showEditSubscriptionModal   : PropsTypes.bool.isRequired,
    toggle                      : PropsTypes.func.isRequired,
    account_vaults              : PropsTypes.array.isRequired,
}
export default EditSubscriptionModal

