/*
Documentation

sets the body of the appointment screen to reflect that of the contact update page

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ContactsView from '../../../contacts/View';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux'

class OnCall extends Component {

    state = {

    };

    render() {

        const { contact, callType, division, divisions } = this.props;
        const foundDivision = divisions.find(d => d._id === division);

        return (

            <div>

                {callType ? (
                    <div className="alert alert-warning text-capitalize" style={{borderRadius: 0}}>
                        <Row style={{borderBottom: 'none'}}>
                            <Col xs={3}>
                                <i className="fas fa-headset mr-2 " />
                            </Col>
                            <Col xs={9} className="text-right">
                                {foundDivision ? (
                                    <span className="mr-3">
                                        <b className="font-weight-bold">Division: </b>{' '}
                                        {foundDivision.name}
                                    </span>
                                ) : null}
                                <b className="font-weight-bold">Call Type: </b>{' '}
                                {callType === 'business' ? 'business on hold' : callType} 
                            </Col>
                        </Row>
                        
                    </div>
                ) : null }

                <div className="p-4 pt-0 pb-6 mt--4 contact-wrapper">
                    <ContactsView
                        fullWidth={true}
                        contact={contact}
                    />
                </div>
            </div>

        )

    }

}

OnCall.propTypes = {
    contact: PropTypes.string.isRequired,
    callType: PropTypes.string,
    division: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        divisions : state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(OnCall);
