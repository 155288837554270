/*
Documentation

This is the bottom half of the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import * as privileges from '_functions/users/privileges';
import { toggleBillingCenter } from 'store/functions/system/system'
import _user_notifications from '_functions/user_notifications';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import keys from 'keys'

class NavbarBottom extends React.Component {

	state = {
        total_notifications: null
    }

    increment = (data) => {

        if(data.data.user !== this.props.viewing_user._id) return;

        let count = this.state.total_notifications ? this.state.total_notifications : 0;
        count++

        this.setState({total_notifications: count})

    }
    decrement = (data) => {

        if(data.data.user !== this.props.viewing_user._id) return;

        let count = this.state.total_notifications ? this.state.total_notifications : 0;
        count--

        if(count < 0) return this.setState({total_notifications: 0})

        this.setState({total_notifications: count})

    }

    componentWillUnmount = () => {

        io.off(socketEvents.user_notifications_added, this.increment)
        io.off(socketEvents.user_notifications_deleted, this.decrement)

    }

    componentDidMount = async () => {

        const user_notifications = await _user_notifications.find(`?filter=user__${this.props.viewing_user._id}&limit=1`)
        if(user_notifications.total_documents) this.setState({total_notifications: user_notifications.total_documents})

        io.on(socketEvents.user_notifications_added, this.increment)
        io.on(socketEvents.user_notifications_deleted, this.decrement)

    }

	render() {

        const location = this.props.location
        const { total_notifications } = this.state

    	return (

			<div className="under-nav d-none d-md-block z-depth-1">
				<Navbar className="navbar-expand  navbar"  >
					<Container fluid>
						<Nav className="align-items-center " navbar>

                            {privileges.canViewAdminCallCenter() ? (
                                <NavLink
                                    tag={Link}
                                    to={privileges.canViewAdminCallCenter() ? "/dashboard/call_center_admin" : "/dashboard/call_center"}
                                    className={location.includes('/dashboard/call_center') ? 'active' : ''
                                }>
                                    <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                        <i className="fas fa-headphones mr-2" />
                                        Call Center
                                    </NavItem>
                                </NavLink>
                            ) : (
                                // <NavLink
                                //     // tag={Link}
                                //     target="_blank"
                                //     rel="noopener noreferrer"
                                //     href={keys.TALK_URL}
                                //     className={location.includes('/dashboard/call_center') ? 'active' : ''
                                // }>
                                //     <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                //         <i className="fas fa-headphones mr-2" />
                                //         Call Center
                                //     </NavItem>
                                // </NavLink>
                                <NavLink
                                    // tag={Link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={keys.TALK_URL}
                                    className={location.includes('/dashboard/call_center') ? 'active' : ''
                                }>
                                    <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                        <i className="fas fa-headphones mr-2" />
                                        Call Center
                                    </NavItem>
                                </NavLink>
                            )}

                            
                          
                            {privileges.canViewCases() && (
                                <NavLink
                                    tag={Link}
                                    to="/dashboard/cases/all"
                                    className={location.includes('/dashboard/case') ? 'active' : ''}
                                >
                                    <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                        <i className="fas fa-gavel mr-2" />
                                        Cases
                                    </NavItem>
                                </NavLink>
							)}

                            <NavLink
                                tag={Link}
                                to="/dashboard/contacts/all"
                                className={location.includes('/dashboard/contacts/') ? 'active' : ''
                            }>
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="fas fa-users mr-2" />
                                    Contacts
                                </NavItem>
                            </NavLink>

                            <NavLink
                                tag={Link}
                                to="/dashboard/appointments/all"
                                className={location.includes('/dashboard/appointments/') ? 'active' : ''
                            }>
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="fas fa-users mr-2" />
                                    Appointments
                                </NavItem>
                            </NavLink>

                            <NavLink
                                tag={Link}
                                to="/dashboard/calendar/me"
                                className={location.includes('/dashboard/calendar/') ? 'active' : ''
                            }>
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="ni ni-calendar-grid-58 mr-2" />
                                    Calendar
                                </NavItem>
                            </NavLink>

                            <NavLink
                                tag={Link}
                                to="/dashboard/notifications"
                                className={location.includes('/dashboard/notifications') ? 'active' : ''
                            }>
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="fas fa-bell mr-2" />
                                    Alerts
                                    <b>
                                    {total_notifications ? <span className={location.includes('/dashboard/notifications') ? 'ml-2' : 'ml-2 text-warning'}>({total_notifications})</span> : null}

                                    </b>
                                </NavItem>
                            </NavLink>

                            {privileges.canViewFinances() && (
                                <NavLink to="/dashboard/billing/all" onClick={(e) => { e.preventDefault(); toggleBillingCenter(true)}}>
                                    <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                        <i className="fas fa-dollar-sign mr-2" />
                                        Billing
                                    </NavItem>
                                </NavLink>
							)}

                            <NavLink 
                              tag={Link}
                              to="/dashboard/directory"
                              className={location.includes('/dashboard/directory') ? 'active' : ''
                              }>
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="fas fa-link mr-2" />
                                    Directory
                                </NavItem>
                            </NavLink>


						</Nav>
					</Container>
				</Navbar>
			</div>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(NavbarBottom);
