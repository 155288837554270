// import listenersNewContact from './listeners/newContact'
import listenersNewUserNotification from './listeners/newUserNotification'
import listenersDocumentMerged from './listeners/documentMerged'

// import eventsCallCenterInactivity from './events/callCenterInactivity'

export default {

    run: (addNotification, viewing_user, userSubscribedContacts) => {

        // listenersNewContact.run(addNotification);
        listenersNewUserNotification.run(addNotification, userSubscribedContacts);
        listenersDocumentMerged.run(addNotification);

        // eventsCallCenterInactivity.run(addNotification, viewing_user);

    },

    terminate: () => {
        // listenersNewContact.terminate();
        listenersNewUserNotification.terminate();
        listenersDocumentMerged.terminate();
        // eventsCallCenterInactivity.terminate();
    }

}
