import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import LocationCourtSearchInput from 'views/dashboard/pages/_components/LocationCourtSearchInput';

class AppointmentsCrudCourt extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.location_court) return true
            if(appointment.location_court && !appointment.location_court._id) return true;

        }

        return false

    }

    render() {

        const { appointment, locationCourtRequired, onAssignLocationCourt, onRemoveLocationCourt } = this.props
        const location_court = appointment.location_court;

        if(!locationCourtRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Associate Court</h4>
                        <LocationCourtSearchInput
                            value={{}}
                            onSelect={(location_court) => onAssignLocationCourt(location_court)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a court before proceeding.</div>
                    ) : null}

                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Court</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {location_court && location_court._id ? (
                                <tr key={location_court._id}>
                                    <td className="text-capitalize">
                                        <div>
                                            {location_court.name}
                                                <div>

                                                    {location_court.address_line_1} {' '}
                                                    {location_court.address_line_2 ? location_court.address_line_2 : null}
                                                </div>
                                                <div>
                                                    {location_court.city}, {location_court.state} {location_court.postal_code}
                                                </div>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveLocationCourt()} />
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Contact Assigned</td>
                                    <td></td>
                                </tr>

                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudCourt.propTypes = {
    locationCourtRequired   : PropTypes.func.isRequired,
    onAssignLocationCourt   : PropTypes.func.isRequired,
    onRemoveLocationCourt   : PropTypes.func.isRequired,
    appointment       : PropTypes.object.isRequired,
}

export default AppointmentsCrudCourt
