import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const initWorker = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf-js.worker.js`;
}

export const resetMemory = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = false;
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf-js.worker.js`;
}