/*
Documentation

Renders the single view for a case within the contact view on billing center

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Badge, Card, CardHeader, CardTitle } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import * as privileges from '_functions/users/privileges';


class BillingOverview extends Component {

    state = {
        canSave: true,
        total_paid: null,
        payments: null
    };

    componentDidMount = async () => {

        const payments = await _payments.findByCase(this.props.case._id)

        if(payments.success) {
            this.setState({
                payments: payments.data.payments,
                total_paid:payments.data.total_paid
            })
        }

    }

    render() {

        const _case = this.props.case
        const onChangeNavigation = this.props.onChangeNavigation

        const total = _case.billing_total - _case.billing_paid

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Case Billables</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <tbody>

                            <tr>
                                <td>Client Payments</td>
                                <td className="text-right">
                                    {formatMoney(_case.billing_paid)}
                                </td>
                            </tr>

                            <tr>
                                <td>Total Billables</td>
                                <td  className="text-right">{formatMoney(_case.billing_total)}</td>
                            </tr>

                            <tr>
                                <td>Outstanding Balance</td>
                                <td  className="text-right">
                                    <b className={total > 0 ? 'text-danger' : 'text-success'}>
                                        {formatMoney(total)}
                                    </b>
                                </td>
                            </tr>

                            <tr>
                                <td>Has Recurring Plan</td>
                                <td  className="text-right">
                                    <Badge
                                        className="cursor-pointer text-sm"
                                        color={_case.recurrings && _case.recurrings.length ? 'success' : 'warning'}
                                        onClick={privileges.canUpdateFinances() ? () => onChangeNavigation(3) : null}
                                    >
                                        <i className="fas fa-edit mr-2" />
                                        {_case.recurrings && _case.recurrings.length ? 'View Plan' : 'Set Up'}
                                    </Badge>

                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </Card>

        )

    }

}

BillingOverview.propTypes = {
    case: PropTypes.object.isRequired,
    onChangeNavigation: PropTypes.func.isRequired
}

export default BillingOverview
