import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';

const required_form_fields = [
    'amount',
    'description'
]

class Discounts extends Component {

    state = {
        discount: {},
    };

    // scroll to top of open modal
    scrollModalToTop = () => {

        const modal = document.getElementById('modal-invoice')

        //if modal is open and active
        if(modal) {

            let modal_content = null;

            // find the .modal-content within the modal
            for (let i = 0; i < modal.childNodes.length; i++) {

                if (modal.childNodes[i].className === "modal-content") {
                    modal_content = modal.childNodes[i];
                    break;
                }

            }

            //if we have the modal content scroll to top
            if(modal_content) {
                modal_content.scrollTop = 0
            }

        }

    }

    onDiscountInputChange = (f,v) => {

        let discount = Object.assign({}, this.state.discount);
        discount[f] = v

        // if changing the type reset the amount
        if(f === 'type') discount.amount = null

        this.setState({discount})

    }

    inputsAreValid = (obj_name) => {

        let errors = 0;
        let newState = Object.assign({}, this.state[obj_name]);

        required_form_fields.forEach((field) => {

            if(field === 'amount' && newState[field] === '0') {
                newState[field + "State"] = 'invalid';
                errors++
            } else if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        // validate decimals
        if(!_payments.validate.decimals(newState.amount)) {
            newState["amountState"] = 'invalid';
            errors++
        }

        // validate we have an actual number
        if(isNaN(parseFloat(newState.amount))) {
            newState["amountState"] = 'invalid';
            errors++
        }

        if(obj_name === 'discount' && newState.type === 'percent') {
            if(parseFloat(newState.amount) > 100) {
                newState["amountState"] = 'invalid';
                errors++
            } else if(parseFloat(newState.amount) < 0) {
                newState["amountState"] = 'invalid';
                errors++
            }
        }

        this.setState({[obj_name]: newState})
        return errors ? false : true

    }

    onAddObject = (obj_name) => {

        // validate all required fields are filled out
        if(!this.inputsAreValid(obj_name)) return;

        let object = Object.assign({}, this.state[obj_name]);

        // add a mock id for reference to this specific item (user id + unix timestamp)
        object.id = this.props.viewing_user._id + '_' + Math.floor(new Date() / 1000)

        // set to a true number
        object.amount = parseFloat(object.amount)

        if(obj_name === 'discount') {
            // if type was not changed on discount set to default 'fixed'
            object.type = object.type ? object.type : 'fixed';
            // scroll modal to top
            this.scrollModalToTop()
        }

        this.props.onAddInvoiceItem(object);
        this.setState({[obj_name]: {}})

    }

    onRemoveInvoiceItem = (expense_id) => {

        this.props.onRemoveInvoiceItem(expense_id);

    }



    render() {

        const { discount } = this.state
        const { invoice } = this.props

        const data = invoice.discounts
        const hasDiscount = invoice.discounts && invoice.discounts.length;

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Current Discount Items</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table  className="table">

                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Discount Type</th>
                                <th>Value</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>

                            {data && data.length ? data.map(f => (
                                <tr key={f._id ? f._id : f.id}>
                                    <td style={{wordBreak: 'break-word'}}>{f.description}</td>
                                    <td>{formatMoney(f.amount)}</td>
                                    <td className="text-capitalize">{f.type}</td>
                                    <td className="text-capitalize">Discount</td>
                                    <td className="text-right">
                                        <i
                                            onClick={() => this.onRemoveInvoiceItem(f._id ? f._id : f.id)}
                                            className="fas fa-trash text-danger mr-2 cursor-pointer"
                                        />
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td style={{wordBreak: 'break-word'}}>There are no discounts on this invoice.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>

                    </table>
                </div>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Add Discount</CardTitle>
                </CardHeader>

                <CardBody>

                    <StandardFormGroup
                        obj={discount}
                        objName="discount"
                        onChange={(o, f, v) => this.onDiscountInputChange(f, v)}
                        type="select"
                        field="type"
                        title="Type"
                    >
                        <option value="fixed">Fixed Amount</option>
                        <option value="percent">Percent</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={discount}
                        objName="discount"
                        onChange={(o, f, v) => this.onDiscountInputChange(f, v)}
                        type="number"
                        field="amount"
                        title={discount.type === 'percent' ? 'Amount (Percent 0 - 100)' : 'Amount (Fixed Dollar Amount)'}
                    />

                    <StandardFormGroup
                        obj={discount}
                        objName="discount"
                        onChange={(o, f, v) => this.onDiscountInputChange(f, v)}
                        type="textarea"
                        field="description"
                        title="Discount Description"
                    />

                    <div className="text-right">

                        {hasDiscount ? (
                            <p className="text-sm font-weight-bold text-warning">There can only be one discount applied per invoice.</p>
                        ) : null }

                        <button
                            disabled={hasDiscount}
                            className="btn btn-success"
                            onClick={hasDiscount ? null : () => this.onAddObject('discount')}
                        >
                            Add Discount
                        </button>
                    </div>

                </CardBody>

            </Card>

            </>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

Discounts.propTypes = {
    onAddInvoiceItem: PropTypes.func.isRequired,
    onRemoveInvoiceItem: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(Discounts);
