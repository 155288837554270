import React, { Component } from 'react';
import renderName from 'utils/renderName';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import PropTypes from 'prop-types'
import { formatPhone } from 'utils/text'

class SendTextTo extends Component {

    state = {
        isEditing: false,
    }

    focusEditingInput = () => {
        const input = document.getElementById('send-text-input')
        if(input) input.focus()
    };

    onTextBodyInputFocus = () => this.setEditing(false)

    setEditing = (isEditing) => {

        this.setState({isEditing}, () => {

            const textBodyInput = document.getElementById('archk-send-text-body-input')

            if(isEditing) {

                this.focusEditingInput()

                if(textBodyInput) textBodyInput.addEventListener('focus', this.onTextBodyInputFocus)

            } else {

                if(textBodyInput) textBodyInput.removeEventListener('focus', this.onTextBodyInputFocus)

            }

        })

    }

    onRemoveContact = () => {
        this.props.onContactChange()
        this.focusEditingInput()
    }

    onAddTo = (contact) => {
        this.props.onContactChange(contact)
        this.focusEditingInput()
    }

    componentWillUnmount = () => {

        const textBodyInput = document.getElementById('archk-send-text-body-input')
        if(textBodyInput) textBodyInput.removeEventListener('focus', this.onTextBodyInputFocus)

    }

    render() {

        const { contact } = this.props
        const { isEditing } = this.state

        const contact_name = renderName(contact)

        if(isEditing) {

            return (

                <div className="to-container editing">
                    To: {' '}
                    {contact && contact._id ? (
                         <span className="text-capitalize">{contact.phone ? formatPhone(contact.phone) : <b className='text-danger'>Contact Phone Not Found</b>}
                            <b><i onClick={this.onRemoveContact} className="fas fa-times" /></b>
                        </span>
                    ): null}

                     <ContactSearchInput
                        inputID="send-text-input"
                        value={{}}
                        onSelect={this.onAddTo}
                        leaveEmpty={true}
                    />
                </div>
            )

        }

        return (

            <div className="to-container" onClick={() => this.setEditing(true)}>
                To: {' '}
                {contact && contact._id ? (
                    <span className="text-capitalize">{contact_name === 'Not Found' ? contact.phone : contact_name}</span>
                ): null}
            </div>

        )

    }

}


SendTextTo.propTypes = {
    onContactChange   : PropTypes.func.isRequired,
    contact           : PropTypes.object,
}


export default SendTextTo
