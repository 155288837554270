// in theory these should only fire from the backend

import apiWorker from '../apiWorker'

export default {

    update: (user, data)    => apiWorker('patch',  `/api/v1/core/user_notification_settings/update/${user}`, data),
    find:  (query)          => apiWorker('get', `/api/v1/core/user_notification_settings/find${query}`),

}
