/*
Documentation

This component renders a modal for creating a new task within an existing case

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Modal, Row } from "reactstrap";
import _case_workflow_tasks from '_functions/case_workflow_tasks'
import { connect } from 'react-redux';
import renderName from 'utils/renderName';

const required_form_fields = [
    'name',
    'type',
]

const oneHour = 3600;

class ModalAddTask extends Component {

    state = {
        taskValue: {},
        action: null
    }

    onSave = async () => {

        let newState = Object.assign({}, this.state.taskValue);

        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ taskValue: newState });

        let due_after_assignment = undefined
        if(newState.due_after_assignment) due_after_assignment = parseInt(newState.due_after_assignment)

        let default_billable_minutes = undefined
        if(newState.default_billable_minutes) default_billable_minutes = parseInt(newState.default_billable_minutes)

        if(newState.required === 'true') newState.required = true;
        if(newState.required === 'false') newState.required = false;

        if(!errors) {

            _case_workflow_tasks.create({
                ...newState,
                persistent            : newState.persistent === 'true' ? true : false,
                required              : newState.persistent === 'true' ? false : newState.required,
                assigned_to           : newState.assigned_to ? [newState.assigned_to] : [],
                case                  : this.props.case._id,
                contact               : this.props.case.contact._id,
                case_workflow_step    : this.props.current_step._id,
                case_workflow         : this.props.case.workflow,
                active                : true,
                user                  : this.props.viewing_user._id,
                due_after_assignment,
                default_billable_minutes
            })

            this.setState({taskValue: {}})
            this.props.toggle();

        }

    }

    render() {

        const { taskValue } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={this.props.showModal}
                toggle={() => this.props.toggle()}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Task</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <Row>

                        <Col lg={7}>
                            <StandardFormGroup
                                inputId="modal-task-card-first-input"
                                obj={taskValue}
                                objName='taskValue'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="text"
                                field="name"
                                title="Task Name *"
                            />
                        </Col>

                        <Col lg={5}>
                            <StandardFormGroup
                                obj={taskValue}
                                objName='taskValue'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                formatter={(val) => val ? val.toString() : ''}
                                type="number"
                                field="default_billable_minutes"
                                title="Default Billable Minutes*"
                            />
                        </Col>

                    </Row>

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="type"
                        title="Type *"
                    >
                        <option></option>
                        <option value="text">text</option>
                        <option value="upload">upload</option>
                        <option value="appointment">appointment</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="required"
                        title="Required"
                    >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="textarea"
                        field="description"
                        title="Description"
                    />

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="assigned_to"
                        populate={taskValue.assigned_to && taskValue.assigned_to._id ? '_id' : null}
                        title="Assigned To User"
                    >
                        <option value={null}></option>
                        {this.props.case.roles && this.props.case.roles.length ? this.props.case.roles.map((r, i) => (
                            r.user ? (
                            <option key={r.user._id + i} value={r.user._id}>{renderName(r.user)}</option>
                            ) : null
                        )): null}

                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="due_after_assignment"
                        title="Due After Assignment "
                    >
                        <option value={null}></option>
                        <option value={oneHour}>1 Hour</option>
                        <option value={oneHour * 2}>2 Hours</option>
                        <option value={oneHour * 3}>3 Hours</option>
                        <option value={oneHour * 6}>6 Hours</option>
                        <option value={oneHour * 12}>12 Hours</option>
                        <option value={oneHour * 24 * 1}>1 Day</option>
                        <option value={oneHour * 24 * 2}>2 Days</option>
                        <option value={oneHour * 24 * 3}>3 Days</option>
                        <option value={oneHour * 24 * 4}>4 Days</option>
                        <option value={oneHour * 24 * 5}>5 Days</option>
                        <option value={oneHour * 24 * 6}>6 Days</option>
                        <option value={oneHour * 24 * 7 * 1}>1 Week</option>
                        <option value={oneHour * 24 * 7 * 2}>2 Weeks</option>
                        <option value={oneHour * 24 * 7 * 3}>3 Weeks</option>
                        <option value={oneHour * 24 * 7 * 4}>4 Weeks</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={taskValue}
                        objName='taskValue'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="persistent"
                        title="Persistent "
                        description="If this is set to yes than this task will not be required to move any step however it will show up as a talk to fulfil on any step greater than or equal to this one until it is completed"
                    >
                        <option value={false}>False</option>
                        <option value={true}>True</option>
                    </StandardFormGroup>

                    {taskValue.persistent ? (
                        <StandardFormGroup
                            obj={taskValue}
                            objName='taskValue'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            type="select"
                            field="persistent_everywhere"
                            title="Persistent Everywhere"
                            description="If this is set to true then this task will be shown on all steps, if false it will be shown on all steps greater than or equal to the one it is assigned to until finished"
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </StandardFormGroup>
                    ) : null}

                </div>

                <div className="modal-footer text-right">
                    <button onClick={this.onSave} className="btn btn-success">Create Task</button>
                </div>

            </Modal>

        )

    }

}

ModalAddTask.propTypes = {
    step            : PropTypes.string,
    showModal       : PropTypes.bool.isRequired,
    toggle          : PropTypes.func.isRequired,
    case            : PropTypes.object.isRequired,
    current_step    : PropTypes.object.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalAddTask);

