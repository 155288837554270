/*
Documentation

loads a notification showing the status of a transferring call on all pages

called by

import { startTransferListener } from 'store/functions/call_center/call_center'
startTransferListener(user_id, contact_id)

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import Dots from 'components/markup/loading/Dots';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import renderName from 'utils/renderName'

class TransferNotifications extends React.Component {

    state = {
        show: false,
        answered: null,
        user: null
    }

    resetAtInterval = () => {
        setTimeout(() => {
            this.setState({show: false, answered: null, status: false, user: null})
        }, 3000)
    }

    componentWillReceiveProps = (nextProps) => {

        const { contact_id, user_id, status } = this.props.transfer_listener;
        const nextStatus = nextProps.transfer_listener.status;
        const nextContactId = nextProps.transfer_listener.contact_id;
        const nextUserId = nextProps.transfer_listener.user_id;

        if(!contact_id && !user_id && nextContactId && nextUserId) {

            const user = this.props.users.find(u => u._id === nextProps.transfer_listener.user_id)

            this.setState({show: true, user})

        } else if(!status && nextStatus === 'answered') {

            this.setState({answered: 'answered'}, this.resetAtInterval)

        } else if(!status && nextStatus === 'not answered') {

            this.setState({answered: 'not answered'}, this.resetAtInterval)

        }

    }


    render() {

        const { show, answered } = this.state
        const user = this.state.user ? this.state.user : {};

        if(!show) return null

        return (
            <div className="archk-transfer-notifications z-depth-5">
                <Card>

                    <CardBody>

                        <Row>
                            <Col xs={3} className="align-self-center">
                                <AvatarImage
                                    className="z-depth-2"
                                    style={{width: 45, height: 45}} src={user.avatar}
                                />
                            </Col>
                            <Col xs={9} className="align-self-center">
                                <p className="text-sm mb-0  font-weight-bold text-capitalize">
                                    {!answered ? <i className="fas fa-mobile mr-2 text-success" /> : null}
                                    {renderName(user)}
                                </p>
                                <p className="text-sm mb-0 mt--2">

                                    {answered === 'answered' ? (
                                        <><i className="mr-2 fas fa-check text-success" />Answered! </>
                                    ) : answered === 'not answered' ? (
                                        <><i className="mr-2 fas fa-times text-danger" />Not Answered </>
                                    ) :  <>Calling<Dots /></>}

                                </p>
                            </Col>

                        </Row>

                    </CardBody>


                </Card>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        transfer_listener: state.call_center.transfer_listener,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(TransferNotifications);
