/*
Documentation

this modal allows the ability to assign a user to a case role

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { toggleAlert } from 'store/functions/system/system';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import _cases from '_functions/cases';

class RolesAssignUser extends Component {

    state = {
        user: null
    };

    onAssignUser = () => {

        const user    = this.state.user

        const role    = this.props.role
        const _case   = this.props.case;

        if(user) {

            _cases.assignUser({case: _case._id,  user: user._id, role: role.role })
            this.props.toggleModal()

        } else {

            toggleAlert(true, 'warning', 'You have not selected a valid user.', '3000')

        }

    }

    componentWillReceiveProps = (nextProps) => {

        // when modal closes remove the user from state
        if(this.props.showModal && !nextProps.showModal) this.setState({user: null})

    }

    render() {

        const { showModal, toggleModal, role } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Assign User To Role</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">By assigning an user to this case they will be given full access rights to update, move case steps, add documents, etc. </p>

                    <UserSearchInput
                        onSelect={(user) => this.setState({user})}
                        value={this.state.user ? this.state.user : {}}
                    />

                </div>

                <div className="modal-footer">
                    <button disabled={!role} onClick={this.onAssignUser} className="btn text-center btn-success" >
                        Add Role
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

RolesAssignUser.propTypes = {
    case        : PropTypes.object.isRequired,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    role        : PropTypes.object
}

export default connect(mapStateToProps, '')(RolesAssignUser);
