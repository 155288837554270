/*
Documentation

renders the contact card ( almost a header ) on the billing center

*/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class ContactCard extends Component {

    state = {};

    formatAddress = (obj, coloring) => {

        let o = obj

        let string1 = '';
        let string2 = '';
        let string3 = '';

        if(o) {

            if(o.address_line_1) {
                string1 = o.address_line_1
            }

            if(o.address_line_1 && o.address_line_2) {
                string1 = string1 + ' ' + o.address_line_2
            }

            if(o.city) {
                string2 = o.city
            }

            if(o.city && o.state) {
                string2 = string2 + ', ' + o.state
            }

            if(o.city && o.state && o.postal_code) {
                string2 = string2 + ' ' + o.postal_code
            }

            if(o.city && o.state && o.postal_code && o.country) {
                string3 = string3 + ' ' + o.country
            }

        }

        if(!string1 && !string2) {
            return  <p className="mb--2 text-sm font-weight-bold text-danger">No Address Found</p>
        }

        let color = coloring === 'black' ? null : 'text-white'


        return (
            <>
                <p className={`${color} mb--2 text-sm font-weight-bold`}>{string1} </p>
                <p className="text-muted mb--2 text-sm">{string2}</p>
                <p className="text-muted mb-0 text-sm">{string3}</p>
            </>
        )

    }

    render() {

        if(!this.props.cases) return <div></div>

        const { contact } = this.props

        return (

            <Card className="bg-primary">
                <CardBody>

                    <Row>

                        <Col md={4}>

                            <h2 className="text-capitalize text-white">
                                <i className="fas fa-user text-success mr-2" />
                                contact
                            </h2>

                            <p className="mb--2 text-sm font-weight-bold">
                                <Link
                                    onClick={this.props.onToggleBillingCenter}
                                    className="text-white text-capitalize"
                                    to={`/dashboard/contacts/view/${contact._id}`}
                                >
                                    {renderName(contact)}
                                </Link>
                            </p>

                            <p className="text-muted mb--2 text-sm">{contact.email}</p>
                            {contact.phone ? (
                                <p className="text-muted mb-0 text-sm">M: {formatPhone(contact.phone)}</p>
                            ) : null}

                        </Col>

                        <Col md={4}>

                            <h2 className="text-capitalize text-white">
                                <i className="fas fa-map-pin text-success mr-2" />
                                Address
                            </h2>

                            {this.formatAddress(contact)}

                        </Col>

                        <Col md={4}>

                            <h2 className="text-capitalize text-white">
                                <i className="fas fa-info-circle text-success mr-2" />
                                Client Info
                            </h2>

                            <p className="mb--2 text-sm font-weight-bold">
                                <span className="text-white" to={`/dashboard/contacts/view/${contact._id}`}>
                                    Created: {moment.unix(contact.created_at).format('MMMM Do, YYYY')}
                                </span>
                            </p>

                        </Col>

                    </Row>

                </CardBody>

            </Card>

        )

    }

}

ContactCard.propTypes = {
    cases: PropTypes.array,
    contact: PropTypes.object.isRequired,
}

export default ContactCard;
