import React, { Component } from 'react';
import { Col, Row, Card, CardHeader, CardTitle, CardBody, UncontrolledTooltip } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _invoices from '_functions/invoices';

// 7/25/2021 EST is when this went into effect
const EXPENSE_FEES_UPDATE = 1627185600;

class InvoiceDetails extends Component {

    state = {
        invoice: {}
    };

    render() {

        const { invoice, toggleModal } = this.props

        const total_fees        = _invoices.calculate.totalFees(invoice)
        const total_expenses    = _invoices.calculate.totalExpenses(invoice)
        const total_credits     = _invoices.calculate.totalCredits(invoice)
        const total_write_offs  = _invoices.calculate.totalWriteOffs(invoice)
        const total_refunds     = _invoices.calculate.totalRefunds(invoice)

        const total_invoice = _invoices.calculate.total(invoice)

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Invoice Details </CardTitle>
                </CardHeader>

                <CardHeader>

                    <Row>
                        <Col xs={4}>
                            <p className="mb-0 text-sm">Name</p>
                        </Col>
                        <Col xs={8}>
                            <p className="mb-0 text-sm text-right">
                                {invoice.name ? (
                                    <b>{invoice.name}</b>
                                ) : (
                                    <b className="text-danger">Untitled</b>
                                )}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Fees</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_fees)}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Expenses</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_expenses)}</p>
                        </Col>
                    </Row>

                    <hr className="my-2" />

                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Credits</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_credits)}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Write Offs</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_write_offs)}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Discounts</p>
                        </Col>
                        <Col xs={5}>
                                <p className="mb-0 text-sm text-right">
                                {invoice.discounts && invoice.discounts.length ? (
                                    <span>
                                        {invoice.discounts[0].type === 'percent' ? (
                                            invoice.discounts[0].amount + '%'
                                        ) : (
                                            formatMoney(invoice.discounts[0].amount)
                                        )}
                                    </span>
                                ) : 'none'}
                                </p>
                        </Col>
                    </Row>

                    <hr className="my-2" />

                    <Row>
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Total</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_invoice)}</p>
                        </Col>
                    </Row>

                    <hr className="my-2" />

                    <Row className="text-danger font-weight-bold">
                        <Col xs={7}>
                            <p className="mb-0 text-sm">Refunds</p>
                        </Col>
                        <Col xs={5}>
                            <p className="mb-0 text-sm text-right">{formatMoney(total_refunds)}</p>
                        </Col>
                    </Row>

                </CardHeader>

                {invoice.created_at >= EXPENSE_FEES_UPDATE ? (
                    <>
                        <CardHeader>
                            <CardTitle className="mb-0">
                                Paid Breakdown  
                                <i className="fas fa-info-circle text-info-original ml-2 float-right" id="tooltip-invoice-expenses" />
                                <UncontrolledTooltip delay={0} placement="right" target="tooltip-invoice-expenses" >
                                    Invoice expense and fee breakdown will not show for any invoices created before 7/25/2021.
                                </UncontrolledTooltip>
                            </CardTitle>
                        </CardHeader>
                        
                        <CardBody>
                            <Row>
                                <Col xs={7}>
                                    <p className="mb-0 text-sm">Expenses </p>
                                </Col>
                                <Col xs={5}>
                                    <p className="mb-0 text-sm text-right">{formatMoney(invoice.expenses_paid)}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={7}>
                                    <p className="mb-0 text-sm">Fees</p>
                                </Col>
                                <Col xs={5}>
                                    <p className="mb-0 text-sm text-right">{formatMoney(invoice.fees_paid)}</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </>
                ) : null}

                

            </Card>

            {invoice.status === 'sent' ? (
                <div></div>
            ) : (
                <button
                    onClick={() => toggleModal(true)}
                    className="btn btn-success btn-block"
                >
                    Preview / Send Invoice
                </button>
            )}

            </>

        )

    }

}

export default InvoiceDetails;
