/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ModalSelectTemplateEmail from './ModalSelectTemplateEmail'
import PropTypes from 'prop-types'

class SendEmailToolbar extends Component {

    state = {
        showModal: false
    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onFocusAttachmentUpload = () => {

        const input = document.getElementById('archk-send-send-email-attachment-input')
        input.click();

    }

    onAttachmentsChange = (event) => {

        const newAttachments = event.target.files
        let attachments = [...this.props.attachments];

        let attachmentsCounter = 0

        while (attachmentsCounter < newAttachments.length) {

            attachments.push(newAttachments[attachmentsCounter])
            attachmentsCounter++

        }

        this.props.onAttachmentsChange(attachments)

    }

    onRemoveAttachment = (attachmentsCounter) => {

        let attachments = [...this.props.attachments];
        attachments.splice(attachmentsCounter, 1)

        this.props.onAttachmentsChange(attachments)

    }

    renderAttachments = () => {

        let attachments = [...this.props.attachments];
        let attachmentNames = []

        let attachmentsCounter = 0

        // push file names into an array, as they are file object cant just map through them as normal
        while (attachmentsCounter < attachments.length) {

            attachmentNames.push(attachments[attachmentsCounter].name)
            attachmentsCounter++
        }

        // return mapping of names
        return attachmentNames.map((name, i) => (
            <div className="attachment" key={`attachment__${i}`}>
                {name.length > 30 ? name.slice(0, 30) + '...' + name.slice(name.length - 3, name.length) : name}
                <span onClick={() => this.onRemoveAttachment(i)} className="remove"><i className="fas fa-times" /></span>
            </div>
        ))

    }

    render() {

        const { showModal } = this.state
        const { onSend, onSelectTemplate, division } = this.props

        return (

        <>

            <div className="show-attachments">
                {this.renderAttachments()}
            </div>

            <div id="toolbar">

                <button onClick={onSend} className="btn btn-success send"><i className="fas fa-paper-plane mr-2" />Send</button>
                <button onClick={this.toggleModal} className="btn btn-outline-success template"><i className="fas fa-plus mr-2" />Template</button>

                <button onClick={this.onFocusAttachmentUpload}>
                    <i className="fas fa-paperclip" style={{position: 'relative', top: -3}} />
                </button>

                <button className="ql-bold"></button>
                <button className="ql-underline"></button>
                <button className="ql-italic"></button>
                <button className="ql-link"></button>
                <select className="ql-background"></select>
                <select className="ql-color"></select>

            </div>

            <input id="archk-send-send-email-attachment-input" hidden={true} multiple={true} type="file" onChange={this.onAttachmentsChange} />

            <ModalSelectTemplateEmail
                showModal={showModal}
                toggleModal={this.toggleModal}
                onSelect={onSelectTemplate}
                division={division}

            />

        </>
        )

    }

}

SendEmailToolbar.propTypes = {
    onSend   : PropTypes.func.isRequired,
    onSelectTemplate   : PropTypes.func.isRequired,
    onAttachmentsChange   : PropTypes.func.isRequired,
    attachments           : PropTypes.array,
}

export default SendEmailToolbar
