import React from "react";
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import validator from 'utils/validator';
import _auth from '_functions/auth';

class AccountSecurity extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    onInputChange = (e, stateField) => {
        validator.onInputChange(e.target.value, 'user', stateField, this)
    }


    onPasswordUpdate = async  () => {

        this.setState({error_password: false})

        const user = {...this.state.user, _id: this.props.viewing_user._id };

        const updated = await _auth.password.update({
            _id:                user._id,
            password:           user.new_password,
            password_confirm:   user.new_password_confirm
        })

        if(updated.success) {

            toggleAlertBS(false, `Your password has been successfully changed.`)
            this.setState({ user: { ...this.state.user, new_password: null, new_password_confirm: null }})

        } else {

            this.setState({error_password: updated.message})

        }

    }

    render() {

        const { user } = this.state

        return (
           
            <Card>

                <CardHeader><CardTitle className="mb-0">Security</CardTitle></CardHeader>

                <CardBody>

                    <h6 className="heading-small text-muted mb-4">Change Password</h6>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="new_password" >
                            New Password
                        </label>
                        <Input
                            id="new_password"
                            value={user.new_password || ''}
                            placeholder=""
                            type="password"
                            onChange={e => this.onInputChange(e, "new_password") }
                        />
                        {this.state.error_password ? <div className="text-danger">{this.state.error_password}</div> : null}
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="new_password_confirm" >
                            Confirm New Password
                        </label>
                        <Input
                            id="new_password_confirm"
                            value={user.new_password_confirm || ''}
                            placeholder=""
                            type="password"
                            onChange={e => this.onInputChange(e, "new_password_confirm") }
                        />
                    </FormGroup>

                    <hr />

                    <p className="text-sm mb-0">When changing your password make sure to not reuse any that you have in the past and ensure that it is not easily guessed by others trying to access your account.</p>

                </CardBody>

                <CardFooter className="text-right">
                    <Button
                        color="success"
                        onClick={this.onPasswordUpdate}
                        disabled={this.state.canSave ? false : true}
                    >
                        Update Password
                    </Button>
                </CardFooter>

            </Card>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSecurity);
