import Sidebar from 'components/layout/Sidebar';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';

import AccountDetails from './Details';
import AccountSecurity from './Security';
import AccountProfile from './Profile';
import AccountDevice from './Device';
import AccountConfiguration from './Configuration';

const CompanySettings = () => (
    <>
        <Helmet>
            <title>Account Settings</title>
            <meta name="description" content="Account Settings" />
        </Helmet>

        <Sidebar 
            navTitle="My Account"
            header={(
                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Settings</span>} breadcrumb_1="Account"
                />
            )}

            tabs={[
                { title: 'Details', component: <AccountDetails /> },
                { title: 'Security', component: <AccountSecurity /> },
                { title: 'Profile', component: <AccountProfile /> },
                { title: 'Device', component: <AccountDevice /> },
                { title: 'Configuration', component: <AccountConfiguration /> },
            ]}
        />

    </>
)

export default CompanySettings
