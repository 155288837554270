/*
Documentation

this page renders a card that goes in each row of the
table generated in ./OffCall

*/

import PropTypes from 'prop-types';
import AvatarImage from 'components/functional/images/AvatarImage';
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import renderName from 'utils/renderName';
import moment from 'moment';
import { connect } from 'react-redux';
import * as privileges from '_functions/users/privileges'

const timeToOverdue = 600 // 10 minutes

class CallCenterAdminOffCallCard extends Component {

    state = {
        show: false,
    };

    // how long there overdue in making a call
    // default is 10 minutes
    callOverdue = (row) => {

        const now = Math.floor(new Date() / 1000)

        return row.call_ended_last < (now - timeToOverdue) ? true : false

    }

    // see if a user is delinquent and overdue on
    // changing what they are doing
    isDelinquent = (row) => {

        const now = Math.floor(new Date() / 1000)

        const { user_set_status, user_set_status_time } = row
        let delinquent = false

        if(user_set_status_time && user_set_status) {

            const foundStatus = this.props.user_statuses.find(s => s.name === user_set_status)

            if(foundStatus) {

                let delinquentSeconds = foundStatus.time_to_delinquent * 60

                if((user_set_status_time + delinquentSeconds) < now) {
                    delinquent = true
                }


            }

        }

        return delinquent

    }

    render() {

        const { show } = this.state
        const { row, userActivity, lastOnline } = this.props
        const { isMobile } = this.props.device.info

        const delinquent = this.isDelinquent(row)
        const callOverdue = this.callOverdue(row)

        const now = Math.floor(new Date() / 1000)
        const lastOnlineActivity = lastOnline ? Math.floor((now - lastOnline) / 60) : null


        return (

            <div className="px-3 py-3 border-bottom">

                <div className="text-capitalize font-weight-bold  cursor-pointer" onClick={() => this.setState({show: !show})}>
                    <AvatarImage style={{width: 35, height: 35}} src={row.avatar} className="mr-3 z-depth-1" />

                    <i className={callOverdue ? "text-danger fas fa-clock  ml-2 mr-1"  : "text-muted fas fa-clock  ml-2 mr-1" } />
                    <i className={delinquent && row.user_set_status !== 'default'  ? "text-danger fas fa-calendar mr-2"     : "text-muted fas fa-calendar mr-2"}     />

                    <span className={row.status === 'online' ? '' : 'text-danger'}> {renderName(row)}</span>

                    <div className={isMobile ? "text-sm" : "float-right text-sm"} style={{position: 'relative', top: 5}}>
                        {/* Inactivity:{' '} */}
                        {row.status === 'offline' ? <span className="text-right" style={styles.activity} >{lastOnlineActivity ? moment.unix(now - (lastOnlineActivity * 60)).fromNow() : '-'}</span> :
                        userActivity === undefined ? <span className="text-right" style={styles.activity} >-</span> :
                        userActivity <= 2 ?  <span style={styles.activity} className="text-right text-success">{moment.unix(now - (userActivity * 60)).fromNow()}</span> :
                        userActivity < 10 ?  <span style={styles.activity} className="text-right text-warning">{moment.unix(now - (userActivity * 60)).fromNow()}</span> :
                        <span style={styles.activity} className="text-right text-warning">{moment.unix(now - (userActivity * 60)).fromNow()}</span>
                        }
                    </div>

                </div>

                {show ? (
                    <div className="px-3 py-3">
                        <hr className="my-2 mx--4 mb-3"/>

                        <Row >
                            <Col md={6} className="pl-0">
                                <p className="text-sm mb--1 font-weight-bold">Extension:</p>

                                <p className="text-sm mb-1 text-muted font-weight-bold">
                                    <i className="fas fa-mobile mr-1" /> {row.call_extension}
                                </p>
                            </Col>
                            <Col  md={6} className="text-right col-auto pr-0 pl-0">
                                <p className="text-sm mb--1 font-weight-bold">Last Call:</p>

                                <p className="text-sm mb-1 font-weight-bold">
                                    <span className={callOverdue ? "text-danger" : "text-muted"}>
                                        {row.call_ended_last ? moment.unix(row.call_ended_last).fromNow()  : '-'}
                                    </span>
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="pl-0">
                                <p className="text-sm mb--1 font-weight-bold">Status:</p>
                                <p className="text-sm mb-1 text-muted font-weight-bold">
                                    {row.user_set_status === 'default' ? '-' : row.user_set_status}
                                </p>

                            </Col>
                            <Col  md={6} className="text-lg-right col-auto pr-0 text-right">

                                <p className="text-sm mb--1 font-weight-bold">Set:</p>

                                <p className="text-sm mb-1 font-weight-bold">
                                    <span className={delinquent && row.user_set_status_time && row.user_set_status !== 'default' ? "text-danger" : "text-muted"}>
                                        {row.user_set_status_time ? moment.unix(row.user_set_status_time).fromNow() : '-'}
                                    </span>
                                </p>

                            </Col>
                        </Row>

                        {privileges.canScreenWatch() && !isMobile && (
                            <Row>
                                <Col xs={12} className="pl-0">
                                    <a
                                        href={`/blank/screen_watch/${row._id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-outline-warning mt-4"
                                    >
                                        <i className="fas fa-eye mr-2" /> Watch User
                                    </a>
                                </Col>
                            </Row>
                        )}
                    </div>
                ) : null}

            </div>

        )

    }

}

const styles = {
    activity: {
        display: 'inline-block', width: 100
    }
}

CallCenterAdminOffCallCard.propTypes = {
    row: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        user_statuses: state.user_statuses.user_statuses,
        device: state.device,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdminOffCallCard);
