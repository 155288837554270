/*
Documentation

This file creates the billing center for the app
it appears as a sidebar that should be able to be accessed from anywhere on the dashboard
It should not appear to anyone without canViewFinances privilege

*/

import { connect } from 'react-redux'

import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import SearchContacts from './SearchContacts';
import ViewContact from './ViewContact';
import _contacts from '_functions/contacts'
import Circle from 'components/markup/loading/Circle'

import { getUrlParameter, setUrlParameter } from 'utils/urls'

import { canViewFinances } from '_functions/users/privileges';
import { toggleBillingCenter } from 'store/functions/system/system'
// import _contacts from '_functions/contacts'

class BillingCenter extends Component {


    state = {
        contact: null
    };

    //let body scroll when this is not show
    onClose = () => {
        toggleBillingCenter()
    }

    //go back to selecting a contact
    onShowAllContacts = () => this.setState({contact: null})

    //show the information for a single contact and set
    // the url to remember the contact on reload
    onSelectContact = (contact) => {
        this.setState({ contact })
        if(contact && contact._id) setUrlParameter('bcc', contact._id)
    }


    //stop body from scrolling when this component is show
    componentWillReceiveProps = async (nextProps) => {

        if(nextProps.billingCenter.show && !this.props.billingCenter.show) {

            document.body.classList.add('noScroll')

            // if url param is set for cid try to grab the contact_id from that parameter but only if it is 24 characters long
            // this is a loose way to validate that it is a mongo _id
            const contact_id = getUrlParameter('cid')

            if(contact_id && contact_id.length === 24 && !this.state.contact) {

                this.setState({loading: true})

                const contact = await _contacts.findById(contact_id)
                if(contact.data) {
                    this.setState({contact: contact.data})
                    setUrlParameter('bcc', contact.data._id)
                }

                this.setState({loading: false})

            }

        } else {

            this.setState({contact: null})
            document.body.classList.remove('noScroll')
            setUrlParameter('bcc', 'na')

        }

    }

    componentDidMount = async () => {

        // billing center contact, if this is true we want to load the page with the 
        // billing center set to open and the prefilled contact
        const contact_id = getUrlParameter('bcc')

        if(contact_id && contact_id.length === 24 && !this.state.contact) {

            const contact = await _contacts.findById(contact_id)
            if(contact.data) {
                this.setState({contact: contact.data})
                toggleBillingCenter(true)
            }

        }




    }

    render() {

        const { contact, loading } = this.state;
        const billingCenter = this.props.billingCenter

        //if this is not show return blank
        if(!billingCenter.show) { return <></> }

        //only show this component if the viewing user has the correct permission
        if(!canViewFinances()) { return <div></div> }

        return (

            <div id="billing-center" className="billing-center">

                {/* Backdrop div that when clicked closes the billing center */}
                <div className="close " onClick={this.onClose} />

                <div id="billing-center-content" className="content">

                    <Row>

                        <Col xs={6} className="align-self-center">
                            <h2 className="mb-0">Billing Center</h2>
                        </Col>

                        <Col xs={6} className="align-self-center text-right">
                            {contact ? (

                                <button onClick={this.onShowAllContacts} className="btn btn-outline-success">
                                    <i className="fas fa-users mr-2" />
                                    All Contacts
                                </button>

                            ) : (

                                <button onClick={this.onClose} className="btn btn-warning">
                                    Close
                                </button>

                            )}
                        </Col>

                    </Row>

                    <hr className="my-3" />

                    {!loading ? (
                        contact ? (

                            <ViewContact
                                contact={contact}
                                toggleBillingCenter={this.onClose}
                            />

                        ) : (

                            <SearchContacts
                                onSelectContact={(contact) => this.onSelectContact(contact)}
                            />

                        )
                    ) : (
                        <Circle />
                    )}

                </div>
            </div>

        )

    }

}

const mapStateToProps = state => {

    return {
        billingCenter: state.system.billingCenter
    };
};

export default connect(mapStateToProps, '')(BillingCenter);
