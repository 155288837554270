/*
Documentation

This file creates a modal to either update or create a new objection

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Modal } from 'reactstrap';
import _objections from '_functions/objections';
import ContactSearchInput from '../../_components/ContactSearchInput';

const required_form_fields = [
    'name',
    'severity',
    'objection_number'
]

class ModalObjectionsCrud extends React.Component {

    state = {
        objection: { severity: 1 },
        canSave: true,
        has_made: false
    }

    onSave = async () => {

        this.setState({canSave: false, error_message: null})
        //copy state
        let newState = Object.assign({}, this.state.objection);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        if(newState.objection_number === 'null') {
            newState["objection_numberState"] = 'invalid';
            errors++
        }

        this.setState({ objection: newState });

        if(!errors) {

            if(!newState.objecting_party) {
                return this.setState({error_message: 'You must select an objecting party before proceeding', canSave: true})
            }

            let action

            if(newState._id) {

                action = await _objections.update(newState._id, {
                    ...newState,
                    case              : this.props.case,
                    severity          : parseInt(newState.severity),
                    objecting_party   : newState.objecting_party._id,
                    created_by        : this.props.viewing_user._id
                })

            } else {

                action = await _objections.create({
                    ...newState,
                    case              : this.props.case,
                    severity          : parseInt(newState.severity),
                    objecting_party   : newState.objecting_party._id,
                    created_by        : this.props.viewing_user._id
                })

            }

            if(action.success) { this.props.onSuccess(action) }

        }

        this.setState({canSave: true})

    }

    componentDidMount = () => {

        if(this.props.objection && this.props.objection._id) {
            this.setState({objection: this.props.objection})
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.objection && nextProps.objection._id) {

            const o = Object.assign({}, nextProps.objection)
            if(o.objection_number)  {
                o.objection_number = o.objection_number._id
            }

            this.setState({objection: o})

        } else {
            this.setState({objection: { severity: 1 }})
        }

        // if modal is toggled off clear the objection
        if(this.props.showModal && !nextProps.showModal) this.setState({ objection: { severity: 1 } })

    }

    render() {

        const objection = this.state.objection
        const { showModal, toggleModal, objection_numbers } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {objection._id ? 'Update Objection'  : 'Create A New Objection'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={objection}
                        objName='objection'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Objection Name"
                    />

                    <StandardFormGroup
                        obj={objection}
                        objName='objection'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="severity"
                        title="Severity"
                    >
                        <option value="1">Normal</option>
                        <option value="2">Important</option>
                        <option value="3">Severe</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={objection}
                        objName='objection'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="objection_number"
                        title="Objection Number"
                    >
                        <option value="null"></option>
                        {objection_numbers.map(number => (
                            <option key={number._id} value={number._id}>{number.name}</option>
                        ))}

                    </StandardFormGroup>

                    <FormGroup>
                        <label className="form-control-label">Opposing Party</label>
                        <ContactSearchInput
                            value={objection.objecting_party || {}}
                            onSelect={(contact) => this.setState({objection: {...this.state.objection, objecting_party: contact}})}
                        />
                    </FormGroup>


                    {this.state.error_message ? (
                        <p className="mb-0 text-sm font-weight-bold text-danger">{this.state.error_message}</p>
                    ) : null}

                </div>

                <div className="modal-footer">
                    <button  disabled={!this.state.canSave}  onClick={this.onSave}  className="btn btn-success">
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                </div>

            </Modal>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        objection_numbers: state.objection_numbers.objection_numbers,

    };
};

ModalObjectionsCrud.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    onSuccess     : PropTypes.func.isRequired,
    case          : PropTypes.string.isRequired,
    objection     : PropTypes.object,
}

export default connect(mapStateToProps, '')(ModalObjectionsCrud);
