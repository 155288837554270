/*
Documentation

renders the dark bar on the bottom of the screen.
When the button is clicked we set up browser dialing

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardTitle, Modal } from 'reactstrap';
import { setBrowserDialing } from 'store/functions/system/system';

class BrowserDialingConsent extends Component {

    onConfirm = async () => {

        await setBrowserDialing(this.props.viewing_user._id)
        this.props.onConfirmModalConsent(true)

    }

    render() {

        const { showModal, toggleModal } = this.props

        return (

            <Modal
                id="end-call-modal"
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleModal()}
                size="md"
            >
                 <div className="modal-header">
                    <CardTitle className="mb-0 text-capitalize">Browser Dialing Consent</CardTitle>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0">To take this call confirm Browser Dialing consent below.</p>
                </div>

                <div className="modal-footer">
                    <button onClick={this.onConfirm} className="btn btn-success">Confirm Browser Dialing</button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(BrowserDialingConsent);

