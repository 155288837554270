export default (account_type) => {

    if(account_type === 'mc' ) {
        return 'Master Card'
    } else if(account_type === 'visa' ) {
        return 'Visa'
    } else if(account_type === 'disc' ) {
        return 'Discover'
    } else if(account_type === 'amex' ) {
        return 'American Express'
    } else if(account_type === 'jcb' ) {
        return 'JCB'
    } else if(account_type === 'diners' ) { 
        return 'Diners Club'
    }

    return null

}
 