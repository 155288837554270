/*
Documentation

this page renders the the component that shows all history of step moves
for the case. The user may revert back to any step in the history at any point

*/

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import _case_workflows from '_functions/case_workflows';
import _case_workflow_history from '_functions/case_workflow_history';
import * as privileges from '_functions/users/privileges'

import ConfirmationModal from 'components/functional/modals/Confirmation';

class History extends Component {

    state = {
        history: null,
        showModal: false,
        step_id: null,
        step_name: null,
    };

    toggleModal = (history) => this.setState({
        showModal: !this.state.showModal,
        step_id: history ? history.step_id : null,
        step_name: history ? history.step_name : null,
    })

    // calls the database to force a change to revert to a past step
    onRevertStep = async () => {

        toggleStandardLoader(true)

        await _case_workflows.moveStep({
            case: this.props.case._id,
            user: this.props.viewing_user._id,
            next_step: this.state.step_id,
            forceChange: true,
        })

        this.fetchHistory()

        toggleStandardLoader(false)

    }

    fetchHistory = async () => {

        const history = await _case_workflow_history.findByCase(this.props.case._id)
        if(history.success) this.setState({history: history.data})

    }

    render() {

        const { history, showModal, step_name } = this.state
        const _case = this.props.case


        return (

            <>

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Case Step History</CardTitle>
                    </CardHeader>

                    {history ? (
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Step</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {history.history && history.history.length ? history.history.map((h, i) => (
                                    <tr key={i}>
                                        <td>{moment.unix(h.date).format('MMM Do, YYYY - h:mm A')}</td>
                                        <td className="text-capitalize">{h.user_name}</td>
                                        <td>{h.step_name}</td>
                                        <td className="text-right">
                                            {i + 1 !== history.history.length && !_case.finished_on ? (
                                                <button
                                                    onClick={privileges.canUpdateCases() ? () => this.toggleModal(h) : null}
                                                    disabled={!privileges.canUpdateCases()}
                                                    className="btn btn-sm btn-danger"
                                                >
                                                    Revert To Step
                                                </button>
                                            ) : null}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>There is not history of moving steps for this case</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    ) : (
                        <CardBody>
                            <p onClick={this.fetchHistory} className="text-sm cursor-pointer text-underline">Click here to load the case step change history</p>
                        </CardBody>
                    )}

                </Card>

                <ConfirmationModal
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    title="Revert Step"
                    body={(<span>Are you sure you wish to revert this case back to the step: <br /><span className="text-danger font-weight-bold">{step_name}?</span><br /> <i className="fas fa-exclamation-triangle mr-2 text-danger" /> Note that This cannot be undone.</span>)}
                    onConfirmation={this.onRevertStep}
                />

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(History);
