import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, UncontrolledTooltip } from 'reactstrap';
import ContactQuestionnaires from './Questions';

const Question = ({question, answers, onSetAnswers, nestedLevel, questionnaireId, contact, onMarkDocNaOnNo, location_courts}) => {

    const [answer, setAnswer] = useState()

    const onInputChange = (e, question) => {

        const val = e.target.value;

        if(question.mark_doc_na_on_no && val === 'no' && onMarkDocNaOnNo) {
            onMarkDocNaOnNo(question, val)
        }

        setAnswer(val)
        onSetAnswers(question, val)
    }

    const onCheckBoxClick = (clickedAnswer) => {
        let newAnswer;

        if(answer && answer.includes(', ' + clickedAnswer)) {
            newAnswer = answer.replace(', ' + clickedAnswer, '');
        } else if(answer && answer.includes(clickedAnswer)) {
            newAnswer = answer.replace(clickedAnswer, '');
        } else if(answer) {
            newAnswer = answer + ', ' + clickedAnswer;
        } else {
            newAnswer = clickedAnswer;
        }

        if(newAnswer.slice(0, 2) === ', ') {
            newAnswer = newAnswer.slice(2, newAnswer.length)
        }

        onInputChange({target: { value: newAnswer }}, question );
    }

   const label = useCallback((question) => {
        return (
            <label className="form-control-label">
                {question.hint ? (
                    <>
                    <i id={`hint-${question._id}`} className="fas fa-info-circle text-info-original mr-2 cursor-pointer " />
                    <UncontrolledTooltip delay={0} placement="top" target={`hint-${question._id}`}>
                        {question.hint}
                    </UncontrolledTooltip>
                    </>
                ) : null}
            {question.description} 
            {question.map_field === 'phone' ? <span className="text-warning font-weight-bold"> (NO SPACES)</span> : ''}
            </label>
        )
   }, [])

   useEffect(() => {

        let initialAnswer = answers[question._id];

        if(question.map_field && contact[question.map_field]) {
            initialAnswer = contact[question.map_field];
        }

        setAnswer(initialAnswer);
        onSetAnswers(question, initialAnswer)
   }, [question]);

   if(question.type === 'upload') return <div />

    return (

        <div className={question.alert_on_answer && answer === question.alert_on_answer ? 'important' : ''}>
            {question.type === 'text' ? (
                <FormGroup>
                    {label(question)}
                    <Input 
                        type="text"
                        placeholder={question.placeholder ? question.placeholder : ''}
                        value={answer || ''}
                        onChange={(e) => onInputChange(e, question)}
                    />
                </FormGroup>
            ) : question.type === 'text area' ? (
                <FormGroup>
                    {label(question)}
                    <Input 
                        type="textarea"
                        placeholder={question.placeholder ? question.placeholder : ''}
                        value={answer || ''}
                        onChange={(e) => onInputChange(e, question)}
                    />
                </FormGroup>
            ) : question.type === 'yes - no' ? (
                <FormGroup>
                   {label(question)}
                    <Input 
                        type="select"
                        placeholder={question.placeholder ? question.placeholder : ''}
                        value={answer || false}
                        onChange={(e) => onInputChange(e, question)}
                    >
                        <option value="false"></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </Input>
                </FormGroup>
            ) : question.type === 'court' ? (
                <FormGroup>
                   {label(question)}
                    <Input 
                        type="select"
                        placeholder={question.placeholder ? question.placeholder : ''}
                        value={answer || false}
                        onChange={(e) => onInputChange(e, question)}
                    >
                        <option value="false"></option>
                        {location_courts.map(c => (
                            <option value={c._id} key={c._id}>{c.name}</option>
                        ))}
                    </Input>
                </FormGroup>
            ) : question.type === 'select' ? (
                <FormGroup>
                    {label(question)}
                    <Input 
                        type="select"
                        placeholder={question.placeholder ? question.placeholder : ''}
                        value={answer || false}
                        onChange={(e) => onInputChange(e, question)}
                    >
                        <option value="false"></option>
                        {question.answers.map((answer, i) => (
                            <option key={i} value={answer}>{answer}</option>
                        ))}
                    </Input>
                </FormGroup>
            ) : question.type === 'check box' ? (
                <FormGroup>
                    {label(question)}
                    {question.answers.map((possibleAnswer, i) => (
                        <div key={i} className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={possibleAnswer + i}
                                type="checkbox"
                                checked={answer && answer.includes(possibleAnswer) ? true : false}
                                onChange={() => onCheckBoxClick(possibleAnswer)}
                            />
                            <label className="custom-control-label" htmlFor={possibleAnswer + i}>
                                {possibleAnswer}
                            </label>
                        </div>
                    ))}
                    
                </FormGroup>
            ) : ''}

            {question.type === 'yes - no' && answer === 'yes' ? (
                <ContactQuestionnaires 
                    questions={question.questions}
                    answers={answers}
                    onSetAnswers={onSetAnswers}
                    nestedLevel={nestedLevel + 1}
                    questionnaireId={questionnaireId}
                    contact={contact}
                />
            ) : null}
        </div>
    )

}



const mapStateToProps = state => {
    return {
        location_courts: state.location_courts.location_courts,
    };
};

export default connect(mapStateToProps, '')(Question);