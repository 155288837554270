/*
Documentation

this method resolves by returning success true if a contact exists in fortispay
it will also resolve true if a contact is successfully created in fortispay

@param contact
contact entry from our database
must have family_name field

*/

import { architeckCall } from 'database';

export default  (contact) => {
    return new Promise (async resolve => {

        if(contact && contact.family_name) {


            //find the user in fortispau
            const contact_in_fortispay = await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'get',
                url: `/api/v1/billing/contacts/find/${contact._id}`,
            })

            //if contact is in fortispay get the payform and set their payment methods
            if(contact_in_fortispay.success) {

                return resolve({success: true, data: contact_in_fortispay.data})

            } else {

                

                //if contact is not in fortis pay add them to the system.
                const create_contact = await architeckCall({
                    method: 'post',
                    url: `/api/v1/billing/contacts/create`,
                    shouldNotShowAndLogError: true,
                    data: {
                        contact_id: contact._id,
                        first_name: contact.given_name,
                        last_name: contact.family_name,
                        email: contact.email
                    }
                })

                //resolve false if error creating contact
                if(!create_contact.success) { return resolve(create_contact) }

                return resolve({success: true, data: {
                    ...create_contact.data,
                    account_vaults: [],
                }})

            }

        } else {

            return resolve({success: false, message: "Either no contact was passed in or the contact passed in does not have a family_name property"})

        }

    })
}
