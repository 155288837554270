import React, { useState, useEffect, useCallback } from "react";
import _contact_texts from '_functions/contact_texts';

import * as footer from 'utils/footer';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import { Row } from 'reactstrap';
import * as _ from 'underscore';

import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight/index';
import Feed from './Feed';

import renderName from 'utils/renderName'
import { formatPhone } from 'utils/text';

const limit = 50;

const baseState = {
    contacts: [],
    texts: [],
    selectedContact: null,
    show: 'all',
    showLoader: true,
    skip: 0,
    filter: null,
    isReady: false
}

var lastTextLength = 0;
var lastScrollHeight = 0;
var lastFetch = 0

const RenderMessenger = () => {

    const [texts, setTexts] = useState(false)
    const [skip, setSkip] = useState(false)
    const [selectedContact, setSelectedContact] = useState(false)
    const [state, setState] = useState(baseState)

    const safeSetState = (newState) => {
        const oldState = Object.assign({}, state)
        setState({...oldState, ...newState})
    }

    const fetchTexts = (contact, forceSkip) =>  new Promise (async resolve => {

        safeSetState({skip: skip + limit})

        const textData = await _contact_texts.searchMessenger(`?contact=${contact._id}&limit=${limit}&skip=${forceSkip !== undefined ? forceSkip : skip}`)
        const texts = textData.data ? textData.data : [];        

        return resolve(texts);
        
    })

    const fetchMoreTexts = _.throttle( async () => {

        const now = Math.floor(new Date() / 1)
        if(lastFetch > now - 300) return;

        lastFetch = now;

        if(texts.length <= 0) return;
        const currentTexts = [...texts];

        const feed = document.getElementById('archk-text-feed-body')
        const initialScroll = feed.scrollHeight

        const newTexts = await fetchTexts(selectedContact);

        lastTextLength = currentTexts.length;
        lastScrollHeight = initialScroll;
        
        if(!newTexts.length) return;

        setSkip(skip + limit)

        const allTexts = newTexts.concat(currentTexts);

        setTexts(allTexts)

    }, 500)

    const onSelectContact = async (contact) => {

        safeSetState({showLoader: true})
        setSelectedContact(contact)

        const foundTexts = await fetchTexts(contact, 0)
        setTexts(foundTexts)
        setSkip(limit)
        safeSetState({ showLoader: false, isReady: true})

        const feed = document.getElementById('archk-text-feed-body')
        feed.scrollTo({ top: 5 })

        setTimeout(() => {
            feed.scrollTo({ top: feed.scrollHeight, })
        }, 200)
    
    }

    const onTextAdded = (data) => {
        if(selectedContact && data.data.contact && (data.data.contact._id === selectedContact._id || data.data.contact === selectedContact._id)) {
            const allTexts = [...texts];
            allTexts.push(data.data);

            const contact = Object.assign({}, selectedContact);
            contact.last_text = data.data.value && data.data.value.length > 18 ? data.data.value.slice(0,18) : data.data.value ? data.data.value : 'Sent Media'

            setTexts(allTexts);
            setSelectedContact(contact);
        }

    }

    const onFeedScroll = (e) => {
        if(e.target.scrollTop === 0) {
            fetchMoreTexts()
        }
    }

    const getContactIdentifier = useCallback((contact) => {
        return contact.given_name || contact.family_name ? renderName(contact) : formatPhone(contact.phone);
    }, [])

    useEffect(() => {

        footer.hide()
        document.body.classList.add('noScroll')

        io.on(socketEvents.contact_texts_added, onTextAdded)

        const feed = document.getElementById('archk-text-feed-body')
        feed.addEventListener('scroll', onFeedScroll)
        

        // console.log(lastTextLength)
        if(lastTextLength <= texts.length && texts.length !== limit) {
            feed.scrollTo({ top: feed.scrollHeight - lastScrollHeight })
        }

        return () => {

            footer.show();
            document.body.classList.remove('noScroll')
            
            io.off(socketEvents.contact_texts_added, onTextAdded);

            const feed = document.getElementById('archk-text-feed-body')
            feed.removeEventListener('scroll', onFeedScroll)

        }

    }, [state, texts, selectedContact])

    const { showLoader, isReady } = state;

    return (

        <div className="archk-messenger">
            <Row>

                <SidebarLeft 
                    onSelectContact={onSelectContact}
                    getContactIdentifier={getContactIdentifier}
                    isReady={isReady}
                    selectedContact={selectedContact}
                />

                <Feed 
                    texts={texts}
                    contact={selectedContact}
                    offset={245}
                    showLoader={showLoader}
                    getContactIdentifier={getContactIdentifier}
                />

                <SidebarRight 
                    contact={selectedContact}
                    getContactIdentifier={getContactIdentifier}
                />

            </Row>
        </div>

    );
}

export default RenderMessenger