/*
Documentation

this file shows any errors that may occur in template generation.

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from "reactstrap";

class ModalTemplateTextsErrors extends Component {

    render() {

        const { toggleModal, showModal, errors } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >

                <div className="modal-header d-block">
                    <h5 className="modal-title" id="exampleModalLabel">Template Errors</h5>
                </div>

                <div className="modal-body" >

                    <h4 className="text-capitalize">The following errors occurred generating this template text:</h4>
                    {errors && errors.length ? errors.map((e, i) => (
                        <p key={i} className="text-sm text-warning font-weight-bold mb-1">{i+1}) {e}</p>
                    ) ) : null}

                </div>

                <div className="modal-footer">
                    <button onClick={toggleModal}  className="btn btn-outline-warning">Close</button>
                </div>

            </Modal>

        )

    }

}

ModalTemplateTextsErrors.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    errors      : PropTypes.array,
}

export default ModalTemplateTextsErrors
