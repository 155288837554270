import PropTypes from 'prop-types';
import React, { Component } from 'react';
import renderDayView from '../functions/ViewDay.js/renderViewDay'

const timeIncrement = 1800; // 1 hour increments

class ViewDay extends Component {

    state = {
        shouldScroll: false
    }



    scrollToSevenAm = () => {

        const sevenAM = document.querySelector('.sevenAM')
        if(sevenAM) {  sevenAM.scrollIntoView() }

    }

    setTableWidth = () => {

        const table = document.getElementById('table-view-day')
        var wrapper = document.getElementById('table-view-day-wrapper');

        if(!wrapper || !table) return

        let furthestAwayPosition = 0
        let furthestAwayWidth = 0


        const events = document.querySelectorAll('.index.index-important')

        events.forEach(event => {

            if(event.offsetLeft > furthestAwayPosition) {
                furthestAwayPosition = event.offsetLeft;
                furthestAwayWidth = event.scrollWidth
            }

        })

        const timeWidth = 125;
        const marginWidth = 10

        const width = timeWidth + furthestAwayWidth + furthestAwayPosition + marginWidth

        if(width >= wrapper.scrollWidth) {
            table.style.width = width + 'px'
        } else {
            table.style.width = null
        }

    }

    componentWillReceiveProps = (nextProps) => {

        // if we are changing to this view set the state to scroll after update
        const currentView = this.props.showView
        const nextView = nextProps.showView

        if(nextView === 'day' && currentView !== 'day') {
            this.setState({shouldScroll: true})
        }

        setTimeout(() => {
            this.setTableWidth()
        }, 1000)

    }

    componentDidUpdate = () => {

        // if we are changing to this view we want to scroll to seven am as that is where most things start
        if(this.state.shouldScroll) {
            this.scrollToSevenAm()
            this.setState({shouldScroll: false})

        }

        this.setTableWidth()


    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setTableWidth)
    }


    componentDidMount = () => {
        this.scrollToSevenAm();
        setTimeout(() => {
            this.setTableWidth()
        }, 1000)
        window.addEventListener('resize', this.setTableWidth)
    }

    render() {

        const {events, day_to_show, showView}  = this.props

        if(showView !== 'day') { return null }

        const { markup } = renderDayView(day_to_show, events, timeIncrement, this);

        return (

            <div id="table-view-day-wrapper" className="table-responsive">

                <table id="table-view-day" className="table view-day">

                    <thead>
                        <tr>

                            <th style={{width: 120, textAlign: 'left'}}>Time</th>
                            <th style={{textAlign: 'left'}}>Event</th>

                        </tr>
                    </thead>

                    <tbody>
                        {markup}
                    </tbody>

                </table>

            </div>

        )

    }

}

ViewDay.propTypes = {

    // this is the start of the day we wish to show in the header | also control moving forward and backwards
    day_to_show: PropTypes.number.isRequired,

    //what type of view to render
    showView: PropTypes.oneOf(['day', 'week', 'month']).isRequired,

    //an combined array of case_workflow_tasks and appointments to be rendered on the calendar
    events: PropTypes.array.isRequired,

    onError: PropTypes.func.isRequired,

    onDayEventClick: PropTypes.func,
    onDayCellClick: PropTypes.func,
    renderDayEvent: PropTypes.func,
}

export default ViewDay;
