import React from 'react'

export const getMarkerPositionOnPage = (marker) => {

    const container = document.getElementById('pdf-canvas')

    if(!container) return null

    const containerPosition = container.getBoundingClientRect()
    const containerTop = containerPosition.top + window.scrollY


    const page = document.getElementById(`pdf-canvas-page-${marker.pageNumber}`)
    if(!page) return null;

    const pagePosition = page.getBoundingClientRect()
    const pageTop = pagePosition.top + window.scrollY

    const offsetFromTop = pageTop - containerTop

    return {
        top         : marker.top + offsetFromTop,
        left        : marker.left,
        width       : marker.right - marker.left,
        height      : marker.bottom - marker.top
    }

}

export const getMarkerIcon = (marker) => {

    if(!marker.type) return

    return marker.type === 'text' ? (
        <i className={`fas fa-text-width party-${marker.party}`}  />
    ) : marker.type === 'date' ? (
        <i className={`fas fa-calendar party-${marker.party}`} />
    ) : marker.type === 'initial' ? (
        <i className={`fas fa-signature party-${marker.party}`} />
    ) : (
        <i className={`fas fa-file-signature party-${marker.party}`} />
    )
}

export const getMarkerName = (marker) => {

    if(!marker.type) return

    return marker.type === 'text' ? (
        marker.answer ? marker.answer : marker.name
    ) : marker.type === 'date' ? (
        'DATE'
    ) : marker.type === 'initial' ? (
        'INITIAL'
    ) : (
        'SIGNATURE'
    )
}

