import React from "react";
import { Col, Row } from 'reactstrap';
import _classes from '../_classes';

const MessengerSidebarLeft = React.memo(({show, onShowChange}) => (
    <li>
    
        <h3 className="mb-2 text-uppercase">Open Chats</h3>

        <Row>
            <Col xs={4}>
                <p className="text-sm mb-0 display-inline-block cursor-pointer">
                    <span className={show === 'all' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => onShowChange('all')}>
                        All
                    </span>
                </p>
            </Col>
            <Col xs={4}>
                <p className="text-sm mb-0 display-inline-block cursor-pointer text-center">
                    <span className={show === 'mine' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => onShowChange('mine')}>
                        Mine
                    </span>
                </p>
            </Col>
            <Col xs={4} className="text-right">
                <p className="text-sm mb-0 display-inline-block cursor-pointer">
                    <span className={show === 'unread' ? _classes.sidebar.tabs.active : _classes.sidebar.tabs.inactive} onClick={() => onShowChange('unread')}>
                        Unread
                    </span>
                </p>
            </Col>
        </Row>

    </li>
))

export default MessengerSidebarLeft;