/*
Documentation

This file renders a card that allows for updating check dates and garnishment parties

*/

import React from 'react';
import DatePicker from 'react-datepicker';
import { Card, CardBody, CardHeader, CardTitle, FormGroup, CardFooter } from 'reactstrap';
import { convertDateToUnix } from 'utils/time';
import _garnishments from '_functions/garnishments';
import { toggleAlertBS } from 'store/functions/system/system'
import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput'

class GarnishmentLeftColumn extends React.Component {

    onUpdateFields = async () => {

        const { garnishment } = Object.assign({}, this.props)

        const action = await _garnishments.update(garnishment._id, {
            garnishing_party: garnishment.garnishing_party,
            garnishing_party_attorney: garnishment.garnishing_party_attorney,

            check_memo_1: garnishment.check_memo_1 || 'none',
            check_memo_2: garnishment.check_memo_2 || 'none',
            check_memo_3: garnishment.check_memo_3 || 'none',
            check_memo_4: garnishment.check_memo_4 || 'none',

            check_date_1: convertDateToUnix(garnishment.check_date_1),
            check_date_2: convertDateToUnix(garnishment.check_date_2),
            check_date_3: convertDateToUnix(garnishment.check_date_3),
            check_date_4: convertDateToUnix(garnishment.check_date_4),
        })

        if(action.success) {
            toggleAlertBS(false, 'Garnishment Fields Updated Successfully.')
        } else {
            toggleAlertBS(true, 'An error occurred updating garnishment fields.')
        }

    }

    render() {

        const { garnishment, onInputChange } = this.props

        return (

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">Parties And Checks</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <label className="form-control-label">Garnishing Party</label>
                        <ContactSearchInput
                            onSelect={(contact) => onInputChange('garnishing_party', contact)}
                            value={garnishment.garnishing_party}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Garnishing Party Attorney</label>
                        <ContactSearchInput
                            onSelect={(contact) => onInputChange('garnishing_party_attorney', contact)}
                            value={garnishment.garnishing_party_attorney}
                        />
                    </FormGroup>

                    <hr />

                    <FormGroup className="mb-5">
                        <label className="form-control-label">Recovery Check Date</label>
                        <div
                            className={
                                garnishment.check_date_1State === 'invalid' ? 'date-picker-wrapper invalid' :
                                garnishment.check_date_1State === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                            }
                        >
                            <DatePicker
                                selected={garnishment.check_date_1}
                                onChange={date => onInputChange('check_date_1', date)}
                            />
                        </div>

                        <StandardFormGroup
                            obj={garnishment}
                            objName="garnishment"
                            onChange={(o, f, v) => onInputChange('check_memo_1', v)}
                            type="text"
                            field={'check_memo_1'}
                            title={'Recovery Check Memo'}
                        />

                    </FormGroup>

                    <FormGroup className="mb-5">
                        <label className="form-control-label">Additional Recovery Check Date</label>
                        <div
                            className={
                                garnishment.check_date_2State === 'invalid' ? 'date-picker-wrapper invalid' :
                                garnishment.check_date_2State === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                            }
                        >
                            <DatePicker
                                selected={garnishment.check_date_2}
                                onChange={date => onInputChange('check_date_2', date)}
                            />
                        </div>

                        <StandardFormGroup
                            obj={garnishment}
                            objName="garnishment"
                            onChange={(o, f, v) => onInputChange('check_memo_2', v)}
                            type="text"
                            field={'check_memo_2'}
                            title={'Additional Recovery Check Memo'}
                        />
                    </FormGroup>

                    <FormGroup className="mb-5">
                        <label className="form-control-label">Additional Recovery Check Date</label>
                        <div
                            className={
                                garnishment.check_date_3State === 'invalid' ? 'date-picker-wrapper invalid' :
                                garnishment.check_date_3State === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                            }
                        >
                            <DatePicker
                                selected={garnishment.check_date_3}
                                onChange={date => onInputChange('check_date_3', date)}
                            />
                        </div>

                        <StandardFormGroup
                            obj={garnishment}
                            objName="garnishment"
                            onChange={(o, f, v) => onInputChange('check_memo_3', v)}
                            type="text"
                            field={'check_memo_3'}
                            title={'Additional Recovery Check Memo'}
                        />
                    </FormGroup>

                    <FormGroup className="mb-5">
                        <label className="form-control-label">Additional Recovery Check Date</label>
                        <div
                            className={
                                garnishment.check_date_4State === 'invalid' ? 'date-picker-wrapper invalid' :
                                garnishment.check_date_4State === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                            }
                        >
                            <DatePicker
                                selected={garnishment.check_date_4}
                                onChange={date => onInputChange('check_date_4', date)}
                            />
                        </div>

                        <StandardFormGroup
                            obj={garnishment}
                            objName="garnishment"
                            onChange={(o, f, v) => onInputChange('check_memo_4', v)}
                            type="text"
                            field={'check_memo_4'}
                            title={'Additional Recovery Check Memo'}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={this.onUpdateFields} className="btn btn-success"><i className="fas fa-save mr-2" /> Update Fields</button>
                </CardFooter>
            </Card>

        );
    }
}

GarnishmentLeftColumn.propTypes = {
    garnishment: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired
}

export default GarnishmentLeftColumn
