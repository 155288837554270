/*
Documentation

This page shows everything to update  and view a case
In some cases the type of case feed will be 'note' and this may get confusing

*/

import React from "react";
import { Col, Row } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import Alerts        from'./Alerts';
import Roles         from'./Roles';
import Overview      from'./Overview';
import Appointments  from'./Appointments';
import Parties       from'./Parties';

import Feed          from'../Feed';
import ModalUpdate   from'./ModalUpdate';

class CaseHomePage extends React.Component {

    state = {
        showModal: false
    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    render() {

        const { navPills, case_workflow, garnishments, objections, getCase } = this.props
        const _case = this.props.case

        const { showModal } = this.state;

        if(!_case) return <div className="py-6"><Circle /></div>

        return (

            <>
            <Overview
                navPills={navPills}
                case={_case}
                garnishments={garnishments}
                objections={objections}
                case_workflow={case_workflow}
                toggleModal={this.toggleModal}
                getCase={getCase}
            />

            <Row>

                <Col lg={4} >
                    <div id="case_home_left_col">

                        <Parties navPills={navPills} case={_case} toggleModal={this.toggleModal} />
                        <Roles case={_case} navPills={navPills} />
                        <Appointments case={_case} navPills={navPills} contact_id={_case.contact._id} />

                    </div>
                </Col>

                <Col lg={4}>
                    <Alerts navPills={navPills} case={_case}  />
                </Col>

                <Col lg={4}>
                    <Feed navPills={navPills} case={_case} />
                </Col>

            </Row>

            <ModalUpdate
                case={_case}
                showModal={showModal}
                toggleModal={this.toggleModal}
            />

            </>

        );
    }
}

export default CaseHomePage
