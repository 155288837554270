
import moment from 'moment';
import React from 'react';
import { getAppAndEventsForADay, getEventsBetweenInterval } from '../utils';
import getElementOffset from 'utils/position/getElementOffset'

const renderEmptyDay = (placeholder_day) => {
    return (
        <td key={placeholder_day} className="cell day-of-other-month">
            <span className="day">{placeholder_day}</span>
        </td>
    )
}

const setActiveCell = (e) => {

    //get all with this class and remove them
    let previousActive = document.querySelectorAll('.archk-calendar-active')
    for (var i = 0; i < previousActive.length; i++) {
        previousActive[i].classList.remove('archk-calendar-active')
    }

    //make sure we have an element, wrapped in if block just in case
    if(e.target ) {

        if(!e.target.classList.value.includes('cell')) {

            const closestCell = e.target.closest('.cell')

            if(closestCell) {
                closestCell.classList.add('archk-calendar-active')
            }

        } else {
            e.target.classList.add('archk-calendar-active')

        }

    }

}

const getAppointmentsMarkup = (appointments, _this) => {

    // if custom renderer is used call it
    if(_this.props.renderViewMonth) return _this.props.renderViewMonth(appointments)

    if(!appointments && appointments.length) { return null }

    let appObject = {}
    let markup = []


    appointments.forEach(appointment => {

        if(appointment.assigned_to && appointment.assigned_to.length) {


            appointment.assigned_to.forEach(user => {


                const color = user.color ? user.color :  'black'

                if(appObject[user._id]) {
                    appObject[user._id].appointments.push(appointment)
                } else {
                    appObject[user._id] = {
                        appointments: [appointment],
                        color_index: color
                    }
                }

            })

        }

    })


    Object.keys(appObject).forEach((key, i) => {

        const appointments = appObject[key].appointments;
        let margin =  i >= 1 ? ' ml-1' : null

        markup.push((
            <React.Fragment key={key}>
                <span className="full">
                    <span style={{background: appObject[key].color_index }} className={'index index-important' + margin}>
                        <i className={"fas fa-calendar mr-1 "} />
                        {appointments.length}
                    </span>
                </span>

                <span className="circle">
                    <i className={"fas fa-calendar mr-2 "} />
                </span>
            </React.Fragment>
        ))

    })

    return markup

}

// gets the cell height on the month view
const getCellHeight = () => {

    // this can change based off of the css of the system this is used in
    const systemOffset = 60;

    var bodyHeight = window.innerHeight
    const archkCalendar = document.querySelectorAll('.archk-calendar')

    if(archkCalendar && archkCalendar.length) {

        const calendar = archkCalendar[0];
        const { top }  = getElementOffset(calendar);

        let calendarHeight =  bodyHeight - top;
        calendarHeight = calendarHeight - systemOffset

        return calendarHeight / 6

    }

    // if failed to find the calendar return 0
    return 0

}

export default (day_to_show, events, _this) => {

    const calendarHeight = getCellHeight()

    //used for testing
    let calendar_days_rendered = 0
    let appointments_rendered = 0
    let tasks_rendered = 0

    let markup = [];
    let weeks = [ [], [], [], [], [], [] ]
    let counter = 0;

    const startOfMonth = parseInt(moment.unix(day_to_show).startOf('month').format('X'))
    const endOfMonth = parseInt(moment.unix(startOfMonth).endOf('month').format('X'))

    const startOfMonthString = moment.unix(startOfMonth).format('ddd')
    const endOfLastMonthDay = parseInt(moment.unix(startOfMonth - 86400).format('DD'))

    const lastDayOfMonth = parseInt(moment.unix(endOfMonth).format('D'))

    ////////////////////////////////////////////////////////////////////////////////////
    // this code block looks for the empty days that should be on the calendar
    // aka days that are from other months that should show up grayed out
    let emptyDays = 0

    let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    days.forEach((day, i) => {
        if(day === startOfMonthString) { emptyDays = i + 1 }
    })
    ////////////////////////////////////////////////////////////////////////////////////

    //get all events for this month
    let month_events_array = getEventsBetweenInterval(events, startOfMonth, endOfMonth)

    //create all the td's for the month
    for (let i = 1; i <= lastDayOfMonth + emptyDays; i++) {


        if(!emptyDays || i > emptyDays) {

            calendar_days_rendered++

            const object = getAppAndEventsForADay(month_events_array, startOfMonth, i - emptyDays)

            //send this data back when a cell is clicked
            const onClickData = {
                day_of_month    : i - emptyDays,
                start_date      : startOfMonth + (86400 * (i - emptyDays - 1)) ,
                end_date        : startOfMonth + (86400 * (i - emptyDays - 1) + 86399) ,
                appointments    : object.appointments,
            }

            let time_now = Math.floor(new Date()) / 1000;
            let start_of_rendering_day = startOfMonth + ( 86400 * (i - emptyDays - 1));

            let is_today = (time_now >= start_of_rendering_day && time_now < start_of_rendering_day + 86400) ;
            let classNames = is_today ? 'today cell' : 'cell'
            weeks[counter].push((
                <td
                    key={i - emptyDays}
                    className={classNames}
                    onClick={(e) =>{

                        setActiveCell(e);
                        _this.props.onViewMonthCellClick(onClickData)

                    }}
                >
                    {/* {is_today ? <i className="fas fa-circle indicator text-green"></i> : null} */}

                    <span className={is_today ? "is-today day" : "day"}>{i - emptyDays}</span>

                     {object.appointments.length ?
                        <div className="month-content-wrapper">
                            {/* <i className="fas fa-calendar mr-2" />
                            {object.appointments.length}  */}
                            {getAppointmentsMarkup(object.appointments, _this)}
                        </div>
                    : null }

                </td>
            ))

        } else {
            weeks[counter].push(renderEmptyDay(endOfLastMonthDay - emptyDays + i))
        }

        //this will end up creating the tr's that wee need
        if(i === 7 || i === 14 || i ===21 || i === 28 || i === 35) {
            counter++
        }

    }

    //push each row of td's into a tr
    weeks.forEach((w, wIndex) => {

        // only push rows if they have content
        if(w &&w.length) {

             //here we push extra days at the end of the month
            if(w && w.length !== 7 && w.length !== 0) {

                let counter = w.length
                let placeholder_day = 1

                while(counter < 7) {
                    w.push(renderEmptyDay(placeholder_day))

                    counter++
                    placeholder_day++
                }

            }

            markup.push((
                <tr key={wIndex + calendarHeight} style={{height: calendarHeight}}>
                    {w}
                </tr>
            ))
        }

    })

    return { markup, calendar_days_rendered, appointments_rendered, tasks_rendered }

}
