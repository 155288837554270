import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import store from 'store'

// store parent function to push a new notification, set on run
let pushNotification;

const onDocumentMerged = async (data) => {

    const payload = data.data
    const storeData = store.getState()

    const viewing_user = storeData.auth.viewing_user

    if(viewing_user && viewing_user._id === payload.user_id) {

        const date = Math.floor(new Date() / 1000)

        pushNotification({
            _id: viewing_user._id + date,
            link: `/dashboard/cases/view/${payload.case_id}?nav=3&doc=${payload.document_id}`,
            color: payload.success ? 'success' : 'danger',
            icon: 'fas fa-file',
            title: payload.success ? 'Document Merged' : 'Document Merge Failed',
            message: payload.success ? 
                `Your document ${payload.friendly_name} has been merged. Click here to access the document.` : 
                `Something went wrong merging the document: ${payload.friendly_name}`,
            created_at: date
        }, 10000)

    }

}


export default {
    run: (addNotification) => {

        pushNotification = addNotification;
        io.on(socketEvents.document_merged, onDocumentMerged);
    },
    terminate:  () => io.off(socketEvents.document_merged, onDocumentMerged)
}
