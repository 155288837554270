/*
Documentation

this is the file that renders the merge component lighthouse preview

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'

import PdfThumbnail from './PdfThumbnail'

class MergePreview extends React.Component {

    state = {
        previewIndex: 0,
        loading: false
    }

    // show we are done loading
    onLoadedDocument = () => this.setState({documentLoaded: true})

    // remove dashes and underscores from document name
    formatDocName = (name) => {

        name = name.toLowerCase();
        name = name.replace(/_/g, ' ')
        name = name.replace(/-/g, ' ')

        return name

    }

    stopLoading = () => this.setState({loading: false})

    // allows left arrow to be clicking showing the previous document
    onMoveLeft = () => {

        let previewIndex = this.state.previewIndex - 1;
        previewIndex     = previewIndex < 0 ? this.props.documents.length -1 : previewIndex;

        this.setState({documentLoaded: false, previewIndex, loading: true}, this.stopLoading);

    }

    // allows left arrow to be clicking showing the next document
    onMoveRight = () => {


        let previewIndex = this.state.previewIndex + 1;
        previewIndex     = previewIndex >= this.props.documents.length ? 0 : previewIndex;

        this.setState({documentLoaded: false, previewIndex, loading: true}, this.stopLoading);

    }

    listenForKeyPress = (e) => {

        const { documents, onRotateImage } = this.props
        const { previewIndex } = this.state
        const doc = documents[previewIndex]

        if(e.key === 'ArrowLeft') {
            this.onMoveLeft()
        } else if(e.key === 'ArrowRight') {
            this.onMoveRight()
        } else if(e.key === 'q') {
            onRotateImage(doc, -90, previewIndex)
        } else if(e.key === 'w') {
            onRotateImage(doc, 90, previewIndex)
        } else if(e.key === 'e') {
            onRotateImage(doc, 180, previewIndex)
        }

    }

    componentWillReceiveProps = (nextProps) => {

        // when we load, show the file of the index passed in
        if(!this.props.showPreview && nextProps.showPreview) {
            this.setState({previewIndex: nextProps.previewIndex, documentLoaded: false})

            window.addEventListener('keydown', this.listenForKeyPress);
            
        } else if(this.props.showPreview && !nextProps.showPreview) {

            window.removeEventListener('keydown', this.listenForKeyPress);

        }

    }

    render() {

        const { documents, showPreview, togglePreview, rotatedDocuments, onRotateImage } = this.props
        const { previewIndex, loading, documentLoaded} = this.state;

        const doc = documents[previewIndex]

        if(!showPreview || !doc) return <div />

        let rotation = rotatedDocuments[doc._id + doc.page]
        rotation = rotation ? rotation : 0;
    

        return (

            <div className="merge-lighthouse-preview">

                <div className="merge-lighthouse-preview-content">
                    <Row>
                        <Col xs={12} className="align-self-center position-relative">

                            <i className="fas fa-times close" onClick={togglePreview} />

                            <i className="fas fa-caret-left caret move-left"   onClick={this.onMoveLeft}/>
                            <i className="fas fa-caret-right caret move-right" onClick={this.onMoveRight} />

                            <button className="btn btn-success rotate-button rotate-left" onClick={() => onRotateImage(doc, -90, previewIndex)}>Rotate Left</button>
                            <button className="btn btn-warning rotate-button rotate-right" onClick={() => onRotateImage(doc, 90, previewIndex)}>Rotate Right</button>
                            <button className="btn btn-primary rotate-button flip" onClick={() => onRotateImage(doc, 180, previewIndex)}>Flip Image</button>

                            {loading ? <div /> : (
                                <div style={{transform: `rotate(${rotation}deg)`, maxWidth: '90vh', maxHeight: '90vh', margin: 'auto'}}>
                                    {doc.url.includes('.pdf') ? (
                                        <>
                                        <PdfThumbnail className="z-depth-5" doc={doc} docID={`pdf_lighthouse_${doc._id + doc.page}`} width={window.innerWidth } onLoadedDocument={this.onLoadedDocument} />
                                        {!documentLoaded ? <div className="loading-container"><Circle /></div> : null}
                                        </>
                                    ) : (
                                        <img className="z-depth-5" style={{maxWidth: '100%', borderRadius: 6, border: 'solid 1px #ddd'}} src={doc.url} alt="..."  />
                                    )}
                                </div>
                            )}
                            

                        </Col>
                    </Row>
                </div>

                <Container fluid>
                    <p className="merge-preview-text text-capitalize">
                        {this.formatDocName(doc.friendly_name)} - Page {doc.page}
                    </p>
                </Container>

                <span className="merge-preview-counter">Page {previewIndex + 1}/{documents.length}</span>


            </div>
                  
        );
    }
}

MergePreview.propTypes = {
    documents       : PropTypes.array.isRequired,
    showPreview     : PropTypes.bool.isRequired,
    previewIndex    : PropTypes.number.isRequired,
    togglePreview   : PropTypes.func.isRequired,
}

export default MergePreview
