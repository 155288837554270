/*
Documentation

renders an empty card, a simple version of a skeleton load

*/

import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import Circle from './Circle';

class LoadingCard extends Component {

    render() {


        return (

            <Card>
                <CardBody>
                    <Circle />
                </CardBody>
            </Card>

        )

    }

}

export default LoadingCard;
