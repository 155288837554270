import * as actionTypes from '../../actions';

const initialState = {
    questionnaires: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_QUESTIONNAIRES:
            
            return {
                ...state,
                questionnaires: action.payload.data,
            }

        default: return state;

    }

}

export default reducer;
