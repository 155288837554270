/*
Documentation

This file renders a modal to either create or update a garnishment
Simply it takes 1 field: name

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import _garnishments from '_functions/garnishments';

const required_form_fields = [
    'name',
]

class ModalGarnishmentsCrud extends React.Component {

    state = {
        garnishment: {},
        canSave: true,
        has_made: false
    }

    onSave = async () => {

        this.setState({canSave: false, error_message: null})
        //copy state
        let newState = Object.assign({}, this.state.garnishment);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ garnishment: newState });

        if(!errors) {

            let action

            if(newState._id) {

                action = await _garnishments.update(newState._id, {
                    name: newState.name,
                })

            } else {

                action = await _garnishments.create({
                    ...newState,
                    case          : this.props.case,
                    created_by    : this.props.viewing_user._id,
                    status        : 'need to send demand'
                })

            }

            if(action.success) { this.props.onSuccess(action) }

        }

        this.setState({canSave: true})

    }

    componentDidMount = () => {

        if(this.props.garnishment && this.props.garnishment._id) {
            this.setState({garnishment: this.props.garnishment})
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.garnishment && nextProps.garnishment._id) {

            const o = Object.assign({}, nextProps.garnishment)
            this.setState({garnishment: o})

        } else {

            this.setState({garnishment: {}})

        }

        // if modal is toggled off clear the garnishment
        if(this.props.showModal && !nextProps.showModal) this.setState({ garnishment: {} })

    }

    render() {

        const garnishment = this.state.garnishment
        const { showModal, toggleModal } = this.props

        return (
        <>

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {garnishment._id ? 'Update Garnishment'  : 'Create A New Garnishment'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={garnishment}
                        objName='garnishment'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Garnishment Name"
                    />

                    {this.state.error_message ? (
                        <p className="mb-0 text-sm font-weight-bold text-danger">{this.state.error_message}</p>
                    ) : null}

                </div>

                <div className="modal-footer">
                    <button  disabled={!this.state.canSave}  onClick={this.onSave}  className="btn btn-success">
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                </div>

            </Modal>

        </>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ModalGarnishmentsCrud.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    onSuccess     : PropTypes.func.isRequired,
    case          : PropTypes.string.isRequired,
    garnishment   : PropTypes.object,
}

export default connect(mapStateToProps, '')(ModalGarnishmentsCrud);
