/*
Documentation

allows a user to switch between read and unread texts

*/

import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types'

class ChangeEmailReadFilter extends Component {

    render() {

        const {  showing, onChangeShowing } = this.props

        return (

            <Row >

                <Col md={3} className="align-self-center">
                    <h3 className="title">Texts</h3>
                </Col>

                <Col md={9} className="align-self-center text-right showing-container">
                    <p className="text-sm mb-0">
                        <span className={showing === 'all' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('all')}>All</span>
                        {' / '}
                        <span className={showing === 'unread' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('unread')}>Unread</span>
                        {' / '}
                        <span className={showing === 'assigned_to' ? 'active showing-action' : 'showing-action'} onClick={() => onChangeShowing('assigned_to')}>Assigned</span>
                    </p>
                </Col>

            </Row>

        )

    }

}

ChangeEmailReadFilter.propTypes = {
    showing           : PropTypes.string.isRequired,
    onChangeShowing   : PropTypes.func.isRequired,
}

export default ChangeEmailReadFilter
