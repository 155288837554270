/*
Documentation

this renders a circle that spins via css

*/

import React from 'react'

const LoadingCircle = (props) => {

    return (
        <div className="text-center" style={props.style ? props.style : {}}>
            <div className={`lds-ring ${props.color === 'dark' ? 'dark' : null}`}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );

}

export default LoadingCircle;
