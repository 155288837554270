import store from 'store';
import * as configuration from '_settings/configuration'

const findConfiguration = (configuration_name, viewing_user = store.getState().auth.viewing_user) => {

    if(viewing_user) {

        const config = viewing_user.configuration;
        if(config && config.length) return config.includes(configuration[configuration_name]) ? true : false

    }

    return false;

}

export const feedShowFullDate = () => findConfiguration(configuration.feed_show_full_date);
