import React, { Component } from 'react';
import { Card, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import _contacts from '_functions/contacts';
import _contact_emails from '_functions/contact_emails';
import _template_emails from '_functions/template_emails';

import Body from './Body';
import To from './To';
import Contact from './Contact';
import From from './From';
import Toolbar from './Toolbar';
import Subject from './Subject';
import ModalTemplateEmailsErrors from './ModalTemplateEmailsErrors';

import Circle from 'components/markup/loading/Circle';

import { stripHTML } from 'utils/code'

import getAuthorizedSendingEmails from '_functions/users/getAuthorizedSendingEmails'
import renderName from 'utils/renderName';

import { toggleAlertBS } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

import _recent_activity from '_functions/recent_activity';

class SendEmail extends Component {


    state = {

        // email properties
        body                : "",
        subject             : "",
        to                  : [],
        attachments         : [],
        sendingFrom         : null,
        lockSendingFrom     : null,
        from                : null,
        contact             : null,

        // used for selection of sending email
        sendEmailOptions    : null,
        showModal           : false,

    };

    toggleModal = (errors) => this.setState({showModal: !this.state.showModal, errors})

    onSelectTemplate = async (values) => {

        const contact = this.state.contact
        const contact_id = contact && contact._id ? contact._id : contact ? contact : undefined

        if(!contact_id) return toggleAlertBS(true, `A contact must be set before generating a template email.`)

        let { attachments, subject } = values.value

        if(attachments && attachments.length) {
            attachments.forEach(a => a.name = a.friendly_name)
        }

        if(!attachments) attachments = [];

        const finalAttachments = this.state.attachments.concat(attachments)

        let body = ''

        const generatedText = await _template_emails.generate({
            contact_id: contact_id, 
            template_id: values.value._id, 
            type: 'email', 
            show_errors: true,
            case_id: this.props.case ? this.props.case : undefined
        })

        if(generatedText.success) {

            body = generatedText.data
            subject = generatedText.subject

            if(generatedText.errors.length) this.toggleModal(generatedText.errors)

        }

        this.setState({body, subject, attachments: finalAttachments})

    }

    onSend = async () => {

        this.setState({error: null})

        const { body, subject, to, attachments, sendingFrom, contact } = this.state;
        const { microsoft_message_id } = this.props;

        // return if there is no actual text content in the body of the email
        if(!stripHTML(body)) return this.setState({error: 'You cannot send a blank email.'})
        // return if they have not specified who the email is sendingFrom
        if(!sendingFrom) return this.setState({error: 'You must specify who this email is from.'})
        // return if subject is blank
        if(!subject) return this.setState({error: 'You cannot send an email without a subject.'})
        // return if there is not at least 1 valid person this email is being sent to
        if(!to.length) return this.setState({error: 'You must add at least one email address to send this email to.'})

        const finalToArray = []
        const contact_id = contact ? contact._id : undefined

        let emailError = false

        to.forEach(obj => {

            if(obj.email) {
                finalToArray.push(obj.email)
            } else {
                emailError = <span>There was no email found for the recipient: <span className="text-capitalize">{renderName(obj)}. </span>Either remove them from the "To" list or add their email via the contact update page.</span>
            }

        })

        if(emailError) return this.setState({error: emailError})

        let finalAttachments = [];

        var bodyFormData = new FormData();


        if(attachments && attachments.length) {
            attachments.forEach((a, i) => {

                if(a.document) {
                    finalAttachments.push(a)
                } else {
                    bodyFormData.append('file', a);
                }

            })
        }

        bodyFormData.append('user', this.props.viewing_user._id);
        bodyFormData.append('value', body);
        bodyFormData.append('to', JSON.stringify(finalToArray)  );
        bodyFormData.append('from', sendingFrom);
        bodyFormData.append('send', 'microsoft');
        bodyFormData.append('subject', subject);

        if(contact_id) bodyFormData.append('contact', contact_id);

        if(finalAttachments && finalAttachments.length) bodyFormData.append('attachments', JSON.stringify(finalAttachments));
        if(microsoft_message_id) bodyFormData.append('microsoft_message_id', microsoft_message_id);


        this.setState({isSending: true})

        if(this.props.onSendingStart) this.props.onSendingStart()

        const sent = await _contact_emails.create(bodyFormData)

        _recent_activity.update({title: 'Email Sent', value: `<span style="display: block">${subject}</span><span style="display: block">${body}</span>`})


        if(sent.success) {

            toggleAlertBS(false, `Email has been sent and will show up in the contacts feed shortly.`)
            if(this.props.onSuccess) this.props.onSuccess(sent.data)
            
            _analytics.events.create({event: ANALYTIC_EVENTS.EMAILS_SENT});
            if(this.props.isReply) _analytics.events.create({event: ANALYTIC_EVENTS.EMAILS_REPLIED});

        } else {

            if(this.props.onError) this.props.onError(sent)

        }

        this.setState({isSending: false})


    }

    setEmailPrivileges = () => {

        const { viewing_user, company } = this.props;

        const authorizedSendingEmails = getAuthorizedSendingEmails(viewing_user.email, company)

        let sendEmailOptions = []


        authorizedSendingEmails.forEach(email => sendEmailOptions.push({value: email, label: 'From: ' + email}))

        const { from, sendingFrom } = this.props
        const lockSendingFrom = sendingFrom ? true : false

        this.setState({sendEmailOptions, from, sendingFrom, lockSendingFrom})

    }

    onBodyChange        = (body)        => this.setState({body})
    onToChange          = (to)          => this.setState({to})
    onSendingFromChange = (sendingFrom) => this.setState({sendingFrom})
    onAttachmentsChange = (attachments) => this.setState({attachments})
    onSubjectChange     = (subject)     => this.setState({subject})
    onContactChange     = (contact)     => this.setState({contact})

    setToOnMount = () => {

        let { to, from, useStrictEmail } = this.props;
        let populatedTo = []

        if(!to) to = []

        // if sent to the viewing user filter out, dont send to themselves
        to = to.filter(to => to !== this.props.sendingFrom)
        // if this email is from someone add them to list of receiving emails to send back to
        if(from && from !== this.props.sendingFrom) to.push(from)

        let counter = 0

        if(to && to.length) {

            to.forEach(async (email) => {

                const foundContact = await _contacts.findByEmail(email);

                if(foundContact.data && !useStrictEmail) {

                    const c = foundContact.data
                    populatedTo.push({_id: c._id, given_name: c.given_name, family_name: c.family_name, email: c.email})

                } else {

                    const u = this.props.users.find(user => user.email === email)

                    if(u) {
                        populatedTo.push({_id: u._id, given_name: u.given_name, family_name: u.family_name, email: u.email})
                    } else {
                        populatedTo.push({_id: email, email})
                    }

                }

                counter++
                // when we are done looping finish and set state to reflect to values
                if(counter === to.length) this.setState({to: populatedTo})

            })

        }


    }

    setFieldsOnMount = () => {

        const { body, subject } = this.props

        this.setState({
            body    : body ? body : "",
            subject : subject ? subject : "",
        })

    }

    fetchContactOnMount = async () => {

        const { contact } = this.props

        if(contact) {
            const foundContact = await _contacts.findById(contact, true)
            if(foundContact.data) this.setState({contact: foundContact.data})
        }
    }

    componentWillReceiveProps = (nextProps) => {

        // if viewing a new message refresh everything but email privileges
        if(this.props.microsoft_message_id !== nextProps.microsoft_message_id) {
            this.setFieldsOnMount()
            this.setToOnMount()
            this.fetchContactOnMount()
        }

    }

    componentDidMount = () => {

        this.setEmailPrivileges();
        this.setFieldsOnMount()
        this.setToOnMount()
        this.fetchContactOnMount()

        try { document.querySelector('.ql-editor').focus() } catch(e) {}

    }

    render() {

        const { error, body, to, attachments, sendEmailOptions, subject, contact, sendingFrom, lockSendingFrom, isSending } = this.state
        const { showModal, errors } = this.state;
        const { division } = this.props;

        return (

            <Card className="archk-send-email mb-0">

                <From
                    onSendingFromChange={this.onSendingFromChange}
                    sendEmailOptions={sendEmailOptions}
                    sendingFrom={sendingFrom}
                    lockSendingFrom={lockSendingFrom}
                />

                <Subject
                    onSubjectChange={this.onSubjectChange}
                    subject={subject}
                />

                <Contact
                    onContactChange={this.onContactChange}
                    contact={contact}
                />

                <To
                    onToChange={this.onToChange}
                    to={to}
                />

                <Body
                    body={body}
                    onBodyChange={this.onBodyChange}
                />

                <Toolbar
                    attachments={attachments}
                    onAttachmentsChange={this.onAttachmentsChange}
                    onSend={this.onSend}
                    onSelectTemplate={this.onSelectTemplate}
                    division={division}
                />

                <ModalTemplateEmailsErrors
                    showModal={showModal}
                    toggleModal={() => this.toggleModal()}
                    errors={errors}
                />

                {isSending ? (
                    <CardFooter><Circle /></CardFooter>
                ) : null }

                {error && <div className="alert alert-warning mb-0">{error}</div>}

            </Card>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        company: state.companies.company,
        location_courts: state.location_courts.location_courts,
        users: state.users.users,
    };
};

SendEmail.propTypes = {
    sendingFrom             : PropTypes.string,
    body                    : PropTypes.string,
    from                    : PropTypes.string,
    subject                 : PropTypes.string,
    microsoft_message_id    : PropTypes.string,
    contact                 : PropTypes.string,
    division                : PropTypes.string,
    case                    : PropTypes.string,
    to                      : PropTypes.array,

    onSuccess               : PropTypes.func,
    onError                 : PropTypes.func,
    onSendingStart          : PropTypes.func,
}

export default connect(mapStateToProps, '')(SendEmail);
