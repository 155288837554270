/*
Documentation

returns an object with top and left positioning when passed in a js element

*/

export default (el) => {

    if(!el) {
        return console.log('el is not defined at utils/position/getElementOffset')
    }

    try {

        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }

    } catch(e) {

        console.log('error at utils/position/getElementOffset')
        console.log(e)

        return {}

    }

}
