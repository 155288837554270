/*
Documentation

shows the sidebar of the merge component=

*/

import PropTypes from 'prop-types'
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import PdfThumbnail from './PdfThumbnail'

class MergeDocumentsSidebar extends React.Component {

    state = {
        error: false
    }

    validateMerge = () => {

        const friendly_name = this.state.name

        if(!friendly_name) return this.setState({error: true})
        if(friendly_name.includes('.')) return this.setState({error: true})

        let finalName = friendly_name.trim()
        finalName = finalName.replace(/ /g, '_')

        this.setState({error: false})
        this.props.onMerge(finalName)

    }

    render() {

        const { onRestorePage, deletedDocuments } = this.props
        const { error } = this.state

        return (

            <div className="merge-sidebar z-depth-3">
                
                <div className="section">
                    <h3 className="mb-0">Document Details</h3>
                </div>

                <ul>
                    <li>
                        <FormGroup>
                            <label className="form-control-label">Document Name</label>
                            {error ? <p className="me-0 text-sm font-weight-bold text-danger">The document name cannont be left blank or contain any periods.</p> : null}
                            <Input 
                                type="text"
                                value={this.state.name || ''}
                                onChange={(e) => this.setState({name: e.target.value})}
                            />
                        </FormGroup>
                    </li>
                    <li>
                        <button onClick={this.validateMerge} className="btn btn-success btn-block">Merge Document</button>
                    </li>
                </ul>

                <div className="section">
                    <h3 className="mb-0">Deleted Pages</h3>
                </div>

                <ul>
                    <li>
                        {deletedDocuments.length ? deletedDocuments.map(doc => (
                            <div className="z-depth-1 merge-card" style={{ borderColor: doc.color }}>

                                <div className="merge-header">
                                    <p className="text-sm mb-0">Deleted</p>
                                </div>

                                <div className="merge-preview">

                                    <div className="merge-tint" style={{background: doc.color}} />
                                    {doc.url.includes('.pdf') ? (
                                        <PdfThumbnail doc={doc} docID={`pdf_thumbnail_${doc._id + doc.page}`} />
                                    ) : (
                                        <img style={{maxWidth: '100%', borderRadius: 6, border: 'solid 1px #ddd'}} src={doc.url} alt="..."  />
                                    )}
                                </div>

                                <i className="fas fa-plus text-success merge-restore-icon " onClick={() => onRestorePage(doc)} />

                            </div>
                        )) : (
                            'No pages have been deleted from this document.'
                        )}
                    </li>
                </ul>

            </div>
                  
        );
    }
}

MergeDocumentsSidebar.propTypes = {
    onMerge             : PropTypes.func.isRequired,
    onRestorePage       : PropTypes.func.isRequired,
    deletedDocuments    : PropTypes.array.isRequired,
}

export default MergeDocumentsSidebar
