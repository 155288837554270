import _workflow_steps from '_functions/workflow_steps'
import _workflows from '_functions/workflows'

const fetchAllWorkflowSteps = () => new Promise (async resolve => {

    async function fetchData() {

        const workflows = await _workflows.find();
        const data = [];

        const fetchWorkflow = async (index) => {

            const workflow = workflows.data[index];

            if(!workflow) {
                return resolve(data);
            }

            const workflow_steps = await _workflow_steps.findByWorkflow(workflow._id)
            if(workflow_steps.data) {
                data.push({workflow, steps: workflow_steps.data})
            }

            fetchWorkflow(index + 1)

        }

        fetchWorkflow(0)

    }

    fetchData();

})

export default fetchAllWorkflowSteps;