/*
Documentation

this is the loader that gets shown whenever the dashboard is loading,
not needed anywhere else so unless this throws an error leave it alone

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';

class Loader extends Component {

    state = {
        showLoader: false,
        faderOpacity: 1,
    };

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.loader !== this.props.loader) {

            if(nextProps.loader === true) {

                //this is used to hold of on loading for a fraction of a second
                //if we set this to not load in that time don't bother showing the loader
                this.setState({shouldShow: true})
                setTimeout(() => {
                    if(this.state.shouldShow) {
                        this.setState({showLoader: nextProps.loader})
                    }
                }, 150)
            }

            if(this.props.loader === true && nextProps.loader === false) {
                this.setState({shouldShow: false})
                this.setState({showLoader: this.props.showLoader, faderOpacity: 1})
            }

        }

    }

    render() {

        if(this.state.showLoader) {

            return (
                <div className="system-loader">
                    <div className='container'>
                        <div className='loader'>
                            <div className='loader--dot'></div>
                            <div className='loader--dot'></div>
                            <div className='loader--dot'></div>
                            <div className='loader--dot'></div>
                            <div className='loader--dot'></div>
                            <div className='loader--dot'></div>
                            <div className='loader--text'></div>
                        </div>
                    </div>
                </div>
            )

        } else {

            return <div></div>

        }

    }

}

const mapStateToProps = state => {
    return {
        loader: state.system.loader,
    };
};

export default connect(mapStateToProps, '')(Loader);
