import React, { Component } from 'react';
import renderName from 'utils/renderName';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import PropTypes from 'prop-types'

class SendEmailContact extends Component {

    state = {
        isEditing: false,
    }

    focusEditingInput = () => {
        const input = document.getElementById('associate-contact-input')
        if(input) input.focus()
    };

    onQuillFocus = () => this.setEditing(false)

    setEditing = (isEditing) => {

        this.setState({isEditing}, () => {

            const editor = document.querySelector('.ql-editor')

            if(isEditing) {

                this.focusEditingInput()

                if(editor) editor.addEventListener('focus', this.onQuillFocus)

            } else {

                if(editor) editor.removeEventListener('focus', this.onQuillFocus)

            }

        })

    }

    onRemoveTo = () => {
        this.props.onContactChange()
        this.focusEditingInput()
    }

    onAddTo = (contact) => {
        this.props.onContactChange(contact)
    }

    componentWillUnmount = () => {

        const editor = document.querySelector('.ql-editor')
        if(editor) editor.removeEventListener('focus', this.onQuillFocus)

    }

    render() {

        const { contact } = this.props
        const { isEditing } = this.state

        const contact_name = renderName(contact)

        if(isEditing) {

            return (

                <div className="to-container for-contact editing">
                    Contact: {' '}
                    {contact && contact._id ? (
                        <span key={contact._id}>{contact.email}
                            <b><i onClick={() => this.onRemoveTo(contact._id)} className="fas fa-times" /></b>
                        </span>
                    ) : null}

                     <ContactSearchInput
                        inputID="associate-contact-input"
                        value={{}}
                        onSelect={this.onAddTo}
                        leaveEmpty={true}
                    />
                </div>
            )

        }

        return (

            <div className="to-container for-contact" onClick={() => this.setEditing(true)}>
                Contact: {' '}

                {contact && contact._id ? (
                    <span key={contact._id}>{contact_name === 'Not Found' ? contact.email : contact_name}</span>
                ): null}
            </div>

        )

    }

}

SendEmailContact.propTypes = {
    contact           : PropTypes.object,
    onContactChange   : PropTypes.func.isRequired,
}

export default SendEmailContact
