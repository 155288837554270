import keys from 'keys';
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import A from 'components/markup/links/A'

import Array from './Array'

const Integrations = (props) => {

    const _case = props.case;
    const contact = props.case.contact;

    const ZAP_URL = `${keys.ZAP_REPORTS_URL}/dashboard/contacts` + 
        `?given_name=${contact.given_name}` + 
        `&family_name=${contact.family_name}` + 
        `&email=${contact.email}` + 
        `&phone=${contact.phone}` + 
        `&intuidesk_case_id=${_case._id}` 

    return (

        <>

        <Card>
            
            <CardHeader>
                <CardTitle className="mb-0">Zap Reports</CardTitle>
            </CardHeader>

            <CardBody>
                <p className="text-sm">Use this link to populate a contact inside of Zap Reports.</p>
            </CardBody>

            <CardFooter>
                <A href={ZAP_URL} className="btn btn-outline-success">Order Zap Report</A>
            </CardFooter>

        </Card>

        <Array 
            case={_case}
            setNav={props.setNav}
        />


        </>

    )

}

export default Integrations;