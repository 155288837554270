import moment from 'moment';
import React from 'react';

import { returnEventsForADay } from '../utils';

// get the hight for each event
const getHeight = (event) => {

    const rowHeight = 40;

    const height15Minutes = rowHeight / 2
    const minutes15 = 900

    let isFifteenMinutes = moment.unix(event.date).format('mm') === '15' ||  moment.unix(event.date).format('mm') === '45'

    const top = isFifteenMinutes ? (rowHeight / 2) -2 : -2
    const date = event.date;
    const date_end = event.date_end

    const appointment_seconds = date_end - date;

    const height = (appointment_seconds / minutes15 ) * height15Minutes
    let rows = appointment_seconds / (minutes15 * 2)
    // if(isFifteenMinutes) rows = rows + 1

    return { height: height -2, rows, top, isFifteenMinutes }

}

// get events with the date property in between start and end times
export const getIntervalEvents = (events, startTime,  endTime) => {

    if(!events) return []
    events = [...events]

    return events.filter(e => e.date >= startTime && e.date < endTime)

}

// create an object with row numbers to know how far to the right each new event should be rendered
const createRowEvents = () => {

    let row_events = {};
    let row_counter = 1;

    while(row_counter < 49) {

        row_events[row_counter] = 0
        row_counter++

    }

    return row_events

}


export default (day_to_show, events, timeIncrement, _this) => {

    if(!events) { console.warn('no events given to renderDayView at ArchCalendar. Day view was rendered empty as a result'); }
    if(!day_to_show) { console.warn('no day_to_show given to renderDayView at ArchCalendar. Day view was rendered emtpy as a result'); }
    if(!timeIncrement) { console.warn('no timeIncrement given to renderDayView at ArchCalendar. Day view was rendered emtpy as a result'); }

    // if the moment toString includes 23:00:00 it means its daylight savings time and we are really trying to grab the next day
    if(moment.unix(day_to_show).toString().includes('23:00:00')) {
        day_to_show += 86400
    }

    const startODay = parseInt(moment.unix(day_to_show).startOf('day').format('X'))

    let endODay = startODay + 86399

    //set default time increment to 1 hour
    timeIncrement = timeIncrement ? timeIncrement :3600;

    let startInterval = startODay;
    let error_counter = 0
    let markup = [];
    let todays_events = returnEventsForADay(events, startODay); //filter events to only get the ones for the start of this day

    if(!todays_events) { todays_events = [] }
    let current_row = 1;
    let row_events = createRowEvents()


    const incrementRowEvents = (rows, isFifteenMinutes) => {

        let counter = 0

        while(counter < rows) {
            row_events[current_row + counter]++

            if(isFifteenMinutes) {
                // row_events[current_row + counter + 1]++

            }

            counter++
        }

    }

    const getLeftPosition = () => {
        return  (((row_events[current_row] ) * 110) + 10)
    }

    //while our time interval is less then the end of the day we are looking for
    while(startInterval < endODay) {

        error_counter++

        if(error_counter > 1000) {

            //break loop
            startInterval = 1;
            endODay = 0;

            //send error if we hav the props to do so
            if(_this.props && _this.props.onError) {
                _this.props.onError('Calendar component dayView hit infinite loop. Probably an invalid date given')
            }

        }

        const start_time = moment.unix(startInterval)
        const end_time = moment.unix(startInterval + timeIncrement)

        const start_time_unix = start_time.format('X')
        const end_time_unix = end_time.format('X')
        const tdSpans = []

        // this is used as in index to start the calendar here
        let sevenAM = false

        //get events for this time slot
        const eventsForInterval = getIntervalEvents(todays_events, startInterval,  startInterval + timeIncrement)

        // set 7 am as the default time to scroll the calendar to
        if(start_time.format('hh:mm A') === '07:00 AM') sevenAM = true

        let classNames = eventsForInterval.length ? 'has-content ' : 'no-content '
        classNames = sevenAM ? classNames + ' sevenAM' : classNames;

        if(eventsForInterval.length) {

            eventsForInterval.sort()

            eventsForInterval.forEach((event, i) => {

                const { height, rows, top, isFifteenMinutes } =  getHeight(event)
                const left = getLeftPosition()

                const color_index =  event.color && event.color !== 'none' ? event.color : event.color_index ? event.color_index :
                    event.show === 'company' ? 'linear-gradient(to bottom right, #777, #000)' :
                    event.show === 'call center' ? 'linear-gradient(to bottom right, orange, red)' :
                    'green'

                tdSpans.push(
                    <React.Fragment key={event._id + '_' + color_index}>
                        <span
                            style={{background: color_index || 'green', left, height, top}}
                            className="index index-important text-capitalize"
                            onClick={() => _this.props.onDayEventClick({start_time_unix, end_time_unix, event})}
                        >
                            {_this.props.renderDayEvent ? _this.props.renderDayEvent(event, height) : event.name }
                        </span>
                    </React.Fragment>
                )

                incrementRowEvents(rows, isFifteenMinutes)

            })

        }

        const contentTD = (
            <td
                onClick={(e) => e.target.tagName === 'DIV' ?  _this.props.onDayCellClick({start_time_unix, end_time_unix}) : null }
            >
                <div className="content-wrapper">
                    {tdSpans}
                </div>
            </td>
        )

        markup.push((
            <tr  start={startInterval} end={endODay} key={startInterval + Math.random()} className={classNames}>
                <td className="time" style={{verticalAlign: 'text-tp', }}>{start_time.format('hh:mm')} - {end_time.format('hh:mm A')}</td>
                {/* <td className="time" style={{verticalAlign: 'text-tp', }}>{expectedTime}</td> */}
                {contentTD}
            </tr>

        ))

        startInterval += timeIncrement

        current_row++

    }

    return { markup, row_events }

}
