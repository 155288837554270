/*
Documentation

This page shows all case_templates in the system gotten through redux

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import * as privileges from '_functions/users/privileges';

const { SearchBar } = Search;

class Cases extends React.Component {

    state = {
        caseTemplateToDelete: null
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},

            formatter: (cell, row) => <Link to={`/dashboard/cases/create${row.url}`} className="text-capitalize font-weight-bold">{row.name}</Link>
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                <Link to={`/dashboard/cases/create${row.url}&hide_contact=true`}>
                    <i className="fas fa-directions text-success mr-2 font-weight-bold" />
                    Use This Template
                </Link>
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        {privileges.canModerateCaseTemplates() ? (
                            <button className="btn btn-outline-danger" onClick={() => this.setState({caseTemplateToDelete: row})}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : (
                            'No actions are available'
                        )}
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const case_templates          = this.props.case_templates.case_templates;
        const case_templates_length   = this.props.case_templates.case_templates.length;

        const caseTemplateToDelete    = this.state.caseTemplateToDelete;

        return (
            <>

            <Helmet>
                <title>{`Case Templates (${case_templates_length}) `}</title>
                <meta name="description" content="Cases" />
            </Helmet>

            {caseTemplateToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/case_templates/delete/${caseTemplateToDelete._id}`}
                    confirmBtnText="Delete Template"
                    title={<span className="text-capitalize">{`Delete Case Template: ${caseTemplateToDelete.name}`}</span>}
                    text="Deleting this case template will remove its ability to be used in the future."
                    onClose={() => this.setState({caseTemplateToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Case Templates</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link to="/dashboard/cases/all" className="btn btn-success">
                            <i className="fas fa-arrow-left mr-2" />
                            All Cases
                        </Link>
                    )}
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Case Templates</CardTitle>
                    </CardHeader>

                    {case_templates && case_templates.length ? (

                        <ToolkitProvider
                            data={case_templates}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Template Name"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                                </>

                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            No case templates have been created yet. You may create one on the {' '}
                            <Link to="/dashboard/cases/create" className="text-success text-underline font-weight-bold">
                                finalize details
                            </Link>
                            {' '}page when creating a case.
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        case_templates: state.case_templates
    };
};

export default connect(mapStateToProps, '')(Cases);

