export default (amount) => {

    if(!amount) {
        return true
    }

    if(isNaN(parseFloat(amount))) {
        return false
    }

    const val = amount.toString().split('.');

    if(val && val[1]) {
        
        if(val[1].length > 2) {
            return false;
        }

    }

    return true;

}