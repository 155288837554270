/*
Documentation

This file renders the messaging overview with emails on the
left hand sidebar, texts on the right hand sidebar and an
expanded editor in the middle based on what piece of communication
is selected

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';

import SidebarLeft from './components/SidebarLeft'
import SidebarRight from './components/SidebarRight/index'
import CenterContent from './components/CenterContent'

import { Helmet } from 'react-helmet';

import * as footer from 'utils/footer'

class Messaging extends Component {

    state = {
        // object with the data of either the text or email
        content: null,
        // either email / text
        type: null,
        // default to viewing the company email address
        viewingEmailAddress : this.props.company.microsoft_email_addresses ? this.props.company.microsoft_email_addresses[0] : '',
    }

    removeFooter = () => {

        // store document body color
        var bodyBackground = window.getComputedStyle ? window.getComputedStyle(document.body, null).getPropertyValue("background-color") : document.body.style.backgroundColor;
        this.setState({bodyBackground})

        footer.hide()

        document.body.style.minHeight = 0
        document.body.style.background = 'white'

    }

    showFooter = () => {

        footer.show()

        document.body.style.minHeight = '100vh'
        document.body.style.background = `${this.state.bodyBackground}`

    }

    onChangeEmailAddress = (viewingEmailAddress) => this.setState({viewingEmailAddress})

    // type should be "email" or "texts", obj is the actual email or text object
    setContent = async (obj, type) => {

       if(obj) {
        let newValue = obj.value

        newValue = newValue.replace(/\s*\[.*?\]\s*/g, ``);
        newValue = newValue.replace(/<br \/><br \/>/g, `<br />`);

        obj.value = newValue
       }

        this.setState({content: obj, type})

    }

    componentWillUnmount = () => this.showFooter()
    componentDidMount    = () => this.removeFooter()

    render() {

        const { type, content, viewingEmailAddress } = this.state;
        const { viewing_user } = this.props

        const { isMobile } = this.props.device.info

        if(isMobile) return (
            <div className="py-6 px-4">
                <p className="text-sm text-center">
                    <i className="text-danger font-weight-bold fas fa-exclamation-triangle mr-2" />
                    The messaging center must be viewed on either a computer or tablet device.
                </p>
            </div>
        )

        return (

            <div className="page-messaging">

                <Helmet>
                    <title>{`Messaging`}</title>
                    <meta name="description" content="Contacts" />
                </Helmet>

                <SidebarLeft
                    viewingEmailAddress={viewingEmailAddress}
                    setContent={this.setContent}
                    content={content}
                    onChangeEmailAddress={this.onChangeEmailAddress}
                />

                <CenterContent
                    setContent={this.setContent}
                    type={type}
                    content={content}
                    viewingEmailAddress={viewingEmailAddress}
                />

                <SidebarRight
                    setContent={this.setContent}
                    content={content}
                    viewing_user={viewing_user}
                />

            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        device: state.device,
        viewing_user: state.auth.viewing_user,
        company: state.companies.company,

    };
};


export default connect(mapStateToProps, '')(Messaging);
