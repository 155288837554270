import { connect } from 'react-redux';
import AvatarImage from 'components/functional/images/AvatarImage';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { Component } from 'react';
import reactHTMLParser from 'react-html-parser';
import { Card, CardHeader } from 'reactstrap';
import renderName from 'utils/renderName';
import _payments from '_functions/payments';

class EventLogs extends Component {


    state = {
        event_logs: null
    };

    componentDidMount = async () => {

        const event_logs = await _payments.event_logs.findByContact(this.props.contact._id)
        if(event_logs.success) this.setState({event_logs: event_logs.data})

    }

    render() {

        const contact = this.props.contact
        const { event_logs } = this.state

        const { isMobile } = this.props.device.info

        return (

            <Card>

                <CardHeader>
                    <h3 className="mb-0 text-capitalize">{contact.given_name}'s Payment Event Logs</h3>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table" style={{tableLayout: 'fixed'}}>

                        <thead>
                            <tr>
                                <th style={{width: 140, maxWidth: 140}}>Date</th>
                                <th style={{width: 200, maxWidth: 200}}>Staff</th>
                                <th>Event</th>
                            </tr>
                        </thead>

                        <tbody>
                            {event_logs ? event_logs.map(log => (
                                <tr key={log._id}>
                                    <td>{moment.unix(log.created_at).format('M/DD/YY - h:mm A')}</td>
                                    <td className="text-capitalize" >
                                        {log.user ? (
                                            <div>
                                                <AvatarImage src={log.user.avatar} className="mr-2"></AvatarImage>
                                                {renderName(log.user)}
                                            </div>
                                        ) : 'SYSTEM'}
                                    </td>
                                    <td style={{whiteSpace: isMobile  ? 'pre' : 'pre-line'}}>{reactHTMLParser(log.value)}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td><Circle /></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Card>

        )

    }

}

const mapStateToProps = state => {
    return {
    	device: state.device,
    };
};

export default connect(mapStateToProps, '')(EventLogs);
