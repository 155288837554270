import React from "react";
import { Helmet } from 'react-helmet';
import Render from './Render';

const MessengerView = () => {

    return (
        <div>
    
            <Helmet>
                <title>{`Messenger`}</title>
                <meta name="description" content="Messenger" />
            </Helmet>
    
            <Render />
    
        </div>
    )

}

export default MessengerView
