import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import _invoices from '_functions/invoices'
import { formatMoney } from 'utils/currency';
import moment from 'moment';
import * as privileges from '_functions/users/privileges';

class Invoices extends Component {


    state = {
        invoices: null,
        showModal: false
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    listenForUpdatedInvoices = (data) => {

        // invoice is not for this contact, return
        if(this.props.case.contact._id !== data.data.contact) return;

        const invoices = this.state.invoices ? [...this.state.invoices] : [];
        const invoice = data.data;

        if(invoices) {
            invoices[invoices.findIndex(el => el._id === invoice._id)] = invoice;
            this.setState({invoices})
        }

    }

    listenForAddedInvoices = (data) => {

        // invoice is not for this contact, return
        if(this.props.case.contact._id !== data.data.contact) return;

        const invoices = this.state.invoices ? [...this.state.invoices] : [];
        const invoice = data.data;

        invoices.unshift(invoice)

        this.setState({invoices})

    }

    listenForDeletedInvoices = (data) => {

        let invoices = this.state.invoices ? [...this.state.invoices] : [];
        const invoice = data.data;

        invoices = invoices.filter(i => i._id !== invoice._id);

        this.setState({invoices})

    }

    setListeners = () => {
        io.on(socketEvents.invoices_updated,    this.listenForUpdatedInvoices)
        io.on(socketEvents.invoices_added,      this.listenForAddedInvoices)
        io.on(socketEvents.invoices_deleted,      this.listenForDeletedInvoices)
    }

    componentWillUnmount = () => {
        io.off(socketEvents.invoices_updated,   this.listenForUpdatedInvoices)
        io.off(socketEvents.invoices_added,     this.listenForAddedInvoices)
        io.off(socketEvents.invoices_deleted,     this.listenForDeletedInvoices)
    }


    componentDidMount = async () => {

        const invoices = await _invoices.findByCase(this.props.case._id)
        if(invoices.success) this.setState({invoices: invoices.data})

        this.setListeners()

    }

    render() {

        const invoices = this.state.invoices;
        const canUpdateFinances = privileges.canUpdateFinances()

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">All Invoices</CardTitle>
                </CardHeader>

                {invoices ? (
                    invoices.length ? (
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Paid</th>
                                        <th>Total</th>
                                        <th>Date Sent</th>
                                        <th className="text-right"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {invoices.map(i => (
                                        <tr key={i._id}>
                                            <td style={{width: 180}}>
                                                <span
                                                    className={parseFloat(i.total_paid) === parseFloat(_invoices.calculate.total(i)) ? 'text-success font-weight-bold cursor-pointer' : 'cursor-pointer'}
                                                    onClick={() => this.props.setInvoice(i)}
                                                >
                                                    {i.is_programmatic ? (
                                                        <span className="text-danger font-weight-bold"><i className="mr-2 fas fa-exclamation" />{i.name}</span>
                                                    ) : i.name}
                                                </span>
                                            </td>
                                            <td>{i.status}</td>
                                            <td style={{width: 110}}>{formatMoney(i.total_paid)}</td>
                                            <td style={{width: 110}}>{formatMoney(_invoices.calculate.total(i))}</td>
                                            <td>
                                                {i.date ?
                                                    moment.unix(i.date).format('MM/DD/YYYY') :
                                                    'Not Sent Yet'
                                                }
                                            </td>
                                            <td className="text-right">
                                                {canUpdateFinances ? (
                                                    <i onClick={() => this.props.setInvoice(i)} className="fas fa-edit text-success cursor-pointer" />
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0 font-weight-bold">
                                There are no created invoices yet.
                            </p>
                        </CardBody>
                    )

                ) : null}

            </Card>

            </>

        )

    }

}

export default Invoices;
