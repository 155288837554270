import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import ContactFormsCrud from 'views/dashboard/pages/contacts/components/ContactFormsCrud';
import ContactQuestionnaires from 'views/dashboard/pages/contacts/components/ContactQuestionnaires';
import PropTypes from 'prop-types'


import _contact_forms from '_functions/contact_forms';



class Forms extends Component {

    state = {
        version: 2,
        hasOlderForms: false
    }

    componentDidMount = async () => {
        const forms = await _contact_forms.findByContact(this.props.contact._id)

        if(forms.data && forms.data.values) {
            this.setState({hasOlderForms: true})
        }

    }

    render() {

        const { contact, navPills } = this.props;
        const { version, hasOlderForms } = this.state;

        return (

            navPills === 7 ? (

                <Card className="card-color card-warning">

                    <CardHeader>
                        <Row>
                            <Col lg={4} className="align-self-center">
                                <CardTitle className="mb-0">Contact Forms</CardTitle>
                            </Col>
                            <Col lg={8} className="align-self-center text-right">
                                {hasOlderForms ? (
                                    <>
                                    <span className="mr-2">
                                        {version === 1 ? 
                                        'Showing Previous Version' : 
                                        'Showing Latest Version'}
                                    </span>
                                    
                                    <label className="custom-toggle" style={{position: 'relative', top: 6}}>
                                        <input defaultChecked type="checkbox" onClick={() => this.setState({version: version === 1 ? 2 : 1})} />
                                        <span className="custom-toggle-slider rounded-circle" />
                                    </label>
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>

                        {version === 1 ? (
                            <ContactFormsCrud
                                contact_id={contact._id}
                            />
                        ) : version === 2 ? (
                            <ContactQuestionnaires 
                                contact_id={contact._id}
                            />
                        ) : null}                        

                    </CardBody>

                </Card>

            ) : null

        )

    }

}

Forms.propTypes = {
    contact   : PropTypes.object.isRequired,
    navPills  : PropTypes.number.isRequired
}

export default Forms;
