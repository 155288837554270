/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/


//system reducer
export const SYSTEM_SET_REDUCERS_FIRED    = 'SYSTEM_SET_REDUCERS_FIRED';
export const SET_SETTINGS                 = 'SET_SETTINGS';
export const TOGGLE_SYSTEM_ALERT          = 'TOGGLE_SYSTEM_ALERT';
export const TOGGLE_SYSTEM_LOADER         = 'TOGGLE_SYSTEM_LOADER';
export const TOGGLE_SYSTEM_ALERT_BS       = 'TOGGLE_SYSTEM_ALERT_BS';
export const TOGGLE_STANDARD_LOADER       = 'TOGGLE_STANDARD_LOADER';
export const TOGGLE_HELP_CENTER           = 'TOGGLE_HELP_CENTER';
export const TOGGLE_RECENT_ACTIVITY       = 'TOGGLE_RECENT_ACTIVITY';
export const TOGGLE_BILLING_CENTER        = 'TOGGLE_BILLING_CENTER';
export const TOGGLE_DEV_TICKETS           = 'TOGGLE_DEV_TICKETS';

export const SET_DEVICE                   = 'SET_DEVICE';
export const SET_ONLINE_STATUS            = 'SET_ONLINE_STATUS';

export const FUNCTION_RAN                 = 'FUNCTION_RAN';

//auth reducer
export const SET_VIEWING_USER             = 'SET_VIEWING_USER';

//user reducer
export const SET_USERS                    = 'SET_USERS';
export const SET_USER_IMAGE               = 'SET_USER_IMAGE';

export const SET_LEAD_SOURCES             = 'SET_LEAD_SOURCES';

//contacts reducer
export const SET_CONTACTS                 = 'SET_CONTACTS';

export const SET_COMPANY                  = 'SET_COMPANY';


//cases reducer
export const SET_CASES                    = 'SET_CASES';
export const SET_CASE_TEMPLATES           = 'SET_CASE_TEMPLATES';
export const SET_CASE_ALERT_TEMPLATES     = 'SET_CASE_ALERT_TEMPLATES';

export const SET_EMAIL_SENDERS            = 'SET_EMAIL_SENDERS';

export const SET_DIVISIONS                = 'SET_DIVISIONS';

export const SET_DOCUMENT_LOADING         = 'SET_DOCUMENT_LOADING';
export const SET_DOCUMENTS_FOR_MERGE      = 'SET_DOCUMENTS_FOR_MERGE';

export const SET_EMAIL_PARSERS            = 'SET_EMAIL_PARSERS';

export const SET_TAGS                     = 'SET_TAGS';
export const SET_OBJECTION_NUMBERS        = 'SET_OBJECTION_NUMBERS';

export const SET_ANALYTICS_DATA_TYPES     = 'SET_ANALYTICS_DATA_TYPES';

export const SET_FORMS                    = 'SET_FORMS';

export const SET_GROUPINGS                = 'SET_GROUPINGS';

//workflows reducer
export const SET_WORKFLOWS                = 'SET_WORKFLOWS';

export const SET_TEMPLATE_APPOINTMENTS    = 'SET_TEMPLATE_APPOINTMENTS';
export const SET_TEMPLATE_EMAILS          = 'SET_TEMPLATE_EMAILS';
export const SET_TEMPLATE_TEXTS           = 'SET_TEMPLATE_TEXTS';
export const SET_TEMPLATE_DOCS            = 'SET_TEMPLATE_DOCS';
export const SET_TEMPLATE_WORD_DOCS       = 'SET_TEMPLATE_WORD_DOCS';

export const SET_AUTOMATIONS              = 'SET_AUTOMATIONS';

export const SET_MISSING_DOCS             = 'SET_MISSING_DOCS';

export const SET_LOCATION_COURTS          = 'SET_LOCATION_COURTS';
export const SET_LOCATION_HEARINGS        = 'SET_LOCATION_HEARINGS';

export const SET_OFFICES                  = 'SET_OFFICES';

export const SET_QUESTIONNAIRES           = 'SET_QUESTIONNAIRES';

export const SET_PHONE_NUMBERS            = 'SET_PHONE_NUMBERS';

export const SET_BROWSER_DIALING_STATUS   = 'SET_BROWSER_DIALING_STATUS';
export const SET_TRANSFER_LISTENER        = 'SET_TRANSFER_LISTENER';
export const SET_CALL_STATUS              = 'SET_CALL_STATUS';
export const SET_END_CALL_MODAL           = 'SET_END_CALL_MODAL';
export const SET_START_CALL_MODAL         = 'SET_START_CALL_MODAL';


export const SET_USER_STATUSES            = 'SET_USER_STATUSES';

export const SET_STEPS_TO_PETITION_PREP  = 'SET_STEPS_TO_PETITION_PREP';
