import apiWorker from '../apiWorker'

export default {

    find:       (query)         => apiWorker('get',    `/api/v1/core/companies/find${query}`),
    findById:   (company_id)    => apiWorker('get',    `/api/v1/core/companies/find/${company_id}`),

    create:     (data)        => apiWorker('post',   `/api/v1/core/companies/create`, data),
    update:     (_id, data)   => apiWorker('patch',  `/api/v1/core/companies/update/${_id}`, data),

}
