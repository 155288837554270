/*
Documentation

This page allows a user to add / remove payment methods from a contact

*/


import keys from 'keys';
import PropTypes from 'prop-types';
import React from "react";
import { Card, CardBody, CardHeader, Badge} from "reactstrap";
import Circle from 'components/markup/loading/Circle';

import _payments from '_functions/payments';
import _contacts from '_functions/contacts';
import { setViewingUser } from 'store/functions/auth/auth';
import * as privileges from '_functions/users/privileges';
import { connect } from 'react-redux';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system'

class PaymentMethods extends React.Component {

    state = {
        payformUrl        : this.props.payformUrl,
        account_vaults    : this.props.account_vaults,
        loaded            : false
    }

    // this function updates all internal recurrings fortispay recurrings and the contact default method
    onChangeDefaultMethod = async (default_account_vault_id, last_four) => {

        // return if not default account id is given
        if(!default_account_vault_id) return

        toggleStandardLoader(true)

        // update all internal recurrings fortispay recurrings and the contact default method
        const updated = await _payments.account_vaults.updateDefaultVaults({
            contact_id: this.props.contact._id,
            account_vault_id: default_account_vault_id
        })


        // send message to the viewing user
        if(updated.success) {

            toggleAlertBS(false, 'Contact\'s default payment method has been updated.')
            this.props.getPaymentMethods()

            _payments.event_logs.create({
                value: `Set contacts default card to ...${last_four}`,
                contact: this.props.contact._id,
                user: this.props.viewing_user._id
            })

        } else {

            toggleAlertBS(true, 'Something went wrong updating the default payment method.')

        }

        toggleStandardLoader(false)

    }


    //send call to backend to remove the payment method
    onRemovePaymentMethod = async (account_vault_api_id) => {

        const deleted = await _payments.account_vaults.delete(account_vault_api_id, {
            contact_id: this.props.contact._id,
            user_id: this.props.viewing_user._id
        })

        if(deleted.success) {

            let account_vaults = [...this.state.account_vaults]
            account_vaults = account_vaults.filter(a => a.account_vault_api_id !== account_vault_api_id)

            this.setState({account_vaults})
            this.props.getPaymentMethods()

        }

    }

    //when a new vault is added update state
    receiveZeamsterMessage = async (event) => {

        //if the origin is from zeamster add new card to state
        if (event.origin === keys.ZEAMSTER_ORIGIN) {

            this.props.getPaymentMethods()

            let account_vaults = [...this.state.account_vaults]
            const card = JSON.parse(event.data)

            account_vaults.unshift(card)
            this.setState({account_vaults})

            // get card info from message

            // create a payment log
            _payments.event_logs.create({
                value: `Added the credit card: ${card.first_six}...${card.last_four} exp: ${card.exp_date.substring(0, 2)}/${card.exp_date.substring(2, 4)}`,
                contact: this.props.contact._id,
                user: this.props.viewing_user._id
            })

            // if contact does not have a default payment method set it
            const contact = this.props.contact

            if(!contact.default_account_vault_id) {

                // set default card on contact
                await _contacts.update(contact._id, {default_account_vault_id: card.id})
                // update the viewing user
                setViewingUser()

            }

            // set a timeout to refresh a new payform url
            setTimeout(async () => {

                const payform = await _payments.createPayform(this.props.contact._id)
                if(payform.success) { this.setState({payformUrl: payform.data}) }

            }, 1500)


        }

    }

    componentDidMount    = () => {
        window.addEventListener("message", this.receiveZeamsterMessage, false)
    }

    componentWillUnmount = () => window.removeEventListener("message", this.receiveZeamsterMessage);

    render() {

        const { contact, payformUrl } = this.props
        const { account_vaults, loaded } = this.state
        const canUpdateFinances = privileges.canUpdateFinances()
        const canUpdateContacts = privileges.canUpdateContacts()

        return (
            <>

            {account_vaults && account_vaults.length ? (
                <Card>

                    <CardHeader>
                        <h3 className="mb-0 text-capitalize">{contact.given_name}'s Payment Methods</h3>
                        <p className="mb-0 text-sm">If you are not able to remove a card it means that card is assigned to a current and active payment plan.</p>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Card #</th>
                                    <th>Expires</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {account_vaults.map(a => (
                                    <tr key={a.id}>
                                        <td>
                                            {a.payment_method === 'cc' ? 'Credit Card' : a.payment_method}
                                            {a.id === contact.default_account_vault_id ? 
                                                <Badge className="text-sm ml-3" color="success">DEFAULT</Badge> : 
                                            null}
                                        </td>
                                        <td>{_payments.cards.getName(a.account_type)}</td>
                                        <td>{a.first_six.slice(0,1)}......{a.last_four}</td>
                                        <td>{a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}</td>
                                        <td className="text-right">
                                            {canUpdateFinances ? (
                                            <>
                                            {!a.has_recurring ? (
                                                <button
                                                    onClick={() => this.onChangeDefaultMethod(a.id, a.last_four)}
                                                    className="btn btn-outline-success"
                                                >
                                                    Set Default
                                                </button>
                                            ) : null}
                                            <button
                                                disabled={a.has_recurring}
                                                className="btn btn-danger"
                                                onClick={() => this.onRemovePaymentMethod(a.account_vault_api_id ? a.account_vault_api_id : a.id)}
                                            >
                                                    <i className="fas fa-trash mr-2" />
                                                Remove
                                            </button>

                                            </>
                                            ): 'No actions available'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>

                </Card>

            ) : (

                <div className="alert alert-danger">There are no payment methods for this contact.</div>

            )}

            {canUpdateFinances && canUpdateContacts  ? (

                <Card>

                    <CardHeader>
                        <h3 className="mb-0">Add A Payment Method</h3>
                    </CardHeader>

                    <CardBody>

                        {!loaded ? <Circle /> : null}

                        {payformUrl? (
                            <iframe
                                id="ifrm"
                                onLoad={() => this.setState({loaded: true})}
                                title="fortispay"
                                height="350px"
                                width="100%"
                                style={{border: 'none', margin: 0}}
                                src={payformUrl}
                            />
                        ) : null}

                    </CardBody>

                </Card>

            ) : null}

            </>
        );
    }
}

PaymentMethods.propTypes = {
    payformUrl        : PropTypes.string.isRequired,
    account_vaults    : PropTypes.array.isRequired,
    contact           : PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(PaymentMethods);
