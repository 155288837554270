import _activity from '_functions/activity'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const getScreenHTML = () => {
    return document.body.innerHTML
    // remove scripts
    .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
    // replace root with different id for rendering on admin side
    .replace('<div id="root">', '<div id="root_screen_watch">')
}

const recordScreen = async (user_id) => {

    let isWatched = false
    let interval;
    let mousePosition;

    _activity.screen_watch.cancelEvents({user_id})

    const handleMouseMove = function handleMouseMove(event) {
        var eventDoc, doc, body;

        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX +
              ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
              ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
            event.pageY = event.clientY +
              ((doc && doc.scrollTop)  || (body && body.scrollTop)  || 0) -
              ((doc && doc.clientTop)  || (body && body.clientTop)  || 0 );
        }

        mousePosition = { x: event.pageX, y: event.pageY };

    }

    const startListening =  () => {

        // don't look multiple listeners if already listening
        if(isWatched) return
        // set listing to true so we don't keep sending events later
        isWatched = true

        // send date to server every .7 seconds
        interval = setInterval(() => {

            window.addEventListener('mousemove', handleMouseMove)

            _activity.screen_watch.sendScreen({
                user_id,
                mousePosition,
                html          : getScreenHTML(),
                url           : window.location.href,
                pageOffset    : window.pageYOffset,
                pageWidth     : window.innerWidth,
                pageHeight    : window.innerHeight,
            })

        }, 700)
    }

    const stopListening = () => {

        // if failed its due to clearing an interval that doesn't exists
       try {
            isWatched = false

            clearInterval(interval)
            window.removeEventListener('onmousemove', handleMouseMove)
       } catch(e) {}

    }


    // if tab is unfocused stop listening, else start listening
    // if the tab comes into focus
    document.addEventListener("visibilitychange", async () => {
        if (document.visibilityState === "visible") {
            const currentWatchers = await _activity.screen_watch.getWatching()
            if(currentWatchers.data && currentWatchers.data.includes(user_id))  startListening()
        } else {
            stopListening()
        }
    })

    // when window focuses cancel all other events firing and start
    // listening to this tab
    window.addEventListener("focus", async function(event) {

        const currentWatchers = await _activity.screen_watch.getWatching()
        if(currentWatchers.data && currentWatchers.data.includes(user_id)) {
            _activity.screen_watch.cancelEvents({user_id})
            setTimeout(() => {
                startListening()
            }, 1000)
        }

    }, false);

    // when this function loads see if the user is currently being watched
    const currentWatchers = await _activity.screen_watch.getWatching()

    // if being watched wait 1 second to see if tab is visible, if so start watch
    if(currentWatchers.data && currentWatchers.data.includes(user_id)) {
        setTimeout(() => {
            if(document.visibilityState === "visible") {
                startListening()
            }
        }, 1000)
    }

    // when screen watch is updated stat listening or stop listening
    // depending on if a user is being watched
    io.on(socketEvents.screen_watch_set, (data) => {

        if(data.data && data.data.includes(user_id)) {
            setTimeout(() => {
                if(document.visibilityState === "visible" && document.hasFocus()) {
                    if(!isWatched) startListening()
                }
            }, 1000)
        } else {
            stopListening()
        }

    })

    // when a browser comes into focus it fires to the server to cancel all
    // events being sent. here we listen for that
    io.on(socketEvents.screen_watch_cancel_user_events, (data) => {
        if(data.data === user_id) {
            stopListening()
        }
    })

}

export default recordScreen
