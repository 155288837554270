/*
Documentation

Opens outlook in an iframe,
DEPRECIATED

*/

import React, { Component } from 'react';

class MicrosoftEmails extends Component {

    render() {

        return (

            <iframe
                id="iframe"
                style={{width: '100vw', height: 'calc(100vh - 70px'}}
                src="https://outlook.office365.com/mail/inbox/"
                title="test"
            />

        )

    }

}

export default MicrosoftEmails;
