// import { Link } from 'react-router-dom'
import React from 'react';

export default {

    create: {
        body: (
            <div>
                <h3>Template Emails / Text</h3>
                <p></p>

                <h3>Client Fields</h3>
                <table>
                    <tbody>
                        <tr>
                            <td style={{width: '40%'}}>{`{client_first_name}`}</td>
                            <td>Changes to the associated client's first name</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{client_last_name}`}</td>
                            <td>Changes to the associated client's last name</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{client_email}`}</td>
                            <td>Changes to the associated client's email</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{client_phone}`}</td>
                            <td>Changes to the associated client's phone number</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Attorney Fields</h3>
                <table>
                    <tbody>
                        <tr>
                            <td style={{width: '40%'}}>{`{lead_attorney_first_name}`}</td>
                            <td>Changes to the lead attorney's first name for the given case.</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{lead_attorney_last_name}`}</td>
                            <td>Changes to the lead attorney's last name for the given case.</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{lead_attorney_email}`}</td>
                            <td>Changes to the lead attorney's email for the given case.</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{lead_attorney_phone}`}</td>
                            <td>Changes to the lead attorney's phone number for the given case.</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Case Fields</h3>
                <table>
                    <tbody>
                        <tr>
                            <td style={{width: '40%'}}>{`{case_name}`}</td>
                            <td>Changes to the name of the case.</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{case_type}`}</td>
                            <td>Changes to the workflow name associated with the case.</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{case_number}`}</td>
                            <td>Once a case number is added this will change to it..</td>
                        </tr>
                        <tr>
                            <td style={{width: '40%'}}>{`{date}`}</td>
                            <td>Changes to current date.</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Signatures</h3>
                <p>Note that initials are surrounded by parenthesis and NOT brackets</p>

                <p> When having a client electronically sign a document any time you add the following value it will be replaced by the clients initials:</p>
                <p><b>(_____)</b></p>

                <p> When having a client electronically sign a document any time you add the following value it will be replaced by the clients signature:</p>
                <p><b>________________________________________</b></p>

            </div>
        ),

    }

}
