import apiWorker from '../apiWorker'

export default {

    create:   (data, hideError) => apiWorker('post',   `/api/v1/core/template_docs/create`, data, hideError),
    update:   (doc_id, data)    => apiWorker('post',   `/api/v1/core/template_docs/update/${doc_id}`, data),
    delete:   (doc_id)          => apiWorker('delete', `/api/v1/core/template_docs/delete/${doc_id}`),

    find:      (query)          => apiWorker('get',    `/api/v1/core/template_docs/find${query}`),
    findById:  (_id)            => apiWorker('get',    `/api/v1/core/template_docs/${_id}`),
    convertWordToPDF: (data)    => apiWorker('post',  `/api/v1/core/convertWordToPDF`, data, null, {'content-type': 'multipart/form-data'}),

}
