/*
Documentation

and appointments this function rendered in a givin week

*/

import moment from 'moment';
import React from 'react';
import { getEventsBetweenInterval, returnEventsForADay } from '../utils';

//set 7 empty weeks, as we loop if we have an event thats end date is greater than the cell end date push it to the next render below
let toPushToNextRender = [ [], [], [], [], [], [], [], ]

const getWeekMarkup = (week_events_array, startTime,  timeIncrement, _this) => {

    let markup = [];

    let appointments_rendered = 0;

    let fullAppObject = []

    for(let x = 0; x < 7; x++) {

        let events = week_events_array[x]
        let newStartTime = startTime + (x * 86400)

        //get all events between the interval we are looking to render
        let intervalEvents = getEventsBetweenInterval(events, newStartTime, newStartTime + timeIncrement)

        //if we have any events that should be pushed to the next cell for this day based on end date
        intervalEvents = intervalEvents.concat(toPushToNextRender[x])

        //add in any events that should be here from the last loop
        toPushToNextRender[x] = intervalEvents.filter(e => e.date_end > newStartTime + timeIncrement)

        ///////////////////////////////////////////////////////////////////////////////////////////////////////
        //this code block is used for testing
        if(intervalEvents && intervalEvents.length) {

            let appointments = intervalEvents.filter(e => e.calendar_index === 'appointment')
            if( appointments.length) { appointments_rendered =  appointments_rendered +  appointments.length}

        }
        //end testing block
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        let appObject = {}
        let cellData = []

        if(intervalEvents && intervalEvents.length) {

            intervalEvents.forEach(appointment => {

                if(appointment.assigned_to && appointment.assigned_to.length) {

                    appointment.assigned_to.forEach(user => {

                        const color = user.color ? user.color :  'black'

                        if(appObject[user._id]) {
                            appObject[user._id].appointments.push(appointment)
                            fullAppObject.push(appointment._id)
                        } else {
                            appObject[user._id] = {
                                appointments: [appointment],
                                color_index: color
                            }
                            fullAppObject.push(appointment._id)
                        }

                    })

                } else if(appointment.show === 'company') {

                    if(appObject['company']) {
                        if(!fullAppObject.includes(appointment._id)) {
                            appObject['company'].appointments.push(appointment)
                            fullAppObject.push(appointment._id)
                        }
                    } else {
                        appObject['company'] = {
                            appointments: [appointment],
                            color_index: 'linear-gradient(to bottom right, #777, #000)'
                        }
                        fullAppObject.push(appointment._id)
                    }


                } else if(appointment.show === 'call center') {

                    if(appObject['call_center']) {
                        if(!fullAppObject.includes(appointment._id)) {
                            appObject['call_center'].appointments.push(appointment)
                            fullAppObject.push(appointment._id)
                        }                    } else {
                        appObject['call_center'] = {
                            appointments: [appointment],
                            color_index: 'linear-gradient(to bottom right, orange, red)'
                        }
                        fullAppObject.push(appointment._id)

                    }

                }
            })

            Object.keys(appObject).forEach((key, i) => {

                const appointments = appObject[key].appointments;
                const color_index = appObject[key].color_index;

                cellData.push((
                    <span  key={key} className="index index-important" style={{background: color_index}} >
                        <i className="fas fa-calendar mr-2" />
                        {appointments.length}
                    </span>
                ))

            })

        }


        markup.push((
            <td
                onClick={(e) => _this.props.onViewWeekCellClick({start_date: newStartTime, end_date: newStartTime + timeIncrement})}
                key={startTime + x}
                className="timeslot"
            >
                <div className="content-wrapper">
                    {cellData}
                </div>
            </td>
        ))

    }

    return { markup, appointments_rendered }

}

const getWeekArray = (events, startOfWeek) => {

    let week_events_array = []
    let dayToFind = startOfWeek;

    for (let i = 0; i < 7; i++) {

        if(i !== 0) {
            dayToFind = dayToFind + 86400
        }

        const endOfDayToFind = dayToFind + 86399;

        let days_events = returnEventsForADay(events, dayToFind, endOfDayToFind)
        week_events_array.push(days_events)

    }

    return week_events_array

}

export default (startOfWeek, events, _this) => {

    let markup = [];
    const week_events_array = getWeekArray(events, startOfWeek)



    //increment each row by 1 hour
    const timeIncrement = 900 * 4;
    let appointments_rendered = 0;

    //loop the hours in the day
    for(let i = 1; i< 25; i++) {

        let startTime = startOfWeek + (timeIncrement * (i - 1))
        let endTime = startOfWeek + (timeIncrement * i)

        const weekMarkup = getWeekMarkup(week_events_array, startTime,  timeIncrement, _this)

        //used as testing metrics
        appointments_rendered = appointments_rendered + weekMarkup.appointments_rendered

        markup.push((
            <tr key={i + startTime}>

                <td className="time">
                    {moment.unix(startTime).format('hh:mm')} {' - '}
                    {moment.unix(endTime).format('hh:mm A')}
                </td>

                {weekMarkup.markup}

            </tr>
        ))

    }

    return { markup, appointments_rendered }

}
