import ReactSelect from 'components/functional/inputs/ReactSelect';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { connect } from 'react-redux';

import _trust_logs from '_functions/trust_logs';

import validator from 'utils/validator';
import DatePicker from 'react-datepicker';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'
import * as privileges from '_functions/users/privileges';

import moment from 'moment';

const required_fields = [
    'amount',
    'date',
    'reason',
    'payment_method',
    'party',
]

class TrustReceiveFunds extends React.Component {

    state = {
        trust_log: {},
        showSelects: true
    }

    onInputChange = (stateField, value) => {
        validator.onInputChange(value, 'trust_log', stateField, this)
    }

    onSave = async () => {

        const payment_method = this.state.trust_log.payment_method
        let fieldToValidate = [...required_fields]

        if(payment_method === 'ECH/EFT/Wire Transfer') {
            fieldToValidate.push('transaction_reference')
        }

        if(payment_method === `Check` || payment_method === `Bank Check/Money Order`) {
            fieldToValidate.push('check_date')
            fieldToValidate.push('check_from')
            fieldToValidate.push('check_number')
        }

        if(payment_method === `Cash`) {
            fieldToValidate.push('notes_amount')
            fieldToValidate.push('coins_amount')
        }

        if(payment_method === 'Credit Card' || payment_method === 'Debit Card') {
            fieldToValidate.push('last_4')
        }

        const validateFields = validator.requiredFields('trust_log', fieldToValidate, this, true)
        const newState = validateFields.state

        this.setState({trust_log: {...newState} })

        if(validateFields.success) {

            newState.date       = parseInt(moment(newState.date).format('X'))
            newState.amount     = parseFloat(newState.amount)
            newState.type       = 'in';
            newState.user       = this.props.viewing_user._id
            newState.contact    = this.props.case.contact._id
            newState.case       = this.props.case._id

            if(newState.check_date) {
                newState.check_date = parseInt(moment.unix(newState.check_date).format('X'))
            }

            toggleStandardLoader(true)

            const created = await _trust_logs.create(newState)

            toggleStandardLoader(false)

            if(created.success) {

                toggleAlertBS(false, `Payment logged successfully.`)
                this.setState({trust_log: {}, showSelects: false}, () => {
                    this.setState({showSelects: true})
                })

            } else {

                toggleAlertBS(true, `An error occurred logging this payment.`)

            }

        }

    }

    render() {

        const canUpdate = privileges.canUpdateFinances()

        const { trust_log, showSelects } = this.state;

        return (
            <>

                <h2 className="display-5"><i className="fas fa-plus-square mr-2 text-success" /> Add Received Funds</h2>

                <Row>

                    <Col lg={6}>
                        <StandardFormGroup
                            obj={trust_log}
                            objName="trust_log"
                            onChange={(o, f, v) => this.onInputChange(f, v)}
                            type="number"
                            field={'amount'}
                            title={'Amount Received *'}
                        />
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Date Banked</label>
                            <div
                                className={
                                    trust_log.dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    trust_log.dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={trust_log.date}
                                    onChange={date => this.onInputChange('date', date)}
                                />
                            </div>
                        </FormGroup>
                    </Col>

                </Row>

                <StandardFormGroup
                    obj={trust_log}
                    objName="trust_log"
                    onChange={(o, f, v) => this.onInputChange(f, v)}
                    type="text"
                    field={'party'}
                    title={'Received From *'}
                />
                <StandardFormGroup
                    obj={trust_log}
                    objName="trust_log"
                    onChange={(o, f, v) => this.onInputChange(f, v)}
                    type="textarea"
                    field={'party_address'}
                    title={'Received From Address'}
                />

                <Row>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Reason For Payment *</label>
                            {showSelects ? (
                                <ReactSelect
                                    placeholder=""
                                    invalid={trust_log.reasonState === 'invalid'}
                                    onChange={(values) => this.onInputChange('reason', values.value)}
                                    options={[
                                        {'label': 'Case Settlement', 'value': 'Case Settlement'},
                                        {'label': 'Client Funded Their Account', 'value': 'Client Funded Their Account'},
                                        {'label': 'Filing Fees', 'value': 'Filing Fees'},
                                        {'label': 'Garnishment Recovery', 'value': 'Garnishment Recovery'},
                                        {'label': 'Monthly ACH', 'value': 'Monthly ACH'},
                                        {'label': 'Opening Balance', 'value': 'Opening Balance'},
                                        {'label': 'Zero Down Payment Plan', 'value': 'Zero Down Payment Plan'},
                                        {'label': 'Other', 'value': 'Other'},
                                    ]}
                                />
                            ) : null}
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Payment Method *</label>
                            {showSelects ? (
                                <ReactSelect
                                    placeholder=""
                                    invalid={trust_log.payment_methodState === 'invalid'}
                                    onChange={(values) => this.onInputChange('payment_method', values.value)}
                                    options={[
                                        {'label': 'ECH/EFT/Wire Transfer', 'value': 'ECH/EFT/Wire Transfer'},
                                        {'label': 'Check', 'value': 'Check'},
                                        {'label': 'Bank Check/Money Order', 'value': 'Bank Check/Money Order'},
                                        {'label': 'Cash', 'value': 'Cash'},
                                        {'label': 'Credit Card', 'value': 'Credit Card'},
                                        {'label': 'Debit Card', 'value': 'Debit Card'},
                                        {'label': 'Client Direct Deposit Via Bank', 'value': 'Client Direct Deposit Via Bank'},
                                    ]}
                                />
                            ) : null}
                        </FormGroup>
                    </Col>

                </Row>

                <hr />

                {trust_log.payment_method === `ECH/EFT/Wire Transfer` ? (
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'transaction_reference'}
                        title={'Transaction Reference *'}
                    />
                ) : null}

                {trust_log.payment_method === `Check` || trust_log.payment_method === `Bank Check/Money Order` ? (
                    <>
                    <FormGroup>
                        <label className="form-control-label">Check Date</label>
                        <div
                            className={
                                trust_log.dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                trust_log.dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                            }
                        >
                            <DatePicker
                                selected={trust_log.check_date}
                                onChange={date => this.onInputChange('check_date', date)}
                            />
                        </div>
                    </FormGroup>

                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="text"
                        field={'check_from'}
                        title={'Check From *'}
                    />

                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field={'check_number'}
                        title={'Check Number *'}
                    />
                    </>
                ) : null}

                {trust_log.payment_method === `Cash` ? (
                    <>
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="number"
                        field={'notes_amount'}
                        title={'Notes Amount *'}
                    />
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="number"
                        field={'coins_amount'}
                        title={'Coins Amount *'}
                    />
                    </>
                ) : null}

                {trust_log.payment_method === `Credit Card` || trust_log.payment_method === 'Debit Card' ? (
                    <StandardFormGroup
                        obj={trust_log}
                        objName="trust_log"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="number"
                        field={'last_4'}
                        title={'Card Last 4 *'}
                    />
                ) : null}

                <StandardFormGroup
                    obj={trust_log}
                    objName="trust_log"
                    onChange={(o, f, v) => this.onInputChange(f, v)}
                    type="textarea"
                    field={'description'}
                    title={'Memo'}
                />

                <hr />


                {trust_log.payment_method && trust_log.payment_method !== 'Client Direct Deposit Via Bank' ? (
                    <hr />
                ) : null}

                <div className="text-right">
                    <button
                        disabled={!canUpdate}
                        onClick={canUpdate ? this.onSave : null}
                        className="btn btn-success"
                    >
                        Add Trust Amount
                    </button>
                </div>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(TrustReceiveFunds);

