import React, { Component } from 'react';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import PropTypes from 'prop-types'

class SendEmailFrom extends Component {

    render() {

        const { sendEmailOptions, sendingFrom, lockSendingFrom, onSendingFromChange} = this.props

        return (

            <ReactSelect
                placeholder={sendingFrom && sendingFrom.includes('From: ') ? sendingFrom : sendingFrom ? 'From: ' + sendingFrom : <span className="text-danger">SELECT SENDING EMAIL ADDRESS</span>}
                onChange={(values) => onSendingFromChange(values.value)}
                options={
                    lockSendingFrom ? [{'label': sendingFrom, 'value': sendingFrom}] :
                    sendEmailOptions ? sendEmailOptions : []}
            />

        )

    }

}

SendEmailFrom.propTypes = {
    onSendingFromChange   : PropTypes.func.isRequired,
    sendingFrom           : PropTypes.string,
    lockSendingFrom       : PropTypes.bool,
    sendEmailOptions      : PropTypes.array,
}

export default SendEmailFrom
