/*
Documentation

Renders the single view for a case within the contact view on billing center

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Col, Row, CardTitle } from 'reactstrap';
import { canUpdateFinances, canViewFinances, canViewCases } from '_functions/users/privileges';
import ChargeCashPayment from './ChargeCashPayment';
import BillingOverview from './BillingOverview';
import ChargeOneTimePayment from './ChargeOneTimePayment';
import CreatePaymentPlan from './CreatePaymentPlan';
import HasNoVaults from './HasNoVaults';
import HasRecurring from './HasRecurring';
import Invoices from './Invoices';
import Schedule from './Schedule';
import Trust from './Trust';
import Circle from 'components/markup/loading/Circle';
import { toggleBillingCenter } from 'store/functions/system/system'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import _invoices from '_functions/invoices'
import { getUrlParameter, setUrlParameter } from 'utils/urls'

class CaseView extends Component {

    state = {
        navigation: 0,
        canSave: true,
        total_paid: null,
        payments: null
    };

    fetchInvoices = async () => {

        const invoices = await _invoices.findByCase(this.props.case._id)
        if(invoices.success) this.setState({invoices: invoices.data})

    }

    // when navigation is changed scroll to top of billing center
    onChangeNavigation = (navigation) => {

        this.setState({navigation})
        //scroll to top of div
        document.getElementById('billing-center-content').scrollTop = 0;
        setUrlParameter('bcn', navigation)


    }

    //when a payment is added, add it to state to reflect the changes
    addPaymentToState = (value) => this.setState({total_paid: parseFloat(this.state.total_paid) + parseFloat(value)})

    listenForInvoicesAdded = (data) => {

        if(data.data.case === this.props.case._id) {

            let invoices = this.state.invoices

            invoices = invoices && invoices.length ? [...invoices] : [];
            invoices.unshift(data.data)

            this.setState({invoices})

        }

    }

    listenForInvoicesUpdated = (data) => {

        const invoice_id = data.data._id

        let invoices = this.state.invoices && this.state.invoices.length ? [...this.state.invoices] : [];
        invoices[invoices.findIndex(el => el._id === invoice_id)] = data.data;

        this.setState({invoices})

    }

    componentWillUnmount = () => {

        io.off(socketEvents.invoices_added, this.listenForInvoicesAdded)
        io.off(socketEvents.invoices_updated, this.listenForInvoicesUpdated)

        setUrlParameter('bcn', 'na')

    }

    componentDidMount = () => {

        this.fetchInvoices()
        io.on(socketEvents.invoices_added, this.listenForInvoicesAdded)
        io.on(socketEvents.invoices_updated, this.listenForInvoicesUpdated)

        const navigation = parseInt(getUrlParameter('bcn'))
        if(navigation && !Number.isNaN(navigation)) this.setState({navigation})



    }

    render() {

        const _case = this.props.case
        const { account_vaults, contact, showAllCases } = this.props
        const { navigation, invoices } = this.state;

        const userCanViewFinances = canViewFinances()
        const userCanUpdateFinances = canUpdateFinances()
        const userCanViewCases = canViewCases()

        const has_vaults = account_vaults && account_vaults.length ? true : false;

        if(!account_vaults) return <Circle />

        return (

            <>

                {userCanViewFinances && (

                    <Row>

                        <Col md={3}>

                            <h2>Billing</h2>

                            <ul className="pl-0" style={{listStyle: 'none'}}>
                                <li className={navigation === 0 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(0)}>
                                    Overview
                                </li>

                                <li className={navigation === 1 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(1)}>
                                    Case Invoices
                                </li>

                                {userCanUpdateFinances ? (
                                    <li className={navigation === 2 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(2)}>
                                        Charge A Payment
                                    </li>
                                ) : null}

                                <li className={navigation === 3 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(3)}>
                                    Recurring Charges
                                </li>

                                <li className={navigation === 4 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(4)}>
                                    Trust
                                </li>

                                <li className={navigation === 5 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(5)}>
                                    Schedule
                                </li>
                                <a target="_blank" rel="noopener noreferrer" href={`/exports/payments/history/${_case._id}`} >
                                    <li className="cursor-pointer p-3" >
                                        Print Payments
                                    </li>
                                </a>

                             
                            </ul>

                        </Col>

                        <Col md={9}>

                            <Row className="mb-3">

                                <Col md={6} className="align-self-center">
                                    <CardTitle className="mb-0 text-capitalize">
                                        <h2>{_case.name} {_case.case_number ? <span>- Case Number: <span id="archk-billing-case-id">{_case.case_number}</span></span> : null}</h2>
                                    </CardTitle>
                                </Col>

                                <Col md={6} className="align-self-center text-right">

                                    <button className="btn btn-outline-success" onClick={showAllCases}>
                                        <i className="fas fa-arrow-left mr-2" /> Back
                                    </button>

                                    {userCanViewCases && (
                                        <Link
                                            onClick={toggleBillingCenter}
                                            className="btn btn-success"
                                            to={`/dashboard/cases/view/${_case._id}`}
                                        >
                                            <i className="fas fa-edit mr-2" /> Open Case
                                        </Link>
                                    )}

                                </Col>

                            </Row>

                            {navigation === 0 && (
                                <BillingOverview
                                    case={_case}
                                    showAllCases={showAllCases}
                                    onChangeNavigation={(val) => this.onChangeNavigation(val)}
                                />
                            )}


                            {navigation === 1 && (
                                <Invoices
                                    case={_case}
                                    onInvoiceSent={(sent_invoice) => {}}
                                />
                            )}

                            {navigation === 2 && canUpdateFinances && (

                                invoices && invoices.length ? (
                                    <>

                                        {has_vaults ? (
                                            <ChargeOneTimePayment
                                                userCanUpdateFinances={userCanUpdateFinances}
                                                case={_case}
                                                account_vaults={account_vaults}
                                                addPaymentToState={(amount) => this.addPaymentToState(amount)}
                                            />
                                        ) : (
                                            <HasNoVaults />
                                        )}
                                        <ChargeCashPayment
                                            userCanUpdateFinances={userCanUpdateFinances}
                                            invoices={invoices}
                                            case={_case}
                                            addPaymentToState={(amount) => this.addPaymentToState(amount)}
                                        />

                                    </>
                                ) : (
                                    <div className="alert alert-danger">
                                        You must create an invoice before a cash or one time payment can be charged.
                                    </div>
                                )

                            )}

                            {navigation === 3 && (
                                _case.recurrings && _case.recurrings.length ? (

                                    <HasRecurring
                                        userCanUpdateFinances={userCanUpdateFinances}
                                        case={_case}
                                        contact={contact}
                                        account_vaults={account_vaults}
                                        invoices={invoices}
                                        onRecurringDeleted={() => this.props.onRecurringDeleted()}
                                    />

                                ) : (

                                    invoices && invoices.length ? (

                                        has_vaults ? (
                                            <CreatePaymentPlan
                                                userCanUpdateFinances={userCanUpdateFinances}
                                                invoices={invoices}
                                                case={_case}
                                                account_vaults={account_vaults}
                                                onSuccess={(recurring) => this.props.onRecurringCreation(recurring)}
                                            />
                                        ) : (
                                            <HasNoVaults />
                                        )

                                    ) : (
                                        <div className="alert alert-danger">
                                            You must create an invoice before creating a recurring payment plan.
                                        </div>
                                    )

                                )
                            )}

                            {navigation === 4 ? (
                                <Trust
                                    invoices={invoices}
                                    case={_case}
                                />
                            ) : null}

                            {navigation === 5 ? (
                                <Schedule
                                    contact={contact}
                                    case={_case}
                                />
                            ) : null}

                        </Col>

                    </Row>

                )}

            </>

        )

    }

}

CaseView.propTypes = {
    showAllCases    : PropTypes.func.isRequired,
    case            : PropTypes.object.isRequired,
    contact         : PropTypes.object.isRequired,
    account_vaults  : PropTypes.array,
}

export default CaseView
