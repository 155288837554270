import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom'
import { getUrlParameter} from 'utils/urls'
import Dots from 'components/markup/loading/Dots'

import _contacts from '_functions/contacts'

const Lookup = ({}) => {

    const [err, setErr] = useState(false)
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        const lookup = async () => {
            const found = await _contacts.findByPhone(getUrlParameter('phone'))
            if(!found.data) setErr(true)
            setRedirect(`/dashboard/contacts/view/${found.data._id}`)
        }
        lookup()
    }, [])

    if(redirect) return <Redirect to={redirect} />
    if(err) return <div className='alert alert-warning text-center'>Contact Could Not Be Found For This Call</div>

    return (
        <div className='text-center py-5'>Looking Up Contact <Dots /></div>
    )

}

export default Lookup