/*
Documentation

This file allows a user to  update the objection filed, data document sent to opposing party, and amendment date fields

*/


import React from 'react';
import DatePicker from 'react-datepicker';

import { Card, CardBody, CardHeader, CardTitle, FormGroup, CardFooter } from 'reactstrap';
import { convertDateToUnix } from 'utils/time';
import _objections from '_functions/objections';
import { toggleAlertBS } from 'store/functions/system/system'
import PropTypes from 'prop-types';

class ObjectionFields extends React.Component {

    onUpdateFields = async () => {

        const { objection } = Object.assign({}, this.props)

        objection.date_document_sent = convertDateToUnix(objection.date_document_sent)
        objection.date_amendment_filed = convertDateToUnix(objection.date_amendment_filed)
        objection.date_objection_filed = convertDateToUnix(objection.date_objection_filed)

        const action = await _objections.update(objection._id, {
            ...objection,
            date_document_sent: objection.date_document_sent,
            date_amendment_filed: objection.date_amendment_filed,
            date_objection_filed: objection.date_objection_filed,
        })

        if(action.success) {
            toggleAlertBS(false, 'Objection Fields Updated Successfully.')
        } else {
            toggleAlertBS(true, 'An error occurred updating objection fields.')
        }

    }

    render() {

        const { objection, onInputChange } = this.props

        return (
            <>
                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">Objection Fields</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Date Objection Filed</label>
                            <div
                                className={
                                    objection.date_objection_filedState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    objection.date_objection_filedState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={objection.date_objection_filed}
                                    onChange={date => onInputChange('date_objection_filed', date)}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Date Document Sent To Objecting Party</label>
                            <div
                                className={
                                    objection.date_document_sentState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    objection.date_document_sentState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={objection.date_document_sent}
                                    onChange={date => onInputChange('date_document_sent', date)}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Date Amendment Filed</label>
                            <div
                                className={
                                    objection.date_amendment_filedState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    objection.date_amendment_filedState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={objection.date_amendment_filed}
                                    onChange={date => onInputChange('date_amendment_filed', date)}
                                />
                            </div>
                        </FormGroup>

                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={this.onUpdateFields} className="btn btn-success"><i className="fas fa-save mr-2" /> Update Fields</button>
                    </CardFooter>
                </Card>

            </>
        );
    }
}

ObjectionFields.propTypes = {
    objection:      PropTypes.object.isRequired,
    onInputChange:  PropTypes.func.isRequired
}

export default ObjectionFields
