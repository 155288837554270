import React from 'react'

import { Container, Card, CardHeader, CardTitle } from 'reactstrap';

const Directory = () => (
    <div className='pt-3'>
         <Container fluid>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Booking Directory</CardTitle>
                </CardHeader>

                    <div className='table-responsive'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='text-right'>Booking Link</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td>Sales</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/d/3sc-xvw-7rr/debt-relief-consultation">https://calendly.com/d/3sc-xvw-7rr/debt-relief-consultation</a></td>
                                </tr>
                               

                              
                                <tr>
                                    <td>Alex Borrero</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-alex">https://calendly.com/fairmax-law-alex</a></td>
                                </tr>
                             
                                <tr>
                                    <td>Danny Davila</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-danny">https://calendly.com/fairmax-law-danny</a></td>
                                </tr>

                                <tr>
                                    <td>Faith Mondenedo</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-faith">https://calendly.com/fairmax-law-faith</a></td>
                                </tr>

                                <tr>
                                    <td>Jenny Dayag</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-jenny">https://calendly.com/fairmax-law-jenny</a></td>
                                </tr>

                                <tr>
                                    <td>John Mark</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-johnmark">https://calendly.com/fairmax-law-johnmark</a></td>
                                </tr>
                            
                                <tr>
                                    <td>Lina Munoz</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-lina">https://calendly.com/fairmax-law-lina</a></td>
                                </tr>
                            
                              

                                <tr>
                                    <td>Luisa Calderon</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-luisa">https://calendly.com/fairmax-law-luisa</a></td>
                                </tr>
                                <tr>
                                    <td>Mary Coslo</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-mary">https://calendly.com/fairmax-law-mary</a></td>
                                </tr>
                             

                                <tr>
                                    <td>Nathalia Delgado</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-nathalia">https://calendly.com/fairmax-law-nathalia</a></td>
                                </tr>
                              

                                <tr>
                                    <td>Nathalie Escanlar</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-nathalie">https://calendly.com/fairmax-law-nathalie</a></td>
                                </tr>
                               
                                <tr>
                                    <td>Norma Rahal</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-norma">https://calendly.com/fairmax-law-norma</a></td>
                                </tr>
                              
                                <tr>
                                    <td>Sophia Whyte</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-sophia">https://calendly.com/fairmax-law-sophia</a></td>
                                </tr>

                             
                             
                                <tr>
                                    <td>Tammy Johnson</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-tammy">https://calendly.com/fairmax-law-tammy</a></td>
                                </tr>

                                <tr>
                                    <td>Valentina Monroy</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-valentina">https://calendly.com/fairmax-law-valentina</a></td>
                                </tr>

                                <tr>
                                    <td>Mary Rose</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-mary-rose">https://calendly.com/fairmax-mary-rose</a></td>
                                </tr>
                                <tr>
                                    <td>Angela Seymour</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-angela">https://calendly.com/fairmax-law-angela</a></td>
                                </tr>
                              
                                
                                <tr>
                                    <td>Jean Cachaya</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-jean">https://calendly.com/fairmax-law-jean</a></td>
                                </tr>
                                <tr>
                                    <td>Mike Jaafar</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/bigcasemike">https://calendly.com/bigcasemike</a></td>
                                </tr>
                                <tr>
                                    <td>Christian Sinisterra</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-christian">https://calendly.com/fairmax-law-christian</a></td>
                                </tr>
                                <tr>
                                    <td>Betsy Sanchez</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-betsy">https://calendly.com/fairmax-law-betsy</a></td>
                                </tr>
                                <tr>
                                    <td>Sammy Navarrete</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-sammy">https://calendly.com/fairmax-law-sammy</a></td>
                                </tr>
                                <tr>
                                    <td>Lorena Aristizabal</td>
                                    <td className='text-right'><a target="_blank" rel="noopener noreferrer" href="https://calendly.com/fairmax-law-lorena">https://calendly.com/fairmax-law-lorena</a></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

            </Card>
            
        </Container>
    </div>
)

export default Directory