import apiWorker from '../apiWorker'

export default {

    create:             (data)          => apiWorker('post',  `/api/v1/core/case_workflow_tasks/create`, data),
    finish:             (task_id, data) => apiWorker('post',  `/api/v1/core/case_workflow_tasks/finish/${task_id}`, data),
    update:             (task_id, data) => apiWorker('patch', `/api/v1/core/case_workflow_tasks/update/${task_id}`, data),
    queryUnfinished:    (data)          => apiWorker('post',  `/api/v1/core/case_workflow_tasks/unfinished`, data),
    queryOverdue:       (data)          => apiWorker('post',  `/api/v1/core/case_workflow_tasks/overdue`, data),
    queryOverdueToday:  (data)          => apiWorker('post',  `/api/v1/core/case_workflow_tasks/overdue_today`, data),

}
