/*

This function takes a number such as 100 and turns it in to money
make sure to / 100 before sending numbers into this function if they are in cents
note: all system payments should be stored in cents with no decimal

*/

export const formatMoney = function (amount, decimalCount = 2, decimal = ".", thousands = ",") {

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + '$' + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");

    } catch (e) {

        console.log(e)
        return undefined
    }

};

// returns false if length of decimals is greater than 2
// ex: 2 returns true, 2.1 returns true, 2.22 returns true, 2.222 returns false
export const passesDecimalCheck = (flat_fee_amount) => {

    if(!flat_fee_amount) return true

    if(typeof flat_fee_amount !== 'string' && typeof flat_fee_amount !== 'number') return false

    try {



        flat_fee_amount = flat_fee_amount.toString()


        const val = flat_fee_amount.split('.');

        if(val && val[1]) {

            if(val[1].length > 2) {
                return false;
            }

        }

        return true;


    } catch(e) {

        console.log(e)
        return false

    }

}

