import PropTypes from 'prop-types';

import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import Canvas from './Canvas/index'
import { connect } from 'react-redux';

class CanvasMain extends React.Component {

    render() {

        const { markers, addMarker, removeMarker, setMarkerType, onFileAdded, pdfData, updateMarkerName, shouldFireUploads, onUpload, viewing_user } = this.props

        return (

            <div className="archk-template-docs-main">


                <Card style={{display: pdfData ? 'none' : 'block', height: 'auto', width: 720, margin: 'auto'}} className="z-depth-3">

                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0">Upload PDF To Send</CardTitle>
                    </CardHeader>

                    <CardBody className="text-center py-6">
                        <DragAndDrop
                            // only allow pdf documents
                            acceptedFiles="application/pdf"
                            uploadOne={true}
                            zone_id="documents_1"
                            url={`/api/v1/core/documents/create`}
                            hidePreview={true}
                            onFileAdded={onFileAdded}
                            shouldShowButton={false}
                            shouldFireUploads={shouldFireUploads}
                            onUpload={(a, b) => { onUpload(a, b) }}
                            onError={(e) => toggleStandardLoader(false)}
                            defaultValue={`Contract_${viewing_user._id}`}
                            extraData={{bucket: `/template docs`}}
                        />
                    </CardBody>

                </Card>

                {pdfData ? (
                     <div style={{height: 'auto', width: 720,  margin: 'auto'}} >
                        <Canvas
                            pdfData={pdfData}
                            markers={markers}
                            addMarker={addMarker}
                            removeMarker={removeMarker}
                            setMarkerType={setMarkerType}
                            updateMarkerName={updateMarkerName}
                        />
                    </div>
                ) : null}

            </div>

        );
    }
}

CanvasMain.propTypes = {
    markers             : PropTypes.array.isRequired,
    setMarkerType       : PropTypes.func.isRequired,
    removeMarker        : PropTypes.func.isRequired,
    addMarker           : PropTypes.func.isRequired,
    updateMarkerName    : PropTypes.func.isRequired,

    onUpload            : PropTypes.func.isRequired,
    shouldFireUploads   : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(CanvasMain);
