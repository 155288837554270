import PropTypes from 'prop-types';
import React from "react";
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'
import renderName from 'utils/renderName';
import _cases from '_functions/cases';
import * as _ from 'underscore';

class CaseSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            showResults: false,
            selected: {},
            dataToShow: [],
        }
       this.queryCasesThrottled = _.throttle(this.queryCases, 750)
    }


    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    queryCases = async (value) => {

        const cases = await _cases.search({
            search: value,
            limit: 25,
        })

        if(cases.success) {
            this.setState({dataToShow: cases.data, showResults: true})

        }

    }


    onChange = async (value) => {

        this.setState({filter: value})

        if(value) {
            this.queryCasesThrottled(value)
        } else {
            this.setState({filter: null, showResults: false})
        }

    }


    componentDidMount = () => {
        const { value } = this.props
        this.setState({ selected: value ? value : {} })
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                onSelect={this.onSelect}
                placeholderFormatter={selected ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Case: <b className="text-success text-capitalize">{renderName(selected)}</b></span> : 
                        'Select Case'
                }
                resultsFormatter={(result) => (
                    <li key={result._id} onClick={() => this.onSelect(result)}>
                        <p className="text-sm mb-0 text-capitalize">{result.name ? result.name : "CASE NAME NOT FOUND"}</p>
                        <p className="text-sm mb-0 text-muted"><i className="fas fa-shoe-prints mr-2" />STEP:  {result.finished_on ? 'Finished' : result.current_step ? result.current_step.name : 'NOT FOUND'} </p>
                    </li>
                )}
            />
        );
    }
}

CaseSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default CaseSearchInput
