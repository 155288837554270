/*
Documentation

This is the component that we use to assign a user to a role within the case creation process
Tested for errors 10/15/2020

*/

import PropTypes from 'prop-types';
import React from "react";
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Col, Row } from "reactstrap";
import UserSearchInput from '../../../_components/UserSearchInput';

class AssignUsers extends React.Component {

    state = {
        selected_roles: this.props.selected_roles || [],
        errors: 0
    }

    selectRoles = (user, r) => {

        let selected_roles = [...this.state.selected_roles]

        if(this.hasRole(r)) {

            // replace the role
            selected_roles[selected_roles.findIndex(role => role.role === r)] = { user, role: r}

        } else {

            //push the new selected workflow to the array of roles
            selected_roles.push({ user, role: r });

        }

        this.setState({selected_roles})

    }

    onAssignUser = (obj) => {

        this.setState({errors: 0})

        if(this.props.selected_workflow && this.props.selected_workflow.roles) {

            const roles = this.props.selected_workflow.roles

            let selected_roles = [...this.state.selected_roles]

            let errors = 0;

            roles.forEach(r => {
                if(!selected_roles.find(sr => sr.role === r))
                errors++
            })

            if(!errors) {
                this.props.onAssignUsers(selected_roles)
            } else {
                this.setState({errors})
            }

        } else {

            console.log('this.props.selected_workflow or this.props.selected_workflow.roles is empty at onAssignUser in component AssignUsers. Prop tree broken.')

        }

    }

    // create the row that allows a user to assign system users to a case role
    renderAssignmentRow = (r, i) => {

        let hasRole = this.hasRole(r)

        return (
            <Row key={i}>

                <Col md={3}>
                    {hasRole ? <i className="fas fa-check mr-2 text-success" /> : null}
                    {r}
                </Col>

                <Col md={9}>
                    <UserSearchInput
                        value={hasRole ? hasRole.user : {}}
                        onSelect={(user) => this.selectRoles(user, r)}
                    />
                </Col>

            </Row>
        )

    }

    hasRole = (role) => this.state.selected_roles.find(r =>  r.role === role );

    render() {

        const { selected_workflow } = this.props

        return (

            <>

            {this.state.errors ? <div className="alert alert-danger">You must assign all roles before proceeding.</div> : null}

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Assign Users To Case</CardTitle>
                </CardHeader>

                <CardBody>
                    {selected_workflow.roles && selected_workflow.roles.length  ? (

                        <div>
                            {selected_workflow.roles.map((r, i) => (
                                this.renderAssignmentRow(r, i)
                            ))}
                        </div>

                    ) : (
                        <div>
                            <p>There are no roles that need association for this workflow. Move to the next step.</p>
                        </div>
                    )}

                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={this.onAssignUser} className="btn btn-success">Next Step</button>
                </CardFooter>

            </Card>

            </>
        );
    }
}

AssignUsers.propTypes = {
    selected_roles    : PropTypes.array.isRequired,
    selected_workflow : PropTypes.object.isRequired,
    onAssignUsers     : PropTypes.func.isRequired,
}

export default AssignUsers
