import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';
import keys from 'keys';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Row } from "reactstrap";
import _template_docs_sent from '_functions/template_docs_sent';
import * as privileges from '_functions/users/privileges';
import ModalVoidDocument from './modals//ModalVoidDocument';
import renderName from 'utils/renderName'

class TemplateDocuments extends React.Component {

    state = {
        selectOptions: [],
        selectedDoc: null,
        selectedDocToVoid: null,
        showModalVoid: false,
    }



    onVoidSuccess = (document) => {

        this.fetchTemplateDocs()

    }

    fetchTemplateDocs = async () => {

        const docs = []

        // documents may be sent to contacts not on a case, here we get all documents sent out to 
        // the contact AND the case itself
        const values = await Promise.all([
            _template_docs_sent.findByContact(this.props.case.contact._id),
            _template_docs_sent.findByCase(this.props.case._id),
        ])

        if(values[0].data && values[1].data) {

            const all = values[0].data.concat(values[1].data);

            all.forEach(a => {
                if(!docs.some(d => d._id === a._id)) {
                    docs.push(a);
                }
            })

            this.setState({template_docs_sent: docs})

        }
    }


    toggleModalVoid = (selectedDocToVoid, type) => this.setState({showModalVoid: !this.state.showModalVoid, selectedDocToVoid, type})

    componentDidMount = async () => {

        let selectOptions = []

        this.props.template_docs.forEach(doc => {
            selectOptions.push({label: doc.name, value: doc})
        })

        this.setState({selectOptions})

        this.fetchTemplateDocs()

    }

    render() {

        const canUpdate = privileges.canUpdateCases()

        const { selectedDoc, selectOptions, showModalVoid, selectedDocToVoid, template_docs_sent, type } = this.state
        const _case = this.props.case

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Document Signing</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <label className="form-control-label">Select the template doc you wish send from the list below and click the green button below to send it.</label>
                        <ReactSelect
                            onChange={(values) => this.setState({selectedDoc: values.value})}
                            options={selectOptions}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter >
                    <Row>

                        <Col xs={6}>
                            <Link
                                to={`/dashboard/template_docs/new?case=${_case._id}&contact=${_case.contact._id}`}
                                className="btn btn-outline-warning d-none d-lg-block"
                                disabled={!canUpdate}
                                onClick={canUpdate ? null : (e) => e.preventDefault()}
                            >
                                Send New
                            </Link>
                        </Col>

                        <Col xs={6} className="text-right">
                            {selectedDoc ? (
                                <Link
                                    to={`/dashboard/template_docs/${selectedDoc._id}?case=${_case._id}&contact=${_case.contact._id}`}
                                    disabled={!canUpdate ? true : selectedDoc ? false : true}
                                    className="btn btn-success btn-block"
                                >
                                Send Template Doc
                            </Link>
                            ) : (
                                <button
                                    disabled={true}
                                    className="btn btn-success btn-block"
                                >
                                    Send Template
                                </button>
                            )}
                            
                        </Col>

                    </Row>
                </CardFooter>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Template Docs Sent To Contact</CardTitle>
                </CardHeader>

                {!template_docs_sent ? <Circle /> :
                    template_docs_sent.length ? template_docs_sent.map(doc => (
                        <React.Fragment key={doc._id}>

                            <CardHeader className="bg-secondary">
                                <CardTitle className="mb-0">

                                    <i onClick={() => this.toggleModalVoid(doc, 'template')} className="fas fa-edit text-success cursor-pointer mr-2" />
                                    {doc.template_doc ? doc.template_doc.name : doc.name ? doc.name : 'TEMPLATE NOT FOUND'}
                                </CardTitle>
                            </CardHeader>

                           <CardHeader>
                                <Row className="mb-3">
                                    <Col md={6} className="align-self-center align-self-center">
                                        <h4 className="mb-0">Events</h4>
                                    </Col>
                                    <Col md={6} className="text-right align-self-center">
                                        <p className="text-sm mb-0">
                                        {doc.status === 'void' ? (
                                            <Badge style={{width: 140, display: 'inline-block'}} color="danger" className="text-sm">Voided</Badge>
                                        ) : doc.status === 'sent' ? (
                                            <Badge style={{width: 140, display: 'inline-block'}} color="primary" className="text-sm text-white">Sent</Badge> 
                                        ):  doc.status === 'awaiting internal signature' ? (
                                            <a href={`${keys.CLIENT_URL}/actions/signatures/pdf/sign/${doc._id}?token=${doc.internal_access_code}`} target="_blank" rel="noopener noreferrer" >
                                                <Badge style={{width: 140, display: 'inline-block'}} color="warning" className="text-sm ">Needs Signature</Badge> 
                                            </a>
                                        ) :
                                            <Badge style={{width: 140, display: 'inline-block'}} color="success" className="text-sm">Signed</Badge>
                                        }
                                        </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <p className="text-sm mb-0">Sent On:</p>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        <p className="text-sm mb-0">{moment.unix(doc.created_at).format('MMM Do, YYYY - h:mm A')}</p>
                                    </Col>
                                </Row>

                                {doc.parties && doc.parties.length ? doc.parties.map(p => (
                                    <Row>
                                        <Col md={6}>
                                            <p className="text-sm mb-0 text-capitalize"><b className="text-dark">Signed: </b>{renderName(p.contact)}</p>
                                        </Col>
                                        <Col md={6} className="text-right">
                                            <p className="text-sm mb-0">
                                                {p.signed_on ? (
                                                    moment.unix(p.signed_on).format('MMM Do, YYYY - h:mm A')
                                                ) : 'not signed'}
                                            </p>
                                        </Col>
                                    </Row>
                                )) : null}
                           </CardHeader>

                        </React.Fragment>
                    )) : (
                        <CardBody>
                            <p className="text-sm px-4 mb-2">No Documents have been sent for signing</p>
                        </CardBody>
                    )
                }

            </Card>

            <ModalVoidDocument
                showModal={showModalVoid}
                toggleModal={this.toggleModalVoid}
                selectedDocToVoid={selectedDocToVoid}
                onSuccess={this.onVoidSuccess}
                type={type}
            />

            </>

        );
    }
}

TemplateDocuments.propTypes = {
    case: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
    	template_docs: state.template_docs.template_docs,
    };
};

export default connect(mapStateToProps, '')(TemplateDocuments);
