/*
Documentation

this file renders a card showing all Finished tasks for the step being viewed

*/

import LoadingCircle from 'components/markup/loading/Circle';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle } from "reactstrap";
import renderName from 'utils/renderName'
import AvatarImage from 'components/functional/images/AvatarImage';

class TasksFinished extends Component {

    state = {};

    render() {

        const { tasks_finished } = this.props

        return (

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Completed Step Tasks</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table" >
                        <tbody>

                            {tasks_finished && tasks_finished.length ? tasks_finished.map((task, i) => (
                                <tr key={i}>

                                    <td style={{verticalAlign: 'top', width: '3.5rem', maxWidth: 45}}>
                                        <AvatarImage style={{width: 42, height: 42}} src={task.finished_by.avatar} className="mr-2" />
                                    </td>

                                    <td>

                                        <h6 className="text-sm mb-0 font-weight-bold">

                                            <b className="text-capitalize">
                                                <i className="fas mr-2 fa-check text-primary" />
                                                <Link to={`/dashboard/users/view/${task.finished_by._id}`}>
                                                    {renderName(task.finished_by)}
                                                </Link>
                                            </b>

                                            {' '}&mdash;{' '}{moment.unix(task.finished).fromNow()}

                                        </h6>

                                        <p style={{whiteSpace: 'normal'}} className="text-sm mb-0 font-weight-bold text-capitalize">
                                            {task.name}
                                        </p>

                                        <p style={{whiteSpace: 'normal'}} className="text-sm mb-0">
                                            {ReactHTMLParser(task.value)}
                                        </p>

                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No tasks have been finished for this step yet.</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>

                {!tasks_finished ? ( <LoadingCircle width={30}/> ) : null }

            </Card>

        )

    }

}

TasksFinished.propTypes = {
    tasks_finished: PropTypes.array.isRequired,
}

export default TasksFinished
