import React from "react";
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { FormGroup, Input } from 'reactstrap';


class ModalMarkComplete extends React.Component {

    state = {
       outcome: '',
       err: false
    }

    toggleModal = () => {
        this.setState({outcome: '', err: false})
        this.props.toggleModal();
    }

    onValidate = () => {

        const outcome = this.state.outcome;

        if(!outcome || outcome === 'false') return this.setState({err: true})

        this.props.onConfirmation(outcome);
        this.toggleModal();

    }
  
    render() {

        const { showModal, SETTINGS } = this.props;
        const { outcome, err } = this.state;

        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                zIndex={100001}
                toggle={this.toggleModal}
            >
                <div className="modal-header">
                    <h5 className="modal-title">Mark Appointment Finished</h5>
                </div>

                <div className="modal-body">

                    <p className="text-sm"><i className="fas fa-exclamation-triangle mr-2 text-danger" />Manually finishing this appointment will not fire any step change events. Make sure to update the clients case accordingly.</p>

                    <FormGroup>
                        <label className="form-control-label">What was the outcome of this appointment?</label>
                        <Input 
                            type="select"
                            value={outcome}
                            onChange={(e) => this.setState({outcome: e.target.value})}
                        >
                            <option value="false"></option>
                            {SETTINGS.call_center.outcomes.map((outcome, i) => <option key={i} value={outcome}>{outcome}</option> )}
                        </Input>
                    </FormGroup>

                    {err ? <p className="text-sm font-weight-bold text-danger mb-0">Please select a valid outcome</p> : null}

                </div>

                <div className="modal-footer">
                    <button onClick={this.toggleModal} className="btn btn-success"><i className="fas fa-arrow-left mr-2" />Cancel</button>
                    <button onClick={this.onValidate} className="btn btn-warning">Mark Finished</button>
                </div>
            </Modal>
        )

    }
}

const mapStateToProps = state => {
    return {
        SETTINGS : state.system.SETTINGS
    };
};

export default connect(mapStateToProps, '')(ModalMarkComplete);
