import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class AppointmentsCrudRoles extends React.Component {

    hasError = () => {

        const { associationError, appointment, role} = this.props;

        if(associationError) {

            let foundRole = appointment.roles.find(p => p.title === role);
            return foundRole && foundRole.user ? false : true

        }

        return false

    }

    render() {

        const { appointment, onRemoveRole, onAssignRole, role } = this.props

        let foundRole = appointment.roles ? appointment.roles.find(p => p.title === role) : null;
        if(foundRole && foundRole.user) foundRole = foundRole.user

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign <span className="text-capitalize">{role}</span></h4>
                        <UserSearchInput
                            hideTitle={true}
                            value={{}}
                            onSelect={(user) => onAssignRole(role, user)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select at least 1 staff member before proceeding.</div>
                    ) : null}
                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th className="text-capitalize">{role}</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {foundRole ?  (
                            <tr key={foundRole._id}>
                                <td>
                                    <div className="text-capitalize">{renderName(foundRole)}</div>
                                    {foundRole && foundRole.phone ? <div>{formatPhone(foundRole.phone)}</div> : null}
                                    {foundRole && foundRole.email ? <div>{foundRole.email}</div> : null}
                                </td>
                                <td className="text-right">
                                    <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveRole(role)} />
                                </td>
                            </tr>
                        ): (
                            <tr>
                                <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Role Assigned</td>
                                <td></td>
                            </tr>

                        )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudRoles.propTypes = {
    onRemoveRole    : PropTypes.func.isRequired,
    onAssignRole    : PropTypes.func.isRequired,
    appointment     : PropTypes.object.isRequired,
}

export default AppointmentsCrudRoles
