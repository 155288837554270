import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import CaseSearchInput from 'views/dashboard/pages/_components/CaseSearchInput';

class AppointmentsCrudCase extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.case) return true
            if(appointment.case && !appointment.case._id) return true;

        }

        return false

    }

    render() {

        const { appointment, caseRequired, onAssignCase, onRemoveCase } = this.props
        const _case = appointment.case;

        if(!caseRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Associate Case</h4>
                        <CaseSearchInput
                            value={_case ? _case : {}}
                            onSelect={(selectedCase) => onAssignCase(selectedCase)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a case before proceeding.</div>
                    ) : null}

                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Case</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {_case ?(
                                <tr key={_case._id}>
                                    <td>
                                        <div className="text-capitalize">{_case.name}</div>
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveCase()} />
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold">
                                        <i className="fas fa-exclamation-triangle mr-2 " /> No Case Assigned
                                    </td>
                                    <td></td>
                                </tr>

                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudCase.propTypes = {
    caseRequired   : PropTypes.func.isRequired,
    onAssignCase   : PropTypes.func.isRequired,
    onRemoveCase   : PropTypes.func.isRequired,
    appointment    : PropTypes.object.isRequired,
}

export default AppointmentsCrudCase
