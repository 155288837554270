import PropTypes from 'prop-types';
import React from 'react';
import renderName from 'utils/renderName'
import { formatPhone } from 'utils/text'

class ModalSendReviewContacts extends React.Component {

    state = {
        isValidated: true,
        contacts: []
    }

    render() {

        const { contacts } = this.props

        return (

            <div>

                {contacts.length ? contacts.map((contact, i) => (
                    <div key={contact.fullContact._id + i}>

                        <p className="text-sm mb-0">When you send this document the following contact(s) will be notified:</p>

                        <hr className="my-2" />
                        <h4 className="mb-0 text-capitalize">{renderName(contact.fullContact)}</h4>
                        {contact.fullContact.email ? (
                            <p className="text-sm mb-0">
                                By email at:{' '}
                                <b className="text-dark font-weight-bold">{contact.fullContact.email}</b>
                            </p>
                        ) : null }
                        {contact.fullContact.phone ? (
                            <p className="text-sm mb-0">
                                By text at: {' '}
                                <b className="text-dark font-weight-bold">{formatPhone(contact.fullContact.phone)}</b>
                            </p>
                        ) : null }

                        <p className="text-sm mb-0">
                            Before sending this document make sure you have highlighted all required fields for the receiving client to fill out.
                        </p>

                    </div>
                )) : (
                    <p className="text-sm mb-0">
                        <i className="fas fa-exclamation-triangle text-danger mr-2 " /> 
                        No contacts have been selected to send this document to
                    </p>
                )}

               

            </div>
           
        );
    }
}

ModalSendReviewContacts.propTypes = {
    contacts : PropTypes.array.isRequired,
}

export default ModalSendReviewContacts
