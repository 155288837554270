//returns a formatted string based on how often a recurring runs

export default (interval) => {

    interval = parseInt(interval)

    if(interval === 1) {
        return 'Once Per '
    } else if(interval === 2) {
        return 'Every 2 '
    } else if(interval === 3) {
        return 'Every 3 '
    }

    return null

}