/*
Documentation

This is the master file for creating contact
The functionality for this is held in views/dashboard/pages/contacts/ContactsCrud

*/

import React from "react";
import { Card, CardBody } from "reactstrap";
import ContactsCrud from '../../components/ContactsCrud';
import { toggleAlertBS } from 'store/functions/system/system'
import PropTypes from 'prop-types'

class ContactsUpdate extends React.Component {

    state = {
        alert: null
    }

    componentWillReceiveProps = () => {

        setTimeout(() => {
           if(this.props.shouldScrollToRelationships) {

                try {

                    const el = document.getElementById('contacts-crud-relationship-start')
                    window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })

                } catch(e) {
                    console.log('scrolling failed ')
                }
           }
        }, 100)

    }

    render() {

        const { fetchContact, navPills, contact } = this.props

        return (
            navPills === 5 ? (

                <Card className="card-color card-warning">

                    <CardBody>
                        <ContactsCrud
                            contact={contact}
                            onUpdate={(values) => {
                                toggleAlertBS(false, 'Contact has been updated successfully.')
                                fetchContact()
                            }}
                        />
                    </CardBody>
                </Card>

            ) : null
        );
    }
}

ContactsUpdate.propTypes = {
    fetchContact                  : PropTypes.func.isRequired,
    navPills                      : PropTypes.number.isRequired,
    contact                       : PropTypes.object.isRequired,
    shouldScrollToRelationships   : PropTypes.bool.isRequired,
}

export default ContactsUpdate
