import apiWorker from '../apiWorker'


export default {

    create: (data)                      => apiWorker('post',   `/api/v1/core/garnishments/create/`, data),
    notesCreate: (objection_id, data)   => apiWorker('post', `/api/v1/core/garnishments/notes_create/${objection_id}`, data),
    update: (objection_id, data)        => apiWorker('patch',  `/api/v1/core/garnishments/update/${objection_id}`, data),
    delete: (objection_id)              => apiWorker('delete', `/api/v1/core/garnishments/delete/${objection_id}`),

    find: (query)                       => apiWorker('get', `/api/v1/core/garnishments/find${query}`),

}
