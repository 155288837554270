/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    forms: [],
    total: 0,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_FORMS:

            return {
                ...state,
                forms: action.payload.data,
                total: action.payload.total_documents,
            }

        default: return state;

    }

}

export default reducer;
