/*
Documentation

This file renders a card that allows for adding notes to a garnishment

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import renderName from 'utils/renderName';
import _garnishments from '_functions/garnishments';
import PropTypes from 'prop-types';

class GarnishmentNotes extends React.Component {

    state = {
        note: {},
    }

    onNotesCreate = async() => {

        const text = this.state.note.text
        if(!text) return

        _garnishments.notesCreate(this.props.garnishment._id, {
            user: this.props.viewing_user._id,
            text: text
        })

        this.setState({note: {}})

    }

    getNotes = (garnishment) => {

        let notes = [];

        if(garnishment.notes && garnishment.notes) {
            notes = garnishment.notes.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
        }

        return notes
    }

    render() {

        const { note } = this.state
        const { garnishment, toggleModalGarnishmentsCrud } = this.props

        const notes = this.getNotes(garnishment)

        return (
            <>

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">
                            <i onClick={() => toggleModalGarnishmentsCrud('update')} className="fas fa-edit mr-2 text-warning" />
                            {garnishment.name}
                        </CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={note}
                            objName="note"
                            onChange={(o, f, v) => this.setState({note: {text: v}})}
                            type="textarea"
                            style={{minHeight: 100}}
                            field={'text'}
                            title={'Add Garnishment Note'}
                        />

                        <div className="text-right">
                            <button onClick={this.onNotesCreate} className="btn btn-success">
                                Add Note
                            </button>
                        </div>

                    </CardBody>

                    <CardFooter>

                        {notes.length ? (
                            notes.map((note, i) => (
                                <div key={i} className="p-4 bg-secondary mb-4">
                                    <p className="text-sm mb-0 font-weight-bold text-capitalize">
                                        <AvatarImage src={note.user ? note.user.avatar : null} className="mr-3" />
                                        {renderName(note.user)} {' '}
                                        {moment.unix(note.created_at).format('MMM Do, YYYY - h:mm A')}
                                    </p>
                                    <p className="text-sm mt-2 mb-0">{note.text}</p>
                                </div>
                            ))
                        ) : (
                            <div className="p-4 bg-secondary mb-4">
                                <p className="text-sm mb-0 font-weight-bold">No garnishment notes have been created yet.</p>
                            </div>
                        )}

                    </CardFooter>

                </Card>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

GarnishmentNotes.propTypes = {
    garnishment: PropTypes.object.isRequired,
    toggleModalGarnishmentsCrud: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, '')(GarnishmentNotes);
