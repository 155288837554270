import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import renderName from 'utils/renderName';
import { getMarkerPositionOnPage } from '../../_functions/utils';

class ModalSendIsInvalid extends React.Component {

    state = {
        errors: [],
        warnings: [],
        contact_1: null,
        contact_2: null,
        contact_3: null,
        contact_4: null,
    }

    getName = (marker, index) => {

        const contact = this.state[`contact_${index}`]

        return marker.party === index ? contact ? 
            <span className="text-capitalize">{renderName(contact)}</span> : 
            <span className="text-danger">Party Not Set:</span> : 
            null;

    }

    unFocusLastMarker = () => {

        const { lastIndex }  = this.state
        if(lastIndex) {
            const lastSelectedMarker = document.querySelector(`[data-marker-index='${lastIndex}']`);
            if(lastSelectedMarker) {
                lastSelectedMarker.style.zIndex = 1;
                lastSelectedMarker.style.backgroundColor = '';
            }

        }
    }

    focusMarker = (marker) => {
        const markers = this.props.markers

        const foundIndex = markers.findIndex(el => el.party === marker.party && el.top === marker.top && el.left === marker.left);

        const selectedMarker = document.querySelector(`[data-marker-index='${foundIndex}']`);

        if(selectedMarker) {
            selectedMarker.style.zIndex = 9999999999;
            selectedMarker.style.backgroundColor = 'yellow';
            this.setState({lastIndex: foundIndex})
        }

    }

    scrollToMarker = (marker) => {

        this.unFocusLastMarker()
        this.focusMarker(marker);

        window.scrollTo({top: getMarkerPositionOnPage(marker).top, left: 0})

    }

    componentWillUnmount = () => {
       this.unFocusLastMarker()
    }

    setMarkerAnswer = (value, marker) => {

        const markers = this.props.markers
        const foundIndex = markers.findIndex(el => el.party === marker.party && el.top === marker.top && el.left === marker.left);

        this.props.setMarkerAnswer(foundIndex, value)

    }

    componentDidMount = () => {

        const contacts = this.props.contacts;

        const contact_1 = contacts.find(c => c.party_index === 1);
        const contact_2 = contacts.find(c => c.party_index === 2);
        const contact_3 = contacts.find(c => c.party_index === 3);
        const contact_4 = contacts.find(c => c.party_index === 4);

        this.setState({
            contact_1: contact_1 ? contact_1.fullContact : null, 
            contact_2: contact_2 ? contact_2.fullContact : null, 
            contact_3: contact_3 ? contact_3.fullContact : null, 
            contact_4: contact_4 ? contact_4.fullContact : null
        })

    }

    render() {

        const { markers } = this.props;

        const textMarkers = markers.filter(m => m.type === 'text')

        textMarkers.sort((a, b) => a.party < b.party ? -1 : 1);

        return (

            <div className="modal-body">
               
                {textMarkers.length ? textMarkers.map(marker => (
                    <div>
                        <FormGroup>
                            <label className="form-control-label">
                                {this.getName(marker, 1)}
                                {this.getName(marker, 2)}
                                {this.getName(marker, 3)}
                                {this.getName(marker, 4)}
                                {' '}{marker.name}
                            </label>
                            <Input 
                                onFocus={() => this.scrollToMarker(marker)}
                                value={marker.answer ? marker.answer : ''}
                                onChange={(e) => this.setMarkerAnswer(e.target.value, marker)}
                                type="text"
                            />
                        </FormGroup>
                    </div>
                )) : (
                    <p className="text-sm mb-0">This document does not have any text fields to pre-fill.</p>
                )}

            </div>

        );
    }
}

export default ModalSendIsInvalid
