/*
Documentation

obj is a contact or user
here we try to format their address in a readable way

*/

import React from 'react';

export default (obj) => {

    let string1;
    let string2

    if(obj.address_line_1) string1 = obj.address_line_1

    if(obj.address_line_2) {

        if(obj.address_line_1) {
            string1 = string1 + ' ' + obj.address_line_2
        } else {
            string1 = obj.address_line_2
        }

    }

    if(obj.city) string2 = obj.city

    if(obj.state) {

        if(obj.city) {
            string2 = string2 + ', ' + obj.state;
        } else {
            string2 = obj.state;
        }

    }

    if(obj.postal_code) {

        if(string2) {
            string2 = string2 + ' ' + obj.postal_code;
        } else {
            string2 = obj.postal_code;
        }

    }

    if(obj.country) {

        if(string2) {
            string2 = string2 + ' ' + obj.country;
        } else {
            string2 = obj.country;
        }

    }

    let markup;

    if(string1 && string2) {
        markup = (
            <span className="address">
                {string1} <br />
                {string2}
            </span>
        )
    } else if(string1) {
        markup = (
            <span className="address">
                {string1}
            </span>
        )
    } else if (string2) {
        markup = (
            <span className="address">
                {string2}
            </span>
        )
    }

    return markup

}
