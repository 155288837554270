/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { setViewingUser } from 'store/functions/auth/auth';
import { setDeviceListener } from 'store/functions/device/device';
import { initWorker } from 'utils/pdf/controller';
import { setOnlineStatus } from 'store/functions/system/system';
import stackLogger from 'database/stackLogger';
import getLogoutURL from 'utils/getLogoutURL'

import _auth from '_functions/auth'

const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);

    // gets access to internet, not fired on load
    window.addEventListener('online', () => setOnlineStatus(true));
    window.addEventListener('offline', () => setOnlineStatus(false));

    // listen for changes in screen size, set device, etc
    setDeviceListener()
    // set pdf js
    initWorker()

    let errors = 0;

    const tryLoad = async (tries = 0) => {

        errors = 0;

        //run all promises that need to fire before the app loads here
        const values = await Promise.all([
            setViewingUser(),
        ])

         // if we connected to the database but do not have a logged in user redirect
        // to the auth domain
        if(values[0].success && !values[0].data) {
            return window.location.href = getLogoutURL();
        }

        window.addEventListener('focus', async () => {
            const loggedIn = await _auth.status.isLoggedIn()
            if(loggedIn.success && !loggedIn.user) {
                return window.location.href = getLogoutURL();
            }
        })

        // make sure all required calls pass
        values.forEach(v => { if(v.success === false) { errors++; } });

        // if we dont have an error resolve success
        if(!errors) return resolve(true);
        // if we have tried to load over 3 times resolve error
        if(tries > 2) return resolve(false);

        setTimeout(() => tryLoad(tries + 1), 2000);

    }

    tryLoad();


    window.onerror = function(msg, file, line, col, e) {
        stackLogger(e);
    };

})

export default init