import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Input } from 'reactstrap';

import ModalMissingDocs from '../../../../../views/dashboard/pages/cases/View/components/Documents/modals/ModalMissingDocs';
import ImageRender from 'components/functional/images/ImageRenderer';


const StepsToPrepQuestionnaire = ({nav, foundCase, creditCounselingDoc, onDocStatusChange, trimDocName}) => {

    const [showModal, setShowModal] = useState(false)
  
    if(!creditCounselingDoc) return (
        <div className="archk-steps-to-prep-docs">
            <div className="archk-steps-to-prep-spacer mt-3">
                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Client Questionnaire</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original mr-2 " /> There is no questionnaire needed for this case..</p>
                    </CardBody>
                </Card>

                <ModalMissingDocs
                    case={foundCase}
                    missing_doc={creditCounselingDoc}
                    showModal={showModal}
                    toggleModal={() => setShowModal(null)}
                />
                
            </div>
        </div>
    )

    const hasCCC = creditCounselingDoc.status === 'n/a' || creditCounselingDoc.status === 'completed';

    return (
        <div className="archk-steps-to-prep-docs">
            <div className="archk-steps-to-prep-spacer mt-3">

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Credit Counseling Course</CardTitle>
                    </CardHeader>

                    <CardHeader>
                        <p className="text-sm">** We never want a person's ability to pay for the Credit Counseling Course to affect their ability to file. If a person is ready to go and gets all other documents on file we will pay for their course by using the following codes below. Only give this out to clients <b className="text-dark font-weight-bold text-underline">if they get all documents together within 7 days of hiring us.</b></p>
                        <p className="text-sm mb-0 font-weight-bold">Michael Jaafar: 923-115-402 </p>
                        <p className="text-sm mb-0 font-weight-bold">David Ienna: 938-115-424 </p>
                    </CardHeader>
                    <CardBody>

                        <div key={creditCounselingDoc._id} className="mb-3 border rounded p-3">
                            <div className="mb-3">
                                <i className="far fa-folder-open mr-2 " />
                                {creditCounselingDoc.status === 'completed' ? <i className="fas fa-check ml-2 text-success " /> : <i className="far fa-times-circle text-info-original " />}{' '}
                                <span className={hasCCC ? 'text-line-through' : ''}>{trimDocName(creditCounselingDoc)} </span>
                                    
                                <button className="btn btn-success btn-sm ml-2" onClick={() => setShowModal(true)}>
                                    <i className="fas fa-upload mr-2 " /> Upload
                                </button>

                                <Input
                                    className="float-right status"
                                    type="select"
                                    size="sm"
                                    style={{maxWidth: 100}}
                                    value={creditCounselingDoc.status || ''}
                                    onChange={(e) => onDocStatusChange(creditCounselingDoc, e.target.value)}
                                >
                                   <option value="missing">Missing</option>
                                    <option value="n/a">N/A</option>
                                    <option value="completed">Completed</option>
                                </Input>
                            </div>

                            {creditCounselingDoc.documents && creditCounselingDoc.documents.length ? (
                                <div className="ml--2 pl-5">
                                    {creditCounselingDoc.documents.map(d => (
                                        <div>
                                            <ImageRender 
                                                maxNameLength={20}
                                                doc={d}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="ml--2 pl-5"><small className="text-muted">No Documents Uploaded</small></div>
                            )}
                        </div>

                    </CardBody>
                </Card>

                <ModalMissingDocs
                    case={foundCase}
                    missing_doc={creditCounselingDoc}
                    showModal={showModal}
                    toggleModal={() => setShowModal(null)}
                />
                
            </div>
        </div>
    )

}
export default StepsToPrepQuestionnaire;