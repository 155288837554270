/*
Documentation

this page renders the admin view to see all case tasks within the system

*/

import { connect } from 'react-redux';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card,  CardHeader, Container, CardTitle, Row, Col } from "reactstrap";
import renderName from 'utils/renderName';
import _case_workflow_tasks from '_functions/case_workflow_tasks'
import Circle from 'components/markup/loading/Circle';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';

import moment from 'moment';

const { SearchBar } = Search;

const oneDay = 86400;
const warningInterval = 1 //number of days to show warning for

class TasksAdmin extends React.Component {

    state = {
        page: 1,
        sizePerPage: 10,
        total_documents: 0,
        data: null,
        user: {}
    }

    onSelectUser = (user) => this.setState({user}, this.queryTableData)
    clearFilters = () => this.setState({user: {}, search: null}, this.queryTableData)

    renderDueText = (task) => {

        const time_now = Math.floor(new Date() / 1000)

        let color = '';
        let overdue = false

        if(task.finished) {
            return <span className="font-weight-bold text-success">Finished</span>
        }

        if(!task.date) return 'No Due Date ';

        if(task.date < time_now) {
            color = 'text-danger text-uppercase text-undrline';
            overdue = true
        }

        if(task.date > time_now && task.date < (time_now + (oneDay * warningInterval))) {
            color = 'text-warning';
        }

        return  (
            <span className={`font-weight-bold ${color}`}>Task Due: {moment.unix(task.date).fromNow()}{overdue ? '!!!' : ''} </span>
        )

    }

    componentDidMount = async () => {

        const tasks = await _case_workflow_tasks.queryUnfinished({
            user: this.state.user._id ? this.state.user._id : undefined,
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: tasks.data, total_documents: tasks.total_documents})

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const tasks = await _case_workflow_tasks.queryUnfinished({
            user: this.state.user._id ? this.state.user._id : undefined,
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: tasks.data,
            total_documents: tasks.total_documents,
            canRun: false,
            loading: false,
            searchText
        })

    }

    lastSearch = Math.floor(new Date())
    searchInterval;

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.lastSearch = Math.floor(new Date())

            // if we are running an interval to check when to search clear the old one
            if(this.searchInterval) clearInterval(this.searchInterval)

            // set interval to see if we are typing every .5 seconds
            this.searchInterval = setInterval(() => {

                const now =  Math.floor(new Date())

                // if it has been .5 seconds query the data
                if(now >= this.lastSearch + 500) {
                    clearInterval(this.searchInterval)
                    this.queryTableData(searchText, true)
                }

            }, 500)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableData(searchText)
            })

        }

    }

    columns = [
        {
            dataField: "name",
            text: "Task Name",
            headerStyle: {width: 400},
            formatter: (cell, row) => (
                <div style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>
                    {row.case ? (
                        <Link className="text-capitalize" to={`/dashboard/cases/view/${row.case._id}`}>
                            <b> {row.name}</b> <br />
                            {row.type}
                        </Link>
                    ) : (
                        <div>
                            <b> {row.name}</b> <br />
                            {row.type}
                        </div>
                    )}
                    
                </div>
            ),
        },
        {
            dataField: "date",
            text: "Due Date",
            formatter: (cell, row) => (
                <div>
                    <div>{this.renderDueText(row)}</div>
                    <div className="text-capitalize"><b>TYPE:</b> {row.type}</div>
                </div>
            )
        },
        {
            dataField: "assigned_to",
            text: "Assigned To",
            formatter: (cell, row) => {

                if(row.assigned_to && row.assigned_to.length) return (
                    <span className="text-capitalize">{row.assigned_to.map((user, i) => i > 0 ? ', ' + renderName(user) : renderName(user))}</span>
                )

                return '-'
            }

        },
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                row.case ? (
                    <Link className="text-capitalize" to={`/dashboard/contacts/view/${row.case._id}`}>
                        <div>{row.case.name}</div>
                        <div className="text-capitalize"><b>Step:</b> {row.case_workflow_step_name}</div>
                    </Link>
                ) : 'Not Found'

            ),
        },

        {
            dataField: "created_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={row.case ? `/dashboard/cases/view/${row.case._id}?nav=2` : `/dashboard/cases/all`}>
                            <i className="fas fa-edit text-success mr-2"></i>
                        </Link>
                    </div>
                )
            }
        },

    ]


    render() {

        let { data, loading, user} = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`All Open Tasks (${this.state.total_documents}) `}</title>
                    <meta name="description" content="All Open Tasks" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>All Open Tasks</span>}
                    breadcrumb_1="All"
                    actionComponent={(
                        <Link to="/dashboard/tasks/user" className="btn btn-success">My Tasks</Link>
                    )}

                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>
                            <Col xs={6} className="align-self-center">
                                <CardTitle className="mb-0 float-left">All Open Tasks</CardTitle>
                            </Col>
                            <Col xs={6} className="text-right align-self-center">
                                <button onClick={this.clearFilters} className="btn btn-outline-warning">Clear Filters</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    {data ? (

                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >

                                            <Row>

                                                <Col lg={6}>
                                                    <label>
                                                        <span style={{display: 'inline-block', width: 110}}>Search Value:</span>
                                                        <SearchBar
                                                            style={{width: 325}}
                                                            className="form-control"
                                                            placeholder="Task Name, Step Name, Case ID, Contact ID"
                                                            {...props.searchProps}
                                                        />
                                                        {loading ? (
                                                        <Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} />
                                                        ) : null}
                                                    </label>
                                                </Col>

                                                <Col lg={6} >
                                                    <label>
                                                        <span style={{display: 'inline-block', width: 110}}>Filter By User:</span>
                                                        <UserSearchInput
                                                            wrapperStyles={{display: 'inline', textAlign: 'left'}}
                                                            formGroupStyles={{display: 'inline-block', width: 325}}
                                                            inputStyles={{display: 'inline-block', width: 325}}
                                                            hideTitle={true}
                                                            onSelect={this.onSelectUser}
                                                            value={user}
                                                        />
                                                        {loading ? (
                                                        <Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} />
                                                        ) : null}
                                                    </label>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="table-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>

                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(TasksAdmin);
