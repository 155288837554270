/*

pops up when a user clicks on an appointment assigned to them
in the left hand sidebar of the call center

*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Modal, Row } from "reactstrap";
import moment from 'moment';
import renderName from 'utils/renderName';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import reactHTMLParser from 'react-html-parser'
import Circle from 'components/markup/loading/Circle';
import { connect } from 'react-redux';
import { formatPhone } from 'utils/text'
import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import keys from 'keys';

const DescriptionValue = (props) => (
    <Row>
        <Col md="5"><p className="text-sm font-weight-bold mb-0">{props.description}</p></Col>
        <Col md="7"><p className="text-sm mb-0">{props.value}</p></Col>
    </Row>
)

class ModalStartAppointment extends Component {

    state = {
        showAppointmentsCrud: false
    };

    // close the appointment modal and close the start appointment modal after
    onAppointmentSave = () => {

        this.setState({showAppointmentsCrud: false}, () => {
            this.props.toggleModalStart()
        });

    }

    // returns markup for a contact_note
    renderNote = (note, i) => {

        const time = moment.unix(note.created_at);

        let hr =  <hr className="my-3" />
        if (this.props.contact_notes.length -1 === i) hr = null

        return (
            <div key={note._id}>
                 <p className="text-sm mb-0 font-weight-bold text-capitalize">
                    {time.format('M/D/YYYY')} {' at '} {time.format('h:mm A') } {' '}
                    by {renderName(note.user)}
                </p>

                <p className="text-sm mb-0">{reactHTMLParser(note.value)}</p>
                {hr}
            </div>
        )

    }

    startAppointment = async  (appointment) => {

        const inPerson = appointment.in_person === 'true' || appointment.in_person === true ? true : false;
        const event = inPerson ? ANALYTIC_EVENTS.DIAL_IN_PERSON : ANALYTIC_EVENTS.DIAL_MANUAL;

        this.props.setOnCall({ user: this.props.viewing_user._id, appointment: appointment._id }, event)
        this.props.toggleModalStart();
    }

    render() {

        const { showAppointmentsCrud } = this.state;
        const { showModalStart, toggleModalStart, contact_notes, canTakeCalls } = this.props
        let { selected_appointment } = this.props

        selected_appointment = selected_appointment ? selected_appointment : {}
        const contact =  selected_appointment.contact ? selected_appointment.contact :  {}

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalStart}
                toggle={toggleModalStart}
                size="lg"
            >
                <div className="modal-header">
                    <CardTitle className="mb-0 text-capitalize">{selected_appointment.name}</CardTitle>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalStart}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body pt-3">

                    <Row>

                        <Col lg={6}>

                            <Card className="card-color card-success mb-0">

                                <CardHeader>
                                    <CardTitle className="mb-0">Appointment Information</CardTitle>
                                </CardHeader>

                                <CardBody>

                                    <DescriptionValue
                                        description={(<><i className="fas fa-calendar-check mr-2" />Appointment Type:</>)}
                                        value={selected_appointment.type ? selected_appointment.type.toUpperCase() : 'Not Found'}
                                    />

                                    <hr className="my-3" />

                                    <DescriptionValue
                                        description={(<><i className="fas fa-phone mr-2" /> Client name: </>)}
                                        value={renderName(contact) || 'Not Found'}
                                    />
                                    <DescriptionValue
                                        description={(<><i className="fas fa-phone mr-2" /> Client Phone: </>)}
                                        value={formatPhone(contact.phone) || 'Not Found'}
                                    />

                                    <DescriptionValue
                                        description={(<><i className="fas fa-envelope mr-2" /> Client Email: </>)}
                                        value={contact.email || 'Not Found'}
                                    />

                                    <hr className="my-3" />

                                    {selected_appointment.assigned_to && selected_appointment.assigned_to.length ? (
                                    <Row>
                                        <Col md="5">
                                            <p className="text-sm font-weight-bold mb-0">
                                                <i className="fas fa-users mr-2" /> Assigned To:
                                            </p>
                                        </Col>
                                        <Col md="7">
                                            <p className="text-sm mb-0 text-capitalize">
                                            { selected_appointment.assigned_to.map((user, i) => (
                                                i === 0 ? renderName(user) : ', ' + renderName(user)
                                            ))}
                                            </p>
                                        </Col>
                                    </Row>
                                    ) : (
                                        <DescriptionValue
                                            description={(<><i className="fas fa-users mr-2" /> Assigned To: </>)}
                                            value={'No One'}
                                        />
                                    )}


                                    <hr className="my-3" />

                                    <DescriptionValue
                                        description={(<><i className="fas fa-clock mr-2" /> Date:</>)}
                                        value={"12/12/2020 from 9:00 - 12:00 AM"}
                                    />
                                    <DescriptionValue
                                        description={(<><i className="fas fa-calendar mr-2" /> Last Contact:</>)}
                                        value={contact.last_contact ? moment.unix(contact.last_contact).format('MMM Do, YYYY - h:mm A') : '-'}
                                    />
                                    <DescriptionValue
                                        description={(<><i className="fas fa-user mr-2" />Last Attempt By:</>)}
                                        value={contact.last_contact_by ? renderName(contact.last_contact_by) : <b className="text-success">NEVER CONTACTED BEFORE</b>}
                                    />

                                </CardBody>
                            </Card>

                        </Col>

                        <Col lg={6}>

                            <Card className="card-color card-success mb-0">

                                <CardHeader>
                                    <CardTitle className="mb-0">Contact Notes</CardTitle>
                                </CardHeader>

                                <CardBody>

                                    <div className="pr-2" style={{maxHeight: 275, overflow: 'auto'}}>

                                        {contact_notes ? (
                                            contact_notes.length ? (
                                                contact_notes.map((note, i) => this.renderNote(note, i))
                                            ) : (
                                                <p className="text-sm mb-0 font-weight-bold">No Contact Notes Found</p>
                                            )
                                        ) : (
                                            <Circle />
                                        )}

                                    </div>
                                </CardBody>
                            </Card>

                        </Col>

                    </Row>

                </div>

                <div className="modal-footer">

                    <button onClick={() => this.setState({showAppointmentsCrud: true})} className="btn btn-warning">
                        Update Appointment
                    </button>

                    <a 
                        disabled={!canTakeCalls && !selected_appointment.in_person} 
                        // onClick={() => this.startAppointment(selected_appointment)} 
                        className="btn btn-success"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${keys.TALK_URL}?call_id=${selected_appointment.contact && selected_appointment.contact._id ? selected_appointment.contact._id : selected_appointment.contact}`}
                    >
                        Start Appointment
                    </a>

                </div>

                {showAppointmentsCrud ? (
                    <AppointmentsCrud
                        toggleAppointmentsCrud={() => this.setState({showAppointmentsCrud: false})}
                        appointment_id={selected_appointment._id}
                        title="Update Appointment"
                        showAppointmentsCrud={showAppointmentsCrud}
                        onSuccess={this.onAppointmentSave}
                        isModal={true}
                    />
                ) : null}

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ModalStartAppointment.propTypes = {
    setOnCall               : PropTypes.func.isRequired,
    toggleModalStart        : PropTypes.func.isRequired,
    contact_notes           : PropTypes.array,
    selected_appointment    : PropTypes.object,
}
export default connect(mapStateToProps, '')(ModalStartAppointment);
