import React from 'react';
import { connect } from 'react-redux';

import KeyPad from './KeyPad';
import ContactSearch from './ContactSearch';
class CallCenterUsers extends React.Component {

    state = {
        onCall: false,
        view: 'search',
    }

    setOnCall = (onCall) => this.setState({onCall});
    setView = (view) => this.setState({view});


    componentDidMount = async () => {

      
    }

    render() {

        const { view } = this.state;
        const { setOnCall } = this.props

        return (
            <div className="call-center-mobile">
                {view === 'keypad' ? (
                    <KeyPad 
                        setView={this.setView}
                        view={view}
                        setOnCall={setOnCall}
                    />
                ) : view === 'search' ? (
                    <ContactSearch 
                        setView={this.setView}
                        view={view}
                        setOnCall={setOnCall}
                    />
                ) : view === 'on call' ? (
                    <KeyPad />
                ) : null}

            </div>
        )

     
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        device: state.device,
        browser_dialing_status: state.system.browser_dialing_status,
    };
};

export default connect(mapStateToProps, '')(CallCenterUsers);
