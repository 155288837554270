/*
Documentation

Renders the keypad a user can control on a cal

*/

import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { Device } from 'twilio-client';

class KeypadOnCall extends Component {

    state = {
        number: ''
    };

    // adds number by button click
    addNumber = async (number) => {

        const activeConnection = Device.activeConnection()

        if(activeConnection) {
            
            activeConnection.sendDigits(number);
            
            let newNumber = this.state.number ? this.state.number + number : number
            this.setState({number: newNumber})

        }

    }

    render() {

        const { number } = this.state
        const { toggleKeypad, showKeypad} = this.props

        if(!showKeypad) return <></>

        return (

            <div className="keypad">

                <div className="keypad-background" onClick={toggleKeypad} />

                <div className="keypad-content rounded">

                    {number ? (
                        <div className="header">{number}</div>
                    ) : null}

                    <span className="block first" onClick={() => this.addNumber('1')}>
                        1 <span className="letters placeholder">-</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('2')}>
                        2 <span className="letters">ABC</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('3')}>
                        3 <span className="letters">DEF</span>
                    </span>

                    <span className="block first" onClick={() => this.addNumber('4')}>
                        4 <span className="letters">GHI</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('5')}>
                        5 <span className="letters">JKL</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('6')}>
                        6 <span className="letters">MNO</span>
                    </span>

                    <span className="block first" onClick={() => this.addNumber('7')}>
                        7 <span className="letters">PQRS</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('8')}>
                        8 <span className="letters">TUV</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('9')}>
                        9 <span className="letters">WXYZ</span>
                    </span>

                    <span className="block first last" onClick={() => this.addNumber('*')}>
                        *
                    </span>

                    <span className="block second last" onClick={() => this.addNumber('0')}>
                        0 <span className="letters last">+</span>
                    </span>

                    <span className="block third last" onClick={() => this.addNumber('#')}>
                        #
                    </span>

                </div>
            </div>

        )

    }

}

KeypadOnCall.propTypes = {
    showKeypad:      PropTypes.bool.isRequired,
    toggleKeypad:   PropTypes.func.isRequired
}

export default KeypadOnCall
