import PropTypes from 'prop-types'
import React, { useState } from "react";
import { Modal } from "reactstrap";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system'

import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import _contacts from '_functions/contacts';

const MergeModal = ({showModal, toggleModal, fromContact, setData}) => {

    const [contact, setContact] = useState({});

    const onMerge = async () => {

        if(!contact._id) return;

        toggleStandardLoader(true);
        const merged = await _contacts.mergeFile({from_contact: fromContact._id, to_contact: contact._id});
        toggleStandardLoader(false);

        if(merged.success) {
            toggleAlertBS(false, `File Merges Successfully`);
            toggleModal();
            setData();
        } else {
            toggleAlertBS(true, `Something Went Wrong`);
        }

    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Merge Contact</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <p className="text-sm">
                    Merging a contact will move over all information and contact notes from this contact into the selected contacts file below.{' '}
                    <b>Note that this cannot be undone.</b>
                </p>

                <ContactSearchInput
                    value={contact}
                    onSelect={(contact) => setContact(contact)}
                />

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onMerge}>
                    <i className="fas fa-save mr-2" />
                    Merge
                </button>
            </div>

        </Modal>
    )
}

MergeModal.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default MergeModal