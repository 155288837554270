/*
Documentation

this file is responsible for rendering each email preview in the left hand column

*/

import moment from 'moment';
import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';
import { stripHTML } from 'utils/code';
import renderName from 'utils/renderName';

import Circle from 'components/markup/loading/Circle';

import PropTypes from 'prop-types';

class RenderEmail extends Component {

    state = {
        startOfDay: moment.unix(Math.floor(new Date() / 1000)).startOf('day').valueOf() / 1000,
    }

    // if email is from today we only show the tie, else we show the date
    renderDate = (date) =>  moment.unix(date).format(date >= this.state.startOfDay ? 'h:mm A' : 'MM/DD/YYYY')

    render() {

        const { emails, setContent, loading, activeEmail, showing } = this.props

        return (

            <ul className="pl-0 mb-0">
                {emails ? emails.length ? emails.map(email => (
                    <li
                        onClick={() => setContent({...email, microsoft_message_id: showing === 'sent' ? undefined : email.microsoft_message_id}, 'email')}
                        key={email._id + email.microsoft_message_id}
                        className={
                            email._id === activeEmail ? 'list-style-none cursor-pointer bg-warning text-white' :
                            email.read ? 'list-style-none cursor-pointer bg-secondary text-muted' :
                            'list-style-none cursor-pointer'
                        }
                    >

                        <div className="from">
                            <Row>
                                <Col lg={7}>
                                {email.contact && email.contact._id ?
                                    <span className="text-capitalize">
                                        {renderName(email.contact).length > 15 ? renderName(email.contact).slice(0, 15) + '...' : renderName(email.contact)}

                                    </span> :
                                    email.from && email.from.length > 15 ? email.from.slice(0, 15) + '...' : email.from

                                }
                                </Col>
                                <Col lg={5} className="text-right date">
                                    {this.renderDate(email.created_at)}
                                </Col>
                            </Row>
                        </div>

                        <div className="subject">
                            <i className={
                                email._id === activeEmail ? 'fas fa-envelope mr-2 font-weight-bold text-white' :
                                email.read ? 'fas fa-envelope mr-2 font-weight-bold' :
                                'fas fa-envelope mr-2 text-success font-weight-bold'
                            }
                            />
                            {stripHTML((email.subject && email.subject.length > 25 ? email.subject.slice(0, 25) + '...' : email.value).replace(/<br \/>/g, ' '))}
                        </div>

                        <div className="body pt-2">
                            {stripHTML((email.value && email.value.length > 50 ? email.value.slice(0, 50) + '...' : email.value).replace(/<br \/>/g, ' '))}
                        </div>

                    </li>
                )) : (
                    <li><i className="fas fa-check mr-2 text-success" />There are no emails to view with the current filters.</li>
                ) : null}

                {loading ? <Circle />  : null}
            </ul>

        )

    }

}

RenderEmail.propTypes = {
    setContent    : PropTypes.func.isRequired,
    showing       : PropTypes.string.isRequired,
    emails        : PropTypes.array,
    loading       : PropTypes.bool,
    activeEmail   : PropTypes.string,
}

export default RenderEmail
