/*
Documentation

This page component is a wrapper around AppointmentsCrud
In short it renders a full dashboard page on which to view an appointment.

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import AppointmentsCrud from './components/AppointmentsCrud';

class AppointmentsView extends React.Component {

    state = {}

    onSuccess = (data) => {

        toggleAlertBS(false, 'Appointment Saved Successfully')
        this.setState({shouldRedirect: '/dashboard/appointments/all'})

    }

    onError = (err) => {

        console.log(err)

    }

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Appointments`}</title>
                    <meta name="description" content="Appointment Templates" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Appointments</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link  to="/dashboard/appointments/all" className="btn btn-success">
                            <i className="fas fa-arrow-left mr-2" /> All Appointments
                        </Link>
                    )}
                />

                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">
                            {this.props.match.params._id !== 'new' ? 'Update Appointment' : 'Create An Appointment'}
                        </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <AppointmentsCrud
                            appointment_id={this.props.match.params._id}
                            onSuccess={this.onSuccess}
                            onError={this.onError}
                            showLoader={true}
                        />
                    </CardBody>

                </Card>

            </Container>

            </>
        );
    }
}

export default AppointmentsView
