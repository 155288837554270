/*
Documentation

This renders form inputs for a task with the type "set assigned user"

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _case_workflow_tasks from '_functions/case_workflow_tasks'
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import { FormGroup } from 'reactstrap';

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const required_form_fields = [
    'value',
]

class FormTypesSetCaseRole extends Component {

    state = {
        editing_task: {},
    };

    onFinishTask = async () => {

        let editing_task = Object.assign({}, this.state.editing_task);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(editing_task[field]) {
                editing_task[field + "State"] = 'valid';
            } else {
                editing_task[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ editing_task });

        // do nothing iff we dont have a selected user
        if(!editing_task.value) return
        if(!editing_task.value._id) return

        if(!errors) {

            toggleStandardLoader(true)

            await _case_workflow_tasks.finish(this.state.editing_task._id,  {
                value: editing_task.value._id,
                minutes: 0,
                user: this.props.viewing_user._id,
                case_workflow: this.props.case_workflow_id,
            })

            toggleStandardLoader(false)

            this.props.toggleModal();
            this.props.forceRefresh();
            _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

        }

    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes,
                value: this.state.editing_task.value ? this.state.editing_task.value : null
            })

            this.setState({editing_task: task})

        }
    }

    render() {

        const editing_task = this.state.editing_task || {};

        if(editing_task.type !== "set assigned user") { return <></> }

        return (

            <>
                <p className="mb-0 text-sm font-weight-bold">Assign User To Case Contact</p>
                <hr className="my-3" />

                <FormGroup className="">
                    <UserSearchInput
                        value={editing_task.value ? editing_task.value : {}}
                        onSelect={(user) => this.setState({ editing_task: {...Object.assign({}, this.state.editing_task), value: user} })}
                    />
                </FormGroup>

                <div className="text-right">

                    <hr className="my-4" />

                    <button
                        disabled={editing_task.value && editing_task.value._id ? false : true}
                        onClick={() => this.onFinishTask()}
                        className="btn btn-success"
                    >
                        Finish Task
                    </button>
                </div>


            </>

        )

    }

}

FormTypesSetCaseRole.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesSetCaseRole);
