/*
Documentation

Renders the single contact view of the billing center
this file is what will make a contact a contact in fortispay if they are not already one

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import CaseCards from './CaseCards';
import CaseView from './CaseView';
import ContactCard from './ContactCard';
import Navigation from './Navigation';
import PaymentMethods from './PaymentMethods';
import PaymentLogs from './PaymentLogs';
import EventLogs from './EventLogs';
import Circle from 'components/markup/loading/Circle';
import _cases from '_functions/cases';
import { getUrlParameter, setUrlParameter } from 'utils/urls'

import _payments from '_functions/payments';

import { Card, CardHeader, CardTitle } from 'reactstrap';

const PaymentErrorMarkup = ({contact, emailError}) =>  (
    <Card className="bg-warning" style={{maxWidth: 600}}>
        <CardHeader className="bg-transparent">
            <CardTitle className="mb-0 text-white">Information Needed</CardTitle>
        </CardHeader>
        <CardHeader className="bg-transparent">
            <p className="text-sm text-white mb-0"> To update or view contacts billing information the contact must have both a first and last name as well as a valid email address associated with their profile.</p>
        </CardHeader>
        {emailError ? (
             <CardHeader className="bg-transparent">
             <p className="text-sm text-white mb-0 font-weight-bold text-underline"> Please Check The Email Address On The Contact, The One Found Is Not Valid.</p>
         </CardHeader>

        ) : null}
        <CardHeader className="bg-transparent">
            <p className="text-sm mb-0 text-white"><b className="font-weight-bold">First Name:</b> {contact.given_name ? contact.given_name : '-'}</p>
            <p className="text-sm mb-0 text-white"><b className="font-weight-bold">Last Name:</b> {contact.family_name ? contact.family_name : '-' }</p>
            <p className="text-sm mb-0 text-white"><b className="font-weight-bold">Email:</b> {contact.email ? contact.email : '-' }</p>
        </CardHeader>
    </Card>
)

class ViewContact extends Component {

    state = {
        showCase: null,
        navPills: 1,
        payformUrl: null,
        account_vaults: null,
        payments: null,
        paymentError: false,
        emailError: false,
        total_paid: 0,
    };

    // grab all cases for the client we are viewing
    setClientCases = async () => {

        const cases = await _cases.find(`?filter=contact__${this.props.contact._id}|deleted__false{bool}`)
        if(cases.success) this.setState({cases: cases.data})

    }

    //when a case is selected set it to show and change the contents rendered
    // also set url to remember the case value if page is refreshed
    onSelectCase = (_case) => {
        this.setState({showCase: _case})
        setUrlParameter('bccc', _case._id)
    }

    // if a payment was captured there will be two payments with the same _ID
    // filter out any with status of authorized and leave the captured payment if exists
    filterCapturedPayments = (payments) => {
        let filteredPayments = payments.filter(p => {
            if(p.status !== 'authorized') return true;
            const foundCaptured = payments.find(p2 => p2.fortispay_id === p.fortispay_id && p2.status !== 'authorized');
            if(foundCaptured) return false;
            return true;
        })
        return filteredPayments;
    }

    //find all payments that occurred for this contact
    findPaymentsByContact = async () => {

        const payments = await _payments.findByContact(this.props.contact._id);

        if(payments.success) {
            const allPayments = this.filterCapturedPayments(payments.data.payments)
            this.setState({ payments: allPayments, total_paid: payments.data.total_paid })
        }

    }

    //get the payform url via fortispay
    getPayForm = async (contact_id) => {

        const payform = await _payments.createPayform(contact_id)
        if(payform.success) this.setState({payformUrl: payform.data})

    }

    //this function finds all payment methods associated with the contact, will create contact if they don't exist
    getPaymentMethods = async  () => {

        const contact = this.props.contact;

        const fortisContactExists = await _payments.contacts.findOrCreate(contact)

        if(fortisContactExists.success) {

            this.setState({account_vaults: fortisContactExists.data.account_vaults})
            this.getPayForm(contact._id);

        } else {

            try {
                if(fortisContactExists.message.errors.email[0] === 'Email is not a valid email address.') {
                    this.setState({emailError: true, paymentError: true})
                }
            } catch(e) {
                this.setState({paymentError: true})
            }


        }

    }

    // if a case is updated and we are viewing the case update the case
    // to reflect new values
    listenForCasesUpdated = (data) => {

        if(this.state.showCase && data.data._id === this.state.showCase._id) {
            this.setState({showCase: data.data})
        }

    }

    listenForPaymentsAdded = (data) => {

        if(data.data.contact === this.props.contact._id) {

            let payments = this.state.payments

            payments = payments && payments.length ? [...payments] : [];
            payments.unshift(data.data)

            payments = this.filterCapturedPayments(payments)

            this.setState({payments})

        }

    }

    listenForPaymentsUpdated = (data) => {

        if(data.data.contact === this.props.contact._id) {

            let payments = this.state.payments

            payments = payments && payments.length ? [...payments] : [];
            payments[payments.findIndex(el => el._id === data.data._id)] = data.data

            this.setState({payments})

        }
    }

    componentWillReceiveProps = (nextProps) => {

        //if a new contact is passed render data for them
        if(this.props.contact._id !== nextProps.contact._id) this.getPayForm(nextProps.contact._id);
        //update the case shown if we have new case props
       if(this.state.showCase) {

            let updatedShowCase = nextProps.cases.find(c => c._id === this.state.showCase._id)
            if(updatedShowCase) this.setState({showCase: updatedShowCase})

       }

    }

    componentWillUnmount = () => {

        io.off(socketEvents.payments_added, this.listenForPaymentsAdded)
        io.off(socketEvents.payments_updated, this.listenForPaymentsUpdated)
        io.off(socketEvents.cases_updated, this.listenForCasesUpdated)

        setUrlParameter('bccc', 'na')


    }

    componentDidMount = async () => {

        this.findPaymentsByContact();
        this.getPaymentMethods();
        this.setClientCases()

        //set the listener to listen for new payments
        io.on(socketEvents.payments_added, this.listenForPaymentsAdded)
        io.on(socketEvents.payments_updated, this.listenForPaymentsUpdated)
        io.on(socketEvents.cases_updated, this.listenForCasesUpdated)


        // billing center case, if this is true we want to load the page with the 
        // pre filled case set by the url
        const case_id = getUrlParameter('bccc')

        if(case_id && case_id.length === 24 && !this.state.contact) {

            this.getPaymentMethods()
                
            const _case = await _cases.findById(case_id)
            if(_case.data) this.onSelectCase(_case.data)

        }

    }

    render() {

        const contact = this.props.contact
        const { navPills, showCase, payformUrl, account_vaults, payments, paymentError, emailError, cases, total_paid } = this.state

        return (

            <>

                <Navigation
                    navPills={navPills}
                    setNavPills={(val) => {
                        this.setState({navPills: val, showCase: val === 1 ? null: this.state.showCase})}
                    }
                />

                <ContactCard
                    cases={cases}
                    contact={contact}
                    onToggleBillingCenter={this.props.onToggleBillingCenter}

                />

                {navPills === 1 && (

                    paymentError ? (
                        <PaymentErrorMarkup contact={contact} emailError={emailError} />
                    ) : (
                        showCase ? (
                            <CaseView
                                case={showCase}
                                contact={contact}
                                showAllCases={() => this.setState({showCase: null})}
                                account_vaults={account_vaults}
                                onToggleBillingCenter={this.props.onToggleBillingCenter}
                                onRecurringCreation={(x) => this.getPaymentMethods()}
                                onRecurringDeleted={(x) => this.getPaymentMethods()}
                            />
                        ) : (
                            cases ? (
                                <CaseCards
                                    onSelectCase={(_case) => this.onSelectCase(_case)}
                                    cases={cases}
                                    contact={contact}
                                />
                            ) : <Circle />
                        )
                    )

                )}

                {navPills === 2 && (
                    payformUrl ? (
                        <PaymentMethods
                            payformUrl={payformUrl}
                            account_vaults={account_vaults}
                            contact={contact}
                            getPaymentMethods={this.getPaymentMethods}
                            paymentError={paymentError}
                        />
                    ) : (
                        paymentError ? <PaymentErrorMarkup contact={contact} emailError={emailError}  />  : <Circle />

                    )
                )}

                {navPills === 3 && (
                    <PaymentLogs
                        contact={contact}
                        payments={payments}
                        total_paid={total_paid}
                    />
                )}

                {navPills === 4 && (
                    <EventLogs
                        contact={contact}
                    />
                )}

            </>

        )

    }

}

ViewContact.propTypes = {
    contact: PropTypes.object.isRequired,
}

export default ViewContact
