import * as actionTypes from 'store/actions';
import store from 'store';
import { isMobile } from 'utils/screen'

export const setDeviceListener = async() => {

    setDevice()

    window.addEventListener('resize', setDevice)

}

const setDevice = () => {

    store.dispatch({
        type: actionTypes.SET_DEVICE,
        payload: {
            device                : navigator.userAgent,
            isMobile              : isMobile(),
            currentScreenWidth    : window.innerWidth,
            currentScreenHeight   : window.innerHeight,
        }
    });

}
