import React, { useState, useEffect } from 'react';

const CountUp = ({timestamp}) => {

    const [now, setNow] = useState(Math.floor(new Date() / 1000))

    const  secondsToTime = (e) => {
        var h = Math.floor(e / 3600).toString().padStart(2,'0'),
            m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
            s = Math.floor(e % 60).toString().padStart(2,'0');
        return `${h}:${m}:${s}`;
    }
    const time = now - timestamp;

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Math.floor(new Date() / 1000))
        }, [1000])

        return () => {
            clearInterval(interval);
        }
    }, [timestamp])

    return secondsToTime(time);
}

export default CountUp;