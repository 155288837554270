/*
Documentation

this page renders the master calendar

blue underline means date is future and it has events /tasks
red underline means a task is overdue on this day or time

*/


import Calendar from 'components/system/Calendar';
import moment from 'moment';
import React from "react";
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import renderName from 'utils/renderName';
import { getUrlParameter } from 'utils/urls';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import _calendar from '_functions/calendar';
import { Card, CardBody } from 'reactstrap';
import * as footer from 'utils/footer'
import * as privileges from '_functions/users/privileges'
import PropTypes from 'prop-types'

class ContactAppointments extends React.Component {

    state = {
        data: [],
        showCalendars: [this.props.viewing_user._id],
        showCalendarsError: false,
        loading: false,
        appointment_id: 'new',
        startDate: null,
        queryFinished: false
    }

    setURLParams = () => {

        const { showCalendars } = this.state;

        let string = null

        if(showCalendars.length) {

            showCalendars.forEach(user => {

                string = string ? string + ' ' + user : user

            })

        }

        const view = getUrlParameter('view')
        const time = getUrlParameter('time')

        window.history.replaceState(null, null, `${window.location.pathname}?view=${view}&time=${time}&users=${string}`);

    }

    // this function is a custom renderer for the month cell blcok
    renderViewMonth = (appointments) => {

        if(!appointments && appointments.length) { return null }

        return (
            <React.Fragment>
                <span className="full">
                    <span className={'index index-important bg-success'}>
                        <i className={"fas fa-calendar mr-1 "} />
                        {appointments.length}
                    </span>
                </span>

                <span className="circle">
                    <i className={"fas fa-calendar mr-2 "} />
                </span>
            </React.Fragment>
        )

    }

    renderSidebarAdditions = () => {

        return (
            <div className="">
                   <FormGroup className="ml-4 pl-3 mt-3">
                    <Input
                        // disabled={loading}
                        type="checkbox"
                        id={`calendar-expand-data`}
                        checked={this.state.queryFinished}
                        onChange={() => this.setState({queryFinished: !this.state.queryFinished}, this.queryCalendarData)}
                    />

                    <label className="d-block form-control-label" htmlFor={`calendar-expand-data`}>
                        <b className="px-2 py-1  d-block text-dark" >Show Finished Appointments</b>
                    </label>
                </FormGroup>
            </div>
        )

    }

    onCalendarChange = async (user_id) => {

        this.setState({loading: true})

        let showCalendars = [...this.state.showCalendars];

        if(showCalendars.includes(user_id)) {
            showCalendars = showCalendars.filter(_id => _id !== user_id)
        } else {
            showCalendars.push(user_id);
        }

        if(showCalendars.length) {
            await this.queryCalendarData(showCalendars)
            this.setState({showCalendars, loading: false}, this.setURLParams)

        } else {
            this.setState({data: [], showCalendars: [], loading: false}, this.setURLParams)
        }

    }

    renderDayEvent = (event, height) => {

        return (
            <span className="hover-popup" style={{display: 'inline-block', height: '100%', width: '100%'}}>
                <span style={{height: height, display: 'inline-block', overflow: 'hidden'}}>{event.name}</span>
                <span  className="pop text-dark">
                    <b>{event.name}</b>
                    <hr className="my-2" />
                    {event.description}
                    {event.assigned_to && event.assigned_to.length ? (
                        <>
                            <hr className="my-2" />
                            <b>Assigned To:{' '}</b>
                            {event.assigned_to.map((user, i) => i === 0 ? renderName(user) : ', ' + renderName(user))}
                        </>
                    ) : null}
                    <hr className="my-2" />
                    <span className="">From: {moment.unix(event.date).format('h:mm A')} -  {moment.unix(event.date_end).format('h:mm A')}</span>
                </span>
            </span>
        )

    }

    queryCalendarData = async (user_ids = this.state.showCalendars) => {
        return new Promise (async resolve => {

            let string;

            user_ids.forEach(_id => {

                string = string ? string + ` ${_id}` : _id

            })

            let request = await _calendar.query.byContacts(this.props.contact._id, '?queryFinished=true')

            if(request.success) {
                this.setState({data: request.data ? request.data : [], loaded: true})
            }

            resolve()

        })
    }

    setUsersFromParams = () => {

        const users = getUrlParameter('users')

        if(users && users !== 'null') {

            const showCalendars = users.split(' ')
            let errors = 0

            showCalendars.forEach(_id => {

                if (!_id.match(/^[0-9a-fA-F]{24}$/)) {
                    errors++
                }

            })

            if(!errors) {
                this.queryCalendarData(showCalendars)
                this.setState({showCalendars})
            } else {
                this.queryCalendarData([this.props.viewing_user._id])
            }

        } else {

            this.queryCalendarData([this.props.viewing_user._id])

        }

    }

    onSuccess = () => {

        this.queryCalendarData()

        if(this.state.appointment_id) {
            toggleAlertBS(false, 'Appointment Changed')
        } else {
            toggleAlertBS(false, 'Appointment Created Successfully')
        }

        this.toggleAppointmentsCrud()

    }

    toggleAppointmentsCrud = (values) => {

        const appointment_id          = values && values.event ? values.event._id : 'new';
        const startDate               = values && values.start_time_unix ? parseInt(values.start_time_unix) : null
        const showAppointmentsCrud    = this.state.showAppointmentsCrud

        this.setState({
            showAppointmentsCrud: !showAppointmentsCrud,
            appointment_id,
            startDate
        })

    }

    componentDidMount = async () => {

        this.setUsersFromParams()

        footer.hide()
        document.body.style.minHeight = 0

        // store document body color
        var bodyBackground = window.getComputedStyle ? window.getComputedStyle(document.body, null).getPropertyValue("background-color") : document.body.style.backgroundColor;
        this.setState({bodyBackground})

        document.body.style.background = 'white'

    }

    componentWillUnmount = () => {

        footer.show()

        document.body.style.minHeight = '100vh'
        document.body.style.background = `${this.state.bodyBackground}`

    }

    render() {

        const { data, showAppointmentsCrud, appointment_id, startDate } = this.state
        const viewing_user = this.props.viewing_user

        const { navPills } = this.props

        if(navPills !== 2) return null

        return (

           <Card>

                <CardBody className="p-0">
                    <Calendar
                        data                      = {data}
                        onCalendarChange          = {this.onCalendarChange}
                        calendarName              = {viewing_user.given_name + ' ' + viewing_user.family_name}

                        renderViewMonth           = {this.renderViewMonth}
                        renderDayEvent            = {(event, height) => this.renderDayEvent(event, height)}

                        onRefresh                 = {() => this.queryCalendarData() }

                        // onDateChange                ={(values) => console.log(values)}

                        onDayEventClick           = {(values) => this.toggleAppointmentsCrud(values)}
                        onDayCellClick            = {(values) => this.toggleAppointmentsCrud(values)}


                        onViewWeekCellClick       = "show_day"

                        onViewMonthCellClick      = "show_day"
                    />

                    {showAppointmentsCrud && privileges.canModerateAppointments() ? (
                        <AppointmentsCrud
                            toggleAppointmentsCrud={this.toggleAppointmentsCrud}
                            showAppointmentsCrud={showAppointmentsCrud}
                            appointment_id={appointment_id}
                            title={appointment_id !== 'new' ? "Update Appointment" : "Create Appointment"}
                            onSuccess={this.onSuccess}
                            isModal={true}
                            startDate={startDate}
                        />
                    ) : null }

                </CardBody>

           </Card>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
    };
};

ContactAppointments.propTypes = {
    navPills    : PropTypes.number.isRequired,
    contact     : PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(ContactAppointments);
