/*
Documentation

This page shows all unread texts in the contact center
We may need to come up with a better way than querying the db every time
a socket fires but for now until we know what the production load
will be we don't need to overdevelop

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import _contact_texts from '_functions/contact_texts';

import Circle from 'components/markup/loading/Circle'

class CallCenterAdminUnreadTexts extends React.Component {

    state = {
        textsCount: null
    }

    listenForContactTextsUpdated = () => this.queryTextCount()
    listenForContactTextsAdded   = () => this.queryTextCount()
    listenForContactTextsAdded   = () => this.queryTextCount()

    // here we make a call to find unread contact texts but limit
    // the results to 1 document. this will return the total_documents
    // property which is all we need
    queryTextCount = async () => {

        const texts = await _contact_texts.find(`?filter=read__false{bool}&limit=1`)
        if(texts.success) this.setState({textsCount: texts.total_documents})

    }

    componentDidMount = async () => {

        this.queryTextCount()

        // io.on(socketEvents.contact_texts_updated,    this.listenForContactTextsUpdated)
        // io.on(socketEvents.contact_texts_deleted,    this.listenForContactTextsAdded)
        // io.on(socketEvents.contact_texts_added,      this.listenForContactTextsAdded)

    }

    componentWillUnmount = () => {

        // io.off(socketEvents.contact_texts_updated,    this.listenForContactTextsUpdated)
        // io.off(socketEvents.contact_texts_deleted,    this.listenForContactTextsAdded)
        // io.off(socketEvents.contact_texts_added,      this.listenForContactTextsAdded)

    }

    render() {

        let { textsCount } = this.state;

        if(textsCount === null) return <Circle />

        return (

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Unread Texts</CardTitle>
                </CardHeader>

                <CardBody className="text-center font-weight-bold">
                    <span
                        className={!textsCount ? 'text-success' : textsCount < 30 ? 'text-warning' : 'text-danger'}
                        style={{fontSize: '2rem'}}
                    >
                        <i className="fas fa-mobile mr-2" />
                        <span> {textsCount}</span>
                    </span>
                </CardBody>

            </Card>

        );
    }
}

export default CallCenterAdminUnreadTexts
