/*
Documentation

This file allows a user to view and create new objection notes

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import renderName from 'utils/renderName';
import _objections from '_functions/objections';
import PropTypes from 'prop-types';

class ObjectionNotes extends React.Component {

    state = {
        note: {},
    }

    onNotesCreate = async() => {

        const text = this.state.note.text
        if(!text) return

        _objections.notesCreate(this.props.objection._id, {
            user: this.props.viewing_user._id,
            text: text
        })

        this.setState({note: {}})

    }

    getNotes = (objection) => {

        let notes = [];

        if(objection.notes && objection.notes) {
            notes = objection.notes.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
        }

        return notes
    }

    render() {

        const { note } = this.state
        const { objection, toggleModalObjectionsCrud } = this.props

        const notes = this.getNotes(objection)

        return (
            <>

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">
                            <i onClick={() => toggleModalObjectionsCrud('update')} className="fas fa-edit mr-2 text-warning" />
                            {objection.name}
                        </CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={note}
                            objName="note"
                            onChange={(o, f, v) => this.setState({note: {text: v}})}
                            type="textarea"
                            style={{minHeight: 100}}
                            field={'text'}
                            title={'Add Objection Note'}
                        />

                        <div className="text-right">
                            <button onClick={this.onNotesCreate} className="btn btn-success">
                                Add Note
                            </button>
                        </div>

                    </CardBody>

                    <CardFooter>

                        {notes.length ? (
                            notes.map((note, i) => (
                                <div key={i} className="p-4 bg-secondary mb-4">
                                    <p className="text-sm mb-0 font-weight-bold">
                                        <AvatarImage src={note.user ? note.user.avatar : null} className="mr-3" />
                                        <span className="text-capitalize">{renderName(note.user)} {' '}</span>
                                        {moment.unix(note.created_at).format('MMM Do, YYYY - h:mm A')}
                                    </p>
                                    <p className="text-sm mt-2 mb-0">{note.text}</p>
                                </div>
                            ))
                        ) : (
                            <div className="p-4 bg-secondary mb-4">
                                <p className="text-sm mb-0 font-weight-bold">No objection notes have been created yet.</p>
                            </div>
                        )}

                    </CardFooter>

                </Card>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ObjectionNotes.propTypes = {
    objection: PropTypes.object.isRequired,
    toggleModalObjectionsCrud: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, '')(ObjectionNotes);
