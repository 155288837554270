/*
Documentation

export a client side instance of socket.io to use throughout the application

*/

import keys from '../keys'
import socket from 'socket.io-client';

// export const io = socket(keys.API_URL, {
//     transports: [ 'websocket'],
//     forceNew: true,
//     reconnection: true,
//     secure: true
// });

export const io = socket(keys.API_URL, {
    withCredentials: true,
    transports: ['websocket'],
    autoConnect: true,
    reconnect: true,
    secure: true
});
