import { helpCenter } from 'store/functions/system/system';
import { Link } from 'react-router-dom'
import React from 'react';
import * as privileges from '_functions/users/privileges'

const backButton = (
    <Link
        to="/dashboard/users/all"
        className="btn btn-warning"
        onClick={() => helpCenter.toggle(
            helpCenter.content.employees.all.body,
            helpCenter.content.employees.all.footer,
        )}
    >
        <i className="fas fa-arrow-left mr-2" />Back
    </Link>
)

export default {

    all: {
        body: (
            <div>
                <h3>All Employees</h3>
                <p>On this page you may view all employees within the system. You may update and deleted employees based on the icons on the right hand side of each contact row and create a new employee by clicking the green button in the top right corner of the screen.</p>
            </div>
        ),
        footer: () => (
            privileges.canModerateStaff() ?

                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.employees.create.body,
                        helpCenter.content.employees.create.footer,
                        backButton
                    )}
                    to="/dashboard/employees/create"
                    className="btn btn-success"
                >
                    Create Employee
                </Link>

            : null
        )
    },
    create: {
        body: (
            <div>
                <h3>Employee Creation</h3>
                <p>Adding a new employee has five (5) stages:</p>

                <ol>
                    <li><b>General information -</b> Here is where you add the employees name, email and phone. The system will validate both email and phone numbers to make sure there are no duplicates within the system.</li>
                    <li><b>Employee Address</b> - This step requires you to add in the physical address of an employee.</li>
                    <li><b>Billing -</b> Under the billing section you will be required to add the employee hourly rate (This is internal and is used to track their internal cost to your firm) and billable rate (this is external and will be used to calculate invoice total and line items.)</li>
                    <li><b>Privileges -</b> This tab will allow you to set the permissions the employee has giving full control over what they can and cannot access in the system.</li>
                    <li><b>Review -</b> Confirmation of the details added.</li>
                </ol>
            </div>
        ),
    },

    update: {
        body: (
            <div>
                <h3>Updating an Employee</h3>
                <p>Updating an employee is very similar to creating one. The system will validate the email and phone to make sure no duplicates are made, the rest is up to you.</p>
            </div>
        ),
        footer: (
            <Link
                onClick={() => helpCenter.toggle(
                    helpCenter.content.employees.create.body,
                    helpCenter.content.employees.create.footer,
                    backButton
                )}
                to="/dashboard/employees/create"
                className="btn btn-success"
            >
                Create Employee
            </Link>
        )
    }

}
