
const classNames = {
    sidebar: {
        tabs: {
            active: 'font-weight-bold bg-gradient-warning rounded px-2 py-1 d-block text-center text-white border',
            inactive: ' rounded px-2 py-1 d-block text-center bg-secondary border',
        }
    }
  
}

export default classNames;