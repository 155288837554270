/*
Documentation

This file renders a table showing all objections for a case

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container, Badge } from "reactstrap";
import renderName from 'utils/renderName';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import _objections from '_functions/objections';
import NotificationDelete from 'components/functional/notifications/Delete';
import Circle from 'components/markup/loading/Circle';

import ModalObjectionsCrud from './components/ModalObjectionsCrud'
import filter from 'utils/filter';

class ObjectionsAll extends React.Component {

    state = {
        objectionToDelete: null,
        showModalObjectionsCrud: false,
        objections: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Objection",
            headerStyle: {width: 400},
            formatter: (cell, row) => (
                <>

                <Link to={`/dashboard/objections/${this.props.match.params.case}/${row._id}`} className="text-capitalize font-weight-bold" style={{whiteSpace: 'pre-line'}}>
                    {row.resolved_at ? (
                        <span className="text-success"><i className="fas fa-check mr-2" style={{position: 'relative', top: 1 }}/>{row.name}</span>
                    ) : (
                        <span className={row.severity === 3 ? "text-danger" : 'text-warning'}><i className="fas fa-times mr-2" style={{position: 'relative', top: 1 }}/>{row.name}</span>
                    )}
                </Link>

                </>
            )
        },
        {
            dataField: "severity",
            text: "Severity",
            formatter: (cell, row) => {

                if(row.resolved_by) return <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="success"><i className="fas fa-check mr-2" /> Resolved </Badge>
                if(row.severity === 1) return <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="warning">Standard</Badge>
                if(row.severity === 2) return <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="warning">Important</Badge>
                if(row.severity === 3) return <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="danger">Severe</Badge>

            }
        },
        {
            dataField: "created_by",
            text: "Created By",
            formatter: (cell, row) => (
                row.created_by ? (
                    <span className="text-capitalize">
                    <AvatarImage src={row.created_by.avatar} className="mr-2" />
                    {renderName(row.created_by, true)}
                    </span>
                ) : ''
            )
        },
        {
            dataField: "objecting_party",
            text: "Objection Parties",
            formatter: (cell, row) => (
                <>
                    <div>
                        <span style={{display: 'inline-block', width: 120}}>Objecting Party:</span>
                        <b className="text-capitalize">{renderName(row.objecting_party)}</b>
                    </div>
                    <div>
                        <span style={{display: 'inline-block', width: 120}}>Resolved By:</span>
                        <b className="text-capitalize">{row.resolved_by ? renderName(row.resolved_by): '-'}</b>
                    </div>
                </>
            )
        },
        {
            dataField: "created_at",
            text: "Objection Dates",
            formatter: (cell, row) => (
                <>
                <div>
                    <span style={{display: 'inline-block', width: 120}}>Opened On:</span>
                    <b>{moment.unix(row.created_at).format("MMM, Do, YYYY -  h:mm A")}</b>
                </div>
                <div>
                    <span style={{display: 'inline-block', width: 120}}>Resolved On:</span>
                    <b>{row.resolved_at ? moment.unix(row.resolved_at).format("MMM, Do, YYYY -  h:mm A") : '-'}</b>
                </div>
                </>
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/objections/${this.props.match.params.case}/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({objectionToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onObjectionRemoved = (objection) => {

        const objections = filter.byId(this.state.objections, objection.data._id)
        this.setState({objections});

    }

    onObjectionAdded = (data) => {

        this.toggleModalObjectionsCrud()

        const objections = this.state.objections ? [...this.state.objections] : [];
        objections.push(data.data)

        this.setState({objections});

    }

    toggleModalObjectionsCrud = () => this.setState({showModalObjectionsCrud: !this.state.showModalObjectionsCrud})

    componentDidMount = async () => {

        const objections = await _objections.find(`?filter=case__${this.props.match.params.case}&sort=resolved_at__desc|severity__desc`)
        if(objections.success) this.setState({objections: objections.data})

    }

    render() {

        const { objections, objectionToDelete } = this.state;

        return (
            <>

            <Helmet>
                <title>{`Objections`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    title={<span>Case Objections</span>}
                    breadcrumb_1={(
                        <Link to={`/dashboard/cases/view/${this.props.match.params.case}`} className="text-muted"><i className="fas fa-arrow-left mr-2" /> Back To Case</Link>
                    )}
                    actionComponent={(
                        <button onClick={this.toggleModalObjectionsCrud} className="btn btn-success">
                            <i className="fas fa-plus mr-2" /> New
                        </button>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Case Objections</CardTitle>
                    </CardHeader>

                    {objections ? (

                        objections.length ? (

                            <ToolkitProvider
                                data={objections}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                    <div className=" table-responsive table-vertical-align table-not-fixed">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                    </>
                                )}
                            </ToolkitProvider>

                        ) : (

                            <CardBody>
                                No objections have been created for this case yet.
                            </CardBody>

                        )

                    ) : (
                        <Circle />
                    )}

                </Card>

                {objectionToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/core/objections/delete/${objectionToDelete._id}`}
                        confirmBtnText="Delete Object"
                        title={<span className="text-capitalize">{`Delete Objection: ${objectionToDelete.name}`}</span>}
                        text="This action will remove all data associated with the objection."
                        onClose={() => this.setState({objectionToDelete: null})}
                        onSuccess={this.onObjectionRemoved}
                    />
                )}

                <ModalObjectionsCrud
                    case={this.props.match.params.case}
                    showModal={this.state.showModalObjectionsCrud}
                    toggleModal={this.toggleModalObjectionsCrud}
                    onSuccess={this.onObjectionAdded}
                />

            </Container>
            </>
        );
    }
}

export default ObjectionsAll
