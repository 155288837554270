// returns a friendly name based on interval type

export default (interval_type) => {

    let toReturn = null

    if(interval_type === 'd') {

        toReturn = 'Day'

   } else if(interval_type === 'w') {

        toReturn = 'Week'

   }  else if(interval_type === 'm') {

        toReturn = 'Month'

   }

   return toReturn


}