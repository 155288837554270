/*
Documentation

this file shows a modal that creates a task on a case to follow up on an email

*/

import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import {  Modal } from "reactstrap";
import CaseSearchInput from 'views/dashboard/pages/_components/CaseSearchInput'
import _case_workflow_tasks from '_functions/case_workflow_tasks'
import _case_workflows from '_functions/case_workflows'

class ModalCreateReminder extends Component {

    state = {
        task: {},
        selectedCase: null
    }

    onCreate = async () => {

        this.setState({error: null})
        const { selectedCase, task } = this.state

        if(!selectedCase) return this.setState({error: 'You must specify the case to add this reminder to.'})
        if(!task.name) return this.setState({error: 'You must specify the reminder name.'})
        if(!task.date) return this.setState({error: 'You must specify the time frame for this reminder.'})

        const workflow = await _case_workflows.findById(this.state.selectedCase.workflow)

        if(workflow.data) {

            _case_workflow_tasks.create({
                type                  : 'text',
                persistent            : true,
                active                : true,
                required              : false,
                date                  : Math.floor(new Date() / 1000) + parseInt(task.date),
                case                  : selectedCase._id,
                contact               : selectedCase.contact ? selectedCase.contact._id ? selectedCase.contact._id : selectedCase.contact : null,
                case_workflow_step    : workflow.data.steps[0]._id,
                case_workflow         : workflow.data._id,
                name                  : task.name,
            })

            this.setState({task: {}, selectedCase: null}, this.props.toggleModal)

        } else {

            this.setState({error: 'An unexpected error occurred, contact support.'})

        }

    }

    onSelectCase = (selectedCase) => this.setState({selectedCase})

    componentWillReceiveProps = (nextProps) => {

        // reset state task every time this modal opens up
        if(!this.props.showModal && nextProps.showModal && nextProps.email) {
            this.setState({task: {name: `Follow Up: ${nextProps.email.subject}`, date: 86400}})
        }

    }

    render() {

        const { email, showModal, toggleModal } = this.props;
        const { task, selectedCase, error } = this.state;

        if(!email) return null

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleModal()}
                size="lg"
                fade={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">Create Reminder</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm  mb-0">
                        <span className="font-weight-bold" style={{display: 'inline-block', width: 55}}>FROM: </span>
                        {email.from}
                    </p>
                    <p className="text-sm mb-0">
                        <span className="font-weight-bold" style={{display: 'inline-block', width: 55}}>TO:</span>
                        {email.to && Array.isArray(email.to.length) && email.to.length ? email.to.map((email, i) => i=== 0 ? email : ', ' + email) : email.to}
                    </p>

                    <p className="text-sm mb-0">{email.subject}</p>

                    <hr className="my-2" />

                    <StandardFormGroup
                        obj={task}
                        objName='task'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Task Name"
                    />
                    <StandardFormGroup
                        obj={task}
                        objName='task'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="date"
                        title="Follow Up date"
                    >
                        <option value={86400 * 1}>Tomorrow</option>
                        <option value={86400 * 2}>Two Days</option>
                        <option value={86400 * 3}>Three Days</option>
                        <option value={86400 * 7}>One Week</option>
                        <option value={86400 * 14}>Two Weeks</option>
                        <option value={86400 * 21}>Three Weeks</option>
                        <option value={86400 * 30}>One Month</option>
                        <option value={86400 * 60}>Two Months</option>
                    </StandardFormGroup>

                    <CaseSearchInput
                        title="Assign Reminder To Case:"
                        onSelect={this.onSelectCase}
                        value={selectedCase || {}}
                    />

                    {error ? <div className="alert alert-danger mb-0">{error}</div> : null}

                </div>

                <div className="modal-footer text-right">
                    <button onClick={this.onCreate} className="btn btn-success"><i className="fas fa-check mr-2" /> Create Reminder</button>
                </div>

            </Modal>

        )

    }

}

ModalCreateReminder.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    email         : PropTypes.object.isRequired,
}

export default ModalCreateReminder

