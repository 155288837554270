/*
Documentation

self explanatory, hide or show footer by id

*/

export const hide = () => {

    const footer = document.getElementById('footer')
    if(footer) footer.style.display = 'none'

}

export const show = () => {

    const footer = document.getElementById('footer')
    if(footer) footer.style.display = 'block'

}
