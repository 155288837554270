/*
Documentation

this modal can add a role to the case, note that this
does not mean assign a user to role, only add a new role

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from "reactstrap";

class RolesAddRole extends Component {

    onConfirm = (bool) => {

        this.props.onCreateAlertEntry(bool)
        this.props.toggleModal()

    }

    render() {

        const { showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Create Case Alert</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm mb-0">Should this case alert also trigger the contact to be notified? </p>

                </div>

                <div className="modal-footer d-block">
                    <button style={{width: 120}} onClick={toggleModal} className="btn text-center btn-secondary float-left" >
                        <i className="fas fa-arrow-left mr-2 " /> Cancel
                    </button>

                    <button style={{width: 120}} onClick={() => this.onConfirm(true)} className="btn text-center btn-outline-success float-right" >
                        <i className="fas fa-check mr-2 " /> Yes
                    </button>

                    <button style={{width: 120}} onClick={() => this.onConfirm(false)} className="btn text-center btn-outline-warning float-right" >
                        <i className="fas fa-times mr-2 " /> No
                    </button>
                </div>

            </Modal>

        )

    }

}

RolesAddRole.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired
}

export default RolesAddRole
