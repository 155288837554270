const sendNotification = (params) => {

    const close = params.close ? params.close : 3000

    var notification = new Notification(params.title, {
        body: params.body,
        tag: params.tag,
        icon: 'https://fairmax-voice-recordings.s3.us-east-2.amazonaws.com/icon.png',
    });

    setTimeout(() => { notification.close() }, close)

}

const notifyMe = (params) => {

    if (!("Notification" in window)) return;

    if (Notification.permission === "granted") {

      sendNotification(params)

    } else if (Notification.permission !== "denied") {

        Notification.requestPermission().then(function (permission) {

            if (permission === "granted") sendNotification(params)

        });

    }
  
}

export default notifyMe