/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, Input } from "reactstrap";
import { formatMoney } from 'utils/currency'
import { secondsToTime } from 'utils/time'


const Results = ({aggregation, documents, totalDocuments, page, searchPlaceholder, limit, columns, searchString, onSearchChange, onTableChange, loading, hideSearch}) => {

    if(!documents) return <Circle />;

    return (

        <Card className="mb-0">

            {aggregation ? (
                aggregation.total_time_till_first_call !== undefined ? (
                    <div className="table-responsive border-bottom">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td style={{width: '25%'}}>Total Contacted</td>
                                    <td style={{width: '25%'}} className="text-right">{aggregation.total}</td>
                                    <td style={{width: '25%'}}>Average Time Till Call</td>
                                    <td style={{width: '25%'}} className="text-right">{secondsToTime(Math.floor(aggregation.total_time_till_first_call / aggregation.total))}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="table-responsive border-bottom">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Payment Count</td>
                                    <td className="text-right">{aggregation.total}</td>
                                    <td>Amount Succeeded</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_succeeded)}</td>
                                    
                                </tr>
                                <tr>
                                    <td>Amount Total</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments)}</td>
                                    <td>Amount Failed</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_failed)}</td>
                                </tr>
                                <tr>
                                    <td>Amount Standard</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_standard)}</td>
                                    <td>Amount Authorized</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_authorized)}</td>
                                </tr>
                                <tr>
                                    <td>Amount Trust</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_trust)}</td>
                                    <td>Amount Refunded</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_refunded)}</td>
                                </tr>
                                <tr>
                                    <td>Succeeded %</td>
                                    <td className="text-right">{((aggregation.total_payments_succeeded / aggregation.total_payments) * 100).toFixed(2)}%</td>
                                    <td>Amount Voided</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_voided)}</td>
                                </tr>
                                <tr>
                                    <td>Failed %</td>
                                    <td className="text-right">{((aggregation.total_payments_failed / aggregation.total_payments) * 100).toFixed(2)}%</td>
                                    <td>Amount Charged Back</td>
                                    <td className="text-right">{formatMoney(aggregation.total_payments_chargeback)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            ) : null}

            <ToolkitProvider
                data={documents}
                keyField="_id"
                columns={columns}
                search
            >
                {props => (
                    <>
                    {hideSearch ? null : (
                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                        <label>
                            Search:
                            <Input
                                className="form-control-sm"
                                // value={searchString || ''}
                                placeholder={searchPlaceholder}
                                onChange={(e) => onSearchChange(e.target.value)}
                            />
                                {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                        </label>
                    </div>
                    )}

                    {documents.length ? (
                        <div className="table-not-fixed table-responsive table-vertical-align">
                            <BootstrapTable
                                pagination={paginationFactory({
                                    totalSize: totalDocuments,
                                    page: page,
                                    sizePerPage: limit,
                                    alwaysShowAllBtns: true,
                                    showTotal: true,
                                    withFirstAndLast: true,
                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                        <div className="dataTables_length" id="datatable-basic_length">
                                            <label>
                                                Show{" "}
                                                {
                                                <select
                                                    value={currSizePerPage}
                                                    name="datatable-basic_length"
                                                    aria-controls="datatable-basic"
                                                    className="form-control form-control-sm"
                                                    onChange={e => {
                                                        onSizePerPageChange(e.target.value);

                                                    }}
                                                >
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                }{" "}
                                                entries.
                                            </label>
                                        </div>
                                    )
                                })}
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                remote={{
                                    search: true,
                                    pagination: true,
                                    sort: false,
                                    cellEdit: false
                                }}
                                onTableChange={onTableChange}

                            />
                        </div>
                    ): (
                        <Card className="mb-0">
                            <CardBody>
                                <p className="text-sm mb-0">
                                    <i className="fas fa-info-circle mr-2 text-info-original " />
                                    No Data To Show
                                </p>
                            </CardBody>
                        </Card>
                    )}
                    </>
                )}
            </ToolkitProvider>
        </Card>

    );

}

export default Results
