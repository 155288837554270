/*
Documentation

Shows all payments a contact has made

*/

import moment from 'moment';

import PropTypes from 'prop-types';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import * as privileges from '_functions/users/privileges';

import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Badge, CardTitle, Row, Col } from "reactstrap";

import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import renderName from 'utils/renderName';

import ModalRefund from './ModalRefund'
import ModalComplete from './ModalComplete'

class PaymentLogs extends React.Component {

    state = {
        contactToDelete: null,
        showModal: false,
        showModalComplete: false,
        payment: null
    }

    toggleModal = (payment) => this.setState({showModal: !this.state.showModal, payment})
    toggleModalComplete = (payment) => this.setState({showModalComplete: !this.state.showModalComplete, payment})

    columns = [
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => (
                <span>
                    {moment.unix(row.created_at).format('MMM Do, YYYY')} <br />
                    at {moment.unix(row.created_at).format('h:mm A')}

                </span>
            ),
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return formatMoney(row.transaction_amount)
            }
        },
        {
            dataField: "fee_amount",
            text: "Fee Amount",
            formatter: (cell, row) => {
                return row.fee_amount ? formatMoney(row.fee_amount) : '-'
            }
        },
        {
            dataField: "expense_amount",
            text: "Expense Amount",
            formatter: (cell, row) => {
                return row.expense_amount ? formatMoney(row.expense_amount) : '-'
            }
        },
        {
            dataField: "description",
            text: "Description",
            formatter: (cell, row) => (
                <span className="text-capitalize" style={{whiteSpace: 'pre-line'}}>{row.description}</span>
            )
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.type}</span>
            )
        },
        {
            dataField: "is_trust",
            text: "Is Trust",
            formatter: (cell, row) => row.is_trust === true ? <div className="text-center"><i className="fas fa-check text-success" /></div> : null
        },
        {
            dataField: "account_type",
            text: "Method",
            formatter: (cell, row) => (
               row.account_type ? (
                <span>
                    <b>{_payments.cards.getName(row.account_type)}{' '}</b> <br />
                    {row.first_six.slice(0,1)}...{row.last_four}
                </span>
               ) : 'Manual'
            )
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => {

                return row.status === 'succeeded' ? <Badge className="text-sm" style={styles.badges} color="success">Succeeded</Badge> :
                    row.status === 'authorized' ? <Badge className="text-sm" style={styles.badges} color="warning">Authorized</Badge> :
                    row.status === 'failed' ? <Badge className="text-sm" style={styles.badges} color="danger">Failed</Badge> :
                    row.status === 'voided' ? <Badge className="text-sm" style={styles.badges} color="danger">VOIDED</Badge> :
                    <Badge className="text-sm" style={styles.badges} color="warning">Refunded</Badge>

            }
        },

        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        {privileges.canUpdateFinances() && row.status === 'authorized' ? (
                            <Button onClick={() => this.toggleModalComplete(row)} outline color="success" >
                                <i className="fas fa-dollar-sign mr-2" /> Capture
                            </Button>
                        ) : privileges.canUpdateFinances() && row.status !== 'refunded' ? (
                            <Button onClick={() => this.toggleModal(row)} outline color="warning" >
                                <i className="fas fa-dollar-sign mr-2" /> Refund
                            </Button>
                        ) : null}
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    render() {

        const { showModal, payment, showModalComplete } = this.state;
        const { payments, contact, total_paid } = this.props;

        return (
            <>

                <Card>

                    <CardHeader>
                        <Row>
                            <Col lg={6}>
                                <CardTitle className="mb-0 text-capitalize">
                                    {renderName(contact)}'s payments
                                </CardTitle>
                            </Col>
                            <Col lg={6} className="text-right">
                                Total: <b>{formatMoney(total_paid)}</b>
                            </Col>
                        </Row>
                    </CardHeader>

                    {payments && payments.length ? (
                        <ToolkitProvider
                            data={payments}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <CardBody>
                            <Link to="/dashboard/contacts/create">
                                No payments have been collected for this contact yet.
                            </Link>
                        </CardBody>
                    )}

                </Card>

                <ModalRefund
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    payment={payment}

                />
                
                <ModalComplete
                    showModal={showModalComplete}
                    toggleModal={this.toggleModalComplete}
                    payment={payment}

                />

            </>
        );
    }
}

const styles = {

    badges: {
        display: 'inline-block',
        width: 95
    }

}

PaymentLogs.propTypes = {
    contact   : PropTypes.object.isRequired,
    payments  : PropTypes.array,
}

export default PaymentLogs
