import apiWorker from '../apiWorker'

export default {

    findById:       (workflow_id, query)    => apiWorker('get',   `/api/v1/core/case_workflows/find/${workflow_id}${query}`),
    compileByCase:  (case_id, query)        => apiWorker('get',   `/api/v1/core/case_workflows/compileByCase/${case_id}${query}`),
    stepsByCase:    (case_id)               => apiWorker('get',   `/api/v1/core/case_workflows/stepsByCase/${case_id}`),
    getStepsOnMove: (case_workflow_step_id) => apiWorker('get',   `/api/v1/core/case_workflows/getStepsOnMove/${case_workflow_step_id}`),
    moveStep:       (data, hideError)                  => apiWorker('patch', `/api/v1/core/case_workflows/moveStep/`, data, hideError),

}
