import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

class CanvasModalName extends React.Component {

    state = {
        name: {}
    }

    updateMarkerName = () => {

        this.props.updateMarkerName(this.state.name.name, this.props.index)
        this.props.toggleModal()

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.state.showModal && nextProps.showModal) {
            this.setState({name: nextProps.name ? { name: nextProps.name} : {}})
            setTimeout(() => {
                const el = document.getElementById('update-name-input')
                if(el) el.focus() 
            }, 500)
        }

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { name } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Marker Question Text</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        inputId="update-name-input"
                        obj={name}
                        objName="name"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title={'This text will be the question shown to contacts when filling out this pdf form field.'}
                    />

                </div>

                <div className="modal-footer">
                    <button onClick={this.updateMarkerName} className="btn btn-outline-success">Save Question</button>
                </div>

            </Modal>

        );
    }
}

CanvasModalName.propTypes = {
    showModal           : PropTypes.bool.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    updateMarkerName    : PropTypes.func.isRequired,
    name                : PropTypes.string,
    index               : PropTypes.number
}

export default CanvasModalName
