/*
Documentation

this wrapper creates not only a container formatted for pdf's but prints anything inside of itself when clicked

*/

import PropTypes from 'prop-types'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React from "react";
import { Container, Row, Col } from "reactstrap";
import * as footer from 'utils/footer'

class Wrapper extends React.Component {

    state = {

    }

    getPDF= (file_name) => {

        const el = document.getElementById('export-content')

        var HTML_Width = el.offsetWidth;
        var HTML_Height = el.offsetHeight;
        var top_left_margin = 15;
        var PDF_Width = HTML_Width+(top_left_margin*2);
        var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;

        var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;


        html2canvas(el).then(function(canvas) {
            canvas.getContext('2d');

            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);


            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage(PDF_Width, PDF_Height);
                pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
            }

            pdf.save(file_name ? file_name : 'download.pdf');
        });
    };

    print = async  () => {

        footer.hide()
        this.setState({hideController: true}, () => {

            window.print()
            this.setState({hideController: false})
            footer.show()

        })

    }

    render() {

        const { canPrint, canSavePDF } = this.props
        const { hideController } = this.state;

        return (

            <>

            {!hideController ? (
            <div className="py-4 bg-white mb-4 z-depth-2" style={{position: 'fixed', top: 0, right: 0, left: 0, width: '100%', zIndex: 100 }}>
                <Container fluid>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <h2 className="mb-0">Page Actions</h2>
                        </Col>
                        <Col lg={6} className="text-right">
                            {canSavePDF ? <button className="btn btn-success" onClick={() => this.getPDF()}><i className="fas fa-file-pdf mr-2" /> Export To PDF</button> : null}
                            {canPrint ? <button className="btn btn-success" onClick={this.print}><i className="fas fa-print mr-2" /> Print Page</button> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
            ) : null}

            <div style={{maxWidth: '95%', width: '1200px', padding: 20, margin: 'auto', marginTop: 60}} id="export-content">
                {this.props.children}
            </div>

            </>

        );
    }
}


Wrapper.propTypes = {
    canPrint:   PropTypes.func.isRequired,
    canSavePDF: PropTypes.func.isRequired,
}

export default Wrapper
