/*
Documentation

renders adding a cash payment to a case

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Input, Card, CardBody, CardHeader, CardTitle, Row, Col, CardFooter  } from 'reactstrap';
import { passesDecimalCheck } from 'utils/currency';
import _payments from '_functions/payments'
import { formatMoney } from 'utils/currency';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'
import moment from 'moment';
class ChargeCashPayment extends Component {

    state = {
        canSave         : true,
        cash_payment    : null,
        description     : null,
        type            : null,
        date            : null,
    };

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState(newState);

    };

    //when a new payment is charged
    onChargeCashPayment = async () => {

        const { cash_payment, description, type, date } = this.state

        this.setState({
            cash_paymentState: 'valid',

            valueError: null,
            canSave: false
        })

        //set state bases on if we have a valid amount
        if(!cash_payment || !passesDecimalCheck(cash_payment)) {
            return this.setState({cash_paymentState: 'invalid', canSave: true})
        }

        //make sure the payment type has passed
        if(!type || type === 'false') {
            return this.setState({typeState: 'invalid', canSave: true})
        }
       
        //make sure the payment type has passed
        if(!date || date === 'false') {
            return this.setState({dateState: 'invalid', canSave: true})
        }

        //make sure the payment type has passed
        if(type === 'check' || type === 'money_order') {
            if(!description) {
                return this.setState({descriptionState: 'invalid', canSave: true})
            }
        }

        toggleStandardLoader(true)

        const charge = await _payments.transactions.cash({
            case: this.props.case._id,
            transaction_amount: cash_payment,
            date: parseInt(moment(date).format('X')),
            description: type === 'check' ? `Check Number: ${description}` : type === 'money_order' ? `Reference: ${description}` : description
        })

        toggleStandardLoader(false)

        if(charge.success) {
            toggleAlertBS(false, charge.user_message ? charge.user_message  : 'Cash Payment Recorded Successfully')
        } else {
            toggleAlertBS(true, charge.user_message ? charge.user_message  : 'An Error Occurred Recording This Cash Payment')
        }

        this.setState({
            canSave: true, 
            description: null,
            type: 'false',
            cash_payment: null, 
            cash_paymentState: null, 
            descriptionState: null, 
            typeState: null, 
            date: null,
            dateState: null,
            selected_invoice: null, 
            selected_invoice_balance: null
        })
        this.props.addPaymentToState(cash_payment)

    }

    render() {

        const { valueError, type } = this.state;
        const _case = this.props.case

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">Charge A Cash Payment</CardTitle>
                </CardHeader>

                <CardBody>

                    <p className="text-sm">Charging a cash payment means this client has paid with a method other than one provided by this platform.</p>

                    <FormGroup>

                        <label className="form-control-label" htmlFor="cash_payment" > Amount (US Dollars)</label>

                        {valueError ? (
                            <p className="text-sm text-danger font-weight-bold mb-0">{valueError}</p>
                        ) : null}

                        <Input
                            id="cash_payment"
                            value={this.state.cash_payment || ''}
                            type="number"
                            valid={ this.state.cash_paymentState === "valid" }
                            invalid={ this.state.cash_paymentState === "invalid" }
                            onChange={e => this.onInputChange(e, "cash_payment") }
                        />

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                    <FormGroup>

                        <label className="form-control-label" htmlFor="cash_payment" >Payment Type</label>

                        <Input
                            id="type"
                            value={this.state.type || ''}
                            type="select"
                            valid={ this.state.typeState === "valid" }
                            invalid={ this.state.typeState === "invalid" }
                            onChange={e => this.onInputChange(e, "type") }
                        >
                            <option value="false"></option>
                            <option value="cash">Cash</option>
                            <option value="check">Check</option>
                            <option value="money_order">Money Order</option>
                        </Input>

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>
                 
                    <FormGroup>

                        <label className="form-control-label" htmlFor="cash_payment" >Payment Date</label>

                        <Input
                            id="type"
                            value={this.state.date || ''}
                            type="date"
                            valid={ this.state.dateState === "valid" }
                            invalid={ this.state.dateState === "invalid" }
                            onChange={e => this.onInputChange(e, "date") }
                        />

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="cash_payment" >
                            {type === 'check' ? 'Check Number' : type === 'money_order' ? 'Money Order Reference' : 'Payment Description'}
                        </label>
                        <Input
                            id="description"
                            value={this.state.description || ''}
                            type="text"
                            valid={ this.state.descriptionState === "valid" }
                            invalid={ this.state.descriptionState === "invalid" }
                            onChange={e => this.onInputChange(e, "description") }
                        />
                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <Row>

                        <Col lg={6} className="align-self-center">
                            <p className="text-muted mb-0 text-sm">Paid: {formatMoney(_case.billing_paid)} of {formatMoney(_case.billing_total)}</p>
                        </Col>

                        <Col lg={6} className="align-self-center text-right">
                            <button
                                disabled={!this.state.canSave}
                                onClick={!this.state.canSave ? null : this.onChargeCashPayment}
                                className="btn btn-success"
                            >
                                Add Cash Payment
                            </button>
                        </Col>
                        
                    </Row>
                </CardFooter>

            </Card>

        )

    }

}

ChargeCashPayment.propTypes = {
    case: PropTypes.object.isRequired,
    addPaymentToState: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired
}

export default ChargeCashPayment
