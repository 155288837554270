import React, { useEffect, useState } from 'react';

import Quote from './Quote';
import ContactQuestionnaires from 'views/dashboard/pages/contacts/components/ContactQuestionnaires';

const StepsToPrepInfo = ({onSetInfoProgress, questionnaire_id, questionnaire, answers, foundCase, onMarkDocNaOnNo, roadsideSettings}) => {

    const [quote, setQuote] = useState(0)

    useEffect(() => {

        let _quote = 0;
        let hasDUI = false

        const checkQuestions = (_questions) => {
            _questions.forEach(question => {

                const value = answers[question._id];

                if(value === 'yes' || Number.isInteger(parseInt(value))) {
                    if(question.description === 'Was there an accident?') {
                        _quote += parseFloat(roadsideSettings.additions.accident);
                    } else if(question.description === 'Is This A Commercial Ticket (Truck Drivers, Etc.)? *') {
                        _quote += parseFloat(roadsideSettings.additions.commercial);
                    } else if(question.description === 'How Many Times Did The Officer Do That? *' && value && value !== '0') {
                        _quote += (parseFloat(roadsideSettings.additions.misd_extra) * parseInt(value - 1)) + parseInt(roadsideSettings.additions.misd);
                    } 
                    // else if(question.description === 'Does This Ticket Cite You For A Suspended License? *') {
                    //     _quote += parseFloat(roadsideSettings.additions.suspended_license);
                    // } 
                    else if(question.description === 'Does Ticket Cite You For No Insurance/No Proof Of Insurance? *') {
                        _quote += parseFloat(roadsideSettings.additions.misd_extra);
                    } else if(question.description === 'Was There A Reckless Driving Charge On Your Ticket? *') {
                        _quote += parseFloat(roadsideSettings.additions.reckless_driving);
                    } else if(question.description === 'Was There A Drunk Driving (OWI) On Your Ticket? *') {
                        _quote += parseFloat(roadsideSettings.additions.drunk_driving);
                        hasDUI = true
                    } else if(hasDUI && question.description === 'Have You Had Any Drunk Driving Ticket In Your Past? *') {
                        _quote += parseFloat(roadsideSettings.additions.drunk_driving_prior);
                    } else if(question.description === 'What court is your ticket assigned to?') {
                        const foundCourt = roadsideSettings.courts.find(c => c.intuidesk_id === value);
                        if(foundCourt) _quote += parseInt(foundCourt.value)
                    }
                }

                if(question.questions && question.type === 'yes - no' && answers[question._id] === 'yes' ) {
                    checkQuestions(question.questions)
                }
            })
        }

        checkQuestions(questionnaire.questions);

        setQuote(_quote)

    }, [questionnaire, answers, roadsideSettings])


    return (

        <div className="archk-steps-to-prep-info">
            <div className="archk-steps-to-prep-spacer">


                {questionnaire_id ? (
                    <div>
                        <Quote quote={quote} />
                        <ContactQuestionnaires 
                            populatedContact={foundCase.contact}
                            questionnaire={questionnaire_id}
                            autoSave={true}
                            onAnswerChange={(answers) => onSetInfoProgress(answers)}
                            onMarkDocNaOnNo={onMarkDocNaOnNo}
                        />
                        <Quote quote={quote} />
                    </div>
                    
                ): (
                    <p className="text-sm">
                        No questionnaire was found for this case.{' '}
                    </p>
                )}

            </div>
        </div>
    )
}

export default StepsToPrepInfo