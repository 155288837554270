import apiWorker from '../apiWorker'

import calculateTotalByCase from './calculate/totalByCase'
import calculateTotalRemainingByCase from './calculate/totalRemainingByCase'
import { calculateCreditTotal, calculateInvoiceExpenses, calculateInvoiceFees, calculateInvoiceTotal, calculateWriteOffTotal, calculateRefundTotal } from './calculate/calculations';

export default {


    create: (data)                  => apiWorker('post',   `/api/v1/billing/invoices/create`, data, true),
    send:   (invoice_id, user_id)   => apiWorker('patch',  `/api/v1/billing/invoices/send/${invoice_id}/${user_id}`),
    update: (data)                  => apiWorker('patch',  `/api/v1/billing/invoices/update/${data._id}`, data, true),
    delete: (invoice_id)            => apiWorker('delete', `/api/v1/billing/invoices/delete/${invoice_id}`),

    findByCase: (case_id)           => apiWorker('get', `/api/v1/billing/invoices/case/${case_id}`),



    calculate: {
        totalByCase: calculateTotalByCase,
        totalRemainingByCase: calculateTotalRemainingByCase,
        totalCredits: calculateCreditTotal,
        totalExpenses: calculateInvoiceExpenses,
        totalFees: calculateInvoiceFees,
        totalWriteOffs: calculateWriteOffTotal,
        totalRefunds: calculateRefundTotal,
        total: calculateInvoiceTotal,
    }

}
