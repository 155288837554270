import React, { Component } from 'react';

class Navigation extends Component {

    render() {

        const navigation = this.props.navigation
        const setNavigation = this.props.setNavigation

        return (

            <ol className="simple-nav">
                <li className={navigation === 1 ? 'active' : null} onClick={() => setNavigation(1)}>
                    Details
                </li>
                <li className={navigation === 2 ? 'active' : null} onClick={() => setNavigation(2)}>
                    Fees
                </li>
                <li className={navigation === 3 ? 'active' : null} onClick={() => setNavigation(3)}>
                    Expenses
                </li>
                <li className={navigation === 4 ? 'active' : null} onClick={() => setNavigation(4)}>
                    Credits
                </li>
                <li className={navigation === 5 ? 'active' : null} onClick={() => setNavigation(5)}>
                    Discounts
                </li>
                <li className={navigation === 6 ? 'active' : null} onClick={() => setNavigation(6)}>
                    Write Offs
                </li>
                <li className={navigation === 7 ? 'active' : null} onClick={() => setNavigation(7)}>
                Refunds
                </li>
            </ol>

        )

    }

}

export default Navigation;
