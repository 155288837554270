import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle } from 'reactstrap';

const AccountDevice = () => (
    <Card>

        <CardHeader>
            <CardTitle className="mb-0">Audio / Communication</CardTitle>
        </CardHeader>

        <CardHeader>
            <p className="text-sm mb-0">
                To test your devices ability to call via the browser{' '}
                <a href="https://networktest.twilio.com/" target="_blank" rel="noopener noreferrer" className="text-underline text-warning">CLICK HERE</a>{' '}
                and follow the direction on the page making sure to enable every prompt that comes up.
            </p>
        </CardHeader>

        <CardHeader>
            <p className="text-sm mb-0">
                To test and find your computer/phones default audio input/output devices{' '}
                <Link className="text-underline text-warning" to="/dashboard/call_center_test">CLICK HERE.</Link>
            </p>
        </CardHeader>

    </Card>
)

export default AccountDevice