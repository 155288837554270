/*
Documentation

This renders a card showing the number of staff members online
against those offline

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Circle from 'components/markup/loading/Circle'
import { connect } from 'react-redux';

class CallCenterAdminStaffOnline extends React.Component {

    state = {
        usersOnline: null,
        totalUsers: null,
    }

    setUsers = (props) => {

        const users = [...props.users]
        let usersOnline = 0

        users.forEach(user => {

            if(user.status === 'online') usersOnline++

        })

        this.setState({usersOnline, totalUsers: users.length })

    }

    componentDidMount         =  () => this.setUsers(this.props)
    componentWillReceiveProps = (nextProps) => this.setUsers(nextProps)

    render() {

        let { usersOnline, totalUsers } = this.state;

        if(usersOnline === null || totalUsers === null) return <Circle />

        return (
            <>


                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Staff Online</CardTitle>
                    </CardHeader>

                    <CardBody className="text-center font-weight-bold">
                        <span
                            style={{fontSize: '2rem'}}
                        >
                            <i className="fas fa-user mr-2" />
                            {usersOnline} / {totalUsers}
                        </span>
                    </CardBody>


                </Card>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdminStaffOnline);
