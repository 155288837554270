import PropTypes from 'prop-types';
import React from 'react';
import renderName from 'utils/renderName';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';

class ModalSendAssignContacts extends React.Component {

    render() {

        const { contacts, parties, onAddParty } = this.props

        const contact_1 = contacts.find(c => c.party_index === 1);
        const contact_2 = contacts.find(c => c.party_index === 2);
        const contact_3 = contacts.find(c => c.party_index === 3);
        const contact_4 = contacts.find(c => c.party_index === 4);

        return (

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: '33%'}}>Party</th>
                            <th>Contact</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td className="text-capitalize">
                                Party 1: {contact_1 ? renderName(contact_1.fullContact) : 'Not Selected'}
                            </td>
                            <td>
                                <ContactSearchInput
                                    titleFormatter={() => ' '}
                                    value={contact_1 ? contact_1 : {}}
                                    onSelect={(contact) => onAddParty(contact, 1)}
                                />
                            </td>
                        </tr>
                        {parties > 1 ? (
                            <tr>
                                <td className="text-capitalize">
                                    Party 2: {contact_2 ? renderName(contact_2.fullContact) : 'Not Selected'}
                                </td>
                                <td>
                                    <ContactSearchInput
                                        titleFormatter={() => ' '}
                                        value={contact_2 ? contact_2 : {}}
                                        onSelect={(contact) => onAddParty(contact, 2)}
                                    />
                                </td>
                            </tr>
                        ) : null}
                        {parties > 2 ? (
                            <tr>
                                <td className="text-capitalize">
                                    Party 3: {contact_3 ? renderName(contact_3.fullContact) : 'Not Selected'}
                                </td>
                                <td>
                                    <ContactSearchInput
                                        titleFormatter={() => ' '}
                                        value={contact_3 ? contact_3 : {}}
                                        onSelect={(contact) => onAddParty(contact, 3)}
                                    />
                                </td>
                            </tr>
                        ) : null}
                        {parties > 3 ? (
                            <tr>
                                <td className="text-capitalize">
                                    Party 4: {contact_4 ? renderName(contact_4.fullContact) : 'Not Selected'}
                                </td>
                                <td>
                                    <ContactSearchInput
                                        titleFormatter={() => ' '}
                                        value={contact_4 ? contact_4 : {}}
                                        onSelect={(contact) => onAddParty(contact, 4)}
                                    />
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>

        );
    }
}

ModalSendAssignContacts.propTypes = {
    contacts    : PropTypes.array.isRequired,
    parties     : PropTypes.number.isRequired,
    onAddParty  : PropTypes.func.isRequired,
}

export default ModalSendAssignContacts
