/*
Documentation

this file renders a card showing all unfinished tasks for the step being viewed

*/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Col, Row, CardTitle, CardBody } from "reactstrap";
import ModalUnfinished from './ModalUnfinished';
import ModalAddTask from './ModalAddTask'
import renderName from 'utils/renderName'
import { canUpdateCases } from '_functions/users/privileges'
import AvatarImage from 'components/functional/images/AvatarImage';

import { Redirect } from 'react-router-dom'

const oneDay = 86400;
const warningInterval = 1 //number of days to show warning for

class TasksUnfinished extends Component {

    state = {
        move_to: {},
        time_now: Math.floor(new Date() / 1000),
        editing_task: null,
        showModal: false,
        showModalAddTask: false
    };

    //returns color coded text for the due date of the task
    renderDueText = (task, time_now) => {

        let color = '';
        let overdue = false

        if(task.finished) return <span className="font-weight-bold text-success">Finished</span>

        if(!task.date) return 'No Due Date ';

        if(task.date < time_now) {
            color = 'text-danger text-uppercase text-undrline';
            overdue = true
        }

        if(task.date > time_now && task.date < (time_now + (oneDay * warningInterval))) {
            color = 'text-warning';
        }

        return  <span className={`font-weight-bold ${color}`}>Task Due: {moment.unix(task.date).fromNow()}{overdue ? '!!!' : ''} </span>

    }

    //this checks every minute to update the due dates for each associated task
    setTimeInterval = () => {

        this.timeCheckInterval = setInterval(() => {
            this.setState({time_now: Math.floor(new Date() / 1000)})
        }, 60000)

    }

    componentWillUnmount = () => clearInterval(this.timeCheckInterval)
    componentDidMount = async () => this.setTimeInterval()

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { time_now, editing_task, showModal} = this.state
        const _case = this.props.case;
        const { tasks_unfinished, tasks_persistent,  current_step, step_to_show, case_workflow, forceRefresh } = this.props

        const not_current_step =  step_to_show !== _case.current_step

        const canUpdate = canUpdateCases();

        const possibleFinishedTasks = tasks_unfinished && tasks_persistent ? tasks_unfinished.concat(tasks_persistent) : null

        return (

        <>


            <Card className="card-color card-warning">

                <CardHeader>
                    <Row>

                        <Col xs={6} className="align-self-center">
                            <CardTitle className="mb-0">Step Tasks</CardTitle>
                        </Col>

                        <Col xs={6} className="text-right align-self-center">
                            {current_step ? (
                                <button
                                    disabled={not_current_step ? true : !canUpdate ? true : false}
                                    onClick={not_current_step ? null : !canUpdate ? null :  () => this.setState({showModalAddTask: true})  }
                                    className="btn btn-success btn-s"
                                >
                                    <i className="fas fa-plus-square" /> Add Task
                                </button>
                            ) : null}
                        </Col>

                    </Row>
                </CardHeader>

                {possibleFinishedTasks && possibleFinishedTasks.length ? (


                <div  className="table-responsive table-no-margin">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Task</th>
                                <th>Required</th>
                                <th>Due</th>
                                <th >Assigned To</th>
                                <th  className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {possibleFinishedTasks.length ? possibleFinishedTasks.map((t, i) => (
                                <tr key={i}>

                                    <td style={{wordBreak: 'break-word'}}>
                                        <span className="text-capitalize" style={{whiteSpace: 'pre'}}>
                                            {t.date && t.date < time_now ? <i className="mr-2 text-danger fas fa-exclamation-triangle" /> : null}
                                            {t.date && t.date > time_now && t.date < (time_now + (oneDay * warningInterval)) ? <i className="mr-2 text-info-original fas fa-info-circle" /> : null}
                                            {t.type === 'party association' ? 'Assign Party: ' : null} {t.name}
                                        </span>
                                        <br />
                                    </td>

                                    <td>{t.required ? <b className="text-success">Required</b> : 'Optional'}</td>

                                    <td>{this.renderDueText(t, time_now)}</td>

                                    <td style={{wordBreak: 'break-word'}}>
                                        {t.assigned_to && t.assigned_to.length ? t.assigned_to.map((assigned_to, i) => (
                                            assigned_to ? (
                                                <div key={assigned_to._id + i}>
                                                    {assigned_to._id === this.props.viewing_user._id ? (
                                                        <b className="text-success text-underline">YOU</b>
                                                    ) : (
                                                        <div>
                                                            <AvatarImage className="mr-2" src={assigned_to.avatar} />
                                                            <span className="text-capitalize">{renderName(assigned_to)} </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <span key={i} className="text-danger">
                                                    <i className=" mr-1 fas fa-exclamation-triangle"></i>
                                                    No User Assigned
                                                </span>
                                            )
                                        )): (
                                            <span className="text-danger">
                                                <i className=" mr-1 fas fa-exclamation-triangle"></i>
                                                No User Assigned
                                            </span>
                                        )}
                                    </td>

                                    <td className="text-right">
                                        <button
                                            disabled={not_current_step ? true : !canUpdate ? true : false}
                                            onClick={not_current_step ? null : !canUpdate ? null :  () => {
                                                this.setState({ editing_task: t._id, showModal: true, action: 'add'})
                                            }}
                                            className="btn btn-outline-success btn-s my-0"
                                        >
                                            <i className="fas fa-edit mr-1" /> Update
                                        </button>
                                    </td>
                                </tr>

                            )) : (
                                <tr>
                                    <td className="font-weight-bold text-warning">There are no open tasks, move to next step</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                ) : (
                    <CardBody>
                        <p className="text-sm mb-0">There are no more required tasks to complete before this step is ready to be moved.</p>
                    </CardBody>
                )}


            </Card>


            <ModalUnfinished
                case={_case}
                case_workflow_id={case_workflow._id}
                editing_task={tasks_unfinished.find(t => t._id === editing_task) || {}}
                showModal={showModal}
                toggleModal={() => this.setState({showModal: false})}
                forceRefresh={forceRefresh}
            />

            <ModalAddTask
                current_step={current_step}
                step={this.props.case.current_step}
                case={_case}
                showModal={this.state.showModalAddTask}
                toggle={() => this.setState({showModalAddTask: false})}
            />

        </>

        )

    }

}

TasksUnfinished.propTypes = {
    case                : PropTypes.object.isRequired,
    case_workflow       : PropTypes.object.isRequired,
    tasks_unfinished    : PropTypes.array.isRequired,
    tasks_persistent    : PropTypes.array.isRequired,

    // these may be empty on initial case creation where we are not on a step
    current_step        : PropTypes.object,
    step_to_show        : PropTypes.string,
}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(TasksUnfinished);
