/*
Documentation

This fill looks for attachment son and email and if they exists
renders them at the bottom of an email body in the CenterContent component

*/

import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CardFooter } from 'reactstrap';
import Dots from 'components/markup/loading/Dots';

import { architeckCall } from 'database';

// name is the file name of the document to download
// bytes is the actual content of the file to download
function downloadDocument(name, bytes) {
    const linkSource = `data:application/pdf;base64,${bytes}`;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

const imageTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
]

// renders an attachment as an equally sized square (not a 1x1 but close enough)
// if we have an image we show the image if not we show an icon to represent
// what the attachment is
const AttachmentBody = (props) => (
    <span className={props.isImage ? 'p4 z-depth-1 cursor-pointer attachment' : 'p4 z-depth-1 attachment'} >
        <span
            onClick={props.isImage ? () => props._this.setState({isOpen: true, photoIndex: props.i, imageTitle: props.attachment.name})  :null }
            className={props.isImage ? "attachment-img" : "attachment-img text-center icon-container"}
            style={{ backgroundImage: props.isImage ?  'url("data:image/png;base64, '+props.attachment.contentBytes+'")' : null }}
        >
            {props.icon ? props.icon : null}
        </span>

        <span className="attachment-details"  onClick={props.isImage ? () => props._this.setState({isOpen: true, photoIndex: props.i, imageTitle: props.attachment.name}) : null} >
            <p>{props.attachment.name}</p>
        </span>

        <span className="attachment-download cursor-pointer" onClick={() => downloadDocument(props.attachment.name, props.attachment.contentBytes)}>
            <p><i className="fas fa-download mr-2 text-warning" /> <span>Download</span></p>
        </span>
    </span>
)

class MessagingCenterHandleEmail extends Component {

    state = {
        value         : null,
        isOpen        : false,
        canMarkRead   : true,
        images        : [],
        contentAttachments        : null,
    }

    renderAttachment = (attachment, i) => {

        if(imageTypes.includes(attachment.contentType)) {

            return (
                <AttachmentBody
                    _this={this}
                    key={attachment.id}
                    attachment={attachment}
                    i={i}
                    isImage={true}
                />
            )

        } else {

            return (
                <AttachmentBody
                    _this={this}
                    key={attachment.id}
                    attachment={attachment}
                    i={i}
                    isImage={false}
                    icon={
                        attachment.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? <i className="far fa-file-word"  /> :
                        attachment.contentType === 'application/pdf' ? <i className="far fa-file-pdf"  /> :
                        <i className="fas fa-file"  />
                    }
                />

            )

        }

    }

    setAttachments = async (obj) => {

        if(obj && obj.attachments_link) {

            const attachments = await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'post',
                url: `/api/v1/core/microsoft/emails/getAttachments`,
                data: { attachments_link: obj.attachments_link }
            })

            // make sure that fetching attachments worked and we are still on the correct email view
            if(attachments.success) {

                let allAttachments = attachments.data.value
                let imageArray = []

                // for each attachment if it is an image we push it to the image array
                // this array gets rendered inside react-lightbox
                if(allAttachments && allAttachments.length) {

                    allAttachments.forEach(a => {
                        if(imageTypes.includes(a.contentType)) {
                            imageArray.push('data:image/png;base64, '+a.contentBytes)
                        }
                    })

                }

                this.setState({images: imageArray, contentAttachments: attachments.data.value})

            }

        }

    }

    componentWillReceiveProps = (nextProps) => {

        // only fetch attachments if the content (AKA the email to view) changes
        if(nextProps.content && nextProps.content._id && nextProps.content._id !== this.props.content._id) {

            // reset attachments to load new ones if applicable
            this.setState({contentAttachments: null})
            this.setAttachments(nextProps.content)
        }

    }

    componentDidMount = () => {
        this.setAttachments(this.props.content)
    }

    render() {

        const { content } = this.props;
        const { photoIndex, isOpen, images, imageTitle, contentAttachments } = this.state

        return (

            <>

            {contentAttachments ? (
                    contentAttachments.length ? (
                        <CardFooter className="pt-5">
                            {contentAttachments.map((attachment, i) => (
                                this.renderAttachment(attachment, i)
                            ))}
                        </CardFooter>
                    ) :   null
                ) : content.attachments_link ? (
                    <CardFooter> <p className="text-sm font-weight-bold mb-0 text-warning">Checking For Attachments<Dots /></p></CardFooter>
            ) : null}

            {isOpen && (
                <Lightbox
                    imageTitle={imageTitle}
                    imageLoadErrorMessage="This document cannot be rendered as an image."
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() => this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length, }) }
                    onMoveNextRequest={() => this.setState({ photoIndex: (photoIndex + 1) % images.length, })
                    }
                />
            )}

            </>

        )

    }

}

MessagingCenterHandleEmail.propTypes = {
    content : PropTypes.object.isRequired,
}

export default MessagingCenterHandleEmail
