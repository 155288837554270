/*
Documentation

This file renders 2 cards on the garnishment view page
The first card is a standard document uploader that will then attach the document to the garnishment
The second card shows all documents associated with the garnishment being viewed

*/

import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import PropTypes from 'prop-types';
import { toggleAlertBS } from 'store/functions/system/system';

import onDownloadDocument from '_functions/documents/download'

class GarnishmentDocuments extends Component {

    onUploadStarted = () => {
        toggleAlertBS(false, `Your documents are queued for upload and will appear here once they have been processed.`)
    }

    //split the key by '.' and then return the end. this should be the file type
    getType = (obj) => {

        const key = obj.object_key;

        let splitArray = key.split('.')
        let ending = splitArray[splitArray.length - 1]

        return ending

    }

    render() {

        const documents = this.props.garnishment.documents || []
        const { case_id, contact_id, garnishment } = this.props

        return (

            <>

            {contact_id ? (
                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">Upload Garnishment Document</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <DragAndDrop
                            zone_id="documents_1"
                            url={`/api/v1/core/documents/create`}
                            onUploadStarted={this.onUploadStarted}
                            onError={(e) => console.log(e)}
                            extraData={{ 
                                case: case_id, 
                                contact: contact_id,
                                finishType: 'garnishment', 
                                garnishmentId: garnishment._id
                            }}
                        />
                    </CardBody>

                </Card>
            ) : null}

            <Card  className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Garnishment Documents</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <td className="font-weight-bold">File Name</td>
                                <td className="font-weight-bold">Type</td>
                                <td className="font-weight-bold text-right">Uploaded on (GMT)</td>
                            </tr>
                        </thead>

                        <tbody>
                            {documents.length ? documents.map(doc => (
                                <tr key={doc._id}>
                                    <td style={{width: '50%'}}>
                                        <span className="cursor-pointer" onClick={() => onDownloadDocument(doc)}>
                                            <i className="mr-3 fas fa-download text-warning" />
                                            {doc.friendly_name}
                                        </span>
                                    </td>
                                    <td className="text-uppercase">{this.getType(doc)}</td>
                                    <td className="text-right">{moment.utc(doc.created_at, 'X').format("MM/DD/YYYY")}</td>
                                </tr>
                            )) : (
                                documents ? (
                                    <tr>
                                        <td style={{whiteSpace: 'pre'}}>No garnishment documents have been uploaded.</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ) : null
                            )}
                        </tbody>

                    </table>
                </div>

            </Card>

        </>

        )

    }

}

GarnishmentDocuments.propTypes = {
    garnishment   : PropTypes.object.isRequired,
    case_id       : PropTypes.string.isRequired,
    contact_id    : PropTypes.string.isRequired
}

export default GarnishmentDocuments
