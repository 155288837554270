import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Navigation from './Navigation'
import Info from './Info'
import Docs from './Docs'
import Questionnaire from './Questionnaire'
import CreditCounseling from './CreditCounseling'
import Plan from './Plan'

import _cases from '_functions/cases';
import _questionnaire_answers from '_functions/questionnaire_answers';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import Circle from 'components/markup/loading/Circle'

const StepsToPrep = ({questionnaires, _case, missing_docs, contact, viewing_user, workflow}) => {

    const questionnaire_id = workflow ? workflow.questionnaire : null

    const [foundCase, setFoundCase] = useState(null);

    const [nav, setNav] = useState('info')
    const [infoProgress, setInfoProgress] = useState(0)
    const [questionnaireAnswers, setQuestionnaireAnswers] = useState(null)

    const [completedDocs, setCompletedDocs] = useState([]);
    const [missingDocs, setMissingDocs] = useState([]);
    const [questionnaireDoc, setQuestionnaireDoc] = useState(null);
    const [creditCounselingDoc, setCreditCounselingDoc] = useState(null);

    const filterSpecialDocs = useCallback((docs) => {
        return docs.filter(doc => doc.name !== 'Questionnaire' && doc.name !== 'Questionnaire ' && doc.name !== 'Credit Counseling Certificate');
    }, [])

    const trimDocName = useCallback((doc) => {
        return doc.name && doc.name.length > 22 ? doc.name.slice(0, 22) + '...' : doc.name;
    }, [])

    const onDocStatusChange = useCallback(async (doc, value) => {
        let _completedDocs = JSON.parse(JSON.stringify(completedDocs));
        let _missingDocs = JSON.parse(JSON.stringify(missingDocs));

        _completedDocs = _completedDocs.filter(d => d._id !== doc._id)
        _missingDocs = _missingDocs.filter(d => d._id !== doc._id)
        if(value === 'n/a' || value === 'completed' ) {
            _completedDocs.unshift(doc)
        } else {
            _missingDocs.unshift(doc)
        }
        doc.status = value;
        doc.status_marked_at = Math.floor(new Date() / 1000);
        doc.status_marked_by = viewing_user._id;

        setCompletedDocs(filterSpecialDocs(_completedDocs));
        setMissingDocs(filterSpecialDocs(_missingDocs));

      

        let allDocs = [..._missingDocs, ..._completedDocs];

        if(doc.name === 'Questionnaire' || doc.name === 'Questionnaire ') {
            allDocs.push(doc);
            setQuestionnaireDoc(doc)
        } else if(questionnaireDoc) {
            allDocs.push(questionnaireDoc)
        }
        
        if(doc.name === 'Credit Counseling Certificate') {
            allDocs.push(doc);
            setCreditCounselingDoc(doc);
        } else if(questionnaireDoc) {
            allDocs.push(creditCounselingDoc)
        }

        _cases.update(foundCase._id, { missing_docs: allDocs })

    }, [completedDocs, missingDocs, questionnaireDoc, creditCounselingDoc, viewing_user._id])

    const onMarkDocNaOnNo  = useCallback((question, value) => {
        const doc = missing_docs.find(d => d._id === question.mark_doc_na_on_no);
        if(doc && foundCase) {
            const missingDoc = foundCase.missing_docs.find(d => d.name === doc.name);
            if(missingDoc) {
                onDocStatusChange(missingDoc, 'n/a')
            }
        }
    }, [onDocStatusChange, foundCase, missing_docs])

    const onMissingDocAdded = useCallback((data) => {
        if(data && data.data && data.data.document && data.data.document.case === _case._id) {
            const _missingDocs = JSON.parse(JSON.stringify(missingDocs))
            const foundDoc = _missingDocs.find(d => d.name === data.data.templateDocName);
            if(foundDoc) {
                foundDoc.documents.push(data.data.document)
                setMissingDocs(_missingDocs)
            } else {

                const foundQuestionnaireDoc = questionnaireDoc && questionnaireDoc.name === data.data.templateDocName
                if(foundQuestionnaireDoc) {
                    let _questionnaireDoc = JSON.parse(JSON.stringify(questionnaireDoc))
                    _questionnaireDoc.documents.push(data.data.document)
                    setQuestionnaireDoc(_questionnaireDoc)
                } else {
                    const foundCreditCounselingDoc = questionnaireDoc && questionnaireDoc.name === data.data.templateDocName
                    if(foundCreditCounselingDoc) {
                        let _creditCounselingDoc = JSON.parse(JSON.stringify(creditCounselingDoc))
                        _creditCounselingDoc.documents.push(data.data.document)
                        setCreditCounselingDoc(_creditCounselingDoc)
                    }
                }
            }

        }
    }, [_case._id, missingDocs, questionnaireDoc])

    const onSetInfoProgress = useCallback((_questionnaireAnswers) => {

        const answers = _questionnaireAnswers ? _questionnaireAnswers : questionnaireAnswers
        const questionnaire = questionnaires.find(q => q._id === questionnaire_id);

        if(!questionnaire) return  setInfoProgress(100);

        let requiredQuestions = 0;
        let answeredQuestions = 0;

        const checkQuestions = (_questions) => {
            _questions.forEach(question => {
                if(question.required === true) requiredQuestions++;
                if(answers[question._id] && answers[question._id] !== 'false') {
                    answeredQuestions++;
                } else if(question.map_field && contact[question.map_field]) {
                    answeredQuestions++;
                }
                if(question.questions && question.type === 'yes - no' && answers[question._id] === 'yes' ) {
                    checkQuestions(question.questions)
                }
            })
        }

        checkQuestions(questionnaire.questions);

        const progress = Math.round((answeredQuestions / requiredQuestions) * 100);
        setInfoProgress(progress)
        return progress;

    }, [questionnaires, questionnaireAnswers, questionnaire_id])

    const fetchCase = useCallback(async () => new Promise (async resolve => {
        let foundCase = await _cases.findByIdWithStep(_case._id, true);
        let _completedDocs = [];
        let _missingDocs = [];
        let _questionnaireDoc = null;
        let _creditCounselingDoc = null;

        if(foundCase.data) {
            setFoundCase(foundCase.data);

            _questionnaireDoc = foundCase.data.missing_docs.find(d => d.name === 'Questionnaire' || d.name === 'Questionnaire ');
            _creditCounselingDoc = foundCase.data.missing_docs.find(d => d.name === 'Credit Counseling Certificate');

            foundCase.data.missing_docs = filterSpecialDocs(foundCase.data.missing_docs)

            _completedDocs = foundCase.data.missing_docs.filter(d => d.status === 'n/a' || d.status === 'completed');
            _missingDocs = foundCase.data.missing_docs.filter(d => !d.status || d.status === 'missing');
        }

        setCompletedDocs(_completedDocs);
        setMissingDocs(_missingDocs);
        setQuestionnaireDoc(_questionnaireDoc)
        setCreditCounselingDoc(_creditCounselingDoc)

        return resolve({case: _case.data, missingDocs: _missingDocs, completedDocs: _completedDocs, questionnaireDoc: _questionnaireDoc});
    }) , [_case._id])

    const fetchQuestionnaireAnswers = useCallback(async () => new Promise (async resolve => {
        let defaultAnswers = {};
        let progress = 0
        if(questionnaire_id) {
            const foundAnswers = await _questionnaire_answers.findByContact(questionnaire_id, contact._id)
            defaultAnswers = foundAnswers.data && foundAnswers.data.answers ? foundAnswers.data.answers : {};
            progress = onSetInfoProgress(defaultAnswers)
        }
            
        setQuestionnaireAnswers(defaultAnswers)
        setQuestionnaireAnswers({})

        return resolve({defaultAnswers, progress});

    }), [questionnaire_id, contact._id, onSetInfoProgress]);

    const fetchData = useCallback(async () => {
        const values = await Promise.all([
            fetchCase(),
            fetchQuestionnaireAnswers(),
        ])

        let nav = 'info';

        if(values[1].progress === 100 || !questionnaire_id) {
            nav = 'plan';

            if(values[0].missingDocs.length === 0) {
                nav = 'docs'

                if(values[0].questionnaireDoc) {
                    if(values[0].questionnaireDoc.status === 'n/a' || values[0].questionnaireDoc.status === 'completed') {
                        nav = 'ccc'
                    }
                } else {
                    nav = 'ccc'
                }

            }

        }

        setNav(nav)
    }, [fetchCase, fetchQuestionnaireAnswers, questionnaire_id])

    useEffect(() => {
        if(questionnaires && questionnaires.length && !questionnaireAnswers) {
            fetchData();
        }
    }, [fetchQuestionnaireAnswers, questionnaires, questionnaireAnswers])

    useEffect(() => {
        io.on(socketEvents.case_template_doc_added, onMissingDocAdded);
        return () => {
            io.off(socketEvents.case_template_doc_added, onMissingDocAdded)
        }

    }, [onMissingDocAdded])

    if(!foundCase) return <div className="py-6"><Circle /></div>
    
    return (
        <>
     
            <Navigation 
                nav={nav}
                setNav={setNav}
                missingDocs={missingDocs}
                completedDocs={completedDocs} 
                infoProgress={infoProgress}
                questionnaireDoc={questionnaireDoc}
                creditCounselingDoc={creditCounselingDoc}
                questionnaire_id={questionnaire_id}
            />

            {nav === 'info' ? (
                <Info 
                    foundCase={foundCase}
                    questionnaire_id={questionnaire_id}
                    onSetInfoProgress={onSetInfoProgress}
                    onMarkDocNaOnNo={onMarkDocNaOnNo}
                />
            ) : nav === 'plan' ? (
                <Plan 
                    foundCase={foundCase}
                    contact={contact}
                />
            ) : nav === 'docs' ? (
                <Docs 
                    foundCase={foundCase} 
                    missingDocs={missingDocs} 
                    completedDocs={completedDocs} 
                    onDocStatusChange={onDocStatusChange} 
                    case_id={_case._id} 
                    trimDocName={trimDocName} 
                />
            ) : nav === 'questionnaire' ? (
                <Questionnaire 
                    foundCase={foundCase} 
                    questionnaireDoc={questionnaireDoc} 
                    case_id={_case._id} 
                    onDocStatusChange={onDocStatusChange} 
                    trimDocName={trimDocName} 
                />
            ) : nav === 'ccc' ? (
                <CreditCounseling 
                    foundCase={foundCase} 
                    creditCounselingDoc={creditCounselingDoc} 
                    case_id={_case._id} 
                    onDocStatusChange={onDocStatusChange} 
                    trimDocName={trimDocName} 
                />
            ) : null}

        </>
    )
}


const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires.questionnaires,
        divisions: state.divisions.divisions,
        missing_docs: state.missing_docs.missing_docs,
        viewing_user: state.auth.viewing_user,
        workflows: state.workflows.workflows,
    };
};

export default connect(mapStateToProps, '')(StepsToPrep);