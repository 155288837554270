/*
Documentation

This file renders a card and button to resolve an objection

*/

import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import _objections from '_functions/objections';
import PropTypes from 'prop-types';

class ObjectionResolve extends React.Component {

    onResolve = async () => {

        _objections.update(this.props.objection._id, {resolved_by: this.props.viewing_user._id})

    }

    render() {

        const { objection } = this.props

        return (
        <>
            {!objection.resolved_by ? (
                <Card>

                    <CardHeader>
                        <CardTitle>Resolve Objection</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-check mr-2 text-success" />
                        This objection is ready to be resolved and marked as completed.</p>
                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={this.onResolve} className="btn btn-success">
                            <i className="fas fa-check mr-2" />
                            Mark As Resolved
                        </button>
                    </CardFooter>

                </Card>
            ) : null}

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ObjectionResolve.propTypes = {
    objection: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(ObjectionResolve);
