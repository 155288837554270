import React, { useState, useEffect, useCallback } from 'react';
import AvatarImage from 'components/functional/images/AvatarImage';
import PropTypes from 'prop-types';
import renderName from 'utils/renderName';
import renderNameInitial from 'utils/renderNameAndInitial';
import FeedWrapper from './Wrapper';
import { Badge } from 'reactstrap';
import ReactHTMLParser from 'react-html-parser';
import _microsoft from '_functions/microsoft';
import { stripHTML } from 'utils/code';

import { getEntryDate } from './_utils';

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/pdf"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
};

const onDownload = (name, data) => {
    var sampleArr = base64ToArrayBuffer(data);
    saveByteArray(name, sampleArr);
}

const RenderText = ({entry, renderNameAndInitial}) => {

    const [showBody, setShowBody] = useState(false);
    const [attachments, setAttachments] = useState([])

    const toggleShowBody = async () => {
        setShowBody(!showBody);

        // if(!showBody && entry.attachments_link) {
        //     const foundAttachments = await _microsoft.getAttachments({attachments_link: entry.attachments_link});
        // }
    }

    const fetchAttachments = useCallback( async () => {
        if(entry.attachments_link) {
            const foundAttachments = await _microsoft.getAttachments({attachments_link: entry.attachments_link});
            if(foundAttachments.data && foundAttachments.data.value) setAttachments(foundAttachments.data.value);
        }
    }, [])

    const getName = (name) => {

        let toReturn = name;

        if(name && name.length > 25) {
            const first = name.slice(0,10);
            const last = name.slice(name.length - 15, name.length);
            toReturn = first + '......' + last;
        }

        return toReturn

    }

    useEffect(() => {
        fetchAttachments()
    }, [fetchAttachments])

    const strippedValue = stripHTML(entry.value)

    return (
        <FeedWrapper className={entry.outgoing ? 'incoming-email' : 'outgoing-email'}>
            <p className="text-sm mb-0">{getEntryDate(entry)}</p>

            <p className="text-sm mb-2 font-weight-bold mt--2"  style={{wordBreak: 'break-word'}}>


                {entry.user ? (
                    <span className="text-capitalize">
                        <AvatarImage style={{position: 'relative', top: -2, width: 30, height: 30}} src={entry.user.avatar} className="mr-2" />
                        {renderNameAndInitial ? renderNameInitial(entry.user) : renderName(entry.user)}
                    </span>
                ) : (
                    entry.from ? entry.from : 'System'
                )}

                <div>
                    
                </div>
            </p>

            <h6 className="text-sm mb-0 font-weight-bold"  style={{wordBreak: 'break-word'}}>
                {entry.subject}
            </h6>


            <hr className="my-2" />

            {/* <button className="btn btn-sm btn-primary my-3" onClick={toggleShowBody}>Show / Hide</button> */}

            {showBody ? (
                <div style={{wordBreak: 'break-word'}}  className="text-sm mb-0">
                    {ReactHTMLParser(entry.value.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>'))}
                    {strippedValue && strippedValue.length > 60 ? (
                        <Badge onClick={toggleShowBody} color="warning" className="cursor-pointer">Hide</Badge>
                    ) : null}
                </div>
            ) : (
                <div style={{wordBreak: 'break-word'}}  className="text-sm mb-0">
                    {strippedValue && strippedValue.length > 60 ? (
                        <span>
                            {strippedValue.slice(0, 60)}...
                            <Badge onClick={toggleShowBody} color="info" className="cursor-pointer text-white ml-3">Show More</Badge>
                        </span>
                    ) : strippedValue}
                </div>
            )}

            {attachments.length ? (
                <div>
                    <hr className="my-2" />

                    {attachments.map(attachment => (
                        <p 
                            className="text-sm mb-0 link" 
                            key={attachment.id} 
                            onClick={() => onDownload(attachment.name, attachment.contentBytes)}
                        >
                            <i className="fas fa-paperclip mr-2 " />
                            {getName(attachment.name)}
                        </p>
                    ))}
                </div>
            ) : null}

            {/* {entry.web_link ? (
                <div>
                    <hr className="my-2"/>
                    <p className="text-sm mb-0">
                        <a 
                            className={entry.outgoing ? "font-weight-bold mr-5" : 'font-weight-bold mr-5 text-white'}
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href={`/dashboard/messaging/emails/view/${entry.outgoing ? entry.from : entry.to}/${entry.microsoft_message_id}`}
                        >
                            <i className="fas fa-eye" /> View Email
                        </a>
                        <a className="text-success font-weight-bold" target="_blank" rel="noopener noreferrer" href={entry.web_link}>
                            <i className="fas fa-door-open" /> Open Outlook
                        </a>
                    </p>
                </div>
            ) : null } */}

        </FeedWrapper>
    )
}


RenderText.propTypes = {
    entry   : PropTypes.object.isRequired,
}

export default RenderText
