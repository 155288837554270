import React, { useState, useCallback } from 'react';
import { FormGroup, Input } from 'reactstrap';

const ArchkNumber = ({label, onChange}) => {

    const [queryType, setQueryType] = useState('Equal To')
    const [value, setValue] = useState('')

    const setValueToSend = useCallback((value, type) => {

        setValue(value);

        if(!value) return onChange(undefined);

        if(type === 'Equal To') {
            onChange(parseFloat(value))
        } else if(type === 'Greater Than') {
            onChange({$gt: parseFloat(value)})
        } else if (type === 'Greater Than Or Equal To') {
            onChange({$gte: parseFloat(value)})
        } else if(type === 'Less Than') {
            onChange({$lt: parseFloat(value)})
        } else if (type === 'Less Than Or Equal To') {
            onChange({$lte: parseFloat(value)})
        }

    }, [onChange])

    const onSetQueryType = useCallback((type) => {
        setValueToSend(value, type);
        setQueryType(type)
    }, [value, onChange])

    return (

        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input
                className="mb-2"
                type="select"
                value={queryType}
                onChange={(e) => onSetQueryType(e.target.value)}
            >
                <option value="Equal To">Is Equal To</option>
                <option value="Greater Than">Is Greater Than</option>
                <option value="Greater Than Or Equal To">Is Greater Than Or Equal To</option>
                <option value="Less Than">Is Less Than</option>
                <option value="Less Than Or Equal To">Is Less Than Or Equal To</option>
            </Input>
            
            <Input
                value={value || ''}
                type="number"
                onChange={(e) => setValueToSend(e.target.value, queryType)}
            />

        </FormGroup>

    )

}

export default ArchkNumber;
