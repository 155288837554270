/*
Documentation

this file allows a user to update missing document

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup } from 'reactstrap';

import moment from 'moment';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import _cases from '_functions/cases';

class ModalBulkUpdate extends React.Component {

    state = {
        documentFolder      : null,
        missingDocOptions : [],
        confirmDelete       : '',
        missingDoc: {},
    }

    onMoveDocuments = async () => {

        let missing_docs = [...this.props.case.missing_docs];

        const found_doc = missing_docs.find(doc => doc._id === this.state.missingDoc._id)

        if(!found_doc) return;

        this.props.documentsForAction.forEach(document => {

            found_doc.documents.push(document._id)
        })

        _cases.update(this.props.case._id, { missing_docs })

        this.props.toggleModal();
        this.props.onDocsAssigned({timestamp: Math.floor(new Date() / 1), missing_docs});


    }

    componentDidMount = () => {

        let missingDocOptions = []

        this.props.case.missing_docs.forEach(doc => {
            missingDocOptions.push({value: doc, label: doc.name})
        })

        this.setState({missingDocOptions})

    }

    render() {

        const { showModal, toggleModal, documentsForAction, documentFolders } = this.props
        const { missingDocOptions } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
                id="modal-update-document"
            >
                <div className="modal-header">
                    <h5 className="modal-title" >Assign To Missing Doc</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th style={styles.cellLeft}>File Name</th>
                                <th>Folder</th>
                                <th style={styles.cellRight} className="text-right">Created At</th>
                            </tr>
                        </thead>

                        <tbody>
                            {documentsForAction && documentsForAction.length ? documentsForAction.map(doc => {

                                const folder = documentFolders.find(d => d._id === doc.document_folder )

                                return (
                                    <tr key={doc._id}>
                                        <td style={styles.cellLeft}>
                                            <i className="fas fa-file mr-2 text-warning " />
                                            {doc.friendly_name}
                                        </td>
                                        <td className="text-capitalize">
                                            {folder ? folder.name : <span className="text-warning font-weight-bold">* Root Folder</span>}
                                        </td>
                                        <td style={styles.cellRight} className="text-right">
                                            {moment.unix(doc.created_at).format('M/D/YY - h:mm A')}
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        </tbody>

                    </table>
                </div>
                   
                 <div className="modal-body border-top">
                    <FormGroup>
                        <ReactSelect
                            placeholder="Select Missing Doc"
                            onChange={(values) => this.setState({missingDoc: values.value}) }
                            options={missingDocOptions}
                        />
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    <button onClick={this.onMoveDocuments} className="btn btn-success">
                        <i className="fas fa-save mr-2" /> Update Document
                    </button>
                </div>

            </Modal>

        );
    }
}

const styles = {
    cellLeft: {
        paddingLeft: 16
    },
    cellRight: {
        paddingRight: 16
    }
}

ModalBulkUpdate.propTypes = {
    showModal         : PropTypes.bool.isRequired,
    toggleModal       : PropTypes.func.isRequired,
    onSuccess         : PropTypes.func.isRequired,
    documentFolders   : PropTypes.array.isRequired,
    document          : PropTypes.object,
}

export default ModalBulkUpdate
