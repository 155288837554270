import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup } from "reactstrap";
import _template_word_docs from '_functions/template_word_docs';

import { toggleAlertBS } from 'store/functions/system/system';

import ModalConfirmGeneration from './modals/ModalConfirmGeneration'


class CaseDocumentGenerate extends React.Component {

    state = {
        selectOptions: [],
        selectedDoc: null,
        showModal: false,
        loading: false,
        fields: [],
    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})


    onGenerate = async () => {
        
        const case_id = this.props.case._id;
        const template_word_doc_id = this.state.selectedDoc._id;

        const fields = await _template_word_docs.fields(template_word_doc_id, case_id)
        if(fields.success) {
            this.setState({fields: fields.data.fields, errors: fields.data.errors, showModal: true});
        }

    }

    onConfirmGeneration = async (fields) => {

        this.setState({loading: true})
        const generated = await _template_word_docs.generate(this.state.selectedDoc._id, fields)
        this.setState({loading: false})
        if(!generated.success) {
            toggleAlertBS(true, generated.message && generated.message[0] ? generated.message[0] : `An error occurred generating this document.`)
        } else {
            this.toggleModal()
        }

    }

    componentDidMount = async () => {

        let selectOptions = []

        this.props.template_word_docs.forEach(doc => {
            selectOptions.push({label: doc.name, value: doc})
        })

        this.setState({selectOptions})

    }

    render() {

        const { selectedDoc, selectOptions, showModal, fields, errors, loading } = this.state;

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Document Generation</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <label className="form-control-label">Select the template doc you wish to generate from the list below.</label>
                        <ReactSelect
                            onChange={(values) => this.setState({selectedDoc: values.value})}
                            options={selectOptions}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter >
                    <button disabled={selectedDoc ? false : true} className="btn btn-success btn-block" onClick={this.onGenerate} >
                        Generate Document
                    </button>
                </CardFooter>

                <ModalConfirmGeneration 
                    onConfirmGeneration={this.onConfirmGeneration}
                    toggleModal={this.toggleModal}
                    showModal={showModal}
                    errors={errors}
                    fields={fields}
                    loading={loading}
                />

            </Card>

            </>

        );
    }
}

CaseDocumentGenerate.propTypes = {
    case: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
    	template_word_docs: state.template_word_docs.template_word_docs,
    };
};

export default connect(mapStateToProps, '')(CaseDocumentGenerate);
