import React from "react";
import { Modal } from "reactstrap";
import { FormGroup, Input } from 'reactstrap';


class ModalValidation extends React.Component {

    state = {
       reason: '',
       err: false
    }

    toggleModal = () => {
        this.setState({reason: '', err: false})
        this.props.toggleModal();
    }

    onValidate = () => {

        const reason = this.state.reason;

        if(reason.length < 10) return this.setState({err: true})

        this.props.onValidationConfirmed(reason);
        this.toggleModal();

    }
  
    render() {

        const { showModal } = this.props;
        const { reason, err } = this.state;

        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                size="lg"
                zIndex={100001}
                toggle={this.toggleModal}
            >
                <div className="modal-header">
                    <h5 className="modal-title">Confirm Appointment Creation</h5>
                </div>

                <div className="modal-body">

                    <p className="text-sm"><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Initial Consultations, Mini Hydras, and Hydra appointments should only be scheduled when moving a case's step along. If there is an unique circumstance please write the reason below to be submitted for review.</p>

                    <FormGroup>
                        <label className="form-control-label">Reason For Appointment Creation:</label>
                        <Input 
                            type="textarea"
                            value={reason}
                            onChange={(e) => this.setState({reason: e.target.value})}
                        />
                    </FormGroup>

                    {err ? <p className="text-sm font-weight-bold text-danger mb-0">Please write a valid reason of at least 10 characters</p> : null}

                </div>

                <div className="modal-footer">
                    <button onClick={this.toggleModal} className="btn btn-success"><i className="fas fa-arrow-left mr-2" />Cancel</button>
                    <button onClick={this.onValidate} className="btn btn-warning">Create And Submit</button>
                </div>
            </Modal>
        )

    }
}

export default ModalValidation
