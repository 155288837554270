/*
Documentation

This file creates a filter that can be used to query appointments

*/

import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import moment from 'moment';
import React from "react";
import DatePicker from 'react-datepicker';
import { Col, FormGroup, Row } from "reactstrap";
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import UserSearchInput from 'views/dashboard/pages/_components/UserSearchInput';
import _appointments from '_functions/appointments';
import { connect } from 'react-redux';

const filter_array = [
    'confirmed',
    'contact',
    'assigned_to',
    'date'
]

class AppointmentsFilter extends React.Component {

    state = {

        limit: 500,
        query: {},

        dateError: false,
        moreDocs: false,
        type: null
    }



    // set query to be empty and run it
    resetQuery = () => this.setState({query: {}}, () => this.runQuery())

    runQuery = async () => {

        this.setState({dateError: false, moreDocs: false})

        const query = this.state.query;

        let filter;
        let errors = 0;

        // base sorting options
        let string = `?populate=assigned_to contact location_hearing location_hearing_trustee&sort=date__asc&limit=${this.state.limit}`;

        filter_array.forEach(field => {

            // set the value of the field
            let value = query[field]

            if(value) {

                if(value === 'no') value = `false{bool}`
                if(value === 'yes') value = `true{bool}`

                if(field === 'contact') value = value._id;
                if(field === 'assigned_to') value = value._id + '{in}';

                if(field === 'date') {

                    let time_start = parseInt(moment(value).format('X'))
                    let time_end = parseInt(moment.unix(time_start).endOf('day').format('X'))

                    if(query.date_end && query.range === 'yes') {
                        time_end = parseInt(moment(query.date_end).endOf('day').format('X'))
                    }

                    // can query an end date before the start date
                    if(time_end < time_start) {
                        this.setState({dateError: true});
                        errors++
                    }

                    value = `${time_start} ${time_end}{between}`

                }

                if(!filter) {
                    filter = `&filter=${field}__${value}`
                } else {
                    filter += `|${field}__${value}`
                }

            }

        })

        // if we have a filter add it to the query string
        if(filter) string += filter;

        if(!errors) {

            const appointments = await _appointments.find(string);
            if(appointments.success) {

                let data = appointments.data;

                if(this.state.type && this.state.type !== 'null') {

                    const foundGrouping = this.props.groupings.find(g => g._id === this.state.type);
                    data = data.filter(d => foundGrouping.documents.includes(d.template_appointment))

                }

                this.props.onFilter(data)
                if(appointments.total_documents > appointments.returned_documents) {
                    this.setState({moreDocs: true})
                }

            } else {

                if(this.props.onError) this.props.onError(appointments)

            }

       }

    }

    onInputChange = (value, name) => {

        let query = Object.assign({}, this.state.query);
        query[name] = value;

        if(name === 'date' && !query.date_end) query.date_end = value

        // remove the ending range if going from a range to a day
        if(name === 'range' && value === 'no') delete query.date_end

        this.setState({query})

    }

    componentDidMount = async () => {

        this.runQuery()

    }

    render() {


        const query = this.state.query;
        const grouping = this.props.groupings;

        return (
            <>

                <Row>

                    <Col lg={4}>

                        <ContactSearchInput
                            value={this.state.query.contact || {}}
                            onSelect={(contact) => this.onInputChange(contact, 'contact')}
                        />

                        <UserSearchInput
                            value={this.state.query.assigned_to || {}}
                            onSelect={(user) => this.onInputChange(user, 'assigned_to')}
                        />

                    </Col>

                    <Col lg={4}>

                        <StandardFormGroup
                            obj={{type: this.state.type}}
                            objName="type"
                            onChange={(o, f, v) => this.setState({type: v})}
                            type="select"
                            field="type"
                            title="Appointment Type"
                        >
                            <option value="null"></option>
                            {grouping.map((grouping, i) => (
                                <option key={i} value={grouping._id}>{grouping.name}</option>
                            ))}
                        </StandardFormGroup>

                        <StandardFormGroup
                            obj={query}
                            objName="query"
                            onChange={(o, f, v) => this.onInputChange(v, f)}
                            type="select"
                            field="confirmed"
                            title="Was Confirmed"
                        >
                            <option></option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </StandardFormGroup>

                    </Col>

                    <Col lg={4}>

                        <StandardFormGroup
                            obj={query}
                            objName="query"
                            onChange={(o, f, v) => this.onInputChange(v, f)}
                            type="select"
                            field="range"
                            title="Date Query"
                        >
                            <option value="no">Query By Day</option>
                            <option value="yes">Query By Range</option>
                        </StandardFormGroup>

                        {query.range === 'yes' ? (
                            <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Beginning On</label>
                                    <div className="date-picker-wrapper">
                                        <DatePicker
                                            selected={query.date}
                                            onChange={date => this.onInputChange(date, 'date')}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Ending On</label>
                                    <div className="date-picker-wrapper">
                                        <DatePicker
                                            selected={query.date_end}
                                            onChange={date => this.onInputChange(date, 'date_end')}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>

                        </Row>

                        ) : (
                            <FormGroup>
                                <label className="form-control-label">Select Day</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={query.date}
                                        onChange={date => this.onInputChange(date, 'date')}
                                    />
                                </div>
                            </FormGroup>
                        )}

                    </Col>

                </Row>

                <hr />

                {this.state.dateError ? ( <div className="alert alert-danger">The date is formatted incorrectly.</div> ) : null}

                {this.state.moreDocs ? (
                    <div className="alert alert-warning">The above search returned over {this.state.limit} documents resulting in some not being shown. Refine the filters abve to narrow your search.</div>
                ) : null}

                <div className="text-right">
                    <button onClick={this.resetQuery} className="btn btn-outline-warning">Reset Query</button>
                    <button onClick={this.runQuery} className="btn btn-success">Run Query</button>
                </div>

            </>
        );
    }
}

AppointmentsFilter.propTypes = {

    onFilter: PropTypes.func.isRequired,
    onError:  PropTypes.func,

}


const mapStateToProps = state => {
    return {
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(AppointmentsFilter);
