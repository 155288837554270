/*
Documentation

*/

import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import { Card, Badge } from "reactstrap";
import { formatMoney } from 'utils/currency';
import renderAddress from 'utils/renderAddress';
import _cases from '_functions/cases';
import _contacts from '_functions/contacts';
import _payments from '_functions/payments';
import { formatPhone } from 'utils/text'
import renderName from 'utils/renderName'
import Wrapper from '../components/Wrapper'
import LetterHead from 'assets/img/brand/LetterHead.png';

class CasePayments extends React.Component {

    state = {
        contactToDelete: null,
        showModal: false,
        payment: null,
        payments: null
    }

    componentDidMount = async () => {

        const foundCase = await _cases.findById(this.props.match.params.case)
        if(!foundCase.data) return this.setState({error: 'Invalid Url'})

        const payments = await _payments.findByCase(this.props.match.params.case)
        if(!payments.success) return this.setState({error: 'An Internal Error Occurred'})

        const foundContact = await _contacts.findById(foundCase.data.contact._id)
        if(!foundContact.success) return this.setState({error: 'An Internal Error Occurred'})

        this.setState({payments: payments.data.payments, case: foundCase.data, contact: foundContact.data})

    }

    render() {

        const { payments, contact, error } = this.state;
        const _case = this.state.case;

        if(!_case && !error) return <div className="py-6"><Circle /></div>
        if(error) return <div className="py-6 text-center"><h2>{error}</h2></div>

        return (

            <Wrapper
                canPrint={true}
                canSavePDF={true}
            >

                <img src={LetterHead} style={{maxWidth: 300}} className="ml--2 mb-4" alt="fairmax law"></img>

                <h2 className="display-4 text-capitalize">{renderName(contact)}</h2>
                <p className="text-sm mb-3">{moment().format('MM/DD/YYYY')}</p>

                <h4 className="text-capitalize">Case: {_case.name} | ID {_case._id}</h4>

                <h5>
                    {contact.email ? contact.email : null}
                    { contact.phone ? (
                       contact.email ? <span><br />{formatPhone(contact.phone)}</span> : formatPhone(contact.phone)
                    ) : null}
                </h5>

                <h5> {renderAddress(contact)}</h5>
                <hr />

                <h4>Payment History <small>for case number: {_case._id}</small></h4>

                <Card>

                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {payments && payments.length ? (
                                payments.map((log, i) => (
                                    <tr key={log._id}>

                                        <td style={{color: 'red'}}>{i + 1}</td>

                                        <td>{moment.unix(log.created_at).format('MM/DD/YYYY')}</td>

                                        <td className="text-right">{formatMoney(log.transaction_amount)}</td>

                                        <td className="text-right">
                                        {log.status === 'succeeded' ? <Badge className="text-sm" style={styles.badges} color="success">Succeeded</Badge> :
                                        log.status === 'failed' ? <Badge className="text-sm" style={styles.badges} color="danger">Failed</Badge> :
                                            <Badge className="text-sm" style={styles.badges} color="warning">Refunded</Badge>}
                                        </td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </table>

                </Card>

            </Wrapper>

        );
    }
}

const styles = {

    badges: {
        display: 'inline-block',
        width: 95
    }

}


export default CasePayments
