/*
Documentation

appoint counts goes down once when call is connected, then again when appointment is deleted

'new contact on hold', 'new contact',

'appointment',

'business', 'call back', 'on hold'

new contacts are just a stream of all ' new contact ' or 'new contacts on hold'

*/


import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import store from 'store';
import * as actionTypes from 'store/actions';

export const setCallCenter = async() => {
    return new Promise( async (resolve) => {

        listenForTransfers()

        resolve({success: true});

    })

}


export const startTransferListener = (user_id, contact_id) => {

    store.dispatch({
        type: actionTypes.SET_TRANSFER_LISTENER,
        payload: {
            user_id,
            contact_id,
            status: null
        }
    });

}


const stopTransferListener = (status) => {

    store.dispatch({
        type: actionTypes.SET_TRANSFER_LISTENER,
        payload: {
            user_id: null,
            contat_id: null,
            status
        }
    });

}

const listenForTransfers = () => {

    io.on(socketEvents.transfer_answered, async (data) => {

        let transfer_listener = store.getState().call_center.transfer_listener

        if(data && data.data) {
            if(transfer_listener.contact_id === data.data.contact_id && transfer_listener.user_id === data.data.user_id) {
                stopTransferListener('answered')
            }
        }

    })

    io.on(socketEvents.transfer_did_not_answer, async (data) => {

        let transfer_listener = store.getState().call_center.transfer_listener

        if(transfer_listener.contact_id === data.data.contact_id && transfer_listener.user_id === data.data.user_id) {
            stopTransferListener('not answered')
        }


    })
    
    io.on(socketEvents.twilio_browser_transfer_denied, async (data) => {

        let transfer_listener = store.getState().call_center.transfer_listener

        if(data && data.data && data.data.contact) {
            if(transfer_listener.contact_id === data.data.contact._id && transfer_listener.user_id === data.data.user) {
                stopTransferListener('not answered')
            }
    
        }
      
    })

}

export const setOnCall = (params) => {

    store.dispatch({
        type: actionTypes.SET_CALL_STATUS,
        payload: {
            onCall        : true,
            finishCall    : params.finishCall,
            contact_name  : params.contact_name,
            contact_phone : params.contact_phone,
            contact       : params.contact,
            contact_email : params.contact_email,
            ConferenceSid : params.ConferenceSid,
            questionnaire : params.questionnaire,
            division      : params.division,
            callType      : params.callType,
        }
    });

}

export const setOffCall = () => {

    store.dispatch({
        type: actionTypes.SET_CALL_STATUS,
        payload: {
            onCall        : false,
            finishCall    : undefined,
            contact_name  : undefined,
            contact_phone : undefined,
            contact       : undefined,
            contact_email : undefined,
            ConferenceSid : undefined,
            questionnaire : undefined,
            division      : undefined,
            callType      : undefined,
        }
    });

}

export const toggleEndCallModal = (bool) => {

    store.dispatch({
        type: actionTypes.SET_END_CALL_MODAL,
        payload: {
            show : bool,
        }
    });

}

export const toggleStartCallModal = (bool, contact) => {
    store.dispatch({
        type: actionTypes.SET_START_CALL_MODAL,
        payload: {
            show : bool,
            contact,
        }
    });

}