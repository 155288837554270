/*
Documentation

this file renders a feed showing all contact texts, email, notes, and case feeds if db query allows
if case is passed as a query param then we add case_feeds into the feed

we query for case if the case param is passed in as an object with an _id property

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";

import _contacts from '_functions/contacts';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import Circle from 'components/markup/loading/Circle';

import All from './All'
import Notes from './Notes'
import Emails from './Emails'
import Texts from './Texts'

const feedInterval = 50;

class Feed extends Component {

    state = {
        view:               'contact_notes', //  feed, email, text
        isFullScreen:       false,
        originalFeedHeight: null,
        showFeedEntries: feedInterval,
    };

    showMore = () => this.setState({showFeedEntries: this.state.showFeedEntries + feedInterval})

    toggleFullScreen = () => {

        const isFullScreen = this.state.isFullScreen
        let height = 'auto'

        //this saves the old height of the feed container and remove it on full screen and ads it back in on minimization
        if(!isFullScreen) {

            let el = document.getElementById("case_home_left_col")

            if(el) {

                var originalFeedHeight = window.getComputedStyle(el, null);
                originalFeedHeight = originalFeedHeight.getPropertyValue("height");

                originalFeedHeight = originalFeedHeight.split('px')
                originalFeedHeight = parseInt(originalFeedHeight) - 137;
                originalFeedHeight = originalFeedHeight + 'px' ;

                this.setState({originalFeedHeight})

            }

        } else {

            height = this.state.originalFeedHeight

        }

        const feed = document.getElementById('case_feed')
        if(feed) { feed.setAttribute("style",`height: ${height}`); }

        this.setState({isFullScreen: !this.state.isFullScreen});

    }

    //sets interval refreshing state to make moment update the notes time
    //time can be any value that changes, date is irrelevant here
    setTimeInterval = () => {

        this.timeCheckInterval = setInterval(() => {
            this.setState({time: new Date()})
        }, 30000)

    }

    changeView = (view) =>  this.setState({view, showFeedEntries: feedInterval})

    listenForFeedAdded = (data) => {

        if(this.props.case && data.data.case === this.props.case._id) {

            let feed = this.state.feed ? [...this.state.feed] : [];

            feed.unshift(data.data)
            this.setState({feed})

        }

    }

    listenForContactNotesAdded = (data) => {

        if(data.data.contact === this.props.case.contact._id) {

            let feed = this.state.feed ? [...this.state.feed] : []

            feed.unshift(data.data)
            this.setState({feed})

        }

    }

    listenForContactNotesUpdated = (data) => {

        if(data.data.contact === this.props.case.contact._id) {

            let feed = this.state.feed ? [...this.state.feed] : []
            const index = feed.findIndex(el => el._id === data.data._id);

            if(index !== -1) {
                feed[index] = data.data;
                this.setState({feed})
            }

        }

    }

    listenForContactNotesDeleted = (data) => {

        if(data.data.contact === this.props.case.contact._id) {

            let feed = this.state.feed ? [...this.state.feed] : []
            const index = feed.findIndex(el => el._id === data.data._id);

            if(index !== -1) {

                feed.splice(index, 1)
                this.setState({feed})
            }

        }

    }

    listenForContactTextsAdded = (data) => {

        let contact_id = data.data.contact && data.data.contact._id ? data.data.contact._id : data.data.contact

        if(contact_id === this.props.case.contact._id) {

            let feed = this.state.feed ? [...this.state.feed] : []

            feed.unshift(data.data)
            this.setState({feed})

        }

    }

    listenForContactEmailsAdded = (data) => {

        if(data.data.contact === this.props.case.contact._id) {

            let feed = this.state.feed ? [...this.state.feed] : []

            feed.unshift(data.data)
            this.setState({feed})

        }

    }

    initListeners = () => {
        io.on(socketEvents.case_feed_added,         this.listenForFeedAdded)
        io.on(socketEvents.contact_notes_added,     this.listenForContactNotesAdded)
        io.on(socketEvents.contact_notes_updated,     this.listenForContactNotesUpdated)
        io.on(socketEvents.contact_notes_deleted,     this.listenForContactNotesDeleted)
        io.on(socketEvents.contact_texts_added,     this.listenForContactTextsAdded)
        io.on(socketEvents.contact_emails_added,    this.listenForContactEmailsAdded)
    }

    removeListeners = () => {
        io.off(socketEvents.case_feed_added,         this.listenForFeedAdded)
        io.off(socketEvents.contact_notes_updated,     this.listenForContactNotesUpdated)
        io.off(socketEvents.contact_notes_deleted,     this.listenForContactNotesDeleted)
        io.off(socketEvents.contact_notes_added,     this.listenForContactNotesAdded)
        io.off(socketEvents.contact_texts_added,     this.listenForContactTextsAdded)
        io.off(socketEvents.contact_emails_added,    this.listenForContactEmailsAdded)
    }

    componentDidUpdate = () => {

        // set the feed to auto add feed posts when scrolled to bottom
        const obj = document.getElementById('feed-wrapper')

        if(obj) {
            obj.addEventListener("scroll", () => {

                // a bug was found where this sould not scroll all the way down as it was 
                // off by 1 pixel, accounted for by changing the numbers from equalling each 
                // other to being >= the supposed matched height - 10 pixels
                if( obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight - 10)) {

                    let { feed, showFeedEntries } = this.state
                    if(showFeedEntries - feedInterval <= feed.length) this.showMore()

                }
            });
        }

    }

    componentWillUnmount = () => {

        clearInterval(this.timeCheckInterval)
        this.removeListeners()

    }

    componentDidMount = async () => {

        this.setTimeInterval()
        this.initListeners()

        let filterString = `?filter=contact__${this.props.case.contact._id}`

        if(this.props.case && this.props.case._id) filterString += `|case__${this.props.case._id}`

        const feed = await _contacts.feed(filterString)
        if(feed.success) this.setState({feed: feed.data})

    }

    render() {

        let { feed, showFeedEntries, view, isFullScreen } = this.state

        const { navPills, ignoreHeight, renderNameAndInitial } = this.props

        const _case = this.props.case
        const { dontShow } = this.props

        if(navPills &&  navPills !== 1 && navPills !== 4 ) return null

        // used on contact view page
        if(dontShow === true) return null

        return (

        <div style={{width: '100%'}} className={isFullScreen ? 'background full-background' : null}>

            {isFullScreen && <h2 className="mb-3">Case: {this.props.case.name}</h2>}

            <Card id="case_feed" className="case_feed card-color card-primary" style={ ignoreHeight ?{ height: 'auto'} : {}}>
                <CardHeader className="navigation-wrapper">

                    <Row className="navigation">

                        <Col xs={3}>
                            <CardTitle className="mb-0" onClick={() => this.changeView('contact_notes')}>
                                <div className={view === 'contact_notes' ? 'active' : ''}>
                                    <i className="fas fa-user mr-2" />
                                    Notes
                                </div>
                            </CardTitle>
                        </Col>

                        <Col xs={3}>
                            <CardTitle className="mb-0 text-center" onClick={() => this.changeView('contact_emails')}>
                                <div className={view === 'contact_emails' ? 'active' : ''}>
                                    <i className="fas fa-envelope mr-2" />
                                    Email
                                </div>
                            </CardTitle>
                        </Col>

                        <Col xs={3}>
                            <CardTitle className="mb-0 text-center" onClick={() => this.changeView('contact_texts')}>
                                <div className={view === 'contact_texts' ? 'active' : ''}>
                                    <i className="fas fa-mobile mr-2" />
                                    Text
                                </div>
                            </CardTitle>
                        </Col>

                        <Col xs={3}>
                            <CardTitle className="mb-0 text-right" onClick={() => this.changeView('feed')}>
                                <div className={view === 'feed' ? 'active' : ''}>
                                    <i className="fas fa-home mr-2" />
                                    Feed
                                </div>
                            </CardTitle>
                        </Col>

                    </Row>

                </CardHeader>

                {!feed ? (
                    <div className="py-6"><Circle /></div>
                ) : (

                    <>
                    <All
                        feed={feed}
                        view={view}
                        case={_case}
                        toggleFullScreen={this.toggleFullScreen}
                        isFullScreen={isFullScreen}
                        showFeedEntries={showFeedEntries}
                        ignoreHeight={ignoreHeight}
                        renderNameAndInitial={renderNameAndInitial}
                    />

                    <Emails
                        feed={feed}
                        view={view}
                        case={_case}
                        toggleFullScreen={this.toggleFullScreen}
                        isFullScreen={isFullScreen}
                        showFeedEntries={showFeedEntries}
                        ignoreHeight={ignoreHeight}
                        renderNameAndInitial={renderNameAndInitial}
                    />

                    <Texts
                        feed={feed}
                        view={view}
                        case={_case}
                        toggleFullScreen={this.toggleFullScreen}
                        isFullScreen={isFullScreen}
                        showFeedEntries={showFeedEntries}
                        ignoreHeight={ignoreHeight}
                        renderNameAndInitial={renderNameAndInitial}
                    />

                    <Notes
                        feed={feed}
                        view={view}
                        case={_case}
                        toggleFullScreen={this.toggleFullScreen}
                        isFullScreen={isFullScreen}
                        showFeedEntries={showFeedEntries}
                        ignoreHeight={ignoreHeight}
                        renderNameAndInitial={renderNameAndInitial}
                    />
                    </>
                )}

            </Card>

        </div>

        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

Feed.propTypes = {
    case        : PropTypes.object.isRequired,
    navPills    : PropTypes.number,
    ignoreHeight    : PropTypes.bool,
}

export default connect(mapStateToProps, '')(Feed);
