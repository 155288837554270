import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

import _contacts from '_functions/contacts';
import * as _ from 'underscore';
import AvatarImage from 'components/functional/images/AvatarImage';

class PersonSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            showResults: false,
            selected: {},
            dataToShow: [],
        }
       this.queryPersonsThrottled = _.throttle(this.queryPersons, 750)
    }


    onSelect = (selected) => {

        this.setState({selected: {}, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    filterUsers = (value) => {

        if(!value) return []
        value = value.toLowerCase();

        let users = [...this.props.users]

        value = value.toLowerCase()

        users = users.filter(user => {

            if( user.phone && user.phone.includes(value)) return true
            if( user.email && user.email.includes(value)) return true
            if( renderName(user).includes(value)) return true

            return false

        })

        return users

    }

    queryPersons = async (value) => {

        const foundUsers = this.filterUsers(value)

        const contacts = await _contacts.search({
            search: value,
            limit: 50,
        })

        if(contacts.success) {

            let foundContacts = contacts.data ? contacts.data : []
            let dataToShow = foundContacts.concat(foundUsers)

            if( dataToShow.length > 50) dataToShow =  dataToShow.slice(0, 50)

            this.setState({dataToShow, total_documents: contacts.total_documents, showResults: true})

        }

    }


    onChange = async (value) => {

        this.setState({filter: value})

        if(value) {
            this.queryPersonsThrottled(value)
        } else {
            this.setState({filter: null, showResults: false})
        }

    }


    componentDidMount = () => {
        const { value } = this.props
        this.setState({ selected: value ? value : {} })
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter } = this.props

        return (
            <ObjectSearchInput 
                inputID={this.props.inputID ? this.props.inputID : undefined}
                data={dataToShow}
                onChange={this.onChange}
                onSelect={this.onSelect}
                placeholderFormatter={selected ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Case: <b className="text-success text-capitalize">{renderName(selected)}</b></span> : 
                        'Select Case'
                }
                resultsFormatter={(result) => (
                    <li key={result._id} onClick={() => this.onSelect(result)}>
                        <p className="text-sm mb-0 text-capitalize">
                            {result.avatar ? <AvatarImage  src={result.avatar} className="mr-2" /> : null}
                            {renderName(result) === 'Not Found' ? 'Name Not Found' : renderName(result)}
                        </p>
                        <p className="text-sm mb-0 text-muted"><i className="fas fa-phone mr-2" />  {result.phone ? formatPhone(result.phone) : '-'} </p>
                        <p className="text-sm mb-0 text-muted"><i className="fas fa-envelope mr-2" />  {result.email ? result.email : '-'} </p>
                    </li>
                )}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

PersonSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default connect(mapStateToProps, '')(PersonSearchInput);
