import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import { Row, Col, Card, CardHeader, CardTitle } from 'reactstrap';

import _cases from '_functions/cases';
import _contacts from '_functions/contacts';

import Circle from 'components/markup/loading/Circle'

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

import moment from 'moment';

import App from './App';
import CasesCreate from '../../../views/dashboard/pages/cases/Create'

import { showStepsToPetitionPrep } from 'store/functions/system/system'

const StepsToPrep = ({contact_id, case_id, call_status}) => {

    const [state, setState] = useState({
        contact: null,
        _case: null,
        cases: null
    })

    const { contact, _case, cases } = state;
    const case_url = _case && _case._id ? `/dashboard/cases/view/${_case._id}` : null

    const onSetState = useCallback((newState) => {
        const _state = JSON.parse(JSON.stringify(state));
        setState({..._state, ...newState});
    }, [state])

    const fetchData = useCallback(async () => {

        const _contact = await _contacts.findById(contact_id);

        const cases = await _cases.find(`?filter=contact__${contact_id}|deleted__false{bool}&sort=created_at__desc`, true)

        if(cases && cases.data) {
            if(cases.data.length === 1) {
                onSetState({
                    contact: _contact.data,
                    _case: cases.data[0],
                    cases: cases.data,
                })
            } else if(cases.data.length > 1) {
                const foundCase = cases.data.find(c => {
                    return c._id === case_id
                });
                onSetState({
                    contact: _contact.data,
                    cases: cases.data,
                    _case: foundCase
                })
            } else {
                onSetState({
                    contact: _contact.data,
                    cases: [],
                })
            }
        }
    }, [contact_id, case_id])

    useEffect(() => {
        fetchData();
        document.body.classList.add('noScroll')
        return () => {
            document.body.classList.remove('noScroll');
        }
    }, [fetchData])


    return (
        <>
            {case_url && !window.location.href.includes(case_url) ? <Redirect to={case_url} /> : null}
        
            <div className="archk-steps-to-prep-blackout" onClick={() => showStepsToPetitionPrep({})} />

            <div className="archk-steps-to-prep z-depth-4" style={{bottom: call_status.onCall ? 40 : 0}} >

                {!contact ? (
                    <div className="py-6"><Circle /></div>
                ) : (

                    <>
                        <div className="archk-steps-to-prep-header border-bottom">
                            <Row>
                                <Col xs={12} md={8}>
                                    <span className="text-capitalize"> {contact.given_name || contact.family_name ? renderName(contact) : null}</span>
                                    {contact.phone ? <span className="phone"><small> - {formatPhone(contact.phone)}</small></span> : null}
                                    {contact.email ? <span className="email"><small> - {contact.email}</small></span> : null}
                                </Col>
                                <Col xs={12} md={4} className="text-right">
                                    <Row>
                                        <Col xs={6}>
                                        {_case ? (
                                        <button className="btn btn-warning" onClick={() => onSetState({_case: null})}><i className="fas fa-arrow-left " /> All Cases</button>
                                    ) : null}
                                        </Col>
                                        <Col xs={6}>
                                        <button onClick={() => showStepsToPetitionPrep({})} className="btn btn-outline-danger"><i className="fas fa-times " /> Close</button>

                                        </Col>
                                    </Row>
                                 
                                </Col>
                            </Row>
                        </div>

                        {_case ? (
                            <App 
                                _case={_case} 
                                contact={contact} 
                            />
                        ) : cases.length ? (
                            <div className="archk-steps-to-prep-header border-bottom bg-secondary">

                                <Card>
                                    <CardHeader>
                                        <CardTitle className="mb-0">Select Case</CardTitle>
                                    </CardHeader>
                                    <div className="table-responsive">
                                        <table className="table">

                                            <thead>
                                                <tr>
                                                    <th>Case</th>
                                                    <th className="text-right">Created</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {cases.map(c => (                                               
                                                    <tr key={c._id}>
                                                        <td className="text-capitalize"><span className="cursor-pointer" onClick={() => onSetState({_case: c})}>{c.name}</span></td>
                                                        <td className="text-right">{moment.unix(c.created_at).format('MMM Do, YYYY - h:mm A')}</td>
                                                        <td className="text-right"><button onClick={() => onSetState({_case: c})} className="btn btn-success">Select</button></td>
                                                    </tr>
                                                 ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </Card>

                            </div>
                        ) : (
                            <div className="archk-steps-to-prep-container border-bottom bg-secondary">
                                <CasesCreate 
                                    hideNavigation={true}
                                    selectedContact={contact._id}
                                    onCaseCreated={(c) => onSetState({_case: c})}
                                />
                            </div>
                        )}
                    </>

               )}
            </div>

        </>
    )

}

const StepsToPrepWrapper = ({stepsToPetitionPrep, workflows, call_status}) => {

    const { contact, case_id } = stepsToPetitionPrep;
    if(!contact) return <></>

    return <StepsToPrep contact_id={contact} case_id={case_id} workflows={workflows} call_status={call_status} />

}

const mapStateToProps = state => {
    return {
        stepsToPetitionPrep: state.system.stepsToPetitionPrep,
        call_status: state.call_center.call_status,
    };
};

export default connect(mapStateToProps, '')(StepsToPrepWrapper);