import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { capitalize } from 'utils/text'
import { Card, CardHeader, CardTitle, Row, Col, FormGroup, Input } from 'reactstrap';

import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

import fileDownloader from 'js-file-download';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import keys from 'keys';
import Axios from 'axios';

import _analytics from '_functions/analytics';
import { useEffect } from 'react';
import { useCallback } from 'react';

import Circle from 'components/markup/loading/Circle'

const OverviewOverview = ({users}) => {

    // users = users.filter(u => u.)
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState('totalTimeBetweenCalls');
    const [filterOrder, setFilterOrder] = useState(1);
    const [start, setStart] = useState(moment().startOf('day').toDate());

    const onDownload = async (user) => {

        toggleStandardLoader(true);

        const result = await Axios({
            responseType:'arraybuffer',
            headers: { authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
            withCredentials: true,
            method: 'post',
            url: keys.API_URL + `/api/v1/analytics/custom_reports/call_center/downloadUser`,
            data: { 
                user: user._id,
                startOfDay: moment(start).format('X')
            },
        })
        toggleStandardLoader(false);

        if(result.headers && result.headers['content-type'].includes('application/json')) {
            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    toggleAlertBS(true, `Something's not right, please try again`);
                    return body.message[0] ? body.message[0] : `Something's not right, please try again`;
                }
            } catch(e) {
                console.log(e)
                toggleAlertBS(true, `Something's not right, please try again`);
            }
            return;
        }

        fileDownloader(result.data, `${capitalize(user.given_name)} ${capitalize(user.family_name)} - Activity Report.pdf`) 
    }

    const onSetFilter = (_filter) => {
        if(filter === _filter) {
            return setFilterOrder(-1 * filterOrder)
        }
        setFilterOrder(1);
        setFilter(_filter);
    }



    const UserRow = ({user, metrics}) => (
        <tr>
             <td className="text-capitalize">
               
                <small>
                    <a
                        href={`/blank/screen_watch/${user._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fas fa-eye mr-2 text-warning mr-2 cursor-pointer" />
                    </a>
                    <i onClick={() => onDownload(user)} className="fas fa-download mr-2 text-success " />
                    {renderName(user)} ({metrics ? metrics.totalTimeBetweenCalls && metrics.totalCalls ? secondsToTime(metrics.totalTimeBetweenCalls / metrics.totalCalls) : 0 : '-'})
                </small>
            </td>
             <td>
                <small>{metrics ? metrics.totalCallsLastHour : '0'} / {metrics ? metrics.timeOnCallLastHour ? secondsToTime(metrics.timeOnCallLastHour) : 0 : '0 Mins'}</small>
            </td>
             <td>
                <small>{metrics ? metrics.totalCalls : '0'} / {metrics ? metrics.timeOnCall ? secondsToTime(metrics.timeOnCall) : 0 : '0 Mins'}</small>
            </td>            
             <td><small>{metrics ? metrics.totalCallsOver2Minutes : '-'}</small></td>
        </tr>
    )

    let sortedMetrics = data ? JSON.parse(JSON.stringify(data.metrics)) : ''

    // filter object keys to map either all asc or all desc
    if(filter) {
        sortedMetrics = Object.keys(sortedMetrics).sort((a, b) => {
            let valA = sortedMetrics[a][filter]
            let valB = sortedMetrics[b][filter]

            if(filter === 'totalTimeBetweenCalls') {
                valA = valA / sortedMetrics[a]['totalCalls']
                valB = valB / sortedMetrics[b]['totalCalls']
            }
            
            return valA < valB ? filterOrder : -1 * filterOrder
        }).reduce((accumulator, key) => {
            accumulator[key] = sortedMetrics[key];
            return accumulator;
        }, {});
    }

    const fetchData = useCallback(async () => {
        const startDate = moment(start).format('X')
        const endDate = moment(start).add(1, 'days').format('X')
    
        const data = await _analytics.master.getReport(`?start=${startDate}&end=${endDate}`);
        setData(data.data);

    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <Circle />

    return (
        <Card className="card-color card-danger" style={{overflow: 'auto'}}>

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">User Call Metrics</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <FormGroup className="mb-0">
                            <Input 
                                size="sm"
                                type="select"
                                value={filter ? filter : 'Sort'}
                                onChange={(e) => onSetFilter(e.target.value === 'Sort' ? null : e.target.value)}
                            >
                                <option value="Sort">Select Sorting</option>
                                <option value="totalCallsLastHour">Total Calls Last Hour</option>
                                <option value="totalCallsOver2Minutes">Total Calls Over 2 Minutes</option>
                                <option value="timeOnCall">Time On Call</option>
                                <option value="totalCalls">Total Calls</option>
                                <option value="totalTimeBetweenCalls">Total Time Between Calls</option>
                                <option value="timeOnCallLastHour">Time On Call Last Hour</option>
                             
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>
    
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>User (Avg Between)</th>
                            <th className={`curor-pointer ${filter === 'timeOnCallLastHour' ? 'text-success' : null}`} onClick={() => onSetFilter('timeOnCallLastHour')}>
                                Last Hour
                            </th>

                            <th className={`curor-pointer ${filter === 'timeOnCall' ? 'text-success' : null}`} onClick={() => onSetFilter('timeOnCall')}>
                                Total
                            </th>
                            <th className={`curor-pointer ${filter === 'totalCallsOver2Minutes' ? 'text-success' : null}`} onClick={() => onSetFilter('totalCallsOver2Minutes')}>
                                2M+
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(sortedMetrics).length ? Object.keys(sortedMetrics).map((key, i) => {
                            const user = users.find(u => u._id === key);
                            return !user ? <></> : (
                                <UserRow key={i} user={user} metrics={sortedMetrics[key]} />
                            )
                        }) : (
                            <tr>
                                <td><i className="fas fa-exclamation-triangle mr-2 text-warning" /> No Data Available</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
    
        </Card>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(OverviewOverview);
