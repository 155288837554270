/*
Documentation

this is the loader that gets shown whenever the dashboard is loading,
not needed anywhere else so unless this throws an error leave it alone

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import moment from 'moment';

class TimeClock extends Component {

    state = { seconds: 1 }

    interval = false

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.status !== 'starting') {
            clearInterval(this.interval)
        }
    }

    componentDidMount = () => {

        this.interval = setInterval(() => { this.setState({seconds: this.state.seconds + 1}) }, 1000)

    }

    render() {

        const { status } = this.props
        const { seconds } = this.state

        return (
            <span className={status === 'success' ? 'text-success' : status === 'error' ? 'text-danger' : ''}>
                {seconds} Seconds
            </span>
        )

    }

}

class Uploads extends Component {

    state = {
        showLoader: false,
        faderOpacity: 1,
    };


    render() {

        const { documents_loading } = this.props

        if(!documents_loading.length) return <div />

        return (
            <div className="archk-system-uploads z-depth-5">
                <Container fluid>
                
                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Size</th>
                                    <th>Upload Status</th>
                                    <th>Started At</th>
                                    <th>Ended At</th>
                                    <th>Upload Time</th>
                                </tr>
                            </thead>

                            <tbody>
                                {documents_loading.map((doc, i) => {

                                    return (
                                        <tr key={i}>
                                            <td className="text-sm">{doc.fileName}</td>
                                            <td className="text-sm">{doc.size}</td>
                                            <td className="text-sm">
                                                {doc.status === 'success' ? (
                                                    <span className="text-success"><i className="fas fa-check mr-2 " /> {doc.status} </span>
                                                ) : doc.status === 'error' ? (
                                                    <span className="text-danger"><i className="fas fa-check mr-2 " /> {doc.status} </span>
                                                ) : (
                                                    <span className="text-info-original"><i className="fas fa-spinner fa-spin mr-2 " />Uploading </span>
                                                )}
                                            </td>
                                            <td className="text-sm">{moment.unix(doc.started).format('h:MM A')}</td>
                                            <td className="text-sm">{doc.ended ? moment.unix(doc.ended).format('h:MM A') : '-'}</td>
                                            <td className="text-sm"><TimeClock status={doc.status} /></td>
                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>
                  
                </Container>
                
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        documents_loading: state.system.documents_loading,
    };
};

export default connect(mapStateToProps, '')(Uploads);
