/*
Documentation

This file renders an article within the knowledge base

*/

import React, { Component } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { Card, CardBody, CardHeader } from "reactstrap";
import { stripHTML } from 'utils/code'

class KnowledgeBaseArticle extends Component {

    state = {
        query: {},
        open: false
    }

    render() {

        const i = this.props.i;
        const open = this.state.open

        if(!i) { return <div></div>}

        return (

            <>
                <Card>
                    <CardHeader>
                        <h3 className="mb-0 text-capitalize">{i.title}</h3>
                    </CardHeader>
                    <CardBody>
                        {open ? (
                            <div>
                                {ReactHTMLParser(i.html)}
                                <button className="mt-5 btn btn-sm btn-danger" onClick={() => this.setState({open: false})}>Close <i className="ml-2 mt--1 fas fa-compress" /></button>
                            </div>
                        ) : (
                            <div>
                                <p className="text-sm">
                                {stripHTML(i.html && i.html.length ? i.html.length > 100 ? i.html.slice(0, 200) + '...' : i.html : null)}
                                </p>
                                <button className=" mt-5 btn btn-sm btn-success" onClick={() => this.setState({open: true})}>Open <i className="ml-2 mt--1 fas fa-expand" /></button>

                            </div>
                        ) }
                    </CardBody>
                </Card>

            </>

        )

    }

}

export default KnowledgeBaseArticle

