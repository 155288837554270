import { Link } from 'react-router-dom'
import React from 'react';
import { helpCenter } from 'store/functions/system/system';
import * as privileges from '_functions/users/privileges'

const backButton = (
    <Link
        to="/dashboard/contacts/all"
        className="btn btn-warning"
        onClick={() => helpCenter.toggle(
            helpCenter.content.contacts.all.body,
            helpCenter.content.contacts.all.footer,
        )}
    >
        <i className="fas fa-arrow-left mr-2" />Back
    </Link>
)

export default {

    create: {
        body: (
            <div>
                <h3>Contact Creation</h3>
                <p>Contacts are the heart of this software. Everything is tied to them and at least one (1) contact must be made before a case can be added.  </p>
                <p>The only required field to create a contact is their email address however it is highly advised to fill out any and all details you have about them.</p>

                <h3>Contact Validation</h3>
                <p>When creating or updating a contact their phone and email address will be validated within the system to make sure there are no duplicates being made. The system will also run an AI search of existing contacts to see if the contact to be added closely resembled someone else in the system.</p>

                <h3>Contact Relationships</h3>
                <p>Should a contact be related to another contact in the system you may relate them at the bottom of this page. This allows for an easy way to see the associations of leads and existing clients.</p>

                <h3>Contact Types</h3>
                <p>You may select and number of contact types given on this page. If a contact does not have a type they may still be created and as such the field may be left blank if they type of contact is unknown.</p>

            </div>
        ),

    },

    update: {
        body: (
            <div>
                <h3>Update A Contact</h3>
                <p>Updating a contact is similar to creating once. Click the button below to learn more</p>

            </div>
        ),
        footer: (
            <div>

                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.contacts.create.body,
                        helpCenter.content.contacts.create.footer,
                        backButton
                    )}
                    to="/dashboard/courts/create"
                    className="btn btn-success"
                >
                    Create A Contact
                </Link>

            </div>
        )
    },

    all: {
        body: (
            <div>
                <h3>All Contacts</h3>
                <p>On this page you may view all contacts within the system. You may update and deleted contacts based on the icons on the right hand side of each contact row and create a new contact by clicking the green button in the top right corner of the screen.</p>
            </div>
        ),
        footer: () => (
            privileges.canUpdateContacts() ?

                <Link
                    onClick={() => helpCenter.toggle(
                        helpCenter.content.contacts.create.body,
                        helpCenter.content.contacts.create.footer,
                        backButton
                    )}
                    to="/dashboard/courts/create"
                    className="btn btn-success"
                >
                    Create A Contact
                </Link>

            : null
        )

    },

    login: {
        body: (
            <div>
                <h3>Login As Contact</h3>
                <p>Logging in as a contact enables you to to see a client dashboard exactly as they would. Once you click this button you will be redirected and asked to add in your password to authenticate.</p>
                <p>If you refresh the page you will be logged out for security reasons.</p>
                <p><b>When logged in as a client you will have full privileges to act on their behalf. Anything actions you do on their dashboard will be treated as if the contact has done the action themselves.</b></p>

            </div>
        ),

    },


}
