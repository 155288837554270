/*
Documentation


*/


import React from "react";
import {  Redirect, Route, Switch } from "react-router-dom";
import stackLogger from 'database/stackLogger';

import routes from "./routes";

class Blank extends React.Component {
    state = {
        logged_in: true,
        loaded: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/blank${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    componentDidCatch = (e) => {

        console.log(e);
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillReceiveProps = (nextProps) => {

        // any time the location changes base url scroll to top of the page
        const lastLocation = this.state.lastLocation
        const currentLocation = nextProps.location.pathname

        if(currentLocation !== lastLocation) {
            window.scrollTo({top: 0, left: 0})
        }

        this.setState({lastLocation: currentLocation})
    }

    render() {

        return (
            <div className="layout-blank">
                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/dashboard/call_center" />
                </Switch>
            </div>

        );
    }
}

export default Blank
