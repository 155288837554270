import apiWorker from '../apiWorker'

export default {


    create:         (case_id, data)  =>  apiWorker('post',  `/api/v1/core/case_alerts/create/${case_id}`, data),
    createResponse: (case_id, data)  =>  apiWorker('post',  `/api/v1/core/case_alerts/createResponse/${case_id}`, data),
    resolve:        (case_id, data)  =>  apiWorker('patch', `/api/v1/core/case_alerts/resolve/${case_id}`, data),
    findByCase:     (case_id)        =>  apiWorker('get',   `/api/v1/core/case_alerts/findByCase/${case_id}`),

}
