export const calculateTotalBillable = (case_workflow) => {

    let billable = 0;
    let cost = 0;
    let expected_minutes = 0;

    if(case_workflow && case_workflow.steps && case_workflow.steps.length) {

        case_workflow.steps.forEach(step => {

            if(step.tasks && step.tasks.length) {

                step.tasks.forEach(task => {

                    if(task.labor_billable) { billable = billable + task.labor_billable }
                    if(task.labor_cost) { cost = cost + task.labor_cost }

                    if(task.default_billable_minutes) {
                        expected_minutes = expected_minutes + task.default_billable_minutes
                    }

                })

            }

        })

    }
    return { billable, cost, expected_minutes }

}
