import keys from 'keys';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types'
import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { canViewFinances } from '_functions/users/privileges'
import { toggleBillingCenter } from 'store/functions/system/system'

class Navigation extends React.Component {

    state = {
        shouldRedirect: false
    }

    onChange = (val) => {

        const _case = this.props.case;

        if(val === 1 || val === 2 || val === 3 || val === 16 || val === 10 || val === 9 || val === 17) return this.props.setNav(val)
        if(val === 11) return this.setState({shouldRedirect: `/dashboard/contacts/view/${_case.contact._id}`})
        if(val === 12) return this.setState({shouldRedirect: `/dashboard/objections/${_case._id}`})
        if(val === 13) return this.setState({shouldRedirect: `/dashboard/garnishments/${_case._id}`})
        if(val === 14) return  toggleBillingCenter(true)
        if(val === 15) return this.setState({shouldRedirect: `/dashboard/contacts/view/${_case.contact._id}?nav=2`})
    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const navPills = this.props.navPills

        return (

            <div className="pb-3">
                <ReactSelect
                    defaultValue={
                        navPills === 2 ? {value: 2, label: "Tasks" } :
                        navPills === 3 ? {value: 3, label: "Documents" } :
                        navPills === 16 ? {value: 16, label: "Filing Fees" } :
                        navPills === 9 ? {value: 9, label: "Details" } :
                        {value: 1, label: 'Overview' }
                    }
                    onChange={(values) => this.onChange(values.value)}
                    options={[
                        {value: 1, label: 'Overview' },
                        {value: 2, label: "Tasks" },
                        {value: 3, label: "Documents" },
                        {value: 16, label: "Filing Fees" },
                        {value: 9, label: "Details" },
                        {value: 10, label: "Onboarding Forms" },
                        {value: 11, label: "Contact File" },
                        {value: 12, label: "Objections" },
                        {value: 13, label: "Garnishments" },
                        ...(canViewFinances() ? [{value: 14, label: "Billing" }] : []),
                        {value: 15, label: "Appointments" },
                        ...(keys.ZAP_REPORTS_URL ? [{value: 17, label: "Integrations" }] : []),

                    ]}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

Navigation.propTypes = {
    navPills    : PropTypes.number.isRequired,
    setNav      : PropTypes.func.isRequired,
    case        : PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(Navigation);
