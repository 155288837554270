import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

class CoreInformation extends Component {

    render() {

        const invoice = this.props.invoice

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">Invoice Details</CardTitle>
                </CardHeader>

                <CardBody>

                    <StandardFormGroup
                        obj={invoice}
                        objName="invoice"
                        onChange={(o, f, v) => this.props.onChange(f, v)}
                        type="text"
                        field="name"
                        title="Name"
                    />

                </CardBody>
            </Card>

        )

    }

}

export default CoreInformation;
