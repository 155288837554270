/*
Documentation

This file renders the parties card on the case home page

*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardTitle,  Col, Row } from "reactstrap";

import ConfirmationModal from 'components/functional/modals/Confirmation.js'

import { canUpdateCases } from '_functions/users/privileges'
import _case_parties from '_functions/case_parties';

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import { toggleAlertBS } from 'store/functions/system/system';

import ModalUpdateParties from './ModalUpdateParties';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

class CaseParties extends Component {

    state = {
        contact: {},
        canSave: true,
        action: null,
        showModalEmail: false,
        location_court: this.props.case.location_court ? this.props.case.location_court._id : null,
        showModalParties: false,
        showModalDeleteConfirmation: false,
        partyToContact: {}
    };

    toggleModalDeleteConfirmation = () => this.setState({showModalDeleteConfirmation: !this.state.showModalDeleteConfirmation})

    getAddress = (obj) => {
        if(obj.address_line_1 && obj.city && obj.state) {
            return (
                <>
                    <p className="text-muted text-sm mb--3">{obj.address_line_1} {obj.address_line_2}</p>
                    <p className="text-muted text-sm mb--2">{obj.city}, {obj.state} {obj.postal_code}</p>
                </>
            )
        } else if(obj.address_line_1) {
            return (
                <>
                    <p className="text-muted text-sm mb-1">{obj.address_line_1} {obj.address_line_2}</p>
                </>
            )
        }
    }

    getPhone = (obj) => {
        if(obj.phone) return <p onClick={() => toggleStartCallModal(true, obj)} className="text-muted cursor-pointer text-sm mb-0">{formatPhone(obj.phone)}</p>
    }

    getEmail = (obj) => {
        if(obj.phone) {
            if(obj.email) return <p className="text-muted text-sm mb--3">{obj.email}</p>
        }
        if(obj.email) return <p className="text-muted text-sm mb-0">{obj.email}</p>
    }

    toggleModalParties = () => this.setState({showModalParties: !this.state.showModalParties})
    
    toggleModalEmail = (obj) => {
        this.setState({showModalEmail: !this.state.showModalEmail, partyToContact: obj && obj._id ? obj : {}})
    }

    onEmailSendError = () => toggleAlertBS(true, 'Your email was not sent, please try again or send it from your Office 365 account.')


    onDeleteParty = (contact_id) => {

        this.setState({contact_id})
        this.toggleModalDeleteConfirmation()

    }

    onConfirmDeleteParty = () => {

        _case_parties.delete(this.state.contact_id)

    }

    listenForCasePartiesAdded = (data) => {
        if(data.data.case === this.props.case._id) {

            const case_parties = this.state.case_parties ? [...this.state.case_parties] : []
            case_parties.push(data.data)

            this.setState({case_parties})

        }
    }

    listenForCasePartiesDeleted = (data) => {

        let case_parties = this.state.case_parties ? [...this.state.case_parties] : []
        case_parties = case_parties.filter(c => c._id !== data.data._id)

        this.setState({case_parties})

    }

    // a bug was found where the trustee was assigned as a party and not on the case itself.
    // here we see if we have one correctly assigned on the case and we grab it, if not try
    // to grab the trustee assigned as a party
    getTrustee = (_case) => {
        if(!this.state.case_parties) return {};

        if(_case.location_hearing_trustee) return _case.location_hearing_trustee;

        const foundParty = this.state.case_parties.find(party => party.title === 'Trustee');
        if(foundParty && foundParty.contact) return foundParty.contact;

        return {}
    }

    componentWillUnmount = () => {

        io.off(socketEvents.case_parties_added,           this.listenForCasePartiesAdded)
        io.off(socketEvents.case_parties_deleted,         this.listenForCasePartiesDeleted)

    }

    componentDidMount = async () => {

        io.on(socketEvents.case_parties_added,           this.listenForCasePartiesAdded)
        io.on(socketEvents.case_parties_deleted,         this.listenForCasePartiesDeleted)

        const parties = await _case_parties.find(`?filter=case__${this.props.case._id}`)

        //if success and we already have state data due a socket request combine the data here, if not just add the data we got
        if(parties.success) {

            let data = parties.data

            if(this.state.case_parties) { data = this.state.case_parties.concat(data) }
            this.setState({case_parties: data})

        }

    }

    render() {

        const _case = this.props.case;
        const { case_parties, showModalParties, showModalDeleteConfirmation, showModalEmail, partyToContact } = this.state
        const { navPills, toggleModal } = this.props;

        if(navPills !== 1 ) return null

        const location_court = _case.location_court ? _case.location_court : {};
        const location_hearing = _case.location_hearing ? _case.location_hearing : {};
        const location_hearing_trustee = this.getTrustee(_case);
        const office = _case.office ? _case.office : {};

        const canUpdate = canUpdateCases();

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <Row>

                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0">Parties</CardTitle>
                        </Col>

                        <Col md={6} className="text-right align-self-center">
                            <button
                                disabled={!canUpdate}
                                onClick={this.toggleModalParties}
                                className="btn btn-success btn-s text-center"
                            >
                                Add Party
                            </button>
                        </Col>

                    </Row>
                </CardHeader>

                <div className="table-responsive parties-table">
                    <table className="table">

                        {/* <thead>
                            <tr>
                                <td className="font-weight-bold">Name</td>
                                <td className="text-right font-weight-bold" style={{width: 145}}>Role</td>
                            </tr>
                        </thead> */}

                        <tbody>

                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? toggleModal : null}
                                        />
                                        Client: <b className="text-capitalize">{renderName(_case.contact)}</b>
                                    </p>

                                    {this.getAddress(_case.contact)}
                                    {this.getEmail(_case.contact)}
                                    {this.getPhone(_case.contact)}

                                </td>
                            </tr>

                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? toggleModal : null}
                                        />
                                        {office.email ? (
                                            <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(office)} />
                                        ) : null}

                                        Office: {office._id ? <b className="text-capitalize">{office.name}</b> : 'Not Set'}
                                    </p>

                                    {this.getAddress(office)}
                                    {this.getEmail(office)}
                                    {this.getPhone(office)}

                                </td>
                            </tr>

                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? toggleModal : null}
                                        />
                                        
                                        {location_court.email ? (
                                            <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(location_court)} />
                                        ) : null}

                                        Court: {location_court._id ? <b className="text-capitalize">{location_court.name}</b> : 'Not Set'}
                                    </p>

                                    {this.getAddress(location_court)}
                                    {this.getEmail(location_court)}
                                    {this.getPhone(location_court)}

                                </td>
                            </tr>


                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? toggleModal : null}
                                        />


                                        {location_hearing_trustee.email ? (
                                            <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(location_hearing_trustee)} />
                                        ) : null}


                                        Trustee: {location_hearing_trustee._id ? <b className="text-capitalize">{renderName(location_hearing_trustee)}</b> : 'Not Set'}
                                    </p>

                                    {this.getAddress(location_hearing_trustee)}
                                    {this.getEmail(location_hearing_trustee)}
                                    {this.getPhone(location_hearing_trustee)}

                                </td>
                            </tr>


                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? toggleModal : null}
                                        />

                                        {location_hearing.email ? (
                                            <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(location_hearing)} />
                                        ) : null}

                                        Hearing Location: {location_hearing._id ? <b className="text-capitalize">{location_hearing.name}</b> : 'Not Set'}
                                    </p>

                                    {this.getAddress(location_hearing)}
                                    {this.getEmail(location_hearing)}
                                    {this.getPhone(location_hearing)}

                                </td>
                            </tr>


                            {case_parties && case_parties.length ? case_parties.map(case_party => (

                                // a bug was found where the trustee was assigned as a party and not on the case itself.
                                // this creates a filter to not show the trustee down here as we fake it above by putting the party 
                                // on the case object if assigned
                                case_party.title === 'Trustee' ? null : 
                                <tr key={case_party._id}>
                                    <td className="font-weight-bold">
                                        <p className="text-dark text-sm font-weight-bold mb--1">
                                            <i
                                                className="fas fa-trash text-danger mr-2"
                                                style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                                onClick={canUpdate ? () => this.onDeleteParty(case_party._id) : null}
                                            />
                                            {case_party.title}: <b className="text-capitalize">{renderName(case_party.contact)}</b>
                                        </p>

                                        {this.getAddress(case_party.contact)}
                                        {this.getEmail(case_party.contact)}
                                        {this.getPhone(case_party.contact)}

                                    </td>
                                </tr>
                            )) : null}

                        </tbody>
                    </table>
                </div>

            </Card>

            <ModalUpdateParties
                case={_case}
                showModal={showModalParties}
                toggleModal={this.toggleModalParties}
            />

            <ConfirmationModal
                  showModal={showModalDeleteConfirmation}
                  toggleModal={this.toggleModalDeleteConfirmation}
                  title="Remove Case Party"
                  body={<span>Are you sure you wish to remove this party from the case?</span>}
                  onConfirmation={this.onConfirmDeleteParty}
            />

            <SendEmailModalWrapper
                showModal={showModalEmail}
                toggleModal={this.toggleModalEmail}
            >
                <SendEmail
                    onSendingStart={this.toggleModalEmail}
                    onError={this.onEmailSendError}
                    subject="Your Fairmax Law Case"
                    to={partyToContact && partyToContact.email ? [partyToContact.email]   : []}
                    contact={_case.contact ? _case.contact._id : undefined}
                    useStrictEmail={true}
                />
            </SendEmailModalWrapper>

            </>

        )

    }

}

CaseParties.propTypes = {
    case        : PropTypes.object.isRequired,
    navPills    : PropTypes.number.isRequired,
    toggleModal : PropTypes.func.isRequired,
}

export default CaseParties
