/*
Documentation

This file renders the appointments card on the bottom left of the view garnishments page
Has functionality to create 2 types of appointments and then associated them to the garnishment

*/


import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import _garnishments from '_functions/garnishments';
import * as privileges from '_functions/users/privileges'

class GarnishmentAppointments extends React.Component {

    state = {
        deadline: null,
        showAppointmentsCrud: false,
    }

    toggleAppointmentsCrud = (deadline) => {
        this.setState({deadline, showAppointmentsCrud: !this.state.showAppointmentsCrud })
    }

    onAppointmentCreated = async (appointment) => {

        const { garnishment } = Object.assign({}, this.props)

        const action = await _garnishments.update(garnishment._id, {
            [this.state.deadline]: appointment._id
        })

        if(action.success) {
            toggleAlertBS(false, 'Appointment Set Successfully Successfully.')
        } else {
            toggleAlertBS(true, 'An error occurred creating this appointment.')
        }

        this.toggleAppointmentsCrud()

    }

    render() {

        const { garnishment } = this.props
        const { showAppointmentsCrud } = this.state

        return (
        <>

            <Card>

                <CardHeader>
                    <CardTitle>Appointments</CardTitle>
                </CardHeader>

                <CardBody>
                    <h4 className="text-sm">AP Garnishment DEADLINE - 60 days after original 341 Hearing Date</h4>
                </CardBody>

                {privileges.canModerateAppointments() ? (

                    <CardFooter className="text-right">
                        {!garnishment.deadline_appointment ? (
                            <button style={{width: 120}} className="btn btn-danger" onClick={() => this.toggleAppointmentsCrud('deadline_appointment')}>
                                <i className="fas fa-exclamation-triangle mr-2" /> Schedule
                            </button>
                        ) : (
                            <div className="text-left">
                                <p className="text-sm mb-2">
                                    {garnishment.deadline_appointment ? (
                                        <span className="text-capitalize"><b>Set Appointment: </b> {garnishment.deadline_appointment.name}</span>
                                    ) : 'Appointment Not Found'}
                                </p>
                                <button style={{width: 120}} className="btn btn-warning" onClick={() => this.toggleAppointmentsCrud('deadline_appointment')}>
                                    <i className="fas fa-cogs mr-2" /> Change
                                </button>
                            </div>
                        )}

                    </CardFooter>
                ): null }


                <CardFooter>
                    <h4 className="text-sm">Garnishment Followup Deadline Schedule in MICHAEL JAAFAR's Calendar 30 days after initial scheduled 341 hearing</h4>
                </CardFooter>

                {privileges.canModerateAppointments() ? (
                                    <CardFooter className="text-right">

                            <div>
                                {!garnishment.deadline_appointment_followup ? (
                                    <button style={{width: 120}} className="btn btn-danger" onClick={() => this.toggleAppointmentsCrud('deadline_appointment_followup')}>
                                        <i className="fas fa-exclamation-triangle mr-2" /> Schedule
                                    </button>
                                ) : (
                                    <div className="text-left">
                                        <p className="text-sm mb-2">
                                            {garnishment.deadline_appointment_followup ? (
                                                <span className="text-capitalize"><b>Set Appointment: </b> {garnishment.deadline_appointment_followup.name}</span>
                                            ) : 'Appointment Not Found'}
                                        </p>
                                        <button style={{width: 120}} className="btn btn-warning" onClick={() => this.toggleAppointmentsCrud('deadline_appointment_followup')}>
                                            <i className="fas fa-cogs mr-2" /> Change
                                        </button>
                                    </div>
                                )}
                            </div>
                            </CardFooter>

                        ) : null}

            </Card>

            {privileges.canModerateAppointments() && (
            <AppointmentsCrud
                toggleAppointmentsCrud={this.toggleAppointmentsCrud}
                showAppointmentsCrud={showAppointmentsCrud}
                appointment_id="new"
                title={"Create Appointment"}
                onSuccess={this.onAppointmentCreated}
                isModal={true}
            />
            )}

        </>

        );
    }
}

GarnishmentAppointments.propTypes = {
    garnishment: PropTypes.object.isRequired,
}

export default GarnishmentAppointments
