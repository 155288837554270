import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import _calendar from '_functions/calendar';

const SidebarRightDetails = ({contact}) => {

    const [calendar, setCalendar] = useState(null);

   useEffect(() => {
        
        const fetchAppointments = async () => {
            let data = await _calendar.query.byContacts(contact._id, '?queryFinished=true')
            const appointments = data.data;
            if(appointments) {
                appointments.sort((a, b) => a.date < b.date ? 1 : -1)
                setCalendar(appointments);
            }
        }

        fetchAppointments()

   }, [contact._id])

   if(!calendar) return <Circle />

    return (

        <div>

            <div className="archk-messenger-sidebar-section">
                <h3 className="mb-0"><i className="fas fa-gavel mr-2 text-success " />  Appointments</h3>
            </div>

            <ul>
                {calendar.length ? calendar.map(appointment => (

                    appointment.finished_by ? (
                        <div className="archk-messenger-sidebar-section bg-dark">
                            <p className="text-sm mb-0 text-white">
                                {moment.unix(appointment.date).format('MMM, Do YYYY - h:mm')}
                                {' - '}
                                {moment.unix(appointment.date_end).format('h:mm A')}
                            </p>
                            <p className="text-sm mb-0 text-white">
                                <span><i className="fas fa-check mr-2 text-success " />{appointment.name}</span>
                            </p>
                        </div>
                    ) : (
                        <a className="cursor-pointer archk-messenger-sidebar-hover-bg" href={`/dashboard/appointments/view/${appointment._id}`} target="_blank" rel="noopener noreferrer">
                            <div className="archk-messenger-sidebar-section ">
                                <p className="text-sm mb-0">
                                    {moment.unix(appointment.date).format('MMM, Do YYYY - h:mm')}
                                    {' - '}
                                    {moment.unix(appointment.date_end).format('h:mm A')}
                                </p>
                                <p className="text-sm mb-0">
                                    {appointment.name}
                                </p>
                            </div>
                        </a>
                    )

                )) : (
                    <div className="archk-messenger-sidebar-section ">
                        There are no appointments for this contact.
                    </div>
                )}
            </ul>

        </div>

    );

}

export default SidebarRightDetails;
