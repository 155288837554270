/*
Documentation

sets state of a component on an input change

value       = the changed values, usually e.target.value
stateName   = the name of the object to update
statefield  = the property on the stateName object to change
_this       = the this field on the object

*/

export default (value, stateName, stateField, _this) => {

    let newState = Object.assign({}, _this.state[stateName]);
    newState[stateField] = value;

    if (stateField === "checkbox") {
      if (value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (value === "") {
        newState[stateField + "State"] = "invalid";
      } else {
        newState[stateField + "State"] = "valid";
      }
    }

    _this.setState({ [stateName]: newState });

};
