/*
Documentation

This is the master file for creating contact
The functionality for this is held in views/dashboard/pages/contacts/ContactsCrud

*/

import React from "react";
import { Card, CardBody } from "reactstrap";
import contacts from '../../../../../../_functions/contacts'
import { toggleAlertBS } from 'store/functions/system/system'
import PropTypes from 'prop-types'

class ContactsUpdate extends React.Component {

    state = {
        alert: null
    }

    sendToCentury = async () => {
        const result = await contacts.century(this.props.contact._id)
        console.log(result)
        if(!result.success) return toggleAlertBS(true, 'An error occurred sending this contact to Century Debt Relief')
        toggleAlertBS(false, 'Information sent to Century Debt Relief')
    }

    render() {

        const { navPills } = this.props

        return (
            navPills === 50 ? (

                <Card className="card-color card-warning">

                    <CardBody>
                       <button onClick={this.sendToCentury} className="btn btn-success">Send Info To Century Debt Solutions</button>

                       <div className="pt-6">
                            <div>OR Transfer directly to the number (724) 765-5841: </div>
                       </div>
                       <div className="py-6">
                            <div>OR Schedule a time to talk with Century Debt Solutions at the link below: </div>
                       </div>

                       <div><a href="https://meetings.hubspot.com/debtrelief/debt-assessment" target="_blank" rel="noopener noreferrer">https://meetings.hubspot.com/debtrelief/debt-assessment</a></div>
                    </CardBody>

                </Card>

            ) : null
        );
    }
}

ContactsUpdate.propTypes = {
    fetchContact                  : PropTypes.func.isRequired,
    navPills                      : PropTypes.number.isRequired,
    contact                       : PropTypes.object.isRequired,
    shouldScrollToRelationships   : PropTypes.bool.isRequired,
}

export default ContactsUpdate
