

import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Input } from "reactstrap";
import * as _ from 'underscore';
import renderName from 'utils/renderName';
import _contacts from '_functions/contacts';
import { formatPhone } from 'utils/text'

class ContactSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 10,
            total_documents: 0,
            data: null,
            contactToDelete: null
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const contacts = await _contacts.search({
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: contacts.data,
            total_documents: contacts.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteContact = (contact) => {

        this.onTableChange(null, this.state)

    }


    columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <span onClick={() => this.props.onSelect(row)}  >
                    <div className="text-capitalize font-weight-bold"><i className="fas fa-user mr-2" />  {renderName(row)}</div>
                    <div className="text-muted"><i className="fas fa-envelope mr-2" /> {row.email ? row.email : '-'}</div>
                    <div className="text-muted"><i className="fas fa-phone mr-2" /> {row.phone ? formatPhone(row.phone) : '-'}</div>
                </span>

            ),
        },

        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <button onClick={() => this.props.onSelect(row)} className="btn btn-success">
                            Select
                        </button>
                    </div>
                )
            }
        },
    ]


    render() {

        const { data, loading } = this.state;

        return (
            <>

                <ToolkitProvider
                    data={data && data.length ? data : []}
                    keyField="_id"
                    columns={this.columns}
                    search
                >
                    {props => (
                        <>
                            <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                <label>
                                    Search:
                                    <Input
                                        className="form-control-sm"
                                        value={this.state.searchText}
                                        placeholder="Name, Email, Phone"
                                        onChange={(e) => {
                                            this.setState({searchText: e.target.value})
                                            this.queryTableDataThrottled(e.target.value)
                                        }}
                                    />
                                        {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                </label>
                            </div>

                            <div className="table-not-fixed table-responsive table-vertical-align">
                                <BootstrapTable
                                    pagination={paginationFactory({
                                        totalSize: this.state.total_documents,
                                        page: this.state.page,
                                        sizePerPage: this.state.sizePerPage,
                                        alwaysShowAllBtns: true,
                                        showTotal: false,
                                        withFirstAndLast: true,
                                        sizePerPageRenderer: () => false
                                    })}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                    remote={{
                                        search: true,
                                        pagination: true,
                                        sort: false,
                                        cellEdit: false
                                    }}
                                    onTableChange={this.onTableChange}

                                />

                                {!this.state.searchText ? <p className="text-sm ml-4 mt--3">Type to search for a contact</p> : null}
                            </div>
                        </>
                    )}
                </ToolkitProvider>

            </>
        );
    }
}

export default ContactSearch
