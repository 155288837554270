import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'

class LocationHearingSearchInput extends React.Component {

    state = {
        filter: null,
        showResults: false,
        selected: {},
        dataToShow: []
    }

    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    onChange = (value) => {

        let data = this.props.location_hearings || [];
        data = [...data]

        if(value && value !== null) {

            let filter = value.toLowerCase()
            this.setState({dataToShow: data.filter(c =>  c.name.toLowerCase().includes(filter))})

        } else {

            this.setState({dataToShow: data })

        }

    }

    componentDidMount = () => {
        const { location_hearings, value } = this.props
        this.setState({ dataToShow: location_hearings, selected: value ? value : {} })
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                onSelect={this.onSelect}
                placeholderFormatter={selected ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Hearing Location: <b className="text-success">{selected.name}</b></span> : 
                        'Select Hearing Location'
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        location_hearings: state.location_hearings.location_hearings,
    };
};

LocationHearingSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default connect(mapStateToProps, '')(LocationHearingSearchInput);
