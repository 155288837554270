/*
Documentation

*/


import Circle from 'components/markup/loading/Circle';
import React from 'react';
import { connect } from 'react-redux';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import renderName from 'utils/renderName'
import _users from '_functions/users'
import AvatarImage from 'components/functional/images/AvatarImage';
import { Helmet } from 'react-helmet';
import { capitalize } from 'utils/text';
import { setBrowserDialing } from 'store/functions/system/system';
import ConfirmationModal from 'components/functional/modals/Confirmation.js'
import * as privileges from '_functions/users/privileges'
import { Device } from 'twilio-client';

import _activity from '_functions/activity'

class CasesView extends React.Component {

    state = {
        showConfirmationModal: true,
        dialingSetUp: false,
        searchingUser: false,
        lastEvent: 0,
        foundUser: null,
        hasHTML: false,
        crashPage: false
    }

    interval;
    currentTimeInterval;

    listenForScreenWatchUpdated = (data) => {

        const payload = data.data

        if(payload.user_id !== this.props.match.params.user_id) return

        this.setState({
            hasHTML: true,
            url: payload.url,
            pageWidth: payload.pageWidth,
            // account for header on page
            pageHeight: payload.pageHeight - 80,
            mousePosition: payload.mousePosition,
            lastEvent: Math.floor(new Date() / 1000)
        }, () => {

            const span = payload.mousePosition ? `<i class="fas fa-mouse-pointer text-warning"
            style="position: absolute; top: ${payload.mousePosition.y}px; left: ${payload.mousePosition.x}px; display: inline; z-index: 1000000000000000000000; font-size: 20px;"/>` : ''

            // set the iframe contents to be the html send from server
            var doc = document.getElementById('iframe').contentWindow.document;
            doc.getElementById('screenwatch-body').innerHTML = payload.html + span

            // scroll the iframe to where the user is viewing
            document.getElementById('iframe').contentWindow.scrollTo(0,payload.pageOffset);

        })
    }

    listenForScreenWatchSet = (data) => {

        this.setState({searchingUser: data.data && data.data.includes(this.props.match.params.user_id) ? true : false})

    }

    findUser = async () => {

        const foundUser = await _users.findById(this.props.match.params.user_id)
        if(foundUser.data) this.setState({foundUser: foundUser.data})

    }

    setIframeOnLoad = () => {
        const doc = document.getElementById('iframe').contentWindow.document;
        doc.open();
        doc.write(document.head.outerHTML + '<style>.react-select {max-height: 35px;overflow: hidden;} .modal.fade {-webkit-transition: none;-moz-transition: none;-ms-transition: none;-o-transition: none;transition: none;}</style><div id="screenwatch-body"></div>');
        doc.close();
    }

    listenForUserEnteredCall = (data) => {

        // if we are set up and the user searches a call dial them in as muted
        if(this.state.dialingSetUp && this.state.searchingUser && data.data && data.data._id === this.props.match.params.user_id) {

            const user = data.data;

            if(user && user.call_contact) {
                Device.connect({
                    user: this.props.viewing_user._id,
                    contact: user.call_contact._id,
                    isTransfer: true,
                    isMuted: true
                });
            }

        }
    }

    listenForUserExitedCall = (data) => {

        if(data.data && data.data._id === this.props.match.params.user_id) {
            try { Device.disconnectAll() } catch(e) {}
        }

    }

    setIsWatching = () => {

        _activity.screen_watch.setWatching({
            user_id: this.props.match.params.user_id,
            admin_id: this.props.viewing_user._id
        })

        this.currentTimeInterval = setInterval(() => {
            this.setState({currentTime: Math.floor(new Date() / 1000)})
        }, 1000)

        this.interval = setInterval(() => {
            _activity.screen_watch.setWatching({
                user_id: this.props.match.params.user_id,
                admin_id: this.props.viewing_user._id
            })
        }, 60000)

    }

    componentDidMount = async () => {

        if(!this.props.viewing_user) return this.setState({crashPage: true})
        if(!privileges.canScreenWatch()) return this.setState({crashPage: true})

        document.body.style.background = 'rgba(82,82,82)'

        this.findUser()
        this.setIframeOnLoad()
        this.setIsWatching()

        io.on(socketEvents.screen_watch_set, this.listenForScreenWatchSet)
        io.on(socketEvents.screen_watch_updated, this.listenForScreenWatchUpdated)
        io.on(socketEvents.user_entered_call, this.listenForUserEnteredCall)
        io.on(socketEvents.user_exited_call, this.listenForUserExitedCall)

        // if tab is unfocused stop listening, stop pinging server from here
        // if the tab comes into focus start listening
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.setIsWatching()
            } else {
                clearInterval(this.interval)
                clearInterval(this.currentTimeInterval)
            }
        })


    }

    render() {

        const {  pageWidth, pageHeight, searchingUser, lastEvent, currentTime, foundUser, hasHTML, showConfirmationModal, crashPage } = this.state
        const onScreen = lastEvent > currentTime - 2 ? true : false

        const height = pageHeight > window.innerHeight ? window.innerHeight : pageHeight

        if(crashPage) return <div className="py-6"><h1 className="text-center text-danger">Unauthorized</h1></div>

        return (

            <>

            {foundUser ? (
                <Helmet>
                    <title>{`!WATCH! ${capitalize(renderName(foundUser))}`}</title>
                    <meta name="description" content="Call Center Admin" />
                </Helmet>
            ) : null}

            <div className={hasHTML ? "": "container"} style={{...styles.container, width: pageWidth, height}}>

                <Card className="mb-0" style={styles.card}>
                    <CardHeader className="bg-dark">
                        <CardTitle className="mb-0 text-capitalize text-center text-white">
                            {foundUser ? (
                                <span>
                                    <AvatarImage
                                        style={{width: 30, height: 30}}
                                        className="ml-2 mr-3 z-depth-4"
                                        src={foundUser.avatar}
                                    />
                                    {renderName(foundUser)}

                                </span>
                             ) : ''} | {' '}

                            <span className="text-muted">Trying Connection:</span> {' '}
                            {searchingUser ? <span className="text-success ">Yes</span> : <span className="text-danger ">No</span>} | {' '}

                            <span className="text-muted">User On Screen:</span> {' '}
                            {onScreen ? <span className="text-success ">Yes</span> : <span className="text-danger ">No</span>} {' '}
                        </CardTitle>
                    </CardHeader>
                </Card>

                <div className="z-depth-4 position-relative"  style={{display: hasHTML ? 'block': 'none'}}>
                    <div style={styles.overlay} />
                    {!onScreen ? <div style={styles.notOnScreen} /> : null}
                    <iframe id="iframe" title="iframe" style={{...styles.iframe, height}} />
                </div>

                {!hasHTML ? (
                    <div className="text-center">
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Waiting for feed</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Circle />
                            </CardBody>
                        </Card>
                    </div>
                ): null}

            </div>

            <ConfirmationModal
                showModal={showConfirmationModal}
                toggleModal={() => this.setState({showConfirmationModal: !showConfirmationModal})}
                title="Browser Dialing Consent"
                body={<span>Do you wish to listen to this user's phone calls?</span>}
                onConfirmation={() => {
                    setBrowserDialing(this.props.viewing_user._id)
                    this.setState({dialingSetUp: true})
                }}
            />

            </>

        );
    }
}

const styles = {
    iframe: {
        width: '100%',
        margin: 'auto',
        position: 'relative',
        border: 'none',
        outline: 'none'
    },
    container: {
        margin: 'auto',
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2
    },
    notOnScreen: {
        position: 'absolute',
        background: 'rgba(255,255,255,.5)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 3
    },
    card: {
        border: 'solid 1px #777',
        boxShadow: 'none'
    }
}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(React.memo(CasesView));

