/*
Documentation

this page renders the view for users to view all tasks assigned to them

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, Container, Row, Col, Modal } from "reactstrap";

const videos = [

    {title    : "Uploading Documents", src: "https://www.youtube.com/embed/0r6Mifdngac" },
    {title    : "Unassigned Documents", src: "https://www.youtube.com/embed/vfUiTR4BQRc" },
    {title    : "Document Signing", src: "https://www.youtube.com/embed/CCObIVwf1Zc" },
    {title    : "Missing Docs", src: "https://www.youtube.com/embed/7m3REMrDtjA" },
    {title    : "Document Merging", src: "https://www.youtube.com/embed/frpCqBep37g" },

    {title    : "Moving Steps", src: "https://www.youtube.com/embed/I6NLraTBAvQ" },
    {title    : "Contact Forms", src: "https://www.youtube.com/embed/J7DSBVbSLFk" },
    {title    : "Calendar", src: "https://www.youtube.com/embed/_5V8Nw7Y76E" },
    
    {title    : "Case Templates", src: "https://www.youtube.com/embed/MKYFMxJLVzM" },

]

const Iframe = (props) => (
    <div className="video-container  z-depth-2 position-relative">
        {props.onClick && <div onClick={props.onClick} style={styles.overlay} />}
        <iframe 
            src={props.src} 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            title="help video"
        />
    </div>
)

const IframeWrapper = (props) => (
   <Col lg={3}>
       <Card>
           <CardHeader>
               <CardTitle className="mb-0">{props.title}</CardTitle>
           </CardHeader>
            <Iframe {...props} />
       </Card>
   </Col>
)

class Help extends React.Component {

    state = {
        showModal: false,
        index: 0
    }
   
    toggleModal = (index) => this.setState({showModal: !this.state.showModal, index})

    render() {

        const { showModal, index } = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Help Center`}</title>
                    <meta name="description" content="Help Center" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Help Center</span>}
                />


                <Row>
                    {videos.map((video, index) => (
                        <IframeWrapper 
                            onClick={() => this.toggleModal(index)} 
                            title={video.title} 
                            src={video.src} 
                        />
                    ))}
                </Row>

                <Modal
                    className="modal-dialog-centered modal-help"
                    isOpen={showModal}
                    toggle={() => this.toggleModal(0)}
                    size="lg"
                >

                    <div className="modal-header">
                        <h5 className="modal-title">{videos[index].title}</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal(0)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Iframe 
                            title={videos[index].title} 
                            src={videos[index].src} 
                        />
                    </div>

                    <div className="modal-footer text-right">
                        <button className="btn btn-warning" onClick={() => this.toggleModal(0)}>
                            Close
                        </button>
                    </div>

                </Modal>

            </Container>

            </>
        );
    }
}

const styles = {

    overlay: {
        zIndex: 2,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0,.2)'
    }

}

export default Help
