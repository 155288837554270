import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import LocationHearingSearchInput from 'views/dashboard/pages/_components/LocationHearingSearchInput';

class AppointmentsCrudHearingLocation extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.location_hearing) return true
            if(appointment.location_hearing && !appointment.location_hearing._id) return true;

        }

        return false

    }

    render() {

        const { appointment, locationHearingRequired, onAssignLocationHearing, onRemoveLocationHearing } = this.props

        if(!locationHearingRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign Location Hearing</h4>
                        <LocationHearingSearchInput
                            value={appointment.location_hearing ? appointment.location_hearing : {}}
                            onSelect={(location_hearing) => onAssignLocationHearing(location_hearing)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a location hearing before proceeding.</div>
                    ) : null}
                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Hearing Location</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {appointment.location_hearing && appointment.location_hearing._id ? (
                                    <tr>
                                    <td className="text-capitalize">
                                        {appointment.location_hearing ? (
                                            <div>
                                                {appointment.location_hearing.name}
                                                    <div>

                                                        {appointment.location_hearing.address_line_1} {' '}
                                                        {appointment.location_hearing.address_line_2 ? appointment.location_hearing.address_line_2 : null}
                                                    </div>
                                                    <div>
                                                        {appointment.location_hearing.city}, {appointment.location_hearing.state} {appointment.location_hearing.postal_code}
                                                    </div>
                                            </div>

                                            ) : 'HEARING LOCATION NOT FOUND'}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveLocationHearing()}/>
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Hearing Location Assigned</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudHearingLocation.propTypes = {
    locationHearingRequired   : PropTypes.func.isRequired,
    onAssignLocationHearing   : PropTypes.func.isRequired,
    onRemoveLocationHearing   : PropTypes.func.isRequired,
    appointment               : PropTypes.object.isRequired,
}

export default AppointmentsCrudHearingLocation
