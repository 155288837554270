/*
Documentation

this file renders the system Call Center

*/

import Circle from 'components/markup/loading/Circle';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import renderName from 'utils/renderName';
import { setUrlParameter, getUrlParameter } from 'utils/urls';
import _users from '_functions/users';
import _contacts from '_functions/contacts';
import _appointments from '_functions/appointments';
import _voicemail from '_functions/voicemail';
import { architeckCall } from 'database';
import { toggleAlertBS } from 'store/functions/system/system'
import * as privileges from '_functions/users/privileges';

// import InfoBar from './components/not_on_call/InfoBar';
// import NotOnCall from './components/not_on_call/NotOnCall';
import SidebarNotOnCall from './components/not_on_call/SidebarNotOnCall';
import BrowserDialingConsent from './components/not_on_call/BrowserDialingConsent';
import ConfirmCall from './components/modals/ConfirmCall';
import keys from 'keys';

import SidebarOnCall from './components/on_call/SidebarOnCall';
import OnCall from './components/on_call/OnCall';

import moment from 'moment';
import ModalBrowserDialing from './components/not_on_call/ModalBrowserDialing';
import { setViewingUser } from 'store/functions/auth/auth';

// import { beep } from 'utils/sounds'

import * as footer from 'utils/footer'
import * as callCenterActions from 'store/functions/call_center/call_center'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';
import _collections from '_functions/collections';

import { Device } from 'twilio-client';


import Mobile from './mobile';

class CallCenterUsers extends React.Component {

    state = {

        callsNewContacts            : privileges.callsNewContacts(),
        callsBusiness               : privileges.callsBusiness(),
        callAppointments            : privileges.callAppointments(),
        callsContacts               : privileges.callsContacts(),
        voicemailCount              : 0,

        questionnaire               : null,
        loading                     : true,
        division                    : null,

        ConferenceSid               : null,
        callCenterCount             : null,

        showKeypad                  : false,
        showModalConsent            : false,
        onCall                      : false,
        call_center                 : {},

        appointments                : null,
        contact                     : null,
        queueData                   : null,

        showBrowserDialingConsent   : false,
        showModalConfirm            : false,
        showModalConfirmData        : {},
        showModalConfirmContact     : {},

        callType                    : null,
        noCallsOpen                 : ''
    }

    refreshInterval = null

    canCallNewContacts = () =>  this.props.viewing_user.privileges.includes('calls_new_contacts') ? true : false;
    canCallContacts    = () =>  this.props.viewing_user.privileges.includes('calls_contacts') ? true : false;
    canCallBusiness    = () =>  this.props.viewing_user.privileges.includes('calls_business') ? true : false;

    toggleModalConfirm = (showModalConfirmData, showModalConfirmContact) => {
        this.setState({showModalConfirm: !this.state.showModalConfirm, showModalConfirmData, showModalConfirmContact})
    }

    // open/close the dialing keypad
    toggleKeypad = () => {

        this.setState({showKeypad: !this.state.showKeypad}, () => {
            const input = document.getElementById('cc-keypad-input')
            if(input) input.focus()
        })

    }

    // self explanatory.
    // toggleEndCallModal = () => this.setState({showModal: !this.state.showModal})
    toggleModalConsent = (onConfirmConsent) => this.setState({showModalConsent: !this.state.showModalConsent, onConfirmConsent})

    onConfirmModalConsent = () => {
        this.state.onConfirmConsent()
        this.setState({showModalConsent: false, onConfirmConsent: null})
    }

    onConfirmCall = () => {
        this.toggleModalConfirm()
        this.createPhoneConnection(this.state.showModalConfirmData)
    }

    // get appointments that are starting now or before AND
    // are assigned to the user viewing the page
    getAppointments = async () => {

        const user_id = this.props.viewing_user._id

        const appointments = await _appointments.findByAssignedTo(user_id, `?current=true`)
        if(appointments.success) this.setState({appointments: appointments.data})

    }

    // this queries the db to get the number of different call that
    // are potentially open for a user to select
    setQueueData = async () => {
        // const queueData = await architeckCall({
        //     method: 'get',
        //     url: `/api/v1/call_center/call_queue/callQueueGetEntry?user=${this.props.viewing_user._id}&company=${this.props.viewing_user.company}`
        // })
        // if(queueData.success) this.setState({queueData: queueData.data})
    }

    queryVoicemail = async () => {
    
        const voicemail = await _voicemail.find(`?filter=listened__false{bool}&limit=1`);
        if(voicemail.success) this.setState({voicemailCount: voicemail.total_documents})
    
    }

    // if we query the db and no calls are open, refresh the queue to reflect this
    // and then set a four second timing showing no calls are open
    showNoCallOpen = (message) => {

        this.setQueueData()

        this.setState({noCallsOpen: message}, () => {
            setTimeout(() => {this.setState({noCallsOpen: ''})}, 4000)
        })

    }

    // first checks to see if we need to show the user consent for browser dialing
    // second checks to see if consent was given but their is an error with browser dial
    // third, if 1 and 2 pass sets state show users can begin taking calls
    checkForBrowserDialingError = (props) => {

        const { browser_dialing_status, viewing_user } = props

        if(browser_dialing_status === 'not loaded' && viewing_user.call_phone_default !== 'phone') {

            this.setState({ showBrowserDialingConsent: true })

        }

        // if user is dialing by browser and an error occurred loading it prompt to change settings
        else if(browser_dialing_status !== 'ready' && viewing_user.call_phone_default !== 'phone') {
            toggleAlertBS(true, (
                <>
                An error occurred setting up your browser dialing. Please Try Refreshing Your Page<br />
                <a className="font-weight-bold text-underline pt-4" target="_blank" rel="noopener noreferrer" href="/dashboard/settings">
                    You may also click here set your default calling type to "Phone" and continue making calls.
                </a>
                </>
            ))

        } else {

            this.setCanTakeCalls(props)
            this.setState({ showBrowserDialingConsent: false })

        }

    }

    // if a user's call_phone_default is phone automatically set ability to take
    // calls to true, else only set that status if they have accepted browser dialing
    // props are what gets passed in through react redux
    setCanTakeCalls = (props) => {

        const { viewing_user, browser_dialing_status } = props
        const canTakeCalls = viewing_user.call_phone_default === 'phone' || browser_dialing_status === 'ready'
        this.setState({canTakeCalls})

        return canTakeCalls

    }

    // if currently on a call we run a call to the db to end the call
    // also tries to disconnect all client browser dialing calls
    // sets state to revert call center back to showing off call status
    // optionally toggles the end call modal.
    // also make sure we hide the footer and set body styling back after
    finishCall = () => {

        footer.hide()

        document.body.style.minHeight = 0
        document.body.style.paddingBottom = 0

        this.setState({
            showKeypad: false,
            onCall: false,
            appointment: null,
            contact: null,
        })

        // when call is finished refresh the queue data for the 
        // info bar at the top of the screen
        this.setQueueData()

    }

    // runs if starting and appointment and it has a property of
    // .in_person that is true
    startInPersonAppointment = async (data) => {

        const appointment = data.appointment;
        const contact = data.contact;

        if(contact) {

            _users.update(this.props.viewing_user._id, {
                call_must_review: true,
                call_status: 'on call',
                call_start: Math.floor(new Date() / 1000),
                call_appointment: appointment._id,
                call_appointment_in_person: appointment._id,
                call_appointment_previous: appointment._id,
                call_contact: contact._id,
                call_contact_previous: contact._id
            })

            setUrlParameter('contact', contact._id)

            callCenterActions.setOnCall({
                contact: contact,
                contact_name: renderName(contact),
                contact_phone: contact.phone,
                contact_email: contact.email,
                finishCall: this.finishCall,
            })

            this.setState({
                appointment: appointment,
                contact: contact._id,
                contact_name: renderName(contact),
                contact_email: contact.email,
                contact_phone: contact.phone,
                callType: data.type,
                showKeypad: false,
                onCall: true,
                noCallsOpen: ''
            })

        }

    }

    // connects a user to a contact based on data sent in and
    // also switches between phone and browser dialing
    // and sets state to show on call status
    // @param data
    // .user -> user_id
    // .phone -> phone number must be set if not contact or appointment
    // .contact -> contact_id must be set if not phone or appointment
    // .appointment -> appointment_id must be set if not contact or phone

    createPhoneConnection = async (data) => {

        if(this.props.viewing_user.call_phone_default === 'browser' && !data.isMobile) {

            Device.connect(data);
            if(this.state.showKeypad) this.toggleKeypad()

            // manually check if user is on call after 5 seconds
            // if they are force the page to change as socket fails sometimes
            // this is a known bug being looked into

            setTimeout(async () => {
                const user = await setViewingUser();
                if(user.data && user.data.call_conference) this.setCallData(user.data);
            }, 5000)

        } else {

            const call = await architeckCall({
                shouldNotShowAndLogError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/calls/create',
                data
            })

            if(call.success) {

                const { contact, appointment, division, type, questionnaire } = call.data

                if(contact && contact.phone) {

                    callCenterActions.setOnCall({
                        contact: contact,
                        contact_name: renderName(contact),
                        contact_phone: contact.phone,
                        contact_email: contact.email,
                        finishCall: this.finishCall,
                        questionnaire,
                        division,
                        callType: type
                    })

                    // set contact info, then
                    this.setState({
                        appointment,
                        contact: contact._id,
                        contact_name: renderName(contact),
                        contact_email: contact.email,
                        contact_phone: contact.phone,
                        questionnaire,
                        showKeypad: false,
                        onCall: true,
                        noCallsOpen: '',
                        division,
                        callType: type
                    })

                    setUrlParameter('contact', contact._id)

                } else {

                    this.showNoCallOpen(call.user_message ? call.user_message : 'No Calls Currently Open')

                }

            } else {
                this.showNoCallOpen(call.user_message ? call.user_message : 'No Calls Currently Open')
            }

        }

    }

    getCallPreview = async (data) => new Promise (async resolve => {
    
        const call = await architeckCall({
            shouldNotShowAndLogError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/calls/create',
            data: {
                ...data,
                isPreview: true
            }
        })

        return resolve(call.data)
    
    })

    // for data params see params of this.createPhoneConnection
    setOnCall = async (data, event) => {

        const preview = await this.getCallPreview(data)
        if(!preview) return toggleAlertBS(true, 'Please refresh your page before getting your next call.')

        if(this.state.showKeypad) this.setState({showKeypad: false});

        if(preview.appointment && preview.appointment.in_person) {

            this.startInPersonAppointment(preview)
            if(event) _analytics.events.create({event});

        } else {

            if(preview && preview.contact && preview.contact.opt_out_text) {
                this.toggleModalConfirm(data, preview.contact)
            } else {
                this.createPhoneConnection(data)
                if(event) _analytics.events.create({event});
            }
        }

    }

    // this functions tries to create a call as soon as this page loads if
    // url params are set to do so
    setCallOnLoad = () => {

        // if user needs to review or is on an in person or phone call don't do anything
        const { call_must_review, call_conference, call_appointment_in_person, call_phone_default } = this.props.viewing_user;
        if(call_must_review || call_conference || call_appointment_in_person) return

        const collectionId = getUrlParameter('collectionId');

        if(getUrlParameter('startCall') === 'true') {

            let phone         = getUrlParameter('phone')        || undefined
            let appointment   = getUrlParameter('appointment')  || undefined
            let inPerson      = getUrlParameter('inPerson')     || undefined
            let contact       = getUrlParameter('contact')      || undefined
            let isTransfer    = getUrlParameter('isTransfer')   || undefined

            if(phone) phone = '+' + phone.replace(' ', '')
            inPerson = inPerson === 'true' ? true : undefined

            // remove start call parameters here
            window.history.pushState('', '', '/dashboard/call_center')

            if(phone || appointment || contact) {

                // set the call to create as a function to be used or stored in state
                // if browser dialing has been set up go right into the call
                // if not store as function to run after consent modal is confirmed
                const createCall = () => {
                    
                    // here is a manual dial out
                    const event = inPerson ? ANALYTIC_EVENTS.DIAL_IN_PERSON : ANALYTIC_EVENTS.DIAL_MANUAL;

                    if(collectionId) {
                        _collections.setCalled(collectionId)
                    }

                    const callProps = {
                        phone, appointment, contact, user: this.props.viewing_user._id
                    }

                    if(isTransfer === 'true') {
                        callProps.isTransfer = 'true'
                    }

                    this.setOnCall(callProps, event)
                }

                if(this.state.canTakeCalls || inPerson || call_phone_default === 'phone') {

                    createCall()
                    this.setState({onConfirmConsent: null})

                } else {

                    if(this.setCanTakeCalls(this.props)) {
                        createCall()
                    } else {
                        this.toggleModalConsent(createCall)
                    }

                }

            }

        }

    }

    // sets the view of the call center to being on call
    setCallData = async (viewing_user) => {
        return new Promise (async resolve => {

            const user = viewing_user;

            // if user.call_conference or user.call_appointment_in_person is set it means the user should be on a call / in person meeting
            // that means we should show the call screen. call_contact is just a check to make sure we have the data we need
            // in theory it should always be set if condition 1 is true
            if((user.call_conference || user.call_appointment_in_person) && user.call_contact && user.call_contact._id) {

                const values = await Promise.all([
                    _contacts.findById(user.call_contact._id),
                    _appointments.findById(user.call_conference ?  user.call_appointment_to_accept : user.call_appointment_in_person, true),
                ])

                const contact = values[0].data;
                const appointment = values[1];

                const { ConferenceSid, questionnaire, division, callType } = this.props.call_center.call_status;

                if(contact) {

                    callCenterActions.setOnCall({
                        contact: contact,
                        contact_name: renderName(contact),
                        contact_phone: contact.phone,
                        contact_email: contact.email,
                        finishCall: this.finishCall,
                        ConferenceSid,
                        questionnaire,
                        division,
                        callType
                    })
    
                    this.setState({
                        onCall: true,
                        appointment: appointment.data ? appointment.data : null,
                        contact: user.call_contact._id,
                        contact_name: renderName(contact),
                        contact_email: contact.email,
                        contact_phone: contact.phone,
                        questionnaire,
                        division,
                        callType
                    }, () => resolve(true))

                }

            } else {
                resolve(false)
            }

        })
    }

    // tryBeep = (oldData, newData) => {

    //     let shouldBeep = false

    //     if(oldData && newData) {
    //         if(oldData.callQueue   < newData.callQueue) shouldBeep   = true;
    //         if(oldData.newContacts < newData.newContacts) shouldBeep = true;
    //         if(oldData.business    < newData.business) shouldBeep    = true;
    //     }

    //     if(shouldBeep) {

    //         beep();
    //         setTimeout(() => beep(), 200);

    //     }

    // }

    // when a user ends a call make sure its matches the viewing user, if so end the call
    listenForUserEndedCall = (data) => {
        if(data.data === this.props.viewing_user._id) this.finishCall()
    }

    // if appointment is deleted try to remove it from state
    listenForAppointmentsDeleted = (data) => {
        return new Promise (async resolve => {

            const appointment = data.data

            let appointments = [...this.state.appointments]
            appointments = appointments.filter(a => a._id !== appointment._id)

            this.setState({ appointments }, () => resolve())

        })
    }

    // if an appointment is added and is within the time to show
    // on the call center and it is assigned to the user
    // viewing this page push it to state
    listenForAppointmentsAdded = (data) => {

        const appointment = data.data

        const time = Math.floor(new Date() / 1000)
        const day_end = moment.unix(time).endOf('day').format('X')

        if(appointment && this.state.appointments) {

            if(appointment.date <= day_end) {

                const appointments = [...this.state.appointments];
                const assigned_to = appointment.assigned_to;

                if(assigned_to && assigned_to.length && assigned_to.find(a => a._id === this.props.viewing_user._id)) {
                    appointments.push(appointment)
                }

                appointments.sort((a, b) => a.date < b.date ? -1 : 1)
                this.setState({ appointments })

            }

        }

    }

    // when an appointment is updated try to remove it from state and
    // then add it back in. Can be optimized later
    listenForAppointmentsUpdated = async (data) => {

        this.listenForAppointmentsDeleted(data)
        this.listenForAppointmentsAdded(data)

    }

    // this should change the screen from the data sent back from the browser call
    // this is how we now a browser call started
    listenForTwilioBrowserCallStarted = (data) => {

        const user = data.data.user;

        if(user._id === this.props.viewing_user._id) {

            const {contact, appointment, division, questionnaire, type } = data.data

            const phone = contact.phone ? contact.phone : contact.phone_2 ? contact.phone_2 : contact.phone_3 ? contact.phone_3 : null

            if(contact && phone) {

                callCenterActions.setOnCall({
                    contact: contact,
                    contact_name: renderName(contact),
                    contact_phone: phone,
                    contact_email: contact.email,
                    finishCall: this.finishCall,
                    questionnaire,
                    division,
                    callType: type
                })

                // set contact info, then
                this.setState({
                    appointment,
                    contact: contact._id,
                    contact_name: renderName(contact),
                    contact_email: contact.email,
                    contact_phone: phone,
                    questionnaire,
                    division: division,
                    showKeypad: false,
                    onCall: true,
                    noCallsOpen: '',
                    callType: data.data.type
                })

                setUrlParameter('contact', contact._id)

            }

        }

    }

    // hangs up on the client side if no calls are found or are manually ended
    // this is how we now a browser call ended
    listenForTwilioBrowserCallEnded = (data) => {

        const { viewing_user } = this.props

        if(data.data.user_id === viewing_user._id) {

            // set this to assume we want to show the end appointments modal
            let dontShowModal = false;

            if(data.data.reason === 'no call found' || data.data.user_message) {

                const message = data.data.user_message ? data.data.user_message : 'No Calls Currently Open';

                dontShowModal = true
                this.showNoCallOpen(message)

            }

            this.finishCall(viewing_user.call_conference, dontShowModal)

        }

    }

    listenForVoicemailUpdated = (data) => {

        let { voicemailCount } = this.state

        if(data && data.data && data.data.listened !== undefined) {

            if(data.data.listened === false) {
                voicemailCount++
            } else if(data.data.listened === true) {
                voicemailCount--
            }
            
            // don't let count go below zero, just in case
            if(voicemailCount >= 0) this.setState({voicemailCount})

        }

    }

    listenForVoicemailAdded   = () => {
        let { voicemailCount } = this.state
        voicemailCount++

        this.setState({voicemailCount})
    }

    // here we make a call to find unlistend voicemail but limit
    // the results to 1 document. this will return the total_documents
    // property which is all we need


    listenForCallQueueDelete = (data) => {

        const queue = data ? data.data : null;
        const queueData = Object.assign({}, this.state.queueData)



        if(queue && queue.filter && queue.filter.type) {

            switch (queue.filter.type) {
                case 'new contact on hold': this.canCallNewContacts() && this.state.callsNewContacts && queueData.callQueue--;   break;
                case 'new contact':         this.canCallNewContacts() && this.state.callsNewContacts && queueData.newContacts--; break;
                case 'business':            this.canCallBusiness()    && this.state.callsBusiness    && queueData.business--;    break;
                case 'business call back':  this.canCallBusiness()    && this.state.callsBusiness    && queueData.business--;    break;
                case 'call back':           this.canCallContacts()    && this.state.callsContacts    && queueData.callQueue--;   break;
                case 'on hold':             this.canCallContacts()    && this.state.callsContacts    && queueData.callQueue--;   break;
            
                default: return
            }

            // if sockets fire multiple times let 0 be the lowest it gets
            if(queueData.callsNewContacts < 0) queueData.callsNewContacts = 0;
            if(queueData.callsBusiness < 0)    queueData.callsBusiness    = 0;
            if(queueData.callsContacts < 0)    queueData.callsContacts    = 0;

            this.setState({queueData})

        }

    }

    listenForCallQueueAdded = (data) => {

        const queue = data ? data.data : null;
        const queueData = Object.assign({}, this.state.queueData);

        if(queue && queue.type && queueData) {

            switch (queue.type) {
                case 'new contact on hold': this.canCallNewContacts() && this.state.callsNewContacts && queueData.callQueue++;   break;
                case 'new contact':         this.canCallNewContacts() && this.state.callsNewContacts && queueData.newContacts++; break;
                case 'business':            this.canCallBusiness()    && this.state.callsBusiness    && queueData.business++;    break;
                case 'business call back':  this.canCallBusiness()    && this.state.callsBusiness    && queueData.business++;    break;
                case 'call back':           this.canCallContacts()    && this.state.callsContacts    && queueData.callQueue++;   break;
                case 'on hold':             this.canCallContacts()    && this.state.callsContacts    && queueData.callQueue++;   break;
            
                default: return
            }

            // this.tryBeep(this.state.queueData, queueData)
    
            this.setState({queueData})

        }

    }

    initListeners = () => {
        io.on(socketEvents.user_ended_call,             this.listenForUserEndedCall)
        io.on(socketEvents.call_queue_deleted,          this.listenForCallQueueDelete);
        io.on(socketEvents.call_queue_added,            this.listenForCallQueueAdded);
        io.on(socketEvents.twilio_browser_call_started, this.listenForTwilioBrowserCallStarted);
        io.on(socketEvents.twilio_browser_call_ended,   this.listenForTwilioBrowserCallEnded);
        io.on(socketEvents.appointments_added,          this.listenForAppointmentsAdded);
        io.on(socketEvents.appointments_deleted,        this.listenForAppointmentsDeleted);
        io.on(socketEvents.appointments_updated,        this.listenForAppointmentsUpdated);
    }

    componentWillReceiveProps = (nextProps) => {

        const call_contact = this.props.viewing_user.call_contact
        const nextCallContact = nextProps.viewing_user.call_contact

        const browser_dialing_status = this.props.browser_dialing_status
        const nextBrowserDialingStatus = nextProps.browser_dialing_status

        if(call_contact && nextCallContact && call_contact._id !== nextCallContact._id) {
            this.setCallData(nextProps.viewing_user)
        }

        if(browser_dialing_status !== nextBrowserDialingStatus) {
            this.checkForBrowserDialingError(nextProps)
        }

    }

    componentWillUnmount = () => {

        io.off(socketEvents.user_ended_call,             this.listenForUserEndedCall)
        io.off(socketEvents.call_queue_deleted,          this.listenForCallQueueDelete);
        io.off(socketEvents.call_queue_added,            this.listenForCallQueueAdded);
        io.off(socketEvents.twilio_browser_call_started, this.listenForTwilioBrowserCallStarted);
        io.off(socketEvents.twilio_browser_call_ended,   this.listenForTwilioBrowserCallEnded);
        io.off(socketEvents.appointments_added,          this.listenForAppointmentsAdded);
        io.off(socketEvents.appointments_deleted,        this.listenForAppointmentsDeleted);
        io.off(socketEvents.appointments_updated,        this.listenForAppointmentsUpdated);

        // clear refresh interval for call queue values
        clearInterval(this.refreshInterval)

        footer.show()
        document.body.style.minHeight = '100vh'
        document.body.style.paddingBottom = '20px'

    }

    componentDidMount = async () => {

        footer.hide()

        document.body.style.minHeight = 0
        document.body.style.paddingBottom = 0

        this.queryVoicemail()
        this.setQueueData()
        this.getAppointments()
        this.initListeners()

        this.checkForBrowserDialingError(this.props)
        this.setCanTakeCalls(this.props)

        this.setCallOnLoad()

        await this.setCallData(this.props.viewing_user)

        this.setState({loading: false})

        // set interval to refresh call queue entries once every 10 seconds
        this.refreshInterval = setInterval(() => this.setQueueData(), 10000)

      
    }

    render() {

        const { showKeypad, onCall,  contact, appointments, canTakeCalls, showModalConsent, loading, division, questionnaire } = this.state
        const { callType, showModalConfirmData, appointment, noCallsOpen, queueData, showBrowserDialingConsent } = this.state
        const { voicemailCount, showModalConfirm, showModalConfirmContact } = this.state
        const { isMobile } = this.props.device.info

        const { viewing_user } = this.props

        if(loading) return <div className="pt-6"><Circle /></div>

        if(isMobile) {

            if(privileges.canCallOnMobile()) {

                return (
                    <Mobile 
                        setOnCall={this.setOnCall}
                    />
                )

            } else {
                return (
                    <div className="py-6 px-4">
                        <p className="text-sm text-center">
                            <i className="text-danger font-weight-bold fas fa-exclamation-triangle mr-2" />
                            The call center must be viewed on either a computer or tablet device.
                        </p>
                    </div>
                )
            }
            
        }
      
        return (
            <div className="cl-cntr">

                <Helmet>
                    <title>{`Call Center`}</title>
                    <meta name="description" content="Tags" />
                </Helmet>

                <Container fluid >

                <div id="speaker-devices" />

                    <Row className="mr-0 ml-0">

                        {onCall ? (
                            <>

                                <Col lg={3} className="col-left" style={{overflow: 'auto'}}>

                                    <SidebarOnCall
                                        contact={contact}
                                        appointment={appointment}
                                        questionnaire={questionnaire}
                                        ConferenceSid={viewing_user.call_conference}
                                    />

                                </Col>

                                <Col lg={9} className="col-right">

                                    <OnCall
                                        callType={callType}
                                        contact={contact}
                                        division={division}
                                    />

                                </Col>
                            </>
                        ) : (
                            <>
                                <Col lg={3} className="col-left bg-secondary full" style={{overflow: 'auto'}}>

                                    <SidebarNotOnCall
                                        appointments={appointments}
                                        setOnCall={this.setOnCall}
                                        canTakeCalls={canTakeCalls}
                                    />

                                </Col>

                                <Col lg={9} className="col-right">
{/* 
                                    <InfoBar
                                        canTakeCalls={canTakeCalls}
                                        showKeypad={showKeypad}
                                        toggleKeypad={this.toggleKeypad}
                                        setOnCall={this.setOnCall}
                                        queueData={queueData}
                                        voicemailCount={voicemailCount}
                                    />

                                    <NotOnCall
                                        canTakeCalls={canTakeCalls}
                                        noCallsOpen={noCallsOpen}
                                        setOnCall={this.setOnCall}
                                    /> */}
                                    <Container className="py-3 text-center">
                                        <p>To make a call <a target="_blank" rel="noopener noreferrer" href={`${keys.TALK_URL}`}>CLICK HERE</a></p>
                                    </Container>

                                </Col>

                            </>
                        )}

                    </Row>

                    {showBrowserDialingConsent && !onCall ? <BrowserDialingConsent /> : null}

                    <ModalBrowserDialing
                        showModal={showModalConsent}
                        toggleModal={() => this.toggleModalConsent()}
                        onConfirmModalConsent={this.onConfirmModalConsent}
                    />

                    <ConfirmCall 
                        toggleModalConfirm={() => this.toggleModalConfirm()}
                        showModalConfirmData={showModalConfirmData}
                        showModalConfirmContact={showModalConfirmContact}
                        onConfirmCall={this.onConfirmCall}
                        showModalConfirm={showModalConfirm}
                    />

                </Container>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user              : state.auth.viewing_user,
        device                    : state.device,
        browser_dialing_status    : state.system.browser_dialing_status,
        call_center               : state.call_center,
    };
};

export default connect(mapStateToProps, '')(CallCenterUsers);
