import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Input, Row, Col } from 'reactstrap';

import _zap_reports from '_functions/zap_reports';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import ModalMissingDocs from '../../../../../views/dashboard/pages/cases/View/components/Documents/modals/ModalMissingDocs';
import ImageRender from 'components/functional/images/ImageRenderer';

import Array from './Array';

const StepsToPrepQuestionnaire = ({foundCase, contact, questionnaireDoc, case_id, onDocStatusChange, trimDocName}) => {

    const [showModal, setShowModal] = useState(false)
    const [sentZap, setSentZap] = useState(null)

    const onSendZapLink = useCallback(async() => {

        toggleStandardLoader(true)
        const sent = await _zap_reports.requestForm({contact: foundCase.contact._id, case: case_id})
        toggleStandardLoader(false)

        if(sent.success) {
            setSentZap(true)
            return toggleAlertBS(false, 'Zap Document Request Sent')
        }
        return toggleAlertBS(true, 'Something went wrong, please try again or use the Zap Reports Dashboard. This is most likely a conflict with a duplicate email or phone number.')
    }, [])

  
    const hasQuestionnaire = false
    return <Array foundCase={foundCase} contact={contact} />
    return (
        <div className="archk-steps-to-prep-docs">
            <div className="archk-steps-to-prep-spacer mt-3">

                {!hasQuestionnaire && !sentZap ? (
                    <div className="alert alert-danger"><i className="fas fa-exclamation-triangle mr-2 " /> <span className="text-capitalize">{foundCase.contact.given_name}</span> may not have been added to Zap Reports, send them a request for document by <b className="font-weight-bold text-underline cursor-pointer" onClick={onSendZapLink}>CLICKING HERE</b></div>
                ) : null}

                <Card>

                    <CardHeader>
                        <Row>
                            <Col md={6} className="align-self-center">
                                <CardTitle className="mb-0">Client Questionnaire</CardTitle>
                            </Col>
                            <Col md={6} className="align-self-center text-right">
                                <button className="btn btn-outline-warning" onClick={onSendZapLink}>
                                    Send Questionnaire Link
                                </button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardHeader>
                        <p className="text-sm mb-0"><i className="fas fa-exclamation-triangle text-warning mr-2 " /> Do not use the old client questionnaire, make sure any questionnaires sent out to clients are done through Zap Reports either on the dashboard or by using the link above.</p>
                    </CardHeader>

                    <CardBody>

                        <div key={questionnaireDoc._id} className="mb-3 border rounded p-3">
                            <div className="mb-3">
                                <i className="far fa-folder-open mr-2 " />
                                {questionnaireDoc.status === 'completed' ? <i className="fas fa-check ml-2 text-success " /> : <i className="far fa-times-circle text-info-original " />}{' '}
                                <span className={hasQuestionnaire ? 'text-line-through' : ''}>{trimDocName(questionnaireDoc)} </span>
                                    
                                <button className="btn btn-success btn-sm ml-2" onClick={() => setShowModal(true)}>
                                    <i className="fas fa-upload mr-2 " /> Upload
                                </button>

                                <Input
                                    className="float-right status"
                                    type="select"
                                    size="sm"
                                    style={{maxWidth: 100}}
                                    value={questionnaireDoc.status || ''}
                                    onChange={(e) => onDocStatusChange(questionnaireDoc, e.target.value)}
                                >
                                   <option value="missing">Missing</option>
                                    <option value="n/a">N/A</option>
                                    <option value="completed">Completed</option>
                                </Input>
                            </div>

                            {questionnaireDoc.documents && questionnaireDoc.documents.length ? (
                                <div className="ml--2 pl-5">
                                    {questionnaireDoc.documents.map(d => (
                                        <div>
                                            <ImageRender 
                                                maxNameLength={20}
                                                doc={d}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="ml--2 pl-5"><small className="text-muted">No Documents Uploaded</small></div>
                            )}
                        </div>

                    </CardBody>
                </Card>

                <ModalMissingDocs
                    case={foundCase}
                    missing_doc={questionnaireDoc}
                    showModal={showModal}
                    toggleModal={() => setShowModal(null)}
                />
                
            </div>
        </div>
    )

}
export default StepsToPrepQuestionnaire;