/*
Documentation

this file renders the email selector on the left hand sidebar
It should show only the main company email or main company email + users email, or ALL emails
depending on the privileges of the viewing user

*/

import ReactSelect from 'components/functional/inputs/ReactSelect';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getAuthorizedViewingEmails from '_functions/users/getAuthorizedViewingEmails';
import PropTypes from 'prop-types'

class ChangeEmailView extends Component {

    state = {
        viewingEmailAddress: this.props.viewing_user.email,
        viewingEmailsOptions: [],
        emails: null,
    }

    setEmailPrivileges = () => {

        const { viewing_user, company } = this.props;
        const authorizedViewingEmails = getAuthorizedViewingEmails(viewing_user.email, company, viewing_user.privileges)

        let viewingEmailsOptions = []

        authorizedViewingEmails.forEach(email => viewingEmailsOptions.push({value: email, label: email}))

        this.setState({viewingEmailsOptions})

    }

    onChangeEmailAddress = (values) => {

        const viewingEmailAddress = values.value

        if(this.props.viewingEmailAddress !== viewingEmailAddress) {
           this.props.onChangeEmailAddress(viewingEmailAddress)
        }

    }

    componentDidMount = () => this.setEmailPrivileges()

    render() {

        const {  viewingEmailsOptions } = this.state;
        const { viewingEmailAddress } = this.props;

        return (

            <div className="mt-2 mx--1">
                <ReactSelect
                    placeholder={viewingEmailAddress}
                    onChange={this.onChangeEmailAddress}
                    options={viewingEmailsOptions}
                />
            </div>

        )

    }

}

ChangeEmailView.propTypes = {
    viewingEmailAddress   : PropTypes.string.isRequired,
    onChangeEmailAddress  : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        company: state.companies.company,

    };
};

export default connect(mapStateToProps, '')(ChangeEmailView);
