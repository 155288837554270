/*
Documentation

this is the "all view" and it shows a sorted view of case_feeds, contact_notes, contact_emails, and contact_texts

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import RenderEmail from './Render/Email';
import RenderNote from './Render/Note';
import RenderSystem from './Render/System';
import RenderText from './Render/Text';

class FeedAll extends Component {

    state = {
        filter: null, // contact_note | contact_text | contact_email | system | task
    };

    //render each individual case note
    renderFeedEntry = (entry, _case, i) => {

        if(entry.type === 'contact_email') {
            return <RenderEmail key={i} renderNameAndInitial={this.props.renderNameAndInitial} entry={entry} case={_case} />
        } else if(entry.type === 'contact_text') {
            return <RenderText key={i} renderNameAndInitial={this.props.renderNameAndInitial} entry={entry} case={_case} />
        } else if(entry.type === 'contact_note') {
            return <RenderNote key={i} renderNameAndInitial={this.props.renderNameAndInitial} entry={entry} />
        } else if(entry.type === 'system') {
            return <RenderSystem key={i} renderNameAndInitial={this.props.renderNameAndInitial} entry={entry} />
        } else if(entry.type === 'task') {
            return <RenderSystem key={i} renderNameAndInitial={this.props.renderNameAndInitial} entry={entry} />
        }

    }

    //this function takes the state feed array and filters it by type equal to the filter value
    filterFeed = ( feed ) => {

        const filter = this.state.filter;
        if(feed && feed.length && filter) feed = feed.filter(f => f.type === filter)

        return feed;

    }

    render() {

        if(this.props.view !== 'feed') return null

        let { feed, isFullScreen, toggleFullScreen, showFeedEntries } = this.props
        const _case = this.props.case;

        feed = this.filterFeed(feed)

        if(!feed) feed = []
        feed = feed.slice(0, showFeedEntries)

        return (

            <>

                <CardHeader>
                    <Row>

                        <Col md={6}>

                            <UncontrolledDropdown className="ml-0">

                                <DropdownToggle caret color="">
                                    <i className="fas fa-filter text-info-original" />
                                </DropdownToggle>

                                <DropdownMenu>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: null})} }> All </DropdownItem>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: 'contact_note'})} }> Notes </DropdownItem>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: 'contact_text'})} }> Texts </DropdownItem>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: 'contact_email'})} }> Emails </DropdownItem>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: 'task'})} }> Tasks </DropdownItem>
                                    <DropdownItem href="/" onClick={e => {e.preventDefault(); this.setState({filter: 'system'})} }> System </DropdownItem>
                                </DropdownMenu>

                            </UncontrolledDropdown>

                            <span className="text-sm text-muted text-capitalize ">
                                {this.state.filter ? this.state.filter === 'system' ? 'Filter By: System' : `Filter By: ${this.state.filter.replace('_', ' ')}s` : null}
                            </span>
                        </Col>

                        <Col md={6} className="text-right">
                            <i
                                style={{cursor: 'pointer'}}
                                className={!isFullScreen ? "fas fa-plus text-success pl-2" : "fas fa-minus text-warning ml-3 pl-2"}
                                onClick={toggleFullScreen}
                            />
                        </Col>

                    </Row>

                </CardHeader>

                {/* <div id="feed-wrapper" className="feed-wrapper" style={{overflowY: 'auto', height: ignoreHeight ? 'calc(100vh - 300px)' : 'auto'}}> */}
                <div id="feed-wrapper" className="feed-wrapper" style={{overflowY: 'auto'}}>
                    <table className="table" >
                        <tbody >

                            { feed && feed.length ? (
                                feed.map((entry, i) => this.renderFeedEntry(entry, _case, i) )
                            ) : feed ? (

                                <tr>
                                    <td>
                                        <div className="item-wrapper">
                                            <h6 className="text-sm mb-0 font-weight-bold">
                                                SYSTEM
                                            </h6>
                                            {this.state.filter ? 'There are entries to show with the current filter.' : 'There is nothing to show in the case feed yet'}
                                        </div>
                                    </td>
                                </tr>

                            ) : null}

                        </tbody>
                    </table>

                </div>

           </>

        )

    }

}

FeedAll.propTypes = {
    feed                : PropTypes.array.isRequired,
    case                : PropTypes.object.isRequired,
    toggleFullScreen    : PropTypes.func.isRequired,
    isFullScreen        : PropTypes.bool.isRequired,
    showFeedEntries     : PropTypes.number.isRequired,
    view                : PropTypes.string.isRequired,
}

export default FeedAll
