/*
Documentation

uploads a document via the drag and drop editor
note that when documents are uploaded they will be refreshed on the screen through a socket update, no state change needed here on upload

*/

import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { canUpdateCases } from '_functions/users/privileges';

class DocumentsUpload extends Component {

    render() {

        const canUpdate = canUpdateCases();

        return (

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Upload New</CardTitle>
                </CardHeader>

                <CardBody>
                    {canUpdate ? (
                        <DragAndDrop
                            zone_id="documents_1"
                            url={`/api/v1/core/documents/create`}
                            onError={(e) => console.log(e)}
                            extraData={{case: this.props.case._id, contact: this.props.case.contact._id}}
                        />
                    ) : (
                        <p className="text-sm"><i className="text-warning fas fa-exclamation-triangle mr-2" /> Document upload disabled.</p>
                    )}
                </CardBody>

            </Card>

        )

    }

}

DocumentsUpload.propTypes = {
    case: PropTypes.object.isRequired,
}

export default DocumentsUpload
