import React, { useCallback } from 'react';
import { Input, FormGroup } from 'reactstrap';

const ArchkTruthy = ({label, subType, onChange}) => {

    const onInputChange = useCallback((e) => {
        const val = e.target.value;

        if(val === "__false__") return onChange(undefined);
        if(val === 'true') return onChange(true)
        if(val === 'false') return onChange(false)
        return onChange(val)
    }, [onChange])

    return (
        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input onChange={onInputChange} type="select" >
                <option value="__false__"></option>
                {subType === 'yesno' ? (
                    <>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                    </>
                ) : null}

                {subType === 'boolean' ? (
                    <>
                    <option value="true">True</option>
                    <option value="false">False</option>
                    </>
                ) : null}
            </Input>
        </FormGroup>
    )

}

export default ArchkTruthy;
