import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { showStepsToPetitionPrep } from 'store/functions/system/system'

const StepsToPrepLaunch = ({contact, case_id, stepsToPetitionPrep}) => {

    useEffect(() => {
        if(stepsToPetitionPrep.contact) {
            document.body.style.paddingBottom = ''
        } else {
            document.body.style.paddingBottom = '45px'
        }
    }, [stepsToPetitionPrep.contact])

    if(stepsToPetitionPrep && stepsToPetitionPrep.contact) return <></>;

    return (
       <span 
        className="archk-steps-to-prep-launch z-depth-3" 
        onClick={() => showStepsToPetitionPrep({
                contact: contact._id ? contact._id : contact,
                case_id,
            })}
        >
            <i className="fas fa-shoe-prints " />
       </span>
    )
}


const mapStateToProps = state => {
    return {
        stepsToPetitionPrep: state.system.stepsToPetitionPrep,
    };
};

export default connect(mapStateToProps, '')(StepsToPrepLaunch);