// return user friendly payment method name

export default (payment_method) => {

    if(payment_method === 'cc') {
        return 'Credit Card'
    }

    return null

}