/*
Documentation

this modal allows a user to remove a user that is currently assigned to a case role

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { toggleAlert } from 'store/functions/system/system';
import _cases from '_functions/cases';
import renderName from 'utils/renderName';

class RolesAssignUser extends Component {

    state = {

    };

    onRemoveUser = () => {

        const role    = this.props.role
        const _case           = this.props.case;

        if(role.user) {

            // need to add in the role we are removing here and add that to the remove function on the server
            _cases.removeUser({case: _case._id, user: role.user._id, role: role.role})
            this.props.toggleModal()

        } else {

            toggleAlert(true, 'warning', 'You have not selected a valid user.', '3000')

        }

    }

    render() {

        const { showModal, toggleModal, role } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Remove User From case</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">By removing this user they will no longer be able to view, update, or edit this case in the future. </p>
                    <p className="text-sm mt--2"> You are about to remove <b>{renderName(role ? role.user : null)}</b> from this case, if you wish to do this confirm below. </p>

                </div>

                <div className="modal-footer">
                    <button onClick={this.onRemoveUser} className="btn text-center btn-danger" >
                        Remove Role
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

RolesAssignUser.propTypes = {
    case         : PropTypes.object.isRequired,
    showModal    : PropTypes.bool.isRequired,
    toggleModal  : PropTypes.func.isRequired,
    role         : PropTypes.object,
}

export default connect(mapStateToProps, '')(RolesAssignUser);
