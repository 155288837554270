/*
Documentation

This renders form inputs for a task with the type "upload"

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import { FormGroup } from 'reactstrap';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _case_workflow_tasks from '_functions/case_workflow_tasks';

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class FormTypesUpload extends Component {

    state = {
        filesNumberToUpload: 0,
        filesUploadedForTask: [],
        shouldFireUploads: false,
        time_now: Math.floor(new Date() / 1000),
        editing_task: {},
        documents_uploaded: 0,
        documents_total: 0,
        assigned_to: null,
        appointment: null,
    };

    onUploadStarted = () => {
        toggleStandardLoader(true)
    }

    onFileUploaded = async (file, response) => {

        const filesUploadedForTask = [...this.state.filesUploadedForTask]
        filesUploadedForTask.push(response.data)

        this.setState({filesUploadedForTask})

        if(this.state.filesNumberToUpload === filesUploadedForTask.length) {

            toggleStandardLoader(false)

            const editing_task = this.state.editing_task

            toggleStandardLoader(true)

            await _case_workflow_tasks.finish(this.state.editing_task._id,  {
                value: filesUploadedForTask,
                minutes: editing_task.minutes ? parseInt(editing_task.minutes) : editing_task.minutes,
                user: this.props.viewing_user._id,
                case_workflow: this.props.case_workflow_id,
            })

            toggleStandardLoader(false)


            this.props.toggleModal();
            this.setState({filesUploadedForTask: [], filesNumberToUpload: 0, has_new_date: false})

        }

    }

    onParentUpload = (files) => {

        this.setState({shouldFireUploads: false})
        this.setState({filesNumberToUpload: files.length})

    }

    onFinishTask = () => {

        this.setState({shouldFireUploads: true})
        this.props.forceRefresh();
        _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes
            })

            this.setState({editing_task: task})

        }

    }

    render() {

        const editing_task = this.state.editing_task || {};
        const _case = this.props.case;

        if(editing_task.type !== 'upload') { return <></> }

        return (

            <>

                <StandardFormGroup
                    obj={editing_task}
                    objName={'editing_task'}
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                    type="number"
                    field="minutes"
                    title="Task Minutes"
                />

                <FormGroup className="mb-3 ">
                    <label className="form-control-label text-capitalize">{editing_task.name}</label>
                    <DragAndDrop
                        uploadOne={true}
                        shouldFireUploads={this.state.shouldFireUploads}
                        onParentUpload={(files) => this.onParentUpload(files)}
                        shouldShowButton={false}
                        onFileAdded={(files) => this.setState({documents_uploaded: files.length})}
                        onFileRemoved={(files) => this.setState({documents_uploaded: files.length})}
                        zone_id="documents_2"
                        url={`/api/v1/core/documents/create`}
                        onUploadStarted={this.onUploadStarted}
                        onUpload={(file, upload) => this.onFileUploaded(file, upload)}
                        btnText="Upload And Finish Task"
                        extraData={{case: _case._id, contact: _case.contact._id}}
                    />


                </FormGroup>

                <hr className="my-4" />

                <div className="text-right">

                    <button
                        onClick={() => this.onFinishTask()}
                        className="btn btn-success"
                    >
                        Finish Task
                    </button>
                </div>

            </>

        )

    }

}

FormTypesUpload.propTypes = {
    case                : PropTypes.object.isRequired,
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesUpload);
