import React from 'react';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import notifyMe from 'utils/browserNotifications'
import store from 'store'

// store parent function to push a new notification, set on run
let pushNotification;
// store sub
let subscribed_contacts

const onNewUserNotificationAdded = async (data) => {

    const storeData = store.getState()

    const viewing_user = storeData.auth.viewing_user

    if(subscribed_contacts.find(s => s._id === data.data.contact) || data.data.assigned_user === viewing_user._id) {

        let { message, contact_name, _id } = data.data
        if(message && message.length > 60) message = message.slice(0,60) + '...';

        notifyMe({
            title: `Contact: ${contact_name}`,
            body: message,
            tag: data.data._id,
        })

        pushNotification({
            _id: _id,
            link: '/dashboard/notifications',
            color: 'success',
            icon: 'fas fa-envelope',
            title: <span className="text-capitalize">New Message From {contact_name}</span>,
            message,
            created_at: Math.floor(new Date() / 1000)
        })

    }
    
}


export default {
    run: (addNotification, userSubscribedContacts) => {

        pushNotification = addNotification;
        subscribed_contacts = userSubscribedContacts;

        io.on(socketEvents.user_notifications_added, onNewUserNotificationAdded);
    },
    terminate:  () => io.off(socketEvents.user_notifications_added, onNewUserNotificationAdded)
}
