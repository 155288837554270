/*

pops up when a user clicks on an appointment assigned to them
in the left hand sidebar of the call center

*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardTitle, Modal } from "reactstrap";
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';


class ModalConfirmCall extends Component {

    

    render() {

        let { showModalConfirm, toggleModalConfirm, onConfirmCall, showModalConfirmContact } = this.props
        if(!showModalConfirmContact) showModalConfirmContact = {}

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalConfirm}
                toggle={toggleModalConfirm}
                size="lg"
                zIndex={2000}
            >
                <div className="modal-header">
                    <CardTitle className="mb-0 text-capitalize">Confirmation Required</CardTitle>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalConfirm}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-header">
                    <p className="text-sm mb-0">This contact requires confirmation before calling. Click below to confirm.</p>
                </div>

                <div className="modal-body pt-3">
                    <h3 className="mb-0">{renderName(showModalConfirmContact)}</h3>
                    {showModalConfirmContact.phone ? <p className="text-sm mb-0">{formatPhone(showModalConfirmContact.phone)}</p> : null }
                    {showModalConfirmContact.email ? <p className="text-sm mb-0">{showModalConfirmContact.email}</p> : null}
                </div>

                <div className="modal-footer">

                    <button onClick={onConfirmCall} className="btn btn-success">
                        Dial Contact
                    </button>

                </div>



            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ModalConfirmCall.propTypes = {
    toggleModalConfirm    : PropTypes.func.isRequired,
    onConfirmCall         : PropTypes.func.isRequired,
    showModalConfirm      : PropTypes.bool.isRequired,
}
export default connect(mapStateToProps, '')(ModalConfirmCall);
