import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'
import AvatarImage from 'components/functional/images/AvatarImage';

class UserSearchInput extends React.Component {

    state = {
        filter: null,
        showResults: false,
        selected: {},
        dataToShow: []
    }

    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    onChange = (value) => {

        let data = this.props.users || [];
        data = [...data]

        if(value && value !== null) {

            value = value.toLowerCase()

            data = data.filter(user => {

                if( user.phone && user.phone.includes(value)) return true
                if( user.email && user.email.includes(value)) return true
                if( renderName(user).includes(value)) return true

                return false

            })

            this.setState({dataToShow: data})

        } else {

            this.setState({dataToShow: data })

        }

    }

    componentDidMount = () => {
        const { users, value } = this.props
        this.setState({ dataToShow: users, selected: value ? value : {} })
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter, hideTitle } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                onSelect={this.onSelect}
                hideTitle={hideTitle}
                placeholderFormatter={selected ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected User: <b className="text-success text-capitalize">{renderName(selected)}</b></span> : 
                        'Select User'
                }
                resultsFormatter={(result) => (
                    selected._id !== result._id ? (
                        <li key={result._id} onClick={() => this.onSelect(result)}>
                            <p className="text-sm mb-2 text-capitalize">
                                {result.avatar ? <AvatarImage  src={result.avatar} className="mr-2" /> : null}
                                {renderName(result) === 'Not Found' ? 'Name Not Found' : renderName(result)}
                            </p>
                            <p className="text-sm mb-0 text-muted"><i className="fas fa-phone mr-2" />  {result.phone ? formatPhone(result.phone) : '-'} </p>
                            <p className="text-sm mb-0 text-muted"><i className="fas fa-envelope mr-2" />  {result.email ? result.email : '-'} </p>
                        </li>
                    ) : null
                )}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

UserSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default connect(mapStateToProps, '')(UserSearchInput);
