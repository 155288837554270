import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { CardTitle, Container, Card, CardHeader, CardBody, Badge } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import _analytics from '_functions/analytics'

import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

import Circle from 'components/markup/loading/Circle';

import moment from 'moment';

const CallCenterAdmin = ({users, offices}) => {
    
    const [startOfDay] = useState(parseInt(moment().startOf('day').format('X')));
    const [filter, setFilter] = useState(null);
    const [filterOrder, setFilterOrder] = useState(1);

    const [loading, setLoading] = useState(null);
    const [err, setErr] = useState(null);
    const [allMetrics, setAllMetrics] = useState(null);
    const [dates, setDates] = useState(null);

    const clockedInUsers = users.filter(u => u.user_set_location_time > startOfDay && u.user_types && u.user_types.some(t => t === 'call_center_operator'));

    const fetchData = useCallback(async () => {
        setLoading(true)
        const data = await await _analytics.users.allStats();
        if(data.data) {
            setAllMetrics(data.data);
            setDates({start: data.start, end: data.end})
        } else {
            setErr(true)
        }
        setLoading(false)

    }, [])

    const onSetFilter = (_filter) => {
        if(filter === _filter) {
            return setFilterOrder(-1 * filterOrder)
        }
        setFilterOrder(1);
        setFilter(_filter);
    }


    useEffect(() => {
        fetchData();
    }, [fetchData])

    let sortedMetrics = allMetrics ? JSON.parse(JSON.stringify(allMetrics)) : null
    // filter object keys to map either all asc or all desc
    if(filter && allMetrics) {
        sortedMetrics = Object.keys(sortedMetrics).sort((a, b) => {
            let valA = sortedMetrics[a][filter]
            let valB = sortedMetrics[b][filter]

            if(filter === 'totalTimeBetweenCalls') {
                valA = valA / sortedMetrics[a]['totalCalls']
                valB = valB / sortedMetrics[b]['totalCalls']
            }
            
            return valA < valB ? filterOrder : -1 * filterOrder
        }).reduce((accumulator, key) => {
            accumulator[key] = sortedMetrics[key];
            return accumulator;
        }, {});
    }



    return (
        <div>


            <Helmet>
                <title>{`Overview`}</title>
                <meta name="description" content="Overview" />
            </Helmet>


            <Container fluid>
                <DashHeaderOpen
                    title={<span><i className="fas fa-chart-line mr-2 text-success " /> Team</span>}
                    breadcrumb_1="Stats"
                    breadcrumb_2="Team Members"
                />


                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Active Today</CardTitle>
                    </CardHeader>
                    
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Location</th>
                                    <th>Call Status</th>
                                    <th className="text-right">Active At</th>
                                </tr>
                            </thead>

                            <tbody>
                                {clockedInUsers.length ? clockedInUsers.map(u => {
                                    const office = offices.find(o => o._id = u.user_set_location)
                                    return (
                                        <tr>
                                            <td className="text-capitalize">{renderName(u)}</td>
                                            <td>{office ? office.name : 'Home'}</td>
                                            <td>{u.call_status === 'on call' ? <Badge color="success"><i className="fas fa-check mr-2 " /> On Call</Badge> :  <Badge color="danger"><i className="fas fa-times mr-2 " /> Off Call</Badge> }</td>
                                            <td className="text-right">{moment.unix(u.user_set_location_time).format('h:mm A')}</td>
                                        </tr>
                                    )
                                }) : (
                                    <tr>
                                        <td className="text-sm mb-0">No team members are currently active.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Team Members Stats <small>- {dates ? `${dates.start} -  ${dates.end}` : ''}</small></CardTitle>
                    </CardHeader>

                   
                        {err ? (
                            <CardBody>
                                <p className="text-sm mb-0">Something's not right, please refresh your page.</p>
                            </CardBody>
                        ) : loading || !allMetrics ? (
                            <CardBody>
                                <div className="py-6"><Circle /></div>
                            </CardBody>
                        ) : (
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th className={filter === 'totalTimeBetweenCalls' ? 'text-success' : ''}>
                                                <span className="cursor-pointer" onClick={() => onSetFilter('totalTimeBetweenCalls')}>Avg Between Calls</span>
                                            </th>
                                            <th className={filter === 'totalCalls' ? 'text-success' : ''}>
                                                <span className="cursor-pointer" onClick={() => onSetFilter('totalCalls')}>All Calls</span>
                                            </th>
                                            <th className={filter === 'timeOnCall' ? 'text-success' : ''}>
                                                <span className="cursor-pointer" onClick={() => onSetFilter('timeOnCall')}>Time On Call</span>
                                            </th>
                                            <th className={filter === 'totalCallsOver2Minutes' ? 'text-success' : ''}>
                                                <span className="cursor-pointer" onClick={() => onSetFilter('totalCallsOver2Minutes')}>Calls Over 2 Min</span>
                                            </th>
                                            <th className={filter === 'docsUploaded' ? 'text-success' : ''}>
                                                <span className="cursor-pointer" onClick={() => onSetFilter('docsUploaded')}>Docs Uploaded</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Object.keys(sortedMetrics).map((key, i) => {
                                            const user = users.find(u => u._id === key);
                                            const metrics = sortedMetrics[key];
                                            return (
                                                <tr>
                                                    <td className="text-capitalize"><small>{renderName(user)}</small></td>
                                                    <td><small>{metrics ? metrics.totalTimeBetweenCalls && metrics.totalCalls ? secondsToTime(metrics.totalTimeBetweenCalls / metrics.totalCalls) : 0 : '-'}</small></td>
                                                    <td><small>{metrics ? metrics.totalCalls : '0'}</small></td>
                                                    <td><small>{metrics ? metrics.timeOnCall ? secondsToTime(metrics.timeOnCall) : 0 : '0 Mins'}</small></td>        
                                                    <td><small>{metrics ? metrics.totalCallsOver2Minutes : '-'}</small></td>        
                                                    <td><small>{metrics ? metrics.docsUploaded : '-'}</small></td>        
                                                </tr>
                                                )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}

                </Card>

            </Container>

        </div>
    )

}


const mapStateToProps = state => {
    return {
        offices : state.offices.offices,
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdmin);