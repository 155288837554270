/*
Documentation

this file renders the right sidebar in the messaging center showing all texts

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import _contact_texts from '_functions/contact_texts';
import ChangeShowing from './ChangeShowing';
import RenderText from './RenderText';
import { connect } from 'react-redux';

const dataSetLength = 20;

class SidebarLeft extends Component {

    state = {

        // either all or unread
        showing : 'assigned_to',
        // the actual array of texts to show in the sidebar
        texts   : null,

    }

    refreshInterval = null;

    onChangeShowing = (showing) => this.setState({showing, texts: null}, this.fetchTexts)


    // create the query string we use to query the database with
    createQueryString = () => {

        let { showing } = this.state

        const skip = this.state.texts ? this.state.texts.length : 0

        let string = `?limit=${dataSetLength}&skip=${skip}&sort=created_at__desc&populate=contact&user=${this.props.viewing_user._id}`

        if(showing === 'unread') string = string + `&filter=read__false{bool}`
        if(showing === 'assigned_to') string = string + `&filter=assigned_to__${this.props.viewing_user._id}&read__false{bool}`

        return string 

    }

    fetchTexts = async () => {

        this.props.setContent(null)

        this.setState({loading: true})

        const queryString = this.createQueryString()

        const call = await _contact_texts.find(queryString)

        let texts = this.state.texts ? [...this.state.texts] : [];
        let newTexts = call.data

        // if we have new texts add them to state
        if(newTexts && newTexts.length) texts = texts.concat(newTexts)

        this.setState({
            texts,
            loading: false,
            total_texts: call.total_documents ? call.total_documents : this.state.total_texts
        })

    }

    listenForContactTextsAdded = (data) => {

        let text = data.data;

        const { showing }     = this.state
        if(showing === 'unread' && text.read ) return

        // only push to state if the text is for the user or is not assigned to anyone
        if(!text.assigned_to || (text.assigned_to && text.assigned_to._id === this.props.viewing_user._id)) {

            let texts = this.state.texts ? [...this.state.texts] : [];
            texts.unshift(text)

            this.setState({texts, total_texts: this.state.total_texts + 1})

        }

    }

    listenForContactTextsUpdated = (data) => {

        let text = data.data;

        const { setContent, content }  = this.props
        let texts = this.state.texts ? [...this.state.texts] : []

        if(text.read) {
            texts = texts.filter(t => t._id !== text._id)
            return this.setState({texts})
        }

        const foundText = texts.find(e => e._id === text._id)

        if(foundText) {

            texts[texts.findIndex(el => el._id === text._id)] = text;
            this.setState({texts})

            // update current viewing text if applicable
            if(content && content._id === foundText._id) setContent(text, 'text', true)

        }

    }

    componentWillUnmount = () => {

        io.off(socketEvents.contact_texts_added,    this.listenForContactTextsAdded)
        io.off(socketEvents.contact_texts_updated,    this.listenForContactTextsUpdated)

        // clear 5 minute interval of refreshing texts
        clearInterval(this.refreshInterval)

    }

    componentDidMount = () => {

        io.on(socketEvents.contact_texts_added,    this.listenForContactTextsAdded)
        io.on(socketEvents.contact_texts_updated,    this.listenForContactTextsUpdated)
        this.fetchTexts()

        // refresh texts every 5 minutes on this page
        this.refreshInterval = setInterval(this.fetchTexts, 300000)

    }

    render() {

        const { setContent, content } = this.props
        const { texts, showing, loading, total_texts} = this.state

        return (

            <div lg={3} className="sidebar sidebar-right">

                <div className="section">
                    <ChangeShowing
                        showing={showing}
                        onChangeShowing={this.onChangeShowing}
                    />
                </div>

                <RenderText
                    activeText={content ? content._id : null}
                    texts={texts}
                    setContent={setContent}
                    loading={loading}
                />

                {texts && (texts.length < total_texts) ? (
                    <div className="py-4 text-center">
                        <button onClick={() => this.fetchTexts()} className="btn btn-success"> Load More</button>
                    </div>
                ) : null}

            </div>

        )

    }

}

SidebarLeft.propTypes = {
    setContent    : PropTypes.func.isRequired,
    viewing_user  : PropTypes.object.isRequired,
    content       : PropTypes.object,
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(SidebarLeft);
