import React from 'react';

import Questionnaire from './Questionnaire'

import _questionnaire_answers from '_functions/questionnaire_answers';

class ContactQuestionnaire extends React.Component {

    state = {
        answers: this.props.defaultAnswers ? this.props.defaultAnswers : {},
        answersLastChanged: Math.floor(new Date() / 1000)
    }

    autoSaveInterval = null;

    onAnswerChange = ({answers}) => {
        this.setState({
            answers,
            answersLastChanged: Math.floor(new Date() / 1000)
        })
        if(this.props.onAnswerChange) this.props.onAnswerChange(answers)
    }

    componentWillUnmount = () => {

        const {contact_id, case_id, questionnaire, autoSave, populatedContact} = this.props

        if(autoSave) {
            _questionnaire_answers.save(questionnaire, populatedContact ? populatedContact._id : contact_id, case_id, this.state.answers)
        }

        if(this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval)

        }
    }

    componentDidMount = async () => {

        const {contact_id, case_id, questionnaire, autoSave, populatedContact} = this.props

        if(autoSave && questionnaire) {
            let lastSave = 0
            this.autoSaveInterval = setInterval(() => {

                const { answersLastChanged } = this.state;

                if(answersLastChanged >= lastSave) {
                    _questionnaire_answers.save(questionnaire, populatedContact ? populatedContact._id : contact_id, case_id, this.state.answers)
                    lastSave = Math.floor(new Date() / 1000)
                }
            }, 3000)
        }

    }

    render() {

        const {contact_id, case_id, questionnaire, defaultAnswers, autoSave, populatedContact, onMarkDocNaOnNo } = this.props;

        return (
            <Questionnaire 
                contact_id={contact_id}
                populatedContact={populatedContact}
                case_id={case_id}
                questionnaire={questionnaire}
                defaultAnswers={defaultAnswers}
                onMarkDocNaOnNo={onMarkDocNaOnNo}
                onAnswerChange={autoSave ? this.onAnswerChange : null}
            />
        );
    }
}

export default ContactQuestionnaire
