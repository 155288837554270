import React from 'react';

const FeedWrapper = (props) => (
    <tr>
        <td style={styles.td} className={props.className}>
            <div className="item-wrapper border">
                {props.children}
            </div>
        </td>
    </tr>

)

const styles = {
    td: {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
    }
}

export default FeedWrapper;
