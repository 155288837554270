/*
Documentation

this page renders the view for users to view all tasks assigned to them

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, Container, UncontrolledTooltip } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TableRow = ({name, link}) => {

    const [copied, setCopied ] = useState(false);

    return (
        <tr>
            <td>{name}</td>
            <td>{link}</td>
            <td className="text-right">
    
                <CopyToClipboard
                    text={link}
                    onCopy={() => {
                        setCopied(true)
                        setTimeout(() => setCopied(false), 2000)
                    }}
                >
                    <i className="far fa-clipboard text-success mr-3 cursor-pointer" id={name.replace(/ /g, '')} />
                </CopyToClipboard>
    
                <UncontrolledTooltip
                    delay={0}
                    trigger="hover focus"
                    target={name.replace(/ /g, '')}
                >
                    {copied ? "Copied": "Copy To Clipboard"}
                </UncontrolledTooltip>
    
                <a href={link} target="_blank" rel="noopener noreferrer" className="text-warning">
                    <i className="fas fa-door-open " />
                </a>
            </td>
        </tr>
    )

}

const Link = () => (
    <Container fluid>

            <Helmet>
                <title>{`Links`}</title>
                <meta name="description" content="Links" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/dashboard"
                title={<span>Links</span>}
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Links</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Link</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>

                            <TableRow name="Website" link="https://fairmaxlaw.com" />
                            <TableRow name="Client Portal" link="https://clients.myfairmax.com" />
                            <TableRow name="Pay Filing Fees" link="https://fairmax-filing-fees.netlify.app" />
                            <TableRow name="Questionnaire" link="https://fairmax-forms.netlify.app" />
                            <TableRow name="Roadside Website" link="https://roadsideattorneys.com/" />
                            

                        </tbody>

                    </table>
                </div>

            </Card>

        </Container>
)


export default Link
