import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import _contact_emails from '_functions/contact_emails';
import _contact_texts from '_functions/contact_texts';
import _contacts from '_functions/contacts';
import _cases from '_functions/cases';

import renderName from 'utils/renderName'

import ModalCreateReminder from './ModalCreateReminder';
import ModalAssociateToContact from './ModalAssociateToContact';
import HandleEmail from './HandleEmail';
import HandleText from './HandleText';

import Circle from 'components/markup/loading/Circle'

class MessagingCenterContent extends Component {

    state = {
        type: null,

        show: null,

        emailResponse: {},
        cases: null,
        contact: null,
        noFoundCases: null,

        showModalCreateReminder: false,
        showModalAssociateContact: false,

    }

    fetchContact = async (contact_id) => {

        const contact = await _contacts.findById(contact_id, true)

        if(contact.success && contact.data) {

            this.setState({contact: contact.data, loaded: true})

        }

    }

    fetchContactCases = async (contact_id) => {

        this.setState({loadingCases: true})
        const cases = await _cases.find(`?filter=contact__${contact_id}|deleted__false{bool}`, true)

        if(cases && cases.data && cases.data.length) {
            this.setState({cases: cases.data, noFoundCases: false, loadingCases: false})
        } else {
            this.setState({noFoundCases: true, loadingCases: false})
        }

    }

    onTextSent = () => {
        this.toggleRead('text', true)
        this.props.setContent()
    }

    toggleRead = async (type, read) => {

        const { content } = this.props

        if(type === 'email') {

            _contact_emails.update(content._id, {read: read ? read : !content.read})

        } else {

            _contact_texts.update(content._id, {read: read ? read : !content.read})

        }

        this.props.setContent();


    }

    onChangeShow = (show) => {

        this.setState({show}, () => {

            if(show === 'reply') {

                // scroll to bottom of reply
                var objDiv = document.getElementById("archk-email-send-center-content");
                objDiv.scrollTop = objDiv.scrollHeight;

            }


        })

    }

    onSentEmail = async () => {

        // await here to when props are clear it does not update again by the socket fired by marking read
        await _contact_emails.update(this.props.content._id, {read: true})
        this.props.setContent();

    }

    toggleModalCreateReminder = () => this.setState({showModalCreateReminder: !this.state.showModalCreateReminder})
    toggleModalAssociateContact = () => this.setState({showModalAssociateContact: !this.state.showModalAssociateContact})

    setEmailHTML = (html) => {

        return html.replace(/<html>/, '<div>').replace(/<\/html>/, '</div>')
            .replace(/<head>/, '<div>').replace(/<\/head>/, '</div>')
            .replace(/<body>/, '<div>').replace(/<\/body>/, '</div>')
            .replace(/<body dir="ltr">/, '<div>').replace(/<\/body>/, '</div>')
    }

    componentWillReceiveProps = (nextProps) => {

        const current_content_id = this.props.content ? this.props.content._id : null
        const next_content_id = nextProps.content ? nextProps.content._id : null

        // if changing the viewing email or text set show back to normal
        if(current_content_id !== next_content_id) this.setState({show: null, cases: null, contact: null})

        if(nextProps.content && nextProps.content.contact) {

            this.fetchContactCases(nextProps.content.contact._id ? nextProps.content.contact._id : nextProps.content.contact)
            this.fetchContact(nextProps.content.contact)

        }

    }

    render() {

        const { show, showModalCreateReminder, showModalAssociateContact, cases, contact, noFoundCases, loadingCases } = this.state
        const { type, content, viewingEmailAddress } = this.props;

        if(!content) return (
            <div className="center-content text-center d-flex">
                <div className="align-self-center w-100">
                    <div className="gradient-box d-none d-lg-block " style={{width: 350, margin: 'auto'}}>
                        <h2>Select An Email Or Text To View</h2>
                    </div>
                    <div className="gradient-box d-block d-lg-none " style={{width: 200, margin: 'auto'}}>
                        <h2>Select Email/Text</h2>
                    </div>
                </div>
            </div>
        )

        return (

            <div id="archk-email-send-center-content" className="center-content">

                {loadingCases ? <Circle /> : (
                    <div>
                        {(cases && cases.length) || contact ? (
                            <Card className="mb-0">
                                <CardBody>
                                    {contact ? (
                                        <p className="mb-0 text-capitalize">
                                            <b style={styles.bold}>Contact: </b>
                                            <Link to={`/dashboard/contacts/view/${contact._id}`}>
                                            {renderName(contact)}
                                            </Link>
                                        </p>
                                    ): null}
                                    {cases && cases.length ? (
                                        <p className="mb-0 text-capitalize">
                                            <b style={styles.bold} className="text-success">Cases: </b>
                                            {cases.map((c, i) => (
                                                <span key={c._id}>
                                                    {i !== 0 ? ' | ' : null}
                                                    <Link to={`/dashboard/cases/view/${c._id}`} >{c.name}</Link>
                                                </span>
                                            ))}
                                        </p>
                                    ): null}
                                </CardBody>
                            </Card>
                        ): null}

                        {noFoundCases === true ? (
                            <Card className="mb-0">
                                <CardBody>
                                    <p className="mb-0 text-danger font-weight-bold">No cases have been found for this contact</p>
                                </CardBody>
                            </Card>
                        ) : null }
                    </div>
                )}


                {type === 'email' ? (

                    <HandleEmail
                        content={content}
                        show={show}
                        toggleRead={this.toggleRead}
                        onChangeShow={this.onChangeShow}
                        toggleModalCreateReminder={this.toggleModalCreateReminder}
                        toggleModalAssociateContact={this.toggleModalAssociateContact}
                        viewingEmailAddress={viewingEmailAddress}
                        onSentEmail={this.onSentEmail}
                    />

                ) : type === 'text' ?  (

                    <HandleText
                        content={content}
                        show={show}
                        toggleRead={this.toggleRead}
                        onChangeShow={this.onChangeShow}
                        onSuccess={this.onTextSent}
                    />


                ) : null}

                <ModalCreateReminder
                    toggleModal={this.toggleModalCreateReminder}
                    showModal={showModalCreateReminder}
                    email={content}
                />

                <ModalAssociateToContact
                    toggleModal={this.toggleModalAssociateContact}
                    showModal={showModalAssociateContact}
                    email={content}
                />

            </div>

        )

    }

}

const styles = {
    bold: {
        display: 'inline-block',
        width: 70,
        fontWeight: 'bold'
    }
}

MessagingCenterContent.propTypes = {
    setContent            : PropTypes.func.isRequired,
    viewingEmailAddress   : PropTypes.string.isRequired,
    type                  : PropTypes.string,
    content               : PropTypes.object,
}

export default MessagingCenterContent
