import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const ModalTooLarge = ({showModal, toggleModal, file}) => (

    <Modal className="modal-dialog-centered" isOpen={showModal} toggle={toggleModal} size="md" >

        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><i className="fas fa-exclamation text-warning mr-2 " /> File Size Exceeded</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggleModal}>
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body bg-secondary">
            <h4>{file.name}</h4>
            
            <p className="text-sm">
                The max file size that can be uploaded is {' '}
                <b className="text-dark text-underline">50MB</b>, {' '}
                the file you are trying to upload is  {' '}
                <b className="text-dark text-underline">{(file.size / 1000000).toFixed(2)} MB.</b> 
            </p>

            <p className="text-sm">
                Please either reduce the file's size or upload it as a zip file.
            </p>

            <p className="text-sm mb-0">
                For pdf's you can reduce the file size by clicking this link:{' '}
                <a className="text-warning font-weight-bold" target="_blank" rel="noopener noreferrer" href="https://www.ilovepdf.com/compress_pdf">
                    https://www.ilovepdf.com/compress_pdf
                </a>
            </p>
        </div>

        <div className="modal-footer text-right">
            <button className="btn btn-success" onClick={toggleModal}>Close</button>
        </div>

    </Modal>

)

ModalTooLarge.propTypes = {
    file   : PropTypes.object,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
}

export default ModalTooLarge;
