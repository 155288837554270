/*
Documentation

this returns and array of emails a user can view
if the user have the privilege can_view_all_email_addresses they can view all emails
if not they an only view the ones they can send from

*/

import getAuthorizedSendingEmails from './getAuthorizedSendingEmails'

export default (user_email, company, user_privileges) => {


    if(!user_privileges.includes('can_view_all_email_addresses')) {
        const authorized =  getAuthorizedSendingEmails(user_email, company)
        return authorized
    }

    const company_emails = company && company.microsoft_email_addresses ? company.microsoft_email_addresses : [];
    const authorized_emails = company.authorized_emails ? [...company.authorized_emails] : [];

    return company_emails.concat(authorized_emails)

}
