import moment from 'moment';
import * as userConfig from '_functions/users/configuration';

export const getEntryDate = (entry) => {

    if(userConfig.feedShowFullDate()) {
        return moment.unix(entry.created_at).format("M/D/YY h:mm A");
    }

    return moment.unix(entry.created_at).fromNow()

}
