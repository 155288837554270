import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system'

import Circle from 'components/markup/loading/Circle';

import _questionnaire_answers from '_functions/questionnaire_answers';
import _contacts from '_functions/contacts';

import Navigation from './Navigation'
import Questions from './Questions'

const Questionnaire = ({contact_id, populatedContact, case_id, questionnaires, questionnaire, defaultAnswers, onAnswerChange, onMarkDocNaOnNo}) => {

    const [needQuestionnaires, setNeedsQuestionnaires] = useState(false);
    const [contact, setContact] = useState({});
    const [err, setErr] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [activeQuestionnaire, setActiveQuestionnaire] = useState({});
    const [answers, setAnswers] = useState({});

    const onSave = async () => {
        toggleStandardLoader(true)
        const result = await _questionnaire_answers.save(activeQuestionnaire._id, contact_id, case_id, answers)
        toggleStandardLoader(false);

        if(result.success) {
            toggleAlertBS(false, 'Saved Successfully');
            setHasChanges(false);
        } else {
            toggleAlertBS(true, 'Something Went Wrong');
        }
    }

    const onSetContact = useCallback(() => new Promise (async resolve => {
        const foundContact = await _contacts.findById(contact_id);
        if(foundContact.data) setContact(foundContact.data);

        return resolve();
    }), [contact_id])

    const onSetActiveQuestionnaire = useCallback(async (newQuestionnaire) => {

        if(newQuestionnaire._id !== activeQuestionnaire._id) {
            setActiveQuestionnaire(newQuestionnaire)
            setLoaded(false);

            const foundAnswers = await _questionnaire_answers.findByContact(newQuestionnaire._id, populatedContact ? populatedContact._id : contact_id)
            if(!foundAnswers.success) return setErr(true)

            const toSet = foundAnswers.data && foundAnswers.data.answers ? foundAnswers.data.answers : {};

            setAnswers(toSet);
            setLoaded(true);
            setHasChanges(false)
        }

    }, [activeQuestionnaire._id])

    const onSetAnswers = (question, value) => {

        const copy = JSON.parse(JSON.stringify(answers));
        copy[question._id] = value;
        setAnswers(copy);
        setHasChanges(true)
        if(onAnswerChange) {
            onAnswerChange({activeQuestionnaire, contact_id, case_id, answers: copy})
        }
    }

    const initialLoad = async () => {
        // 1 set contact to state
        if(populatedContact) {
            setContact(populatedContact)
        } else {
            await onSetContact(contact_id);
        }

        // 2. set active questionnaire
        let active = questionnaires[0];

        if(questionnaire) {
            const foundQuestionnaire = questionnaires.find(q => q._id === questionnaire);
            if(foundQuestionnaire) active = foundQuestionnaire;
        }

        if(active) {
            onSetActiveQuestionnaire(active);
        } else {
            setNeedsQuestionnaires(true);
            setLoaded(true);
        }

        // 3. set answers if passed in
        if(defaultAnswers) {
            setAnswers(defaultAnswers)
        }
    }

    useEffect(() => {
        initialLoad();
    }, [])

    if(err) return <div className="alert alert-danger">Somethings not right, Please try again later.</div>
    if(!loaded) return <Circle />

    if(needQuestionnaires) {
        return <div className="alert alert-warning"><i className="fas fa-info-circle mr-2 " /> No questionnaires are currently active.</div>
    }

    return (
        <div className="archk-contact-questionnaires">

            <Row>
                <div className="navigation col-auto">
                    <Navigation 
                        activeQuestionnaire={activeQuestionnaire}
                        onSetActiveQuestionnaire={onSetActiveQuestionnaire}
                        questionnaires={questionnaires.filter(q => q.active === true)}
                    />
                </div>
                <Col className="questions">
                    {hasChanges ? (
                        <div className="alert alert-warning change-alert">
                            <i className="fas fa-info-circle mr-2 " /> The form has unsaved changes.
                        </div>
                    ) : null}
                    <h3>{activeQuestionnaire.name}</h3>
                    <hr className="my-3" />
                    <Questions 
                        questions={activeQuestionnaire.questions}
                        onSetAnswers={onSetAnswers}
                        answers={answers}
                        nestedLevel={0}
                        questionnaireId={activeQuestionnaire._id}
                        contact={contact}
                        onMarkDocNaOnNo={onMarkDocNaOnNo}
                    />

                    <hr />

                    <div className="text-right">
                        <button className="btn btn-outline-success" onClick={onSave}>
                            <i className="fas fa-save mr-2 " /> Save
                        </button>
                    </div>
                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires.questionnaires,
    };
};

export default connect(mapStateToProps, '')(Questionnaire);