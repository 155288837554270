import React, { Component } from 'react';
import Actions from './Actions';
import History from './History';
import * as privileges from '_functions/users/privileges';

class Trust extends Component {

    render() {

        const canUpdate = privileges.canUpdateFinances()

        const { invoices } = this.props
        const _case = this.props.case

        return (

            <div>

                {!canUpdate ? (
                    <div className="alert alert-danger">Your account does not have access to update trust records.</div>
                ) : null}

                <Actions
                    invoices={invoices}
                    case={_case}
                />
                <History
                    case={_case}
                />

            </div>

        )

    }

}

export default Trust;
