/*
Documentation

takes an object with given_name and family_name properties and tries to match them up
into one coherent string

*/

export default (obj) => {

    let name = 'Not Found'

    if(!obj) return name


    if(obj.given_name && obj.family_name) {

        name = obj.given_name + ' ' + obj.family_name.slice(0, 1)

    } else if(obj.given_name) {

        name = obj.given_name

    } else if(obj.family_name) {

        name = obj.family_name

    }

    if(name.includes('delmair')) name = name.replace('delmair', 'DelMair');

    return name;

}
