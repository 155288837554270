import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import { Modal } from 'reactstrap';

import { formatPhone } from 'utils/text'
import { toggleAlertBS } from 'store/functions/system/system';

import { architeckCall } from 'database';


const CallTransfer = ({viewing_user}) => {


    const [redirect, setRedirect] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState({
        contact: {}
    })

    const onCloseModal = useCallback((data) => {
        setShowModal(false);

        architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/browser_transfer_denied',
            data: data
        })
    }, [])

    const onStartCall = useCallback(() => {
        setShowModal(false)
        const phone = data.contact && data.contact.phone ? data.contact.phone : null
        if(!phone) return toggleAlertBS('info', 'This call has been taken')

        setRedirect(`/dashboard/call_center_redirect?startCall=true&phone=${data.contact.phone}&isTransfer=true`)
        setTimeout(() => {
            setRedirect(false)
        }, 1000);

    }, [data.contact])

    const getName = useCallback((queue) => {
        let name = 'Unknown';

        if(queue.contact) {
            const contact = queue.contact;
    
            if(contact.given_name && contact.family_name) {
                const string = contact.given_name + ' ' + contact.family_name
                if(string && string.length > 10) {
                    name = contact.given_name ? contact.given_name : contact.family_name
                } else {
                    name = string
                }
            } else if(contact.given_name) {
                name = contact.given_name;
            } else if(contact.family_name) {
                name = contact.family_name
            } else {
                name = formatPhone(queue.contact.phone)
            }
        }
        return name;
    }, [])

    const listenForTransferStarted = useCallback((data) => {
        // if(data.data && data.data.user === viewing_user._id && viewing_user.call_status === 'open') {
        if(data.data && data.data.user === viewing_user._id) {
            if(!viewing_user.call_conference) {
                setShowModal(true);
                setData(data.data)

                setTimeout(() => {
                    onCloseModal(data.data)
                }, 15 * 1000)
            }
        }
    }, [viewing_user.call_conference, viewing_user._id, showModal, onCloseModal])
    
    useEffect(() => {
        if(showModal && viewing_user.call_conference) {
            setTimeout(() => {
                setShowModal(false)
            }, 1000)
        }
    }, [viewing_user.call_conference, showModal, onCloseModal])

    useEffect(() => {
        
        io.on(socketEvents.twilio_browser_transfer,   listenForTransferStarted);
        return () => {
            io.off(socketEvents.twilio_browser_transfer,   listenForTransferStarted);
        }
    }, [listenForTransferStarted])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => onCloseModal(data)}
            size="sm"
        >
            <div className="modal-header">
                <h5 className="modal-title">New Transfer Request</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onCloseModal(data)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-dark">
                <p className="text-sm text-white">You have an incoming transfer, click here to accept.</p>
                <p className="text-sm text-white mb-0">Lead: {getName(data.contact)}</p>
            </div>

            <div className="modal-footer">
                <button className="btn btn-warning" onClick={() => onCloseModal(data)}>Close</button>
                <button className="btn btn-success" onClick={onStartCall}>Accept Transfer</button>
            </div>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CallTransfer);