/*
Documentation

this file renders a modal that opens up a case searching table

*/

import React from "react";
import { Modal } from "reactstrap";
import CaseSearch from 'views/dashboard/pages/_components/CaseSearch';
import { Redirect } from 'react-router-dom'

class FindContact extends React.Component {

    state = {
        filter: null
    }

    onSelect = (contact) => {

        this.setState({shouldRedirect: `/dashboard/cases/view/${contact._id}`}, () => {
            this.setState({shouldRedirect: false})
            this.props.toggle()
        })

    }

    render() {

        if(!this.props.showModalFindCase) return null
        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={this.props.showModalFindCase}
                toggle={() => this.props.toggle()}
                size="md"
            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">
                        Find Case
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body px-2">
                    <CaseSearch onSelect={this.onSelect} />
                </div>

            </Modal>

        );
    }
}

export default FindContact

