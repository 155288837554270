/*
Documentation

This file renders a table showing all garnishments for a specific case

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import { formatMoney } from 'utils/currency';
import filter from 'utils/filter';
import renderName from 'utils/renderName';
import _garnishments from '_functions/garnishments';

import ModalGarnishmentsCrud from './components/ModalGarnishmentsCrud';

class Garnishments extends React.Component {

    state = {
        garnishmentToDelete: null,
        showModalGarnishmentsCrud: false,
        garnishments: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Garnishment",
            formatter: (cell, row) => (
                <Link to={`/dashboard/garnishments/${this.props.match.params.case}/${row._id}`} className="text-capitalize font-weight-bold" style={{whiteSpace: 'pre-line'}}>
                    <span className="text-warning text-capitalize">{row.name}</span>
                </Link>
            )
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.status} </span>
            )
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {

                if(!row.amount) return (
                    <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="danger">
                        Not Set
                    </Badge>
                )

                if(row.amount) return (
                    <Badge style={{width: 120, display: 'inline-block'}} className='text-sm' color="success">
                        {formatMoney(row.amount)}
                    </Badge>
                )

            }
        },
        {
            dataField: "created_by",
            text: "Created By",
            formatter: (cell, row) => (
                row.created_by ? (
                    <span className="text-capitalize">
                        <AvatarImage src={row.created_by.avatar} className="mr-2" />
                        {renderName(row.created_by, true)}
                    </span>
                ) : ''
            )
        },
        {
            dataField: "objecting_party",
            text: "Garnishment Parties",
            formatter: (cell, row) => (
                <>
                    <div>
                        <span style={{display: 'inline-block', width: 140}}>Garnishing Party:</span>
                        <b className="text-capitalize">{row.garnishing_party ? renderName(row.garnishing_party): '-'}</b>
                    </div>
                    <div>
                        <span style={{display: 'inline-block', width: 140}}>Garnishing Attorney:</span>
                        <b className="text-capitalize">{row.garnishing_party_attorney ? renderName(row.garnishing_party_attorney): '-'}</b>
                    </div>
                </>
            )
        },
        {
            dataField: "created_at",
            text: "Created At",
            formatter: (cell, row) => moment.unix(row.created_at).format("MMM, Do, YYYY -  h:mm A")
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/garnishments/${this.props.match.params.case}/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({garnishmentToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onGarnishmentRemoved = (garnishment) => {

        const garnishments = filter.byId(this.state.garnishments, garnishment.data._id)
        this.setState({garnishments});

    }

    onGarnishmentAdded = (data) => {

        this.toggleModalGarnishmentsCrud()

        const garnishments = this.state.garnishments ? [...this.state.garnishments] : [];
        garnishments.push(data.data)

        this.setState({garnishments});

    }

    toggleModalGarnishmentsCrud = () => this.setState({showModalGarnishmentsCrud: !this.state.showModalGarnishmentsCrud})


    componentDidMount = async () => {

        const garnishments = await _garnishments.find(`?filter=case__${this.props.match.params.case}&sort=resolved_at__desc|severity__desc`)
        if(garnishments.success) this.setState({garnishments: garnishments.data})

    }

    render() {

        const { garnishments, garnishmentToDelete } = this.state;

        return (
            <>

            <Helmet>
                <title>{`Garnishments`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    title={<span>Case Garnishments</span>}
                    breadcrumb_1={(
                        <Link to={`/dashboard/cases/view/${this.props.match.params.case}`} className="text-muted"><i className="fas fa-arrow-left mr-2" /> Back To Case</Link>
                    )}
                    actionComponent={(
                        <button onClick={this.toggleModalGarnishmentsCrud} className="btn btn-success">
                            <i className="fas fa-plus mr-2" /> New
                        </button>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Case Garnishments</CardTitle>
                    </CardHeader>

                    {garnishments ? (

                        garnishments.length ? (

                            <ToolkitProvider
                                data={garnishments}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                    <div className=" table-responsive table-vertical-align table-not-fixed">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                    </>
                                )}
                            </ToolkitProvider>

                        ) : (

                            <CardBody>
                                No garnishments have been created for this case yet.
                            </CardBody>

                        )

                    ) : (
                        <Circle />
                    )}

                </Card>

                {garnishmentToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/core/garnishments/delete/${garnishmentToDelete._id}`}
                        confirmBtnText="Delete Object"
                        title={<span className="text-capitalize">{`Delete Garnishment: ${garnishmentToDelete.name}`}</span>}
                        text="This action will remove all data associated with the garnishment."
                        onClose={() => this.setState({garnishmentToDelete: null})}
                        onSuccess={this.onGarnishmentRemoved}
                    />
                )}

                <ModalGarnishmentsCrud
                    case={this.props.match.params.case}
                    showModal={this.state.showModalGarnishmentsCrud}
                    toggleModal={this.toggleModalGarnishmentsCrud}
                    onSuccess={this.onGarnishmentAdded}
                />

            </Container>

            </>
        );
    }
}

export default Garnishments
