
import ScreenWatch from '../pages/screen_watch';

export default () => {

    return[


        /***********************
        ScreenWatch
        ************************/
        {
            path: "/screen_watch/:user_id",
            component: ScreenWatch,
        },

    ]

}
