import PropTypes from 'prop-types';
import React from "react";
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'
import Circle from 'components/markup/loading/Circle';

import _cases from '_functions/cases';
import _contacts from '_functions/contacts';
import * as _ from 'underscore';

class ContactAndCaseSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            showResults: false,
            selected: {},
            dataToShow: [],
            loading: false,
        }
       this.queryCasesThrottled = _.throttle(this.queryCases, 750)
    }


    onSelect = (selected, e) => {

        // we clicked the link icon, do nothing
        if(e.target.tagName === 'i') return;

        this.setState({selected: {}, filter: '', showResults: false})
        this.props.onSelect(selected, false)

    }

    onLinkSelect = (selected) => {

        this.setState({selected: {}, filter: '', showResults: false})
        this.props.onSelect(selected, true)

    }

    queryCases = async (value) => {

        this.setState({loading: true})

        const values = await Promise.all([
            _cases.search({
                search: value,
                limit: 25,
            }),
            _contacts.search({
                search: value,
                limit: 25,
            })
        ])

        if(values[0].success && values[1].success) {
            const data = values[0].data.concat(values[1].data)
            data.sort((a,b) => a.name < b.name ? -1 : 1)
            this.setState({dataToShow: data, showResults: true, loading: false})
        }

    }


    onChange = async (value) => {

        this.setState({filter: value})

        if(value) {
            this.queryCasesThrottled(value)
        } else {
            this.setState({filter: null, showResults: false})
        }

    }


    componentDidMount = () => {
        const { value } = this.props
        this.setState({ selected: value ? value : {} })
    }

    render() {

        const { dataToShow, loading } = this.state

        return (

            <div className="position-relative d-inline-block">

                {loading ? (

                    <span style={{position: 'absolute', right: 0, top: -3}}>
                        <Circle color="dark" />
                    </span>

                ) : null}

                <ObjectSearchInput 
                    data={dataToShow}
                    onChange={this.onChange}
                    onSelect={this.onSelect}
                    placeholderFormatter={'Search Contacts OR Cases'}
                    hideResultsOnFocus={true}
                    noResultsFormatter={(
                        <div>
                            No Results found. If searching by name make sure you search by <b className="text-warning font-weight-bold">first name and then last name</b>. If searching by phone try <b className="text-warning font-weight-bold">removing all spaces, dashes, etc</b>.
                        </div>
                    )}
                    resultsFormatter={(result) => (
                        result.workflow ? (
                            <div className="position-relative">

                                <i 
                                    className="fas fa-link bg-gradient-purple text-white new-link" 
                                    onClick={(e) => this.onLinkSelect(result)} 
                                />

                                <li key={result._id} onClick={(e) => this.onSelect(result, e)} >
                            
                                    <p className="text-sm mb-0 text-capitalize">{result.name ? result.name : "CASE NAME NOT FOUND"}</p>
                                    <p className="text-sm mb-0 text-muted">
                                        {result.type === 'Credit Report Review' ? (
                                            <i className="fas fas fa-search-dollar mr-2 text-danger" />
                                        ) : (
                                            <i className="fas fa-shoe-prints mr-2 text-warning" />
                                        )}
                                        STEP:  {result.finished_on ? 'Finished' : result.current_step ? result.current_step.name : 'NOT FOUND'} </p>
                                </li>

                            </div>
                        ) : (
                            <div className="position-relative">

                                <i 
                                    className="fas fa-link bg-gradient-purple text-white new-link" 
                                    onClick={(e) => this.onLinkSelect(result)} 
                                />

                                <li key={result._id} onClick={(e) => this.onSelect(result, e)} className="position-relative">
                                    <p className="text-sm mb-0 text-capitalize">
                                        <i className="fas fa-user mr-2 text-success" />
                                        {renderName(result) === 'Not Found' ? 'Name Not Found' : renderName(result)}
                                    </p>
                                    {result.phone ? 
                                        <p className="text-sm mb-0 text-muted">
                                            <i className="fas fa-phone mr-2" />
                                            {formatPhone(result.phone)}
                                        </p> 
                                    : null}
                                    {result.email ? 
                                        <p className="text-sm mb-0 text-muted">
                                            <i className="fas fa-envelope mr-2" />
                                            {result.email} 
                                        </p> 
                                    : null }
                                </li>

                            </div>
                        )
                        
                    )}
                />

            </div>
        );
    }
}

ContactAndCaseSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default ContactAndCaseSearchInput
