/*
Documentation

this file creates a modal to upload missing docs on a case

*/

import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

class ModalMissingDocs extends React.Component {

    onUploadStarted = () => {
        this.props.toggleModal();
    }

    render() {

        const { missing_doc, showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{missing_doc ? missing_doc.name : ''}</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    {missing_doc ? (
                        <DragAndDrop
                            zone_id="documents_2"
                            url={`/api/v1/core/documents/create`}
                            onUploadStarted={this.onUploadStarted}
                            defaultValue={missing_doc.name}
                            extraData={{
                                case: this.props.case._id, 
                                contact: this.props.case.contact._id, 
                                finishType: 'template_doc', 
                                templateDocName: missing_doc.name
                            }}
                        />
                    ) : null}
                    
                </div>

            </Modal>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

ModalMissingDocs.propTypes = {
    missingDoc: PropTypes.object,
    case: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, '')(ModalMissingDocs);
