import React, { Component } from 'react';
import _invoices from '_functions/invoices';
import { toggleAlertBS } from 'store/functions/system/system'
import * as privileges from '_functions/users/privileges';

class Actions extends Component {

    state = {};

    onSave = async () => {

        const invoice = this.props.invoice;

        if(!invoice.name) return toggleAlertBS(true, 'Invoice name must be specified before saving.')

        if(invoice._id) {

            const update_invoice = await _invoices.update(invoice)

            if(update_invoice.success) {
                toggleAlertBS(false, 'Invoice updated successfully.')
            } else {
                toggleAlertBS(true, update_invoice.user_message ? update_invoice.user_message : 'An error occurred updating this invoice.')
            }

        } else {

            const create_invoice = await _invoices.create({
                ...invoice,
                case: this.props.case._id,
            })

            if(create_invoice.success) {
                toggleAlertBS(false, 'Invoice created successfully.')
                this.props.setCreatedInvoice(create_invoice.data)
            } else {
                toggleAlertBS(true, create_invoice.user_message ? create_invoice.user_message : 'An error occurred saving this invoice.')
            }


        }

    }

    onDelete = async () => {

        const update_invoice = await _invoices.delete(this.props.invoice._id)

        if(update_invoice.success) {
            this.props.toggle()
        } else {
            toggleAlertBS(true, 'An error occurred deleting this invoice.')
        }

    }

    render() {

        const invoice = this.props.invoice;

        const canUpdate = privileges.canUpdateFinances()

        return (

            <>

                {invoice._id && invoice.status === 'draft' ? (
                    <div className="text-right float-left">
                        <button onClick={this.onDelete} className="btn btn-danger float-left">Delete Draft</button>
                    </div>
                ) : null}

                <div className="text-right float-right">
                    <button
                        onClick={canUpdate ? this.onSave : null}
                        className="btn btn-success float-right"
                        disabled={!canUpdate}
                    >
                        Save
                    </button>
                </div>

            </>

        )

    }

}

export default Actions;
