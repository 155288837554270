import React, {useEffect, useState, useCallback} from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import { Card,  CardHeader, Container, CardTitle, CardBody } from "reactstrap";
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';

import reactHTMLParser from 'react-html-parser'

import _wikis from '_functions/wikis';

const View = ({match}) => {

    const [loaded, setLoaded] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const [state, setState] = useState({
        body: '',
        subject: '',
    });

    const fetchWiki = useCallback(async (_id) => {
        const data = await _wikis.findById(_id);
        if(data.data) {
            setState({
                body: data.data.body,
                subject: data.data.subject,
            })
            setLoaded(true);
        } else {
            setRedirect('/dashboard/help/wikis');
        }
    }, [])

    useEffect(() => {
        fetchWiki(match.params._id)
    }, [match.params._id, fetchWiki])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <Circle />

    return (
        <Container fluid>

            <Helmet>
                <title>Wikis</title>
                <meta name="description" content="Wikis" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> Help</span>}
                breadcrumb_1="Wikis"
                actionComponent={(
                    <Link to="/dashboard/help/wikis" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                )}
            />

            <Card  className="card-color card-success">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">{state.subject}</CardTitle>
                </CardHeader>

                <CardBody className="ql-editor-border">
                    {reactHTMLParser(state.body)}
                </CardBody>

            </Card>
        </Container>
    )
}

export default View;