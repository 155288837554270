/*
Documentation

This page is the first step in creating a case.
It's purpose is to

1) let a user select a workflow before continuing or 2) prompt them to create
a workflow if one does not already exist.

*/

import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

class SelectWorkflow extends React.Component {

    state = {}

    renderCard = (w) => {

        const { selected_workflow } = this.props
        const selected = selected_workflow._id === w._id

        // here we set the card to have a green background if it's selected
        // this will only show should a user come back
        let cardClassNames = "text-white cursor-pointer text-center"
        cardClassNames = selected ? cardClassNames + ' bg-gradient-success' : cardClassNames

        let h3ClassNames = "mb-0 text-muted font-weight-normal"
        h3ClassNames = selected ? h3ClassNames + ' text-white' : h3ClassNames

        let h2ClassNames = "my-2"
        h2ClassNames = selected ? h2ClassNames + ' text-white' : h2ClassNames

        return (
            <Card onClick={() => this.props.onSelectWorkflow(w)} className={cardClassNames} >

                <CardHeader className={selected ? "bg-transparent " :"" } >
                    <CardTitle className={h3ClassNames} >Select Workflow</CardTitle>
                </CardHeader>

                <CardBody><h2 className={h2ClassNames}>{w.type}</h2> </CardBody>

            </Card>
        )

    }

    render() {

        let workflows = [...this.props.workflows.workflows]

        // filter workflows to only get the active ones
        if(workflows && workflows.length) workflows = workflows.filter(w => w.active)

        return (
            <Row>

                {workflows && workflows.length ? workflows.map(w => (
                    <Col key={w._id} lg={3} md={4}>
                        {this.renderCard(w)}
                    </Col>
                )): (
                    <Col lg={3} md={4}>
                        <Link to="/dashboard/workflows/all?create=true">
                            <Card className="text-white cursor-pointer text-center bg-gradient-danger">
                                <CardHeader className="bg-transparent" >
                                    <h3 className="mb-0 text-muted font-weight-normal text-white" >
                                        No Active Workflows Found
                                    </h3>
                                </CardHeader>

                                <CardBody>
                                    <h2 className="my-2 text-white">
                                        Click here to create your first workflow
                                    </h2>
                                </CardBody>

                            </Card>
                        </Link>
                    </Col>
                )}

            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        workflows: state.workflows
    };

};

SelectWorkflow.propTypes = {
    onSelectWorkflow: PropTypes.func.isRequired,
    selected_workflow: PropTypes.object
}

export default connect(mapStateToProps, '')(SelectWorkflow);
