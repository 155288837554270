import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';

const required_form_fields = [
    'amount',
    'description',
    'date',
    'hours'
]

class Fees extends Component {

    state = {
        fee: {},
    };

    onInputChange = (f,v) => {

        let fee = Object.assign({}, this.state.fee);
        fee[f] = v

        this.setState({fee})

    }

    inputsAreValid = () => {

        let errors = 0;
        let newState = Object.assign({}, this.state.fee);

        required_form_fields.forEach((field) => {

            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        // validate decimals
        if(!_payments.validate.decimals(newState.amount)) {
            newState["amountState"] = 'invalid';
            errors++
        }

        // validate we have an actual number
        if(isNaN(parseFloat(newState.amount))) {
            newState["amountState"] = 'invalid';
            errors++
        }

        this.setState({fee: newState})
        return errors ? false : true

    }

    onAddInvoiceItem = () => {

        // validate all required fields are filled out
        if(!this.inputsAreValid()) return;

        let fee = Object.assign({}, this.state.fee);

        //convert date to unix timestamp
        fee.date = moment(fee.date).valueOf() / 1000;
        //add a mock id for reference to this specific item (user id + unix timestamp)
        fee.id = this.props.viewing_user._id + '_' + Math.floor(new Date() / 1000)
        //if status has not been changed set it to billable
        fee.status = fee.status ? fee.status : 'billable'

        fee.hours = parseFloat(fee.hours)
        fee.amount = parseFloat(fee.amount)

        this.props.onAddInvoiceItem(fee);
        this.setState({fee: {}})

    }

    onRemoveInvoiceItem = (fee_id) => {

        this.props.onRemoveInvoiceItem(fee_id);

    }

    render() {

        const { fee } = this.state
        const { invoice } = this.props

        const fees = invoice.fees

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Current Fee Items</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table  className="table">

                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Hours</th>
                                <th style={{width: 95}}>Amount</th>
                                <th style={{width: 95}}>Date</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>

                            {fees && fees.length ? fees.map(f => (
                                <tr key={f._id ? f._id : f.id}>
                                    <td className="text-capitalize" style={{wordBreak: 'break-word'}}>
                                        {f.description && f.description.includes('Uploaded the document') ?
                                            'Uploaded document(s)' :
                                            f.description.includes('href=') ?
                                            'Uploaded document(s)' :
                                            f.description
                                        }
                                    </td>
                                    <td className="text-capitalize">
                                        <Badge
                                            color={
                                                f.status === 'not billable' ? 'warning' :
                                                f.status === 'hidden' ? 'danger': 'success'
                                            }
                                            className="d-block text-capitalize"
                                        >
                                            {f.status}
                                        </Badge>
                                    </td>
                                    <td>{f.hours}</td>
                                    <td>{formatMoney(f.amount)}</td>
                                    <td>{moment.unix(f.date).format('MM/DD/YYYY')}</td>
                                    <td className="text-right">
                                        <i

                                            onClick={() => this.onRemoveInvoiceItem(f._id ? f._id : f.id)}
                                            className="fas fa-trash text-danger mr-2 cursor-pointer"
                                        />
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td style={{wordBreak: 'break-word'}}>No fees have been created yet.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>

                    </table>
                </div>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Add Fees</CardTitle>
                </CardHeader>

                <CardBody>

                    <Row>

                        <Col md={6}>
                            <FormGroup>
                                <label className="form-control-label">Item Date</label>
                                <div
                                    className={
                                        fee.dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                        fee.dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                    }
                                >
                                    <DatePicker
                                        selected={fee.date}
                                        onChange={date => this.onInputChange('date', date)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <StandardFormGroup
                                obj={fee}
                                objName="fee"
                                onChange={(o, f, v) => this.onInputChange(f, v)}
                                type="select"
                                field="status"
                                title="Status"
                            >
                                <option value="billable">Billable</option>
                                <option value="not billable">Not Billable</option>
                                <option value="hidden">Hidden</option>
                            </StandardFormGroup>
                        </Col>

                    </Row>

                    <Row>

                        <Col md={6}>
                            <StandardFormGroup
                                obj={fee}
                                objName="fee"
                                onChange={(o, f, v) => this.onInputChange(f, v)}
                                type="number"
                                field="hours"
                                title="Total Hours"
                            />
                        </Col>

                        <Col md={6}>
                            <StandardFormGroup
                                obj={fee}
                                objName="fee"
                                onChange={(o, f, v) => this.onInputChange(f, v)}
                                type="number"
                                field="amount"
                                title="Total Amount"
                            />
                        </Col>

                    </Row>

                    <StandardFormGroup
                        obj={fee}
                        objName="fee"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field="description"
                        title="Item Description"
                    />

                    <div className="text-right">
                        <button className="btn btn-success" onClick={this.onAddInvoiceItem}>Add Fee</button>
                    </div>

                </CardBody>

            </Card>

            </>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Fees);
