/*
Documentation

in render week view if we are starting on a monday push the calendar ahead 1 day

*/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import renderWeekView from '../functions/ViewWeek/renderViewWeek';

class ViewWeek extends Component {

    render() {

        const { events, showView, startOnSunday }  = this.props
        let { day_to_show }  = this.props

        //if we are not in the calendar week view return
        if( showView !== 'week' ) { return null }

        const _this = this

        // if the moment toString includes 23:00:00 it means its daylight savings time and we are really trying to grab the next day
        if(moment.unix(day_to_show).toString().includes('23:00:00')) day_to_show += 86400


        let startOfWeek = moment.unix(day_to_show).startOf(startOnSunday ? 'week' : 'isoWeek').valueOf() / 1000

        return (

            <div className="table-responsive">
                <table className="table view-week" style={{tableLayout: 'fixed'}}>

                    <thead>
                        <tr>

                            <th style={{width: 130, verticalAlign: 'middle'}}>Time</th>

                            {startOnSunday ? (
                                <>
                                    <th>Sun <br />({moment.unix(startOfWeek + (86400 * 0) + 3600).format('Do')})</th>
                                    <th>Mon <br />({moment.unix(startOfWeek + (86400 * 1) + 3600).format('Do')})</th>
                                    <th>Tue <br />({moment.unix(startOfWeek + (86400 * 2) + 3600).format('Do')})</th>
                                    <th>Wed <br />({moment.unix(startOfWeek + (86400 * 3) + 3600).format('Do')})</th>
                                    <th>Thu <br />({moment.unix(startOfWeek + (86400 * 4) + 3600).format('Do')})</th>
                                    <th>Fri <br />({moment.unix(startOfWeek + (86400 * 5) + 3600).format('Do')})</th>
                                    <th>Sat <br />({moment.unix(startOfWeek + (86400 * 6) + 3600).format('Do')})</th>
                                </>
                            ) : (
                                <>
                                    <th>Mon  <br />({moment.unix(startOfWeek + (86400 * 0) + 3600).format('Do')})</th>
                                    <th>Tue  <br />({moment.unix(startOfWeek + (86400 * 1) + 3600).format('Do')})</th>
                                    <th>Wed  <br />({moment.unix(startOfWeek + (86400 * 2) + 3600).format('Do')})</th>
                                    <th>Thu  <br />({moment.unix(startOfWeek + (86400 * 3) + 3600).format('Do')})</th>
                                    <th>Fri  <br />({moment.unix(startOfWeek + (86400 * 4) + 3600).format('Do')})</th>
                                    <th>Sat  <br />({moment.unix(startOfWeek + (86400 * 5) + 3600).format('Do')})</th>
                                    <th>Sun  <br />({moment.unix(startOfWeek + (86400 * 6) + 3600).format('Do')})</th>
                                </>
                            )}

                        </tr>
                    </thead>

                    <tbody>
                        {renderWeekView(parseInt(moment.unix(day_to_show).startOf('week').format('X')), events, _this).markup}
                    </tbody>

                </table>

            </div>

        )

    }

}

ViewWeek.propTypes = {

    // this is the start of the day we wish to show in the header | also control moving forward and backwards
    day_to_show: PropTypes.number.isRequired,

    //what type of view to render
    showView: PropTypes.oneOf(['day', 'week', 'month']).isRequired,

    //an combined array of case_workflow_tasks and appointments to be rendered on the calendar
    events: PropTypes.array.isRequired,

}

export default ViewWeek;
