/*
Documentation

If a use is unauthorized to view a resource this page should be shown

*/

import React from "react";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, Container, CardBody, CardTitle } from "reactstrap";

class Unauthorized extends React.Component {

    render() {

        return (

            <Container className="pt-4">

                <Helmet>
                    <title>Unauthorized</title>
                    <meta name="description" content="Unauthorized" />
                </Helmet>

                <Card className="mt-4">

                    <CardHeader>
                        <CardTitle className="mb-0">Unauthorized</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">
                            Your account does not have access to view this resource.
                            <br />If this is something you believe your account should handle contact your system administrator immediately.
                        </p>
                    </CardBody>

                </Card>

            </Container>

        );
    }
}

export default Unauthorized

