/*
Documentation

This file renders the individual objection page where it can be updated

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import filter from 'utils/filter';
import { convertDateToObject } from 'utils/time';
import validator from 'utils/validator';
import _objections from '_functions/objections';

import ObjectionDocuments from './components/Documents';
import ObjectionFields from './components/Fields';
import ObjectionList from './components/List';
import ModalObjectionsCrud from './components/ModalObjectionsCrud';
import ObjectionNotes from './components/Notes';
import ObjectionResolve from './components/Resolve';

class ObjectionView extends React.Component {

    state = {
        objection: null,
        objections: null,
        objectionNotFound: false
    }

    renderObjectionNotFound = () => (
        <Container className="pt-6">
            <div className=" alert alert-danger">
                <i className="fas fa-exclamation-triangle mr-3" />
                The objection you are trying to find does not exist.
            </div>
        </Container>
    )

    onInputChange = (field, value) =>  validator.onInputChange(value, 'objection', field, this)

    toggleModalObjectionsCrud = (action) => this.setState({ showModalObjectionsCrud: action})

    listenForObjectionsAdded = (data) => {

        const objections = this.state.objections ? [...this.state.objections] : [];

        if(data.data.case._id === this.props.match.params.case) {

            objections.push(data.data)
            this.setState({objections});

        }
    }

    listenForObjectionsUpdated = (data) => {

        const objections = this.state.objections ? [...this.state.objections] : [];

        // if the objection was for this case
        if(data.data.case._id === this.props.match.params.case) {

            objections[objections.findIndex(e => e._id === data.data._id)] = data.data;

            this.setState({objections}, () => {
                if(data.data._id === this.props.match.params.objection) {
                    this.setObjectionById(data.data._id)
                }
            });

        }

    }

    listenForObjectionsDeleted = (data) => {

        const objections = this.state.objections ? [...this.state.objections] : [];

        const newObjections = filter.byId(objections, data.data._id)
        this.setState({objections: newObjections});

    }

    initListeners = () => {
        io.on(socketEvents.objections_added,    this.listenForObjectionsAdded)
        io.on(socketEvents.objections_updated,  this.listenForObjectionsUpdated)
        io.on(socketEvents.objections_deleted,  this.listenForObjectionsDeleted)
    }

    componentWillUnmount = () => {
        io.off(socketEvents.objections_added,    this.listenForObjectionsAdded)
        io.off(socketEvents.objections_updated,  this.listenForObjectionsUpdated)
        io.off(socketEvents.objections_deleted,  this.listenForObjectionsDeleted)
    }

    setObjectionById = (_id) => {

        const objections = this.state.objections

        if(objections) {

            let objection = objections.find(o => o._id ===_id)
            if(objection) {

                objection.date_document_sent = convertDateToObject(objection.date_document_sent)
                objection.date_amendment_filed = convertDateToObject(objection.date_amendment_filed)
                objection.date_objection_filed = convertDateToObject(objection.date_objection_filed)

                this.setState({objection})
                return objection

            } else {

                this.setState({objectionNotFound: true})

            }

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.match.params.objection !== nextProps.match.params.objection) {
            this.setObjectionById(nextProps.match.params.objection)
        }

    }

    componentDidMount = async () => {

        this.initListeners()

        const objections = await _objections.find(`?filter=case__${this.props.match.params.case}&sort=resolved_at__desc|severity__desc`)

        if(objections.success) {

            this.setState({objections: objections.data}, () => {
                this.setObjectionById(this.props.match.params.objection)
            })

        }

    }

    render() {

        const { objection, objections, objectionNotFound } = this.state

        if(objectionNotFound) return this.renderObjectionNotFound()
        if(!objection) return <div className="py-6"><Circle /></div>

        const case_id = objection.case._id
        const contact_id = objection.case.contact

        return (
        <>

            <Helmet>
                <title>{`Objections`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Objections</span>} breadcrumb_1={objection.case.name}
                    actionComponent={(
                        <Link to={`/dashboard/objections/${case_id}`} className="btn btn-success">
                        <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                <Row>

                    <Col lg={4}>
                        <ObjectionList
                            objections={objections}
                            case_id={case_id}
                            objection={objection}
                            toggleModalObjectionsCrud={this.toggleModalObjectionsCrud}
                        />
                    </Col>

                    <Col lg={8}>

                        <ObjectionDocuments
                            objection={objection}
                            case_id={case_id}
                            contact_id={contact_id}
                        />

                        <Row>
                            <Col lg={6}>
                                <ObjectionFields
                                    objection={objection}
                                    onInputChange={this.onInputChange}
                                />
                                <ObjectionResolve
                                    objection={objection}
                                />
                            </Col>

                            <Col lg={6}>
                                <ObjectionNotes
                                    objection={objection}
                                    toggleModalObjectionsCrud={this.toggleModalObjectionsCrud}
                                />
                               
                            </Col>
                        </Row>

                    </Col>

                   

                </Row>

            </Container>

            <ModalObjectionsCrud
                case={case_id}
                showModal={this.state.showModalObjectionsCrud ? true : false}
                toggleModal={() => this.toggleModalObjectionsCrud(false)}
                onSuccess={() => this.toggleModalObjectionsCrud(false)}
                objection={this.state.showModalObjectionsCrud === 'create' ? null :  objection}
            />

            </>
        );
    }
}

export default ObjectionView
