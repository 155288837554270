import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle, Row, Col } from "reactstrap";
import renderName from 'utils/renderName';
import _collections from '_functions/collections';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';
import moment from 'moment'

import A from 'components/markup/links/A'

class Collections extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            total_documents: 0,
            data: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "contact",
            text: "Contact/Case",
            formatter: (cell, row) => (
                <div>
                    {row.contact ? (
                        <div className="text-capitalize">
                            <A href={`/dashboard/contacts/view/${row.contact._id}`} className="cursor-pointer">
                            {renderName(row.contact)}
                            </A>
                        </div>
                    ) : null}
                    {row.case ? (
                        <div className="text-capitalize">
                            <A href={`/dashboard/cases/view/${row.case._id}`} className="cursor-pointer">
                            {row.case.name}
                            </A>
                        </div>
                    ) : null}
                </div>
            ),
        },
        {
            dataField: "delinquent_since",
            text: "Dates",
            formatter: (cell, row) => (
                <div>
                    <div>
                        Delinquent Since: {moment.unix(row.delinquent_since).format('MMM Do, YYYY')}
                    </div>
                    <div>
                        Last Payment On: {' '}
                        {row.last_successful_payment ? (
                            moment.unix(row.last_successful_payment).format('MMM Do, YYYY')
                        ) : '-'}
                    </div>
                </div>
            )
        },
        {
            dataField: "collection_attempts",
            text: "Next Call / Attempts",
            formatter: (cell, row) => (
                <div>
                    <div>{moment.unix(row.next_call).format('MM/DD/YYYY h:mm A')}</div>
                    <div>Past Attempts: {row.collection_attempts}</div>
                </div>
                
            )
        },
        {
            dataField: "last_collection_attempt",
            text: 'Last Call For Payment',
            formatter: (cell, row) => row.last_collection_attempt ? moment.unix(row.last_collection_attempt).format('MM/DD/YYYY h:mm A') : '-'
        },
       
        {
            dataField: "updated_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        {row.contact ? (
                            <Link to={`/dashboard/call_center?startCall=true&phone=${row.contact.phone}&collectionId=${row._id}`} className="btn btn-success">Call For Payment</Link>
                        ) : null}
                    </div>
                )
            }
        },

    ]

    queryTableData = async (searchText, setPage1) => {

        this.setState({refreshing: true});
        if(setPage1) this.setState({page: 1})

        const collections = await _collections.search({
            needsCall: true,
            marked_for_collections: false,
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: collections.data,
            total_documents: collections.total_documents,
            canRun: false,
            refreshing: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    componentDidMount = async () => {

        const values = await Promise.all([
            _collections.failedToday(),
            _collections.search({
                needsCall: true,
                marked_for_collections: false,
                search: undefined,
                limit: this.state.sizePerPage
            })
        ])

        const failedToday = values[0].data;
        const collections = values[1];

        this.setState({data: collections.data, total_documents: collections.total_documents, failedToday})

    }


    render() {

        let { data, refreshing, failedToday} = this.state;

        const hasFailedToday = failedToday && failedToday.length ? true : false;

        return (
            <>


            <Container fluid>

                <Helmet>
                    <title>{`Collections (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Collections" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Collections</span>} breadcrumb_1="All"
                />

                {failedToday && failedToday.length ? (
                    <Card className="card-color card-primary">

                        <CardHeader>
                            <Row>
                                <Col xs={6} className="align-self-center">
                                    <CardTitle className="mb-0 float-left">
                                        {'Collections Failed Today Collections'}
                                    </CardTitle>
                                </Col>
                                <Col xs={6} className="align-self-center text-right">
                                    <span className="cursor-pointer" onClick={() => this.queryTableData('', true)}>
                                        Refresh <i className={`fas fa-sync ml-2 ${refreshing ? ' fa-spin' : ''}`} />
                                    </span>
                                </Col>
                            </Row>
                        </CardHeader>

                        <ToolkitProvider
                            data={failedToday}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <div className="table-not-fixed table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={paginationFactory({
                                            totalSize: hasFailedToday.length,
                                            page: this.state.page,
                                            sizePerPage: this.state.sizePerPage,
                                            alwaysShowAllBtns: true,
                                            showTotal: true,
                                            withFirstAndLast: true,
                                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                <div className="dataTables_length" id="datatable-basic_length">
                                                    <label>
                                                        Show{" "}
                                                        {
                                                        <select
                                                            value={currSizePerPage}
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm"
                                                            onChange={e => onSizePerPageChange(e.target.value)}
                                                        >
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        }{" "}
                                                        entries.
                                                    </label>
                                                </div>
                                            )
                                        })}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                        remote={false}
                                        onTableChange={this.onTableChange}

                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    </Card>
                ) : null}

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>
                            <Col xs={6} className="align-self-center">
                                <CardTitle className="mb-0 float-left">
                                    {'All Collections'}
                                </CardTitle>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <span className="cursor-pointer" onClick={() => this.queryTableData('', true)}>
                                    Refresh <i className={`fas fa-sync ml-2 ${refreshing ? ' fa-spin' : ''}`} />
                                </span>
                            </Col>
                        </Row>
                    </CardHeader>

                    {data ? (
                        data.length ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: true,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label>
                                                            Show{" "}
                                                            {
                                                            <select
                                                                value={currSizePerPage}
                                                                name="datatable-basic_length"
                                                                aria-controls="datatable-basic"
                                                                className="form-control form-control-sm"
                                                                onChange={e => onSizePerPageChange(e.target.value)}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            }{" "}
                                                            entries.
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}

                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0">
                                    <i className="fas fa-check mr-2 text-success font-weight-bold " />
                                    There are no contacts to be called for payment.
                                </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default Collections
