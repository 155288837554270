


import React from "react";
import { CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'
import renderName from 'utils/renderName'
import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment'

class ContactViewProfile extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    render() {

        const { contact } = this.props

        return (
            <>

                <CardHeader className=" bg-secondary">

                    <CardTitle className="mb-0 text-capitalize">
                        {renderName(contact)}
                    </CardTitle>
                </CardHeader>

                <CardBody>

                    <Row>
                        <Col xs={4}>
                            <h5>Assigned User</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="text-capitalize">
                                {contact.assigned_user ? (
                                    <span>
                                        <AvatarImage style={{
                                                width: 30,
                                                height: 30,
                                                position: 'absolute',
                                                top: -8,
                                                left: 3
                                        }} src={contact.assigned_user.avatar} />
                                        <span className="ml-5 font-weight-bold">
                                        {renderName(contact.assigned_user)}
                                        </span>

                                    </span>
                                 ) : 'None' }

                            </h5>
                        </Col>
                    </Row>

                    <hr className="my-2"/>


                    <Row>
                        <Col xs={4}>
                            <h5>Nickname</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal">
                                {contact.nickname ? contact.nickname : '-'}
                            </h5>
                        </Col>
                    </Row>

                    <hr className="my-2"/>

                    <Row>
                        <Col xs={4}>
                            <h5>DOB</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal">
                                {contact.dob ? moment(contact.dob).format('MMM Do, YYYY') : '-'}
                            </h5>
                        </Col>
                    </Row>


                    <hr className="my-2"/>

                    <Row>
                        <Col xs={4}>
                            <h5>Marital Status</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal text-capitalize">
                                {contact.marital_status ? contact.marital_status : '-'}
                            </h5>
                        </Col>
                    </Row>

                    <hr className="my-2"/>

                    <Row>
                        <Col xs={4}>
                            <h5>Employer</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal">
                                {contact.employer ? contact.employer : '-'}
                            </h5>
                        </Col>
                    </Row>

                    <hr className="my-2"/>

                    <Row>
                        <Col xs={4}>
                            <h5>Occupation</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal">
                                {contact.occupation ? contact.occupation : '-'}
                            </h5>
                        </Col>
                    </Row>

                </CardBody>

            </>
        );
    }
}

ContactViewProfile.propTypes = {
    contact: PropTypes.object.isRequired
}

export default ContactViewProfile
