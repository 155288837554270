/*
Documentation

This page is used to render popup notifications on the bottom right corner of the screen on the dashboard
On load it grabs user notification settings and inits notification listers

to add a notification type create a new file in the functions folder and call it from init.js

notifications should have the following properties

_id:         unique identifier of the notification
icon:        example -> 'fas fa-envelope',
title:       the headline of the notification
message:     message of the notification
created_at:  usually Math.floor(new Date() / 1000)

link:        optional to link on notification click. example -> '/dashboard/notifications',
color:       optional example 'success',


*/

import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import _user_notification_settings from '_functions/user_notification_settings'

import _initNotifications from './functions/init'

class Notifications extends Component {

    state = {
        notifications: []
    };

    // notifications is the added notifications, interval is time in milliseconds to remove the alert
    removeAtInterval = (notification_id, interval) => {

        setTimeout(() => { this.onRemove(notification_id) }, interval)

    }

    onRemove = (notification_id) => {



        let notifications = [...this.state.notifications]
        notifications = notifications.filter(n => n._id !== notification_id);

        this.setState({notifications})

    }

    fetchUserNotificationSettings = async () => {
        return new Promise (async resolve => {

            const user_notification_settings = await _user_notification_settings.find(`?filter=user__${this.props.viewing_user._id}`)

            const data = user_notification_settings.data;

            resolve(data[0] && data[0].subscribed_contacts ? data[0].subscribed_contacts : [])



        })
    }

    pushNotifications = (data, removeIn) => {

        const notifications = [...this.state.notifications]
        notifications.push(data)

        this.setState({notifications})

        this.removeAtInterval(data._id, removeIn ? removeIn : 5000)


    }


    componentWillUnmount = () => {

        _initNotifications.terminate()

    }

    componentDidMount = async () => {

        const userSubscribedContacts = await this.fetchUserNotificationSettings()

        _initNotifications.run(this.pushNotifications, this.props.viewing_user, userSubscribedContacts)

    }

    renderMarkup = (n) => (
        <>
            <CardHeader className="bg-transparent">
                <CardTitle className="mb-0 text-white"><i className={`${n.icon} mr-2`} />{n.title}</CardTitle>
            </CardHeader>
            <CardBody>
                <p className="bg-transparent text-sm text-white mb-0">{n.message}</p>
            </CardBody>

            <CardFooter className="bg-transparent text-right text-white text-sm">
                {moment.unix(n.created_at).format('MM/DD/YYYY - h:mm A')}
            </CardFooter>
        </>
    )

    renderBody = (n, i  ) => (
        <Card
            onClick={() => this.onRemove(n._id)} 
            id={n._id ? n._id : Math.floor(new Date() / 1)} 
            key={n._id ? n._id : Math.floor(new Date() / 1)}
            className={n.color ? `bg-gradient-${n.color} notification` : `notification`}
        >
            <span className="close" onClick={() => this.onRemove(n._id)} >
                <i className="fas fa-times" />
            </span>
            {n.link ? ( <Link to={n.link}>{this.renderMarkup(n)}</Link> ) : this.renderMarkup(n) }

        </Card>
    )

    render() {

        const notifications = this.state.notifications

        return (

            <div className="archk-notifications">

                {notifications && notifications.length ? notifications.map((n, i) => (
                    this.renderBody(n, i)
                )) : null }

            </div>

        )

    }

}


const mapStateToProps = state => {

    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Notifications);
