import apiWorker from '../apiWorker'

export default {

    create              : (data)                  => apiWorker('post',   `/api/v1/core/cases/create`, data),
    reAssignUser        : (data)                  => apiWorker('post',  `/api/v1/core/cases/reAssignUser`, data),
    assignUser          : (data)                  => apiWorker('patch',  `/api/v1/core/cases/assignUser`, data),
    removeUser          : (data)                  => apiWorker('delete', `/api/v1/core/cases/assignUser`, data),

    update              : (case_id, data)         => apiWorker('patch',  `/api/v1/core/cases/update/${case_id}`, data),
    updateTrust         : (case_id, data)         => apiWorker('patch',  `/api/v1/core/cases/updateTrust/${case_id}`, data, true),
    addMissingDoc       : (case_id, data)         => apiWorker('patch',  `/api/v1/core/cases/addMissingDoc/${case_id}`, data),
    updateRoles         : ( data)                 => apiWorker('patch',  `/api/v1/core/cases/updateRoles`, data),
    moveStep            : (case_id, data)         => apiWorker('patch', `/api/v1/core/cases/moveStep/${case_id}`, data),

    find                : (query, hideError)      => apiWorker('get',  `/api/v1/core/cases/find${query}`, null, hideError),
    findById            : (case_id, hideError)    => apiWorker('get',  `/api/v1/core/cases/find/${case_id}`, null,  hideError),
    findByIdWithStep    : (case_id, hideError)    => apiWorker('get',  `/api/v1/core/cases/find/${case_id}?queryCurrentStep=true`, null,  hideError),
    search              : (query)                 => apiWorker('post',  `/api/v1/core/cases/search`, query),

}
