/*
Documentation

*/

import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import { Card } from "reactstrap";
import { formatMoney } from 'utils/currency';
import renderAddress from 'utils/renderAddress';
import _cases from '_functions/cases';
import _contacts from '_functions/contacts';
import _trust_logs from '_functions/trust_logs';
import { formatPhone } from 'utils/text'
import renderName from 'utils/renderName'
import Wrapper from '../components/Wrapper'

class TrustHistory extends React.Component {

    state = {
        contactToDelete: null,
        showModal: false,
        payment: null,
        trust_logs: null
    }

    componentDidMount = async () => {

        const foundCase = await _cases.findById(this.props.match.params.case)
        if(!foundCase.data) return this.setState({error: 'Invalid Url'})

        const trust_logs = await _trust_logs.findByCase(this.props.match.params.case)
        if(!trust_logs.success) return this.setState({error: 'An Internal Error Occurred'})

        const foundContact = await _contacts.findById(foundCase.data.contact._id)
        if(!foundContact.success) return this.setState({error: 'An Internal Error Occurred'})

        this.setState({trust_logs: trust_logs.data, case: foundCase.data, contact: foundContact.data})

    }

    render() {

        const { trust_logs, contact, error } = this.state;
        const _case = this.state.case;

        if(!_case && !error) return <div className="py-6"><Circle /></div>
        if(error) return <div className="py-6 text-center"><h2>{error}</h2></div>

        return (

            <Wrapper
                canPrint={true}
                canSavePDF={true}
            >

                <p className="text-sm mb-3">{moment().format('MM/DD/YYYY')}</p>

                <h2 className="display-3 text-capitalize">{renderName(contact)}</h2>
                <h4 className="text-capitalize">Case: {_case.name} | ID {_case._id}</h4>

                <h5>
                    {contact.email ? contact.email : null}
                    { contact.phone ? (
                       contact.email ? <span><br />{formatPhone(contact.phone)}</span> : formatPhone(contact.phone)
                    ) : null}
                </h5>

                <h5> {renderAddress(contact)}</h5>
                <hr />

                <h4>Trust Account History <small>for case number: {_case._id}</small></h4>
                <h4 className="mb-4">Current Available Trust Balance: <small>{formatMoney(_case.billing_in_trust)}</small></h4>

                <Card>

                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Reason</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Balance</th>
                            </tr>
                        </thead>

                        <tbody>
                            {trust_logs && trust_logs.length ? (
                                trust_logs.map((log, i) => (
                                    <tr key={log._id}>
                                        <td style={{color: 'red'}}>{i + 1}</td>
                                        <td>{moment.unix(log.date).format('MM/DD/YYYY')}</td>
                                        <td>
                                            <div>Payment Method: {log.payment_method}</div>
                                            <div>Reason: {log.reason}</div>
                                            <div>{log.transaction_reference ? <span>Transaction Reference: {log.transaction_reference}</span> : null}</div>
                                            <div>{log.to_account_number ? <span>To Account Number: {log.to_account_number}</span> : null}</div>
                                            <div>
                                                {log.check_date   ? <span>Check Date: {moment.unix(log.check_date).format('MM/DD/YYYY')} </span> : null}
                                                {log.check_number ? <span>Number: {log.check_number} </span> : null}
                                                {log.check_from   ? <span>From: {log.check_from} </span> : null}
                                                {log.check_series ? <span>Series: {log.check_series} </span> : null}
                                                {log.check_memo   ? <span>Memo: {log.check_memo} </span> : null}
                                            </div>
                                            <div>
                                                {log.notes_amount   ? <span>Notes Amount: {log.notes_amount} </span> : null}
                                                {log.coins_amount   ? <span>Coins Amount: {log.coins_amount} </span> : null}
                                            </div>
                                            <div>
                                                {log.last_4   ? <span>Card Last 4: {log.last_4} </span> : null}
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            {log.amount > 0 ? '+' : null}
                                            {formatMoney(log.amount)}
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(log.balance)}
                                        </td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </table>

                </Card>

            </Wrapper>

        );
    }
}

export default TrustHistory
