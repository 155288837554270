/*
Documentation


 document            : { type: Schema.Types.ObjectId, ref: 'documents' },
    name                : { type: String, required: true },

    initial_pages       : { type: String, required: true },
    parties             : { type: Number, required: true },

    markers:,

*/

import React from 'react';
import { Modal } from 'reactstrap';

class ModalCreateTemplate extends React.Component {

    state = {
        parties: this.props.parties,
        warnings: [],
        errors: [],
    }

    partyHasMarker = (counter, markers) => {
        return markers.some(marker => marker.party === counter && marker.type === 'signature')
    }

    checkErrors = () => {

        const { markers, parties, docName } = this.props;
        let counter = 0;

        const warnings = []
        const errors = []

        while(counter !== parties) {

            counter++;

            const foundMarker = this.partyHasMarker(counter, markers)
            if(!foundMarker) warnings.push(`Party ${counter} does not have a signature within this document.`)

        }

        if(!docName) errors.push(`This document requires a name.`)

        this.setState({warnings, errors})

    }

    onConfirm = () => {

        this.props.onTemplate();
        this.props.toggleTemplateModal();

    }

    componentWillReceiveProps = (nextProps) => {
        if(!this.props.showTemplateModal && nextProps.showTemplateModal) {
            this.checkErrors()  
        }
    }


    
    render() {

        const { showTemplateModal, toggleTemplateModal, parties, originalDoc } = this.props
        const { warnings, errors } = this.state;

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showTemplateModal}
                toggle={toggleTemplateModal}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{originalDoc ? 'Update' : 'Create'} Template</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleTemplateModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <p className="text-sm mb-0">This document can be signed by up to {parties} {parties === 1 ? 'party' : 'parties'}</p>
                    {warnings.length ? (
                        <div>
                            <h3 className="mb-0 mt-3">Warnings</h3> 
                            {warnings.map((warning, i) => (
                               <p key={i} className="text-warning font-weight-bold mb-0">{warning}</p>
                            ))}
                        </div>
                    ) : null}
                    {errors.length ? (
                        <div>
                            <h3 className="mb-0 mt-3">Errors</h3> 
                            {errors.map((error, i) => (
                               <p key={i} className="text-danger font-weight-bold mb-0">{error}</p>
                            ))}
                        </div>
                    ) : null}
                </div>

                <div className="modal-footer">
                    {errors.length ? (
                        <button onClick={toggleTemplateModal} className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2 " />Back
                        </button>
                    ) : (
                        <button onClick={this.onConfirm} className="btn btn-outline-success">
                            <i className="fas fa-save mr-2 " /> Save
                        </button>
                    )}
                </div>

            </Modal>

        );
    }
}

ModalCreateTemplate.propTypes = {

}

export default ModalCreateTemplate
