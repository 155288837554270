/*
Documentation

create a thumbnail for a pdf doc type

*/

import PropTypes from 'prop-types';
import React from 'react';


class PdfThumbnail extends React.Component {

    componentDidMount = () => {

        const { doc, docID, onLoadedDocument } = this.props

        // get the canvas we are trying to create the thumbnail for
        const canvas = document.getElementById(docID)
        var ctx      = canvas.getContext('2d');

        doc.pdfDoc.getPage(doc.page).then(function(page) {

            var viewport = page.getViewport({scale: page.getViewport({scale: canvas.width / page.getViewport({scale: 1}).width}).scale});

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderTask = page.render({ canvasContext: ctx, viewport });

            // Wait for rendering to finish
            renderTask.promise.then(function() {
                if(onLoadedDocument) onLoadedDocument()
            });
        });

    }

    render() {

        const { rotation, docID, width } = this.props

        return (
            <div className="position-relative">
                <canvas className="className" id={docID} width={width ? width : 600} style={{maxWidth: '100%', transform: `rotate(${rotation ? rotation : 0}deg)`}} />
            </div>
        )

    }
}

PdfThumbnail.propTypes = {
    doc                 : PropTypes.object.isRequired,
    docID               : PropTypes.string.isRequired,
    onLoadedDocument    : PropTypes.func,
}

export default PdfThumbnail
