import PropTypes from 'prop-types';
import React, { Component } from 'react';

import renderViewMonth from '../functions/ViewMonth/renderViewMonth'

class ViewMonth extends Component {

    state = {
        inView: false,
        renderedResize: 0
    };

    resizeInterval = null

    onResize = () => {

        if(this.props.showView === 'month') {
            this.setState({renderedResize: Math.floor(new Date() / 1000)})
        }
    }

    setResizeInterval = () => {

        this.resizeInterval = setInterval(() => {

            const calendars = document.querySelectorAll('.archk-calendar')

            // calendar is in view
            if(calendars.length) {

                // if calendar is not in view but is found in dom fire the resize
                if(!this.state.inView) {
                    this.onResize()
                    this.setState({inView: true})
                }

            // no calendar in view
            } else {

                // set time to re run interval if the calendar is out of view and it is currently set to show
                if(!this.state.inView) this.setState({inView: true})

            }

        }, 300)

    }

    componentDidMount = () => {
        window.addEventListener('resize', this.onResize)
        this.setResizeInterval()
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onResize)
        clearInterval(this.resizeInterval)
    }

    render() {

        const {events, day_to_show, showView} = this.props

        if(showView !== 'month') return null

        return (

            <div className="table-responsive">

                <table className="table view-month" style={{tableLayout: 'fixed'}}>

                    <thead>
                        <tr>
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                        </tr>
                    </thead>

                    <tbody key={this.state.renderedResize}>
                        {renderViewMonth(day_to_show, events, this, this.state.renderedResize).markup}
                    </tbody>

                </table>

            </div>

        )

    }

}

ViewMonth.propTypes = {

    // this is the start of the day we wish to show in the header | also control moving forward and backwards
   day_to_show: PropTypes.number.isRequired,

   //what type of view to render
   showView: PropTypes.oneOf(['day', 'week', 'month']).isRequired,

    //an combined array of case_workflow_tasks and appointments to be rendered on the calendar
    events: PropTypes.array.isRequired,

    onViewMonthCellClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),

}

export default ViewMonth;
