import React, { Component } from 'react';
import { Col, Modal, Row } from 'reactstrap';
import Actions from './Actions';
import CoreInformation from './CoreInformation';
import Credits from './Credits';
import Discounts from './Discounts';
import Expenses from './Expenses';
import Fees from './Fees';
import InvoiceDetails from './InvoiceDetails';
import Navigation from './Navigation';
import Preview from './Preview';
import WriteOffs from './WriteOffs';
import Refunds from './Refunds';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const getEmptyInvoice = () => {
    return {
        expenses:   [],
        fees:       [],
        discounts:  [],
        credits:    [],
        write_offs: [],
        total: 0,
        total_paid: 0
    }
}

class ViewInvoice extends Component {

    state = {
        showModal: false,
        canSendInvoice: false,
        invoice: getEmptyInvoice(),
        navigation: 1
    };

    toggleModal = (canSendInvoice) => this.setState({showModal: !this.state.showModal, canSendInvoice})

    toggleShowInvoice = () => {

        this.setState({invoice: getEmptyInvoice(), navigation: 1})
        this.props.toggle()

    }

    setNavigation = (navigation) => this.setState({navigation})

    onInputChange = (f,v) => {

        let invoice = Object.assign({}, this.state.invoice);
        invoice[f] = v

        this.setState({invoice})

    }

    onRemoveInvoiceItem = (field, id) => {

        let invoice = Object.assign({}, this.state.invoice);
        let objs = invoice[field]

        objs = objs.filter(f => {

            let toCheck = f._id ? f._id : f.id
            return toCheck !== id

        })
        invoice[field] = objs

        this.setState({invoice})

    }

    onAddInvoiceItem = (field, fee) => {
        let invoice = Object.assign({}, this.state.invoice);
        invoice[field].push(fee)

        this.setState({invoice})
    }

    onInvoiceSent = (sent_invoice) => {

        this.props.toggle();
        this.props.onInvoiceSent(sent_invoice)

    }

    componentWillReceiveProps = (nextProps) => {

        const invoiceToShow =  nextProps.invoiceToShow
        const invoice = this.state.invoice

        //if in invoice is passed in set the state to show this invoice
        if(invoiceToShow && invoiceToShow._id !== invoice._id) {

            this.setState({invoice: invoiceToShow})

        //if we have a current invoice and we pass in null that means we are creating a new invoice
        } else if(invoice && !invoiceToShow) {

            this.setState({invoice: getEmptyInvoice()})

        }

    }

    listenForUpdatedInvoices = (data) => {

        const invoice = data.data;
        if(invoice._id === this.state.invoice._id) this.setState({invoice})

    }

    componentDidMount = () => {
        io.on(socketEvents.invoices_updated,    this.listenForUpdatedInvoices)
    }

    componentWillUnmount = () => {
        io.off(socketEvents.invoices_updated,   this.listenForUpdatedInvoices)
    }

    render() {

        const { invoice, navigation } = this.state
        const { showModal }= this.props
        const toggleShowInvoice = this.toggleShowInvoice
        const _case = this.props.case

        return (

            <div>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={showModal}
                    size="lg"
                    fade={false}
                    id="modal-invoice"
                    zIndex={1500}
                >

                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Invoice</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleShowInvoice()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Row className="mt-3 mb-3">

                            <Col lg={3}>

                                <InvoiceDetails
                                    invoice={invoice}
                                    toggleModal={(canSendInvoice) => this.toggleModal(canSendInvoice)}
                                />

                            </Col>

                            <Col lg={9}>

                                <Navigation
                                    navigation={navigation}
                                    setNavigation={(navigation) => this.setNavigation(navigation)}
                                />

                                <Preview
                                    showModal={this.state.showModal}
                                    canSendInvoice={this.state.canSendInvoice}
                                    toggle={this.toggleModal}
                                    onInvoiceSent={(sent_invoice) => this.onInvoiceSent(sent_invoice)}
                                    invoice={invoice}
                                    case={_case}
                                />

                                {navigation === 1 && (
                                    <CoreInformation
                                        invoice={invoice}
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}
                                {navigation === 2 && (
                                    <Fees
                                        invoice={invoice}
                                        onAddInvoiceItem={(fee) => this.onAddInvoiceItem('fees', fee)}
                                        onRemoveInvoiceItem={(fee_id) => this.onRemoveInvoiceItem('fees', fee_id)}
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}
                                {navigation === 3 && (
                                    <Expenses
                                        invoice={invoice}
                                        onAddInvoiceItem={(expense) => this.onAddInvoiceItem('expenses', expense)}
                                        onRemoveInvoiceItem={(expense_id) => this.onRemoveInvoiceItem('expenses', expense_id)}
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}

                                {navigation === 4 && (
                                    <Credits
                                        invoice={invoice}
                                        onAddInvoiceItem={
                                            (expense) => this.onAddInvoiceItem('credits', expense)
                                        }
                                        onRemoveInvoiceItem={
                                            (expense_id) => this.onRemoveInvoiceItem('credits', expense_id)
                                        }
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}

                                {navigation === 5 && (
                                    <Discounts
                                        invoice={invoice}
                                        onAddInvoiceItem={
                                            (expense) => this.onAddInvoiceItem('discounts', expense)
                                        }
                                        onRemoveInvoiceItem={
                                            (expense_id) => this.onRemoveInvoiceItem('discounts', expense_id)
                                        }
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}

                                {navigation === 6 && (
                                    <WriteOffs
                                        invoice={invoice}
                                        onAddInvoiceItem={
                                            (expense) => this.onAddInvoiceItem('write_offs', expense)
                                        }
                                        onRemoveInvoiceItem={
                                            (expense_id) => this.onRemoveInvoiceItem('write_offs', expense_id)
                                        }
                                        onChange={(f, v) => this.onInputChange(f, v)}
                                    />
                                )}
                                {navigation === 7 && (
                                    <Refunds
                                        invoice={invoice}
                                    />
                                )}

                                <Actions
                                    invoice={invoice}
                                    case={_case}
                                    toggle={() => toggleShowInvoice()}
                                    setCreatedInvoice={(invoice) => this.props.setCreatedInvoice(invoice)}
                                />

                            </Col>

                        </Row>

                    </div>
                </Modal>
            </div>
        )

    }

}

export default ViewInvoice;
