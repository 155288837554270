/*
Documentation

this function takes a case_workflow (populated with steps and tasks) and returns all finished tasks as an array

*/

export default (case_workflow) => {

    let logs = [];

    if(case_workflow && case_workflow.steps && case_workflow.steps.length) {

        case_workflow.steps.forEach(step => {

            if(step.tasks && step.tasks.length) {

                step.tasks.forEach(task => {

                    if(task.finished_by) {

                        logs.push({
                            ...task,
                            task_profit: task.labor_billable - task.labor_cost
                        })
                    }



                })

            }

        })

    }

    //sort by newest finished task first
    logs = logs.sort((a, b) => a.finished < b.finished ? true : false)

    return logs

}
