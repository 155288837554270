import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import _template_docs_sent from '_functions/template_docs_sent';
import _template_docs_sent_events from '_functions/template_docs_sent_events';
import keys from 'keys';
import renderName from 'utils/renderName';

import Circle from 'components/markup/loading/Circle'
import A from 'components/markup/links/A'

import moment from 'moment';

const ModalVoidDocument = ({showModal, toggleModal, selectedDocToVoid, onSuccess, type}) => {

    const [events, setEvents] = useState(null)

    const onVoidDocument = useCallback(async () => {

        toggleStandardLoader(true)

        const voided = await _template_docs_sent.update(selectedDocToVoid._id, { status: 'void'})
        if(voided.success) onSuccess(voided.data)

        toggleModal()
        toggleStandardLoader(false)

    }, [selectedDocToVoid, onSuccess])

    const fetchEvents = useCallback(async (_selectedDocToVoid) => {
        let promiseArr = [];

        _selectedDocToVoid.parties.forEach(p => {
            promiseArr.push(_template_docs_sent_events.find(p.contact ? p.contact._id : null, _selectedDocToVoid._id))
        })

        const values = await Promise.all(promiseArr);
        let _events = {};

        values.forEach(v => {
            if(v.data && v.data.length) {
                _events[v.data[0].contact] = v.data
            }
        })

        setEvents(_events)

    }, [])

    useEffect(() => {
        if(selectedDocToVoid && selectedDocToVoid.parties) {
            fetchEvents(selectedDocToVoid)
        } else {
            setEvents(null)
        }
    }, [selectedDocToVoid, fetchEvents])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            {selectedDocToVoid ? (
                <>
                    <div className="modal-header">
                        <h5 className="modal-title" >Void Document</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body bg-secondary">
                        <p className="text-sm font-weight-bold mb-0">
                            Document: {' '}
                            <span className="text-underline text-dark">
                                {selectedDocToVoid.name ? selectedDocToVoid.name : selectedDocToVoid.template_doc ?
                                    selectedDocToVoid.template_doc.name : type === 'pdf' ?
                                    'PDF Document' : 'Document Not Found'}
                            </span>
                        </p>
                        {selectedDocToVoid.status === 'sent' ? (
                            <div>
                                <p className="text-sm mb-0 mt-3">Are you sure you wish to void this document?</p>
                                <p className="text-sm">The contact will no longer be able to sign it.</p>
                            </div>
                        ) : null}
                       
                    </div>

                    <div className="modal-body bg-secondary border-top">
                        <h4>Signing Links</h4>

                        {selectedDocToVoid.parties && selectedDocToVoid.parties.map((party, i) => {
                            const link = `${keys.CLIENT_URL}/actions/signatures/pdf/sign/${selectedDocToVoid._id}?token=${party.access_code}`;
                            return (
                                !party.contact ? '' : 
                                <Card  key={i}>
                                    <CardHeader>
                                        <CardTitle className="mb-0 text-capitalize">{renderName(party.contact)}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{maxHeight: 250, overflow: 'auto'}}>
                                            <h4 className="mb-0 text-capitalize text-dark font-weight-bold">Link</h4>
                                            <p className="text-sm mb-2" style={{wordBreak: 'break-all'}}>
                                                <A href={link}><i className="fas fa-external-link-alt text-info-original mr-2 " /> {link} </A>
                                            </p>
    
                                            <h4 className="mb-0 text-capitalize text-dark font-weight-bold">Events</h4>
                                            {!events ? <Circle /> : !events[party.contact._id] ? (
                                                <p className="text-sm mb-0">No signing events have been found for this party</p>
                                            ) : !events[party.contact._id].length ? (
                                                <p className="text-sm mb-0">No signing events have been found for this party</p>
                                            ) : events[party.contact._id].map(e => (
                                                <div key={e._id}>
                                                    <p className="text-sm mb-0">{moment.unix(e.created_at).format('MMM Do, YYYY h:mm A')} - IP: {e.ip}</p>
                                                    <p className="text-sm ml-4 mb-2">- {e.event}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            )
                        } )}
                    </div>

                    <div className="modal-footer">
                        {selectedDocToVoid.status === 'sent' ? (
                            <button className="btn btn-outline-danger" onClick={onVoidDocument}><i className="fas fa-exclamation-triangle mr-2" /> Void Document</button>
                        ) : null}
                        <button className="btn btn-outline-success" onClick={() => toggleModal()}>Close</button>
                    </div>
                </>
            ) : null}



        </Modal>
    )

}

ModalVoidDocument.propTypes = {
    toggleModal         : PropTypes.func.isRequired,
    showModal           : PropTypes.bool.isRequired,
    selectedDocToVoid   : PropTypes.object,
    type                : PropTypes.string,
}

export default ModalVoidDocument