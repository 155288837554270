import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class AppointmentsCrudHearingLocationTrustee extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.location_hearing_trustee) return true
            if(appointment.location_hearing_trustee && !appointment.location_hearing_trustee._id) return true;

        }

        return false

    }

    render() {

        const { appointment, locationHearingTrusteeRequired, onAssignLocationHearingTrustee, onRemoveLocationHearingTrustee } = this.props

        if(!locationHearingTrusteeRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign Trustee</h4>
                        <ContactSearchInput
                            hideTitle={true}
                            value={{}}
                            onSelect={(locationHearingTrustee) => onAssignLocationHearingTrustee(locationHearingTrustee)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a trustee before proceeding.</div>
                    ) : null}
                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Location Hearing Trustee</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {appointment.location_hearing_trustee && appointment.location_hearing_trustee._id ? (
                                <tr>
                                    <td>
                                        <div className="text-capitalize">
                                            {renderName(appointment.location_hearing_trustee)}
                                            {appointment.location_hearing_trustee.phone ? (
                                                <div>{formatPhone(appointment.location_hearing_trustee.phone)}</div>
                                            ) : null}
                                        </div>
                                        {appointment.location_hearing_trustee.email ? (
                                            <div>{appointment.location_hearing_trustee.email}</div>
                                        ) : null}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveLocationHearingTrustee()} />
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Location Hearing Trustee Assigned</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudHearingLocationTrustee.propTypes = {
    locationHearingTrusteeRequired    : PropTypes.func.isRequired,
    onAssignLocationHearingTrustee    : PropTypes.func.isRequired,
    onRemoveLocationHearingTrustee    : PropTypes.func.isRequired,
    appointment                       : PropTypes.object.isRequired,
}

export default AppointmentsCrudHearingLocationTrustee
