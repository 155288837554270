import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _users from '_functions/users';

class AccountSettings extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    onUpload = async (file, call) => {

        toggleStandardLoader(true)

        const updated = await _users.update(this.props.viewing_user._id, {avatar: call.data._id})

        if(updated.success) {
            toggleAlertBS(false, `Your profile has been updated successfully.`)
            setViewingUser()
        }

        toggleStandardLoader(false)

    }

    render() {

        const { user } = this.state

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Profile Picture</CardTitle>
                </CardHeader>

                <CardBody>

                    <p className="text-sm">Drag in a picture here to change the avatar associated with your account.</p>

                    <DragAndDrop
                        zone_id="documents_1"
                        url={`/api/v1/core/documents/create`}
                        onError={(e) => console.log(e)}
                        resizePixels={75}
                        onUpload={this.onUpload}
                        defaultValue="Profile Picture" 
                        extraData={{bucket: `/users/${user._id}`}}
                    />

                </CardBody>

            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);
