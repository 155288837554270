/*
Documentation

this file handles tasks that need to associate contacts

*/

import React, { Component } from 'react';
import ContactsCrud from 'views/dashboard/pages/contacts/components/ContactsCrud';
import _case_workflow_tasks from '_functions/case_workflow_tasks';
// import renderName from 'utils/renderName';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import { FormGroup, Input } from 'reactstrap'

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class FormTypesContact extends Component {


    state = {
        show: 'associate', // associated / create
        contact: null
    };

    onFinishTask = async (contact) => {

        const editing_task = this.props.editing_task;
        const viewing_user = this.props.viewing_user;

        toggleStandardLoader(true)

        await _case_workflow_tasks.finish(editing_task._id,  {
            case_workflow: this.props.case_workflow_id,
            value: contact._id,
            minutes: editing_task.minutes ? parseInt(editing_task.minutes) : editing_task.minutes,
            finished_by: viewing_user._id,
            user: viewing_user._id
        })

        toggleStandardLoader(false)

        this.props.toggleModal();
        this.props.forceRefresh();
        _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

    }

    render() {
        const { editing_task } = this.props;
        const { show, contact } = this.state

        if(editing_task.type !== 'set location hearing trustee') { return <></> }

        return (

            <div>
                <p className="text-sm mb-0 font-weight-bold">Assign Party: {editing_task.name}</p>

                <FormGroup>
                    <Input
                        type="select"
                        value={show}
                        onChange={(e) => this.setState({show: e.target.value})}
                    >
                        <option value="associate">Associate Contact</option>
                        <option value="create">Create Contact</option>
                    </Input>
                </FormGroup>

                <hr className="my-3" />

                {show === 'associate' ? (

                    <>

                    <ContactSearchInput
                        value={contact || {}}
                        onSelect={(contact) => this.setState({contact})}
                    />

                    {contact && contact._id ? (
                        <div className="text-right">
                            <button onClick={() => this.onFinishTask(contact)} className="btn btn-success">Finish Task</button>
                        </div>
                    ) : null}

                    </>
                ) : (
                    <ContactsCrud
                        onCreate={this.onFinishTask}
                    />
                )}

            </div>

        )

    }

}


FormTypesContact.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,

}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesContact);
