import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import _invoices from '_functions/invoices';

const required_form_fields = [
    'amount',
    'description',
    'quantity'
]

class Expenses extends Component {

    state = {
        expense: {},
    };

    onInputChange = (f,v) => {

        let expense = Object.assign({}, this.state.expense);
        expense[f] = v

        this.setState({expense})

    }

    inputsAreValid = () => {

        let errors = 0;
        let newState = Object.assign({}, this.state.expense);

        this.setState({quantityError: false})

        required_form_fields.forEach((field) => {

            if(field === 'amount' && newState[field] === '0') {
                newState[field + "State"] = 'invalid';
                errors++
            } else if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        // validate decimals
        if(!_payments.validate.decimals(newState.amount)) {
            newState["amountState"] = 'invalid';
            errors++
        }

        // validate we have an actual number
        if(isNaN(parseFloat(newState.amount))) {
            newState["amountState"] = 'invalid';
            errors++
        }

        if(!newState.quantity || newState.quantity < 1) {
            this.setState({quantityError: true})

            newState["quantityState"] = 'invalid';
            errors++
        }

        this.setState({expense: newState})
        return errors ? false : true

    }

    onAddInvoiceItem = () => {

        // validate all required fields are filled out
        if(!this.inputsAreValid()) return;

        let expense = Object.assign({}, this.state.expense);

        //add a mock id for reference to this specific item (user id + unix timestamp)
        expense.id = this.props.viewing_user._id + '_' + Math.floor(new Date() / 1000)

        //set the total and make sure all values are true numbers not strings
        expense.amount = parseFloat(expense.amount)
        expense.quantity = parseInt(expense.quantity)
        expense.total = _invoices.calculate.totalExpenses(expense)

        this.props.onAddInvoiceItem(expense);
        this.setState({expense: {}})

    }

    onRemoveInvoiceItem = (expense_id) => {

        this.props.onRemoveInvoiceItem(expense_id);

    }

    render() {

        const { expense, quantityError } = this.state
        const { invoice } = this.props

        const expenses = invoice.expenses

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Current Expense Items</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table  className="table">

                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>

                            {expenses && expenses.length ? expenses.map(f => (
                                <tr key={f._id ? f._id : f.id}>
                                    <td style={{wordBreak: 'break-word'}}>{f.description}</td>
                                    <td>{formatMoney(f.amount)}</td>
                                    <td>{f.quantity}</td>
                                    <td>{formatMoney(f.total)}</td>
                                    <td className="text-right">
                                        <i

                                            onClick={() => this.onRemoveInvoiceItem(f._id ? f._id : f.id)}
                                            className="fas fa-trash text-danger mr-2 cursor-pointer"
                                        />

                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td style={{wordBreak: 'break-word'}}>No expenses have been created yet.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>

                    </table>
                </div>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Add Expense</CardTitle>
                </CardHeader>

                <CardBody>

                    <Row>

                        <Col md={6}>
                            <StandardFormGroup
                                obj={expense}
                                objName="expense"
                                onChange={(o, f, v) => this.onInputChange(f, v)}
                                type="number"
                                field="amount"
                                title="Amount"
                            />
                        </Col>
                        <Col md={6}>
                            <StandardFormGroup
                                obj={expense}
                                objName="expense"
                                onChange={(o, f, v) => this.onInputChange(f, v)}
                                type="number"
                                field="quantity"
                                title="Quantity"
                            />

                            {quantityError ? <p className="text-sm text-danger font-weight-bold">Quantity must be 1 or greater.</p> : null}
                        </Col>

                    </Row>

                    <StandardFormGroup
                        obj={expense}
                        objName="expense"
                        onChange={(o, f, v) => this.onInputChange(f, v)}
                        type="textarea"
                        field="description"
                        title="Expense Description"
                    />

                    <div className="text-right">
                        <button className="btn btn-success" onClick={this.onAddInvoiceItem}>Add Expense</button>
                    </div>

                </CardBody>

            </Card>

            </>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Expenses);
