export const APPOINTMENT_STEP_CHANGE            = 'APPOINTMENT_STEP_CHANGE';
export const APPOINTMENT_MANUAL                 = 'APPOINTMENT_MANUAL';

export const CASES_VIEWED_UNIQUE_BY_VALUE       = 'CASES_VIEWED_UNIQUE_BY_VALUE';
export const CASES_CREATED                      = 'CASES_CREATED';

export const CONTACTS_VIEWED_UNIQUE_BY_VALUE    = 'CONTACTS_VIEWED_UNIQUE_BY_VALUE';
export const CONTACTS_CREATED                   = 'CONTACTS_CREATED';

export const DIAL_MANUAL                        = 'DIAL_MANUAL';
export const DIAL_QUEUE                         = 'DIAL_QUEUE';
export const DIAL_IN_PERSON                     = 'DIAL_IN_PERSON';

export const EMAILS_SENT                        = 'EMAILS_SENT';
export const EMAILS_REPLIED                     = 'EMAILS_REPLIED';

export const DOCUMENTS_UPLOADED                 = 'DOCUMENTS_UPLOADED';
export const DOCUMENTS_SENT                     = 'DOCUMENTS_SENT';

export const NOTES_CREATED                      = 'NOTES_CREATED';

export const STEPS_MOVED                        = 'STEPS_MOVED';
export const STEPS_JUMPED                       = 'STEPS_JUMPED';

export const TASK_FINISHED                      = 'TASK_FINISHED';

export const TEXTS_SENT                         = 'TEXTS_SENT';
export const TEXTS_REPLIED                      = 'TEXTS_REPLIED';
