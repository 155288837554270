import apiWorker from '../apiWorker'

export default {

    search: (data)        =>  apiWorker('post',  `/api/v1/call_center/call_queue/search`, data),
    searchNew: ()         =>  apiWorker('post',  `/api/v1/call_center/call_queue/searchNew` ),
    update: (_id, data)   =>  apiWorker('patch',  `/api/v1/call_center/call_queue/update/${_id}`, data),
    delete: (_id)         =>  apiWorker('delete',  `/api/v1/call_center/call_queue/delete/${_id}`),

}
