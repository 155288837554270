 /*

Simulate a click event.
@param id {string} id  the element to simulate a click on

*/
export const simulateClickOfId = function (id) {

	const elem = document.getElementById(id)

	if(elem) {

			// Create our event (with options)
		var evt = new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window
		});
		// If cancelled, don't dispatch our event
		elem.dispatchEvent(evt);

	}

};

/*
Documentation

this function tries to focus an element by id;
console logs the error if it fails

*/
export const focusElement = (id) => {

	try {
		document.getElementById(id).focus();
	} catch(e) {
		console.log(e)
	
	}

}
