import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup, Input } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';


class ModalBulkUpdate extends React.Component {

    state = {
        documentFolder      : null,
        folderSelectOptions : [],
        confirmDelete       : '',
    }

    onInputChange = (index, answer) => {

        const fields = [...this.state.fields];
        fields[index].answer = answer;

        this.setState({fields})

    }

    onGenerate = () => {

        const fields = [...this.state.fields];
        let errors = 0;

        fields.forEach(field => {
            if(!field.answer && field.required) errors++
        })

        if(errors) return toggleAlertBS(true, `You must fill out all required field for this document before generating it.`);

        this.props.onConfirmGeneration(fields)

    }

    componentDidUpdate = (prevProps) => {

        if(!prevProps.showModal && this.props.showModal) {
            this.setState({fields: [...this.props.fields]})
        }

    }

    render() {

        let { showModal, toggleModal, errors, loading } = this.props
        let { fields } = this.state

        if(!errors) errors = [];
        if(!fields) fields = [];


        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
                id="modal-update-document"
            >
                <div className="modal-header">
                    <h5 className="modal-title" >Generate Document</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {errors.length ? (
                    <div className="modal-body border-top bg-secondary">
                        <p className="text-sm mb-0">The following fields were not able to be populated on the document.</p>
                        <p className="text-sm">Add in any required fields bellow</p>
                        {errors.map((err, index) => (
                            <p key={index} className="mb-0 text-sm font-weight-bold text-danger"><i className="fas fa-exclamation-triangle " /> {err}</p>
                        ))}
                    </div>
                ) : null}

                <div className="modal-body border-top">
                    {fields.map((field, index) => {

                        const valid = field.answer ? true : false;
                        const invalid = field.required && !field.answer;

                        return (
                            <FormGroup key={index} >
                                
                                <label className="form-control-label">
                                    <span className={invalid ? 'text-danger' : ''} >{field.text} {field.required ? '*' : ''}</span>
                                </label>
                                <Input 
                                    type="text"
                                    value={field.answer || ''}
                                    invalid={invalid}
                                    valid={valid}
                                    onChange={(e) => this.onInputChange(index, e.target.value)}
                                />
                            </FormGroup>
                        )
                    })}
                </div>

                <div className="modal-footer">
                    <button disabled={loading} onClick={this.onGenerate} className="btn btn-success">
                        <i className="fas fa-download mr-2" /> Download Document
                    </button>
                </div>

            </Modal>

        );
    }
}

ModalBulkUpdate.propTypes = {
    showModal             : PropTypes.bool.isRequired,
    toggleModal           : PropTypes.func.isRequired,
    onConfirmGeneration   : PropTypes.func.isRequired,
    fields                : PropTypes.array,
    errors                : PropTypes.array,
}

export default ModalBulkUpdate
