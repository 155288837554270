/*
Documentation

this file allows a user to update missing document

*/

import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import ReactQuill from "react-quill";

import _contact_notes from '_functions/contact_notes';

class ModalEditNote extends React.Component {

    state = {
        note : {},
        reactQuillText: '',
    }

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            note: { ...this.state.note, value }
        });
    };

    onUpdateNote = async () => {

        this.props.toggleModal();

        _contact_notes.update(this.props.note._id, {
            value: this.state.reactQuillText,
            user: this.props.viewing_user._id
        })

    }

    onDeleteNote = async () => {

        this.props.toggleModal();

        _contact_notes.delete(this.props.note._id, {
            user: this.props.viewing_user._id
        }, true)

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.showModal && !this.props.showModal) {

            const note = Object.assign(nextProps.note);
            this.setState({note, reactQuillText: note.value})

            setTimeout(() => {
                const input = document.querySelector('#archk-edit-notes-quill .ql-editor');
                if(input) input.focus();
            }, 500)


        } else if(!nextProps.showModal && this.props.showModal) {

            this.setState({note: {}, reactQuillText: ''})

        }

    }

    componentDidMount = () => {


    }

    render() {

        const { showModal, toggleModal } = this.props
        const { reactQuillText } = this.state;

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => {}}
                size="lg"
                id="archk-edit-notes-quill"
            >
                <div className="modal-header">
                    <h5 className="modal-title" >Edit Note</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                 <div className="modal-body border-top">
                    <div className="react-quill-toolbar-hide">
                        <ReactQuill
                            value={reactQuillText}
                            onChange={this.handleReactQuillChange}
                            placeholder="My Note..."
                            theme="snow"
                            onPaste={() => console.log('you pasted something')}
                            modules={{
                                toolbar: []
                            }}
                        />
                    </div>
                </div>

                <div className="modal-footer d-block">
                    <Row>
                        <Col xs={6}>
                            <button onClick={this.onDeleteNote} className="btn btn-outline-danger float-left">
                                <i className="fas fa-save mr-2" /> Delete Note
                            </button>
                        </Col>
                        
                        <Col xs={6} className="text-right">
                            <button onClick={this.onUpdateNote} className="btn btn-success ">
                                <i className="fas fa-save mr-2" /> Save Note
                            </button>
                        </Col>
                    </Row>
                </div>

            </Modal>

        );
    }
}

ModalEditNote.propTypes = {
    showModal         : PropTypes.bool.isRequired,
    toggleModal       : PropTypes.func.isRequired,
    note       : PropTypes.object.isRequired,

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalEditNote);
