import apiWorker from '../apiWorker'


export default {

    create: (data)                      => apiWorker('post',   `/api/v1/core/objections/create/`, data),
    update: (objection_id, data)        => apiWorker('patch',  `/api/v1/core/objections/update/${objection_id}`, data),
    delete: (objection_id)              => apiWorker('delete', `/api/v1/core/objections/delete/${objection_id}`),

    notesCreate: (objection_id, data)   => apiWorker('post', `/api/v1/core/objections/notes_create/${objection_id}`, data),


    find: (query)                       => apiWorker('get', `/api/v1/core/objections/find${query}`),
    findById: (objection_id)            => apiWorker('get', `/api/v1/core/objections/find/${objection_id}`),

}
