/*
Documentation

this page allows an unmoved case to be jumped to any step
within its workflow

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import { toggleStandardLoader } from 'store/functions/system/system';
import _case_workflows from '_functions/case_workflows';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import TasksCompleteBeforeMove from './TasksCompleteBeforeMove';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

class History extends Component {

    state = {
        complete_before_move: [],
        options: [],
        movingToStep: null,
        showModal: false
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})


    onConfirmMove = () => {

        const { movingToStep } = this.state;

        if(!movingToStep) return

        this.toggleModal()


    }

    onForceMove = async () => {

        toggleStandardLoader(true)

        await  _case_workflows.moveStep({
            case: this.props.case_workflow.case,
            user: this.props.viewing_user._id,
            next_step: this.state.movingToStep.value,
            finished: false,
            hideMissingDocsMessage: false,
            forceChange: true
        })

        _analytics.events.create({event: ANALYTIC_EVENTS.STEPS_JUMPED});

        toggleStandardLoader(false)

    }

    onSelectStep = async (values) => {

        
        const complete_before_move = await _case_workflows.getStepsOnMove(values.value)
        if(complete_before_move) this.setState({complete_before_move: complete_before_move.data})

        this.setState({movingToStep: values})


    }

    listenForCaseWorkflowTasksUpdated = (data) => {

        let complete_before_move = [...this.state.complete_before_move]

        if(complete_before_move.length && data.data.finished) {

            complete_before_move = complete_before_move.filter(c => c._id !== data.data._id)
            this.setState({complete_before_move})

        }
    }

    componentWillUnmount = () => {
        io.off(socketEvents.case_workflow_tasks_updated,  this.listenForCaseWorkflowTasksUpdated)
    }

    // when this component loads we check to see if we do not have any
    // case history. if we don't we then load all steps for the case and 
    // allow the user to jump to anyone of their choice
    componentDidMount = async () => {

        let options = []

        const allSteps = await _case_workflows.stepsByCase(this.props.case_workflow.case)

        if(allSteps.data) {
            allSteps.data.forEach(step => {
                options.push({value: step._id, label: step.name})
            })
    
            this.setState({options})
        }
           
        io.on(socketEvents.case_workflow_tasks_updated,  this.listenForCaseWorkflowTasksUpdated)

    }



    render() {

        // const { case_workflow } = this.props

        // if we have a history do not let users jump elsewhere
        // if(case_workflow.history && case_workflow.history.length > 2) return <div></div>

        const { options, showModal, movingToStep, complete_before_move } = this.state
        const { forceRefresh } = this.props;

        return (

            <>

                <Card>

                    <CardHeader className="bg-transparent border-bottom">
                        <CardTitle className="mb-0">
                            <i className="fas fa-exclamation mr-2 text-warning " /> 
                            Jump Case Steps
                        </CardTitle>
                    </CardHeader>

                    <CardFooter className="bg-transparent pb-4">

                        <p className="text-sm mb-2">
                            <i className="fas fa-exlamation-triangle " />Only jump case steps when updating a contact that came from Action Step. For all new clients move the case ahead normally above.
                        </p>

                        <ReactSelect
                            placeholder="Select Step To Jump To"
                            onChange={this.onSelectStep}
                            options={options}
                        />
                    </CardFooter>

                    {!complete_before_move.length && movingToStep ? (
                        <CardFooter className="bg-transparent text-right border-top">
                            <button onClick={this.onConfirmMove} className="btn btn-success ">Jump To Step</button>
                        </CardFooter>
                    ) : null}
                  

                    {complete_before_move.length ? (
                        <div className="p-3">
                            <TasksCompleteBeforeMove
                                case={this.props.case_workflow.case}
                                case_workflow={this.props.case_workflow}
                                complete_before_move={complete_before_move}
                                forceRefresh={forceRefresh}
                                hide_add_task={true}
                            />
                        </div>
                    ) : null}

                </Card>


                <ConfirmationModal
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    title="Jump Case To Step"
                    body={(
                        <span>
                            Are you sure you wish to jump this case ahead to step:<br />
                            <b className="text-warning font-weight-bold">{movingToStep ? movingToStep.label : null}</b>
                        </span>
                    )}
                    onConfirmation={this.onForceMove}
                />

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(History);