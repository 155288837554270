/*
Documentation

this file holds an array of all possible contact types for this system

*/

export default [


    // 'Accountant',
    // 'Bank',
    // 'Bookkeeper',
    // 'Buyers Representative',
    // 'Client Primary Contact',
    'Court',
    'Defendant',
    'Filing For Family Member',
    'Hard Of Hearing',

    'Judge',
    'Lawyer',
    'Legal Assistant',
    // 'Manager',
    // 'Mortgage Broker',
    'No Show',

    'Only Speaks Spanish',
    'Only Speaks Arabic',
    'Only Speaks Italian',
    'Only Speaks Portuguese',
    'Only Speaks French',
    'Only Speaks Chinese',
    'Only Speaks Japanese',

    'Opposing Lawyer',
    'Opposing Party',

    // 'Other Contact',
    'Plaintiff',
    'Primary Contact',
    // 'Purchaser',
    // 'Purchasers Conveyancer',
    // 'Real Estate Agent',
    // 'Relationship Manager',


    'Responsible Lawyer',
    // 'Sales Assistant',
    // 'Salesperson',
    // 'Spouse',
    // 'Staff Member',
    // 'Supplier',

    // 'Vendor',
    // 'Vendors Conveyancer',
    // 'Vendors Representative',
    'Witness',



]

