import apiWorker from '../apiWorker'

export default {

    create:         (data, hideErr)         => apiWorker('post',  `/api/v1/core/contact_notes/create`, data, hideErr),
    update:         (_id, data, hideErr)    => apiWorker('patch',  `/api/v1/core/contact_notes/update/${_id}`, data, hideErr),
    delete:         (_id, data, hideErr)    => apiWorker('delete',  `/api/v1/core/contact_notes/delete/${_id}`, data, hideErr),
    findByContact:  (contact_id)            => apiWorker('get',   `/api/v1/core/contact_notes/findByContact/${contact_id}`),

}
