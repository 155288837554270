/*
Documentation

this file renders an individual alert as a table row

*/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom';
import Response from './Response';


class AlertWrapper extends Component {

    state = {
        showMore: false
    };

    getResponseUserName = (response) => {

        let creating_users_name = 'SYSTEM';

        if(response.metadata && response.metadata.creating_users_name) {
            creating_users_name = response.metadata.creating_users_name
        }

        return creating_users_name
    }

    render() {

        const alert = this.props.alert

         //default and fallback is a system alert
         let creating_users_name = 'SYSTEM';
         let icon = alert.resolved ?  'fa-check fas mr-2 text-success ': 'fa-exclamation fas mr-2 text-danger';

         if(alert.metadata && alert.metadata.creating_users_name) {
            creating_users_name = alert.metadata.creating_users_name
        }

         let value = alert.value;

         value = value.replace('<p>', '')
         value = value.replace('</p>', '')

         return (
             <tr key={alert._id} className={`${alert.resolved ? 'resolved-alert' : ''}`}>
                 <td style={styles.td} className="item">
                     <div className="item-wrapper">

                         <div className="bg-secondary  p-3 py-4 rounded">

                             <h6 className="text-sm mb-0 font-weight-bold">
                                 <div>
                                     <b className="text-capitalize">
                                        <i className={icon} />
                                        {creating_users_name}
                                     </b>
                                 </div>
                                 {moment.unix(alert.created_at).fromNow()}
                             </h6>

                             <hr className="my-2" />
                             <p style={{wordBreak: 'break-word'}}  className="text-sm mb-2">{ReactHTMLParser(value)}</p>

                         </div>

                         {this.state.showMore ? (

                            <div>
                                 {alert.responses && alert.responses.length ? alert.responses.map((response, i) => (
                                    <div key={i} className="bg-danger p-3 pb-0 rounded mt-4">
                                        <h6 className="text-sm mb-0 font-weight-bold text-white">
                                            <div>
                                                <b className="text-capitalize">
                                                    <Link to={`/dashboard/users/view/${response.user}`} className="text-white">
                                                        {this.getResponseUserName(response)}
                                                    </Link>
                                                </b>
                                            </div>
                                            {moment.unix(response.created_at).fromNow()}
                                        </h6>

                                        <hr className="my-2" />

                                        <p style={{wordBreak: 'break-word'}}  className="text-sm mb-2 text-white">{ReactHTMLParser(response.value)}</p>
                                    </div>
                                )) : null}

                                <Response onShowLess={() => this.setState({showMore: false})} case_alert={alert._id} />
                            </div>

                         ) : (
                            <div>
                                <Row className="p-3">
                                    <Col xs={6}>
                                        <span>
                                            <i className="fas fa-comments text-info-original mr-2" />
                                            {alert.responses && alert.responses.length ?  alert.responses.length : 0}
                                        </span>
                                    </Col>
                                    <Col className="text-right">
                                        <Button onClick={() => this.setState({showMore: true})} style={{paddingBottom: 2}} size="sm" color="primary" outline type="button">
                                            <i className="fas fa-plus mr-2" /> Show More
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                         )}

                     </div>

                 </td>
             </tr>

        )

    }

}

const styles = {

    td: {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
    }

}

AlertWrapper.propTypes = {
    alert: PropTypes.object.isRequired,
}

export default AlertWrapper
