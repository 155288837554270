import React from 'react';
import { FormGroup, Input } from 'reactstrap';

const ArchkString = ({label, onChange}) => (
    <FormGroup>
        <label className="form-control-label mb-0">{label}</label>
        <Input
            type="text"
            onChange={(e) => onChange(e.target.value)}
        />
    </FormGroup>
)

export default ArchkString;
