/*
Documentation

renders the left hand sidebar that shows all the emails to select
inside of the messaging center

*/

import React, { Component } from 'react';
import _contact_emails from '_functions/contact_emails';

import ChangeEmailView from './ChangeEmailView';
import ChangeShowing from './ChangeShowing';
import RenderEmail from './RenderEmail';

import PropTypes from 'prop-types';

const dataSetLength = 20;

class SidebarLeft extends Component {

    state = {
        // either all / unread / sent
        showing         : 'unread',
        // the actual array of emails to show in the sidebar
        emails          : null,
        // total number of emails for query
        total_emails    : 0,
        noEmailError    : false,
    }

    onChangeShowing = (showing) => this.setState({showing, emails: null}, this.fetchEmails)

    // create the query string to pass along to the database
    createQueryString = () => {

        let { viewingEmailAddress } = this.props
        let { showing } = this.state
        viewingEmailAddress = viewingEmailAddress.toLowerCase()

        const skip = this.state.emails ? this.state.emails.length : 0

        let string = `?limit=${dataSetLength}&skip=${skip}&sort=created_at__desc`

        if(showing === 'sent') {
            string = string + `&filter=from__${viewingEmailAddress}`
        } else {
            string = string + `&filter=to_email__${viewingEmailAddress}`
        }

        if(showing === 'unread') string = string + `|read__false{bool}`

        return string

    }

    fetchEmails = async () => {

        this.props.setContent(null)

        this.setState({loading: true})

        const queryString = this.createQueryString()

        const call = await _contact_emails.find(queryString)

        let emails = this.state.emails ? [...this.state.emails] : [];
        let newEmails = call.data

        // if we have new emails add them to state
        if(newEmails && newEmails.length) emails = emails.concat(newEmails)

        this.setState({
            emails,
            loading: false,
            total_emails: call.total_documents ? call.total_documents : this.state.total_emails
        })

    }

    listenForContactEmailsAdded = (data) => {

        let email = data.data;

        if(email.read === false && email.to_email === this.props.viewingEmailAddress) {

            let emails = this.state.emails ? [...this.state.emails] : [];
            emails.unshift(email)

            this.setState({emails, total_emails: this.state.total_emails + 1})

        }

    }

    listenForContactEmailsUpdated = (data) => {

        let email = data.data;

        const { setContent, content }  = this.props
        let emails = this.state.emails ? [...this.state.emails] : []

        const foundEmail = emails.find(e => e._id === email._id)

        if(foundEmail) {

            emails[emails.findIndex(el => el._id === email._id)] = email;
            this.setState({emails})

            if(content && content._id === foundEmail._id) {
                setContent(email, 'email', true)
            }

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.viewingEmailAddress !== nextProps.viewingEmailAddress) {
            this.setState({emails: null}, this.fetchEmails)
        }

    }

    componentWillUnmount = () => {

        // comment out see if this fixes socket issue
        // io.off(socketEvents.contact_emails_added,    this.listenForContactEmailsAdded)
        // io.off(socketEvents.contact_emails_updated,    this.listenForContactEmailsUpdated)

    }

    componentDidMount = () => {

        // io.on(socketEvents.contact_emails_added,    this.listenForContactEmailsAdded)
        // io.on(socketEvents.contact_emails_updated,    this.listenForContactEmailsUpdated)

        if(this.props.viewingEmailAddress) {
            this.fetchEmails()
        } else {
            this.setState({noEmailError: true})
        }

    }

    render() {

        const { setContent, onChangeEmailAddress, viewingEmailAddress, content } = this.props
        const { emails, showing, loading, total_emails, noEmailError } = this.state

        return (

            <div lg={3} className="sidebar sidebar-left">

                {noEmailError ? (
                    <div className="section">
                        <p className="text-sm">No Email Have Been Authorized For Use.</p>
                    </div>
                ) : (

                    <>
                    <div className="section">

                        <ChangeShowing
                            showing={showing}
                            onChangeShowing={this.onChangeShowing}
                        />

                        <ChangeEmailView
                            viewingEmailAddress={viewingEmailAddress}
                            onChangeEmailAddress={onChangeEmailAddress}
                        />

                        </div>

                        <RenderEmail
                            activeEmail={content ? content._id : null}
                            emails={emails}
                            showing={showing}
                            setContent={setContent}
                            loading={loading}
                        />

                        {emails && (emails.length < total_emails) ? (
                        <div className="py-4 text-center">
                            <button onClick={() => this.fetchEmails()} className="btn btn-success"> Load More</button>
                        </div>
                    ) : null}
                    </>
                )}

            </div>

        )

    }

}

SidebarLeft.propTypes = {
    setContent              : PropTypes.func.isRequired,
    onChangeEmailAddress    : PropTypes.func.isRequired,
    viewingEmailAddress     : PropTypes.string.isRequired,
    content                 : PropTypes.object
}

export default SidebarLeft
