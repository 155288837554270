/*
Documentation

This file shows a column on the left side of the screen with all open and finished objection in two separate cards

*/

import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import renderName from 'utils/renderName';
import PropTypes from 'prop-types';

class ObjectionList extends React.Component {

    splitObjections = (objections) => {

        let unfinished_objections = [];
        let finished_objections = [];

        objections.forEach(o => {
            if(o.resolved_by) {
                finished_objections.push(o)
            } else {
                unfinished_objections.push(o)
            }
        })

        return {unfinished_objections, finished_objections}

    }

    render() {

        const { objections, case_id, toggleModalObjectionsCrud } = this.props
        const current_objection_id = this.props.objection._id

        const { unfinished_objections, finished_objections } = this.splitObjections(objections)

        return (
            <>

                <Card className={unfinished_objections.length ? "list-hover card-color card-danger" : "list-hover card-color card-success"}>

                    <CardHeader>
                        <CardTitle className="mb-0">Unfinished Objections</CardTitle>
                    </CardHeader>

                    <CardBody className="">

                        <ul className="mb-0">

                            {unfinished_objections.length ? unfinished_objections.map(o => (
                                <Link key={o._id} to={`/dashboard/objections/${case_id}/${o._id}`}>
                                    <li className={o._id === current_objection_id ? 'active' : null}>
                                        <p className="text-sm font-weight-bold mb-0">{o.name}</p>
                                        <p className="text-sm mb-0">
                                            <b>Objection Number: </b> {' '}
                                            {o.objection_number ?
                                                o.objection_number.name :
                                                <span><i className="fas fa-exclamation-triangle text-danger mr-2" />Number Not Found </span>
                                            }
                                        </p>
                                        <hr className="my-2" />
                                        <p className="text-sm mb-0">
                                            Created On {moment.unix(o.created_at).format('MMM Do, YYYY')} by <span className="text-capitalize">{renderName(o.created_by)}</span>
                                        </p>
                                        {o.severity === 1 ?  <Badge color="warning">Standard</Badge> : null}
                                        {o.severity === 2 ?  <Badge color="warning">Important</Badge> : null}
                                        {o.severity === 3 ?  <Badge color="danger">Severe</Badge> : null}
                                    </li>
                                </Link>
                            )): (
                                <li>There are currently no unfinished objections for this case.</li>
                            )}

                        </ul>

                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={() => toggleModalObjectionsCrud('create')} className="btn btn-success">
                            <i className="fas fa-plus mr-2" />
                            New Objection
                        </button>
                    </CardFooter>

                </Card>

                <Card className="list-hover card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0">Finished Objections</CardTitle>
                    </CardHeader>

                    <CardBody className="">

                        <ul className="mb-0">

                            {finished_objections.length ? finished_objections.map(o => (
                                <Link key={o._id} to={`/dashboard/objections/${case_id}/${o._id}`}>
                                    <li className={o._id === current_objection_id ? 'active' : null}>
                                        <p className="text-sm font-weight-bold mb-0">{o.name}</p>
                                        <p className="text-sm mb-0">
                                            <b>Objection Number: </b> {' '}
                                            {o.objection_number ?
                                                o.objection_number.name :
                                                <span><i className="fas fa-exclamation-triangle text-danger mr-2" />Number Not Found </span>
                                            }
                                        </p>
                                        <p className="text-sm mb-0">Created On {moment.unix(o.created_at).format('MMM Do, YYYY')} by <span className="text-capitalize">{renderName(o.created_by)}</span></p>
                                        <Badge color="success"><i className="fas fa-check mr-2" /> Resolved</Badge>
                                    </li>
                                </Link>
                            )) : (
                                <li>There are currently no finished objections for this case</li>
                            )}

                        </ul>

                    </CardBody>

                </Card>

            </>
        );
    }
}

ObjectionList.propTypes = {
    objections                  : PropTypes.array.isRequired,
    objection                   : PropTypes.object.isRequired,
    case_id                     : PropTypes.string.isRequired,
    toggleModalObjectionsCrud   : PropTypes.func.isRequired,
}

export default ObjectionList
